var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("telia-heading", { attrs: { tag: "h3", variant: "title-200" } }, [
        _vm._v(
          "\n    " + _vm._s(_vm.t("DATANET_DELIVERY_INFORMATION")) + "\n  "
        ),
      ]),
      _c(
        "div",
        { staticClass: "margin__bottom__24" },
        [
          _c("telia-p", {
            domProps: {
              innerHTML: _vm._s(
                _vm.t("DATANET_DELIVERY_INFORMATION_INFO", {
                  date: _vm.estimatedInstallationDate,
                })
              ),
            },
          }),
        ],
        1
      ),
      !_vm.access.enable
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "margin__bottom__12" },
                [
                  _c(
                    "telia-heading",
                    { attrs: { tag: "h4", variant: "title-100" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.t(
                              "DATANET_WOULD_YOU_LIKE_TO_ADD_TEMPORARY_ACCESS"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("telia-button", {
                attrs: {
                  id: "add-temporary-access",
                  "t-id": "add-temporary-access",
                  type: "button",
                  variant: "secondary",
                  size: "sm",
                  "left-icon": JSON.stringify({ name: "plus", size: "sm" }),
                  text: _vm.t("DATANET_ADD_TEMPORARY_ACCESS"),
                },
                on: {
                  click: function ($event) {
                    return _vm.showModal()
                  },
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "Summary",
                {
                  staticClass: "margin__bottom__24",
                  attrs: { "edit-label": _vm.t("DATANET_EDIT") },
                  on: {
                    edit: function ($event) {
                      return _vm.showModal()
                    },
                  },
                },
                [
                  _c("telia-p", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.t("DATANET_TEMPORARY_ACCESS"))),
                    ]),
                    _c("br"),
                    _vm._v("\n        " + _vm._s(_vm.fastTrackSummaryText)),
                    _c("br"),
                    _vm._v("\n        " + _vm._s(_vm.bearerSummaryText)),
                    _c("br"),
                    _vm._v("\n        " + _vm._s(_vm.bandwidthSummaryText)),
                    _c("br"),
                    _vm._v("\n        " + _vm._s(_vm.slaSummaryText)),
                    _c("br"),
                    _vm._v("\n        " + _vm._s(_vm.qosSummaryText)),
                    _c("br"),
                    _vm._v(
                      "\n        " + _vm._s(_vm.teliaInstallationSummaryText)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n        " + _vm._s(_vm.antennaPackageSummaryText)
                    ),
                    _c("br"),
                    _vm._v("\n        " + _vm._s(_vm.antennaCableSummaryText)),
                    _c("br"),
                    _vm._v(
                      "\n        " + _vm._s(_vm.antennaInstallationSummaryText)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n        " + _vm._s(_vm.afterDeliveryOptionSummaryText)
                    ),
                    _c("br"),
                  ]),
                ],
                1
              ),
              _c("telia-button", {
                attrs: {
                  id: "delete-temporary-access",
                  type: "button",
                  variant: "secondary",
                  size: "sm",
                  "left-icon": JSON.stringify({ name: "close", size: "sm" }),
                  text: _vm.t("DATANET_DELETE_TEMPORARY_ACCESS"),
                },
                on: {
                  click: function ($event) {
                    return _vm.disableAccess(_vm.Accesses.TEMPORARY)
                  },
                },
              }),
            ],
            1
          ),
      _vm.modalIsActive
        ? _c(
            "Modal",
            {
              attrs: {
                "modal-id": "temporary-access-settings-modal",
                width: "m",
                "is-open": _vm.modalIsActive,
              },
              on: {
                closeModal: function ($event) {
                  return _vm.hideModal()
                },
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "modal-body" }, slot: "modal-body" },
                [
                  _c("TemporaryAccessSettings", {
                    on: {
                      complete: function ($event) {
                        return _vm.hideModal()
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }