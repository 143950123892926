<template>
  <div>
    <InformationBox v-if="activeTooltip === 'bandwidth-distribution'" @close="handleTooltip(null)">
      <BandwidthAllocationTooltip />
    </InformationBox>
    <div class="tooltip-wrapper">
      <telia-heading tag="h4" variant="title-100">
        {{ t("DATANET_VPN_SELECT_BANDWIDTH_DISTRIBUTION") }}
      </telia-heading>
      <telia-icon
        class="tooltip-icon"
        name="question"
        size="sm"
        @click="handleTooltip('bandwidth-distribution')"
      />
    </div>
    <telia-fieldset>
      <div class="chip-choice-wrapper">
        <telia-chip-choice
          v-for="(option, index) in bandwidthDistributionOptions"
          :key="index"
          :value="option.value"
          :checked="bandwidthDistribution === option.value"
          :disabled="option.disabled"
          @click="handleBandwidthDistributionInput($event.target.value)"
        >
          {{ t(`DATANET_VPN_BANDWIDTH_${option.value}`) }}
        </telia-chip-choice>
      </div>
    </telia-fieldset>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { translateMixin } from "../../locale";
import InformationBox from "../InformationBox.vue";
import BandwidthAllocationTooltip from "../tooltip-information/vpn-settings/BandwidthAllocationTooltip.vue";

export default {
  name: "BandwidthDistribution",
  mixins: [translateMixin],
  props: {
    accessKey: {
      type: String,
      required: true,
    },
    activeTooltip: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("vpn", ["getVpnSettings", "getBandwidthDistributionOptions"]),
    bandwidthDistribution() {
      return this.getVpnSettings(this.accessKey).bandwidthDistribution.new;
    },
    bandwidthDistributionOptions() {
      return this.getBandwidthDistributionOptions(this.accessKey);
    },
  },
  methods: {
    ...mapActions("vpn", ["setBandwidthDistribution"]),
    handleBandwidthDistributionInput(value) {
      this.setBandwidthDistribution({
        accessKey: this.accessKey,
        new: value,
      });
    },
    handleTooltip(value) {
      this.$emit("activeTooltip", value);
    },
  },
  components: { InformationBox, BandwidthAllocationTooltip },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.chip-choice-wrapper > * {
  margin-right: $telia-spacing-12;
}
.tooltip-wrapper {
  display: flex;
}
.tooltip-icon {
  position: relative;
  top: 0.5rem;
  margin-left: $telia-spacing-12;
}
.tooltip-icon:hover {
  color: $telia-purple-500;
  cursor: pointer;
}
</style>
