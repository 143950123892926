import { isEnabled as featureToggleIsEnabled } from "@telia/b2b-feature-toggles";

const toggles = {
  "sdw-test-urls": false,
  "sdw-ciam-urls": true,
  "create-help-ticket": true,
  "internet-on-site": true,
  "internet-on-site-change": false,
};

function isEnabled(toggleName) {
  return featureToggleIsEnabled(toggleName, toggles);
}

export const sdwTestUrlsEnabled = () => isEnabled("sdw-test-urls");
export const sdwCiamUrlsEnabled = () => isEnabled("sdw-ciam-urls");
export const createHelpTicketEnabled = () => isEnabled("create-help-ticket");
export const internetOnSiteEnabled = () => isEnabled("internet-on-site");
export const internetOnSiteChangeEnabled = () => isEnabled("internet-on-site-change");
