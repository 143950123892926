var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("telia-heading", {
        staticClass: "margin__bottom__12",
        attrs: { tag: "h3", variant: "title-100" },
        domProps: { textContent: _vm._s(_vm.titleText) },
      }),
      _vm.shouldShowActionSelection
        ? _c("ActionSelection", {
            staticClass: "margin__bottom__24",
            attrs: {
              "selected-action": _vm.network.action,
              "disabled-actions": _vm.disabledActions,
            },
            on: {
              selected: function ($event) {
                return _vm.handleActionSelection($event)
              },
            },
          })
        : _vm._e(),
      _vm.isChangeOrder
        ? _c("telia-p", [
            _c("strong", [_vm._v(_vm._s(_vm.t("DATANET_VPN_LAN_NETWORK")))]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "margin__bottom__24" },
        [
          _c(
            "div",
            { staticClass: "margin__bottom__4 inputs-wrapper" },
            [
              _vm.shouldShowCurrentNetworkInput
                ? _c("telia-text-input", {
                    staticClass: "input-container",
                    attrs: {
                      "t-id": "current-lan-network",
                      label: _vm.t("DATANET_CURRENT_VALUE"),
                      disabled: _vm.isValidating,
                      value: _vm.network.input.current,
                    },
                    on: {
                      input: function ($event) {
                        _vm.network.input.current = $event.target.value
                      },
                    },
                  })
                : _vm._e(),
              _vm.shouldShowNewNetworkInput
                ? _c("telia-text-input", {
                    staticClass: "input-container",
                    attrs: {
                      "t-id": "new-lan-network",
                      label: _vm.newNetworkLabel,
                      disabled: _vm.isValidating,
                      value: _vm.network.input.new,
                    },
                    on: {
                      input: function ($event) {
                        _vm.network.input.new = $event.target.value
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("telia-p", {
            class: _vm.isAnyNetworkInvalid
              ? "additional-text-warning"
              : "additional-text",
            attrs: { variant: "additional-100" },
            domProps: {
              textContent: _vm._s(_vm.t("DATANET_VPN_LAN_NETWORK_PLACEHOLDER")),
            },
          }),
        ],
        1
      ),
      _vm.shouldShowVirtualIpInputs
        ? [
            _vm.isChangeOrder
              ? _c("telia-p", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.t("DATANET_VPN_LAN_VIRTUAL_IP"))),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "margin__bottom__4 inputs-wrapper" },
              [
                _vm.shouldShowCurrentNetworkInput
                  ? _c("telia-text-input", {
                      staticClass: "input-container",
                      attrs: {
                        label: _vm.t("DATANET_CURRENT_VALUE"),
                        disabled: _vm.shouldDisableVirtualIpInputs,
                        value: _vm.network.virtualIp.current,
                      },
                      on: {
                        input: function ($event) {
                          _vm.network.virtualIp.current = $event.target.value
                        },
                      },
                    })
                  : _vm._e(),
                _vm.shouldShowNewNetworkInput
                  ? _c("telia-text-input", {
                      staticClass: "input-container",
                      attrs: {
                        "t-id": "new-virtual-ip",
                        label: _vm.newVirtualIpLabel,
                        disabled: _vm.shouldDisableVirtualIpInputs,
                        value: _vm.network.virtualIp.new,
                      },
                      on: {
                        input: function ($event) {
                          _vm.network.virtualIp.new = $event.target.value
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("telia-p", {
              class: _vm.isAnyVirtualIpInvalid
                ? "additional-text-warning"
                : "additional-text",
              attrs: { variant: "additional-100" },
              domProps: {
                textContent: _vm._s(
                  _vm.t("DATANET_VPN_LAN_VIRTUAL_IP_PLACEHOLDER")
                ),
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }