<template>
  <div>
    <telia-heading tag="h4" variant="title-100">
      {{ t("DATANET_EXTENDED_AVAILABILITY_TYPE_TITLE") }}
    </telia-heading>
    <div class="margin__bottom__24">
      <telia-fieldset>
        <div class="chip-choice-wrapper">
          <telia-chip-choice
            v-for="backupOption in backupOptions"
            :key="backupOption.value"
            ref="backup"
            id="secondary-access-backup-options"
            name="secondary-access-backup-options"
            caption="fieldset for secondary-access-backup-options"
            :checked="backupOption.value === backup"
            :disabled="backupOption.disabled"
            @change="handleBackupSelection(backupOption.value)"
          >
            {{ backupOption.label }}
          </telia-chip-choice>
        </div>
      </telia-fieldset>
      <ValidationError v-if="$v.backup.$error">
        {{ t("DATANET_EXTENDED_AVAILABILITY_TYPE_REQUIRED") }}
      </ValidationError>
    </div>

    <div v-if="shouldShowSecondaryAddress" class="margin__bottom__24">
      <AddressSearch
        class="input-wrapper"
        :label="t('DATANET_EXTENDED_AVAILABILITY_SECONDARY_ADDRESS')"
        :disabled="!isSecondaryAddressEditable"
        :value="secondaryFullAddress"
        @addressSelected="updateSecondaryAddress($event)"
      />
    </div>

    <telia-heading tag="h4" variant="title-100">
      {{ t("DATANET_ACCESS_TYPE") }}
    </telia-heading>
    <div class="margin__bottom__24">
      <telia-fieldset>
        <div class="chip-choice-wrapper">
          <telia-chip-choice
            v-for="bearerOption in bearerOptions"
            :key="bearerOption.value"
            inline
            ref="bearer"
            id="secondary-access-bearer-options"
            name="secondary-access-bearer-options"
            caption="fieldset for secondary-access-bearer-options"
            :checked="bearerOption.value === bearer"
            :disabled="bearerOption.disabled"
            @change="handleBearerSelection(bearerOption.value)"
          >
            {{ bearerOption.label }}
          </telia-chip-choice>
        </div>
      </telia-fieldset>
      <ValidationError v-if="$v.bearer.$error">
        {{ t("DATANET_EXTENDED_AVAILABILITY_BEARER_REQUIRED") }}
      </ValidationError>
    </div>
    <div v-if="isFttxBearer">
      <div class="input-wrapper">
        <telia-select
          ref="apartment"
          id="apartment"
          :label="t('DATANET_APARTMENT_NUMBER')"
          :options="JSON.stringify(apartmentOptions)"
          :validation-message="t('DATANET_APARTMENT_NUMBER_REQUIRED')"
          @change="apartment = $event.target.value"
        />
        <ValidationError v-if="$v.apartment.$error">
          {{ t("DATANET_APARTMENT_NUMBER_REQUIRED") }}
        </ValidationError>
      </div>
      <div class="input-wrapper">
        <telia-text-input
          id="installation-room"
          :label="t('DATANET_INSTALLATION_ROOM')"
          :value="installationRoom"
          @input="installationRoom = $event.target.value"
        />
      </div>
    </div>
    <telia-heading tag="h4" variant="title-100" class="margin__bottom__12">
      {{ t("DATANET_ACCESS_SETTINGS") }}
    </telia-heading>
    <div class="input-wrapper">
      <telia-select
        ref="bandwidth"
        id="secondary-access-bandwidth"
        :label="t('DATANET_SPEED')"
        :disabled="disableBandwidth"
        :options="JSON.stringify(bandwidthOptions)"
        @change="handleBandwidthSelection($event.target.value)"
      />
      <ValidationError v-if="$v.bandwidth.$error">
        {{
          !$v.bandwidth.required
            ? t("DATANET_EXTENDED_AVAILABILITY_BANDWIDTH_REQUIRED")
            : t("DATANET_EXTENDED_AVAILABILITY_BANDWIDTH_EXCEEDS_PRIMARY")
        }}
      </ValidationError>
    </div>
    <div class="input-wrapper">
      <telia-select
        ref="sla"
        id="secondary-access-sla"
        :label="t('DATANET_SLA')"
        :disabled="disableSla"
        :options="JSON.stringify(slaOptions)"
        @change="sla = $event.target.value"
      />
      <ValidationError v-if="$v.sla.$error">
        {{ t("DATANET_SLA_REQUIRED") }}
      </ValidationError>
    </div>
    <div class="input-wrapper">
      <telia-select
        ref="qos"
        id="secondary-access-qos"
        :label="t('DATANET_QOS')"
        :disabled="disableQos"
        :options="JSON.stringify(qosOptions)"
        @change="qos = $event.target.value"
      />
      <ValidationError v-if="$v.qos.$error">
        {{ t("DATANET_QOS_REQUIRED") }}
      </ValidationError>
    </div>
    <StatBas
      v-if="shouldShowSecondaryAccessTrafficStatistics"
      class="component-wrapper"
      id="secondary-access-stat-bas"
      :access-key="accessKey"
      :initial-value="statBas"
      @request-stat-bas="statBas = $event"
    />

    <div v-if="isWirelessBearer">
      <WirelessInstallation
        class="component-wrapper"
        id="request-installation-by-telia-for-secondary-access"
        :initial-value="wirelessInstallation"
        :disabled="useTemporaryAccessAsBackup"
        @request-installation-by-telia="wirelessInstallation = $event.target.value"
      />
      <AntennaPackage
        class="component-wrapper"
        :access-key="accessKey"
        :disable="useTemporaryAccessAsBackup"
        @antenna-package-data="handleAntennaPackageData($event)"
      />
    </div>
    <div class="button-wrapper">
      <telia-button
        id="save-secondary-access-configuration"
        t-id="save-secondary-access-configuration"
        variant="expressive"
        type="button"
        @click="saveAndClose()"
      >
        {{ t("DATANET_SAVE_CHANGES") }}
      </telia-button>

      <telia-button
        id="cancel-secondary-access-configuration"
        type="button"
        variant="secondary"
        @click="$emit('complete')"
      >
        {{ t("DATANET_ABORT") }}
      </telia-button>
    </div>
  </div>
</template>

<script>
import StatBas from "./StatBas.vue";
import AntennaPackage from "./AntennaPackage.vue";
import WirelessInstallation from "./WirelessInstallation.vue";
import AddressSearch from "../AddressSearch.vue";
import ValidationError from "../ValidationError.vue";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapState, mapGetters, mapActions } from "vuex";
import { translateMixin } from "../../locale";
import { Accesses, Bearers } from "../../constants";

export default {
  name: "SecondaryAccessSettings",
  mixins: [translateMixin, validationMixin],
  components: {
    StatBas,
    AntennaPackage,
    WirelessInstallation,
    AddressSearch,
    ValidationError,
  },

  data() {
    return {
      accessKey: Accesses.SECONDARY,
      backup: this.$store.state.access[Accesses.SECONDARY].backup,
      bearer: this.$store.state.products.secondaryBearer,
      apartment: this.$store.state.apartments.apartment,
      installationRoom: this.$store.state.addresses.installation.installationRoom,
      wirelessInstallation: this.$store.state.access[Accesses.SECONDARY].options
        .wirelessInstallation,
      antennaPackage: this.$store.state.access[Accesses.SECONDARY].options.antenna.package,
      antennaCable: this.$store.state.access[Accesses.SECONDARY].options.antenna.cable,
      antennaInstallation: this.$store.state.access[Accesses.SECONDARY].options.antenna
        .installation,
      bandwidth: this.$store.state.access[Accesses.SECONDARY].bandwidth.new,
      sla: this.$store.state.access[Accesses.SECONDARY].sla.new,
      qos: this.$store.state.access[Accesses.SECONDARY].qos.new,
      statBas: this.$store.state.access[Accesses.SECONDARY].options.trafficStatistics.new,
    };
  },

  computed: {
    ...mapState("products", {
      primaryAccessBearer: (state) => state.bearer.value,
    }),

    ...mapState("access", {
      primaryAccessBandwidth: (state) => state[Accesses.PRIMARY].bandwidth.new,
      useTemporaryAccessAsBackup: (state) => state[Accesses.TEMPORARY].useAsSecondaryAccess,
      secondaryAccess: (state) => state[Accesses.SECONDARY],
    }),

    ...mapState("addresses", {
      secondaryAddress: (state) => state.secondary,
    }),

    ...mapGetters("apartments", {
      apartmentOptions: "getApartmentOptions",
    }),

    ...mapGetters("products", [
      "getBackupOptions",
      "getBearerOptionsForBackup",
      "getBandwidthOptions",
      "getSlaOptions",
      "getQosOptions",
    ]),

    ...mapGetters("access", [
      "shouldDisableExtendedAvailability",
      "shouldDisableSecondaryAccessBandwidth",
      "shouldDisableSecondaryAccessSla",
      "shouldDisableSecondaryAccessQos",
      "shouldShowSecondaryAccessTrafficStatistics",
    ]),

    ...mapGetters("addresses", ["shouldShowSecondaryAddress", "isSecondaryAddressEditable"]),

    disableSecondaryAccessSettings() {
      return (
        !this.bearer || this.useTemporaryAccessAsBackup || this.shouldDisableExtendedAvailability
      );
    },

    disableBandwidth() {
      return this.disableSecondaryAccessSettings || this.shouldDisableSecondaryAccessBandwidth;
    },

    disableSla() {
      return this.disableSecondaryAccessSettings || this.shouldDisableSecondaryAccessSla;
    },

    disableQos() {
      return this.disableSecondaryAccessSettings || this.shouldDisableSecondaryAccessQos;
    },

    isWirelessBearer() {
      return this.bearer === Bearers.WIRELESS;
    },

    isFttxBearer() {
      return this.bearer === Bearers.FTTX;
    },

    backupOptions() {
      return this.getBackupOptions(this.primaryAccessBearer);
    },

    bearerOptions() {
      return this.getBearerOptionsForBackup(this.primaryAccessBearer, this.backup);
    },

    defaultApartment() {
      if (!this.apartment) return 0;
      let index = this.apartmentOptions.findIndex(
        (apartmentOption) => apartmentOption.value === this.apartment
      );
      return index === -1 ? 0 : index;
    },

    secondaryFullAddress() {
      return this.secondaryAddress.fullAddress || "";
    },

    bandwidthOptions() {
      const options = this.getBandwidthOptions(this.bearer, Number(this.primaryAccessBandwidth));

      return options.map((option) => {
        return {
          value: option.value,
          label: option.label,
          selected: option.value === this.bandwidth,
          disabled: option.disabled,
        };
      });
    },

    slaOptions() {
      const options = this.getSlaOptions(this.bearer);

      return options.map((option) => {
        return {
          value: option.value,
          label: option.label,
          selected: option.value === this.sla,
          disabled: option.disabled,
        };
      });
    },

    qosOptions() {
      const options = this.getQosOptions(this.bearer, this.bandwidth);

      return options.map((option) => {
        return {
          value: option.value,
          label: option.label,
          selected: option.value === this.qos,
          disabled: option.disabled,
        };
      });
    },
  },
  validations: {
    backup: {
      required,
    },
    bearer: {
      required,
    },
    apartment: {
      required: requiredIf(function () {
        return this.isFttxBearer;
      }),
    },
    bandwidth: {
      required,
      lessOrEqualToPrimaryBandwidth: function () {
        return Number(this.bandwidth) <= Number(this.primaryAccessBandwidth);
      },
    },
    sla: {
      required,
    },
    qos: {
      required,
    },
  },

  methods: {
    ...mapActions("access", [
      "setBackup",
      "setBearer",
      "setBandwidth",
      "setSla",
      "setQos",
      "setAntennaPackage",
      "setAntennaCable",
      "setAntennaInstallation",
      "enableWirelessInstallation",
      "disableWirelessInstallation",
      "enableAccess",
      "setTrafficStatistics",
    ]),

    ...mapActions("addresses", ["setInstallationRoomInfo"]),

    ...mapActions("apartments", ["setApartment"]),

    ...mapActions("products", {
      setBearer: "setSecondaryBearer",
    }),

    updateSecondaryAddress(address) {
      if (address) {
        this.$store.dispatch("addresses/addAddress", {
          addressId: "secondary",
          newAddress: address,
        });
      } else {
        this.$store.dispatch("addresses/resetAddress", "secondary");
      }
    },

    handleBackupSelection(value) {
      this.backup = value;
      if (this.useTemporaryAccessAsBackup) return;
      this.handleBearerSelection("");
    },

    resetDropdowns() {
      this.bandwidth = "";
      this.$v.bandwidth.$reset();
      this.resetSlaAndQos();
    },

    resetSlaAndQos() {
      this.sla = "";
      this.$v.sla.$reset();
      this.qos = "";
      this.$v.qos.$reset();
    },

    handleBearerSelection(value) {
      this.bearer = value;
      this.$v.bearer.$reset();
      this.resetDropdowns();
    },

    handleBandwidthSelection(value) {
      this.bandwidth = value;
      this.resetSlaAndQos();
    },

    handleAntennaPackageData(data) {
      this.antennaPackage = data.antennaPackage;
      this.antennaCable = data.antennaCable;
      this.antennaInstallation = data.antennaInstallation;
    },

    saveAndClose() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.setBackup(this.backup);

      this.setBearer(this.bearer);

      this.setApartment(this.apartment);

      this.setInstallationRoomInfo(this.installationRoom);

      this.wirelessInstallation
        ? this.enableWirelessInstallation(Accesses.SECONDARY)
        : this.disableWirelessInstallation(Accesses.SECONDARY);

      this.setAntennaPackage({
        accessKey: Accesses.SECONDARY,
        name: this.antennaPackage,
      });

      this.setAntennaCable({
        accessKey: Accesses.SECONDARY,
        boolean: this.antennaCable,
      });

      this.setAntennaInstallation({
        accessKey: Accesses.SECONDARY,
        boolean: this.antennaInstallation,
      });

      this.setBandwidth({
        accessKey: Accesses.SECONDARY,
        new: this.bandwidth,
      });

      this.setSla({
        accessKey: Accesses.SECONDARY,
        new: this.sla,
      });

      this.setQos({
        accessKey: Accesses.SECONDARY,
        new: this.qos,
      });

      this.setTrafficStatistics({
        accessKey: Accesses.SECONDARY,
        new: this.statBas,
      });

      this.enableAccess(Accesses.SECONDARY);

      this.$emit("complete");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.input-wrapper {
  max-width: 42rem;
  margin-bottom: $telia-spacing-24;
}
.is-block > div {
  display: block;
}
.chip-choice-wrapper {
  display: flex;
}
.chip-choice-wrapper > * {
  margin-right: $telia-spacing-16;
}
.component-wrapper {
  margin-bottom: $telia-spacing-24;
}
.button-wrapper {
  padding-top: $telia-spacing-12;
}
.button-wrapper > * {
  margin-right: $telia-spacing-8;
}
.margin {
  &__bottom {
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
}
</style>
