<template>
  <div class="address-master" data-dont-collect>
    <telia-tab variant="light-wide">
      <telia-tab-content tab-id="1" :name="t('ADDRESS_MASTER.ADDRESS_SEARCH')">
        <div class="address-search">
          <div class="address-input">
            <b2x-skeleton-input v-show="initializing" />
            <AddressSearch
              :label="t('ADDRESS_MASTER.ADDRESS')"
              @addressSelected="address = $event"
            />
          </div>
          <telia-p class="additional-info">
            {{ t("ADDRESS_MASTER.ADDRESS_SEARCH_DESCRIPTION") }}
          </telia-p>
          <div class="service-info">
            <telia-p> {{ t("PRE_CHECK.SERVICE_INFO") }}</telia-p>
          </div>
          <div class="button-holder">
            <telia-button
              t-id="address-button"
              variant="primary"
              size="sm"
              :disabled="disableSubmit"
              @click="handleAddressSubmit()"
            >
              {{
                disableSubmit ? t("ADDRESS_MASTER.CHECKING_ADDRESS") : t("ADDRESS_MASTER.CONTINUE")
              }}
            </telia-button>
          </div>
        </div>
      </telia-tab-content>

      <telia-tab-content tab-id="2" :name="t('ADDRESS_MASTER.COORDINATE_SEARCH')">
        <div ref="coordinateSearch" class="coordinate-search">
          <form @submit.prevent>
            <div class="address-input">
              <telia-text-input
                ref="xCoordinate"
                :label="t('ADDRESS_MASTER.COORDINATES.X')"
                :value="coordinates.x"
                @input="coordinates.x = $event.target.value"
                minLength="7"
                maxLength="7"
                :minlength-error-message="t('ADDRESS_MASTER.MIN_LENGTH')"
                :additional="t('ADDRESS_MASTER.SEVEN_DIGITS')"
                required
                :required-error-message="t('ADDRESS_MASTER.REQUIRED_MESSAGE')"
              />
            </div>
            <div class="address-input">
              <telia-text-input
                ref="yCoordinate"
                :label="t('ADDRESS_MASTER.COORDINATES.Y')"
                :value="coordinates.y"
                @input="coordinates.y = $event.target.value"
                minLength="7"
                maxLength="7"
                :minlength-error-message="t('ADDRESS_MASTER.MIN_LENGTH')"
                :additional="t('ADDRESS_MASTER.SEVEN_DIGITS')"
                required
                :required-error-message="t('ADDRESS_MASTER.REQUIRED_MESSAGE')"
              />
            </div>
            <telia-p class="additional-info">
              {{ t("ADDRESS_MASTER.COORDINATES_DESCRIPTION") }}
              <b2x-tooltip :content="t('ADDRESS_MASTER.TOOLTIP')"> (RT90) </b2x-tooltip>
            </telia-p>
            <div class="service-info">
              <telia-p> {{ t("PRE_CHECK.SERVICE_INFO") }}</telia-p>
            </div>
            <div class="button-holder">
              <telia-button
                t-id="coordinate-button"
                type="submit"
                variant="primary"
                size="sm"
                :disabled="disableSubmit"
                @click="handleCoordinateSubmit()"
              >
                {{
                  disableSubmit
                    ? t("ADDRESS_MASTER.CHECKING_ADDRESS")
                    : t("ADDRESS_MASTER.CONTINUE")
                }}
              </telia-button>
            </div>
          </form>
        </div>
      </telia-tab-content>
    </telia-tab>
  </div>
</template>

<script>
import AddressSearch from "./AddressSearch.vue";
import { mapState } from "vuex";
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { translateMixin } from "../locale";
import "@telia/b2x-input-suggestions";
import "@telia/b2x-skeleton-input";
import "@telia/b2x-tooltip";

export default {
  name: "AddressMaster",
  mixins: [translateMixin],
  components: {
    AddressSearch,
  },

  props: {
    disableSubmit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      debounce: null,
      addressError: "",
      suggestions: [],
      address: null,
      selectedAddress: null,
      coordinates: {
        x: "",
        y: "",
      },
      initializing: false,
      loading: false,
      initiatedSearch: false,
    };
  },

  mounted() {
    this.initializing = true;
    this.$refs.xCoordinate.addEventListener(
      "input",
      () => {
        this.GA(analytics.action.INITIATED, "Sök X-koordinat");
      },
      { once: true }
    );
    this.$refs.yCoordinate.addEventListener(
      "input",
      () => {
        if (this.coordinates.x !== "") {
          this.GA(analytics.action.COMPLETED, "Sök X-koordinat");
        }
        this.GA(analytics.action.INITIATED, "Sök Y-koordinat");
      },
      { once: true }
    );
    this.initializing = false;
  },

  computed: {
    ...mapState("user", {
      scopeId: (state) => state.scopeId,
    }),
  },

  methods: {
    handleAddressSubmit() {
      this.GA(analytics.action.CLICK, "Sök adress klickad");
      this.$emit("addressSubmit", this.address);
    },

    handleCoordinateSubmit() {
      if (this.coordinates.x.length !== 7 && this.coordinates.y.length !== 7) {
        return;
      }

      this.GA(analytics.action.COMPLETED, "Sök X-koordinat");
      this.GA(analytics.action.COMPLETED, "Sök Y-koordinat");
      this.GA(analytics.action.CLICK, "Sök koordinater klickad");
      this.$emit("coordinateSubmit", { x: this.coordinates.x, y: this.coordinates.y });
    },

    GA(action, label) {
      analytics.trackEvent("DataNet", action, label);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";

.address-input {
  margin-top: $telia-spacing-8;
}

.button-holder {
  margin-top: $telia-spacing-32;
}

.additional-info {
  margin-top: $telia-spacing-20;
}
.service-info {
  padding: $telia-spacing-2 0 0 0;
}
.button-holder {
  margin-top: $telia-spacing-24;
}
::v-deep .telia-tab__container {
  padding: $telia-spacing-0;
}
</style>
