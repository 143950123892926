var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    { staticClass: "list" },
    _vm._l(_vm.stepTitles, function (title, index) {
      return _c(
        "li",
        { key: "step-" + _vm.indexToStep(index), staticClass: "list__item" },
        [
          _c("div", { staticClass: "list__item__title" }, [
            _vm.showIcons
              ? _c(
                  "span",
                  { class: _vm.getIconClassList(_vm.indexToStep(index)) },
                  [
                    _vm.currentStep > _vm.indexToStep(index)
                      ? _c(
                          "span",
                          { staticClass: "list__item__title__icon__container" },
                          [
                            _c("telia-icon", {
                              attrs: { name: "checkmark", size: "sm" },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "span",
                          { staticClass: "list__item__title__icon__number" },
                          [_vm._v(_vm._s(_vm.indexToStep(index)))]
                        ),
                  ]
                )
              : _vm._e(),
            _c("p", { staticClass: "list__item__title__text" }, [
              _c(
                "strong",
                {
                  class: !_vm.showIcons ? "list__item__title__text--large" : "",
                },
                [_vm._v("\n          " + _vm._s(title) + "\n        ")]
              ),
            ]),
          ]),
          _vm._t("step-" + _vm.indexToStep(index)),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }