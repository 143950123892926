import { Actions, MetadataKeys } from "../../../constants";

const getters = {
  shouldShowVirtualIp: (state, getters, rootState, rootGetters) =>
    rootGetters["access/hasBackupAccess"] &&
    (rootGetters["access/hasBackupTypeBackup"] ||
      rootGetters["access/hasBackupTypeRedundancy"] ||
      rootGetters["access/hasBackupTypeDiversity"]),

  shouldShowDhcpRelays: (state, getters, rootState, rootGetters) => (vpnKey) =>
    rootGetters["metadata/isVisible"](MetadataKeys.DHCP_RELAYS) &&
    rootGetters["vpn/getVpn"](vpnKey).action !== Actions.DELETE,

  isDhcpRelayActionEditable: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isActionEditable"](MetadataKeys.DHCP_RELAYS),

  getLan: (state) => (lanKey) => state[lanKey] || null,

  getAllLans: (state) => Object.values(state),

  getLansByKeys: (state) => (lanKeys = []) =>
    Object.values(state).filter((lan) => lanKeys.includes(lan.key)),

  getNetworks: (state) => (lanKey) => {
    const lan = state[lanKey];
    if (!lan) {
      return [];
    }

    return lan.networks || [];
  },

  hasAnyDhcpRelays: (state, getters) => getters.getAllLans.some((lan) => lan.dhcpRelays.length),

  canAddLan: (state, getters, rootState, rootGetters) => (vpnKey) => {
    if (rootGetters["vpn/isLogicalInterface"]) {
      return true;
    }

    const { lans: vpnLans } = rootGetters["vpn/getVpn"](vpnKey);
    return getters.getLansByKeys(vpnLans).filter((lan) => lan.action === Actions.ADD).length < 1;
  },
};

export default getters;
