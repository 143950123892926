<template>
  <div>
    <div v-if="isChangeOrder" class="margin__bottom__24">
      <telia-heading
        tag="h3"
        variant="title-100"
        class="margin__bottom__12"
        v-text="t('DATANET_VPN_EDIT_LAN_CHOICE')"
      />
      <ActionSelection
        :selected-action="lan.action"
        :disabled-actions="disabledActions"
        @selected="handleActionSelection($event)"
      />
    </div>

    <div v-if="shouldShowIdentifyingNetwork" class="margin__bottom__24">
      <telia-p>
        <strong>{{ t("DATANET_VPN_LAN_IDENTIFYING_NETWORK") }}</strong>
      </telia-p>
      <div class="input-wrapper margin__bottom__4">
        <telia-text-input
          :id="`lan-${lan.key}-identifying-network`"
          :label="t('DATANET_CURRENT_VALUE')"
          :value="lan.identifyingNetwork.input.new"
          @input="handleIdentifyingNetworkInput($event.target.value, lan.key)"
        />
      </div>
      <ValidationError v-if="v.identifyingNetwork.$error">
        {{ identifyingNetworkErrorText }}
      </ValidationError>
      <telia-p v-else variant="additional-100" class="additional-text">
        {{ t("DATANET_NETWORK_PLACEHOLDER") }}
      </telia-p>
    </div>
    <div v-if="isLogicalInterface" class="margin__bottom__24">
      <telia-p>
        <strong>{{ t("DATANET_VPN_LAN_VLAN_ID") }}</strong>
      </telia-p>
      <div class="margin__bottom__4 inputs-container">
        <telia-text-input
          v-if="shouldNotAddLan"
          t-id="current-vlan-id"
          class="input-container"
          :label="t('DATANET_CURRENT_VALUE')"
          :disabled="isSecondaryAccessVpn(lan.vpn)"
          :validation-state="v.vlanId.current.$error ? 'Invalid' : 'Valid'"
          :value="lan.vlanId.current"
          @input="
            setVlanId({
              lanKey: lan.key,
              current: $event.target.value,
            })
          "
        />
        <telia-text-input
          v-if="shouldShowNewVlanId"
          t-id="new-vlan-id"
          class="input-container"
          :label="newVlanIdLabel"
          :disabled="isSecondaryAccessVpn(lan.vpn)"
          :validation-state="v.vlanId.new.$error ? 'Invalid' : 'Valid'"
          :value="lan.vlanId.new"
          @input="
            setVlanId({
              lanKey: lan.key,
              new: $event.target.value,
            })
          "
        />
      </div>
      <telia-p
        variant="additional-100"
        :class="isAnyVlanIdInvalid ? 'additional-text-warning' : 'additional-text'"
        v-text="t('DATANET_VPN_LAN_VLAN_ID_REQUIRED')"
      />
      <div class="margin__top__12">
        <telia-checkbox
          v-if="shouldShowVlanIdAction"
          t-id="change-vlanId"
          :value="vlanIdCheckboxValue"
          :checked="isVlanIdCheckboxChecked"
          @input="handleVlanIdActionSelection(vlanIdCheckboxValue)"
        >
          {{ t("DATANET_VPN_LAN_UPDATE_VLAN_ID") }}
        </telia-checkbox>
      </div>
    </div>

    <template v-if="shouldNotDeleteLan">
      <div class="information-box-wrapper">
        <InformationBox v-if="activeTooltip === 'lan'" @close="handleTooltip(null)">
          <LanTooltip />
        </InformationBox>
        <InformationBox v-if="activeTooltip === 'dhcp-relay'" @close="handleTooltip(null)">
          <DhcpRelayTooltip />
        </InformationBox>
      </div>
      <div class="margin__bottom__24">
        <div class="tooltip-wrapper">
          <telia-p v-if="isPrimaryAccessVpn(lan.vpn) || lan.networks.length > 0">
            <strong>{{ t("DATANET_VPN_LAN_NETWORKS") }}</strong>
          </telia-p>
          <telia-icon
            v-if="isPrimaryAccessVpn(lan.vpn)"
            class="tooltip-icon"
            name="question"
            size="sm"
            @click="handleTooltip('lan')"
          />
        </div>
        <SummaryList v-if="lan.networks.length > 0">
          <SummaryRow variant="header">
            <SummaryColumn v-if="shouldShowVirtualIp" />
            <SummaryColumn v-if="isChangeOrder">
              <telia-p>{{ t("DATANET_CURRENT_VALUE") }}</telia-p>
            </SummaryColumn>
            <SummaryColumn>
              <telia-p>{{ t("DATANET_NEW_VALUE") }}</telia-p>
            </SummaryColumn>
            <SummaryColumn variant="small" />
          </SummaryRow>
          <SummaryRow v-for="(network, index) in lan.networks" :key="`range-${index}`">
            <SummaryColumn v-if="shouldShowVirtualIp">
              <span>
                <telia-p>{{ t("DATANET_VPN_LAN_NETWORK") }}</telia-p>
              </span>
              <span>
                <telia-p>{{ t("DATANET_VPN_LAN_VIRTUAL_IP") }}</telia-p>
              </span>
            </SummaryColumn>
            <SummaryColumn v-if="isChangeOrder">
              <span
                :class="
                  v.networks.$each.$iter[index].input.current.$invalid
                    ? 'additional-text-warning'
                    : ''
                "
              >
                <telia-p>
                  {{
                    network.input.current
                      ? network.input.current
                      : v.networks.$each.$iter[index].input.current.$invalid
                      ? t("DATANET_MISSING_VALUE")
                      : "-"
                  }}
                </telia-p>
              </span>
              <span
                v-if="shouldShowVirtualIp"
                :class="
                  v.networks.$each.$iter[index].virtualIp.current.$invalid
                    ? 'additional-text-warning'
                    : ''
                "
              >
                <telia-p>
                  {{
                    network.virtualIp.current
                      ? network.virtualIp.current
                      : v.networks.$each.$iter[index].virtualIp.current.$invalid
                      ? t("DATANET_MISSING_VALUE")
                      : "-"
                  }}
                </telia-p>
              </span>
            </SummaryColumn>
            <SummaryColumn>
              <span
                :class="
                  v.networks.$each.$iter[index] && v.networks.$each.$iter[index].input.new.$invalid
                    ? 'additional-text-warning'
                    : ''
                "
              >
                <telia-p>
                  {{
                    network.input.new
                      ? network.input.new
                      : v.networks.$each.$iter[index].input.new.$invalid
                      ? t("DATANET_MISSING_VALUE")
                      : "-"
                  }}
                </telia-p>
              </span>
              <span
                v-if="shouldShowVirtualIp"
                :class="
                  v.networks.$each.$iter[index].virtualIp.current.$invalid
                    ? 'additional-text-warning'
                    : ''
                "
              >
                <telia-p>
                  {{
                    network.virtualIp.new
                      ? network.virtualIp.new
                      : v.networks.$each.$iter[index].virtualIp.new.$invalid
                      ? t("DATANET_MISSING_VALUE")
                      : "-"
                  }}
                </telia-p>
              </span>
            </SummaryColumn>
            <SummaryColumn v-if="!isChangeOrder" />
            <SummaryColumn variant="small" class="align-end actions">
              <div class="list-button-wrapper">
                <telia-button
                  class="summary-action"
                  size="sm"
                  variant="text"
                  :right-icon="JSON.stringify({ name: 'edit', size: 'sm' })"
                  @click.prevent="showNetworkModal(index)"
                />
                <telia-button
                  v-if="isPrimaryAccessVpn(lan.vpn)"
                  class="summary-action"
                  size="sm"
                  variant="text"
                  :right-icon="JSON.stringify({ name: 'close', size: 'sm' })"
                  @click.prevent="
                    removeNetwork({
                      lanKey: lan.key,
                      index,
                    })
                  "
                />
              </div>
            </SummaryColumn>
          </SummaryRow>
        </SummaryList>

        <telia-button
          v-if="isPrimaryAccessVpn(lan.vpn)"
          t-id="add-new-lan-network-config"
          :text="addLanNetworkText"
          :left-icon="JSON.stringify({ name: 'plus', size: 'sm' })"
          variant="text"
          @click="showNetworkModal()"
        />

        <telia-p
          v-if="shouldShowNetworksError"
          class="additional-text-warning"
          variant="additional-100"
          v-text="t('DATANET_VPN_LAN_AT_LEAST_ONE_NETWORK_REQUIRED')"
        />
        <Modal
          v-if="isLanNetworkModalActive"
          modal-id="lan-network-modal"
          width="s"
          :is-open="isLanNetworkModalActive"
          @closeModal="closeNetworkModal()"
        >
          <div slot="modal-body" class="lan-networks-settings-wrapper">
            <LanNetworkSettings
              ref="lan-networks-settings"
              :initial-network="selectedNetwork"
              :lan-action="lan.action"
              :is-primary-lan="isPrimaryAccessVpn(lan.vpn)"
              @lan-network-data="handleLanNetworkData($event, selectedNetworkIndex)"
              @is-validating="isValidatingLanNetwork = $event"
            />
          </div>
          <div slot="button-footer" class="modal-footer">
            <telia-button
              t-id="save-lan-network-config"
              variant="expressive"
              :disabled="isValidatingLanNetwork"
              @click="$refs['lan-networks-settings'].getLanNetworkData()"
              :text="saveLanNetworkText"
            />
            <telia-button
              variant="secondary"
              @click="closeNetworkModal"
              v-text="t('DATANET_ABORT')"
            />
          </div>
        </Modal>
      </div>

      <!--DHCP RELAY-->
      <div
        v-if="
          shouldShowDhcpRelays(lan.vpn) && (isPrimaryAccessVpn(lan.vpn) || dhcpRelays.length > 0)
        "
        class="tooltip-wrapper"
      >
        <telia-p>
          <strong>{{ t("DATANET_VPN_DHCP_RELAY") }}</strong>
        </telia-p>
        <telia-icon
          class="tooltip-icon"
          name="question"
          size="sm"
          @click="handleTooltip('dhcp-relay')"
        />
      </div>
      <SummaryList v-if="dhcpRelays.length > 0">
        <SummaryRow variant="header">
          <SummaryColumn v-if="isChangeOrder">
            <telia-p>{{ t("DATANET_CURRENT_VALUE") }}</telia-p>
          </SummaryColumn>
          <SummaryColumn>
            <telia-p>{{ t("DATANET_NEW_VALUE") }}</telia-p>
          </SummaryColumn>
          <SummaryColumn variant="small" />
        </SummaryRow>
        <SummaryRow v-for="(dhcpRelay, index) in dhcpRelays" :key="`range-${index}`">
          <SummaryColumn v-if="isChangeOrder">
            <span
              :class="
                v.dhcpRelays.$each.$iter[index].$invalid &&
                !v.dhcpRelays.$each.$iter[index].validCurrentIp
                  ? 'additional-text-warning'
                  : ''
              "
            >
              <telia-p>
                {{
                  dhcpRelay.ipAddress.current
                    ? dhcpRelay.ipAddress.current
                    : v.dhcpRelays.$each.$iter[index].$invalid &&
                      !v.dhcpRelays.$each.$iter[index].validCurrentIp
                    ? t("DATANET_MISSING_VALUE")
                    : "-"
                }}
              </telia-p>
            </span>
          </SummaryColumn>
          <SummaryColumn>
            <span
              :class="
                v.dhcpRelays.$each.$iter[index].$invalid &&
                !v.dhcpRelays.$each.$iter[index].validNewIp
                  ? 'additional-text-warning'
                  : ''
              "
            >
              <telia-p>
                {{
                  dhcpRelay.ipAddress.new
                    ? dhcpRelay.ipAddress.new
                    : v.dhcpRelays.$each.$iter[index].$invalid &&
                      !v.dhcpRelays.$each.$iter[index].validNewIp
                    ? t("DATANET_MISSING_VALUE")
                    : "-"
                }}
              </telia-p>
            </span>
          </SummaryColumn>
          <SummaryColumn v-if="!isChangeOrder" />

          <SummaryColumn variant="small" class="align-end">
            <div class="list-button-wrapper">
              <telia-button
                v-if="isPrimaryAccessVpn(lan.vpn)"
                class="summary-action"
                href=""
                variant="text"
                :right-icon="JSON.stringify({ name: 'close', size: 'sm' })"
                @click.prevent="deleteDhcpRelay(dhcpRelay.key)"
              />
              <telia-button
                v-else
                class="summary-action"
                href=""
                variant="text"
                :right-icon="JSON.stringify({ name: 'edit', size: 'sm' })"
                @click.prevent="showDhcpModal(dhcpRelay.key)"
              />
            </div>
          </SummaryColumn>
        </SummaryRow>
      </SummaryList>

      <telia-button
        v-if="shouldShowDhcpRelays(lan.vpn) && isPrimaryAccessVpn(lan.vpn)"
        t-id="add-new-dhcp-relay-config"
        variant="text"
        :left-icon="JSON.stringify({ name: 'plus', size: 'sm' })"
        :text="addDhcpRelayText"
        @click="showDhcpModal()"
      />
      <Modal
        v-if="isDhcpRelayModalActive"
        modal-id="dhcp-relay-modal"
        width="s"
        :is-open="isDhcpRelayModalActive"
        @closeModal="closeDhcpModal()"
      >
        <div slot="modal-body" class="dhcp-relay-settings-wrapper">
          <DhcpRelaySettings
            ref="dhcp-relay-settings"
            :dhcp-relay-key="selectedDhcpRelayKey"
            :lan-action="lan.action"
            @dhcp-relay-data="handleDhcpRelayData($event)"
            @is-validating="isValidatingDhcp = $event"
          />
        </div>
        <div slot="button-footer" class="modal-footer">
          <telia-button
            t-id="save-dhcp-relay-config"
            variant="expressive"
            :disabled="isValidatingDhcp"
            @click="$refs['dhcp-relay-settings'].getDhcpRelayData()"
            :text="saveLanNetworkText"
          />
          <telia-button variant="secondary" @click="closeDhcpModal()" v-text="t('DATANET_ABORT')" />
        </div>
      </Modal>
    </template>
  </div>
</template>

<script>
import { corpValidationService } from "@telia/b2b-rest-client";
import { mapState, mapGetters, mapActions } from "vuex";
import { Actions } from "../../constants";
import { translateMixin } from "../../locale";
import LanNetworkSettings from "./LanNetworkSettings";
import ActionSelection from "../ActionSelection";
import SummaryList from "../summary/SummaryList";
import SummaryRow from "../summary/SummaryRow";
import SummaryColumn from "../summary/SummaryColumn";
import DhcpRelaySettings from "./DhcpRelaySettings";
import InformationBox from "../InformationBox.vue";
import LanTooltip from "../tooltip-information/vpn-settings/LanTooltip.vue";
import DhcpRelayTooltip from "../tooltip-information/vpn-settings/DhcpRelayTooltip.vue";
import ValidationError from "../ValidationError.vue";
import Modal from "../Modal.vue";

export default {
  name: "Lan",
  mixins: [translateMixin],
  components: {
    ActionSelection,
    LanNetworkSettings,
    DhcpRelaySettings,
    SummaryList,
    SummaryRow,
    SummaryColumn,
    InformationBox,
    LanTooltip,
    DhcpRelayTooltip,
    ValidationError,
    Modal,
  },

  props: {
    lan: {
      type: Object,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
    activeTooltip: {
      type: String,
    },
  },

  data() {
    return {
      isValidatingLanNetwork: false,
      selectedNetworkIndex: undefined,
      selectedDhcpRelayKey: "",
      isValidatingDhcp: false,
      isLanNetworkModalActive: false,
      isDhcpRelayModalActive: false,
    };
  },

  computed: {
    ...mapState({
      scopeId: (state) => state.user.scopeId,
    }),
    ...mapGetters(["isChangeOrder"]),

    ...mapGetters("lans", ["shouldShowVirtualIp", "shouldShowDhcpRelays"]),

    ...mapGetters("ips", ["getIpsByKeys", "getIp"]),

    ...mapGetters("vpn", [
      "getVpn",
      "isPrimaryAccessVpn",
      "isSecondaryAccessVpn",
      "isLogicalInterface",
      "isPhysicalInterface",
    ]),

    dhcpRelays() {
      return this.getIpsByKeys(this.lan.dhcpRelays);
    },

    dhcpRelayValidationObjects() {
      return Object.values(this.v.ips.$each.$iter).filter((validationObject) =>
        this.lan.dhcpRelays.includes(validationObject.$model.key)
      );
    },

    shouldShowIdentifyingNetwork() {
      return this.isPhysicalInterface && this.shouldNotAddLan;
    },

    shouldShowCurrentVlanId() {
      return this.shouldNotAddLan;
    },

    shouldShowNewVlanId() {
      if (this.lan.action === Actions.ADD) return true;
      return this.shouldNotDeleteLan && this.lan.vlanId.action === Actions.UPDATE;
    },

    shouldShowVlanIdAction() {
      return this.lan.action === Actions.UPDATE;
    },

    shouldNotAddLan() {
      return this.lan.action !== Actions.ADD;
    },

    shouldNotDeleteLan() {
      return this.lan.action !== Actions.DELETE;
    },

    disabledActions() {
      const { action } = this.getVpn(this.lan.vpn);
      return action === Actions.ADD ? [Actions.UPDATE, Actions.DELETE] : [];
    },

    identifyingNetworkErrorText() {
      if (!this.v.identifyingNetwork.input.new.required) {
        return this.t("DATANET_NETWORK_REQUIRED");
      }

      if (!this.v.identifyingNetwork.ipAddress.new.required) {
        return this.t("DATANET_NETWORK_REQUIRED");
      }
      return this.t("DATANET_UNKNOWN_ERROR");
    },

    newVlanIdLabel() {
      return this.isChangeOrder ? this.t("DATANET_NEW_VALUE") : "";
    },

    vlanIdCheckboxValue() {
      return this.lan.vlanId.action === Actions.UPDATE ? Actions.NONE : Actions.UPDATE;
    },

    isVlanIdCheckboxChecked() {
      return this.vlanIdCheckboxValue === Actions.NONE;
    },

    isAnyVlanIdInvalid() {
      return this.v.vlanId.current.$error || this.v.vlanId.new.$error;
    },

    shouldShowNetworksError() {
      return (
        this.isPrimaryAccessVpn(this.lan.vpn) &&
        this.v.networks.$error &&
        !this.v.networks.hasAtLeastOne
      );
    },

    selectedNetwork() {
      return this.lan.networks[this.selectedNetworkIndex];
    },

    addLanNetworkText() {
      return this.isChangeOrder
        ? this.t("DATANET_VPN_LAN_ADD_NETWORK_CHANGE")
        : this.t("DATANET_VPN_LAN_ADD_NETWORK");
    },

    saveLanNetworkText() {
      if (this.isValidatingLanData) {
        return this.t("DATANET_VALIDATING");
      }

      return this.isChangeOrder ? this.t("DATANET_SAVE_CHANGES") : this.t("DATANET_ADD");
    },

    addDhcpRelayText() {
      return this.isChangeOrder
        ? this.t("DATANET_VPN_ADD_DHCP_RELAY_CHANGE")
        : this.t("DATANET_VPN_ADD_RELAY");
    },
  },

  methods: {
    ...mapActions("lans", [
      "setAction",
      "setVlanId",
      "setVlanIdAction",
      "setNetworkAction",
      "setNetworkInput",
      "setNetworkIpVersion",
      "setNetworkIpAddress",
      "setNetworkIpPrefix",
      "setNetworkVirtualIp",
      "setIdentifyingNetworkInput",
      "setIdentifyingNetworkIpVersion",
      "setIdentifyingNetworkIpAddress",
      "setIdentifyingNetworkIpPrefix",
      "removeNetwork",
      "addNetwork",
      "addDhcpRelay",
      "removeDhcpRelay",
    ]),

    ...mapActions("ips", ["addIp", "deleteIp", "setSibling", "setIp"]),

    handleActionSelection(action) {
      this.setAction({ lanKey: this.lan.key, action });
      this.v.$reset();
    },

    handleVlanIdActionSelection(action) {
      this.setVlanIdAction({
        lanKey: this.lan.key,
        action,
      });
      this.v.vlanId.$reset();
    },

    async handleIdentifyingNetworkInput(input, lanKey) {
      this.setIdentifyingNetworkInput({
        lanKey,
        new: input,
      });

      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        let data;
        try {
          const response = await corpValidationService.ValidationControllerService.validate(
            this.scopeId,
            {
              type: "NETWORK",
              value: input,
            }
          );
          if (!response) throw new Error("");
          data = await response;
        } catch (e) {
          this.setIdentifyingNetworkIpVersion({
            lanKey,
            new: "",
          });
          this.setIdentifyingNetworkIpAddress({
            lanKey,
            new: "",
          });
          this.setIdentifyingNetworkIpPrefix({
            lanKey,
            new: "",
          });
          return;
        }

        this.setIdentifyingNetworkIpVersion({
          lanKey,
          new: data.ipVersion,
        });
        this.setIdentifyingNetworkIpAddress({
          lanKey,
          new: data.network,
        });
        this.setIdentifyingNetworkIpPrefix({
          lanKey,
          new: data.prefix,
        });
      }, 400);
    },

    showNetworkModal(index) {
      this.isLanNetworkModalActive = true;
      this.selectedNetworkIndex = index;
    },

    closeNetworkModal() {
      this.isLanNetworkModalActive = false;
    },

    showDhcpModal(dhcpRelayKey = "") {
      this.isDhcpRelayModalActive = true;
      this.selectedDhcpRelayKey = dhcpRelayKey;
    },

    closeDhcpModal() {
      this.isDhcpRelayModalActive = false;
      this.selectedDhcpRelayKey = "";
    },

    async handleLanNetworkData(network, index) {
      if (!network) return;

      const lanKey = this.lan.key;

      if (index === undefined) {
        index = await this.addNetwork(lanKey);
      }

      this.setNetworkAction({ lanKey, index, action: network.action });
      this.setNetworkInput({
        lanKey,
        index,
        action: network.action,
        current: network.input.current,
        new: network.input.new,
      });
      this.setNetworkIpVersion({
        lanKey,
        index,
        action: network.action,
        current: network.ipVersion.current,
        new: network.ipVersion.new,
      });
      this.setNetworkIpAddress({
        lanKey,
        index,
        action: network.action,
        current: network.ipAddress.current,
        new: network.ipAddress.new,
      });
      this.setNetworkIpPrefix({
        lanKey,
        index,
        action: network.action,
        current: network.ipPrefix.current,
        new: network.ipPrefix.new,
      });
      if (network.virtualIp.action !== Actions.NONE) {
        this.setNetworkVirtualIp({
          lanKey,
          index,
          action: network.virtualIp.action,
          current: network.virtualIp.current,
          new: network.virtualIp.new,
        });
      }

      this.closeNetworkModal();
    },

    async handleDhcpRelayData(data) {
      if (!data) return;
      let dhcpRelayKey = this.selectedDhcpRelayKey;
      if (!dhcpRelayKey) {
        const { key: lanKey, siblingKey: siblingLanKey } = this.lan;
        dhcpRelayKey = await this.addIp({ ownerKey: lanKey, action: data.action });
        const siblingDhcpRelayKey = await this.addIp({
          ownerKey: siblingLanKey,
          action: data.action,
        });
        this.addDhcpRelay({ lanKey, dhcpRelayKey });
        this.addDhcpRelay({ lanKey: siblingLanKey, dhcpRelayKey: siblingDhcpRelayKey });
        this.setSibling({ key: dhcpRelayKey, siblingKey: siblingDhcpRelayKey });
      }
      this.setIp({
        key: dhcpRelayKey,
        action: data.action,
        ipAddress: data.ipAddress,
        ipVersion: data.ipVersion,
        resetSibling: this.isPrimaryAccessVpn(this.lan.vpn),
      });
      this.closeDhcpModal();
    },

    deleteDhcpRelay(dhcpRelayKey) {
      const { siblingKey: siblingDhcpRelayKey } = this.getIp(dhcpRelayKey);
      const { key: lanKey, siblingKey: siblingLanKey } = this.lan;
      this.removeDhcpRelay({
        lanKey,
        dhcpRelayKey,
      });
      this.removeDhcpRelay({
        lanKey: siblingLanKey,
        dhcpRelayKey: siblingDhcpRelayKey,
      });
      this.deleteIp(dhcpRelayKey);
      this.deleteIp(siblingDhcpRelayKey);
    },
    handleTooltip(value) {
      this.$emit("activeTooltip", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";

.inputs-container {
  display: flex;
}

.input-container {
  width: 100%;
  max-width: 32rem;
  margin-left: $telia-spacing-12;
}

.input-container:first-child {
  margin-left: 0;
}

.input-wrapper {
  width: 100%;
  max-width: 42rem;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.assistive-text {
  font-size: 13px;
}
.align-end {
  align-items: flex-end;
}
.list-button-wrapper {
  display: flex;
  padding-right: $telia-spacing-8;
}
.tooltip-wrapper {
  display: flex;
}
.tooltip-icon {
  position: relative;
  top: 0.5rem;
  margin-left: $telia-spacing-12;
}
.tooltip-icon:hover {
  color: $telia-purple-500;
  cursor: pointer;
}
.information-box-wrapper {
  position: relative;
  top: -14rem;
  z-index: 10;
}
.margin {
  &__bottom {
    &__4 {
      margin-bottom: $telia-spacing-4;
    }
    &__8 {
      margin-bottom: $telia-spacing-8;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
  &__top {
    &__12 {
      margin-top: $telia-spacing-12;
    }
  }
}
.summary-action {
  margin-top: 0.3rem;
}
.modal-footer {
  padding: 0 $telia-spacing-16 $telia-spacing-24 $telia-spacing-32;
}
.modal-footer > * {
  margin-right: $telia-spacing-12;
}
.additional-text {
  color: $telia-gray-500;
}
.additional-text-warning {
  color: $telia-red-500;
}
</style>
