var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pre-check-container" },
    [
      _c(
        "div",
        { staticClass: "address-master-container" },
        [
          _c("AddressMaster", {
            attrs: {
              "t-id": "address-input",
              "disable-submit": _vm.isFetchingProducts,
            },
            on: {
              addressSubmit: function ($event) {
                return _vm.handleAddressSubmit($event)
              },
              coordinateSubmit: function ($event) {
                return _vm.handleCoordinateSubmit($event)
              },
            },
          }),
        ],
        1
      ),
      _vm.alert.show
        ? _c(
            "telia-notification",
            {
              attrs: {
                "t-id": "pre-check-alert",
                "heading-tag": "h4",
                variant: "inline",
                status: _vm.alert.status,
                "html-aria-live": "polite",
                "html-role": "alert",
              },
            },
            [
              _c(
                "span",
                { attrs: { slot: "heading" }, slot: "heading" },
                [
                  _c("telia-visually-hidden", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.t("A11Y_STATUS_WARNING")) +
                        "\n      "
                    ),
                  ]),
                  _vm._v("\n      " + _vm._s(_vm.alert.title) + "\n    "),
                ],
                1
              ),
              _c(
                "span",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _c("telia-p", [
                    _vm._v("\n        " + _vm._s(_vm.alert.text) + "\n      "),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }