<template>
  <div id="information-box-container" class="information-box-container">
    <div id="test" class="information-box">
      <telia-icon class="information-box__close" name="close" @click="emitClose()" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { translateSetup, translateMixin } from "../locale";

export default {
  name: "InformationBox",
  mixins: [translateMixin],
  props: {
    id: {
      type: String,
      default: "",
    },
  },

  async created() {
    await translateSetup(["mybusiness"]);
  },
  methods: {
    emitClose() {
      this.$emit("close", this.key);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/breakpoints/variables";
.information-box-container {
  position: relative;
}

.information-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 58rem;
  padding: $telia-spacing-32;
  background-color: $telia-gray-50;
  z-index: 10;

  @media screen and (min-width: $telia-breakpoint-xlarge) {
    right: -7rem;
  }
}

.information-box__close {
  position: absolute;
  top: 0;
  right: 0;
}

.information-box__close {
  position: absolute;
  top: $telia-spacing-24;
  right: $telia-spacing-24;
  width: 3.6rem;
  height: 3.6rem;
  padding: 0.6rem;
  fill: $telia-functional-black;
  transition: all 150ms ease;
}

.information-box__close:hover {
  fill: $telia-purple-500;
  cursor: pointer;
}

.information-box__close:active {
  fill: $telia-purple-700;
}

.information-box__close > svg {
  width: 2.4rem;
  height: 2.4rem;
}
</style>
