var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("telia-p", [_vm._v(_vm._s(_vm.t("DATANET_AVAILABILITY_LABEL")))]),
      _c(
        "div",
        { staticClass: "notification-wrapper" },
        [
          _c(
            "telia-notification",
            {
              attrs: {
                "heading-tag": "h4",
                variant: "inline",
                status: "information",
                "html-aria-live": "polite",
                "html-role": "alert",
              },
            },
            [
              _c(
                "span",
                { attrs: { slot: "heading" }, slot: "heading" },
                [
                  _c("telia-visually-hidden", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.t("A11Y_STATUS_INFORMATION")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.t("DATANET_AVAILABILITY_SERVICE_TITLE")) +
                      "\n      "
                  ),
                ],
                1
              ),
              _c(
                "span",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _c("telia-p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.t("DATANET_AVAILABILITY_SERVICE_INFO")) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._l(_vm.products, function (product) {
        return _c("Product", {
          key: product.id,
          staticClass: "product-wrapper",
          attrs: { product: product, selectable: true },
          on: {
            "product-selected": function ($event) {
              return _vm.handleProductSelected($event)
            },
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }