const MetadataKeys = Object.freeze({
  ACCESS_BEARER: "ACCESS_BEARER",
  ACCESS_BANDWIDTH: "ACCESS_BANDWIDTH",
  ACCESS_QOS: "ACCESS_QOS",
  ACCESS_RT_TOT: "ACCESS_RT_TOT",
  ACCESS_SLA: "ACCESS_SLA",
  ADDRESS_DELIVERY: "ADDRESS_DELIVERY",
  ADDRESS_INSTALLATION: "ADDRESS_INSTALLATION",
  ADDRESS_SECONDARY: "SECONDARY_ADDRESS",
  APARTMENT_NUMBER: "APARTMENT_NUMBER",
  BANDWIDTH_DISTRIBUTION: "BANDWIDTH_DISTRIBUTION",
  CONTACT_PERSONS: "CONTACT_PERSONS",
  DHCP_RELAYS: "DHCP_RELAYS",
  DHCP_SERVERS: "DHCP_SERVERS",
  EXTENDED_AVAILABILITY: "EXTENDED_AVAILABILITY",
  INVOICE: "INVOICE",
  LANS: "LANS",
  LAN_PRIMARY_NETWORKS: "LAN_PRIMARY_NETWORKS",
  LAN_SECONDARY_NETWORKS: "LAN_SECONDARY_NETWORKS",
  LAN_VLAN_ID: "LAN_VLAN_ID",
  ON_PREMISE_INSTALLATION: "ON_PREMISE_INSTALLATION",
  ORG: "ORG",
  QUICK_LAUNCH: "QUICK_LAUNCH",
  SNMP: "SNMP",
  INTERNET_ON_SITE: "INTERNET_ON_SITE",
  STATIC_ROUTES: "STATIC_ROUTES",
  TRAFFIC_STATISTICS: "TRAFFIC_STATISTICS",
  VPN: "VPN",
  WIRELESS_ANTENNA: "WIRELESS_ANTENNA",
  WIRELESS_INSTALLATION: "WIRELESS_INSTALLATION",
  SECONDARY_ACCESS_BANDWIDTH: "SECONDARY_ACCESS_BANDWIDTH",
  SECONDARY_ACCESS_SLA: "SECONDARY_ACCESS_SLA",
  SECONDARY_ACCESS_QOS: "SECONDARY_ACCESS_QOS",
  SECONDARY_TRAFFIC_STATISTICS: "SECONDARY_TRAFFIC_STATISTICS",
  SECONDARY_ACCESS_WIRELESS_INSTALLATION: "SECONDARY_ACCESS_WIRELESS_INSTALLATION",
  SECONDARY_ACCESS_WIRELESS_ANTENNA: "SECONDARY_ACCESS_WIRELESS_ANTENNA",
  SECONDARY_ACCESS_RT_TOT: "SECONDARY_ACCESS_RT_TOT",
  SECONDARY_ACCESS_BANDWIDTH_DISTRIBUTION: "SECONDARY_ACCESS_BANDWIDTH_DISTRIBUTION",
});

export default MetadataKeys;
