import { MetadataKeys } from "../../constants";

const getDefaultState = () => {
  return getDefaultMetadata();
};

const getDefaultMetadata = () => {
  const metadata = {};

  Object.keys(MetadataKeys).forEach((key) => {
    metadata[key] = defaultMetadata();
  });

  return Object.assign({}, metadata);
};

const defaultMetadata = ({
  visible = true,
  actionEditable = true,
  editable = true,
  mandatory = false,
  max = null,
} = {}) => ({
  visible,
  editable,
  actionEditable,
  mandatory,
  max,
});

const state = getDefaultState();

const getters = {
  isVisible: (state) => (key = "") => {
    if (!key) return undefined;
    return state[key] ? state[key].visible : undefined;
  },

  isEditable: (state) => (key = "") => {
    if (!key) return undefined;
    return state[key] ? state[key].editable : undefined;
  },

  isActionEditable: (state) => (key = "") => {
    if (!key) return undefined;
    return state[key] ? state[key].actionEditable : undefined;
  },

  isMandatory: (state) => (key = "") => {
    if (!key) return undefined;
    return state[key] ? state[key].mandatory : undefined;
  },

  getMax: (state) => (key = "") => {
    if (!key) return undefined;
    return state[key] ? state[key].max : undefined;
  },
};

const actions = {
  setMetadata({ state, dispatch, commit }, newMetadata = {}) {
    const metadata = Object.assign({}, state);

    for (const metadataKey in newMetadata) {
      if (!Object.prototype.hasOwnProperty.call(metadata, metadataKey)) {
        continue;
      }

      for (const metadataProp in newMetadata[metadataKey]) {
        if (!Object.prototype.hasOwnProperty.call(metadata[metadataKey], metadataProp)) {
          continue;
        }
        metadata[metadataKey][metadataProp] = newMetadata[metadataKey][metadataProp];
      }
    }

    commit("SET_METADATA", metadata);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },
};

const mutations = {
  SET_METADATA(state, metadata) {
    Object.assign(state, metadata);
  },

  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
