<template>
  <div>
    <telia-heading tag="h4" variant="title-100" v-text="t('DATANET_INSTALLATION_INFORMATION')" />
    <telia-p class="margin-bottom" v-text="t('DATANET_INSTALLATION_INFORMATION_INFO')" />
    <telia-checkbox
      t-id="installation-by-telia"
      :disabled="disableInput"
      :value="!checked"
      :checked="checked"
      @change="handleInput"
    >
      {{ t("DATANET_WIRELESS_INSTALLATION_CHOICE") }}
    </telia-checkbox>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { translateMixin } from "../../locale";

export default {
  name: "WirelessInstallation",
  mixins: [translateMixin],
  props: {
    initialValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      checked: this.initialValue,
    };
  },

  computed: {
    ...mapGetters("access", [
      "shouldDisablePrimaryAccessWirelessInstallation",
      "shouldDisableSecondaryAccessWirelessInstallation",
    ]),

    disableInput() {
      if (this.accessKey === "secondary") {
        return this.disabled || this.shouldDisableSecondaryAccessWirelessInstallation;
      } else {
        return this.disabled || this.shouldDisablePrimaryAccessWirelessInstallation;
      }
    },
  },

  methods: {
    handleInput() {
      this.checked = !this.checked;
      this.$emit("request-installation-by-telia", this.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.margin-bottom {
  margin-bottom: $telia-spacing-12;
}
</style>
