<template>
  <div>
    <telia-heading tag="h4" variant="title-100">
      {{ t("DATANET_ANTENNA_OPTIONS") }}
    </telia-heading>
    <div class="margin__bottom__24">
      <telia-fieldset>
        <div class="chip-choice-wrapper">
          <telia-chip-choice
            t-id="antenna-package-1"
            :checked="antennaPackage === AntennaPackages.ANTENNA_PACKAGE_1"
            :disabled="antennaPackage !== AntennaPackages.ANTENNA_PACKAGE_1 && disableInput"
            @change="handleAntennaPackageSelection(AntennaPackages.ANTENNA_PACKAGE_1)"
          >
            <div class="chip-choice-body">
              <b>{{ t("DATANET_ANTENNA_PACKAGE_1") }}</b>
              <telia-p>{{ t("DATANET_ANTENNA_PACKAGE_1_DETAILED") }}</telia-p>
            </div>
          </telia-chip-choice>
          <telia-chip-choice
            id="antenna-package-2"
            t-id="antenna-package-2"
            :checked="antennaPackage === AntennaPackages.ANTENNA_PACKAGE_2"
            :disabled="antennaPackage !== AntennaPackages.ANTENNA_PACKAGE_2 && disableInput"
            @change="handleAntennaPackageSelection(AntennaPackages.ANTENNA_PACKAGE_2)"
          >
            <div class="chip-choice-body">
              <b>{{ t("DATANET_ANTENNA_PACKAGE_2") }}</b>
              <telia-p>{{ t("DATANET_ANTENNA_PACKAGE_2_DETAILED") }}</telia-p>
            </div>
          </telia-chip-choice>
          <telia-chip-choice
            id="antenna-package-none"
            :checked="antennaPackage === AntennaPackages.ANTENNA_PACKAGE_NONE"
            :disabled="disableInput"
            @change="handleAntennaPackageSelection(AntennaPackages.ANTENNA_PACKAGE_NONE)"
          >
            <div class="chip-choice-body">
              <b>{{ t("DATANET_ANTENNA_PACKAGE_NONE") }}</b>
              <telia-p>{{ t("DATANET_ANTENNA_PACKAGE_NONE_DETAILED") }}</telia-p>
            </div>
          </telia-chip-choice>
        </div>
      </telia-fieldset>
    </div>

    <div v-if="antennaPackage !== AntennaPackages.ANTENNA_PACKAGE_NONE" class="checkbox-wrapper">
      <telia-checkbox
        t-id="antenna-cable"
        :disabled="disableInput"
        :value="!antennaCable"
        :checked="antennaCable"
        @change="handleCableSelection($event.target.checked)"
      >
        {{ t("DATANET_ANTENNA_CABLE_INFO") }}
      </telia-checkbox>
      <telia-checkbox
        t-id="antenna-installation"
        :disabled="antennaPackage === 'ANTENNA_PACKAGE_2' || disableInput"
        :value="!antennaInstallation"
        :checked="antennaInstallation"
        @change="handleInstallationSelection($event.target.checked)"
      >
        {{ t("DATANET_ANTENNA_INSTALLATION_INFO") }}
      </telia-checkbox>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AntennaPackages } from "../../constants";
import { translateMixin } from "../../locale";

export default {
  name: "AntennaPackage",
  mixins: [translateMixin],
  props: {
    accessKey: {
      type: String,
      required: true,
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      AntennaPackages,
      antennaPackage: this.$store.state.access[this.accessKey].options.antenna.package,
      antennaCable: this.$store.state.access[this.accessKey].options.antenna.cable,
      antennaInstallation: this.$store.state.access[this.accessKey].options.antenna.installation,
    };
  },

  computed: {
    ...mapGetters("access", [
      "shouldDisablePrimaryAccessAntennaPackage",
      "shouldDisableSecondaryAccessAntennaPackage",
    ]),

    disableInput() {
      if (this.accessKey === "secondary") {
        return this.disable || this.shouldDisableSecondaryAccessAntennaPackage;
      } else {
        return this.disable || this.shouldDisablePrimaryAccessAntennaPackage;
      }
    },
  },

  methods: {
    handleAntennaPackageSelection(value) {
      this.antennaPackage = value;

      if (value === AntennaPackages.ANTENNA_PACKAGE_1) {
        this.antennaCable = false;
        this.antennaInstallation = false;
      }

      if (value === AntennaPackages.ANTENNA_PACKAGE_2) {
        this.antennaCable = false;
        this.antennaInstallation = true;
      }

      if (value === AntennaPackages.ANTENNA_PACKAGE_NONE) {
        this.antennaCable = false;
        this.antennaInstallation = false;
      }

      this.emitData();
    },

    handleCableSelection(value) {
      this.antennaCable = value;
      this.emitData();
    },

    handleInstallationSelection(value) {
      this.antennaInstallation = value;
      this.emitData();
    },

    emitData() {
      this.$emit("antenna-package-data", {
        antennaPackage: this.antennaPackage,
        antennaCable: this.antennaCable,
        antennaInstallation: this.antennaInstallation,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.checkbox-wrapper {
  margin-top: $telia-spacing-20;
}
.checkbox-wrapper > * {
  margin-bottom: $telia-spacing-12;
}
.chip-choice-body {
  display: block;
}
.chip-choice-wrapper > * {
  margin-right: $telia-spacing-12;
}
.margin {
  &__bottom {
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
}
</style>
