<template>
  <div>
    <telia-heading tag="h4" variant="title-100" v-text="t('DATANET_TRAFFIC_STATISTICS')" />
    <telia-p v-text="t('DATANET_TRAFFIC_STATISTICS_INFO')" />
    <div class="checkbox-wrapper">
      <telia-checkbox
        t-id="stat-bas"
        :value="!checked"
        :checked="checked"
        :disabled="disableInput"
        @change="handleInput($event.target.checked)"
      >
        {{ t("DATANET_STAT_BAS") }}
      </telia-checkbox>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { translateMixin } from "../../locale";

export default {
  name: "StatBas",
  mixins: [translateMixin],
  props: {
    accessKey: {
      type: String,
      required: true,
    },
    initialValue: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      checked: this.initialValue,
    };
  },

  computed: {
    ...mapGetters("access", [
      "shouldDisablePrimaryAccessTrafficStatistics",
      "shouldDisableSecondaryAccessTrafficStatistics",
    ]),

    disableInput() {
      if (this.accessKey === "secondary") {
        return this.shouldDisableSecondaryAccessTrafficStatistics;
      } else {
        return this.shouldDisablePrimaryAccessTrafficStatistics;
      }
    },
  },

  methods: {
    handleInput(value) {
      this.checked = value;
      this.$emit("request-stat-bas", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.checkbox-wrapper {
  margin: $telia-spacing-12 0;
}
</style>
