<template>
  <div class="static-route-tooltip">
    <div v-if="showTooltipById === 'route-network'">
      <telia-heading tag="h5" variant="title-100">
        {{ t("DATANET_GUIDANCE_STATIC_ROUTE_NETWORK_TITLE") }}
      </telia-heading>
      <telia-p>
        {{ t("DATANET_GUIDANCE_STATIC_ROUTE_NETWORK_INFO") }}
      </telia-p>
    </div>
    <div v-if="showTooltipById === 'next-hop'">
      <telia-heading tag="h5" variant="title-100">
        {{ t("DATANET_GUIDANCE_STATIC_ROUTE_NEXT_HOP_TITLE") }}
      </telia-heading>
      <telia-p>
        {{ t("DATANET_GUIDANCE_STATIC_ROUTE_NEXT_HOP_INFO") }}
      </telia-p>
    </div>
  </div>
</template>
<script>
import { translateMixin } from "../../../locale";
export default {
  name: "RtTotTooltip",
  mixins: [translateMixin],
  props: {
    showTooltipById: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.margin {
  &__bottom {
    &__4 {
      margin-bottom: $telia-spacing-4;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
  &__top {
    &__12 {
      margin-top: $telia-spacing-12;
    }
  }
  &__left {
    &__48 {
      margin-left: $telia-spacing-48;
    }
  }
}
</style>
