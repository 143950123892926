<template>
  <div>
    <FastTrack class="margin__bottom__32" @enable-fast-track="fastTrack = $event" />
    <div class="margin__bottom__12">
      <telia-heading tag="h4" variant="title-100">
        {{ t("DATANET_TEMPORARY_ACCESS_SETTINGS") }}
      </telia-heading>
    </div>
    <div class="margin__bottom__12">
      <telia-p>
        <strong>{{ t("DATANET_ACCESS_TYPE") }}</strong>
        <br />
        {{ t(`DATANET_${bearer}`) }}
      </telia-p>
    </div>
    <div class="margin__bottom__12">
      <telia-p>
        <strong>{{ t("DATANET_SPEED") }}</strong>
        <br />
        {{ t("DATANET_BEST_EFFORT") }}
      </telia-p>
    </div>
    <div class="margin__bottom__24 input-wrapper">
      <telia-select
        id="temporary-access-sla"
        t-id="temporary-access-sla"
        ref="sla"
        :label="t('DATANET_SLA')"
        :value="sla"
        :options="JSON.stringify(slaOptions)"
        @change="sla = $event.target.value"
      />
      <ValidationError v-if="$v.sla.$error"> {{ t("DATANET_SLA_REQUIRED") }} </ValidationError>
    </div>
    <div class="margin__bottom__32 input-wrapper">
      <telia-select
        id="temporary-access-qos"
        t-id="temporary-access-qos"
        ref="qos"
        :label="t('DATANET_QOS')"
        :value="qos"
        :options="JSON.stringify(qosOptions)"
        @change="qos = $event.target.value"
      />
      <ValidationError v-if="$v.qos.$error"> {{ t("DATANET_QOS_REQUIRED") }} </ValidationError>
    </div>
    <WirelessInstallation
      class="margin__bottom__32"
      id="request-installation-by-telia-for-temporary-access"
      :initial-value="wirelessInstallation"
      @request-installation-by-telia="wirelessInstallation = $event"
    />
    <AntennaPackage
      class="margin__bottom__32"
      access-key="temporary"
      @antenna-package-data="handleAntennaPackageData($event)"
    />

    <div class="margin__bottom__32">
      <telia-heading tag="h4" variant="title-100">
        {{ t("DATANET_AFTER_DELIVERY_INFORMATION") }}
      </telia-heading>
      <telia-fieldset>
        <telia-radio-button
          v-for="option in afterDeliveryOptions"
          :key="option.value"
          id="after-installation-options"
          t-id="after-installation-options"
          name="after-installation-options"
          :label="option.label"
          :checked="afterDeliveryOption === option.value"
          @change="afterDeliveryOption = option.value"
        />
      </telia-fieldset>

      <ValidationError v-if="$v.afterDeliveryOption.$error">
        {{ t("DATANET_MUST_SELECT_AFTER_DELIVERY_CHOICE") }}
      </ValidationError>
    </div>
    <div class="button-wrapper">
      <telia-button
        id="save-temporary-access-configuration"
        t-id="save-temporary-access-configuration"
        variant="expressive"
        type="button"
        @click="saveAndClose()"
      >
        {{ t("DATANET_SAVE_CHANGES") }}
      </telia-button>

      <telia-button
        id="cancel-temporary-access-configuration"
        type="button"
        variant="secondary"
        @click="$emit('complete')"
      >
        {{ t("DATANET_ABORT") }}
      </telia-button>
    </div>
  </div>
</template>

<script>
import { translateMixin } from "../../locale";
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { Accesses } from "../../constants";
import FastTrack from "./FastTrack";
import AntennaPackage from "./AntennaPackage";
import WirelessInstallation from "./WirelessInstallation";
import ValidationError from "../ValidationError";

export default {
  name: "TemporaryAccessSettings",
  mixins: [translateMixin, validationMixin],
  components: {
    AntennaPackage,
    FastTrack,
    WirelessInstallation,
    ValidationError,
  },

  data() {
    return {
      afterDeliveryOptions: [
        {
          value: true,
          label: this.t("DATANET_USE_TEMPORARY_ACCESS_AS_BACKUP"),
        },
        {
          value: false,
          label: this.t("DATANET_TERMINATE"),
        },
      ],
      fastTrack: this.$store.state.access[Accesses.TEMPORARY].fastTrack,
      wirelessInstallation: this.$store.state.access[Accesses.TEMPORARY].options
        .wirelessInstallation,
      antennaPackage: this.$store.state.access[Accesses.TEMPORARY].options.antenna.package,
      antennaCable: this.$store.state.access[Accesses.TEMPORARY].options.antenna.cable,
      antennaInstallation: this.$store.state.access[Accesses.TEMPORARY].options.antenna
        .installation,
      bearer: this.$store.state.access[Accesses.TEMPORARY].bearer,
      bandwidth: this.$store.state.access[Accesses.TEMPORARY].bandwidth.new,
      sla: this.$store.state.access[Accesses.TEMPORARY].sla.new,
      qos: this.$store.state.access[Accesses.TEMPORARY].qos.new,
      afterDeliveryOption: this.$store.state.access[Accesses.TEMPORARY].useAsSecondaryAccess,
    };
  },

  computed: {
    ...mapGetters("products", ["getQosOptions", "getSlaOptions"]),

    slaOptions() {
      return this.getSlaOptions(this.bearer).map((option) => {
        return {
          value: option.value,
          label: option.label,
          selected: option.value === this.sla,
          disabled: option.disabled,
        };
      });
    },

    qosOptions() {
      return this.getQosOptions(this.bearer, this.bandwidth).map((option) => {
        return {
          value: option.value,
          label: option.label,
          selected: option.value === this.qos,
          disabled: option.disabled,
        };
      });
    },
  },

  validations: {
    sla: {
      required,
    },
    qos: {
      required,
    },
    afterDeliveryOption: {
      required,
    },
  },

  methods: {
    ...mapActions("access", [
      "setBandwidth",
      "setSla",
      "setQos",
      "setAntennaPackage",
      "setAntennaCable",
      "setAntennaInstallation",
      "enableWirelessInstallation",
      "disableWirelessInstallation",
      "enableFastTrack",
      "disableFastTrack",
      "useTemporaryAccessAsSecondary",
      "terminateTemporaryAccessOnDeliveryOfPrimary",
      "enableAccess",
    ]),

    ...mapActions("addresses", ["setInstallationRoomInfo"]),

    ...mapActions("apartments", ["setApartment"]),

    ...mapActions("products", {
      setBearer: "setSecondaryBearer",
    }),

    handleAntennaPackageData(data) {
      this.antennaPackage = data.antennaPackage;
      this.antennaCable = data.antennaCable;
      this.antennaInstallation = data.antennaInstallation;
    },

    saveAndClose() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.afterDeliveryOption
        ? this.useTemporaryAccessAsSecondary()
        : this.terminateTemporaryAccessOnDeliveryOfPrimary();

      this.fastTrack ? this.enableFastTrack() : this.disableFastTrack();

      this.wirelessInstallation
        ? this.enableWirelessInstallation(Accesses.TEMPORARY)
        : this.disableWirelessInstallation(Accesses.TEMPORARY);

      this.setAntennaPackage({
        accessKey: Accesses.TEMPORARY,
        name: this.antennaPackage,
      });

      this.setAntennaCable({
        accessKey: Accesses.TEMPORARY,
        boolean: this.antennaCable,
      });

      this.setAntennaInstallation({
        accessKey: Accesses.TEMPORARY,
        boolean: this.antennaInstallation,
      });

      this.setBandwidth({
        accessKey: Accesses.TEMPORARY,
        new: this.bandwidth,
      });

      this.setSla({
        accessKey: Accesses.TEMPORARY,
        new: this.sla,
      });

      this.setQos({
        accessKey: Accesses.TEMPORARY,
        new: this.qos,
      });

      this.enableAccess(Accesses.TEMPORARY);

      this.$emit("complete");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.input-wrapper {
  max-width: 42rem;
}

.is-block > div {
  display: block;
}

.margin {
  &__bottom {
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
    &__32 {
      margin-bottom: $telia-spacing-32;
    }
  }
}
.button-wrapper {
  padding-top: $telia-spacing-12;
}
.button-wrapper > * {
  margin-right: $telia-spacing-8;
}
</style>
