var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "t-id": "multi-vpn" } },
    [
      _c("telia-heading", { attrs: { tag: "h4", variant: "title-100" } }, [
        _vm._v("\n    " + _vm._s(_vm.t("DATANET_VPN_MULTI_VPN")) + "\n  "),
      ]),
      _c("telia-fieldset", [
        _c(
          "div",
          { staticClass: "chip-choice-wrapper" },
          _vm._l(_vm.getMultiVpnOptions, function (option) {
            return _c(
              "telia-chip-choice",
              {
                key: option.value,
                attrs: {
                  value: option.value,
                  checked: _vm.multiVpn === option.value,
                  disabled: option.disabled,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleInput(option.value)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(option.label) + "\n      ")]
            )
          }),
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }