var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("telia-heading", {
        attrs: { tag: "h4", variant: "title-100" },
        domProps: { textContent: _vm._s(_vm.t("DATANET_TRAFFIC_STATISTICS")) },
      }),
      _c("telia-p", {
        domProps: {
          textContent: _vm._s(_vm.t("DATANET_TRAFFIC_STATISTICS_INFO")),
        },
      }),
      _c(
        "div",
        { staticClass: "checkbox-wrapper" },
        [
          _c(
            "telia-checkbox",
            {
              attrs: {
                "t-id": "stat-bas",
                value: !_vm.checked,
                checked: _vm.checked,
                disabled: _vm.disableInput,
              },
              on: {
                change: function ($event) {
                  return _vm.handleInput($event.target.checked)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.t("DATANET_STAT_BAS")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }