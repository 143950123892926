var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SummaryList",
    [
      _c(
        "SummaryRow",
        { attrs: { variant: "header" } },
        [
          _c(
            "SummaryColumn",
            { staticClass: "summary-column--small" },
            [_c("telia-p", [_vm._v(_vm._s(_vm.t("DATANET_VPN")))])],
            1
          ),
          _c(
            "SummaryColumn",
            { staticClass: "summary-column--large" },
            [
              _c(
                "SummaryRow",
                {
                  staticClass: "summary-row--nested",
                  attrs: { variant: "header" },
                },
                [
                  _c(
                    "SummaryColumn",
                    [_c("telia-p", [_vm._v(_vm._s(_vm.t("DATANET_SETTING")))])],
                    1
                  ),
                  _c("SummaryColumn", [_vm._v("   ")]),
                  _c(
                    "SummaryColumn",
                    [
                      _c("telia-p", [
                        _vm._v(_vm._s(_vm.t("DATANET_CURRENT_VALUE"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "SummaryColumn",
                    [
                      _c("telia-p", [
                        _vm._v(_vm._s(_vm.t("DATANET_NEW_VALUE"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.primaryAccessVpns, function (vpn, vpnIndex) {
        return _c(
          "SummaryRow",
          { key: vpn.key },
          [
            _c(
              "SummaryColumn",
              {
                staticClass: "summary-column--small",
                attrs: { variant: "align-top" },
              },
              [
                _c("telia-p", [
                  _c("strong", [_vm._v(_vm._s("VPN " + (vpnIndex + 1)))]),
                ]),
              ],
              1
            ),
            _c(
              "SummaryColumn",
              {
                staticClass: "summary-column--large",
                attrs: { variant: "align-top" },
              },
              [
                _c(
                  "SummaryRow",
                  { staticClass: "summary-row--nested" },
                  [
                    _c(
                      "SummaryColumn",
                      {
                        class:
                          vpn.action === _vm.Actions.DELETE
                            ? "field-value--deleted"
                            : "",
                        attrs: { variant: "align-top" },
                      },
                      [
                        _c("telia-p", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                vpn.action === _vm.Actions.DELETE
                                  ? _vm.t("DATANET_ACTION_DELETE") +
                                      " " +
                                      _vm.t("DATANET_VPN")
                                  : _vm.t("DATANET_VPN_BASE_SETTINGS")
                              ) +
                              "\n          "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("SummaryColumn", { attrs: { variant: "align-top" } }, [
                      _c(
                        "div",
                        { staticClass: "field-name" },
                        [
                          _c("telia-p", [
                            _vm._v(_vm._s(_vm.t("DATANET_VPN_ID"))),
                          ]),
                        ],
                        1
                      ),
                      _vm.isMultiVpn &&
                      vpn.action !== _vm.Actions.DELETE &&
                      (_vm.vpnBandwidths[vpnIndex].current ||
                        _vm.vpnBandwidths[vpnIndex].new)
                        ? _c(
                            "div",
                            { staticClass: "field-name" },
                            [
                              _c("telia-p", [
                                _vm._v(
                                  " " + _vm._s(_vm.t("DATANET_VPN_BANDWIDTH"))
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isMultiVpn &&
                      vpn.action !== _vm.Actions.DELETE &&
                      _vm.isStaticBandwidthDistribution(vpn.accessKey)
                        ? _c(
                            "div",
                            { staticClass: "field-name" },
                            [
                              _c("telia-p", [
                                _vm._v(_vm._s(_vm.t("DATANET_QOS"))),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c("SummaryColumn", { attrs: { variant: "align-top" } }, [
                      _c(
                        "div",
                        [_c("telia-p", [_vm._v(_vm._s(vpn.id || "-"))])],
                        1
                      ),
                      _vm.isMultiVpn &&
                      vpn.action !== _vm.Actions.DELETE &&
                      (_vm.vpnBandwidths[vpnIndex].current ||
                        _vm.vpnBandwidths[vpnIndex].new)
                        ? _c(
                            "div",
                            [
                              _c("telia-p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.vpnBandwidths[vpnIndex].current || "-"
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isMultiVpn &&
                      vpn.action !== _vm.Actions.DELETE &&
                      _vm.isStaticBandwidthDistribution(vpn.accessKey)
                        ? _c(
                            "div",
                            [
                              _c("telia-p", [
                                _vm._v(
                                  _vm._s(
                                    vpn.qos.current
                                      ? _vm.t(
                                          "DATANET_" +
                                            vpn.qos.current +
                                            "_DESCRIPTION"
                                        )
                                      : "-"
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c("SummaryColumn", { attrs: { variant: "align-top" } }, [
                      _c("div", [_vm._v(" ")]),
                      _vm.isMultiVpn &&
                      vpn.action !== _vm.Actions.DELETE &&
                      (_vm.vpnBandwidths[vpnIndex].current ||
                        _vm.vpnBandwidths[vpnIndex].new)
                        ? _c(
                            "div",
                            [
                              _c("telia-p", [
                                _vm._v(
                                  _vm._s(_vm.vpnBandwidths[vpnIndex].new || "-")
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isMultiVpn &&
                      vpn.action !== _vm.Actions.DELETE &&
                      _vm.isStaticBandwidthDistribution(vpn.accessKey)
                        ? _c(
                            "div",
                            [
                              _c("telia-p", [
                                _vm._v(
                                  _vm._s(
                                    vpn.qos.new
                                      ? _vm.t(
                                          "DATANET_" +
                                            vpn.qos.new +
                                            "_DESCRIPTION"
                                        )
                                      : "-"
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _vm._l(_vm.getLansByKeys(vpn.lans), function (lan) {
                  return _c(
                    "SummaryRow",
                    { key: lan.key, staticClass: "summary-row--nested" },
                    [
                      _c(
                        "SummaryColumn",
                        {
                          staticClass: "field-value--changed",
                          attrs: { variant: "align-top" },
                        },
                        [
                          _c("telia-p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.t("DATANET_ACTION_" + lan.action) +
                                    " " +
                                    _vm.t("DATANET_VPN_LAN")
                                )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "SummaryColumn",
                        { attrs: { variant: "align-top" } },
                        [
                          _vm.isLogicalInterface
                            ? _c(
                                "div",
                                { staticClass: "field-name" },
                                [
                                  _c("telia-p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.t("DATANET_VPN_LAN_VLAN_ID"))
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isPhysicalInterface
                            ? _c(
                                "div",
                                { staticClass: "field-name" },
                                [
                                  _c("telia-p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.t(
                                            "DATANET_VPN_LAN_IDENTIFYING_NETWORK"
                                          )
                                        )
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(
                            lan.networks,
                            function (network, networkIndex) {
                              return _c(
                                "div",
                                {
                                  key:
                                    "lan-" +
                                    lan.key +
                                    "-network-" +
                                    networkIndex +
                                    "-title",
                                  staticClass: "field-name",
                                },
                                [
                                  _c("telia-p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.t("DATANET_VPN_LAN_NETWORK"))
                                    ),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                          _vm._l(lan.dhcpRelays, function (relay, relayIndex) {
                            return _c(
                              "div",
                              {
                                key:
                                  "lan-" +
                                  lan.key +
                                  "-dhcp-relay-" +
                                  relayIndex +
                                  "-title",
                                staticClass: "field-name",
                              },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.t("DATANET_VPN_DHCP_RELAY"))
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "SummaryColumn",
                        { attrs: { variant: "align-top" } },
                        [
                          _vm.isLogicalInterface
                            ? _c(
                                "div",
                                [
                                  _c("telia-p", [
                                    _vm._v(
                                      " " + _vm._s(lan.vlanId.current || "-")
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isPhysicalInterface
                            ? _c(
                                "div",
                                { staticClass: "field-name" },
                                [
                                  _c("telia-p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          lan.identifyingNetwork.input.new ||
                                            "-"
                                        )
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(
                            lan.networks,
                            function (network, networkIndex) {
                              return _c(
                                "div",
                                {
                                  key:
                                    "lan-" +
                                    lan.key +
                                    "-network-" +
                                    networkIndex +
                                    "-current",
                                },
                                [
                                  _c("telia-p", [
                                    _vm._v(
                                      " " + _vm._s(network.input.current || "-")
                                    ),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                          _vm._l(lan.dhcpRelays, function (relay, relayIndex) {
                            return _c(
                              "div",
                              {
                                key:
                                  "lan-" +
                                  lan.key +
                                  "-dhcp-relay-" +
                                  relayIndex +
                                  "-current",
                              },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ips[relay].ipAddress.current || "-"
                                      )
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "SummaryColumn",
                        { attrs: { variant: "align-top" } },
                        [
                          _vm.isLogicalInterface
                            ? _c(
                                "div",
                                {
                                  class: lan.vlanId.new
                                    ? "field-value--changed"
                                    : "",
                                },
                                [
                                  _c("telia-p", [
                                    _vm._v(" " + _vm._s(lan.vlanId.new || "-")),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(
                            lan.networks,
                            function (network, networkIndex) {
                              return _c(
                                "div",
                                {
                                  key:
                                    "lan-" +
                                    lan.key +
                                    "-network-" +
                                    networkIndex +
                                    "-new",
                                  class: network.input.new
                                    ? "field-value--changed"
                                    : "",
                                },
                                [
                                  _c("telia-p", [
                                    _vm._v(
                                      " " + _vm._s(network.input.new || "-")
                                    ),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                          _vm._l(lan.dhcpRelays, function (relay, relayIndex) {
                            return _c(
                              "div",
                              {
                                key:
                                  "lan-" +
                                  lan.key +
                                  "-dhcp-relay-" +
                                  relayIndex +
                                  "-new",
                                class: _vm.ips[relay].ipAddress.new
                                  ? "field-value--changed"
                                  : "",
                              },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.ips[relay].ipAddress.new || "-"
                                      )
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                _vm._l(
                  _vm.getServersByKeys(vpn.dhcpServers),
                  function (server) {
                    return _c(
                      "SummaryRow",
                      { key: server.key, staticClass: "summary-row--nested" },
                      [
                        _c(
                          "SummaryColumn",
                          {
                            staticClass: "field-value--changed",
                            attrs: { variant: "align-top" },
                          },
                          [
                            _c("telia-p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.t("DATANET_ACTION_" + server.action) +
                                      " " +
                                      _vm.t("DATANET_VPN_DHCP_SERVER")
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "SummaryColumn",
                          { attrs: { variant: "align-top" } },
                          [
                            _c(
                              "div",
                              { staticClass: "field-name" },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.t("DATANET_VPN_DHCP_SERVER_NETWORK")
                                      )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._l(
                              server.excludedIpRanges.values,
                              function (ipRange, ipRangeIndex) {
                                return _c(
                                  "div",
                                  {
                                    key:
                                      "dhcp-server-" +
                                      server.key +
                                      "-ip-range-" +
                                      ipRangeIndex +
                                      "-title",
                                    staticClass: "field-name",
                                  },
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.t(
                                              "DATANET_VPN_DHCP_SERVER_EXCLUDED_IP_RANGE"
                                            ) +
                                              " " +
                                              (ipRangeIndex + 1)
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._l(
                              server.dnsIps.values,
                              function (dnsIp, dnsIpIndex) {
                                return _c(
                                  "div",
                                  {
                                    key:
                                      "dhcp-server-" +
                                      server.key +
                                      "-dns-ip-" +
                                      dnsIpIndex +
                                      "-title",
                                    staticClass: "field-name",
                                  },
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.t("DATANET_VPN_DHCP_DNS_IP") +
                                              " " +
                                              (dnsIpIndex + 1)
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c(
                          "SummaryColumn",
                          { attrs: { variant: "align-top" } },
                          [
                            _c(
                              "div",
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(server.network.input.current || "-")
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._l(
                              server.excludedIpRanges.values,
                              function (ipRange, ipRangeIndex) {
                                return _c(
                                  "div",
                                  {
                                    key:
                                      "dhcp-server-" +
                                      server.key +
                                      "-ip-range-" +
                                      ipRangeIndex +
                                      "-current",
                                  },
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            ipRange.start.ipAddress.current +
                                              " - " +
                                              ipRange.end.ipAddress.current
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._l(
                              server.dnsIps.values,
                              function (dnsIp, dnsIpIndex) {
                                return _c(
                                  "div",
                                  {
                                    key:
                                      "dhcp-server-" +
                                      server.key +
                                      "-dns-ip-" +
                                      dnsIpIndex +
                                      "-current",
                                  },
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        _vm._s(dnsIp.ipAddress.current || "-")
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c(
                          "SummaryColumn",
                          { attrs: { variant: "align-top" } },
                          [
                            _c(
                              "div",
                              {
                                class: server.network.input.new
                                  ? "field-value--changed"
                                  : "",
                              },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(server.network.input.new || "-")
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._l(
                              server.excludedIpRanges.values,
                              function (ipRange, ipRangeIndex) {
                                return _c(
                                  "div",
                                  {
                                    key:
                                      "dhcp-server-" +
                                      server.key +
                                      "-ip-range-" +
                                      ipRangeIndex +
                                      "-new",
                                    class: ipRange.start.ipAddress.new
                                      ? "field-value--changed"
                                      : "",
                                  },
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        _vm._s(
                                          ipRange.start.ipAddress.new +
                                            " - " +
                                            ipRange.end.ipAddress.new
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._l(
                              server.dnsIps.values,
                              function (dnsIp, dnsIpIndex) {
                                return _c(
                                  "div",
                                  {
                                    key:
                                      "dhcp-server-" +
                                      server.key +
                                      "-dns-ip-" +
                                      dnsIpIndex +
                                      "-new",
                                    class: dnsIp.ipAddress.new
                                      ? "field-value--changed"
                                      : "",
                                  },
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        _vm._s(dnsIp.ipAddress.new || "-")
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }
                ),
                _vm._l(
                  _vm.getStaticRoutesByKeys(vpn.staticRoutes),
                  function (route) {
                    return _c(
                      "SummaryRow",
                      { key: route.key, staticClass: "'summary-row--nested" },
                      [
                        _c(
                          "SummaryColumn",
                          {
                            staticClass: "field-value--changed",
                            attrs: { variant: "align-top" },
                          },
                          [
                            _c("telia-p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.t("DATANET_ACTION_" + route.action) +
                                      " " +
                                      _vm.t("DATANET_VPN_STATIC_ROUTE")
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "SummaryColumn",
                          { attrs: { variant: "align-top" } },
                          [
                            _c(
                              "div",
                              { staticClass: "field-name" },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.t("DATANET_VPN_STATIC_ROUTE_NETWORK")
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "field-name" },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.t("DATANET_VPN_STATIC_ROUTE_NEXT_HOP")
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "SummaryColumn",
                          { attrs: { variant: "align-top" } },
                          [
                            _c(
                              "div",
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(route.network.input.current || "-")
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(
                                      route.nextHop.ipAddress.current || "-"
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "SummaryColumn",
                          { attrs: { variant: "align-top" } },
                          [
                            _c(
                              "div",
                              {
                                class: route.network.input.new
                                  ? "field-value--changed"
                                  : "",
                              },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(route.network.input.new || "-")
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                class: route.nextHop.ipAddress.new
                                  ? "field-value--changed"
                                  : "",
                              },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(route.nextHop.ipAddress.new || "-")
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }
                ),
              ],
              2
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }