import Vue from "vue";
import { corpBillingAccounts } from "@telia/b2b-rest-client";
import { MetadataKeys, DeliveryMethods } from "../../constants";
import { t } from "../../locale";
const getDefaultState = () => {
  return {
    billingAccountNumber: "",
    isHandledByTeliaFinance: null,
    teliaFinanceInfo: null,
    billingAccounts: {},
  };
};

const state = getDefaultState();

const getters = {
  includeInvoiceConfigurationStep: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.INVOICE),

  getBillingAccount: (state) => (id = "") => state.billingAccounts[id],

  getBillingAccountOptions: (state) => {
    const billingAccountOptions = Object.values(state.billingAccounts).map((account) => ({
      label: `${account.billingAccountNumber}${
        account.billingAccountReference ? " - " + account.billingAccountReference : ""
      }`,
      value: account.billingAccountNumber,
    }));

    billingAccountOptions.splice(0, 0, {
      label: t("DATANET_SELECT_INVOICE_ACCOUNT_PLACEHOLDER"),
      value: "",
    });

    return billingAccountOptions;
  },
};

const actions = {
  setBillingAccountNumber({ dispatch, commit }, value = "") {
    commit("SET_BILLING_ACCOUNT_NUMBER", value);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setIsHandledByTeliaFinance({ dispatch, commit }, value = false) {
    commit("SET_IS_HANDLED_BY_TELIA_FINANCE", value);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setTeliaFinanceInfo({ dispatch, commit }, value = "") {
    commit("SET_TELIA_FINANCE_INFO", value);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },
  async getBillingAccounts({ commit }, { scopeId, tscid }) {
    let accounts;
    try {
      const results = await corpBillingAccounts.BillingAccountsControllerService.getBillingAccountsForTscid(
        scopeId,
        tscid,
        ["BILLING_ACCOUNT", "BROADBAND"]
      );
      accounts = results;
    } catch (e) {
      throw new Error("DATANET_COULD_NOT_GET_BILLING_ACCOUNTS");
    }

    accounts = formatBillingAccounts(accounts);

    commit("SET_BILLING_ACCOUNTS", accounts);
  },

  async getBillingAccountAddress({ commit }, { scopeId, tscid, accountNumber }) {
    let accountData;
    try {
      const results = await corpBillingAccounts.BillingAccountsControllerService.getBillingAccountByColumn(
        "BILLING_ACCOUNT_NUMBER",
        scopeId,
        tscid,
        accountNumber
      );
      accountData = results;
    } catch (e) {
      throw new Error("DATANET_COULD_NOT_GET_BILLING_ACCOUNTS");
    }

    const address = formatBillingAddress(accountData);

    const data = {
      accountNumber: accountNumber,
      address: address,
      method: getDeliveryMethodText(accountData.deliveryMethod),
    };

    commit("SET_BILLING_ACCOUNT_ADDRESS", data);
  },
};

const mutations = {
  SET_BILLING_ACCOUNT_NUMBER(state, value) {
    state.billingAccountNumber = value;
  },

  SET_IS_HANDLED_BY_TELIA_FINANCE(state, value) {
    state.isHandledByTeliaFinance = value;
  },

  SET_TELIA_FINANCE_INFO(state, value) {
    state.teliaFinanceInfo = value;
  },

  SET_BILLING_ACCOUNTS(state, accounts = {}) {
    state.billingAccounts = accounts;
  },

  SET_BILLING_ACCOUNT_ADDRESS(state, accountData) {
    Vue.set(
      state.billingAccounts[accountData.accountNumber],
      "billingAddress",
      accountData.address
    );
    Vue.set(
      state.billingAccounts[accountData.accountNumber],
      "billingMethodText",
      accountData.method
    );
  },

  RESET_STATE(state) {
    const defaultState = getDefaultState();
    delete defaultState.billingAccounts;

    Object.assign(state, defaultState);
  },
};

const formatBillingAccounts = (accounts = []) =>
  accounts.reduce((accounts, account) => {
    accounts[account.number] = {
      id: account.number,
      billingAccountNumber: account.number,
      billingAccountReference: account.accountReference,
      billingMethodText: "",
      billingAddress: {},
      billingEmail: "",
    };
    return accounts;
  }, {});

const formatBillingAddress = (address = {}) => ({
  careOf: address.coAddress || "",
  street: address.address || "",
  zipCode: address.postalCode || "",
  city: address.city || "",
  country: address.country || "",
});

const getDeliveryMethodText = (method = "") => {
  switch (method) {
    case DeliveryMethods.POSTAL:
      return t("DATANET_INVOICE_ACCOUNT_METHOD_POSTAL");
    case DeliveryMethods.EMAIL:
      return t("DATANET_INVOICE_ACCOUNT_METHOD_EMAIL");
    default:
      return t("DATANET_UNKNOWN_BILLING_METHOD");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
