<template>
  <div>
    <div class="configure-dns-ip">
      <telia-heading tag="h4" variant="title-100" class="margin__bottom__12">
        {{ t("DATANET_VPN_CONFIGURE_DNS_IP") }}
      </telia-heading>

      <div v-if="isChangeOrder" t-id="action-select">
        <ActionSelection
          :selected-action="action"
          :disabled-actions="disabledActions"
          @selected="action = $event"
        />
      </div>

      <div class="row margin__bottom__4">
        <div class="column-container">
          <div v-if="isCurrentValuesRequired" class="column input-wrapper">
            <telia-text-input
              t-id="current-dns-ip"
              :label="t('DATANET_CURRENT_VALUE')"
              :value="ipAddress.current"
              @input="ipAddress.current = $event.target.value"
            />
          </div>
          <div v-if="isNewValuesRequired" class="column input-wrapper">
            <telia-text-input
              t-id="new-dns-ip"
              :label="t('DATANET_NEW_VALUE')"
              :value="ipAddress.new"
              @input="ipAddress.new = $event.target.value"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="column-container">
          <ValidationError v-if="$v.$error">
            {{ t("DATANET_INVALID_IP_ADDRESS") }}
          </ValidationError>
          <telia-p v-else variant="additional-100" class="additional-text">
            {{ t("DATANET_IP_ADDRESS_PLACEHOLDER") }}
          </telia-p>
        </div>
      </div>
    </div>
    <div class="button-wrapper">
      <telia-button
        t-id="save-dns-ip-config"
        type="button"
        variant="expressive"
        size="sm"
        :disabled="isValidating"
        @click="saveAndClose()"
      >
        {{ t("DATANET_SAVE_CHANGES") }}
      </telia-button>

      <telia-button
        id="cancel-dns-ip-address-configuration"
        type="button"
        variant="secondary"
        size="sm"
        @click="$emit('complete')"
      >
        {{ t("DATANET_ABORT") }}
      </telia-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { Actions } from "../../constants";
import { corpIpAddress } from "../../helpers/corpValidation";
import { corpValidationService } from "@telia/b2b-rest-client";
import { translateMixin } from "../../locale";
import defaultChangeableValue from "../../store/defaultChangeableValue";
import ActionSelection from "../ActionSelection";
import ValidationError from "../ValidationError";

export default {
  name: "DnsIp",

  mixins: [translateMixin, validationMixin],

  components: {
    ActionSelection,
    ValidationError,
  },

  props: {
    dhcpServerKey: [String, Number],
    dnsIndex: Number,
  },

  data() {
    return {
      action: Actions.ADD,
      ipAddress: defaultChangeableValue(),
      ipVersion: defaultChangeableValue(),
      currentDnsIpTimeoutId: null,
      newDnsIpTimeoutId: null,
      disableSaveButton: false,
      isValidatingIpAddressInformation: false,
      isValidatingCurrent: false,
      isValidatingNew: false,
    };
  },

  computed: {
    ...mapState({
      scopeId: (state) => state.user.scopeId,
    }),

    ...mapGetters(["isChangeOrder"]),

    ...mapGetters("dhcpServer", {
      getDhcpServerAction: "getAction",
    }),

    disabledActions() {
      if (this.serverAction === Actions.ADD) {
        return [Actions.UPDATE, Actions.DELETE];
      }

      return [];
    },

    serverAction() {
      return this.getDhcpServerAction(this.dhcpServerKey);
    },

    isCurrentValuesRequired() {
      return this.action === Actions.UPDATE || this.action === Actions.DELETE;
    },

    isNewValuesRequired() {
      return this.action === Actions.ADD || this.action === Actions.UPDATE;
    },

    isValidating() {
      return (
        this.isValidatingIpAddressInformation ||
        this.isValidatingCurrent ||
        this.isValidatingNew ||
        this.disableSaveButton
      );
    },
  },

  validations: {
    ipAddress: {
      current: {
        required: requiredIf(function () {
          return this.isCurrentValuesRequired;
        }),
        validIpAddress: async function (value) {
          if (!this.isCurrentValuesRequired && !value) {
            return true;
          }

          const isValid = await corpIpAddress(value, this.scopeId);
          return isValid;
        },
      },
      new: {
        required: requiredIf(function () {
          return this.isNewValuesRequired;
        }),
        validIpAddress: async function (value) {
          if (!this.isNewValuesRequired && !value) {
            return true;
          }

          const isValid = await corpIpAddress(value, this.scopeId);
          return isValid;
        },
      },
    },
  },

  methods: {
    ...mapActions("dhcpServer", ["addDnsIp", "setDnsIpAddress", "setDnsIpVersion"]),

    async saveAndClose() {
      this.disableSaveButton = true;
      await this.touchAndWaitForValidation();

      if (this.$v.$invalid) {
        this.disableSaveButton = false;
        return;
      }

      const index = this.dnsIndex > -1 ? this.dnsIndex : await this.addDnsIp(this.dhcpServerKey);

      this.setDnsIpAddress({
        serverKey: this.dhcpServerKey,
        index: index,
        action: this.action,
        current: this.ipAddress.current,
        new: this.ipAddress.new,
      });
      this.setDnsIpVersion({
        serverKey: this.dhcpServerKey,
        index: index,
        action: this.action,
        current: this.ipVersion.current,
        new: this.ipVersion.new,
      });

      this.disableSaveButton = false;
      this.$emit("complete");
    },

    async touchAndWaitForValidation() {
      if (this.isCurrentValuesRequired) {
        await this.validateCurrentDnsIpAddress();
      }
      if (this.isNewValuesRequired) {
        await this.validateNewDnsIpAddress();
      }

      this.$v.$touch();

      /* TODO: Should we do something similar?
      if (process.env.MYBD_ENV === "test") {
        return new Promise((resolve) => resolve(true));
      }
      */
      let unwatch = null;
      return new Promise((resolve) => {
        unwatch = this.$watch(
          () => {
            return !this.$v.$pending;
          },
          (isNotPending) => {
            if (isNotPending) {
              if (unwatch) {
                unwatch();
              }
              resolve(!this.$v.$invalid);
            }
          },
          { immediate: true }
        );
      });
    },

    async validateCurrentDnsIpAddress() {
      this.isValidatingCurrent = true;

      if (this.currentStartIpTimeoutId) {
        clearTimeout(this.currentStartIpTimeoutId);
      }
      return new Promise((resolve) => {
        this.currentStartIpTimeoutId = setTimeout(
          async function () {
            const ipInfo = await this.getIpAddressInformation(this.ipAddress.current);

            this.ipVersion.current = ipInfo.valid ? ipInfo.ipVersion : "";
            this.isValidatingCurrent = false;
            resolve();
          }.bind(this),
          400
        );
      });
    },

    async validateNewDnsIpAddress() {
      this.isValidatingNew = true;

      if (this.newDnsIpTimeoutId) {
        clearTimeout(this.newDnsIpTimeoutId);
      }

      return new Promise((resolve) => {
        this.newDnsIpTimeoutId = setTimeout(
          async function () {
            const ipInfo = await this.getIpAddressInformation(this.ipAddress.new);
            this.ipVersion.new = ipInfo.valid ? ipInfo.ipVersion : "";
            this.isValidatingNew = false;
            resolve();
          }.bind(this),
          400
        );
      });
    },

    async getIpAddressInformation(value) {
      this.isValidatingIpAddressInformation = true;
      let ipInfo = {};
      try {
        const response = await corpValidationService.ValidationControllerService.validate(
          this.scopeId,
          {
            type: "IPADDRESS",
            value,
          }
        );

        if (response) {
          ipInfo = {
            valid: true,
            ipVersion: response.version,
          };
        }
      } catch (e) {
        ipInfo = {
          valid: false,
        };
      }
      this.isValidatingIpAddressInformation = false;

      return ipInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";

.configure-dns-ip {
  padding-bottom: $telia-spacing-32;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
}

.column-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  flex: 4 1 0;
}

.column {
  flex: 1 1 0;
  margin-right: 0.5rem;
}

.column:last-child {
  margin: 0;
}

.column-small {
  flex: 1 1 0;
  align-self: center;
  margin: 0;
}

.input-wrapper {
  max-width: 42rem;
}
.button-wrapper > * {
  margin-right: $telia-spacing-12;
}
.additional-text {
  color: $telia-gray-500;
}
.margin {
  &__bottom {
    &__12 {
      margin-bottom: $telia-spacing-4;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
  }
}
</style>
