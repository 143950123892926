<template>
  <div>
    <telia-fieldset :legend="t('DATANET_INTERNET_ON_SITE')">
      <ActionSelection
        v-show="isChangeOrder"
        :disabled-actions="disabledActions"
        :selected-action="internetOnSiteAction"
        @selected="handleActionSelection($event)"
      />

      <telia-p v-show="internetOnSiteAction === 'DELETE'" class="no-margin" disable-hyphenation>
        {{ t("DATANET_INTERNET_ON_SITE_WILL_BE_REMOVED") }}
      </telia-p>

      <div v-show="internetOnSiteAction === 'ADD'" class="fieldset-wrapper">
        <telia-fieldset
          name="ip-options"
          caption="fieldset for ip-options"
          :legend="t('DATANET_INTERNET_ON_SITE_IP_ADDRESSES')"
        >
          <div class="chip-choice-wrapper">
            <telia-chip-choice
              id="use-existing-ip-addresses"
              :checked="useExistingIpAddresses"
              @change="handleUseExistingIpsInput(true)"
            >
              {{ t("DATANET_INTERNET_ON_SITE_EXISTING_IP") }}
            </telia-chip-choice>
            <telia-chip-choice
              id="use-new-ip-addresses"
              :checked="!useExistingIpAddresses"
              @change="handleUseExistingIpsInput(false)"
            >
              {{ t("DATANET_INTERNET_ON_SITE_NEW_IP") }}
            </telia-chip-choice>
          </div>
        </telia-fieldset>
      </div>

      <div v-show="!useExistingIpAddresses">
        <telia-p class="additional-info" disable-hyphenation>
          {{ t("DATANET_INTERNET_ON_SITE_IP_REQUEST_1") }}
          <telia-link href="https://iprequest.teliacompany.com/teliaSE/index.html" target="_blank">
            {{ t("DATANET_INTERNET_ON_SITE_IP_REQUEST") }}
          </telia-link>
          <br />
          {{ t("DATANET_INTERNET_ON_SITE_IP_REQUEST_2") }}
        </telia-p>
        <div class="input-wrapper">
          <telia-text-input
            t-id="ripe-number"
            :label="t('DATANET_INTERNET_ON_SITE_RIPE_NUMBER')"
            :value="ripeNumber"
            @input="handleRipeNumberInput($event.target.value)"
          />
          <ValidationError v-if="$v.ripeNumber.$error">
            {{ t("DATANET_INTERNET_ON_SITE_RIPE_NUMBER_ERROR") }}
          </ValidationError>
        </div>
      </div>

      <div v-show="internetOnSiteAction === 'ADD'" class="fieldset-wrapper">
        <telia-fieldset
          name="lan-interface-options"
          caption="fieldset for lan-interface-options"
          :legend="t('DATANET_INTERNET_ON_SITE_INTERFACE')"
        >
          <telia-p class="additional-info" disable-hyphenation>
            {{ t("DATANET_INTERNET_ON_SITE_INTERFACE_INFO") }}
          </telia-p>
          <div class="chip-choice-wrapper">
            <telia-chip-choice
              id="enable-lan-interface"
              :checked="lanInterface === 'LAN'"
              @change="handleLanInterfaceInput('LAN')"
            >
              {{ t("DATANET_INTERNET_ON_SITE_LAN") }}
            </telia-chip-choice>
            <telia-chip-choice
              id="enable-vlan-interface"
              :checked="lanInterface === 'VLAN'"
              :disabled="true"
              @change="handleLanInterfaceInput('VLAN')"
            >
              {{ t("DATANET_INTERNET_ON_SITE_VLAN") }}
            </telia-chip-choice>
          </div>
        </telia-fieldset>
      </div>

      <div v-show="internetOnSiteAction === 'UPDATE' && !isBandwidthChangeConfigActive">
        <telia-button
          t-id="add-bandwidth-change-config"
          :text="t('DATANET_INTERNET_ON_SITE_ADD_CHANGE_ON_BANDWIDTH')"
          :left-icon="JSON.stringify({ name: 'plus', size: 'sm' })"
          variant="text"
          @click="showBandwidthChangeConfig()"
        />
      </div>

      <div v-show="shouldShowCapacityConfig" class="fieldset-wrapper">
        <telia-fieldset
          name="capacity-options"
          caption="fieldset for capacity-options"
          :legend="t('DATANET_INTERNET_ON_SITE_CAPACITY')"
        >
          <div class="chip-choice-wrapper">
            <telia-chip-choice
              id="enable-dynamic-capacity"
              :checked="useDynamicCapacity"
              @change="handleUseDynamicCapacityInput(true)"
            >
              {{ t("DATANET_INTERNET_ON_SITE_DYNAMIC_CAPACITY") }}
            </telia-chip-choice>
            <telia-chip-choice
              id="disable-dynamic-capacity"
              :checked="!useDynamicCapacity"
              :disabled="isWirelessAccess"
              @change="handleUseDynamicCapacityInput(false)"
            >
              {{ t("DATANET_INTERNET_ON_SITE_STATIC_BANDWIDTH") }}
            </telia-chip-choice>
          </div>
        </telia-fieldset>
      </div>

      <div v-show="shouldShowCapacityConfig && !useDynamicCapacity" class="fieldset-wrapper">
        <telia-fieldset
          name="static-bandwidth-options"
          caption="fieldset for static-bandwidth-options"
          :legend="t('DATANET_INTERNET_ON_SITE_SELECT_BANDWIDTH')"
        >
          <div class="input-wrapper-no-padding">
            <telia-select
              t-id="internet-on-site-bandwidth"
              :options="JSON.stringify(staticBandwidthOptions)"
              @change="handleStaticBandwidthSelection($event.target.value)"
            />
            <ValidationError v-if="$v.staticBandwidth.$error">
              {{ t("DATANET_INTERNET_ON_SITE_STATIC_BANDWIDTH_ERROR") }}
            </ValidationError>
          </div>
        </telia-fieldset>
      </div>

      <div
        v-show="
          internetOnSiteAction === 'UPDATE' &&
          lanInterface === 'LAN' &&
          !isInterfaceChangeConfigActive
        "
      >
        <telia-button
          t-id="add-interface-change-config"
          :text="t('DATANET_INTERNET_ON_SITE_ADD_CHANGE_ON_INTERFACE')"
          :left-icon="JSON.stringify({ name: 'plus', size: 'sm' })"
          variant="text"
          @click="showInterfaceChangeConfig()"
        />
      </div>

      <div v-show="shouldShowEthernetInterfaceConfig" class="fieldset-wrapper">
        <telia-fieldset
          name="ethernet-interface-options"
          caption="fieldset for capacity-options"
          :legend="t('DATANET_INTERNET_ON_SITE_SELECT_ETHERNET_INTERFACES')"
        >
          <div class="input-wrapper-no-padding">
            <telia-select
              t-id="internet-on-site-ethernet-interfaces"
              :options="JSON.stringify(ethernetInterfaceOptions)"
              @change="handleExtraEthernetInterfaceSelection($event.target.value)"
            />
          </div>
        </telia-fieldset>
      </div>
    </telia-fieldset>

    <ValidationError
      v-if="!isChangeConfigAdded"
      class="margin__bottom__24"
      v-text="t('DATANET_INTERNET_ON_SITE_CHANGE_NEEDED')"
    />

    <div class="button-wrapper">
      <telia-button
        id="save-internet-on-site-configuration"
        t-id="save-internet-on-site-configuration"
        variant="expressive"
        type="button"
        @click="saveAndClose()"
      >
        {{ t("DATANET_SAVE_CHANGES") }}
      </telia-button>

      <telia-button
        id="cancel-internet-on-site-configuration"
        t-id="cancel-internet-on-site-configuration"
        type="button"
        variant="secondary"
        @click="$emit('complete')"
      >
        {{ t("DATANET_ABORT") }}
      </telia-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { Accesses, Actions, BandwidthDistributions } from "../../constants";
import { translateMixin } from "../../locale";
import createBandwidthLabel from "../../helpers/createBandwidthLabel";
import ActionSelection from "../ActionSelection.vue";
import ValidationError from "../ValidationError.vue";

const staticBandwidths = [
  1000000,
  500000,
  400000,
  300000,
  200000,
  100000,
  50000,
  20000,
  10000,
  8000,
  6000,
  4000,
  2000,
  1000,
  512,
];

const extraEthernetInterfaces = [0, 1, 2, 3];

export default {
  name: "InternetOnSiteConfig",
  components: { ActionSelection, ValidationError },
  mixins: [translateMixin, validationMixin],

  data() {
    return {
      internetOnSiteAction:
        this.$store.state.access[Accesses.PRIMARY].options.internetOnSite.action || Actions.ADD,
      useExistingIpAddresses: !this.$store.state.access[Accesses.PRIMARY].options.internetOnSite
        .ripeNumber,
      ripeNumber: this.$store.state.access[Accesses.PRIMARY].options.internetOnSite.ripeNumber,
      lanInterface:
        this.$store.state.access[Accesses.PRIMARY].options.internetOnSite.lanInterface || "LAN",
      dynamicCapacityAction:
        this.$store.state.access[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity.dynamic
          .action || Actions.ADD,
      useDynamicCapacity: this.$store.state.access[Accesses.PRIMARY].options.internetOnSite
        .lanConfig.capacity.dynamic.new,
      currentUseDynamicCapacity: this.$store.state.access[Accesses.PRIMARY].options.internetOnSite
        .lanConfig.capacity.dynamic.current,
      staticBandwidth: this.$store.state.access[Accesses.PRIMARY].options.internetOnSite.lanConfig
        .capacity.bandwidth.new,
      currentStaticBandwidth: this.$store.state.access[Accesses.PRIMARY].options.internetOnSite
        .lanConfig.capacity.bandwidth.current,
      staticBandwidthAction:
        this.$store.state.access[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity
          .bandwidth.action || Actions.ADD,
      currentExtraEthernetInterfaces: this.$store.state.access[Accesses.PRIMARY].options
        .internetOnSite.lanConfig.ethernetInterface.current,
      extraEthernetInterfaces: this.$store.state.access[Accesses.PRIMARY].options.internetOnSite
        .lanConfig.ethernetInterface.new,
      ethernetInterfaceAction:
        this.$store.state.access[Accesses.PRIMARY].options.internetOnSite.lanConfig
          .ethernetInterface.action || Actions.ADD,
      isBandwidthChangeConfigActive: false,
      isInterfaceChangeConfigActive: false,
      isChangeConfigAdded: true,
      hasExistingInternetOnSite: false,
    };
  },

  computed: {
    ...mapState("access", {
      primaryAccessBandwidth: (state) => state[Accesses.PRIMARY].bandwidth,
    }),

    ...mapGetters(["isChangeOrder", "isNewOrder"]),
    ...mapGetters("products", ["isWirelessAccess", "getBandwidthForSelectedAccess"]),

    disabledActions() {
      return this.hasExistingInternetOnSite ? [Actions.ADD] : [];
    },

    shouldShowCapacityConfig() {
      return (
        this.internetOnSiteAction === Actions.ADD ||
        (this.internetOnSiteAction === Actions.UPDATE && this.isBandwidthChangeConfigActive)
      );
    },

    shouldShowEthernetInterfaceConfig() {
      return (
        this.internetOnSiteAction === Actions.ADD ||
        (this.internetOnSiteAction === Actions.UPDATE &&
          this.isInterfaceChangeConfigActive &&
          this.lanInterface === "LAN")
      );
    },

    selectedPrimaryBandwidth() {
      if (this.isWirelessAccess) {
        return 0;
      }

      const selectedBandwidth = this.getBandwidthForSelectedAccess(this.primaryAccessBandwidth.new);
      return selectedBandwidth.downRateKbit;
    },

    staticBandwidthOptions() {
      return staticBandwidths.map((bandwidth) => {
        return {
          value: "" + bandwidth,
          label: createBandwidthLabel(bandwidth, bandwidth),
          selected: bandwidth === Number(this.staticBandwidth),
          disabled: bandwidth > this.selectedPrimaryBandwidth,
        };
      });
    },

    ethernetInterfaceOptions() {
      return extraEthernetInterfaces.map((value) => {
        return {
          value: value,
          label:
            value > 0
              ? this.t("DATANET_INTERNET_ON_SITE_EXTRA_ETHERNET_INTERFACES", { number: value })
              : this.t("DATANET_INTERNET_ON_SITE_NO_EXTRA_ETHERNET_INTERFACES"),
          selected: this.extraEthernetInterfaces === value,
          disabled: false,
        };
      });
    },
  },

  validations: {
    ripeNumber: {
      required: requiredIf(function () {
        return this.internetOnSiteAction === Actions.ADD && !this.useExistingIpAddresses;
      }),
    },
    staticBandwidth: {
      required: requiredIf(function () {
        return (
          (this.staticBandwidthAction === Actions.ADD ||
            this.staticBandwidthAction === Actions.UPDATE) &&
          !this.useDynamicCapacity
        );
      }),
    },
  },

  mounted() {
    if (this.isChangeOrder) {
      if (
        this.dynamicCapacityAction === Actions.UPDATE &&
        this.currentUseDynamicCapacity !== this.useDynamicCapacity
      ) {
        this.isBandwidthChangeConfigActive = true;
      }

      if (
        this.staticBandwidthAction === Actions.UPDATE &&
        this.currentStaticBandwidth !== this.staticBandwidth
      ) {
        this.isBandwidthChangeConfigActive = true;
      }

      if (this.ethernetInterfaceAction === Actions.UPDATE) {
        this.isInterfaceChangeConfigActive = true;
      }
    }

    if (this.isWirelessAccess) {
      this.useDynamicCapacity = true;
    }

    if (this.currentUseDynamicCapacity && !this.staticBandwidth) {
      this.useDynamicCapacity = true;
    }

    if (this.currentStaticBandwidth && !this.staticBandwidth) {
      this.staticBandwidth = this.currentStaticBandwidth;
    }

    if (!this.staticBandwidth) {
      this.staticBandwidth = this.selectedPrimaryBandwidth;
    }

    this.hasExistingInternetOnSite = this.currentUseDynamicCapacity || this.currentStaticBandwidth;

    if (this.hasExistingInternetOnSite && this.internetOnSiteAction === Actions.ADD) {
      this.internetOnSiteAction = Actions.UPDATE;
      this.dynamicCapacityAction = Actions.UPDATE;
      this.staticBandwidthAction = Actions.UPDATE;
      this.ethernetInterfaceAction = Actions.UPDATE;
    }
  },

  methods: {
    ...mapActions("access", [
      "setInternetOnSiteAction",
      "setInternetOnSiteRipeNumber",
      "setInternetOnSiteLanInterface",
      "setInternetOnSiteUseDynamicCapacity",
      "setInternetOnSiteStaticBandwidth",
      "setInternetOnSiteExtraEthernetInterfaces",
    ]),

    ...mapActions("vpn", ["setBandwidthDistribution"]),

    handleActionSelection(value) {
      this.internetOnSiteAction = value;

      if (value === Actions.ADD) {
        this.dynamicCapacityAction = Actions.ADD;
        this.staticBandwidthAction = Actions.ADD;
        this.ethernetInterfaceAction = Actions.ADD;
      } else if (value === Actions.UPDATE) {
        this.dynamicCapacityAction = Actions.UPDATE;
        this.staticBandwidthAction = Actions.UPDATE;
        this.ethernetInterfaceAction = Actions.UPDATE;
      } else if (value === Actions.DELETE) {
        this.dynamicCapacityAction = Actions.DELETE;
        this.staticBandwidthAction = Actions.DELETE;
        this.ethernetInterfaceAction = Actions.DELETE;
      }
    },

    showBandwidthChangeConfig() {
      this.isBandwidthChangeConfigActive = true;
      this.isChangeConfigAdded = true;
    },

    showInterfaceChangeConfig() {
      this.isInterfaceChangeConfigActive = true;
      this.isChangeConfigAdded = true;
    },

    handleUseExistingIpsInput(value) {
      this.useExistingIpAddresses = value;
    },

    handleRipeNumberInput(value) {
      this.ripeNumber = value;
    },

    handleLanInterfaceInput(value) {
      this.lanInterface = value;

      if (value === "VLAN") {
        this.extraEthernetInterfaces = 0;
      }
    },

    handleUseDynamicCapacityInput(value) {
      this.useDynamicCapacity = value;

      if (this.isChangeOrder) {
        this.dynamicCapacityAction = Actions.UPDATE;
        this.isChangeConfigAdded = true;
      }
    },

    handleStaticBandwidthSelection(value) {
      this.staticBandwidth = value;

      if (this.isChangeOrder) {
        this.staticBandwidthAction = Actions.UPDATE;
        this.isChangeConfigAdded = true;
      }
    },

    handleExtraEthernetInterfaceSelection(value) {
      this.extraEthernetInterfaces = Number(value);

      if (this.isChangeOrder) {
        this.ethernetInterfaceAction = Actions.UPDATE;
        this.isChangeConfigAdded = true;
      }
    },

    saveAndClose() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (this.isChangeOrder) {
        this.isChangeConfigAdded = true;

        if (this.internetOnSiteAction === Actions.UPDATE) {
          if (!this.isBandwidthChangeConfigActive && !this.isInterfaceChangeConfigActive) {
            this.isChangeConfigAdded = false;
            return;
          }

          if (
            this.isBandwidthChangeConfigActive &&
            this.currentUseDynamicCapacity === this.useDynamicCapacity
          ) {
            this.isChangeConfigAdded = false;
            return;
          }

          if (
            this.isBandwidthChangeConfigActive &&
            this.currentStaticBandwidth === this.staticBandwidth
          ) {
            this.isChangeConfigAdded = false;
            return;
          }

          if (
            this.isInterfaceChangeConfigActive &&
            this.currentExtraEthernetInterfaces &&
            this.currentExtraEthernetInterfaces === this.extraEthernetInterfaces
          ) {
            this.isChangeConfigAdded = false;
            return;
          }
        }
      }

      this.setInternetOnSiteAction({
        accessKey: Accesses.PRIMARY,
        action: this.internetOnSiteAction,
      });

      const ripeNumber = this.useExistingIpAddresses ? "" : this.ripeNumber;

      this.setInternetOnSiteRipeNumber({
        accessKey: Accesses.PRIMARY,
        ripeNumber: ripeNumber,
      });

      this.setInternetOnSiteLanInterface({
        accessKey: Accesses.PRIMARY,
        lanInterface: this.lanInterface,
      });

      let capacityAction = null;

      if (this.isChangeOrder) {
        const hasChangedCapacity = this.currentUseDynamicCapacity !== this.useDynamicCapacity;
        capacityAction =
          this.dynamicCapacityAction === Actions.UPDATE && hasChangedCapacity
            ? this.dynamicCapacityAction
            : null;
      } else {
        capacityAction = this.useDynamicCapacity ? this.dynamicCapacityAction : null;
      }

      const changeableCapacity = {
        action: capacityAction,
        current: this.currentUseDynamicCapacity,
        new: this.useDynamicCapacity ? this.useDynamicCapacity : null,
      };

      this.setInternetOnSiteUseDynamicCapacity({
        accessKey: Accesses.PRIMARY,
        useDynamicCapacity: changeableCapacity,
      });

      let bandwidthAction = null;

      if (this.isChangeOrder) {
        const hasChangedBandwidth = this.currentStaticBandwidth !== this.staticBandwidth;
        bandwidthAction =
          this.staticBandwidthAction === Actions.UPDATE && hasChangedBandwidth
            ? this.staticBandwidthAction
            : null;
      } else {
        bandwidthAction = !this.useDynamicCapacity ? this.staticBandwidthAction : null;
      }

      const changeableBandwidth = {
        action: bandwidthAction,
        current: this.currentStaticBandwidth,
        new: !this.useDynamicCapacity ? this.staticBandwidth : null,
      };

      this.setInternetOnSiteStaticBandwidth({
        accessKey: Accesses.PRIMARY,
        staticBandwidth: changeableBandwidth,
      });

      let interfaceAction = null;

      if (this.isChangeOrder) {
        interfaceAction = this.isInterfaceChangeConfigActive ? this.ethernetInterfaceAction : null;
      } else {
        interfaceAction = this.ethernetInterfaceAction;
      }

      const changeableInterface = {
        action: interfaceAction,
        current: this.currentExtraEthernetInterfaces,
        new: this.extraEthernetInterfaces,
      };

      this.setInternetOnSiteExtraEthernetInterfaces({
        accessKey: Accesses.PRIMARY,
        extraEthernetInterfaces: changeableInterface,
      });

      // Internet on site only allows for dynamic bandwidth distribution for VPNs. Set value to dynamic automatically.
      this.setBandwidthDistribution({
        accessKey: Accesses.PRIMARY,
        new: BandwidthDistributions.DYNAMIC,
      });

      this.$emit("complete");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.action-selection {
  margin-bottom: $telia-spacing-12;
}

.chip-choice-wrapper > * {
  margin-right: $telia-spacing-12;
}

.fieldset-wrapper {
  padding: $telia-spacing-12 0;
  box-sizing: border-box;
}

::v-deep .telia-fieldset telia-text-input {
  margin-bottom: 0;
}

::v-deep .telia-fieldset telia-select {
  margin-bottom: 0;
}

::v-deep .telia-fieldset > legend {
  margin-bottom: $telia-spacing-4;
}

.additional-info {
  margin-bottom: $telia-spacing-8;
}

.input-wrapper {
  max-width: 42rem;
  padding: $telia-spacing-12 0;
}

.input-wrapper-no-padding {
  max-width: 42rem;
  padding: 0;
}

.button-wrapper {
  padding-top: $telia-spacing-24;
}

.button-wrapper > * {
  margin-right: $telia-spacing-8;
}

.no-margin {
  margin: 0;
}
</style>
