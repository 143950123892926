export function getUtcFromIsoDate(dateString = "") {
  const validDateRegex = /([2]\d{3}-(0[1-9]|1[0-2])-([0-2][0-9]|3[0-1]))/g;

  if (!validDateRegex.test(dateString)) {
    return "";
  }

  const [year, month, day] = dateString.split("-");
  return Date.UTC(year, parseInt(month, 10) - 1, day, 10);
}

export function getIsoDateFromUtc(utc) {
  if (!utc) {
    return "";
  }

  const date = new Date(utc);
  const iso = date.toISOString();
  const isoDate = iso.split("T")[0];

  return isoDate;
}
