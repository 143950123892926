var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "overlay",
      class: _vm.isOpen ? "visible" : "hidden",
      attrs: { "aria-hidden": _vm.isOpen ? "false" : "true" },
    },
    [
      _c(
        "div",
        {
          class: "modal modal__" + _vm.width,
          attrs: { id: _vm.modalId, role: "dialog", "aria-modal": "true" },
        },
        [
          _c("telia-focus-trap", [
            _vm.showCloseButton
              ? _c("div", { staticClass: "icon-tag" }, [
                  _c(
                    "button",
                    {
                      staticClass: "close-button",
                      attrs: {
                        type: "button",
                        "aria-label": _vm.closeButtonLabel,
                        "aria-expanded": _vm.isOpen ? "true" : "false",
                        "aria-controls": _vm.modalId,
                      },
                      on: { click: _vm.handleClose },
                    },
                    [
                      _c("telia-icon", {
                        attrs: { name: "close", size: "lg" },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "modal-content" },
              [
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [_vm._t("modal-body")],
                  2
                ),
                _vm._t("button-footer"),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }