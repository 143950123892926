var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Organisation", {
        on: {
          selected: function ($event) {
            return _vm.handleOrganisationSelection($event)
          },
        },
      }),
      _vm.alert.show
        ? _c(
            "telia-notification",
            {
              attrs: {
                "t-id": "organisation-alert",
                "heading-tag": "h4",
                variant: "inline",
                status: _vm.alert.status,
                "html-aria-live": "polite",
                "html-role": "alert",
              },
            },
            [
              _c(
                "span",
                { attrs: { slot: "heading" }, slot: "heading" },
                [
                  _c("telia-visually-hidden", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.t("A11Y_STATUS_WARNING")) +
                        "\n      "
                    ),
                  ]),
                  _vm._v("\n      " + _vm._s(_vm.alert.title) + "\n    "),
                ],
                1
              ),
              _c(
                "span",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _c("telia-p", [
                    _vm._v("\n        " + _vm._s(_vm.alert.text) + "\n      "),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "step-button-wrapper" },
        [
          _c(
            "telia-button",
            {
              attrs: {
                "t-id": "next-step",
                type: "button",
                variant: "primary",
                size: "sm",
                disabled: _vm.disableNextStep,
              },
              on: {
                click: function ($event) {
                  return _vm.completeStep()
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.buttonText) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }