var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.activeTooltip === "rt-tot"
        ? _c(
            "InformationBox",
            {
              on: {
                close: function ($event) {
                  return _vm.handleTooltip(null)
                },
              },
            },
            [_c("RtTotTooltip")],
            1
          )
        : _vm._e(),
      _c("telia-heading", { attrs: { tag: "h4", variant: "title-100" } }, [
        _vm._v("\n    " + _vm._s(_vm.t("DATANET_RT_TOT")) + "\n  "),
      ]),
      _c(
        "div",
        { staticClass: "tooltip-wrapper" },
        [
          _c(
            "div",
            { staticClass: "input-wrapper" },
            [
              _c("telia-select", {
                attrs: {
                  id: "vpn-rt-tot",
                  options: JSON.stringify(_vm.rtTotOptions),
                  disabled: !_vm.isEditable,
                },
                on: {
                  change: function ($event) {
                    _vm.rtTot = $event.target.value
                  },
                },
              }),
            ],
            1
          ),
          _c("telia-icon", {
            staticClass: "tooltip-icon-inputs",
            attrs: { name: "question", size: "sm" },
            on: {
              click: function ($event) {
                return _vm.handleTooltip("rt-tot")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }