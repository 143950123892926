var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "summary" },
    [
      _vm._t("default"),
      _c(
        "div",
        { staticClass: "summary__edit" },
        [
          _vm.editable
            ? _c(
                "telia-button",
                {
                  attrs: { type: "button", variant: "secondary", size: "sm" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("edit")
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.editLabel) + "\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }