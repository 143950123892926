var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: _vm.listItemClasses,
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.deletable
        ? _c("telia-icon", {
            staticClass: "margin-right",
            attrs: { name: "close", size: "sm" },
            on: {
              click: function ($event) {
                return _vm.$emit("delete")
              },
            },
          })
        : _vm._e(),
      _vm.addable
        ? _c("telia-icon", {
            staticClass: "margin-right",
            attrs: { name: "plus", size: "sm" },
          })
        : _vm._e(),
      _vm.checkable
        ? _c("telia-checkbox", {
            attrs: { value: !_vm.checked, checked: _vm.checked },
            on: {
              change: function ($event) {
                return _vm.$emit("include", !_vm.checked)
              },
            },
          })
        : _vm._e(),
      _c("telia-p", {
        staticClass: "list-item__text",
        domProps: { textContent: _vm._s(_vm.text) },
      }),
      !_vm.addable
        ? _c("telia-icon", {
            attrs: {
              name: _vm.invalid ? "alert" : "chevron-right",
              size: "sm",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }