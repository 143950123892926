var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "heading-wrapper" },
      [
        _c("telia-heading", { attrs: { tag: "h5", variant: "title-100" } }, [
          _c("strong", [
            _vm._v(_vm._s("DataNet " + _vm.t("DATANET_" + _vm.product.id))),
          ]),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "items" }, [
        _c("div", [
          _c("img", { staticClass: "image", attrs: { src: _vm.productImage } }),
        ]),
        _c(
          "div",
          { staticClass: "double-size" },
          [
            _c(
              "telia-heading",
              {
                staticClass: "heading-wrapper",
                attrs: { tag: "h5", variant: "title-100" },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.t("DATANET_" + _vm.product.id)) +
                    "\n        "
                ),
              ]
            ),
            _c("telia-p", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.t("DATANET_" + _vm.product.id + "_DESCRIPTION")) +
                  "\n        "
              ),
            ]),
            _vm.product.estimatedInstallationDate
              ? _c("telia-p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.t("DATANET_PREL_DELIVERY_DATE") +
                          ": " +
                          _vm.product.estimatedInstallationDate
                      ) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "telia-heading",
              {
                staticClass: "heading-wrapper",
                attrs: { tag: "h5", variant: "title-100" },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.t("DATANET_SPEEDS")) +
                    "\n        "
                ),
              ]
            ),
            _c(
              "ul",
              { staticClass: "list" },
              [
                _c(
                  "telia-p",
                  _vm._l(_vm.bandwidthsToDisplay, function (bandwidth, index) {
                    return _c("li", {
                      key: _vm.product.id + "-" + bandwidth + index,
                      staticClass: "list-item",
                      attrs: {
                        title: _vm.createBandwidthLabel(
                          bandwidth.downRateKbit,
                          bandwidth.upRateKbit
                        ),
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.createBandwidthLabel(
                            bandwidth.downRateKbit,
                            bandwidth.upRateKbit
                          )
                        ),
                      },
                    })
                  }),
                  0
                ),
              ],
              1
            ),
            _vm.nrOfBandwidthsNotDisplayed > 0
              ? _c("telia-p", { staticClass: "additional-info" }, [
                  _c("em", [
                    _vm._v(
                      "\n            + " +
                        _vm._s(_vm.nrOfBandwidthsNotDisplayed) +
                        "\n            " +
                        _vm._s(
                          _vm.nrOfBandwidthsNotDisplayed === 1
                            ? _vm.t("DATANET_NUMBER_OF_SPEED")
                            : _vm.t("DATANET_NUMBER_OF_SPEEDS")
                        ) +
                        "\n          "
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "double-size" },
          [
            _c(
              "telia-heading",
              {
                staticClass: "heading-wrapper",
                attrs: { tag: "h5", variant: "title-100" },
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.t("DATANET_SLA")) + "\n        "
                ),
              ]
            ),
            _c(
              "ul",
              { staticClass: "list" },
              [
                _c(
                  "telia-p",
                  _vm._l(
                    _vm.serviceLevelAgreementsToDisplay,
                    function (sla, index) {
                      return _c("li", {
                        key: _vm.product.id + "-" + sla + index,
                        staticClass: "list-item'",
                        attrs: {
                          title: _vm.t("DATANET_" + sla + "_DESCRIPTION"),
                        },
                        domProps: {
                          textContent: _vm._s(
                            _vm.t("DATANET_" + sla + "_DESCRIPTION")
                          ),
                        },
                      })
                    }
                  ),
                  0
                ),
              ],
              1
            ),
            _vm.nrOfServiceLevelAgreementsNotDisplayed > 0
              ? _c("telia-p", { staticClass: "additional-info" }, [
                  _c("em", [
                    _vm._v(
                      "\n            +\n            " +
                        _vm._s(_vm.nrOfServiceLevelAgreementsNotDisplayed) +
                        "\n            " +
                        _vm._s(
                          _vm.nrOfServiceLevelAgreementsNotDisplayed === 1
                            ? _vm.t("DATANET_NUMBER_OF_SLA")
                            : _vm.t("DATANET_NUMBER_OF_SLAS")
                        ) +
                        "\n          "
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          { staticClass: "notification-wrapper" },
          [
            _vm.product.options.quickLaunch
              ? _c(
                  "telia-notification",
                  {
                    attrs: {
                      "t-id": "organisation-alert",
                      "heading-tag": "h4",
                      variant: "inline",
                      status: "information",
                      "html-aria-live": "polite",
                      "html-role": "alert",
                    },
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "heading" }, slot: "heading" },
                      [
                        _c("telia-visually-hidden", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.t("A11Y_STATUS_INFORMATION")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.t("INFORMATION")) +
                            "\n          "
                        ),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      { attrs: { slot: "content" }, slot: "content" },
                      [
                        _c("telia-p", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.t("DATANET_QUICK_LAUNCH_IS_AVAILABLE")
                              ) +
                              "\n            "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm.product.id === _vm.Bearers.WIRELESS
              ? _c(
                  "telia-notification",
                  {
                    attrs: {
                      "t-id": "organisation-alert",
                      "heading-tag": "h4",
                      variant: "inline",
                      status: "information",
                      "html-aria-live": "polite",
                      "html-role": "alert",
                    },
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "heading" }, slot: "heading" },
                      [
                        _c("telia-visually-hidden", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.t("A11Y_STATUS_INFORMATION")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.t("INFORMATION")) +
                            "\n          "
                        ),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      { attrs: { slot: "content" }, slot: "content" },
                      [
                        _c("telia-p", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.t("DATANET_WIRELESS_INFO")) +
                              "\n            "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.selectable
          ? _c(
              "div",
              { staticClass: "button-wrapper" },
              [
                _c("telia-button", {
                  attrs: {
                    "t-id":
                      "select-" + _vm.product.id.toLowerCase() + "-access",
                    type: "button",
                    variant: "expressive",
                  },
                  domProps: { textContent: _vm._s(_vm.t("DATANET_SELECT")) },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("product-selected", _vm.product.id)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }