import { Accesses, Actions, BusinessAreas, DefaultValues, MetadataKeys } from "../constants";
import { t } from "../locale";

export default async function populateStoreWithOrderData(store, orderData) {
  const { dispatch, state, commit } = store;

  const { businessArea, orderType } = orderData || {};

  const { sectionMetadata: metadata = {} } = orderData.metadata || {};

  const {
    contactPersons = {},
    addresses = {},
    invoice = {},
    organisation = {},
    access: accesses = {},
  } = orderData.data || {};

  setGeneralStoreData(dispatch, orderData);
  setContacts(dispatch, contactPersons);
  setAddresses(dispatch, addresses);
  if (invoice) {
    setInvoice(dispatch, invoice);
  }
  setUser(dispatch, organisation);
  setProducts(dispatch, accesses, addresses);
  await setAccesses(dispatch, accesses, commit);
  setOnPremisesInstallation(dispatch, state, Accesses.PRIMARY, accesses.primary);
  if (accesses.secondary && accesses.secondary.onPremisesInstallation) {
    setOnPremisesInstallation(dispatch, state, Accesses.SECONDARY, accesses.secondary);
  }
  await setVpns(
    dispatch,
    metadata,
    businessArea,
    orderType,
    Accesses.PRIMARY,
    accesses.primary,
    accesses.primary.vpn,
    commit
  );
  if (accesses.secondary) {
    await setVpns(
      dispatch,
      metadata,
      businessArea,
      orderType,
      Accesses.SECONDARY,
      accesses.secondary,
      accesses.secondary.vpn,
      commit
    );
  }
}

function setGeneralStoreData(
  dispatch,
  { version = "", accessId = "", orderCaptureId = "", alias = "", metadata }
) {
  const { currentSectionIndex = 0, createdBy = "", isShared = false, returnUrl = "" } = metadata;

  dispatch("setState", {
    accessId,
    orderCaptureId,
    orderAlias: alias,
    version,
    createdBy,
    currentStep: currentSectionIndex + 1,
    isShared,
    returnUrl,
  });
}

function setContacts(dispatch, contacts) {
  if (contacts) {
    Object.keys(contacts).forEach((id) => {
      const contact = contacts[id];

      if (contact) {
        dispatch("contactPersons/setContact", {
          id,
          contact: {
            firstName: contact.firstName,
            lastName: contact.lastName,
            emailAddress: contact.emailAddress,
            phoneNumber: contact.telephoneNumber,
          },
        });
      }
    });
  }
}

function setAddresses(dispatch, addresses) {
  Object.keys(addresses).forEach((key) => {
    const address = addresses[key];

    if (address) {
      const {
        streetName = "",
        streetNumber = "",
        entrance = "",
        postalCode = "",
        city = "",
        country = "",
      } = address || {};

      const fullAddress = `${streetName} ${streetNumber}${entrance || ""}, ${city}` || "";

      let newAddress = {
        fullAddress: fullAddress || "",
        streetName: streetName || "",
        streetNumber: streetNumber || "",
        entrance: entrance || "",
        postalCode: postalCode ? postalCode.toString() : "",
        city: city || "",
        country: country || "",
      };

      if (key === "installation") {
        const {
          apartmentNumber = "",
          installationRoom = "",
          xCoordinate: x = "",
          yCoordinate: y = "",
        } = addresses[key] || {};

        newAddress = Object.assign({}, newAddress, {
          apartmentNumber: apartmentNumber || "",
          installationRoom: installationRoom || "",
          x: x || "",
          y: y || "",
        });
      }

      dispatch("addresses/addAddress", { addressId: key, newAddress });
    }
  });
}

function setInvoice(dispatch, invoice) {
  if (invoice.invoiceAccount) {
    dispatch("invoice/setBillingAccountNumber", invoice.invoiceAccount);
  }

  if (invoice.handledByTeliaFinance) {
    dispatch("invoice/setIsHandledByTeliaFinance", invoice.handledByTeliaFinance);
  }

  if (invoice.teliaFinanceInfo) {
    dispatch("invoice/setTeliaFinanceInfo", invoice.teliaFinanceInfo);
  }
}

function setUser(dispatch, organisation) {
  dispatch("user/setOrganisation", organisation.orgNumber);
}

async function setAccesses(dispatch, access, commit) {
  setAccess(dispatch, Accesses.PRIMARY, access.primary, commit);

  if (access.quickLaunch) {
    dispatch("access/enableAccess", Accesses.TEMPORARY);
    access.quickLaunch.fastTrack
      ? dispatch("access/enableFastTrack")
      : dispatch("access/disableFastTrack");
    commit("access/SET_WIRELESS_INSTALLATION", {
      accessKey: Accesses.TEMPORARY,
      boolean: access.quickLaunch.wirelessInstallation,
    });
    commit("access/SET_SLA", {
      accessKey: Accesses.TEMPORARY,
      sla: { new: access.quickLaunch.serviceLevelAgreement },
    });
    commit("access/SET_QOS", {
      accessKey: Accesses.TEMPORARY,
      qos: { new: access.quickLaunch.qualityOfService },
    });

    if (access.quickLaunch.antenna) {
      commit("access/SET_ANTENNA_PACKAGE", {
        accessKey: Accesses.TEMPORARY,
        name: access.quickLaunch.antenna.antennaPackage,
      });
      commit("access/SET_ANTENNA_CABLE", {
        accessKey: Accesses.TEMPORARY,
        boolean: access.quickLaunch.antenna.cable,
      });
      commit("access/SET_ANTENNA_INSTALLATION", {
        accessKey: Accesses.TEMPORARY,
        boolean: access.quickLaunch.antenna.installation,
      });
    }
  }

  if (access.extendedAvailability) {
    dispatch("access/enableAccess", Accesses.SECONDARY);
    commit(
      "access/SET_TEMP_ACCESS_AS_SECONDARY",
      access.extendedAvailability.continueFromQuickLaunch
    );
    dispatch("access/setBackup", access.extendedAvailability.type);
  }

  if (access.secondary) {
    setAccess(dispatch, Accesses.SECONDARY, access.secondary, commit);
  }
}

async function setAccess(dispatch, accessKey, access, commit) {
  if (access.accessType.bandwidth) {
    const { upRateKbit = null, downRateKbit = null } = access.accessType.bandwidth;

    let currentBandwidth =
      downRateKbit.current != null && upRateKbit.current != null
        ? `${downRateKbit.current}${upRateKbit.current}`
        : "";
    let newBandwidth =
      downRateKbit.new != null && upRateKbit.new != null
        ? `${downRateKbit.new}${upRateKbit.new}`
        : "";

    if (!newBandwidth && currentBandwidth) {
      newBandwidth = currentBandwidth;
    }

    commit("access/SET_BANDWIDTH", {
      accessKey,
      bandwidth: {
        current: currentBandwidth,
        new: newBandwidth,
      },
    });
  }

  if (access.serviceLevelAgreement) {
    let currentSla = access.serviceLevelAgreement.current;
    let newSla = access.serviceLevelAgreement.new;

    if (!newSla && currentSla) {
      newSla = currentSla;
    }

    commit("access/SET_SLA", {
      accessKey,
      sla: {
        current: currentSla,
        new: newSla,
      },
    });
  }

  if (access.qualityOfService) {
    let currentQos = access.qualityOfService.type.current;
    let newQos = access.qualityOfService.type.new;

    if (!newQos && currentQos) {
      newQos = currentQos;
    }

    commit("access/SET_QOS", {
      accessKey,
      qos: {
        current: currentQos,
        new: newQos,
      },
    });
  }

  if (access.options) {
    setAccessOptions(dispatch, accessKey, access.options, commit);
  }
}

function setProducts(dispatch, accesses, addresses) {
  dispatch("products/setBearer", {
    value: accesses.primary.accessType.bearer,
  });

  if (addresses.installation.apartmentNumber) {
    dispatch("apartments/setApartment", addresses.installation.apartmentNumber);
  }

  if (accesses.secondary) {
    dispatch("products/setSecondaryBearer", accesses.secondary.accessType.bearer);
  }
}

function setOnPremisesInstallation(dispatch, state, accessKey, access) {
  if (access.onPremisesInstallation) {
    const { choice, connectionSettings, consentGiven } = access.onPremisesInstallation;

    if (choice) {
      const choices = Object.values(state.onPremisesInstallation.choices);
      const selectedChoice = choices.find((element) => t(element.text) === choice);
      if (selectedChoice) {
        dispatch("onPremisesInstallation/setChoice", {
          accessKey,
          id: selectedChoice.id,
        });
      }
    }

    if (connectionSettings) {
      dispatch("onPremisesInstallation/setPair", {
        accessKey,
        value: connectionSettings.pair || "",
      });
      dispatch("onPremisesInstallation/setPunchDownBlock", {
        accessKey,
        value: connectionSettings.punchDownBlock || "",
      });
      dispatch("onPremisesInstallation/setStand", {
        accessKey,
        value: connectionSettings.stand || "",
      });
    }

    dispatch("onPremisesInstallation/setConsentGiven", {
      accessKey,
      boolean: consentGiven,
    });
  }
}

function setAccessOptions(dispatch, accessKey, options, commit) {
  if (options.wirelessInstallation) {
    commit("access/SET_WIRELESS_INSTALLATION", {
      accessKey,
      boolean: options.wirelessInstallation,
    });
  }

  if (options.trafficStatistics) {
    commit("access/SET_TRAFFIC_STATISTICS", {
      accessKey,
      trafficStatistics: {
        current: options.trafficStatistics.current,
        new:
          options.trafficStatistics.new === null
            ? options.trafficStatistics.current
            : options.trafficStatistics.new,
      },
    });
  }

  if (options.antenna) {
    commit("access/SET_ANTENNA_PACKAGE", {
      accessKey,
      name: options.antenna.antennaPackage,
    });
    commit("access/SET_ANTENNA_CABLE", {
      accessKey,
      boolean: options.antenna.cable,
    });
    commit("access/SET_ANTENNA_INSTALLATION", {
      accessKey,
      boolean: options.antenna.installation,
    });
  }

  if (options.internetOnSite) {
    if (options.internetOnSite.action) {
      dispatch("access/setInternetOnSiteAction", {
        accessKey,
        action: options.internetOnSite.action,
      });
    }
    if (options.internetOnSite.ripeNumber) {
      dispatch("access/setInternetOnSiteRipeNumber", {
        accessKey,
        ripeNumber: options.internetOnSite.ripeNumber,
      });
    }
    if (options.internetOnSite.lanInterface) {
      dispatch("access/setInternetOnSiteLanInterface", {
        accessKey,
        lanInterface: options.internetOnSite.lanInterface,
      });
    }
    if (options.internetOnSite.lanConfig.capacity.dynamic) {
      dispatch("access/setInternetOnSiteUseDynamicCapacity", {
        accessKey,
        useDynamicCapacity: options.internetOnSite.lanConfig.capacity.dynamic,
      });
    }
    if (options.internetOnSite.lanConfig.capacity.bandwidth) {
      dispatch("access/setInternetOnSiteStaticBandwidth", {
        accessKey,
        staticBandwidth: options.internetOnSite.lanConfig.capacity.bandwidth,
      });
    }
    if (options.internetOnSite.lanConfig.ethernetInterface) {
      dispatch("access/setInternetOnSiteExtraEthernetInterfaces", {
        accessKey,
        extraEthernetInterfaces: options.internetOnSite.lanConfig.ethernetInterface,
      });
    }
  }

  if (options.snmp) {
    if (options.snmp.action) {
      switch (options.snmp.action) {
        case Actions.ADD:
          dispatch("access/setSnmpActionAdd", accessKey);
          break;
        case Actions.UPDATE:
          dispatch("access/setSnmpActionUpdate", accessKey);
          break;
        case Actions.DELETE:
          dispatch("access/setSnmpActionDelete", accessKey);
          break;
        default:
          dispatch("access/setSnmpActionNone", accessKey);
          break;
      }
    }

    if (options.snmp.communities) {
      options.snmp.communities.forEach((community, communityIndex) => {
        dispatch("access/addSnmpCommunity", accessKey);

        switch (community.action) {
          case Actions.ADD:
            dispatch("access/setSnmpCommunityActionAdd", {
              accessKey,
              communityIndex,
            });
            break;
          case Actions.UPDATE:
            dispatch("access/setSnmpCommunityActionUpdate", {
              accessKey,
              communityIndex,
            });
            break;
          case Actions.DELETE:
            dispatch("access/setSnmpCommunityActionDelete", {
              accessKey,
              communityIndex,
            });
            break;
          default:
            dispatch("access/setSnmpCommunityActionNone", {
              accessKey,
              communityIndex,
            });
            break;
        }

        dispatch("access/setSnmpCommunityName", {
          accessKey,
          communityIndex,
          communityName: community.community,
        });

        if (community.networks) {
          community.networks.forEach((network, networkIndex) => {
            dispatch("access/addSnmpNetwork", {
              accessKey,
              communityIndex,
            });

            dispatch("access/setSnmpNetwork", {
              accessKey,
              communityIndex,
              networkIndex,
              network: {
                action: network.action,
                input: {
                  current: createNetworkInput(network.ipAddress.current, network.prefix.current),
                  new: createNetworkInput(network.ipAddress.new, network.prefix.new),
                },
                ipVersion: network.ipVersion,
                ipAddress: network.ipAddress,
                ipPrefix: network.prefix,
              },
            });
          });
        }
      });
    }
  }
}

function createNetworkInput(ipAddress = "", prefix = "") {
  return `${ipAddress || ""}${prefix ? "/" + prefix : ""}`;
}

async function setVpns(
  dispatch,
  metadata,
  businessArea,
  orderType,
  accessKey,
  access,
  vpn,
  commit
) {
  if (access.qualityOfService && access.qualityOfService.rtTotBandwidth) {
    const { rtTotBandwidth } = access.qualityOfService;
    const currentRtTotBandwidth =
      rtTotBandwidth.current === null ? DefaultValues.NONE : rtTotBandwidth.current;
    const newRtTotBandwidth =
      rtTotBandwidth.new === null
        ? currentRtTotBandwidth === DefaultValues.NONE
          ? DefaultValues.NONE
          : currentRtTotBandwidth
        : rtTotBandwidth.new;
    commit("vpn/SET_RT_TOT", {
      accessKey,
      rtTot: {
        current: currentRtTotBandwidth,
        new: newRtTotBandwidth,
      },
    });
  }

  if (vpn) {
    if (vpn.bandwidthDistribution) {
      commit("vpn/SET_BANDWIDTH_DISTRIBUTION", {
        accessKey,
        bandwidthDistribution: {
          current: vpn.bandwidthDistribution.current,
          new: vpn.bandwidthDistribution.new || vpn.bandwidthDistribution.current,
        },
      });
    }

    if (vpn.vpnInterface) {
      commit("vpn/SET_VPN_INTERFACE", vpn.vpnInterface);
    }

    if (vpn.vpns) {
      for (const vpnKey in vpn.vpns) {
        await addVpn(dispatch, accessKey, vpn.vpns[vpnKey], commit);
      }
    }
  }

  const shouldSetMultiVpn =
    (vpn && vpn.vpns && vpn.vpns.length > 1) ||
    (businessArea === BusinessAreas.FUNCTION &&
      metadata[MetadataKeys.VPN] &&
      metadata[MetadataKeys.VPN].max > 1);

  if (accessKey === Accesses.PRIMARY && shouldSetMultiVpn) {
    commit("vpn/SET_MULTI_VPN", true);
  }
}

async function addVpn(dispatch, accessKey, vpn = {}, commit) {
  if (!vpn) {
    return;
  }

  const {
    action = "",
    isPrimary = false,
    isExisting = false,
    isIncludedInBackup = false,
    key,
    siblingKey,
    id: vpnId = "",
    alias = "",
    bandwidth = {},
    lans = {},
    options = {},
  } = vpn;

  const vpnKey =
    accessKey === Accesses.PRIMARY
      ? await dispatch("vpn/addVpn", {
          key,
          siblingKey,
        })
      : key;

  commit("vpn/SET_ACTION", { vpnKey, action });

  if (bandwidth) {
    const currentBandwidth = bandwidth.upRateKbit.current ? bandwidth.upRateKbit.current : "";
    const newBandwidth = bandwidth.upRateKbit.new ? bandwidth.upRateKbit.new : "";
    commit("vpn/SET_BANDWIDTH", {
      vpnKey,
      bandwidth: { current: currentBandwidth, new: newBandwidth },
    });
  }

  if (isPrimary) {
    dispatch("vpn/setIsPrimary", { vpnKey, isPrimary });
  }

  if (isExisting) {
    dispatch("vpn/setIsExisting", { vpnKey, isExisting });
  }

  if (isIncludedInBackup) {
    dispatch("vpn/setIsIncludedInBackup", { vpnKey, isIncludedInBackup });
  }

  if (vpnId) {
    dispatch("vpn/setVpnId", { vpnKey, vpnId });
  }

  if (alias) {
    dispatch("vpn/setAlias", { vpnKey, alias });
  }

  const { qualityOfService = {}, dhcpRelays = {}, dhcpServers = {}, staticRoutes = {} } =
    options || {};
  if (qualityOfService && Object.keys(qualityOfService).length) {
    handleQosForVpn(commit, vpnKey, qualityOfService);
  }

  const dhcpRelaysAction = dhcpRelays.action;
  if (dhcpRelaysAction) {
    commit("vpn/SET_DHCP_RELAYS_ACTION", { vpnKey, action: dhcpRelaysAction });
  }

  if (lans && lans.length) {
    let allDhcpRelays = dhcpRelays.values || [];

    for (const lanKey in lans) {
      await addLan(dispatch, accessKey, vpnKey, lans[lanKey], commit);

      if (allDhcpRelays && allDhcpRelays.length > 0) {
        const lanId = lans[lanKey].key;
        const lanDhcpRelays = allDhcpRelays.filter((relay) => relay.lanKey === lanId);
        for (let i = 0; i < lanDhcpRelays.length; i++) {
          addDhcpRelay({ dispatch, lan: lans[lanKey], dhcpRelay: lanDhcpRelays[i] });
        }
      }
    }
  }

  if (dhcpServers && Object.keys(dhcpServers).length) {
    const { action: dhcpServersAction, values } = dhcpServers;

    if (dhcpServersAction) {
      commit("dhcpServer/SET_ACTION", { action: dhcpServersAction });
    }

    if (values) {
      for (const serverKey in values) {
        await addDhcpServer(dispatch, accessKey, vpnKey, values[serverKey], commit);
      }
    }
  }

  if (staticRoutes && Object.keys(staticRoutes).length) {
    const { values } = staticRoutes;

    for (const routeKey in values) {
      await addStaticRoute(dispatch, accessKey, vpnKey, values[routeKey]);
    }
  }
}

function handleQosForVpn(commit, vpnKey, qualityOfService) {
  if (!qualityOfService) return;

  const { type, rt1Bandwidth, rt2Bandwidth } = qualityOfService;

  if (type) {
    commit("vpn/SET_QOS", {
      vpnKey,
      qos: { current: type.current, new: type.new },
    });
  }

  if (rt1Bandwidth) {
    commit("vpn/SET_RT1", {
      vpnKey,
      rt1: { current: rt1Bandwidth.current, new: rt1Bandwidth.new },
    });
  }

  if (rt2Bandwidth) {
    commit("vpn/SET_RT2", {
      vpnKey,
      rt2: {
        current: rt2Bandwidth.current,
        new: rt2Bandwidth.new,
      },
    });
  }
}

async function addLan(dispatch, accessKey, vpnKey, lan, commit) {
  if (!lan) return;

  const { key: lanKey, siblingKey, networks = [] } = lan || {};

  const addedLanKey =
    accessKey === Accesses.PRIMARY
      ? await dispatch("lans/addLan", {
          vpnKey,
          lanKey,
          siblingKey,
        })
      : lanKey;

  commit("lans/SET_ACTION", { lanKey, action: lan.action || "" });

  if (lan.vlanId) {
    dispatch("lans/setVlanId", {
      lanKey: addedLanKey,
      action: lan.vlanId.action || Actions.NONE,
      current: lan.vlanId.current || "",
      new: lan.vlanId.new || "",
    });
  }

  if (lan.identifyingNetwork) {
    const { ipVersion = null, ipAddress = null, prefix: ipPrefix = null } =
      lan.identifyingNetwork || {};

    const identifyingNetworkInput = createNetworkInput(ipAddress.new, ipPrefix.new);

    commit("lans/SET_IDENTIFYING_NETWORK_INPUT", {
      lanKey: addedLanKey,
      identifyingNetworkInput: {
        lanKey: addedLanKey,
        action: null,
        current: null,
        new: identifyingNetworkInput,
      },
    });

    commit("lans/SET_IDENTIFYING_NETWORK_IP_ADDRESS", {
      lanKey: addedLanKey,
      identifyingNetworkIpAddress: {
        lanKey: addedLanKey,
        ...ipAddress,
      },
    });

    commit("lans/SET_IDENTIFYING_NETWORK_IP_VERSION", {
      lanKey: addedLanKey,
      identifyingNetworkIpVersion: {
        lanKey: addedLanKey,
        ...ipVersion,
      },
    });

    commit("lans/SET_IDENTIFYING_NETWORK_IP_PREFIX", {
      lanKey: addedLanKey,
      identifyingNetworkIpPrefix: {
        lanKey: addedLanKey,
        ...ipPrefix,
      },
    });
  }

  if (networks) {
    networks.forEach((network, index) => {
      const {
        action: networkAction = "",
        ipVersion = null,
        ipAddress = null,
        prefix: ipPrefix = null,
        vIp = null,
      } = network || {};

      if (accessKey === Accesses.PRIMARY) {
        dispatch("lans/addNetwork", addedLanKey);
      }
      if (networkAction) {
        commit("lans/SET_NETWORK_ACTION", {
          lanKey: addedLanKey,
          index,
          action: network.action || "",
        });
      }

      if (ipAddress) {
        const currentNetworkInput = createNetworkInput(ipAddress.current, ipPrefix.current);
        const newNetworkInput = createNetworkInput(ipAddress.new, ipPrefix.new);
        commit("lans/SET_NETWORK_INPUT", {
          lanKey: addedLanKey,
          index,
          input: {
            lanKey: addedLanKey,
            action: networkAction || "",
            current: currentNetworkInput,
            new: newNetworkInput,
          },
        });
        commit("lans/SET_NETWORK_IP_ADDRESS", {
          lanKey: addedLanKey,
          index,
          ipAddress: {
            lanKey: addedLanKey,
            action: networkAction || "",
            current: ipAddress.current || "",
            new: ipAddress.new || "",
          },
        });
      }

      if (ipVersion) {
        commit("lans/SET_NETWORK_IP_VERSION", {
          lanKey: addedLanKey,
          index,
          ipVersion: {
            lanKey: addedLanKey,
            action: networkAction || "",
            current: ipVersion.current || "",
            new: ipVersion.new || "",
          },
        });
      }

      if (ipPrefix) {
        commit("lans/SET_NETWORK_IP_PREFIX", {
          lanKey: addedLanKey,
          index,
          ipPrefix: {
            lanKey: addedLanKey,
            action: networkAction || "",
            current: ipPrefix.current || "",
            new: ipPrefix.new || "",
          },
        });
      }

      if (vIp) {
        dispatch("lans/setNetworkVirtualIp", {
          lanKey: addedLanKey,
          index,
          action: networkAction || "",
          current: vIp.current || "",
          new: vIp.new || "",
        });
      }
    });
  }
}

const addDhcpRelay = ({ dispatch, lan, dhcpRelay }) => {
  if (dhcpRelay) {
    dispatch("ips/addIp", {
      key: dhcpRelay.key,
      ownerKey: lan.key,
      action: dhcpRelay.action,
    });
    dispatch("ips/addIp", {
      key: dhcpRelay.siblingKey,
      ownerKey: lan.siblingKey,
      action: dhcpRelay.action,
    });
    dispatch("ips/setSibling", {
      key: dhcpRelay.key,
      siblingKey: dhcpRelay.siblingKey,
    });
    dispatch("ips/setIp", {
      key: dhcpRelay.key,
      ipAddress: { ownerKey: dhcpRelay.key, ...dhcpRelay.ipAddress },
      ipVersion: { ownerKey: dhcpRelay.key, ...dhcpRelay.ipVersion },
    });
    dispatch("lans/addDhcpRelay", { lanKey: lan.key, dhcpRelayKey: dhcpRelay.key });
    dispatch("lans/addDhcpRelay", {
      lanKey: lan.siblingKey,
      dhcpRelayKey: dhcpRelay.siblingKey,
    });
  }
};

async function addDhcpServer(
  dispatch,
  accessKey,
  vpnKey = DefaultValues.NONE,
  dhcpServer = {},
  commit
) {
  const {
    action = "",
    key = -1,
    siblingKey = -1,
    network = {},
    excludedIpRanges = {},
    dnsIps = {},
  } = dhcpServer;

  const serverKey =
    accessKey === Accesses.PRIMARY
      ? await dispatch("dhcpServer/addServer", {
          vpnKey,
          serverKey: key,
          serverSiblingKey: siblingKey,
        })
      : key;

  commit("dhcpServer/SET_SERVER_ACTION", { dhcpServerKey: serverKey, action });

  const { action: networkAction, ipVersion = null, ipAddress = null, prefix: ipPrefix = null } =
    network || {};

  commit("dhcpServer/SET_NETWORK_ACTION", { dhcpServerKey: serverKey, action: networkAction });

  if (ipAddress && ipPrefix) {
    const currentNetworkInput = createNetworkInput(ipAddress.current, ipPrefix.current);
    const newNetworkInput = createNetworkInput(ipAddress.new, ipPrefix.new);
    commit("dhcpServer/SET_NETWORK_INPUT", {
      serverKey,
      input: {
        serverKey,
        action: ipAddress.action,
        current: currentNetworkInput,
        new: newNetworkInput,
      },
    });
    commit("dhcpServer/SET_NETWORK_IP_ADDRESS", {
      serverKey,
      ipAddress: {
        serverKey,
        action: ipAddress.action,
        current: ipAddress.current,
        new: ipAddress.new,
      },
    });
    commit("dhcpServer/SET_NETWORK_IP_PREFIX", {
      serverKey,
      ipPrefix: {
        serverKey,
        action: ipPrefix.action,
        current: ipPrefix.current,
        new: ipPrefix.new,
      },
    });

    if (ipVersion) {
      commit("dhcpServer/SET_NETWORK_IP_VERSION", {
        serverKey,
        ipVersion: {
          serverKey,
          action: ipVersion.action,
          current: ipVersion.current,
          new: ipVersion.new,
        },
      });
    }
  }

  excludedIpRanges.values.forEach((range, index) => {
    if (accessKey === Accesses.PRIMARY) {
      dispatch("dhcpServer/addExcludedIpRange", { serverKey, action: range.action });
    }

    const { ipVersion: startIpVersion = null, ipAddress: startIpAddress = null } = range.start;
    const { ipVersion: endIpVersion = null, ipAddress: endIpAddress = null } = range.end;

    if (startIpVersion) {
      dispatch("dhcpServer/setExcludedRangeStartIpVersion", {
        serverKey,
        index,
        action: startIpVersion.action,
        current: startIpVersion.current || "",
        new: startIpVersion.new || "",
      });
    }

    if (startIpAddress) {
      dispatch("dhcpServer/setExcludedRangeStartIpAddress", {
        serverKey,
        index,
        action: startIpAddress.action,
        current: startIpAddress.current || "",
        new: startIpAddress.new || "",
      });
    }

    if (endIpVersion) {
      dispatch("dhcpServer/setExcludedRangeEndIpVersion", {
        serverKey,
        index,
        action: endIpVersion.action,
        current: endIpVersion.current || "",
        new: endIpVersion.new || "",
      });
    }

    if (endIpAddress) {
      dispatch("dhcpServer/setExcludedRangeEndIpAddress", {
        serverKey,
        index,
        action: endIpAddress.action,
        current: endIpAddress.current || "",
        new: endIpAddress.new || "",
      });
    }
  });

  dnsIps.values.forEach((ip, index) => {
    if (accessKey === Accesses.PRIMARY) {
      dispatch("dhcpServer/addDnsIp", serverKey);
    }

    const { ipVersion: dnsIpVersion = null, ipAddress: dnsIpAddress = null } = ip;

    if (dnsIpVersion) {
      dispatch("dhcpServer/setDnsIpVersion", {
        serverKey,
        index,
        action: dnsIpVersion.action,
        current: dnsIpVersion.current || "",
        new: dnsIpVersion.new || "",
      });
    }

    if (dnsIpAddress) {
      dispatch("dhcpServer/setDnsIpAddress", {
        serverKey,
        index,
        action: dnsIpAddress.action,
        current: dnsIpAddress.current || "",
        new: dnsIpAddress.new || "",
      });
    }
  });
}

async function addStaticRoute(dispatch, accessKey, vpnKey = DefaultValues.NONE, staticRoute = {}) {
  const { action = "", key = -1, siblingKey = -1, network = {}, nextHop = {} } = staticRoute || {};

  const routeKey =
    accessKey === Accesses.PRIMARY
      ? await dispatch("staticRoutes/addRoute", {
          vpnKey,
          staticRouteKey: key,
          staticRouteSiblingKey: siblingKey,
        })
      : key;

  if (action) {
    switch (action) {
      case Actions.ADD:
        dispatch("staticRoutes/setActionAdd", routeKey);
        break;
      case Actions.UPDATE:
        dispatch("staticRoutes/setActionUpdate", routeKey);
        break;
      case Actions.DELETE:
        dispatch("staticRoutes/setActionDelete", routeKey);
        break;
      default:
        dispatch("staticRoutes/setActionNone", routeKey);
    }
  }

  const {
    action: networkAction,
    ipVersion: networkIpVersion = null,
    ipAddress: networkIpAddress = null,
    prefix: networkIpPrefix = null,
  } = network || {};
  const {
    action: nextHopAction,
    ipVersion: nextHopIpVersion = null,
    ipAddress: nextHopIpAddress = null,
  } = nextHop || {};

  if (networkIpAddress && networkIpPrefix) {
    const currentNetworkInput = createNetworkInput(
      networkIpAddress.current,
      networkIpPrefix.current
    );
    const newNetworkInput = createNetworkInput(networkIpAddress.new, networkIpPrefix.new);

    dispatch("staticRoutes/setNetworkInput", {
      routeKey,
      action: networkAction,
      current: currentNetworkInput,
      new: newNetworkInput,
    });
    dispatch("staticRoutes/setNetworkIpAddress", {
      routeKey,
      action: networkAction,
      current: networkIpAddress.current,
      new: networkIpAddress.new,
    });
    dispatch("staticRoutes/setNetworkIpPrefix", {
      routeKey,
      action: networkAction,
      current: networkIpPrefix.current,
      new: networkIpPrefix.new,
    });

    if (networkIpVersion) {
      dispatch("staticRoutes/setNetworkIpVersion", {
        routeKey,
        action: networkAction,
        current: networkIpVersion.current,
        new: networkIpVersion.new,
      });
    }
  }

  if (nextHopIpVersion && nextHopIpAddress) {
    dispatch("staticRoutes/setNextHopIpVersion", {
      routeKey,
      action: nextHopAction,
      current: nextHopIpVersion.current,
      new: nextHopIpVersion.new,
    });

    dispatch("staticRoutes/setNextHopIpAddress", {
      routeKey,
      action: nextHopAction,
      current: nextHopIpAddress.current,
      new: nextHopIpAddress.new,
    });

    dispatch("staticRoutes/setNextHopIsMemberOfLanNetwork", {
      routeKey,
      isMemberOfLanNetwork: true,
    });
  }
}
