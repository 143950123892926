<template>
  <div>
    <div class="input-wrapper">
      <telia-select
        t-id="invoice-account"
        :label="t('DATANET_SELECT_INVOICE_ACCOUNT')"
        :options="JSON.stringify(billingAccountOptions)"
        :value="billingAccountNumber"
        @change="handleInvoiceAccount($event.target.value)"
      />
    </div>
    <ValidationError v-if="$v.billingAccountNumber.$error">
      {{ t("DATANET_INVOICE_ACCOUNT_REQUIRED") }}
    </ValidationError>
    <div class="input-wrapper margin-top">
      <telia-checkbox
        t-id="handled-by-telia-finance"
        name="handled-by-telia-finance"
        :value="!isHandledByTeliaFinance"
        :checked="isHandledByTeliaFinance"
        @change="isHandledByTeliaFinanceHandler($event.target.checked)"
      >
        <telia-p>{{ t("DATANET_INVOICE_IS_HANDLED_BY_TELIA_FINANCE") }}</telia-p>
      </telia-checkbox>
    </div>
    <div class="input-wrapper margin-top">
      <telia-textarea
        v-if="isHandledByTeliaFinance"
        t-id="telia-finance-info"
        :label="t('DATANET_TELIA_FINANCE_INFO')"
        :value="teliaFinanceInfo"
        :additional="t('DATANET_TELIA_FINANCE_INFO_PLACEHOLDER')"
        maxlength="350"
        @input="teliaFinanceInfo = $event.target.value"
      />
    </div>
    <ValidationError v-if="$v.teliaFinanceInfo.$error">
      {{ t("DATANET_TELIA_FINANCE_INFO_ERROR") }}
    </ValidationError>
    <div class="margin-top info-wrapper">
      <telia-p>
        <strong>
          {{ t("DATANET_AGREEMENT_PERIOD") }}
        </strong>
      </telia-p>
      <telia-p>
        {{ t("DATANET_AGREEMENT_PERIOD_INFO") }}
      </telia-p>
    </div>

    <Summary v-if="billingAccount" class="margin-top input-wrapper" :editable="false">
      <telia-p>
        <strong>{{ t("DATANET_INVOICE_ACCOUNT") }}</strong>
      </telia-p>
      <telia-p>
        {{ billingAccountNumber }}
      </telia-p>

      <div v-if="billingAccount.billingAddress.street">
        <telia-p>
          <strong>{{ t("DATANET_INVOICE_ACCOUNT_ADDRESS") }}</strong>
        </telia-p>
        <telia-p v-if="billingAccount.billingAddress.careOf">
          {{ `c/o ${billingAccount.billingAddress.careOf}` }}
        </telia-p>
        <telia-p v-if="billingAccount.billingAddress.street">
          {{ billingAccount.billingAddress.street }}
        </telia-p>
        <telia-p v-if="billingAccount.billingAddress.zipCode">
          {{ `${billingAccount.billingAddress.zipCode} ${billingAccount.billingAddress.city}` }}
        </telia-p>
      </div>

      <telia-p v-if="billingAccount.billingMethodText">
        <strong>{{ t("DATANET_INVOICE_ACCOUNT_METHOD") }}</strong>
      </telia-p>
      <telia-p v-if="billingAccount.billingMethodText">
        {{ billingAccount.billingMethodText }}
      </telia-p>
      <telia-p v-if="billingAccount.billingEmail">
        {{ billingAccount.billingEmail }}
      </telia-p>
    </Summary>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { requiredIf, maxLength } from "vuelidate/lib/validators";
import { mapState, mapGetters, mapActions } from "vuex";
import { translateMixin } from "../locale";
import Summary from "./Summary";
import ValidationError from "./ValidationError.vue";

export default {
  name: "Invoice",
  mixins: [translateMixin, validationMixin],
  components: {
    Summary,
    ValidationError,
  },

  computed: {
    ...mapState("user", {
      scopeId: (state) => state.scopeId,
      organisation: (state) => state.organisation,
    }),

    ...mapGetters("user", ["getOrganisation"]),
    ...mapGetters("invoice", ["getBillingAccountOptions"]),

    defaultOption() {
      if (this.billingAccountNumber) {
        let index = this.billingAccountOptions.findIndex(
          (billingAccountOption) => billingAccountOption.value === this.billingAccountNumber
        );

        return index === -1 ? 0 : index;
      } else {
        return 0;
      }
    },

    billingAccountOptions() {
      return this.getBillingAccountOptions.map((option) => {
        return {
          value: option.value,
          label: option.label,
          selected: option.value === this.billingAccountNumber,
        };
      });
    },

    billingAccountNumber: {
      get() {
        return this.$store.state.invoice.billingAccountNumber;
      },
      set(value) {
        this.$store.dispatch("invoice/setBillingAccountNumber", value);
      },
    },

    billingAccount() {
      return this.$store.getters["invoice/getBillingAccount"](this.billingAccountNumber);
    },

    isHandledByTeliaFinance: {
      get() {
        return this.$store.state.invoice.isHandledByTeliaFinance;
      },
      set(value) {
        this.$store.dispatch("invoice/setIsHandledByTeliaFinance", value);

        if (!value) {
          this.teliaFinanceInfo = null;
        }
      },
    },

    teliaFinanceInfo: {
      get() {
        return this.$store.state.invoice.teliaFinanceInfo;
      },
      set(value) {
        this.$store.dispatch("invoice/setTeliaFinanceInfo", value);
      },
    },
  },

  validations: {
    billingAccountNumber: {
      required: requiredIf(function () {
        return !this.isHandledByTeliaFinance;
      }),
    },
    teliaFinanceInfo: {
      maxLength: maxLength(350),
    },
  },

  methods: {
    ...mapActions("invoice", ["getBillingAccountAddress"]),

    isHandledByTeliaFinanceHandler(event) {
      this.isHandledByTeliaFinance = event;
    },
    completeStep() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("step-complete");
      }
    },
    handleInvoiceAccount(event) {
      this.billingAccountNumber = event;
      const object = {
        scopeId: this.scopeId,
        tscid: this.getOrganisation(this.organisation).tscid,
        accountNumber: event,
      };
      this.getBillingAccountAddress(object);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.input-wrapper {
  max-width: 42rem;
}
.margin-top {
  margin-top: $telia-spacing-20;
}

.info-wrapper {
  max-width: 62rem;
}
</style>
