<template>
  <div class="margin__top__24 order-confirmation">
    <div class="order-summary-container">
      <telia-heading tag="h2" variant="title-400" class="margin__top__8 margin__bottom__8">
        {{ t("DATANET_ORDER_SUBMITTED_TITLE") }}
      </telia-heading>
      <telia-p>
        {{ t("DATANET_ORDER_SUBMITTED_INFO_1") }}
        {{ ` ${orderEmail}.` }}
        {{ `${t("DATANET_ORDER_SUBMITTED_INFO_2")} ` }}
        <a class="link" :href="`/foretag/mybusiness/${scopeId}/support/arenden`">
          {{ `${t("DATANET_CONTACT_SUPPORT")}.` }}
        </a>
      </telia-p>

      <div class="order-summary">
        <telia-p>
          <strong>
            {{ t("DATANET_ORDER_SUMMARY") }}
          </strong>
          <div class="order-summary-info">
            <span class="order-summary-column">
              {{ `${t("DATANET_ORDER_ALIAS")}:` }}
            </span>
            <strong class="order-summary-column">
              {{ getOrderAlias || "-" }}
            </strong>
          </div>
          <div v-if="isProductBusinessOrder && isNewOrder" class="order-summary-info">
            <span class="order-summary-column">
              {{ `${t("DATANET_PREL_DELIVERY_DATE")}:` }}
            </span>
            <strong class="order-summary-column">
              {{ getSelectedInstallationDate || "-" }}
            </strong>
          </div>
          <div v-if="isProductBusinessOrder && isChangeOrder" class="order-summary-info">
            <span class="order-summary-column">
              {{ `${t("DATANET_ORDER_DATE")}:` }}
            </span>
            <strong class="order-summary-column">
              {{ orderDate || "-" }}
            </strong>
          </div>
        </telia-p>
      </div>

      <div class="margin__top__24">
        <telia-button
          v-if="isProductBusinessOrder && isNewOrder"
          variant="expressive"
          @click="goToUrl(`/foretag/mybusiness/${scopeId}`)"
        >
          {{ t("DATANET_GO_TO_START") }}
        </telia-button>
        <telia-button
          v-if="isProductBusinessOrder && isChangeOrder"
          variant="expressive"
          @click="goToUrl(`/foretag/mybusiness/${scopeId}/hantera/produkter-tjanster/datanet-vpn`)"
        >
          {{ t("DATANET_ADMINISTER_DATACOM") }}
        </telia-button>
      </div>

      <div class="margin__top__24 margin__bottom__12">
        <div>
          <telia-p>
            <strong>
              {{ t("DATANET_OTHER_OPTIONS") }}
            </strong>
          </telia-p>
        </div>
        <div v-if="isProductBusinessOrder && isNewOrder">
          <a class="link" :href="`/foretag/mybusiness/${scopeId}/bestall/datanet/oc`">
            {{ t("DATANET_CREATE_NEW_ORDER") }}
          </a>
        </div>
        <div v-if="isProductBusinessOrder && isNewOrder">
          <a
            class="link"
            :href="`/foretag/mybusiness/${scopeId}/hantera/produkter-tjanster/datanet-vpn`"
          >
            {{ t("DATANET_ADMINISTER_DATACOM") }}
          </a>
        </div>
        <div v-if="isProductBusinessOrder && isChangeOrder">
          <a
            class="link"
            :href="`/foretag/mybusiness/${scopeId}/hantera/produkter-tjanster/datanet-vpn/${accessId}`"
          >
            {{ t("DATANET_RETURN_TO_ACCESS", { id: accessId }) }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { translateMixin } from "../locale";

export default {
  name: "OrderSubmitted",
  mixins: [translateMixin],
  computed: {
    ...mapState({
      accessId: (state) => state.accessId,
      scopeId: (state) => state.user.scopeId,
    }),

    ...mapGetters(["isNewOrder", "isChangeOrder", "isProductBusinessOrder", "getOrderAlias"]),

    ...mapGetters("contactPersons", ["getContact"]),

    ...mapGetters("products", ["getSelectedInstallationDate"]),

    orderEmail() {
      return this.getContact("order").emailAddress || "";
    },

    orderDate() {
      const now = new Date(Date.now());
      const day = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();

      return `${day}-${month}-${year}`;
    },
  },
  methods: {
    goToUrl(url) {
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.order-confirmation {
  display: flex;
  justify-content: center;
}

.order-summary-container {
  width: 80%;
  padding: 2rem 16rem;
  border: 1px solid $telia-gray-500;
  box-shadow: 0 0 6px $telia-gray-500;
}

.order-summary {
  margin: $telia-spacing-12 0;
}

.order-summary-column {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-summary-info {
  display: flex;
  justify-content: space-between;
}
.link,
.link:visited,
.link:active {
  color: $telia-purple-500 !important;
  text-decoration: none !important;
  background-color: transparent !important;
}
.link:hover {
  color: $telia-purple-400 !important;
  text-decoration: none !important;
  background-color: transparent !important;
}
.margin {
  &__top {
    &__8 {
      margin-top: $telia-spacing-8;
    }
    &__24 {
      margin-top: $telia-spacing-24;
    }
  }
  &__bottom {
    &__8 {
      margin-bottom: $telia-spacing-8;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
  }
}
</style>
