var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("telia-p", { staticClass: "info-wrapper margin-bottom__24" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.t("DATANET_ORDER_CONFIRMATION_INFORMATION")) +
            "\n  "
        ),
      ]),
      _c(
        "div",
        { staticClass: "input-wrapper margin-bottom__24" },
        [
          _c("telia-text-input", {
            attrs: {
              "t-id": "order-alias",
              label: _vm.t("DATANET_ORDER_ALIAS"),
              disabled: _vm.isSubmitting,
              value: _vm.orderAlias,
            },
            on: {
              input: function ($event) {
                _vm.orderAlias = $event.target.value
              },
            },
          }),
          _c(
            "telia-p",
            {
              staticClass: "additional-text",
              attrs: { variant: "additional-100" },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.t("DATANET_ORDER_ALIAS_PLACEHOLDER")) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "input-wrapper margin-bottom__24" },
        [
          _c("telia-text-input", {
            attrs: {
              id: "order-email",
              type: "email",
              label: _vm.t("DATANET_ORDER_EMAIL"),
              disabled: _vm.isSubmitting,
              value: _vm.orderEmail,
            },
            on: {
              input: function ($event) {
                _vm.orderEmail = $event.target.value
              },
            },
          }),
          _vm.$v.orderEmail.$error
            ? _c("ValidationError", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$v.orderEmail.required
                        ? _vm.t("DATANET_INVALID_EMAIL_ADDRESS_FORMAT")
                        : _vm.t("DATANET_EMAIL_ADDRESS_REQUIRED")
                    ) +
                    "\n    "
                ),
              ])
            : _c(
                "telia-p",
                {
                  staticClass: "additional-text",
                  attrs: { variant: "additional-100" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.t("DATANET_ORDER_EMAIL_PLACEHOLDER")) +
                      "\n    "
                  ),
                ]
              ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info-wrapper margin-bottom__12" },
        [
          _c("telia-p", [
            _c("strong", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.t("DATANET_DELIVERY_TIME")) +
                  "\n      "
              ),
            ]),
          ]),
          _c("telia-p", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.t("DATANET_DELIVERY_TIME_INFO")) +
                "\n    "
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "margin-bottom__32" },
        [
          _c(
            "telia-checkbox",
            {
              attrs: {
                "t-id": "consent",
                disabled: _vm.isSubmitting,
                value: !_vm.orderConsentGiven,
                checked: _vm.orderConsentGiven,
              },
              on: {
                change: function ($event) {
                  _vm.orderConsentGiven = $event.target.checked
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.t("DATANET_ORDER_CONFIRMATION_CONSENT")) +
                  "\n    "
              ),
            ]
          ),
          _vm.$v.orderConsentGiven.$error
            ? _c("ValidationError", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.t("DATANET_ORDER_CONFIRMATION_CONSENT_REQUIRED")
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.alert.show
        ? _c(
            "div",
            { staticClass: "info-wrapper margin-bottom__24" },
            [
              _c(
                "telia-notification",
                {
                  attrs: {
                    "heading-tag": "h4",
                    variant: "inline",
                    status: "warning",
                    "html-aria-live": "polite",
                    "html-role": "alert",
                  },
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "heading" }, slot: "heading" },
                    [
                      _c("telia-visually-hidden", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.t("A11Y_STATUS_WARNING")) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.t("SOMETHING_WENT_WRONG")) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { attrs: { slot: "content" }, slot: "content" },
                    [
                      _c("telia-p", [
                        _vm._v(
                          "\n          " + _vm._s(_vm.alert.text) + "\n        "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "telia-button",
        {
          attrs: {
            "t-id": "submit-order",
            type: "button",
            variant: "expressive",
            disabled: _vm.isSubmitting || _vm.isTeliaAdmin,
          },
          on: {
            click: function ($event) {
              return _vm.submitOrder()
            },
          },
        },
        [
          _c("telia-p", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.isSubmitting
                    ? _vm.t("DATANET_SUBMITTING_ORDER")
                    : _vm.t("DATANET_PLACE_ORDER")
                ) +
                "\n    "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }