<template>
  <div class="margin-bottom">
    <slot />
  </div>
</template>

<script>
export default {
  name: "SummaryList",
};
</script>
<style lang="scss" scoped>
.margin-buttom {
  margin-bottom: 0.8rem;
}
</style>
