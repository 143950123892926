import generateShortId from "../../../helpers/generateShortId";
import updateExistingObjectProperties from "../../../helpers/updateExistingObjectProperties";

const actions = {
  addIp({ state, commit, dispatch }, { key = generateShortId(), ownerKey = "", action = "" }) {
    if (state[key]) return;
    commit("ADD_IP", { key, ownerKey, action });
    dispatch("setUnsavedDraft", undefined, { root: true });
    return key;
  },

  deleteIp({ state, commit, dispatch }, key) {
    const { siblingKey } = state[key];
    if (siblingKey) commit("SET_SIBLING", { key: siblingKey, siblingKey: "" });
    commit("DELETE_IP", key);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setSibling({ commit, dispatch }, { key, siblingKey }) {
    commit("SET_SIBLING", { key, siblingKey });
    commit("SET_SIBLING", { key: siblingKey, siblingKey: key });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setIp({ state, commit, dispatch }, { key, action, ipAddress, ipVersion, resetSibling = false }) {
    const ip = state[key];
    const updatedIpAddress = updateExistingObjectProperties(ip.ipAddress, {
      action,
      ...ipAddress,
    });
    const updatedIpVersion = updateExistingObjectProperties(ip.ipVersion, {
      action,
      ...ipVersion,
    });
    commit("SET_IP", { key, ipAddress: updatedIpAddress, ipVersion: updatedIpVersion });
    if (resetSibling && ip.action !== action) {
      dispatch("setIp", {
        key: ip.siblingKey,
        ipAddress: { action, current: "", new: "" },
        ipVersion: { action, current: "", new: "" },
      });
    }
    dispatch("setUnsavedDraft", undefined, { root: true });
  },
};

export default actions;
