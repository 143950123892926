<template>
  <div t-id="multi-vpn">
    <telia-heading tag="h4" variant="title-100">
      {{ t("DATANET_VPN_MULTI_VPN") }}
    </telia-heading>
    <telia-fieldset>
      <div class="chip-choice-wrapper">
        <telia-chip-choice
          v-for="option in getMultiVpnOptions"
          :key="option.value"
          :value="option.value"
          :checked="multiVpn === option.value"
          :disabled="option.disabled"
          @change="handleInput(option.value)"
        >
          {{ option.label }}
        </telia-chip-choice>
      </div>
    </telia-fieldset>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { translateMixin } from "../../locale";

export default {
  name: "MultiVpn",

  mixins: [translateMixin],

  computed: {
    ...mapState("vpn", {
      multiVpn: (state) => state.multiVpn,
    }),

    ...mapGetters("vpn", ["getMultiVpnOptions"]),
  },

  methods: {
    ...mapActions("vpn", ["setMultiVpn"]),

    handleInput(value) {
      this.$emit("click");
      this.setMultiVpn(value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
.chip-choice-wrapper {
  display: flex;
}
.chip-choice-wrapper > * {
  margin-right: $telia-spacing-12;
}
</style>
