var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showContactInfoById === "technical"
      ? _c(
          "div",
          { staticClass: "technical-contact-information" },
          [
            _c(
              "telia-heading",
              { attrs: { tag: "h5", variant: "title-100" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.t("DATANET_GUIDANCE_TECHNICAL_CONTACT_TITLE")) +
                    "\n    "
                ),
              ]
            ),
            _c("telia-p", { staticClass: "margin-bottom__12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.t("DATANET_GUIDANCE_TECHNICAL_CONTACT_INFO")) +
                  "\n    "
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.showContactInfoById === "facility"
      ? _c(
          "div",
          { staticClass: "local-contact-information" },
          [
            _c(
              "telia-heading",
              { attrs: { tag: "h5", variant: "title-100" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.t("DATANET_GUIDANCE_LOCAL_CONTACT_TITLE")) +
                    "\n    "
                ),
              ]
            ),
            _c("telia-p", { staticClass: "margin-bottom__12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.t("DATANET_GUIDANCE_LOCAL_CONTACT_INFO")) +
                  "\n    "
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.showContactInfoById === "delivery"
      ? _c(
          "div",
          [
            _c(
              "telia-heading",
              { attrs: { tag: "h5", variant: "title-100" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.t("DATANET_GUIDANCE_DELIVERY_CONTACT_TITLE")) +
                    "\n    "
                ),
              ]
            ),
            _c("telia-p", { staticClass: "margin-bottom__12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.t("DATANET_GUIDANCE_DELIVERY_CONTACT_INFO")) +
                  "\n    "
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.showContactInfoById === "wirelessTechnical"
      ? _c(
          "div",
          [
            _c(
              "telia-heading",
              { attrs: { tag: "h5", variant: "title-100" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.t("DATANET_GUIDANCE_WIRELESS_TECHNICAL_CONTACT_TITLE")
                    ) +
                    "\n    "
                ),
              ]
            ),
            _c("telia-p", { staticClass: "margin-bottom__12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.t("DATANET_GUIDANCE_WIRELESS_TECHNICAL_CONTACT_INFO")
                  ) +
                  "\n    "
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.showContactInfoById === "wirelessSms"
      ? _c(
          "div",
          [
            _c(
              "telia-heading",
              { attrs: { tag: "h5", variant: "title-100" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.t("DATANET_GUIDANCE_WIRELESS_SMS_CONTACT_TITLE")
                    ) +
                    "\n    "
                ),
              ]
            ),
            _c("telia-p", { staticClass: "margin-bottom__12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.t("DATANET_GUIDANCE_WIRELESS_SMS_CONTACT_INFO")) +
                  "\n    "
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.showContactInfoById === "secondaryFacility"
      ? _c(
          "div",
          [
            _c(
              "telia-heading",
              { attrs: { tag: "h5", variant: "title-100" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.t("DATANET_GUIDANCE_SECONDARY_FACILITY_CONTACT_TITLE")
                    ) +
                    "\n    "
                ),
              ]
            ),
            _c("telia-p", { staticClass: "margin-bottom__12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.t("DATANET_GUIDANCE_SECONDARY_FACILITY_CONTACT_INFO")
                  ) +
                  "\n    "
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }