var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldShowOrganisation
    ? _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("telia-select", {
            attrs: {
              "t-id": "organisation-select",
              options: JSON.stringify(_vm.getOrganisationOptions),
              disabled: _vm.shouldDisableOrganisation,
            },
            on: {
              change: function ($event) {
                return _vm.handleOrganisationSelection($event.target.value)
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }