<template>
  <div v-if="shouldShowOrganisation" class="input-wrapper">
    <telia-select
      t-id="organisation-select"
      :options="JSON.stringify(getOrganisationOptions)"
      :disabled="shouldDisableOrganisation"
      @change="handleOrganisationSelection($event.target.value)"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { translateMixin } from "../locale";

export default {
  name: "Organisation",
  mixins: [translateMixin, validationMixin],
  computed: {
    ...mapState("user", {
      organisation: (state) => state.organisation,
      scopeId: (state) => state.scopeId,
    }),

    ...mapGetters("user", [
      "shouldShowOrganisation",
      "shouldDisableOrganisation",
      "shouldValidateOrganisation",
      "getOrganisationOptions",
      "getOrganisation",
    ]),
  },

  methods: {
    ...mapActions("user", ["setOrganisation"]),
    ...mapActions("invoice", ["getBillingAccounts"]),

    async handleOrganisationSelection(value) {
      if (value === this.organisation) return;

      this.setOrganisation(value);

      await this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$emit("selected", this.organisation);
      }

      this.$emit("selected", this.organisation);

      const params = { scopeId: this.scopeId, tscid: this.getOrganisation(value).tscid };
      await this.getBillingAccounts(params);
    },
  },

  validations: {
    organisation: {
      required: requiredIf(function () {
        return this.shouldValidateOrganisation;
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.input-wrapper {
  max-width: 42rem;
  margin-bottom: $telia-spacing-24;
}
</style>
