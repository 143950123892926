<template>
  <div>
    <!-- In-house cabling for primary access -->
    <telia-heading tag="h5" variant="title-100" class="margin__bottom__12">
      {{ t(`DATANET_PRIMARY_ACCESS`) }}
    </telia-heading>

    <div class="information-box-wrapper">
      <InformationBox v-if="activeTooltip" @close="setActiveTooltip(null)">
        <OnPremisesTooltip :should-show-tooltip-by-id="activeTooltip" />
      </InformationBox>
    </div>
    <div class="input-with-tooltip">
      <div class="input-wrapper">
        <telia-select
          id="installation-contact"
          :label="t('DATANET_CONTACT_PERSON')"
          :value="contact"
          :options="JSON.stringify(contactOptions)"
          @change="handleContactSelection($event.target.value)"
        />
        <ValidationError v-if="$v.contact.emailAddress.$error">
          {{ t("DATANET_CONTACT_PERSON_REQUIRED") }}
        </ValidationError>
      </div>
      <telia-icon
        class="tooltip-icon-inputs"
        name="question"
        size="sm"
        @click="setActiveTooltip('contact-person')"
      />
    </div>
    <div>
      <OnPremisesInstallationSettings
        v-if="isPrimaryAccessFiber || isPrimaryAccessCopper"
        access-key="primary"
        ref="primary-access-installation-options"
        class="margin__top__24 margin__bottom__24"
        :should-show-tooltip-by-id="activeTooltip"
        @setActiveTooltip="setActiveTooltip($event)"
      />
    </div>

    <!-- In-house cabling for secondary access -->
    <div v-if="hasBackupAccess && (isSecondaryAccessFiber || isSecondaryAccessCopper)">
      <telia-heading tag="h5" variant="title-100" class="margin__bottom__12">
        {{ t(`DATANET_SECONDARY_ACCESS`) }}
      </telia-heading>

      <div v-if="hasBackupTypeDiversity || hasBackupTypeRedundancy">
        <div class="input-wrapper">
          <telia-select
            id="secondary-installation-contact"
            :label="t('DATANET_CONTACT_PERSON')"
            :value="secondaryContact"
            :options="JSON.stringify(secondaryContactOptions)"
            @change="handleSecondaryContactSelection($event.target.value)"
          />
          <ValidationError v-if="$v.secondaryContact.emailAddress.$error">
            {{ t("DATANET_CONTACT_PERSON_REQUIRED") }}
          </ValidationError>
        </div>
      </div>
      <div>
        <OnPremisesInstallationSettings
          access-key="secondary"
          ref="secondary-access-installation-options"
          class="margin__top__24 margin__bottom__24"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InformationBox from "../InformationBox";
import OnPremisesInstallationSettings from "./OnPremisesInstallationSettings";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { translateMixin } from "../../locale";
import ValidationError from "./../ValidationError.vue";
import OnPremisesTooltip from "../tooltip-information/on-premise-installation/OnPremisesTooltip.vue";

export default {
  name: "OnPremisesInstallation",
  mixins: [translateMixin, validationMixin],
  components: {
    InformationBox,
    OnPremisesInstallationSettings,
    ValidationError,
    OnPremisesTooltip,
  },
  data() {
    return {
      activeTooltip: null,
    };
  },

  computed: {
    ...mapGetters({
      isProductBusinessOrder: "isProductBusinessOrder",
      shouldValidateOnPremisesInstallation:
        "onPremisesInstallation/shouldValidateOnPremisesInstallation",
      hasBackupAccess: "access/hasBackupAccess",
      hasBackupTypeDiversity: "access/hasBackupTypeDiversity",
      hasBackupTypeRedundancy: "access/hasBackupTypeRedundancy",
      isPrimaryAccessFiber: "products/isFiberAccess",
      isPrimaryAccessCopper: "products/isCopperAccess",
      isSecondaryAccessFiber: "products/isFiberBackupAccess",
      isSecondaryAccessCopper: "products/isCopperBackupAccess",
    }),
    ...mapGetters("contactPersons", ["getContactOptions", "getContact"]),

    contactOptions() {
      return this.getContactOptions(["technical", "facility"]).map((option) => {
        return {
          value: option.value,
          label: option.label,
          selected:
            this.contact.emailAddress !== ""
              ? this.$store.getters["contactPersons/getContact"](option.value)?.emailAddress ===
                this.contact.emailAddress
              : option.value === "",
        };
      });
    },

    secondaryContactOptions() {
      return this.getContactOptions(["secondaryFacility"]).map((option) => {
        return {
          value: option.value,
          label: option.label,
          selected:
            this.contact.emailAddress !== ""
              ? this.$store.getters["contactPersons/getContact"](option.value)?.emailAddress ===
                this.contact.emailAddress
              : option.value === "",
        };
      });
    },

    contact() {
      return this.$store.getters["contactPersons/getContact"]("installation");
    },

    secondaryContact() {
      return this.$store.getters["contactPersons/getContact"]("secondaryInstallation");
    },
  },

  validations: {
    contact: {
      emailAddress: {
        required: requiredIf(function () {
          return this.shouldValidateOnPremisesInstallation;
        }),
      },
    },
    secondaryContact: {
      emailAddress: {
        required: requiredIf(function () {
          return (
            this.shouldValidateOnPremisesInstallation &&
            (this.hasBackupTypeDiversity || this.hasBackupTypeRedundancy)
          );
        }),
      },
    },
  },

  methods: {
    completeStep() {
      this.$v.$touch();
      const hasValidChildComponents = this.isChildComponentsValid();
      if (!this.$v.$invalid && hasValidChildComponents) {
        this.$emit("step-complete");
      }
    },

    isChildComponentsValid() {
      let validChildren = [];

      Object.values(this.$refs).forEach((childComponent) => {
        const isValid = childComponent.validate();
        validChildren.push(isValid);
      });

      return validChildren.filter((isValid) => !isValid).length === 0;
    },

    handleContactSelection(value) {
      const contact = this.$store.getters["contactPersons/getContact"](value);
      if (contact) {
        this.$store.dispatch("contactPersons/setContact", {
          id: "installation",
          contact,
        });
      } else {
        this.$store.dispatch("contactPersons/setContact", {
          id: "installation",
          contact: {},
        });
      }
    },

    handleSecondaryContactSelection(value) {
      const contact = this.$store.getters["contactPersons/getContact"](value);
      if (contact) {
        this.$store.dispatch("contactPersons/setContact", {
          id: "secondaryInstallation",
          contact,
        });
      } else {
        this.$store.dispatch("contactPersons/setContact", {
          id: "secondaryInstallation",
          contact: {},
        });
      }
    },

    setActiveTooltip(value) {
      if (value === this.activeTooltip) {
        this.activeTooltip = null;
        return;
      }
      this.activeTooltip = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.input-wrapper {
  max-width: 42rem;
  flex: 1;
}
.information-box-wrapper {
  z-index: 10;
}
.input-with-tooltip {
  display: flex;
}
.tooltip-icon-inputs {
  position: relative;
  top: 3.8rem;
  margin-left: $telia-spacing-12;
}
.tooltip-icon-inputs:hover {
  color: $telia-purple-500;
  cursor: pointer;
}
.margin {
  &__bottom {
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
  &__top {
    &__24 {
      margin-top: $telia-spacing-24;
    }
  }
}
</style>
