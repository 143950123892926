<template>
  <div>
    <div v-if="shouldShowTooltipById === 'contact-person'">
      <telia-heading tag="h5" variant="title-100">
        {{ t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CONTACT_TITLE") }}
      </telia-heading>
      <telia-p class="margin__bottom__12">
        {{ t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CONTACT_INFO") }}
      </telia-p>
    </div>
    <div v-if="shouldShowTooltipById === 'YES_FIXED_FEE'">
      <telia-heading tag="h5" variant="title-100">
        {{ t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_YES_TITLE") }}
      </telia-heading>
      <telia-p class="margin__bottom__12">
        {{ t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_YES_INFO_1") }}
      </telia-p>
      <telia-p class="margin__bottom__12">
        {{ t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_YES_INFO_2") }}
        <a
          :href="t('DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_INFO_LINK_2')"
          target="_blank"
        >
          {{ t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_INFO_LINK_2") }}
        </a>
      </telia-p>
    </div>
    <div v-if="shouldShowTooltipById === 'NO_INTERCONNECTION_OF_CONNECTION_POINTS'">
      <telia-heading tag="h5" variant="title-100" class="right-padding">
        {{ t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_NO_INTERCONNECTION_TITLE") }}
      </telia-heading>
      <telia-p class="margin__bottom__12">
        {{ t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_NO_INTERCONNECTION_INFO_1") }}
      </telia-p>
      <telia-p class="margin__bottom__12">
        {{ t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_NO_INTERCONNECTION_INFO_2") }}
        <a
          :href="t('DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_INFO_LINK_2')"
          target="_blank"
        >
          {{ t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_INFO_LINK_2") }}
        </a>
      </telia-p>
    </div>
    <div v-if="shouldShowTooltipById === 'NO_EQUIPMENT_AT_CONNECTION_POINTS'">
      <telia-heading tag="h5" variant="title-100">
        {{ t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_NO_EQUIPMENT_TITLE") }}
      </telia-heading>
      <telia-p class="margin__bottom__12">
        {{ t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_NO_EQUIPMENT_INFO_1") }}
      </telia-p>
      <telia-p class="margin__bottom__12">
        {{ t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_NO_EQUIPMENT_INFO_2") }}
        <a
          :href="t('DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_INFO_LINK_2')"
          target="_blank"
        >
          {{ ` ${t("DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_INFO_LINK_2")}` }}
        </a>
      </telia-p>
    </div>
  </div>
</template>

<script>
import { translateMixin } from "../../../locale";
export default {
  name: "OnPremisesTooltip",
  mixins: [translateMixin],
  props: {
    shouldShowTooltipById: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.margin {
  &__bottom {
    &__4 {
      margin-bottom: $telia-spacing-4;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
  &__top {
    &__12 {
      margin-top: $telia-spacing-12;
    }
  }
  &__left {
    &__48 {
      margin-left: $telia-spacing-48;
    }
  }
}
.right-padding {
  padding-right: $telia-spacing-12;
}
</style>
