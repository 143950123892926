import { MetadataKeys } from "../../constants";
import { t } from "../../locale";
const state = getDefaultState();

function getDefaultState() {
  return {
    choices: defaultChoices(),
    choicesByAccess: defaultChoicesByAccess(),
    primary: defaultOnPremisesInstallationObject(),
    secondary: defaultOnPremisesInstallationObject(),
  };
}

function defaultOnPremisesInstallationObject() {
  return {
    selectedChoiceId: "",
    connectionSettings: defaultConnectionSettings(),
    consentGiven: false,
  };
}

function defaultChoices() {
  return {
    YES_FIXED_FEE: {
      id: "YES_FIXED_FEE",
      text: "DATANET_YES_FIXED_FEE",
    },
    NO_INTERCONNECTION_OF_CONNECTION_POINTS: {
      id: "NO_INTERCONNECTION_OF_CONNECTION_POINTS",
      text: "DATANET_NO_INTERCONNECTION_OF_CONNECTION_POINTS",
    },
    NO_EQUIPMENT_AT_CONNECTION_POINTS: {
      id: "NO_EQUIPMENT_AT_CONNECTION_POINTS",
      text: "DATANET_NO_EQUIPMENT_AT_CONNECTION_POINTS",
    },
  };
}

function defaultChoicesByAccess() {
  return {
    FIBER: defaultChoices(),
    COPPER: defaultChoices(),
  };
}

function defaultConnectionSettings() {
  return {
    pair: "",
    punchDownBlock: "",
    stand: "",
  };
}

const getters = {
  shouldValidateOnPremisesInstallation: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.ON_PREMISE_INSTALLATION),

  includeInstallationConfigurationStep: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.ON_PREMISE_INSTALLATION) &&
    rootGetters["products/hasInstallationAvailableForSelectedBearers"],

  getInstallationOptions: (state, getters, rootState, rootGetters) =>
    Object.values(state.choices).map((choice) => ({
      value: choice.id,
      label: t(choice.text),
      disabled: !rootGetters["metadata/isEditable"](MetadataKeys.ON_PREMISE_INSTALLATION),
    })),

  getInstallationChoice: (state) => (id = "") => state.choices[id] || null,
};

const actions = {
  setChoice({ dispatch, commit }, { accessKey = "", id = "" }) {
    commit("SET_SELECTED_CHOICE", { accessKey, id });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setPair({ dispatch, commit }, { accessKey = "", value = "" }) {
    commit("SET_PAIR", { accessKey, value });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setPunchDownBlock({ dispatch, commit }, { accessKey = "", value = "" }) {
    commit("SET_PUNCH_DOWN_BLOCK", { accessKey, value });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setStand({ dispatch, commit }, { accessKey = "", value = "" }) {
    commit("SET_STAND", { accessKey, value });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setConsentGiven({ dispatch, commit }, { accessKey = "", boolean = false }) {
    commit("SET_CONSENT_GIVEN", { accessKey, boolean });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },
};

const mutations = {
  SET_SELECTED_CHOICE(state, { accessKey = "", id = "" }) {
    state[accessKey].selectedChoiceId = id;
  },

  SET_PAIR(state, { accessKey = "", value = "" }) {
    state[accessKey].connectionSettings.pair = value;
  },

  SET_PUNCH_DOWN_BLOCK(state, { accessKey = "", value = "" }) {
    state[accessKey].connectionSettings.punchDownBlock = value;
  },

  SET_STAND(state, { accessKey = "", value = "" }) {
    state[accessKey].connectionSettings.stand = value;
  },

  SET_CONSENT_GIVEN(state, { accessKey = "", boolean = false }) {
    state[accessKey].consentGiven = boolean;
  },

  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
