import Vue from "vue";
import { normalize, schema } from "normalizr";
import { t } from "../../locale";
import createBandwidthLabel from "../../helpers/createBandwidthLabel";
import {
  Accesses,
  Backups,
  BandwidthDistributions,
  Bearers,
  MetadataKeys,
  QualityOfServices,
} from "../../constants";

const qosOrder = [
  "PREMIUM",
  "PREMIUM_OLD",
  "REALTIME",
  "REALTIME_OLD",
  "VOIP_ONLY",
  "VOIP_ONLY_OLD",
  "BASIC",
  "NONE",
];

const bandwidthSchema = new schema.Entity(
  "bandwidths",
  {},
  {
    idAttribute: (value) => `${value.downRateKbit}${value.upRateKbit}`,
    processStrategy: (value) => {
      return {
        id: `${value.downRateKbit}${value.upRateKbit}`,
        ...value,
      };
    },
  }
);
const bandwidthListSchema = [bandwidthSchema];
const preCheckAccessSchema = new schema.Entity(
  "preCheckAccesses",
  { bandwidths: bandwidthListSchema },
  {
    idAttribute: "bearer",
    processStrategy: (value) => {
      return {
        id: value.bearer,
        ...value,
      };
    },
  }
);
const preCheckAccessListSchema = [preCheckAccessSchema];

const PRE_CHECK_ACCESS_STUB = {
  id: "",
  bearer: "",
  estimatedInstallationDate: "",
  slas: [],
  bandwidths: [],
};

const BANDWIDTH_STUB = {
  downRateKbit: 0,
  upRateKbit: 0,
  dynamicAllowed: false,
};

const getDefaultState = () => {
  return {
    preCheckAccesses: {},
    orderCaptureAccesses: {},
    bandwidths: {},
    bearer: {
      value: "",
    },
    secondaryBearer: "",
  };
};

const state = getDefaultState();

const getters = {
  getProducts: (state) => state,

  isFiberAccess: (state) => state.bearer.value === Bearers.FIBER,

  isFiberAvailable: (state) => !!state.orderCaptureAccesses[Bearers.FIBER],

  isFttxAccess: (state) => state.bearer.value === Bearers.FTTX,

  isFttxAvailable: (state) => !!state.orderCaptureAccesses[Bearers.FTTX],

  isCopperAccess: (state) => state.bearer.value === Bearers.COPPER,

  isWirelessAccess: (state) => state.bearer.value === Bearers.WIRELESS,

  isFiberBackupAccess: (state) => state.secondaryBearer === Bearers.FIBER,

  isFttxBackupAccess: (state) => state.secondaryBearer === Bearers.FTTX,

  isCopperBackupAccess: (state) => state.secondaryBearer === Bearers.COPPER,

  isWirelessBackupAccess: (state) => state.secondaryBearer === Bearers.WIRELESS,

  isWirelessBearer: (state) => (accessKey) => {
    if (accessKey === Accesses.PRIMARY) return state.bearer.value === Bearers.WIRELESS;
    if (accessKey === Accesses.SECONDARY) return state.secondaryBearer === Bearers.WIRELESS;
    return false;
  },

  hasInstallationAvailableForSelectedBearers: (state, getters) =>
    getters.isFiberAccess ||
    getters.isCopperAccess ||
    getters.isFiberBackupAccess ||
    getters.isCopperBackupAccess,

  hasQuickLaunchAvailable: (state) => {
    const access = state.orderCaptureAccesses[state.bearer.value];
    if (!access) {
      return false;
    }

    return access.options ? access.options.quickLaunch : false;
  },

  shouldShowBearerSelection: (state, getters, rootState, rootGetters) =>
    (getters.isFiberAccess || getters.isFttxAccess) &&
    getters.isFttxAvailable &&
    rootGetters["metadata/isVisible"](MetadataKeys.ACCESS_BEARER),

  shouldShowQuickLaunch: (state, getters, rootState, rootGetters) => {
    return (
      getters.hasQuickLaunchAvailable &&
      rootGetters["metadata/isVisible"](MetadataKeys.QUICK_LAUNCH)
    );
  },

  hasFastTrackAvailable: (state) => {
    const access = state.orderCaptureAccesses[state.bearer.value];

    if (!access) {
      return false;
    }

    return access.options ? access.options.fastTrack : false;
  },

  hasExtendedAvailabilityAvailable: (state) => {
    const access = state.orderCaptureAccesses[state.bearer.value];

    if (!access || !access.options) {
      return false;
    }

    return access.options.extendedAvailability;
  },

  shouldShowExtendedAvailability: (state, getters, rootState, rootGetters) => {
    return (
      getters.hasExtendedAvailabilityAvailable &&
      rootGetters["metadata/isVisible"](MetadataKeys.EXTENDED_AVAILABILITY)
    );
  },

  includePrimaryBearerSelectionStep: (state, getters, rootState, rootGetters) => {
    return rootGetters["metadata/isVisible"](MetadataKeys.ACCESS_BEARER);
  },

  getBackupOptions: (state, getters, rootState, rootGetters) => (bearerKey) => {
    if (bearerKey == null) return undefined;
    if (!Object.keys(Bearers).includes(bearerKey)) return undefined;

    const temporaryAccess = rootGetters["access/getAccess"](Accesses.TEMPORARY);
    if (!temporaryAccess) return undefined;

    const defaultBackupOptions = [
      {
        value: Backups.BACKUP,
        label: t(`DATANET_EXTENDED_AVAILABILITY_TYPE_${Backups.BACKUP}`),
        disabled: true,
      },
    ];
    let backupOptions;

    const isTemporaryAccessEnabled = temporaryAccess.enable;
    const useTemporaryAccessAsSecondary = temporaryAccess.useAsSecondaryAccess;
    if (isTemporaryAccessEnabled && useTemporaryAccessAsSecondary) {
      backupOptions = [
        ...defaultBackupOptions,
        {
          value: Backups.BACKUP_COMBO,
          label: t(`DATANET_EXTENDED_AVAILABILITY_TYPE_${Backups.BACKUP_COMBO}`),
          disabled: true,
        },
      ];
    } else if (isTemporaryAccessEnabled && !useTemporaryAccessAsSecondary) {
      backupOptions = [
        ...defaultBackupOptions,
        {
          value: Backups.DIVERSITY,
          label: t(`DATANET_EXTENDED_AVAILABILITY_TYPE_${Backups.DIVERSITY}`),
          disabled: true,
        },
        {
          value: Backups.REDUNDANCY,
          label: t(`DATANET_EXTENDED_AVAILABILITY_TYPE_${Backups.REDUNDANCY}`),
          disabled: true,
        },
      ];
    } else {
      backupOptions = [
        ...defaultBackupOptions,
        {
          value: Backups.BACKUP_COMBO,
          label: t(`DATANET_EXTENDED_AVAILABILITY_TYPE_${Backups.BACKUP_COMBO}`),
          disabled: true,
        },
        {
          value: Backups.DIVERSITY,
          label: t(`DATANET_EXTENDED_AVAILABILITY_TYPE_${Backups.DIVERSITY}`),
          disabled: true,
        },
        {
          value: Backups.REDUNDANCY,
          label: t(`DATANET_EXTENDED_AVAILABILITY_TYPE_${Backups.REDUNDANCY}`),
          disabled: true,
        },
      ];
    }

    const availableBackupOptions = Object.keys(
      state.orderCaptureAccesses[bearerKey].options.extendedAvailability
    );
    if (!availableBackupOptions) return undefined;

    backupOptions.forEach(function (option, index) {
      this[index].disabled = !availableBackupOptions.includes(option.value);
    }, backupOptions);

    return backupOptions;
  },

  getBearer: () => (accessKey) => {
    if (!accessKey) return undefined;
    if (accessKey === Accesses.PRIMARY) return state.bearer.value;
    if (accessKey === Accesses.SECONDARY) return state.secondaryBearer;
    return undefined;
  },

  getBearerOptions: (state, getters, rootState, rootGetters) => (accessKey) => {
    const isPrimaryAccess = accessKey === Accesses.PRIMARY;

    if (isPrimaryAccess) {
      return [
        {
          value: Bearers.FIBER,
          label: t(`DATANET_${Bearers.FIBER}`),
          disabled:
            !getters.isFiberAvailable ||
            (!rootGetters["metadata/isEditable"](MetadataKeys.ACCESS_BEARER) &&
              getters.getBearer(Accesses.PRIMARY) !== Bearers.FIBER),
        },
        {
          value: Bearers.FTTX,
          label: t(`DATANET_${Bearers.FTTX}`),
          disabled:
            !rootGetters["metadata/isEditable"](MetadataKeys.ACCESS_BEARER) &&
            getters.getBearer(Accesses.PRIMARY) !== Bearers.FTTX,
        },
      ];
    }
  },

  getBearerOptionsForBackup: (state, getters, rootState, rootGetters) => (bearerKey, backupKey) => {
    if (bearerKey == null || backupKey == null) return undefined;

    const temporaryAccess = rootGetters["access/getAccess"](Accesses.TEMPORARY);
    if (!temporaryAccess) return undefined;

    let bearerOptions;
    const isTemporaryAccessEnabled = temporaryAccess.enable;
    const useTemporaryAccessAsSecondary = temporaryAccess.useAsSecondaryAccess;
    if (isTemporaryAccessEnabled && useTemporaryAccessAsSecondary) {
      bearerOptions = [
        {
          value: Bearers.WIRELESS,
          label: t(`DATANET_${Bearers.WIRELESS}`),
          disabled: true,
        },
      ];
    } else if (isTemporaryAccessEnabled && !useTemporaryAccessAsSecondary) {
      bearerOptions = [
        {
          value: Bearers.FIBER,
          label: t(`DATANET_${Bearers.FIBER}`),
          disabled: true,
        },
        {
          value: Bearers.COPPER,
          label: t(`DATANET_${Bearers.COPPER}`),
          disabled: true,
        },
      ];
    } else {
      bearerOptions = [
        {
          value: Bearers.FIBER,
          label: t(`DATANET_${Bearers.FIBER}`),
          disabled: true,
        },
        {
          value: Bearers.FTTX,
          label: t(`DATANET_${Bearers.FTTX}`),
          disabled: true,
        },
        {
          value: Bearers.COPPER,
          label: t(`DATANET_${Bearers.COPPER}`),
          disabled: true,
        },
        {
          value: Bearers.WIRELESS,
          label: t(`DATANET_${Bearers.WIRELESS}`),
          disabled: true,
        },
      ];
    }

    const availableBearerOptions =
      state.orderCaptureAccesses[bearerKey].options.extendedAvailability[backupKey];
    bearerOptions.forEach(function (bearer, index) {
      this[index].disabled = !availableBearerOptions.includes(bearer.value);
    }, bearerOptions);

    return bearerOptions;
  },

  hasBackupAvailable: (state) => {
    const access = state.orderCaptureAccesses[state.bearer.value];
    if (!access || !access.options || !access.options.extendedAvailability) {
      return false;
    }

    return access.options.extendedAvailability.BACKUP.length > 0;
  },

  getBandwidthOptions: (state) => (bearer = "", limit = -1) => {
    const access = state.orderCaptureAccesses[bearer];
    if (!access) {
      return [
        {
          value: "",
          label: t("DATANET_SPEED_PLACEHOLDER"),
          disabled: true,
        },
      ];
    }

    const bandwidthOptions = Object.values(access.bandwidths)
      .map(({ upRateKbit = "", downRateKbit = "" }) => {
        const key = `${downRateKbit}${upRateKbit}`;

        return key !== "00"
          ? {
              value: key,
              label: createBandwidthLabel(downRateKbit, upRateKbit),
              disabled: limit !== -1 ? Number(key) > limit : false,
            }
          : {
              value: key,
              label: t("DATANET_BEST_EFFORT"),
            };
      })
      .sort((a, b) => {
        const speedA = parseInt(a.value);
        const speedB = parseInt(b.value);

        return speedB - speedA;
      });

    bandwidthOptions.splice(0, 0, {
      value: "",
      label: t("DATANET_SPEED_PLACEHOLDER"),
      disabled: true,
    });

    return bandwidthOptions;
  },

  getSlaOptions: (state) => (bearer = "") => {
    const access = state.orderCaptureAccesses[bearer];
    if (!access) {
      return [
        {
          value: "",
          label: t("DATANET_SLA_PLACEHOLDER"),
          disabled: true,
        },
      ];
    }

    const slaOptions = access.slas.map((sla) => ({
      value: sla,
      label: t("DATANET_" + sla + "_DESCRIPTION"),
    }));

    slaOptions.splice(0, 0, {
      value: "",
      label: t("DATANET_SLA_PLACEHOLDER"),
      disabled: true,
    });

    return slaOptions;
  },

  getQosOptions: (state) => (bearer = "", bandwidthKey = "") => {
    const noSelectionOptions = [
      {
        value: "NONE",
        label: t("DATANET_NONE_DESCRIPTION"),
      },
    ];

    const access = state.orderCaptureAccesses[bearer];
    if (!access) {
      noSelectionOptions.splice(0, 0, {
        value: "",
        label: t("DATANET_QOS_PLACEHOLDER"),
        disabled: true,
      });

      return noSelectionOptions;
    }

    const bandwidth = access.bandwidths[bandwidthKey];
    const qosOptions = bandwidth
      ? Object.keys(bandwidth.qosMap)
          .map((qos) => ({
            value: qos,
            label: t("DATANET_" + qos + "_DESCRIPTION"),
          }))
          .sort((a, b) => {
            return qosOrder.indexOf(a.id) - qosOrder.indexOf(b.id);
          })
      : noSelectionOptions;

    qosOptions.splice(0, 0, {
      value: "",
      label: t("DATANET_QOS_PLACEHOLDER"),
      disabled: true,
    });

    return qosOptions;
  },

  getRtTotOptions: (state, getters) => (accessKey, bandwidthId) => {
    const bearer = accessKey === Accesses.PRIMARY ? state.bearer.value : state.secondaryBearer;
    const rtBandwidths = getters.getRtTotForAccessAndBandwidth(bearer, bandwidthId);

    const rtOptions = rtBandwidths
      .map((rtBandwidth) => ({
        label: createBandwidthLabel(rtBandwidth, rtBandwidth),
        value: rtBandwidth,
      }))
      .sort((a, b) => {
        return b.value - a.value;
      });

    rtOptions.splice(0, 0, {
      label: t("DATANET_RT_TOT_PLACEHOLDER"),
      value: -1,
    });

    return rtOptions;
  },

  getSelectedInstallationDate: (state) => {
    return state.orderCaptureAccesses && state.bearer.value
      ? state.orderCaptureAccesses[state.bearer.value].estimatedInstallationDate
      : "";
  },

  // Pre-check getters
  getPreCheckAccesses: (state) => {
    return state.preCheckAccesses ? Object.values(state.preCheckAccesses) : [];
  },

  getBandwidthsForPreCheckAccess: (state) => (accessId) => {
    if (!state.bandwidths || !accessId || !state.preCheckAccesses) {
      return [];
    }

    const access = state.preCheckAccesses[accessId];
    if (!access) {
      return [];
    }

    return Object.values(state.bandwidths).filter((bandwidth) =>
      access.bandwidths.includes(bandwidth.id)
    );
  },

  // Order capture getters
  getAccess: (state) => (accessId = "") => (accessId ? state.orderCaptureAccesses[accessId] : {}),

  getBandwidthText: (state) => (bearer = "", bandwidthKey = "") => {
    const access = state.orderCaptureAccesses[bearer];
    if (!access) {
      return "";
    }

    const bandwidth = access.bandwidths[bandwidthKey];

    return bandwidth
      ? bearer === Bearers.WIRELESS
        ? t("DATANET_BEST_EFFORT")
        : createBandwidthLabel(bandwidth.downRateKbit, bandwidth.upRateKbit)
      : "";
  },

  getBandwidthForAccess: (state) => (accessId, bandwidthId) =>
    accessId && bandwidthId ? state.orderCaptureAccesses[accessId].bandwidths[bandwidthId] : {},

  getBandwidthForSelectedAccess: (state) => (bandwidthId = "") => {
    if (!state.bearer.value || !bandwidthId || !state.orderCaptureAccesses) {
      return null;
    }

    const access = state.orderCaptureAccesses[state.bearer.value];
    if (!access) {
      return null;
    }

    return access.bandwidths[bandwidthId] || null;
  },

  getDefaultSlaForAccess: (state) => (accessId) =>
    accessId ? state.orderCaptureAccesses[accessId].defaultSla : "",

  getRtTotForAccessAndBandwidth: (state) => (accessId = "", bandwidthId = "") => {
    if (!accessId || !bandwidthId) {
      return [];
    }

    const access = state.orderCaptureAccesses[accessId];
    if (!access) {
      return [];
    }

    const bandwidth = access.bandwidths[bandwidthId];
    if (!bandwidth) {
      return [];
    }

    return bandwidth.rtTotal || [];
  },

  getDynamicAllowedForAccessAndBandwidth: (state) => (accessId, bandwidthId) =>
    accessId && bandwidthId
      ? state.orderCaptureAccesses[accessId].bandwidths[bandwidthId].dynamicAllowed
      : false,

  getVpnBandwidthOptions: (state, getters, rootState, rootGetters) => (vpnKey) => {
    const placeholderOption = {
      value: "",
      label: t("DATANET_SPEED_PLACEHOLDER"),
      disabled: true,
    };

    const vpn = rootGetters["vpn/getVpn"](vpnKey);
    const bearer = getters.getBearer(vpn.accessKey);
    const access = rootGetters["access/getAccess"](vpn.accessKey);

    const totalDistributedBandwidthForVpns = rootGetters["vpn/getTotalDistributedBandwidth"](
      vpn.accessKey
    );
    const selectedAccessBandwidthKey = Number(access.bandwidth.new || access.bandwidth.current);
    const selectedAccessQosKey = access.qos.current || access.qos.new;
    const selectedVpnBandwidth = Number(vpn.bandwidth.new || vpn.bandwidth.current);

    const accessObject = state.orderCaptureAccesses[bearer];
    if (!accessObject) return [placeholderOption];
    const accessBandwidth = accessObject.bandwidths[selectedAccessBandwidthKey];
    if (!accessBandwidth) return [placeholderOption];
    const selectedAccessBandwidth = accessBandwidth.upRateKbit;

    const remainingBandwidth = selectedAccessBandwidth - totalDistributedBandwidthForVpns;

    const accessQos = accessBandwidth.qosMap[selectedAccessQosKey];
    if (!accessQos) return [placeholderOption];

    const bandwidthOptions = Object.keys(accessQos)
      .map((bandwidth) => {
        const number = Number(bandwidth);
        const label = createBandwidthLabel(number, number);
        return {
          value: number,
          label,
          disabled:
            number !== selectedVpnBandwidth && number > remainingBandwidth + selectedVpnBandwidth,
        };
      })
      .sort((a, b) => parseInt(b.value) - parseInt(a.value));
    bandwidthOptions.splice(0, 0, placeholderOption);

    return bandwidthOptions;
  },

  getVpnQosOptions: (state, getters, rootState, rootGetters) => (vpnKey) => {
    const placeholderOption = {
      value: "",
      label: t("DATANET_QOS_PLACEHOLDER"),
      disabled: true,
    };
    const vpn = rootGetters["vpn/getVpn"](vpnKey);
    const bearer = getters.getBearer(vpn.accessKey);
    const access = rootGetters["access/getAccess"](vpn.accessKey);

    const selectedAccessBandwidthKey = Number(access.bandwidth.new || access.bandwidth.current);
    const selectedAccessQosKey = access.qos.new || access.qos.current;
    const selectedVpnBandwidth = Number(vpn.bandwidth.new || vpn.bandwidth.current);

    const accessObject = state.orderCaptureAccesses[bearer];
    if (!accessObject) return [placeholderOption];

    const accessBandwidth = accessObject.bandwidths[selectedAccessBandwidthKey];
    if (!accessBandwidth) return [placeholderOption];

    const accessQos = accessBandwidth.qosMap[selectedAccessQosKey];
    if (!accessQos) return [placeholderOption];

    const vpnBandwidth = accessQos[selectedVpnBandwidth];
    if (!vpnBandwidth) return [placeholderOption];

    const vpnQos = Object.keys(vpnBandwidth.vpnQoSMap);
    if (!vpnQos) return [placeholderOption];

    const qosOrder = Object.values(QualityOfServices);
    const qosOptions = vpnQos
      .map((key) => ({
        value: key,
        label: t(`DATANET_${key}_DESCRIPTION`),
      }))
      .sort((a, b) => qosOrder.indexOf(a.value) - qosOrder.indexOf(b.value));
    qosOptions.splice(0, 0, placeholderOption);

    return qosOptions;
  },

  getVpnRt1Options: (state, getters, rootState, rootGetters) => (vpnKey) => {
    const placeholderOption = {
      value: "",
      label: t("DATANET_RT1_REQUIRED"),
    };

    const vpn = rootGetters["vpn/getVpn"](vpnKey);
    const access = rootGetters["access/getAccess"](vpn.accessKey);
    const bearer = getters.getBearer(vpn.accessKey);

    const allTotalRtBandwidthValuesForAccess = getters.getRtTotForAccessAndBandwidth(
      state.bearer.value,
      access.bandwidth.new
    );
    const highestTotalRtBandwidthForAccess = Math.max(...allTotalRtBandwidthValuesForAccess);
    const totalDistributedRtBandwidthInVpns = rootGetters["vpn/getTotalDistributedRtBandwidth"](
      vpn.accessKey
    );
    const remainingAccessRtBandwidth = Math.max(
      highestTotalRtBandwidthForAccess - totalDistributedRtBandwidthInVpns,
      0
    );

    const highestTotalRtBandwidthForVpn = getters.getVpnRtTotForQosAndVpnBandwidth(
      state.bearer.value,
      access.bandwidth.new,
      vpn.qos.new,
      vpn.bandwidth.new
    );
    const selectedRt1Bandwidth = vpn.rt1.new || vpn.rt1.current;
    const remainingVpnRtBandwidth = Math.max(
      highestTotalRtBandwidthForVpn - selectedRt1Bandwidth,
      0
    );

    const accessObject = state.orderCaptureAccesses[bearer];
    if (!accessObject) return [placeholderOption];

    const bandwidth = accessObject.bandwidths[access.bandwidth.new];
    if (!bandwidth) return [placeholderOption];

    const qos = bandwidth.qosMap[access.qos.new];
    if (!qos) return [placeholderOption];

    const vpnBandwidth = qos[vpn.bandwidth.new];
    if (!vpnBandwidth) return [placeholderOption];

    const vpnQos = vpnBandwidth.vpnQoSMap[vpn.qos.new];
    if (!vpnQos) return [placeholderOption];

    const remainingRtBandwidth = Math.min(remainingAccessRtBandwidth, remainingVpnRtBandwidth);
    const rt1Options = vpnQos.rt
      .map((rt) => ({
        value: rt.rt1,
        label: createBandwidthLabel(rt.rt1, rt.rt1),
        disabled:
          rt.rt1 !== selectedRt1Bandwidth && rt.rt1 > remainingRtBandwidth + selectedRt1Bandwidth,
      }))
      .sort((a, b) => b.value - a.value);
    rt1Options.splice(0, 0, placeholderOption);
    return rt1Options;
  },

  getVpnRt2Options: (state, getters, rootState, rootGetters) => (vpnKey) => {
    const placeholderOption = {
      value: "",
      label: t("DATANET_RT2_REQUIRED"),
    };

    const vpn = rootGetters["vpn/getVpn"](vpnKey);
    const access = rootGetters["access/getAccess"](vpn.accessKey);
    const bearer = getters.getBearer(vpn.accessKey);

    const allTotalRtBandwidthValuesForAccess = getters.getRtTotForAccessAndBandwidth(
      state.bearer.value,
      access.bandwidth.new
    );
    const highestTotalRtBandwidthForAccess = Math.max(...allTotalRtBandwidthValuesForAccess);
    const totalDistributedRtBandwidthInVpns = rootGetters["vpn/getTotalDistributedRtBandwidth"](
      vpn.accessKey
    );
    const remainingAccessRtBandwidth = Math.max(
      highestTotalRtBandwidthForAccess - totalDistributedRtBandwidthInVpns,
      0
    );

    const highestTotalRtBandwidthForVpn = getters.getVpnRtTotForQosAndVpnBandwidth(
      state.bearer.value,
      access.bandwidth.new,
      vpn.qos.new,
      vpn.bandwidth.new
    );
    const selectedRt2Bandwidth = vpn.rt2.new || vpn.rt2.current;
    const remainingVpnRtBandwidth = Math.max(
      highestTotalRtBandwidthForVpn - selectedRt2Bandwidth,
      0
    );

    const accessObject = state.orderCaptureAccesses[bearer];
    if (!accessObject) return [placeholderOption];

    const bandwidth = accessObject.bandwidths[access.bandwidth.new];
    if (!bandwidth) return [placeholderOption];

    const qos = bandwidth.qosMap[access.qos.new];
    if (!qos) return [placeholderOption];

    const vpnBandwidth = qos[vpn.bandwidth.new];
    if (!vpnBandwidth) return [placeholderOption];

    const vpnQos = vpnBandwidth.vpnQoSMap[vpn.qos.new];
    if (!vpnQos) return [placeholderOption];

    const remainingRtBandwidth = Math.min(remainingAccessRtBandwidth, remainingVpnRtBandwidth);
    const rt1Options = vpnQos.rt
      .map((rt) => ({
        value: rt.rt2,
        label: createBandwidthLabel(rt.rt2, rt.rt2),
        disabled:
          rt.rt2 !== selectedRt2Bandwidth && rt.rt2 > remainingRtBandwidth + selectedRt2Bandwidth,
      }))
      .sort((a, b) => b.value - a.value);
    rt1Options.splice(0, 0, placeholderOption);
    const rt2Options = rt1Options.filter(
      (rt, index) => rt1Options.findIndex((obj) => obj.value === rt.value) === index
    );
    return rt2Options;
  },

  getVpnRtTotForQosAndVpnBandwidth: (state) => (
    accessId = "",
    bandwidthId = "",
    qosId = "",
    vpnBandwidthId = ""
  ) => {
    if (!accessId || !bandwidthId || !qosId || !vpnBandwidthId) {
      return 0;
    }

    const access = state.orderCaptureAccesses[accessId];
    if (!access) {
      return 0;
    }

    const bandwidth = access.bandwidths[bandwidthId];
    if (!bandwidth) {
      return 0;
    }

    const qos = bandwidth.qosMap[qosId];
    if (!qos) {
      return 0;
    }

    const vpnBandwidth = qos[vpnBandwidthId];
    return vpnBandwidth ? vpnBandwidth.rtTotal : 0;
  },

  getShowRt2ForVpnBandwidthAndVpnQos: (state) => (
    accessId = "",
    bandwidthId = "",
    qosId = "",
    vpnBandwidthId = "",
    vpnQosId = ""
  ) => {
    if (!accessId || !bandwidthId || !qosId || !vpnBandwidthId || !vpnQosId) {
      return false;
    }

    const access = state.orderCaptureAccesses[accessId];
    if (!access) {
      return false;
    }

    const bandwidth = access.bandwidths[bandwidthId];
    if (!bandwidth) {
      return false;
    }

    const qos = bandwidth.qosMap[qosId];
    if (!qos) {
      return false;
    }

    const vpnBandwidth = qos[vpnBandwidthId];
    if (!vpnBandwidth) {
      return false;
    }

    const vpnQos = vpnBandwidth.vpnQoSMap[vpnQosId];
    if (!vpnQos) {
      return false;
    }

    return vpnQos.showRt2 || false;
  },

  getVpnRtOptionsForVpnBandwidthAndVpnQos: (state) => (
    accessId = "",
    bandwidthId = "",
    qosId = "",
    vpnBandwidthId = "",
    vpnQosId = ""
  ) => {
    if (!accessId || !bandwidthId || !qosId || !vpnBandwidthId || !vpnQosId) {
      return [];
    }

    const access = state.orderCaptureAccesses[accessId];
    if (!access) {
      return [];
    }

    const bandwidth = access.bandwidths[bandwidthId];
    if (!bandwidth) {
      return [];
    }

    const qos = bandwidth.qosMap[qosId];
    if (!qos) {
      return [];
    }

    const vpnBandwidth = qos[vpnBandwidthId];
    if (!vpnBandwidth) {
      return [];
    }

    const vpnQos = vpnBandwidth.vpnQoSMap[vpnQosId];
    return vpnQos ? vpnQos.rt : [];
  },
};

const actions = {
  setProducts({ commit }, products = {}) {
    if (Object.values(products).length === 0) return;

    const { entities } = normalize(products.precheckAccess, preCheckAccessListSchema);
    commit("SET_PRE_CHECK_ACCESSES", entities.preCheckAccesses);
    commit("SET_ORDER_CAPTURE_ACCESSES", products.orderCaptureAccess);
    commit("SET_BANDWIDTHS", entities.bandwidths);
  },

  setBearer({ state, dispatch, commit }, bearer = { value: "" }) {
    if (!Object.values(Bearers).includes(bearer.value)) return;
    if (state.bearer === bearer.value) return;

    const accessKey = Accesses.PRIMARY;
    const bearerCopy = Object.assign({}, state.bearer);
    const newBearer = Object.assign(bearerCopy, bearer);
    commit("SET_BEARER", newBearer);
    dispatch("setUnsavedDraft", undefined, { root: true });

    dispatch("access/setBandwidth", { accessKey, new: "" }, { root: true });
    dispatch("access/setSla", { accessKey, new: "" }, { root: true });

    if (bearer.value === Bearers.WIRELESS) {
      dispatch(
        "vpn/setBandwidthDistribution",
        { accessKey, new: BandwidthDistributions.DYNAMIC },
        { root: true }
      );
    }

    dispatch("access/resetTemporaryAccess", undefined, { root: true });
    dispatch("access/resetSecondaryAccess", undefined, { root: true });
  },

  setSecondaryBearer({ state, dispatch, commit }, bearer) {
    if (bearer == null) return;
    if (!Object.values(Bearers).includes(bearer)) return;
    if (state.secondaryBearer === bearer) return;

    if (bearer === Bearers.WIRELESS) {
      dispatch(
        "vpn/setBandwidthDistribution",
        { accessKey: Accesses.SECONDARY, new: BandwidthDistributions.DYNAMIC },
        { root: true }
      );
    }

    commit("SET_SECONDARY_BEARER", bearer);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setSelectedAccess({ dispatch, commit }, accessId = "") {
    commit("SET_SELECTED_ACCESS", accessId);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },
};

const mutations = {
  SET_PRE_CHECK_ACCESSES(state, accesses) {
    for (const access in accesses) {
      const oldObj = state.preCheckAccesses[access] || Object.assign({}, PRE_CHECK_ACCESS_STUB);
      const newObj = Object.assign(oldObj, accesses[access]);
      Vue.set(state.preCheckAccesses, access, newObj);
    }
  },

  SET_ORDER_CAPTURE_ACCESSES(state, accesses) {
    state.orderCaptureAccesses = accesses;
  },

  SET_BANDWIDTHS(state, bandwidths) {
    for (const bandwidth in bandwidths) {
      const oldObj = state.bandwidths[bandwidth] || Object.assign({}, BANDWIDTH_STUB);
      const newObj = Object.assign(oldObj, bandwidths[bandwidth]);
      Vue.set(state.bandwidths, bandwidth, newObj);
    }
  },

  SET_BEARER(state, bearer) {
    state.bearer = bearer;
  },

  SET_SECONDARY_BEARER(state, bearer) {
    state.secondaryBearer = bearer;
  },

  SET_SELECTED_ACCESS(state, accessId = "") {
    state.bearer.value = accessId;
  },

  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
