var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isChangeOrder
      ? _c(
          "div",
          { staticClass: "margin__bottom__24" },
          [
            _c(
              "telia-heading",
              {
                staticClass: "margin__bottom__12",
                attrs: { tag: "h4", variant: "title-100" },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.t("DATANET_VPN_EDIT_STATIC_ROUTE_CHOICE")) +
                    "\n    "
                ),
              ]
            ),
            _c("ActionSelection", {
              attrs: {
                "selected-action": _vm.routeAction,
                "disabled-actions": _vm.disabledActions,
              },
              on: {
                selected: function ($event) {
                  return _vm.setAction($event)
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "information-box-wrapper" },
      [
        _vm.activeTooltip
          ? _c(
              "InformationBox",
              {
                on: {
                  close: function ($event) {
                    return _vm.setActiveTooltip(null)
                  },
                },
              },
              [
                _c("StaticRouteTooltip", {
                  attrs: { "show-tooltip-by-id": _vm.activeTooltip },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "margin__bottom__24" },
      [
        _c("telia-p", [
          _c("strong", [
            _vm._v(_vm._s(_vm.t("DATANET_VPN_STATIC_ROUTE_NETWORK_INPUT"))),
          ]),
        ]),
        _c("div", { staticClass: "row margin__bottom__4" }, [
          _vm.isCurrentValuesRequired
            ? _c("div", { staticClass: "column" }, [
                _c(
                  "div",
                  { staticClass: "tooltip-wrapper" },
                  [
                    _c(
                      "div",
                      { staticClass: "input-wrapper" },
                      [
                        _c("telia-text-input", {
                          attrs: {
                            id: "route-network-current",
                            "t-id": "current-static-route-network",
                            label: _vm.t("DATANET_CURRENT_VALUE"),
                            value: _vm.networkInput.current,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.setAndValidateCurrentNetwork(
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.isNewValuesRequired
                      ? _c("telia-icon", {
                          staticClass: "tooltip-icon-inputs",
                          attrs: { name: "question", size: "sm" },
                          on: {
                            click: function ($event) {
                              return _vm.setActiveTooltip("route-network")
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.isNewValuesRequired
            ? _c("div", { staticClass: "column" }, [
                _c(
                  "div",
                  { staticClass: "tooltip-wrapper" },
                  [
                    _c(
                      "div",
                      { staticClass: "input-wrapper" },
                      [
                        _c("telia-text-input", {
                          attrs: {
                            id: "route-network-new",
                            "t-id": "new-static-route-network",
                            label: _vm.t("DATANET_NEW_VALUE"),
                            value: _vm.networkInput.new,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.setAndValidateNewNetwork(
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("telia-icon", {
                      staticClass: "tooltip-icon-inputs",
                      attrs: { name: "question", size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTooltip("route-network")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _vm.v.network.input.current.$error || _vm.v.network.input.new.$error
          ? _c("ValidationError", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.t("DATANET_INVALID_NETWORK_FORMAT")) +
                  "\n    "
              ),
            ])
          : _c(
              "telia-p",
              {
                staticClass: "additional-text",
                attrs: { variant: "additional-100" },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.t("DATANET_NETWORK_PLACEHOLDER")) +
                    "\n    "
                ),
              ]
            ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("telia-p", [
          _c("strong", [
            _vm._v(_vm._s(_vm.t("DATANET_VPN_STATIC_ROUTE_NEXT_HOP"))),
          ]),
        ]),
        _c("div", { staticClass: "row margin__bottom__4" }, [
          _vm.isCurrentValuesRequired
            ? _c("div", { staticClass: "column" }, [
                _c(
                  "div",
                  { staticClass: "tooltip-wrapper" },
                  [
                    _c(
                      "div",
                      { staticClass: "input-wrapper" },
                      [
                        _c("telia-text-input", {
                          attrs: {
                            id: "route-next-hop-current",
                            "t-id": "route-current-next-hop",
                            label: _vm.t("DATANET_CURRENT_VALUE"),
                            value: _vm.nextHopIpAddress.current,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.setAndValidateCurrentNextHop(
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.isNewValuesRequired
                      ? _c("telia-icon", {
                          staticClass: "tooltip-icon-inputs",
                          attrs: { name: "question", size: "sm" },
                          on: {
                            click: function ($event) {
                              return _vm.setActiveTooltip("next-hop")
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.isNewValuesRequired
            ? _c("div", { staticClass: "column" }, [
                _c(
                  "div",
                  { staticClass: "tooltip-wrapper" },
                  [
                    _c(
                      "div",
                      { staticClass: "input-wrapper" },
                      [
                        _c("telia-text-input", {
                          attrs: {
                            id: "route-next-hop-new",
                            "t-id": "route-new-next-hop",
                            label: _vm.t("DATANET_NEW_VALUE"),
                            value: _vm.nextHopIpAddress.new,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.setAndValidateNewNextHop(
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("telia-icon", {
                      staticClass: "tooltip-icon-inputs",
                      attrs: { name: "question", size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTooltip("next-hop")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _vm.v.nextHop.ipAddress.current.$error ||
        _vm.v.nextHop.ipAddress.new.$error
          ? _c("ValidationError", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.t("DATANET_INVALID_IP_ADDRESS")) +
                  "\n    "
              ),
            ])
          : _vm.v.nextHop.isMemberOfLanNetwork.$error
          ? _c("ValidationError", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.t(
                      "DATANET_VPN_STATIC_ROUTE_NEXT_HOP_NOT_IN_LAN_NETWORK"
                    )
                  ) +
                  "\n    "
              ),
            ])
          : _c(
              "telia-p",
              {
                staticClass: "additional-text",
                attrs: { variant: "additional-100" },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.t("DATANET_IP_ADDRESS_PLACEHOLDER")) +
                    "\n    "
                ),
              ]
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }