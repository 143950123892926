<template>
  <transition name="fade">
    <div v-show="show" class="tooltip">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: "Tooltip",

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";

.tooltip {
  position: absolute;
  top: -1.4rem;
  left: 50%;
  width: 20rem;
  border: 0.1rem solid $telia-gray-500;
  border-bottom: 0.2rem solid $telia-purple-500;
  padding: $telia-spacing-8;
  background-color: $telia-white;
  transform: translate(-50%, -100%);
  animation: up 0.3s linear forwards;
}

.tooltip:before {
  content: "";
  position: absolute;
  bottom: -2.4rem;
  left: 50%;
  border: 1.2rem solid transparent;
  border-top: 1.2rem solid $telia-purple-500;
  transform: translate(-50%, 0%);
}

.fade-enter-active {
  transition: opacity 0.3s ease, top 0.3s ease;
}

.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter {
  opacity: 0;
  top: 2rem;
}

.fade-leave-to {
  opacity: 0;
}
</style>
