var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.shouldDeleteVpn(_vm.vpn.key)
        ? _c(
            "telia-notification",
            {
              attrs: {
                "heading-tag": "h4",
                variant: "inline",
                status: "information",
                "html-aria-live": "polite",
                "html-role": "alert",
              },
            },
            [
              _c(
                "span",
                { attrs: { slot: "heading" }, slot: "heading" },
                [
                  _c("telia-visually-hidden", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.t("A11Y_STATUS_INFORMATION")) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.t("DATANET_VPN_DELETE_EXISTING_VPN_TITLE")) +
                      "\n    "
                  ),
                ],
                1
              ),
              _c(
                "span",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _c("telia-p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.t("DATANET_VPN_DELETE_EXISTING_VPN_INFO", {
                            id: this.vpnIdAndAlias,
                          })
                        ) +
                        "\n      "
                    ),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.shouldDeleteVpn(_vm.vpn.key) && _vm.isVpnActionEditable
        ? _c(
            "telia-button",
            {
              attrs: { type: "button", variant: "text" },
              on: {
                click: function ($event) {
                  return _vm.setActionNone(_vm.vpn.key)
                },
              },
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.t("DATANET_VPN_UNDO_REMOVE_VPN")) + "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm.v.lans.$error
        ? _c("ValidationError", {
            domProps: {
              textContent: _vm._s(
                _vm.t("DATANET_VPN_LAN_AT_LEAST_ONE_REQUIRED")
              ),
            },
          })
        : _vm._e(),
      !_vm.shouldDeleteVpn(_vm.vpn.key)
        ? [
            _c("telia-p", [
              _c("strong", [_vm._v(_vm._s(_vm.t("DATANET_VPN_ID")))]),
            ]),
            _c(
              "div",
              { staticClass: "input-wrapper margin__bottom__24" },
              [
                _c("telia-select", {
                  attrs: {
                    id: "vpn-" + _vm.vpn.key + "-id",
                    "t-id": "vpn-id",
                    options: JSON.stringify(_vm.vpnIdOptions),
                    disabled: !_vm.isVpnIdEditable(_vm.vpn.key),
                  },
                  on: {
                    change: function ($event) {
                      _vm.vpnIdAndAlias = $event.target.value
                    },
                  },
                }),
              ],
              1
            ),
            _vm.shouldShowVpnSettings(_vm.vpn.key)
              ? [
                  _c("telia-p", [
                    _c("strong", [_vm._v(_vm._s(_vm.t("DATANET_SPEED")))]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "input-wrapper margin__bottom__24" },
                    [
                      _c("telia-select", {
                        attrs: {
                          id: "vpn-" + _vm.vpn.key + "-bandwidth",
                          options: JSON.stringify(_vm.bandwidthOptions),
                          disabled: !_vm.isVpnBandwidthEditable,
                        },
                        on: {
                          change: function ($event) {
                            _vm.bandwidth = $event.target.value
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("telia-p", [
                    _c("strong", [_vm._v(_vm._s(_vm.t("DATANET_QOS")))]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "input-wrapper margin__bottom__24" },
                    [
                      _c("telia-select", {
                        attrs: {
                          id: "vpn-" + _vm.vpn.key + "-qos",
                          options: JSON.stringify(_vm.qosOptions),
                          disabled: !_vm.isVpnQosEditable(_vm.vpn.key),
                        },
                        on: {
                          change: function ($event) {
                            _vm.qos = $event.target.value
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.shouldShowRtBandwidth(_vm.vpn.key)
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "column" },
                          [
                            _c("telia-p", [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.t("DATANET_RT1"))),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "input-wrapper margin__bottom__24",
                              },
                              [
                                _c("telia-select", {
                                  attrs: {
                                    id: "vpn-" + _vm.vpn.key + "-rt1",
                                    options: JSON.stringify(_vm.rt1Options),
                                    disabled: !_vm.isVpnRt1Editable(
                                      _vm.vpn.key
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.rt1 = $event.target.value
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.shouldShowRt2(_vm.vpn.key)
                          ? _c(
                              "div",
                              { staticClass: "column" },
                              [
                                _c("telia-p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.t("DATANET_RT2"))),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "input-wrapper" },
                                  [
                                    _c("telia-select", {
                                      attrs: {
                                        id: "vpn-" + _vm.vpn.key + "-rt2",
                                        options: JSON.stringify(_vm.rt1Options),
                                        disabled: !_vm.isVpnRt2Editable(
                                          _vm.vpn.key
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.rt2 = $event.target.value
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.shouldShowDeleteExistingVpn(_vm.vpn.key)
              ? _c(
                  "TextButton",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.setActionDelete({ vpnKey: _vm.vpn.key })
                      },
                    },
                  },
                  [
                    _c("telia-icon", { attrs: { name: "delete", size: "sm" } }),
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.t("DATANET_VPN_DELETE_EXISTING_VPN")
                        ),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }