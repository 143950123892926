import Vue from "vue";
import MetadataKeys from "../../constants/MetadataKeys";

const state = defaultState();

function defaultState() {
  return {
    installation: defaultAddress("installation"),
    delivery: defaultAddress("delivery"),
    secondary: defaultAddress("secondary"),
  };
}

function defaultAddress(id = "") {
  const address = {
    id: id,
    fullAddress: "",
    streetName: "",
    streetNumber: "",
    entrance: "",
    postalCode: "",
    city: "",
    country: "",
  };

  if (id === "installation" || id === "secondary") {
    Object.assign(address, {
      x: "",
      y: "",
      apartmentNumber: "",
      installationRoom: "",
    });
  }

  return address;
}

const getters = {
  getAddress: (state) => (addressId = "") => {
    if (!addressId) {
      return null;
    } else {
      return state[addressId] || null;
    }
  },

  includeInstallationSelectionStep: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.ADDRESS_INSTALLATION),

  includeDeliveryConfigurationStep: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.ADDRESS_DELIVERY) &&
    (rootGetters["products/isWirelessAccess"] ||
      rootState.access.temporary.enable ||
      (rootState.access.secondary.enable && rootGetters["products/isWirelessBackupAccess"])),

  shouldShowSecondaryAddress: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.ADDRESS_SECONDARY) &&
    rootState.access.secondary.enable &&
    (rootGetters["access/hasBackupTypeRedundancy"] || rootGetters["access/hasBackupTypeDiversity"]),

  isSecondaryAddressEditable: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isEditable"](MetadataKeys.ADDRESS_SECONDARY) &&
    rootState.access.secondary.enable &&
    (rootGetters["access/hasBackupTypeRedundancy"] || rootGetters["access/hasBackupTypeDiversity"]),
};

const actions = {
  addAddress: ({ state, commit }, { addressId = "", newAddress = {} }) => {
    if (!Object.values(newAddress).length === 0 || !addressId) {
      return;
    }

    if (state[addressId]) {
      commit("UPDATE_ADDRESS", { addressId, newAddress });
    } else {
      commit("ADD_ADDRESS", { addressId, newAddress });
    }
  },

  resetAddress: ({ commit }, addressId = "") => {
    if (!addressId) {
      return;
    }

    commit("RESET_ADDRESS", addressId);
  },

  setInstallationRoomInfo: ({ commit, dispatch, state }, info = "") => {
    if (state.installation.installationRoom === info) {
      return;
    }

    commit("SET_INSTALLATION_ROOM_INFO", info);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },
};

const mutations = {
  UPDATE_ADDRESS(state, { addressId = "", newAddress = {} }) {
    let address = state[addressId];
    Object.assign(address, newAddress, { id: addressId });
    Vue.set(state, addressId, address);
  },

  ADD_ADDRESS(state, { addressId = "", newAddress = {} }) {
    newAddress = Object.assign(defaultAddress(addressId), newAddress);
    Vue.set(state, addressId, newAddress);
  },

  SET_INSTALLATION_ROOM_INFO(state, info) {
    state.installation.installationRoom = info;
  },

  RESET_ADDRESS(state, addressId) {
    state[addressId] = Object.assign({}, defaultAddress(addressId));
  },

  RESET_STATE(state) {
    Object.assign(state, defaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
