<template>
  <div class="text-button" @click.prevent="$emit('click')">
    <slot />
  </div>
</template>

<script>
export default {
  name: "TextButton",
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
.text-button {
  display: flex;
  align-items: center;
}
.text-button:hover {
  cursor: pointer;
  color: $telia-purple-500;
}

.text-button > * {
  margin-right: 0.6rem;
}

.text-button > *:last-child {
  margin-right: 0;
}
</style>
