const DEFAULT_LENGTH_OF_ID = 5;
const POSSIBLE_CHARACTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abcdefghijklmnopqrstuvwxyz";

function generateShortId({ length = DEFAULT_LENGTH_OF_ID } = {}) {
  let id = "";
  for (let i = 0; i < Math.floor(length); i++) {
    const char = POSSIBLE_CHARACTERS[Math.floor(Math.random() * POSSIBLE_CHARACTERS.length)];
    id = id + char;
  }
  return id;
}

export default generateShortId;
