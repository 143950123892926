<template>
  <SummaryList>
    <SummaryRow variant="header">
      <SummaryColumn class="summary-column--small">
        <telia-p> {{ t("DATANET_ACCESS") }}</telia-p>
      </SummaryColumn>
      <SummaryColumn class="summary-column--large">
        <SummaryRow variant="header" class="summary-row--nested">
          <SummaryColumn>
            <telia-p> {{ t("DATANET_SETTING") }}</telia-p>
          </SummaryColumn>
          <SummaryColumn> <telia-p>&nbsp;</telia-p> </SummaryColumn>
          <SummaryColumn>
            <telia-p> {{ t("DATANET_CURRENT_VALUE") }}</telia-p>
          </SummaryColumn>
          <SummaryColumn>
            <telia-p> {{ t("DATANET_NEW_VALUE") }}</telia-p>
          </SummaryColumn>
        </SummaryRow>
      </SummaryColumn>
    </SummaryRow>
    <SummaryRow>
      <SummaryColumn variant="align-top" class="summary-column--small">
        <telia-p>
          <strong>{{ t("DATANET_PRIMARY_ACCESS") }}</strong>
        </telia-p>
      </SummaryColumn>
      <SummaryColumn variant="align-top" class="summary-column--large">
        <SummaryRow class="summary-row--nested">
          <SummaryColumn variant="align-top">
            <telia-p>{{ t("DATANET_ACCESS_BASE_SETTINGS") }}</telia-p>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div class="field-name">
              <telia-p>{{ t("DATANET_ACCESS_TYPE") }}</telia-p>
            </div>
            <div class="field-name">
              <telia-p>{{ t("DATANET_SPEED") }}</telia-p>
            </div>
            <div class="field-name">
              <telia-p>{{ t("DATANET_SLA_SHORT") }}</telia-p>
            </div>
            <div class="field-name">
              <telia-p>{{ t("DATANET_QOS") }}</telia-p>
            </div>
            <div class="field-name">
              <telia-p>{{ t("DATANET_STAT_BAS") }}</telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div>
              <telia-p>{{
                this.primaryAccessBearer ? t(`DATANET_${this.primaryAccessBearer}`) : "-"
              }}</telia-p>
            </div>
            <div>
              <telia-p>{{ getPrimaryAccessBandwidthText(true) }}</telia-p>
            </div>
            <div>
              <telia-p>{{ primaryAccess.sla.current || "-" }}</telia-p>
            </div>
            <div>
              <telia-p>{{
                primaryAccess.qos.current
                  ? t(`DATANET_${primaryAccess.qos.current}_DESCRIPTION`)
                  : "-"
              }}</telia-p>
            </div>
            <div>
              <telia-p>{{
                primaryAccess.options.trafficStatistics.current
                  ? this.t("DATANET_YES")
                  : this.t("DATANET_NO")
              }}</telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div><telia-p>&nbsp;</telia-p></div>
            <div
              :class="
                getPrimaryAccessBandwidthText() !== getPrimaryAccessBandwidthText(true)
                  ? 'field-value--changed'
                  : ''
              "
            >
              <telia-p>
                {{
                  getPrimaryAccessBandwidthText() !== getPrimaryAccessBandwidthText(true)
                    ? getPrimaryAccessBandwidthText()
                    : "-"
                }}
              </telia-p>
            </div>
            <div
              :class="
                primaryAccess.sla.new !== primaryAccess.sla.current ? 'field-value--changed' : ''
              "
            >
              <telia-p>
                {{
                  primaryAccess.sla.new !== primaryAccess.sla.current ? primaryAccess.sla.new : "-"
                }}
              </telia-p>
            </div>
            <div
              :class="
                primaryAccess.qos.new !== primaryAccess.qos.current ? 'field-value--changed' : ''
              "
            >
              <telia-p>
                {{
                  primaryAccess.qos.new !== primaryAccess.qos.current
                    ? t(`DATANET_${primaryAccess.qos.new}_DESCRIPTION`)
                    : "-"
                }}
              </telia-p>
            </div>
            <div
              :class="
                primaryAccess.options.trafficStatistics.current !==
                primaryAccess.options.trafficStatistics.new
                  ? 'field-value--changed'
                  : ''
              "
            >
              <telia-p>
                {{
                  primaryAccess.options.trafficStatistics.current !==
                  primaryAccess.options.trafficStatistics.new
                    ? primaryAccess.options.trafficStatistics.new
                      ? this.t("DATANET_YES")
                      : this.t("DATANET_NO")
                    : "-"
                }}
              </telia-p>
            </div>
          </SummaryColumn>
        </SummaryRow>
        <SummaryRow
          v-for="(community, communityIndex) in getSnmpCommunities"
          :key="`community-${communityIndex}`"
          class="summary-row--nested"
        >
          <SummaryColumn variant="align-top" class="field-value--changed">
            <telia-p>
              {{ `${t("DATANET_ACTION_" + community.action)} ${t("DATANET_SNMP_COMMUNITY")}` }}
            </telia-p>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div class="field-name">
              <telia-p>{{ t("DATANET_SNMP_COMMUNITY_NAME") }}</telia-p>
            </div>
            <div
              v-for="(network, networkIndex) in community.networks"
              :key="`community-${communityIndex}-network-${networkIndex}-title`"
              class="field-name"
            >
              <telia-p>{{ t("DATANET_SNMP_NETWORK") }}</telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div>
              <telia-p>{{ community.communityName.current || "-" }}</telia-p>
            </div>
            <div
              v-for="(network, networkIndex) in community.networks"
              :key="`community-${communityIndex}-network-${networkIndex}-current`"
            >
              <telia-p>{{ network.input.current || "-" }}</telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div :class="community.communityName.new ? 'field-value--changed' : ''">
              <telia-p>{{ community.communityName.new || "-" }}</telia-p>
            </div>
            <div
              v-for="(network, networkIndex) in community.networks"
              :key="`community-${communityIndex}-network-${networkIndex}-new`"
              :class="network.input.new ? 'field-value--changed' : ''"
            >
              <telia-p>{{ network.input.new || "-" }}</telia-p>
            </div>
          </SummaryColumn>
        </SummaryRow>
        <SummaryRow
          v-if="internetOnSiteAction === 'UPDATE' || internetOnSiteAction === 'DELETE'"
          class="summary-row--nested"
        >
          <SummaryColumn variant="align-top" class="field-value--changed">
            <telia-p>
              {{
                `${t("DATANET_ACTION_" + internetOnSiteAction)} ${t("DATANET_INTERNET_ON_SITE")}`
              }}
            </telia-p>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div
              v-if="internetOnSiteAction === 'UPDATE' && isInternetOnSiteCapacityChanged"
              class="field-name"
            >
              <telia-p>{{ t("DATANET_INTERNET_ON_SITE_SELECT_BANDWIDTH") }}</telia-p>
            </div>
            <div
              v-if="internetOnSiteAction === 'UPDATE' && isInternetOnSiteEthernetInterfacesChanged"
              class="field-name"
            >
              <telia-p>{{ t("DATANET_INTERNET_ON_SITE_SELECT_ETHERNET_INTERFACES") }}</telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div v-if="internetOnSiteAction === 'UPDATE' && isInternetOnSiteCapacityChanged">
              <telia-p>{{ currentSelectedBandwidthText || "-" }}</telia-p>
            </div>
            <div
              v-if="internetOnSiteAction === 'UPDATE' && isInternetOnSiteEthernetInterfacesChanged"
            >
              <telia-p>
                {{
                  `${
                    currentExtraEthernetInterfaces > 0
                      ? t("DATANET_INTERNET_ON_SITE_EXTRA_ETHERNET_INTERFACES", {
                          number: currentExtraEthernetInterfaces,
                        })
                      : "-"
                  }`
                }}
              </telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div
              v-if="internetOnSiteAction === 'UPDATE' && isInternetOnSiteCapacityChanged"
              class="field-value--changed"
            >
              <telia-p>{{ newSelectedBandwidthText || "-" }}</telia-p>
            </div>
            <div
              v-if="internetOnSiteAction === 'UPDATE' && isInternetOnSiteEthernetInterfacesChanged"
              class="field-value--changed"
            >
              <telia-p>
                {{
                  `${
                    extraEthernetInterfaces > 0
                      ? t("DATANET_INTERNET_ON_SITE_EXTRA_ETHERNET_INTERFACES", {
                          number: extraEthernetInterfaces,
                        })
                      : t("DATANET_INTERNET_ON_SITE_NO_EXTRA_ETHERNET_INTERFACES")
                  }`
                }}
              </telia-p>
            </div>
          </SummaryColumn>
        </SummaryRow>
      </SummaryColumn>
    </SummaryRow>
  </SummaryList>
</template>

<script>
import SummaryColumn from "../summary/SummaryColumn";
import SummaryList from "../summary/SummaryList";
import SummaryRow from "../summary/SummaryRow";
import { mapState, mapGetters } from "vuex";
import { Accesses, Actions } from "../../constants";
import { translateMixin } from "../../locale";
import createBandwidthLabel from "../../helpers/createBandwidthLabel";

export default {
  name: "AccessChangeSummary",
  mixins: [translateMixin],
  components: {
    SummaryColumn,
    SummaryList,
    SummaryRow,
  },

  computed: {
    ...mapState("products", {
      primaryAccessBearer: (state) => state.bearer.value,
    }),

    ...mapState("access", {
      internetOnSiteAction: (state) => state[Accesses.PRIMARY].options.internetOnSite.action,
      useDynamicCapacityAction: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity.dynamic.action,
      currentUseDynamicCapacity: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity.dynamic.current,
      useDynamicCapacity: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity.dynamic.new,
      currentStaticBandwidth: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity.bandwidth.current,
      staticBandwidthAction: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity.bandwidth.action,
      staticBandwidth: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.capacity.bandwidth.new,
      extraEthernetInterfacesAction: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.ethernetInterface.action,
      currentExtraEthernetInterfaces: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.ethernetInterface.current,
      extraEthernetInterfaces: (state) =>
        state[Accesses.PRIMARY].options.internetOnSite.lanConfig.ethernetInterface.new,
    }),

    ...mapGetters("products", ["getBandwidthText"]),

    ...mapGetters("access", ["getAccess", "getSnmpCommunities"]),

    primaryAccess() {
      return this.getAccess(Accesses.PRIMARY);
    },

    isInternetOnSiteCapacityChanged() {
      return (
        this.currentUseDynamicCapacity !== this.useDynamicCapacity ||
        this.currentStaticBandwidth !== this.staticBandwidth
      );
    },

    isInternetOnSiteEthernetInterfacesChanged() {
      return this.extraEthernetInterfacesAction === Actions.UPDATE;
    },

    currentSelectedBandwidthText() {
      if (this.currentUseDynamicCapacity) {
        return this.t("DATANET_INTERNET_ON_SITE_DYNAMIC_CAPACITY");
      } else if (this.currentStaticBandwidth) {
        return createBandwidthLabel(this.currentStaticBandwidth, this.currentStaticBandwidth);
      }

      return null;
    },

    newSelectedBandwidthText() {
      if (this.useDynamicCapacity) {
        return this.t("DATANET_INTERNET_ON_SITE_DYNAMIC_CAPACITY");
      } else if (this.staticBandwidth) {
        return createBandwidthLabel(this.staticBandwidth, this.staticBandwidth);
      }

      return null;
    },
  },

  methods: {
    getPrimaryAccessBandwidthText(useCurrent = false) {
      return this.getBandwidthText(
        this.primaryAccessBearer,
        useCurrent ? this.primaryAccess.bandwidth.current : this.primaryAccess.bandwidth.new
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";

.summary-row--nested:first-child {
  padding-top: 0;
}

.summary-row--nested:last-child {
  border-bottom: none;
}

.summary-column--large {
  flex: 5;
}

.summary-column--small {
  flex: 1;
}

.field-name {
  color: $telia-gray-500;
}

.field-value--changed {
  color: $telia-blue-500;
}
</style>
