<template>
  <telia-p variant="additional-100" class="validation-error">
    <slot />
  </telia-p>
</template>

<script>
export default {
  name: "ValidationError",
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables.scss";

.validation-error {
  margin-bottom: 0;
  margin-top: $telia-spacing-4;
  color: $telia-red-600;
}
</style>
