var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b2x-input-suggestions", {
        ref: "addressSuggestions",
        attrs: {
          label: _vm.label,
          suggestions: JSON.stringify(_vm.parsedAddressSuggestions),
          loading: _vm.loading,
          disabled: _vm.disabled,
          value: _vm.selectedAddress,
          error: _vm.addressError,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }