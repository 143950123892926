<template>
  <div>
    <telia-notification
      v-if="shouldDeleteVpn(vpn.key)"
      heading-tag="h4"
      variant="inline"
      status="information"
      html-aria-live="polite"
      html-role="alert"
    >
      <span slot="heading">
        <telia-visually-hidden>
          {{ t("A11Y_STATUS_INFORMATION") }}
        </telia-visually-hidden>
        {{ t("DATANET_VPN_DELETE_EXISTING_VPN_TITLE") }}
      </span>
      <span slot="content">
        <telia-p>
          {{ t("DATANET_VPN_DELETE_EXISTING_VPN_INFO", { id: this.vpnIdAndAlias }) }}
        </telia-p>
      </span>
    </telia-notification>

    <telia-button
      v-if="shouldDeleteVpn(vpn.key) && isVpnActionEditable"
      type="button"
      variant="text"
      @click="setActionNone(vpn.key)"
    >
      {{ t("DATANET_VPN_UNDO_REMOVE_VPN") }}
    </telia-button>

    <ValidationError v-if="v.lans.$error" v-text="t('DATANET_VPN_LAN_AT_LEAST_ONE_REQUIRED')" />

    <template v-if="!shouldDeleteVpn(vpn.key)">
      <telia-p>
        <strong>{{ t("DATANET_VPN_ID") }}</strong>
      </telia-p>
      <div class="input-wrapper margin__bottom__24">
        <telia-select
          :id="`vpn-${vpn.key}-id`"
          t-id="vpn-id"
          :options="JSON.stringify(vpnIdOptions)"
          :disabled="!isVpnIdEditable(vpn.key)"
          @change="vpnIdAndAlias = $event.target.value"
        />
      </div>

      <template v-if="shouldShowVpnSettings(vpn.key)">
        <telia-p>
          <strong>{{ t("DATANET_SPEED") }}</strong>
        </telia-p>
        <div class="input-wrapper margin__bottom__24">
          <telia-select
            :id="`vpn-${vpn.key}-bandwidth`"
            :options="JSON.stringify(bandwidthOptions)"
            :disabled="!isVpnBandwidthEditable"
            @change="bandwidth = $event.target.value"
          />
        </div>

        <telia-p>
          <strong>{{ t("DATANET_QOS") }}</strong>
        </telia-p>
        <div class="input-wrapper margin__bottom__24">
          <telia-select
            :id="`vpn-${vpn.key}-qos`"
            :options="JSON.stringify(qosOptions)"
            :disabled="!isVpnQosEditable(vpn.key)"
            @change="qos = $event.target.value"
          />
        </div>

        <div v-if="shouldShowRtBandwidth(vpn.key)" class="row">
          <div class="column">
            <telia-p>
              <strong>{{ t("DATANET_RT1") }}</strong>
            </telia-p>
            <div class="input-wrapper margin__bottom__24">
              <telia-select
                :id="`vpn-${vpn.key}-rt1`"
                :options="JSON.stringify(rt1Options)"
                :disabled="!isVpnRt1Editable(vpn.key)"
                @change="rt1 = $event.target.value"
              />
            </div>
          </div>
          <div v-if="shouldShowRt2(vpn.key)" class="column">
            <telia-p>
              <strong>{{ t("DATANET_RT2") }}</strong>
            </telia-p>
            <div class="input-wrapper">
              <telia-select
                :id="`vpn-${vpn.key}-rt2`"
                :options="JSON.stringify(rt1Options)"
                :disabled="!isVpnRt2Editable(vpn.key)"
                @change="rt2 = $event.target.value"
              />
            </div>
          </div>
        </div>
      </template>
      <TextButton
        v-if="shouldShowDeleteExistingVpn(vpn.key)"
        @click="setActionDelete({ vpnKey: vpn.key })"
      >
        <telia-icon name="delete" size="sm" />
        <span v-text="t('DATANET_VPN_DELETE_EXISTING_VPN')" />
      </TextButton>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { translateMixin } from "../../locale";
import TextButton from "../TextButton";
import ValidationError from "../ValidationError";

export default {
  name: "BaseSettings",
  mixins: [translateMixin],

  components: {
    TextButton,
    ValidationError,
  },

  props: {
    vpn: {
      type: Object,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters("products", [
      "getVpnBandwidthOptions",
      "getVpnQosOptions",
      "getVpnRt1Options",
      "getVpnRt2Options",
    ]),

    ...mapGetters("user", ["getVpnIdOptions"]),

    ...mapGetters("vpn", [
      "shouldShowVpnSettings",
      "shouldDeleteVpn",
      "shouldShowRtBandwidth",
      "shouldShowRt2",
      "shouldShowDeleteExistingVpn",
      "isVpnsEditable",
      "isVpnActionEditable",
      "isVpnIdEditable",
      "isVpnBandwidthEditable",
      "isVpnQosEditable",
      "isVpnRt1Editable",
      "isVpnRt2Editable",
      "getVpnIdAndAlias",
    ]),

    vpnIdAndAlias: {
      get() {
        return `${this.vpn.id}${this.vpn.alias ? " - " + this.vpn.alias : ""}`;
      },
      set(value) {
        const values = value.split(" - ");
        const vpnId = values[0];
        const alias = values[1] || "";
        this.setVpnId({
          vpnKey: this.vpn.key,
          vpnId,
        });
        this.setAlias({
          vpnKey: this.vpn.key,
          alias,
        });
      },
    },

    vpnIdOptions() {
      return this.getVpnIdOptions(this.vpnIdAndAlias).map((option) => {
        return {
          value: option.value,
          label: option.label,
          disabled: option.disabled,
          selected: option.value === this.vpnIdAndAlias,
        };
      });
    },

    bandwidth: {
      get() {
        return this.vpn.bandwidth.new;
      },
      set(value) {
        this.setBandwidth({
          vpnKey: this.vpn.key,
          new: Number(value) || "",
        });
      },
    },

    bandwidthOptions() {
      return this.getVpnBandwidthOptions(this.vpn.key).map((option) => {
        return {
          value: option.value,
          label: option.label,
          disabled: option.disabled,
          selected: option.value === this.bandwidth,
        };
      });
    },

    qos: {
      get() {
        return this.vpn.qos.new;
      },
      set(value) {
        this.setQos({
          vpnKey: this.vpn.key,
          new: value,
        });
      },
    },

    qosOptions() {
      return this.getVpnQosOptions(this.vpn.key).map((option) => {
        return {
          value: option.value,
          label: option.label,
          disabled: option.disabled,
          selected: option.value === this.qos,
        };
      });
    },

    rt1: {
      get() {
        return this.vpn.rt1.new;
      },
      set(value) {
        this.setRt1({
          vpnKey: this.vpn.key,
          new: value,
        });
      },
    },

    rt1Options() {
      return this.getVpnRt1Options(this.vpn.key).map((option) => {
        return {
          value: option.value,
          label: option.label,
          disabled: option.disabled,
          selected: option.value === this.rt1,
        };
      });
    },

    rt2: {
      get() {
        return this.vpn.rt2.new;
      },
      set(value) {
        this.setRt2({
          vpnKey: this.vpn.key,
          new: value,
        });
      },
    },

    rt2Options() {
      return this.getVpnRt2Options(this.vpn.key).map((option) => {
        return {
          value: option.value,
          label: option.label,
          disabled: option.disabled,
          selected: option.value === this.rt2,
        };
      });
    },
  },

  methods: {
    ...mapActions("vpn", [
      "setVpnId",
      "setAlias",
      "setBandwidth",
      "setQos",
      "setRt1",
      "setRt2",
      "setActionDelete",
      "setActionNone",
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
.row {
  display: flex;
}

.column {
  flex: 1;
  margin-right: var(--spacing);
}

.column:last-child {
  margin-right: 0;
}

.input-wrapper {
  max-width: 42rem;
}
.margin {
  &__bottom {
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
}
</style>
