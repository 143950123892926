<template>
  <div class="qos-information">
    <telia-heading tag="h5" variant="title-100" class="margin__bottom__12">
      {{ t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_TITLE") }}
    </telia-heading>
    <telia-p>
      {{ t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_1") }}
    </telia-p>
    <telia-p>
      {{ t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_2") }}
    </telia-p>
    <div class="margin__bottom__12 margin__left__48">
      <ul>
        <telia-p>
          <li>
            {{ t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_3") }}
          </li>
          <li>
            {{ t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_4") }}
          </li>
          <li>
            {{ t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_5") }}
          </li>
          <li>
            {{ t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_6") }}
          </li>
        </telia-p>
      </ul>
    </div>
    <telia-p>
      {{ t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_7") }}
    </telia-p>
    <div class="margin__bottom__12 margin__left__48">
      <ul>
        <telia-p>
          <li>
            {{ t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_8") }}
          </li>
          <li>
            {{ t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_9") }}
          </li>
        </telia-p>
      </ul>
    </div>
    <telia-p>
      {{ t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_10") }}
    </telia-p>
  </div>
</template>
<script>
import { translateMixin } from "../../../locale";
export default {
  name: "QOSTooltip",
  mixins: [translateMixin],
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.margin {
  &__bottom {
    &__4 {
      margin-bottom: $telia-spacing-4;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
  &__top {
    &__12 {
      margin-top: $telia-spacing-12;
    }
  }
  &__left {
    &__48 {
      margin-left: $telia-spacing-48;
    }
  }
}
</style>
