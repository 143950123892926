<template>
  <ol class="list">
    <li v-for="(title, index) in stepTitles" class="list__item" :key="`step-${indexToStep(index)}`">
      <div class="list__item__title">
        <span v-if="showIcons" :class="getIconClassList(indexToStep(index))">
          <span v-if="currentStep > indexToStep(index)" class="list__item__title__icon__container">
            <telia-icon name="checkmark" size="sm" />
          </span>
          <span v-else class="list__item__title__icon__number">{{ indexToStep(index) }}</span>
        </span>
        <p class="list__item__title__text">
          <strong :class="!showIcons ? 'list__item__title__text--large' : ''">
            {{ title }}
          </strong>
        </p>
      </div>
      <slot :name="`step-${indexToStep(index)}`" />
    </li>
  </ol>
</template>

<script>
export default {
  name: "Stepper",

  props: {
    stepTitles: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      default: 1,
      validator: (value) => value > 0,
    },
    showIcons: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    getIconClassList(step) {
      const defaultClass = "list__item__title__icon";

      let classList = defaultClass;
      if (this.currentStep > step) {
        classList = [defaultClass, "list__item__title__icon--completed"];
      } else if (step > this.currentStep) {
        classList = [defaultClass, "list__item__title__icon--inactive"];
      }
      return classList;
    },

    indexToStep(index) {
      return index + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.list {
  margin: 0;
  padding: 0 0 $telia-spacing-24 0;
  list-style: none;
}

.list__item {
  counter-increment: step-counter;
  margin-bottom: $telia-spacing-24;
}

.list__item:last-child {
  margin-bottom: 0;
}

.list__item__title {
  display: flex;
  align-items: center;
  margin-bottom: $telia-spacing-24;
}

.list__item__title__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: $telia-spacing-12;
  height: $telia-spacing-24;
  width: $telia-spacing-24;
  border-radius: 50%;
  background-color: $telia-purple-500;
  font-size: $telia-spacing-16;
}

.list__item__title__icon--completed {
  background-color: $telia-green-500;
}

.list__item__title__icon--inactive {
  background-color: $telia-gray-400;
}

.list__item__title__icon__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.6rem;
  height: 1.6rem;
  color: $telia-white;
}

.list__item__title__icon__number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $telia-spacing-24;
  height: $telia-spacing-24;
  color: $telia-white;
}

.list__item__title__text {
  flex: 1;
  margin: 0;
}

.list__item__title__text--large {
  font-size: 2rem;
}
</style>
