<template>
  <div>
    <telia-p class="info-wrapper margin-bottom__24">
      {{ t("DATANET_ORDER_CONFIRMATION_INFORMATION") }}
    </telia-p>

    <div class="input-wrapper margin-bottom__24">
      <telia-text-input
        t-id="order-alias"
        :label="t('DATANET_ORDER_ALIAS')"
        :disabled="isSubmitting"
        :value="orderAlias"
        @input="orderAlias = $event.target.value"
      />
      <telia-p variant="additional-100" class="additional-text">
        {{ t("DATANET_ORDER_ALIAS_PLACEHOLDER") }}
      </telia-p>
    </div>

    <div class="input-wrapper margin-bottom__24">
      <telia-text-input
        id="order-email"
        type="email"
        :label="t('DATANET_ORDER_EMAIL')"
        :disabled="isSubmitting"
        :value="orderEmail"
        @input="orderEmail = $event.target.value"
      />
      <ValidationError v-if="$v.orderEmail.$error">
        {{
          $v.orderEmail.required
            ? t("DATANET_INVALID_EMAIL_ADDRESS_FORMAT")
            : t("DATANET_EMAIL_ADDRESS_REQUIRED")
        }}
      </ValidationError>
      <telia-p v-else variant="additional-100" class="additional-text">
        {{ t("DATANET_ORDER_EMAIL_PLACEHOLDER") }}
      </telia-p>
    </div>

    <div class="info-wrapper margin-bottom__12">
      <telia-p>
        <strong>
          {{ t("DATANET_DELIVERY_TIME") }}
        </strong>
      </telia-p>
      <telia-p>
        {{ t("DATANET_DELIVERY_TIME_INFO") }}
      </telia-p>
    </div>
    <div class="margin-bottom__32">
      <telia-checkbox
        t-id="consent"
        :disabled="isSubmitting"
        :value="!orderConsentGiven"
        :checked="orderConsentGiven"
        @change="orderConsentGiven = $event.target.checked"
      >
        {{ t("DATANET_ORDER_CONFIRMATION_CONSENT") }}
      </telia-checkbox>
      <ValidationError v-if="$v.orderConsentGiven.$error">
        {{ t("DATANET_ORDER_CONFIRMATION_CONSENT_REQUIRED") }}
      </ValidationError>
    </div>
    <div v-if="alert.show" class="info-wrapper margin-bottom__24">
      <telia-notification
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("A11Y_STATUS_WARNING") }}
          </telia-visually-hidden>
          {{ t("SOMETHING_WENT_WRONG") }}
        </span>
        <span slot="content">
          <telia-p>
            {{ alert.text }}
          </telia-p>
        </span>
      </telia-notification>
    </div>
    <telia-button
      t-id="submit-order"
      type="button"
      variant="expressive"
      :disabled="isSubmitting || isTeliaAdmin"
      @click="submitOrder()"
    >
      <telia-p>
        {{ isSubmitting ? t("DATANET_SUBMITTING_ORDER") : t("DATANET_PLACE_ORDER") }}
      </telia-p>
    </telia-button>
  </div>
</template>

<script>
import { corpValidationService } from "@telia/b2b-rest-client";
import { translateMixin } from "../locale";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { BusinessAreas } from "../constants";
import { mapState, mapGetters } from "vuex";
import ValidationError from "./ValidationError.vue";

const corpValidation = async (value, scopeId) => {
  try {
    const response = await corpValidationService.ValidationControllerService.validate(scopeId, {
      type: "EMAIL",
      value: value,
    });

    return !!response;
  } catch (e) {
    return false;
  }
};

export default {
  name: "OrderConfirmation",
  mixins: [translateMixin, validationMixin],
  components: {
    ValidationError,
  },
  inject: ["allValidations", "installationAddressValidation", "deliveryAddressValidation"],

  data() {
    return {
      isSubmitting: false,
      orderConsentGiven: false,
      alert: {
        show: false,
        type: "alert",
        text: "",
      },
    };
  },

  computed: {
    ...mapState({
      orderId: (state) => state.orderCaptureId,
      orgNumber: (state) => state.user.organisation,
      scopeId: (state) => state.user.scopeId,
      isTeliaAdmin: (state) => state.user.isTeliaAdmin,
    }),

    ...mapGetters({
      isProductBusinessOrder: "isProductBusinessOrder",
      isFunctionBusinessOrder: "isFunctionBusinessOrder",
      hasBackupAccess: "access/hasBackupAccess",
      allVpns: "vpn/getAllVpns",
      getVpn: "vpn/getVpn",
    }),

    orderAlias: {
      get() {
        return this.$store.getters.getOrderAlias;
      },

      set(value) {
        this.$store.dispatch("setOrderAlias", value);
      },
    },

    orderEmail: {
      get() {
        return this.$store.getters["contactPersons/getContact"]("order").emailAddress;
      },
      set(value) {
        this.$store.dispatch("contactPersons/setEmailAddress", {
          id: "order",
          emailAddress: value,
        });
      },
    },
  },

  validations: {
    orderEmail: {
      required,
      validEmail: async function (value) {
        if (!value) {
          return true;
        }

        const isValid = await corpValidation(value, this.scopeId);
        return isValid;
      },
    },
    orderConsentGiven: {
      hasGivenConsent: (value) => value === true,
    },
  },

  methods: {
    async submitOrder() {
      const isValidationValid = await this.touchAndWaitForValidation(this.$v);
      if (!isValidationValid) {
        return;
      }

      await this.touchAndWaitForValidation(this.allValidations);

      const isInstallationAddressValid = !this.installationAddressValidation.$anyError;
      const isDeliveryAddressValid = !this.deliveryAddressValidation.$anyError;
      const isAllValidationsValid = isInstallationAddressValid && isDeliveryAddressValid;

      if (!isAllValidationsValid) {
        this.showAlert(this.t("DATANET_INVALID_FORM_PLEASE_CHECK_ENTERED_DATA"));
        return;
      }

      this.submitting(true);

      try {
        if (this.isProductBusinessOrder) {
          await this.$store.dispatch("submitOrder", {
            businessArea: BusinessAreas.PRODUCT,
            orderId: this.orderId,
          });
        } else if (this.isFunctionBusinessOrder) {
          await this.$store.dispatch("submitOrder", {
            businessArea: BusinessAreas.FUNCTION,
            orderId: this.orderId,
          });
        } else {
          this.showAlert(this.t("DATANET_COULD_NOT_SUBMIT_ORDER"));
          this.submitting(false);
          return;
        }
      } catch (e) {
        this.showAlert(this.t(e.message));
        this.submitting(false);
        return;
      }

      this.submitting(false);
      this.$emit("submitted", true);
    },

    submitting(boolean = true) {
      this.isSubmitting = boolean;
      this.$emit("submitting", boolean);
    },

    showAlert(text = "") {
      this.alert.text = text;
      this.alert.show = true;
    },

    touchAndWaitForValidation(validation) {
      validation.$touch();

      /*
      if (process.env.MYBD_ENV === "test") {
        return new Promise((resolve) => resolve(true));
      }
      */
      let unwatch;
      return new Promise((resolve) => {
        unwatch = this.$watch(
          () => !validation.$pending,
          (isNotPending) => {
            if (isNotPending) {
              if (unwatch) {
                unwatch();
              }

              resolve(!validation.$invalid);
            }
          },
          { immediate: true }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables";

.input-wrapper {
  max-width: 42rem;
}

.input-wrapper p {
  margin-bottom: 0 !important;
}

.margin-bottom {
  &__12 {
    margin-bottom: $telia-spacing-12;
  }
  &__24 {
    margin-bottom: $telia-spacing-24;
  }
  &__32 {
    margin-bottom: $telia-spacing-32;
  }
}

.info-wrapper {
  max-width: 56rem;
}

.additional-text {
  color: $telia-gray-500;
  margin-top: $telia-spacing-4;
}
</style>
