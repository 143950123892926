var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isChangeOrder
        ? _c(
            "div",
            { staticClass: "margin__bottom__24" },
            [
              _c("telia-heading", {
                staticClass: "margin__bottom__12",
                attrs: { tag: "h3", variant: "title-100" },
                domProps: {
                  textContent: _vm._s(_vm.t("DATANET_VPN_EDIT_LAN_CHOICE")),
                },
              }),
              _c("ActionSelection", {
                attrs: {
                  "selected-action": _vm.lan.action,
                  "disabled-actions": _vm.disabledActions,
                },
                on: {
                  selected: function ($event) {
                    return _vm.handleActionSelection($event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.shouldShowIdentifyingNetwork
        ? _c(
            "div",
            { staticClass: "margin__bottom__24" },
            [
              _c("telia-p", [
                _c("strong", [
                  _vm._v(_vm._s(_vm.t("DATANET_VPN_LAN_IDENTIFYING_NETWORK"))),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "input-wrapper margin__bottom__4" },
                [
                  _c("telia-text-input", {
                    attrs: {
                      id: "lan-" + _vm.lan.key + "-identifying-network",
                      label: _vm.t("DATANET_CURRENT_VALUE"),
                      value: _vm.lan.identifyingNetwork.input.new,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.handleIdentifyingNetworkInput(
                          $event.target.value,
                          _vm.lan.key
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.v.identifyingNetwork.$error
                ? _c("ValidationError", [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.identifyingNetworkErrorText) +
                        "\n    "
                    ),
                  ])
                : _c(
                    "telia-p",
                    {
                      staticClass: "additional-text",
                      attrs: { variant: "additional-100" },
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.t("DATANET_NETWORK_PLACEHOLDER")) +
                          "\n    "
                      ),
                    ]
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm.isLogicalInterface
        ? _c(
            "div",
            { staticClass: "margin__bottom__24" },
            [
              _c("telia-p", [
                _c("strong", [
                  _vm._v(_vm._s(_vm.t("DATANET_VPN_LAN_VLAN_ID"))),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "margin__bottom__4 inputs-container" },
                [
                  _vm.shouldNotAddLan
                    ? _c("telia-text-input", {
                        staticClass: "input-container",
                        attrs: {
                          "t-id": "current-vlan-id",
                          label: _vm.t("DATANET_CURRENT_VALUE"),
                          disabled: _vm.isSecondaryAccessVpn(_vm.lan.vpn),
                          "validation-state": _vm.v.vlanId.current.$error
                            ? "Invalid"
                            : "Valid",
                          value: _vm.lan.vlanId.current,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.setVlanId({
                              lanKey: _vm.lan.key,
                              current: $event.target.value,
                            })
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.shouldShowNewVlanId
                    ? _c("telia-text-input", {
                        staticClass: "input-container",
                        attrs: {
                          "t-id": "new-vlan-id",
                          label: _vm.newVlanIdLabel,
                          disabled: _vm.isSecondaryAccessVpn(_vm.lan.vpn),
                          "validation-state": _vm.v.vlanId.new.$error
                            ? "Invalid"
                            : "Valid",
                          value: _vm.lan.vlanId.new,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.setVlanId({
                              lanKey: _vm.lan.key,
                              new: $event.target.value,
                            })
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("telia-p", {
                class: _vm.isAnyVlanIdInvalid
                  ? "additional-text-warning"
                  : "additional-text",
                attrs: { variant: "additional-100" },
                domProps: {
                  textContent: _vm._s(
                    _vm.t("DATANET_VPN_LAN_VLAN_ID_REQUIRED")
                  ),
                },
              }),
              _c(
                "div",
                { staticClass: "margin__top__12" },
                [
                  _vm.shouldShowVlanIdAction
                    ? _c(
                        "telia-checkbox",
                        {
                          attrs: {
                            "t-id": "change-vlanId",
                            value: _vm.vlanIdCheckboxValue,
                            checked: _vm.isVlanIdCheckboxChecked,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.handleVlanIdActionSelection(
                                _vm.vlanIdCheckboxValue
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.t("DATANET_VPN_LAN_UPDATE_VLAN_ID")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.shouldNotDeleteLan
        ? [
            _c(
              "div",
              { staticClass: "information-box-wrapper" },
              [
                _vm.activeTooltip === "lan"
                  ? _c(
                      "InformationBox",
                      {
                        on: {
                          close: function ($event) {
                            return _vm.handleTooltip(null)
                          },
                        },
                      },
                      [_c("LanTooltip")],
                      1
                    )
                  : _vm._e(),
                _vm.activeTooltip === "dhcp-relay"
                  ? _c(
                      "InformationBox",
                      {
                        on: {
                          close: function ($event) {
                            return _vm.handleTooltip(null)
                          },
                        },
                      },
                      [_c("DhcpRelayTooltip")],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "margin__bottom__24" },
              [
                _c(
                  "div",
                  { staticClass: "tooltip-wrapper" },
                  [
                    _vm.isPrimaryAccessVpn(_vm.lan.vpn) ||
                    _vm.lan.networks.length > 0
                      ? _c("telia-p", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.t("DATANET_VPN_LAN_NETWORKS"))),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.isPrimaryAccessVpn(_vm.lan.vpn)
                      ? _c("telia-icon", {
                          staticClass: "tooltip-icon",
                          attrs: { name: "question", size: "sm" },
                          on: {
                            click: function ($event) {
                              return _vm.handleTooltip("lan")
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.lan.networks.length > 0
                  ? _c(
                      "SummaryList",
                      [
                        _c(
                          "SummaryRow",
                          { attrs: { variant: "header" } },
                          [
                            _vm.shouldShowVirtualIp
                              ? _c("SummaryColumn")
                              : _vm._e(),
                            _vm.isChangeOrder
                              ? _c(
                                  "SummaryColumn",
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        _vm._s(_vm.t("DATANET_CURRENT_VALUE"))
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "SummaryColumn",
                              [
                                _c("telia-p", [
                                  _vm._v(_vm._s(_vm.t("DATANET_NEW_VALUE"))),
                                ]),
                              ],
                              1
                            ),
                            _c("SummaryColumn", {
                              attrs: { variant: "small" },
                            }),
                          ],
                          1
                        ),
                        _vm._l(_vm.lan.networks, function (network, index) {
                          return _c(
                            "SummaryRow",
                            { key: "range-" + index },
                            [
                              _vm.shouldShowVirtualIp
                                ? _c("SummaryColumn", [
                                    _c(
                                      "span",
                                      [
                                        _c("telia-p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.t("DATANET_VPN_LAN_NETWORK")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      [
                                        _c("telia-p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.t(
                                                "DATANET_VPN_LAN_VIRTUAL_IP"
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.isChangeOrder
                                ? _c("SummaryColumn", [
                                    _c(
                                      "span",
                                      {
                                        class: _vm.v.networks.$each.$iter[index]
                                          .input.current.$invalid
                                          ? "additional-text-warning"
                                          : "",
                                      },
                                      [
                                        _c("telia-p", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                network.input.current
                                                  ? network.input.current
                                                  : _vm.v.networks.$each.$iter[
                                                      index
                                                    ].input.current.$invalid
                                                  ? _vm.t(
                                                      "DATANET_MISSING_VALUE"
                                                    )
                                                  : "-"
                                              ) +
                                              "\n              "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm.shouldShowVirtualIp
                                      ? _c(
                                          "span",
                                          {
                                            class: _vm.v.networks.$each.$iter[
                                              index
                                            ].virtualIp.current.$invalid
                                              ? "additional-text-warning"
                                              : "",
                                          },
                                          [
                                            _c("telia-p", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    network.virtualIp.current
                                                      ? network.virtualIp
                                                          .current
                                                      : _vm.v.networks.$each
                                                          .$iter[index]
                                                          .virtualIp.current
                                                          .$invalid
                                                      ? _vm.t(
                                                          "DATANET_MISSING_VALUE"
                                                        )
                                                      : "-"
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _c("SummaryColumn", [
                                _c(
                                  "span",
                                  {
                                    class:
                                      _vm.v.networks.$each.$iter[index] &&
                                      _vm.v.networks.$each.$iter[index].input
                                        .new.$invalid
                                        ? "additional-text-warning"
                                        : "",
                                  },
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            network.input.new
                                              ? network.input.new
                                              : _vm.v.networks.$each.$iter[
                                                  index
                                                ].input.new.$invalid
                                              ? _vm.t("DATANET_MISSING_VALUE")
                                              : "-"
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.shouldShowVirtualIp
                                  ? _c(
                                      "span",
                                      {
                                        class: _vm.v.networks.$each.$iter[index]
                                          .virtualIp.current.$invalid
                                          ? "additional-text-warning"
                                          : "",
                                      },
                                      [
                                        _c("telia-p", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                network.virtualIp.new
                                                  ? network.virtualIp.new
                                                  : _vm.v.networks.$each.$iter[
                                                      index
                                                    ].virtualIp.new.$invalid
                                                  ? _vm.t(
                                                      "DATANET_MISSING_VALUE"
                                                    )
                                                  : "-"
                                              ) +
                                              "\n              "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                              !_vm.isChangeOrder
                                ? _c("SummaryColumn")
                                : _vm._e(),
                              _c(
                                "SummaryColumn",
                                {
                                  staticClass: "align-end actions",
                                  attrs: { variant: "small" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "list-button-wrapper" },
                                    [
                                      _c("telia-button", {
                                        staticClass: "summary-action",
                                        attrs: {
                                          size: "sm",
                                          variant: "text",
                                          "right-icon": JSON.stringify({
                                            name: "edit",
                                            size: "sm",
                                          }),
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.showNetworkModal(index)
                                          },
                                        },
                                      }),
                                      _vm.isPrimaryAccessVpn(_vm.lan.vpn)
                                        ? _c("telia-button", {
                                            staticClass: "summary-action",
                                            attrs: {
                                              size: "sm",
                                              variant: "text",
                                              "right-icon": JSON.stringify({
                                                name: "close",
                                                size: "sm",
                                              }),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.removeNetwork({
                                                  lanKey: _vm.lan.key,
                                                  index: index,
                                                })
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.isPrimaryAccessVpn(_vm.lan.vpn)
                  ? _c("telia-button", {
                      attrs: {
                        "t-id": "add-new-lan-network-config",
                        text: _vm.addLanNetworkText,
                        "left-icon": JSON.stringify({
                          name: "plus",
                          size: "sm",
                        }),
                        variant: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showNetworkModal()
                        },
                      },
                    })
                  : _vm._e(),
                _vm.shouldShowNetworksError
                  ? _c("telia-p", {
                      staticClass: "additional-text-warning",
                      attrs: { variant: "additional-100" },
                      domProps: {
                        textContent: _vm._s(
                          _vm.t("DATANET_VPN_LAN_AT_LEAST_ONE_NETWORK_REQUIRED")
                        ),
                      },
                    })
                  : _vm._e(),
                _vm.isLanNetworkModalActive
                  ? _c(
                      "Modal",
                      {
                        attrs: {
                          "modal-id": "lan-network-modal",
                          width: "s",
                          "is-open": _vm.isLanNetworkModalActive,
                        },
                        on: {
                          closeModal: function ($event) {
                            return _vm.closeNetworkModal()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "lan-networks-settings-wrapper",
                            attrs: { slot: "modal-body" },
                            slot: "modal-body",
                          },
                          [
                            _c("LanNetworkSettings", {
                              ref: "lan-networks-settings",
                              attrs: {
                                "initial-network": _vm.selectedNetwork,
                                "lan-action": _vm.lan.action,
                                "is-primary-lan": _vm.isPrimaryAccessVpn(
                                  _vm.lan.vpn
                                ),
                              },
                              on: {
                                "lan-network-data": function ($event) {
                                  return _vm.handleLanNetworkData(
                                    $event,
                                    _vm.selectedNetworkIndex
                                  )
                                },
                                "is-validating": function ($event) {
                                  _vm.isValidatingLanNetwork = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "modal-footer",
                            attrs: { slot: "button-footer" },
                            slot: "button-footer",
                          },
                          [
                            _c("telia-button", {
                              attrs: {
                                "t-id": "save-lan-network-config",
                                variant: "expressive",
                                disabled: _vm.isValidatingLanNetwork,
                                text: _vm.saveLanNetworkText,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs[
                                    "lan-networks-settings"
                                  ].getLanNetworkData()
                                },
                              },
                            }),
                            _c("telia-button", {
                              attrs: { variant: "secondary" },
                              domProps: {
                                textContent: _vm._s(_vm.t("DATANET_ABORT")),
                              },
                              on: { click: _vm.closeNetworkModal },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.shouldShowDhcpRelays(_vm.lan.vpn) &&
            (_vm.isPrimaryAccessVpn(_vm.lan.vpn) || _vm.dhcpRelays.length > 0)
              ? _c(
                  "div",
                  { staticClass: "tooltip-wrapper" },
                  [
                    _c("telia-p", [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.t("DATANET_VPN_DHCP_RELAY"))),
                      ]),
                    ]),
                    _c("telia-icon", {
                      staticClass: "tooltip-icon",
                      attrs: { name: "question", size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.handleTooltip("dhcp-relay")
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.dhcpRelays.length > 0
              ? _c(
                  "SummaryList",
                  [
                    _c(
                      "SummaryRow",
                      { attrs: { variant: "header" } },
                      [
                        _vm.isChangeOrder
                          ? _c(
                              "SummaryColumn",
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(_vm.t("DATANET_CURRENT_VALUE"))
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "SummaryColumn",
                          [
                            _c("telia-p", [
                              _vm._v(_vm._s(_vm.t("DATANET_NEW_VALUE"))),
                            ]),
                          ],
                          1
                        ),
                        _c("SummaryColumn", { attrs: { variant: "small" } }),
                      ],
                      1
                    ),
                    _vm._l(_vm.dhcpRelays, function (dhcpRelay, index) {
                      return _c(
                        "SummaryRow",
                        { key: "range-" + index },
                        [
                          _vm.isChangeOrder
                            ? _c("SummaryColumn", [
                                _c(
                                  "span",
                                  {
                                    class:
                                      _vm.v.dhcpRelays.$each.$iter[index]
                                        .$invalid &&
                                      !_vm.v.dhcpRelays.$each.$iter[index]
                                        .validCurrentIp
                                        ? "additional-text-warning"
                                        : "",
                                  },
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            dhcpRelay.ipAddress.current
                                              ? dhcpRelay.ipAddress.current
                                              : _vm.v.dhcpRelays.$each.$iter[
                                                  index
                                                ].$invalid &&
                                                !_vm.v.dhcpRelays.$each.$iter[
                                                  index
                                                ].validCurrentIp
                                              ? _vm.t("DATANET_MISSING_VALUE")
                                              : "-"
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c("SummaryColumn", [
                            _c(
                              "span",
                              {
                                class:
                                  _vm.v.dhcpRelays.$each.$iter[index]
                                    .$invalid &&
                                  !_vm.v.dhcpRelays.$each.$iter[index]
                                    .validNewIp
                                    ? "additional-text-warning"
                                    : "",
                              },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        dhcpRelay.ipAddress.new
                                          ? dhcpRelay.ipAddress.new
                                          : _vm.v.dhcpRelays.$each.$iter[index]
                                              .$invalid &&
                                            !_vm.v.dhcpRelays.$each.$iter[index]
                                              .validNewIp
                                          ? _vm.t("DATANET_MISSING_VALUE")
                                          : "-"
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                          !_vm.isChangeOrder ? _c("SummaryColumn") : _vm._e(),
                          _c(
                            "SummaryColumn",
                            {
                              staticClass: "align-end",
                              attrs: { variant: "small" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "list-button-wrapper" },
                                [
                                  _vm.isPrimaryAccessVpn(_vm.lan.vpn)
                                    ? _c("telia-button", {
                                        staticClass: "summary-action",
                                        attrs: {
                                          href: "",
                                          variant: "text",
                                          "right-icon": JSON.stringify({
                                            name: "close",
                                            size: "sm",
                                          }),
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.deleteDhcpRelay(
                                              dhcpRelay.key
                                            )
                                          },
                                        },
                                      })
                                    : _c("telia-button", {
                                        staticClass: "summary-action",
                                        attrs: {
                                          href: "",
                                          variant: "text",
                                          "right-icon": JSON.stringify({
                                            name: "edit",
                                            size: "sm",
                                          }),
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.showDhcpModal(
                                              dhcpRelay.key
                                            )
                                          },
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.shouldShowDhcpRelays(_vm.lan.vpn) &&
            _vm.isPrimaryAccessVpn(_vm.lan.vpn)
              ? _c("telia-button", {
                  attrs: {
                    "t-id": "add-new-dhcp-relay-config",
                    variant: "text",
                    "left-icon": JSON.stringify({ name: "plus", size: "sm" }),
                    text: _vm.addDhcpRelayText,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showDhcpModal()
                    },
                  },
                })
              : _vm._e(),
            _vm.isDhcpRelayModalActive
              ? _c(
                  "Modal",
                  {
                    attrs: {
                      "modal-id": "dhcp-relay-modal",
                      width: "s",
                      "is-open": _vm.isDhcpRelayModalActive,
                    },
                    on: {
                      closeModal: function ($event) {
                        return _vm.closeDhcpModal()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "dhcp-relay-settings-wrapper",
                        attrs: { slot: "modal-body" },
                        slot: "modal-body",
                      },
                      [
                        _c("DhcpRelaySettings", {
                          ref: "dhcp-relay-settings",
                          attrs: {
                            "dhcp-relay-key": _vm.selectedDhcpRelayKey,
                            "lan-action": _vm.lan.action,
                          },
                          on: {
                            "dhcp-relay-data": function ($event) {
                              return _vm.handleDhcpRelayData($event)
                            },
                            "is-validating": function ($event) {
                              _vm.isValidatingDhcp = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "modal-footer",
                        attrs: { slot: "button-footer" },
                        slot: "button-footer",
                      },
                      [
                        _c("telia-button", {
                          attrs: {
                            "t-id": "save-dhcp-relay-config",
                            variant: "expressive",
                            disabled: _vm.isValidatingDhcp,
                            text: _vm.saveLanNetworkText,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$refs[
                                "dhcp-relay-settings"
                              ].getDhcpRelayData()
                            },
                          },
                        }),
                        _c("telia-button", {
                          attrs: { variant: "secondary" },
                          domProps: {
                            textContent: _vm._s(_vm.t("DATANET_ABORT")),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.closeDhcpModal()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }