import Vue from "vue";
import defaultLanObject from "./lan";
import network from "./network";

const mutations = {
  ADD_LAN(state, { lanKey, vpnKey, siblingLanKey, lanId } = {}) {
    Vue.set(
      state,
      lanKey,
      defaultLanObject({
        key: lanKey,
        vpn: vpnKey,
        siblingKey: siblingLanKey,
        id: lanId,
      })
    );
  },

  DELETE_LAN(state, key) {
    Vue.delete(state, key);
  },

  SET_ACTION(state, { lanKey, action }) {
    state[lanKey].action = action;
  },

  SET_VLAN_ID(state, { lanKey, vlanId }) {
    Vue.set(state[lanKey], "vlanId", vlanId);
  },

  SET_VLAN_ID_ACTION(state, { lanKey, action }) {
    state[lanKey].vlanId.action = action;
  },

  ADD_NETWORK(state, lanKey) {
    state[lanKey].networks.push(network());
  },

  REMOVE_NETWORK(state, { lanKey, index }) {
    const lan = state[lanKey];
    lan.networks.splice(index, 1);
  },

  SET_NETWORK_INPUT_ACTION(state, { lanKey, index, action }) {
    state[lanKey].networks[index].input.action = action;
  },

  SET_NETWORK_ACTION(state, { lanKey, index, action }) {
    state[lanKey].networks[index].action = action;
  },

  SET_NETWORK_INPUT(state, { lanKey, index, input }) {
    Vue.set(state[lanKey].networks[index], "input", input);
  },

  SET_NETWORK_IP_VERSION(state, { lanKey, index, ipVersion }) {
    Vue.set(state[lanKey].networks[index], "ipVersion", ipVersion);
  },

  SET_NETWORK_IP_ADDRESS(state, { lanKey, index, ipAddress }) {
    Vue.set(state[lanKey].networks[index], "ipAddress", ipAddress);
  },

  SET_NETWORK_IP_PREFIX(state, { lanKey, index, ipPrefix }) {
    Vue.set(state[lanKey].networks[index], "ipPrefix", ipPrefix);
  },

  SET_NETWORK_VIRTUAL_IP(state, { lanKey, index, virtualIp }) {
    Vue.set(state[lanKey].networks[index], "virtualIp", virtualIp);
  },

  SET_IDENTIFYING_NETWORK_INPUT(state, { lanKey, identifyingNetworkInput }) {
    Vue.set(state[lanKey].identifyingNetwork, "input", identifyingNetworkInput);
  },

  SET_IDENTIFYING_NETWORK_IP_ADDRESS(state, { lanKey, identifyingNetworkIpAddress }) {
    Vue.set(state[lanKey].identifyingNetwork, "ipAddress", identifyingNetworkIpAddress);
  },

  SET_IDENTIFYING_NETWORK_IP_VERSION(state, { lanKey, identifyingNetworkIpVersion }) {
    Vue.set(state[lanKey].identifyingNetwork, "ipVersion", identifyingNetworkIpVersion);
  },

  SET_IDENTIFYING_NETWORK_IP_PREFIX(state, { lanKey, identifyingNetworkIpPrefix }) {
    Vue.set(state[lanKey].identifyingNetwork, "ipPrefix", identifyingNetworkIpPrefix);
  },

  SET_SIBLING_LAN_KEY(state, { lanKey, siblingLanKey }) {
    state[lanKey].siblingKey = siblingLanKey;
  },

  ADD_DHCP_RELAY(state, { lanKey, dhcpRelayKey }) {
    state[lanKey].dhcpRelays.push(dhcpRelayKey);
  },

  REMOVE_DHCP_RELAY(state, { lanKey, dhcpRelayKey }) {
    const lan = state[lanKey];
    const index = lan.dhcpRelays.indexOf(dhcpRelayKey);
    lan.dhcpRelays.splice(index, 1);
  },

  RESET_LAN(state, lanKey) {
    const lan = state[lanKey];
    Vue.set(
      state,
      lan.key,
      defaultLanObject({
        key: lan.key,
        vpn: lan.vpn,
        siblingKey: lan.siblingKey,
        id: lan.id,
      })
    );
  },

  RESET_STATE(state) {
    Object.keys(state).forEach((key) => delete state[key]);
  },
};

export default mutations;
