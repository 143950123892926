<template>
  <div class="delivery-tooltip">
    <telia-heading tag="h4" variant="title-100">
      {{ t("DATANET_GUIDANCE_DELIVERY_INFORMATION_TITLE") }}
    </telia-heading>
    <telia-p>
      {{ t("DATANET_GUIDANCE_DELIVERY_INFORMATION_INFO") }}
    </telia-p>
  </div>
</template>
<script>
import { translateMixin } from "../../../locale";
export default {
  name: "DeliveryTooltip",
  mixins: [translateMixin],
  props: {
    showContactInfoById: {
      type: String,
    },
  },
};
</script>
