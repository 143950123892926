<template>
  <div>
    <div class="excluded-ip-range">
      <telia-heading tag="h3" variant="title-100" class="margin__bottom__12">
        {{ t("DATANET_VPN_CONFIGURE_EXCLUDED_IP_RANGE") }}
      </telia-heading>
      <div t-id="action-select">
        <ActionSelection
          v-if="isChangeOrder"
          class="component-wrapper"
          :selected-action="action"
          :disabled-actions="disabledActions"
          @selected="setIpRangeAction($event)"
        />
      </div>
      <div class="row margin__bottom__12">
        <div class="column column-small">
          <telia-p class="column-title column-title-margin">
            {{ t("DATANET_VPN_DHCP_SERVER_EXCLUDED_RANGE_START") }}
          </telia-p>
        </div>
        <div class="column-container">
          <div v-if="isCurrentValuesRequired" class="column input-wrapper">
            <telia-text-input
              t-id="current-start-interval"
              id="server-excluded-range-start-current"
              :label="t('DATANET_CURRENT_VALUE')"
              :validation-state="$v.start.ipAddress.current.$error ? 'Invalid' : 'Valid'"
              :value="start.ipAddress.current"
              @input="start.ipAddress.current = $event.target.value"
            />
          </div>
          <div v-if="isNewValuesRequired" class="column input-wrapper">
            <telia-text-input
              t-id="new-start-interval"
              :label="t('DATANET_NEW_VALUE')"
              :validation-state="$v.start.ipAddress.new.$error ? 'Invalid' : 'Valid'"
              :value="start.ipAddress.new"
              @input="start.ipAddress.new = $event.target.value"
            />
          </div>
        </div>
      </div>
      <div class="row margin__bottom__4">
        <div class="column column-small">
          <telia-p class="column-title">
            {{ t("DATANET_VPN_DHCP_SERVER_EXCLUDED_RANGE_END") }}
          </telia-p>
        </div>
        <div class="column-container">
          <div v-if="isCurrentValuesRequired" class="column input-wrapper">
            <telia-text-input
              t-id="current-end-interval"
              id="server-excluded-range-end-current"
              :validation-state="$v.end.ipAddress.current.$error ? 'Invalid' : 'Valid'"
              :value="end.ipAddress.current"
              @input="end.ipAddress.current = $event.target.value"
            />
          </div>
          <div v-if="isNewValuesRequired" class="column input-wrapper">
            <telia-text-input
              t-id="new-end-interval"
              :validation-state="$v.end.ipAddress.new.$error ? 'Invalid' : 'Valid'"
              :value="end.ipAddress.new"
              @input="end.ipAddress.new = $event.target.value"
            />
          </div>
        </div>
      </div>
      <div class="row margin__bottom__24">
        <div class="column column-small"><telia-p>&nbsp;</telia-p></div>
        <div class="column-container">
          <ValidationError v-if="$v.$error">
            {{ t("DATANET_INVALID_IP_ADDRESS") }}
          </ValidationError>
          <telia-p v-else class="additional-text" variant="additional-100">
            {{ t("DATANET_IP_ADDRESS_PLACEHOLDER") }}
          </telia-p>
        </div>
      </div>
    </div>
    <div class="button-wrapper">
      <telia-button
        t-id="save-excluded-ip-interval-config"
        type="button"
        variant="expressive"
        :disabled="disableSaveButton"
        @click="saveAndClose()"
      >
        {{ t("DATANET_SAVE_CHANGES") }}
      </telia-button>

      <telia-button
        id="cancel-excluded-ip-address-configuration"
        type="button"
        variant="secondary"
        @click="$emit('complete')"
      >
        {{ t("DATANET_ABORT") }}
      </telia-button>
    </div>
  </div>
</template>

<script>
import ActionSelection from "../ActionSelection";
import ValidationError from "../ValidationError";
import { corpValidationService } from "@telia/b2b-rest-client";
import { mapState, mapActions, mapGetters } from "vuex";
import defaultChangeableValue from "../../store/defaultChangeableValue";
import { Actions } from "../../constants";
import { corpIpAddress } from "../../helpers/corpValidation";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { translateMixin } from "../../locale";

export default {
  name: "ExcludedIpRange",
  mixins: [translateMixin, validationMixin],
  components: {
    ActionSelection,
    ValidationError,
  },

  props: {
    dhcpServerKey: [String, Number],
    rangeIndex: Number,
  },

  data() {
    return {
      action: Actions.ADD,
      start: {
        ipAddress: defaultChangeableValue(),
        ipVersion: defaultChangeableValue(),
      },
      end: {
        ipAddress: defaultChangeableValue(),
        ipVersion: defaultChangeableValue(),
      },
      currentStartIpTimeoutId: null,
      newStartIpTimeoutId: null,
      currentEndIpTimeoutId: null,
      newEndIpTimeoutId: null,
      disableSaveButton: false,
    };
  },

  computed: {
    ...mapState({
      scopeId: (state) => state.user.scopeId,
    }),

    ...mapGetters(["isChangeOrder"]),

    ...mapGetters("dhcpServer", {
      getDhcpServerAction: "getAction",
    }),

    serverAction() {
      return this.getDhcpServerAction(this.dhcpServerKey);
    },

    disabledActions() {
      if (this.serverAction === Actions.ADD) {
        return [Actions.UPDATE, Actions.DELETE];
      }
      return [];
    },

    isCurrentValuesRequired() {
      return this.action === Actions.UPDATE || this.action === Actions.DELETE;
    },

    isNewValuesRequired() {
      return this.action === Actions.ADD || this.action === Actions.UPDATE;
    },
  },

  validations: {
    start: {
      ipAddress: {
        current: {
          required: requiredIf(function () {
            return this.isCurrentValuesRequired;
          }),
          validIpAddress: async function (value) {
            if (!this.isCurrentValuesRequired && !value) {
              return true;
            }

            const isValid = await corpIpAddress(value, this.scopeId);
            return isValid;
          },
        },
        new: {
          required: requiredIf(function () {
            return this.isNewValuesRequired;
          }),
          validIpAddress: async function (value) {
            if (!this.isNewValuesRequired && !value) {
              return true;
            }

            const isValid = await corpIpAddress(value, this.scopeId);
            return isValid;
          },
        },
      },
    },
    end: {
      ipAddress: {
        current: {
          required: requiredIf(function () {
            return this.isCurrentValuesRequired;
          }),
          validIpAddress: async function (value) {
            if (!this.isCurrentValuesRequired && !value) {
              return true;
            }

            const isValid = await corpIpAddress(value, this.scopeId);
            return isValid;
          },
        },
        new: {
          required: requiredIf(function () {
            return this.isNewValuesRequired;
          }),
          validIpAddress: async function (value) {
            if (!this.isNewValuesRequired && !value) {
              return true;
            }

            const isValid = await corpIpAddress(value, this.scopeId);
            return isValid;
          },
        },
      },
    },
  },

  methods: {
    ...mapActions("dhcpServer", [
      "addExcludedIpRange",
      "setExcludedRangeStartIpAddress",
      "setExcludedRangeStartIpVersion",
      "setExcludedRangeEndIpAddress",
      "setExcludedRangeEndIpVersion",
    ]),

    setIpRangeAction(value) {
      this.$v.$reset();
      this.action = value;
    },
    async saveAndClose() {
      this.disableSaveButton = true;
      await this.touchAndWaitForValidation();

      if (this.$v.$invalid) {
        this.disableSaveButton = false;
        return;
      }

      const index =
        this.rangeIndex > -1
          ? this.rangeIndex
          : await this.addExcludedIpRange({
              serverKey: this.dhcpServerKey,
              action: this.action,
            });

      this.setExcludedRangeStartIpAddress({
        serverKey: this.dhcpServerKey,
        index: index,
        action: this.action,
        current: this.start.ipAddress.current,
        new: this.start.ipAddress.new,
      });
      this.setExcludedRangeStartIpVersion({
        serverKey: this.dhcpServerKey,
        index: index,
        action: this.action,
        current: this.start.ipVersion.current,
        new: this.start.ipVersion.new,
      });
      this.setExcludedRangeEndIpAddress({
        serverKey: this.dhcpServerKey,
        index: index,
        action: this.action,
        current: this.end.ipAddress.current,
        new: this.end.ipAddress.new,
      });
      this.setExcludedRangeEndIpVersion({
        serverKey: this.dhcpServerKey,
        index: index,
        action: this.action,
        current: this.end.ipVersion.current,
        new: this.end.ipVersion.new,
      });

      this.disableSaveButton = false;
      this.$emit("complete");
    },

    async touchAndWaitForValidation() {
      if (this.isNewValuesRequired) {
        await this.validateNewExcludedIpRangeStartIpAddress();
        await this.validateNewExcludedIpRangeEndIpAddress();
      }
      if (this.isCurrentValuesRequired) {
        await this.validateCurrentExcludedIpRangeStartIpAddress();
        await this.validateCurrentExcludedIpRangeEndIpAddress();
      }
      this.$v.$touch();
      /* TODO: Should we do something similar?
      if (process.env.MYBD_ENV === "test") {
        return new Promise((resolve) => resolve(true));
      }
      */
      let unwatch = null;
      return new Promise((resolve) => {
        unwatch = this.$watch(
          () => {
            return !this.$v.$pending;
          },
          (isNotPending) => {
            if (isNotPending) {
              if (unwatch) {
                unwatch();
              }
              resolve(!this.$v.$invalid);
            }
          },
          { immediate: true }
        );
      });
    },

    async validateCurrentExcludedIpRangeStartIpAddress() {
      if (this.currentStartIpTimeoutId) {
        clearTimeout(this.currentStartIpTimeoutId);
      }
      return new Promise((resolve) => {
        this.currentStartIpTimeoutId = setTimeout(
          async function () {
            const ipInfo = await this.getIpAddressInformation(this.start.ipAddress.current);
            this.start.ipVersion.current = ipInfo.valid ? ipInfo.ipVersion : "";
            resolve();
          }.bind(this),
          600
        );
      });
    },

    async validateNewExcludedIpRangeStartIpAddress() {
      if (this.newStartIpTimeoutId) {
        clearTimeout(this.newStartIpTimeoutId);
      }
      return new Promise((resolve) => {
        this.newStartIpTimeoutId = setTimeout(
          async function () {
            const ipInfo = await this.getIpAddressInformation(this.start.ipAddress.new);
            this.start.ipVersion.new = ipInfo.valid ? ipInfo.ipVersion : "";
            resolve();
          }.bind(this),
          600
        );
      });
    },

    async validateCurrentExcludedIpRangeEndIpAddress() {
      if (this.newEndIpTimeoutId) {
        clearTimeout(this.newEndIpTimeoutId);
      }
      return new Promise((resolve) => {
        this.newEndIpTimeoutId = setTimeout(
          async function () {
            const ipInfo = await this.getIpAddressInformation(this.end.ipAddress.current);
            this.end.ipVersion.current = ipInfo.valid ? ipInfo.ipVersion : "";
            resolve();
          }.bind(this),
          600
        );
      });
    },

    async validateNewExcludedIpRangeEndIpAddress() {
      if (this.newEndIpTimeoutId) {
        clearTimeout(this.newEndIpTimeoutId);
      }
      return new Promise((resolve) => {
        this.newEndIpTimeoutId = setTimeout(
          async function () {
            const ipInfo = await this.getIpAddressInformation(this.end.ipAddress.new);
            this.end.ipVersion.new = ipInfo.valid ? ipInfo.ipVersion : "";
            resolve();
          }.bind(this),
          600
        );
      });
    },

    async getIpAddressInformation(value) {
      let ipInfo = {};
      try {
        const response = await corpValidationService.ValidationControllerService.validate(
          this.scopeId,
          {
            type: "IPADDRESS",
            value,
          }
        );

        if (response) {
          ipInfo = {
            valid: true,
            ipVersion: response.version,
          };
        }
      } catch (e) {
        ipInfo = {
          valid: false,
        };
      }

      return ipInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";

.excluded-ip-range {
  padding-bottom: $telia-spacing-32;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
}

.column-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  flex: 4 1 0;
}

.column {
  flex: 1 1 0;
  margin-right: 0.5rem;
}

.column:last-child {
  margin: 0;
}

.column-small {
  flex: 1 1 0;
  align-self: center;
  margin: 0;
}
.component-wrapper {
  margin-top: $telia-spacing-12;
  margin-bottom: $telia-spacing-24;
}
.input-wrapper {
  max-width: 42rem;
  margin-bottom: $telia-spacing-12;
}

.column-title {
  margin: 0;
  font-weight: bold;
}

.column-title-margin {
  margin-top: 2.5rem;
}
.button-wrapper > * {
  margin-right: $telia-spacing-12;
}
.additional-text {
  color: $telia-gray-500;
}
.margin {
  &__bottom {
    &__4 {
      margin-bottom: $telia-spacing-4;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
}
</style>
