var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("telia-heading", { attrs: { tag: "h5", variant: "title-100" } }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.isWirelessAccess
                ? _vm.t(
                    "DATANET_WOULD_YOU_LIKE_TO_ADD_WIRELESS_ACCESS_FAST_TRACK"
                  )
                : _vm.t(
                    "DATANET_WOULD_YOU_LIKE_TO_ADD_TEMPORARY_ACCESS_FAST_TRACK"
                  )
            ) +
            "\n  "
        ),
      ]),
      _c(
        "telia-fieldset",
        {
          attrs: {
            name: "fast-track-options",
            caption: "fieldset for fast-track-options",
          },
        },
        [
          _c(
            "div",
            { staticClass: "chip-choice-wrapper" },
            [
              _c(
                "telia-chip-choice",
                {
                  attrs: { id: "enable-fast-track", checked: _vm.fastTrack },
                  on: {
                    change: function ($event) {
                      return _vm.handleSelection(true)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.t("DATANET_YES")) + "\n      "
                  ),
                ]
              ),
              _c(
                "telia-chip-choice",
                {
                  attrs: { id: "disable-fast-track", checked: !_vm.fastTrack },
                  on: {
                    change: function ($event) {
                      return _vm.handleSelection(false)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.t("DATANET_NO")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }