<template>
  <div>
    <div v-if="isChangeOrder" class="margin__bottom__24">
      <telia-heading
        tag="h3"
        variant="title-100"
        class="margin__bottom__12"
        v-text="t('DATANET_VPN_EDIT_DHCP_SERVER_CHOICE')"
      />

      <ActionSelection
        :selected-action="dhcpServer.action"
        :disabled-actions="disabledActions"
        @selected="handleActionSelection($event)"
      />
    </div>

    <div class="margin__bottom__24">
      <telia-p>
        <strong>{{ t("DATANET_VPN_DHCP_SERVER_NETWORK") }}</strong>
      </telia-p>
      <div class="inputs-container margin__bottom__4">
        <telia-text-input
          v-if="shouldShowCurrentNetwork(dhcpServer.key)"
          id="server-network-current"
          t-id="current-dhcp-server-network"
          class="input-container"
          :label="t('DATANET_CURRENT_VALUE')"
          :validation-state="v.network.input.current.$error ? 'Invalid' : 'Valid'"
          :value="dhcpServer.network.input.current"
          @input="setAndValidateCurrentNetwork($event.target.value)"
        />
        <telia-text-input
          v-if="shouldShowNewNetwork(dhcpServer.key)"
          t-id="new-dhcp-server-network"
          class="input-container"
          :label="t('DATANET_NEW_VALUE')"
          :validation-state="v.network.input.new.$error ? 'Invalid' : 'Valid'"
          :value="dhcpServer.network.input.new"
          @input="setAndValidateNewNetwork($event.target.value)"
        />
      </div>
      <telia-p
        variant="additional-100"
        :class="v.network.$anyError ? 'additional-text-warning' : 'additional-text'"
        v-text="t('DATANET_NETWORK_PLACEHOLDER')"
      />
      <telia-checkbox
        v-if="shouldShowNetworkAction(dhcpServer.key)"
        t-id="change-dhcp-server-network"
        :value="networkCheckboxValue"
        :checked="isNetworkCheckboxChecked"
        @input="handleNetworkActionSelection($event.target.value)"
      >
        {{ t("DATANET_VPN_DHCP_SERVER_NETWORK_UPDATE") }}
      </telia-checkbox>
    </div>

    <template v-if="shouldNotDeleteDhcpServer">
      <!-- DHCP Server excluded IP ranges -->
      <telia-p
        v-if="
          !isSiblingDhcpServer ||
          (isSiblingDhcpServer && dhcpServer.excludedIpRanges.values.length > 0)
        "
      >
        <strong>{{ t("DATANET_VPN_DHCP_SERVER_EXCLUDED_IP_RANGES") }}</strong>
      </telia-p>

      <SummaryList v-if="dhcpServer.excludedIpRanges.values.length > 0">
        <SummaryRow variant="header">
          <SummaryColumn v-if="shouldShowCurrentValues(dhcpServer.key)">
            <telia-p>{{ t("DATANET_CURRENT_VALUE") }}</telia-p>
          </SummaryColumn>
          <SummaryColumn v-if="shouldShowNewValues(dhcpServer.key)">
            <telia-p>{{ t("DATANET_NEW_VALUE") }}</telia-p>
          </SummaryColumn>
          <SummaryColumn variant="small"><telia-p>&nbsp;</telia-p> </SummaryColumn>
        </SummaryRow>
        <SummaryRow
          v-for="(range, index) in dhcpServer.excludedIpRanges.values"
          :key="`range-${index}`"
        >
          <SummaryColumn v-if="shouldShowCurrentValues(dhcpServer.key)">
            <span
              :class="
                !v.excludedIpRanges.values.$each.$iter[index].start.ipAddress.current.$pending &&
                !v.excludedIpRanges.values.$each.$iter[index].end.ipAddress.current.$pending &&
                (v.excludedIpRanges.values.$each.$iter[index].start.ipAddress.current.$invalid ||
                  v.excludedIpRanges.values.$each.$iter[index].end.ipAddress.current.$invalid)
                  ? 'additional-text-warning'
                  : ''
              "
            >
              <telia-p>
                {{
                  range.start.ipAddress.current && range.end.ipAddress.current
                    ? `${range.start.ipAddress.current} - ${range.end.ipAddress.current}`
                    : v.excludedIpRanges.values.$each.$iter[index].start.ipAddress.current
                        .$invalid ||
                      v.excludedIpRanges.values.$each.$iter[index].end.ipAddress.current.$invalid
                    ? t("DATANET_MISSING_VALUE")
                    : "-"
                }}
              </telia-p>
            </span>
          </SummaryColumn>
          <SummaryColumn v-if="shouldShowNewValues(dhcpServer.key)">
            <span
              :class="
                !v.excludedIpRanges.values.$each.$iter[index].start.ipAddress.new.$pending &&
                !v.excludedIpRanges.values.$each.$iter[index].end.ipAddress.new.$pending &&
                (v.excludedIpRanges.values.$each.$iter[index].start.ipAddress.new.$invalid ||
                  v.excludedIpRanges.values.$each.$iter[index].end.ipAddress.new.$invalid)
                  ? 'additional-text-warning'
                  : ''
              "
            >
              <telia-p>
                {{
                  range.start.ipAddress.new && range.end.ipAddress.new
                    ? `${range.start.ipAddress.new} - ${range.end.ipAddress.new}`
                    : v.excludedIpRanges.values.$each.$iter[index].start.ipAddress.new.$invalid ||
                      v.excludedIpRanges.values.$each.$iter[index].end.ipAddress.new.$invalid
                    ? t("DATANET_MISSING_VALUE")
                    : "-"
                }}
              </telia-p>
            </span>
          </SummaryColumn>
          <SummaryColumn variant="small" class="align-end">
            <telia-button
              v-if="!isSiblingDhcpServer"
              size="sm"
              variant="text"
              :right-icon="JSON.stringify({ name: 'close', size: 'sm' })"
              @click.prevent="
                removeExcludedIpRange({
                  serverKey: dhcpServer.key,
                  index,
                })
              "
            />
            <telia-button
              v-else
              size="sm"
              variant="text"
              :right-icon="JSON.stringify({ name: 'edit', size: 'sm' })"
              @click.prevent="showExcludedIpRangeModal(index)"
            />
          </SummaryColumn>
        </SummaryRow>
      </SummaryList>
      <Modal
        v-if="isExcludedIpRangeModalActive"
        modal-id="excluded-ip-range-modal"
        width="s"
        :is-open="isExcludedIpRangeModalActive"
        @closeModal="hideExcludedIpRangeModal()"
      >
        <div slot="modal-body" class="excluded-ip-range-wrapper">
          <ExcludedIpRange
            :dhcp-server-key="dhcpServer.key"
            :range-index="selectedRangeIndex"
            @complete="hideExcludedIpRangeModal()"
          />
        </div>
      </Modal>
      <div class="margin__bottom__24">
        <telia-button
          v-if="!isSiblingDhcpServer"
          t-id="add-new-excluded-ip-interval-config"
          variant="text"
          @click="showExcludedIpRangeModal()"
          :left-icon="JSON.stringify({ name: 'add', size: 'sm' })"
          :text="t('DATANET_VPN_CONFIGURE_EXCLUDED_IP_RANGE')"
        />
      </div>
      <!-- DHCP Server DNS IPs -->
      <telia-p
        v-if="
          !isSiblingDhcpServer ||
          (isSiblingDhcpServer && dhcpServer.excludedIpRanges.values.length > 0)
        "
      >
        <strong>{{ t("DATANET_VPN_DHCP_DNS_IP") }}</strong>
      </telia-p>

      <SummaryList v-if="dhcpServer.dnsIps.values.length > 0">
        <SummaryRow variant="header">
          <SummaryColumn v-if="shouldShowCurrentValues(dhcpServer.key)">
            <telia-p>{{ t("DATANET_CURRENT_VALUE") }}</telia-p>
          </SummaryColumn>
          <SummaryColumn v-if="shouldShowNewValues(dhcpServer.key)">
            <telia-p>{{ t("DATANET_NEW_VALUE") }}</telia-p>
          </SummaryColumn>
          <SummaryColumn variant="small"> &nbsp; </SummaryColumn>
        </SummaryRow>
        <SummaryRow v-for="(ip, index) in dhcpServer.dnsIps.values" :key="`range-${index}`">
          <SummaryColumn v-if="shouldShowCurrentValues(dhcpServer.key)">
            <span
              :class="
                !v.dnsIps.values.$each.$iter[index].ipAddress.current.$pending &&
                v.dnsIps.values.$each.$iter[index].ipAddress.current.$invalid
                  ? 'additional-text-warning'
                  : ''
              "
            >
              <telia-p>
                {{
                  ip.ipAddress.current
                    ? `${ip.ipAddress.current}`
                    : v.dnsIps.values.$each.$iter[index].ipAddress.current.$invalid
                    ? t("DATANET_MISSING_VALUE")
                    : "-"
                }}
              </telia-p>
            </span>
          </SummaryColumn>
          <SummaryColumn v-if="shouldShowNewValues(dhcpServer.key)">
            <span
              :class="
                !v.dnsIps.values.$each.$iter[index].ipAddress.new.$pending &&
                v.dnsIps.values.$each.$iter[index].ipAddress.new.$invalid
                  ? 'additional-text-warning'
                  : ''
              "
            >
              <telia-p>
                {{
                  ip.ipAddress.new
                    ? `${ip.ipAddress.new}`
                    : v.dnsIps.values.$each.$iter[index].ipAddress.new.$invalid
                    ? t("DATANET_MISSING_VALUE")
                    : "-"
                }}
              </telia-p>
            </span>
          </SummaryColumn>
          <SummaryColumn variant="small" class="align-end">
            <telia-button
              v-if="!isSiblingDhcpServer"
              size="sm"
              variant="text"
              :right-icon="JSON.stringify({ name: 'close', size: 'sm' })"
              @click.prevent="
                removeDnsIp({
                  serverKey: dhcpServer.key,
                  index,
                })
              "
            />
            <telia-button
              v-else
              size="sm"
              variant="text"
              :right-icon="JSON.stringify({ name: 'edit', size: 'sm' })"
              @click.prevent="showDnsIpModal(index)"
            />
          </SummaryColumn>
        </SummaryRow>
      </SummaryList>
      <Modal
        modal-id="dns-ip-modal"
        width="s"
        :is-open="isDnsIpModalActive"
        @closeModal="hideDnsIpModal()"
      >
        <div slot="modal-body" class="excluded-ip-range-wrapper">
          <DnsIp
            :dhcp-server-key="dhcpServer.key"
            :dns-index="selectedDnsIndex"
            @complete="hideDnsIpModal()"
          />
        </div>
      </Modal>
      <div class="margin__bottom__24">
        <telia-button
          v-if="!isSiblingDhcpServer"
          t-id="add-new-dns-ip-config"
          variant="text"
          :left-icon="JSON.stringify({ name: 'add', size: 'sm' })"
          :text="t('DATANET_VPN_CONFIGURE_DNS_IP')"
          @click="showDnsIpModal()"
        />
      </div>
    </template>
  </div>
</template>

<script>
import DnsIp from "./DnsIp";
import ExcludedIpRange from "./ExcludedIpRange";
import SummaryColumn from "../summary/SummaryColumn";
import SummaryList from "../summary/SummaryList";
import SummaryRow from "../summary/SummaryRow";
import Modal from "../Modal.vue";
import { corpValidationService } from "@telia/b2b-rest-client";
import ActionSelection from "../ActionSelection";
import { mapState, mapActions, mapGetters } from "vuex";
import { Actions } from "../../constants";
import { translateMixin } from "../../locale";

export default {
  name: "DhcpServer",
  mixins: [translateMixin],
  components: {
    DnsIp,
    ExcludedIpRange,
    SummaryColumn,
    SummaryList,
    SummaryRow,
    ActionSelection,
    Modal,
  },

  props: {
    dhcpServer: {
      type: Object,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedRangeIndex: -1,
      selectedDnsIndex: -1,
      currentNetworkTimeoutId: null,
      newNetworkTimeoutId: null,
      isDnsIpModalActive: false,
      isExcludedIpRangeModalActive: false,
    };
  },

  computed: {
    ...mapState({
      scopeId: (state) => state.user.scopeId,
    }),
    ...mapGetters(["isChangeOrder"]),
    ...mapGetters("vpn", ["getVpn", "isSecondaryAccessVpn"]),
    ...mapGetters("dhcpServer", [
      "isDhcpServerActionEditable",
      "shouldShowCurrentValues",
      "shouldShowNewValues",
      "shouldShowCurrentNetwork",
      "shouldShowNewNetwork",
      "shouldShowNetworkAction",
    ]),

    disabledActions() {
      const { action } = this.vpn;

      if (action === Actions.ADD) {
        return [Actions.UPDATE, Actions.DELETE];
      }

      if (!this.isDhcpServerActionEditable(this.dhcpServer.vpn)) {
        return [Actions.UPDATE, Actions.UPDATE, Actions.DELETE].filter(
          (action) => action !== this.dhcpServer.action
        );
      }

      return [];
    },

    vpn() {
      return this.getVpn(this.dhcpServer.vpn);
    },

    isSiblingDhcpServer() {
      return this.isSecondaryAccessVpn(this.dhcpServer.vpn);
    },

    isNetworkCheckboxChecked() {
      return this.networkCheckboxValue === Actions.NONE;
    },

    shouldNotDeleteDhcpServer() {
      return this.dhcpServer.action !== Actions.DELETE;
    },

    networkCheckboxValue() {
      return this.dhcpServer.network.action === Actions.UPDATE ? Actions.NONE : Actions.UPDATE;
    },
  },

  methods: {
    ...mapActions("dhcpServer", [
      "setServerAction",
      "setNetworkAction",
      "setNetworkInput",
      "setNetworkIpAddress",
      "setNetworkIpPrefix",
      "setNetworkIpVersion",
      "removeExcludedIpRange",
      "removeDnsIp",
    ]),

    handleActionSelection(action) {
      this.setServerAction({ dhcpServerKey: this.dhcpServer.key, action });
      this.v.$reset();
    },

    handleNetworkActionSelection(action) {
      this.setNetworkAction({
        dhcpServerKey: this.dhcpServer.key,
        action,
      });
      this.v.network.$reset();
    },

    showExcludedIpRangeModal(index = -1) {
      this.selectedRangeIndex = index;
      this.isExcludedIpRangeModalActive = true;
    },

    hideExcludedIpRangeModal() {
      this.selectedRangeIndex = -1;
      this.isExcludedIpRangeModalActive = false;
    },

    showDnsIpModal(index = -1) {
      this.selectedDnsIndex = index;
      this.isDnsIpModalActive = true;
    },

    hideDnsIpModal() {
      this.selectedDnsIndex = -1;
      this.isDnsIpModalActive = false;
    },

    removeDnsIpAddress(index) {
      this.removeDhcpServerDnsIp({
        serverKey: this.dhcpServer.key,
        index: index,
      });
    },

    async setAndValidateCurrentNetwork(value) {
      this.setNetworkInput({
        serverKey: this.dhcpServer.key,
        current: value,
      });

      if (this.currentNetworkTimeoutId) {
        clearTimeout(this.currentNetworkTimeoutId);
      }

      this.currentNetworkTimeoutId = setTimeout(
        async function () {
          let networkInfo;
          try {
            networkInfo = await this.getNetworkInformation(value);
          } catch (e) {
            // TODO
          }

          this.setNetworkIpVersion({
            serverKey: this.dhcpServer.key,
            current: networkInfo && networkInfo.valid ? networkInfo.ipVersion : "",
          });

          this.setNetworkIpAddress({
            serverKey: this.dhcpServer.key,
            current: networkInfo && networkInfo.valid ? networkInfo.network : "",
          });

          this.setNetworkIpPrefix({
            serverKey: this.dhcpServer.key,
            current: networkInfo && networkInfo.valid ? networkInfo.prefix : "",
          });
        }.bind(this),
        400
      );
    },

    async setAndValidateNewNetwork(value) {
      this.setNetworkInput({
        serverKey: this.dhcpServer.key,
        new: value,
      });

      if (this.newNetworkTimeoutId) {
        clearTimeout(this.newNetworkTimeoutId);
      }

      this.newNetworkTimeoutId = setTimeout(
        async function () {
          let networkInfo;
          try {
            networkInfo = await this.getNetworkInformation(value);
          } catch (e) {
            // TODO
          }

          this.setNetworkIpVersion({
            serverKey: this.dhcpServer.key,
            new: networkInfo && networkInfo.valid ? networkInfo.ipVersion : "",
          });

          this.setNetworkIpAddress({
            serverKey: this.dhcpServer.key,
            new: networkInfo && networkInfo.valid ? networkInfo.network : "",
          });

          this.setNetworkIpPrefix({
            serverKey: this.dhcpServer.key,
            new: networkInfo && networkInfo.valid ? networkInfo.prefix : "",
          });
        }.bind(this),
        400
      );
    },

    async getNetworkInformation(value) {
      let networkInfo = {};
      try {
        const response = await corpValidationService.ValidationControllerService.validate(
          this.scopeId,
          {
            type: "NETWORK",
            value: value,
          }
        );

        if (response) {
          const responseData = await response;
          networkInfo = {
            valid: true,
            ipVersion: responseData.ipVersion,
            network: responseData.network,
            prefix: responseData.prefix,
          };
        }
      } catch (e) {
        networkInfo = {
          valid: false,
        };
      }
      return networkInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";

.inputs-container {
  display: flex;
}

.input-container {
  width: 100%;
  max-width: 32rem;
  margin-left: $telia-spacing-12;
}

.input-container:first-child {
  margin-left: 0;
}

.align-end {
  align-items: flex-end;
}

.summary-action {
  margin-top: 0.3rem;
  padding-right: 0.8rem;
}
.assistive-text {
  font-size: 13px;
}

.margin {
  &__bottom {
    &__4 {
      margin-bottom: $telia-spacing-4;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
}

.additional-text {
  color: $telia-gray-500;
}

.additional-text-warning {
  color: $telia-red-500;
}
</style>
