<template>
  <div name="contact">
    <div class="tooltip-wrapper">
      <telia-heading tag="h5" variant="title-100" class="margin-bottom">
        {{ t("DATANET_" + contactId.toUpperCase() + "_CONTACT") }}
      </telia-heading>
      <telia-icon
        class="tooltip-icon"
        name="question"
        size="sm"
        @click="handleTooltip(contactId)"
      />
    </div>
    <div class="input-group">
      <div class="input-wrapper">
        <telia-text-input
          :id="`${contactId}-contact-first-name`"
          :t-id="`${contactId}-contact-first-name`"
          :label="t('DATANET_FIRST_NAME')"
          :value="firstName"
          @input="firstName = $event.target.value"
        />
        <ValidationError v-if="$v.firstName.$error">
          {{ t("DATANET_FIRST_NAME_REQUIRED") }}
        </ValidationError>
      </div>
      <div class="input-wrapper">
        <telia-text-input
          :id="`${contactId}-contact-last-name`"
          :t-id="`${contactId}-contact-last-name`"
          :label="t('DATANET_LAST_NAME')"
          :value="lastName"
          @input="lastName = $event.target.value"
        />
        <ValidationError v-if="$v.lastName.$error">
          {{ t("DATANET_LAST_NAME_REQUIRED") }}
        </ValidationError>
      </div>
    </div>
    <div class="input-group">
      <div class="input-wrapper">
        <telia-text-input
          :id="`${contactId}-contact-email-address`"
          :t-id="`${contactId}-contact-email-address`"
          type="email"
          :label="t('DATANET_EMAIL')"
          :assistive-text="t('DATANET_EMAIL_PLACEHOLDER')"
          :value="emailAddress"
          @input="emailAddress = $event.target.value"
        />
        <ValidationError v-if="$v.emailAddress.$error">
          {{
            $v.emailAddress.required
              ? t("DATANET_INVALID_EMAIL_ADDRESS_FORMAT")
              : t("DATANET_EMAIL_ADDRESS_REQUIRED")
          }}
        </ValidationError>
      </div>
      <div class="input-wrapper">
        <telia-text-input
          :id="`${contactId}-contact-phone-number`"
          :t-id="`${contactId}-contact-phone-number`"
          type="tel"
          :label="t('DATANET_PHONE_NUMBER')"
          :assistive-text="t('DATANET_PHONE_NUMBER_PLACEHOLDER')"
          :value="phoneNumber"
          @input="phoneNumber = $event.target.value"
        />
        <ValidationError v-if="$v.phoneNumber.$error">
          {{
            $v.phoneNumber.required
              ? t("DATANET_INVALID_PHONE_NUMBER_FORMAT")
              : t("DATANET_PHONE_NUMBER_REQUIRED")
          }}
        </ValidationError>
      </div>
    </div>
  </div>
</template>

<script>
import { corpValidationService } from "@telia/b2b-rest-client";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { translateMixin } from "../../locale";
import { mapState } from "vuex";
import ValidationError from "../ValidationError.vue";

export default {
  name: "Contact",
  mixins: [translateMixin, validationMixin],
  components: {
    ValidationError,
  },
  props: {
    contactId: {
      type: String,
      required: true,
    },
    activeTooltip: {
      type: String,
    },
  },

  computed: {
    ...mapState({
      scopeId: (state) => state.user.scopeId,
    }),
    contact() {
      return this.$store.getters["contactPersons/getContact"](this.contactId);
    },

    firstName: {
      get() {
        return this.contact.firstName;
      },
      set(value) {
        this.$store.dispatch("contactPersons/setFirstName", {
          id: this.contactId,
          firstName: value,
        });
      },
    },

    lastName: {
      get() {
        return this.contact.lastName;
      },
      set(value) {
        this.$store.dispatch("contactPersons/setLastName", {
          id: this.contactId,
          lastName: value,
        });
      },
    },

    emailAddress: {
      get() {
        return this.contact.emailAddress;
      },
      set(value) {
        this.$store.dispatch("contactPersons/setEmailAddress", {
          id: this.contactId,
          emailAddress: value,
        });
      },
    },

    phoneNumber: {
      get() {
        return this.contact.phoneNumber;
      },
      set(value) {
        this.$store.dispatch("contactPersons/setPhoneNumber", {
          id: this.contactId,
          phoneNumber: value,
        });
      },
    },
  },

  validations: {
    firstName: {
      required,
    },

    lastName: {
      required,
    },
    emailAddress: {
      required,
      isValidEmail: async function (value) {
        if (!value) {
          return true;
        }

        try {
          const response = await corpValidationService.ValidationControllerService.validate(
            this.scopeId,
            {
              type: "EMAIL",
              value,
            }
          );
          return !!response;
        } catch (e) {
          return false;
        }
      },
    },
    phoneNumber: {
      required,
      isValidPhoneNumber: async function (value) {
        if (!value) {
          return true;
        }

        try {
          const response = await corpValidationService.ValidationControllerService.validate(
            this.scopeId,
            {
              type: "PHONENUMBER",
              value,
            }
          );
          return !!response;
        } catch (e) {
          return false;
        }
      },
    },
  },

  methods: {
    async validate() {
      this.$v.$touch();

      // if (process.env.MYBD_ENV === "test") {
      //   return new Promise((resolve) => resolve(true));
      // }
      let unwatch = null;
      await new Promise((resolve) => {
        unwatch = this.$watch(
          () => !this.$v.$pending,
          (isNotPending) => {
            if (isNotPending) {
              if (unwatch) {
                unwatch();
              }
              resolve(!this.$v.$invalid);
            }
          },
          { immediate: true }
        );
      });

      return !this.$v.$invalid;
    },
    handleTooltip(value) {
      this.$emit("activeTooltip", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";

.tooltip-wrapper {
  display: flex;
}

.tooltip-icon {
  position: relative;
  top: 0.5rem;
  margin-left: $telia-spacing-12;
}

.tooltip-icon:hover {
  color: $telia-purple-500;
  cursor: pointer;
}

.input-group {
  display: flex;
  flex-wrap: wrap;
}

.input-group > :last-child {
  margin-right: 0;
}

.input-wrapper {
  width: 100%;
  max-width: 42rem;
  margin: 0 $telia-spacing-24 $telia-spacing-24 0;
}

.input-wrapper p {
  margin-bottom: 0 !important;
}

.margin-bottom {
  margin-bottom: $telia-spacing-12;
}
</style>
