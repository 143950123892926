var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.activeTooltip === "bandwidth-distribution"
        ? _c(
            "InformationBox",
            {
              on: {
                close: function ($event) {
                  return _vm.handleTooltip(null)
                },
              },
            },
            [_c("BandwidthAllocationTooltip")],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "tooltip-wrapper" },
        [
          _c("telia-heading", { attrs: { tag: "h4", variant: "title-100" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.t("DATANET_VPN_SELECT_BANDWIDTH_DISTRIBUTION")) +
                "\n    "
            ),
          ]),
          _c("telia-icon", {
            staticClass: "tooltip-icon",
            attrs: { name: "question", size: "sm" },
            on: {
              click: function ($event) {
                return _vm.handleTooltip("bandwidth-distribution")
              },
            },
          }),
        ],
        1
      ),
      _c("telia-fieldset", [
        _c(
          "div",
          { staticClass: "chip-choice-wrapper" },
          _vm._l(_vm.bandwidthDistributionOptions, function (option, index) {
            return _c(
              "telia-chip-choice",
              {
                key: index,
                attrs: {
                  value: option.value,
                  checked: _vm.bandwidthDistribution === option.value,
                  disabled: option.disabled,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleBandwidthDistributionInput(
                      $event.target.value
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.t("DATANET_VPN_BANDWIDTH_" + option.value)) +
                    "\n      "
                ),
              ]
            )
          }),
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }