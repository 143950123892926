<template>
  <div>
    <telia-p>{{ t("DATANET_AVAILABILITY_LABEL") }}</telia-p>

    <div class="notification-wrapper">
      <telia-notification
        heading-tag="h4"
        variant="inline"
        status="information"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("A11Y_STATUS_INFORMATION") }}
          </telia-visually-hidden>
          {{ t("DATANET_AVAILABILITY_SERVICE_TITLE") }}
        </span>
        <span slot="content">
          <telia-p>
            {{ t("DATANET_AVAILABILITY_SERVICE_INFO") }}
          </telia-p>
        </span>
      </telia-notification>
    </div>

    <Product
      class="product-wrapper"
      v-for="product in products"
      :key="product.id"
      :product="product"
      :selectable="true"
      @product-selected="handleProductSelected($event)"
    />
  </div>
</template>

<script>
import Product from "./Product";
import { translateMixin } from "../locale";
import { Bearers } from "../constants";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ProductOverview",
  mixins: [translateMixin],
  components: {
    Product,
  },

  computed: {
    ...mapState({
      hasSelectedAccess: (state) => state.products.bearer.value,
    }),
    ...mapGetters({
      products: "products/getPreCheckAccesses",
      isFiberAvailable: "products/isFiberAvailable",
      isFttxAvailable: "products/isFttxAvailable",
    }),
  },

  created() {
    if (this.hasSelectedAccess) {
      this.$emit("step-complete");
    }
  },

  methods: {
    handleProductSelected(productId) {
      if (productId === Bearers.FIBER) {
        if (this.isFiberAvailable) {
          this.$store.dispatch("products/setSelectedAccess", Bearers.FIBER);
        } else if (this.isFttxAvailable) {
          this.$store.dispatch("products/setSelectedAccess", Bearers.FTTX);
        }
      } else {
        this.$store.dispatch("products/setSelectedAccess", productId);
      }

      this.$emit("step-complete");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.notification-wrapper {
  max-width: 80rem;
  margin-top: $telia-spacing-24;
}

.product-wrapper {
  padding: $telia-spacing-24 0;
}
</style>
