<template>
  <div>
    <InformationBox v-if="activeTooltip === 'vpn-interface'" @close="handleTooltip(null)">
      <VpnInterfaceTooltip />
    </InformationBox>
    <div class="tooltip-wrapper">
      <telia-heading tag="h4" variant="title-100">
        {{ t("DATANET_VPN_SELECT_INTERFACE") }}
      </telia-heading>
      <telia-icon
        class="tooltip-icon"
        name="question"
        size="sm"
        @click="handleTooltip('vpn-interface')"
      />
    </div>
    <telia-fieldset>
      <div class="chip-choice-wrapper">
        <telia-chip-choice
          v-for="(option, index) in getVpnInterfaceOptions"
          :key="index"
          :value="option.value"
          :checked="vpnInterface === option.value"
          :disabled="option.disabled"
          @click="handleVpnInterfaceSelection($event.target.value)"
        >
          {{ option.label }}
        </telia-chip-choice>
      </div>
    </telia-fieldset>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { translateMixin } from "../../locale";
import { VpnInterfaces } from "../../constants";
import InformationBox from "../InformationBox.vue";
import VpnInterfaceTooltip from "../tooltip-information/vpn-settings/VpnInterfaceTooltip.vue";

export default {
  name: "VpnInterface",
  mixins: [translateMixin],
  props: {
    accessKey: {
      type: String,
      required: true,
    },
    activeTooltip: {
      type: String,
    },
  },
  components: {
    InformationBox,
    VpnInterfaceTooltip,
  },
  computed: {
    ...mapState("vpn", {
      vpnInterface: (state) => state.vpnInterface,
    }),
    ...mapGetters("vpn", ["getVpnInterfaceOptions", "getAllVpns"]),
    ...mapGetters("lans", ["getAllLans"]),
  },
  methods: {
    ...mapActions("vpn", ["setVpnInterface"]),
    handleVpnInterfaceSelection(value) {
      if (value === VpnInterfaces.PHYSICAL) {
        const totalVpns = this.getAllVpns.length;
        const totalLans = this.getAllLans.length;
        if (totalLans > totalVpns) {
          if (!confirm(this.t("DATANET_VPN_CHANGE_INTERFACE_INFO"))) {
            return;
          }
        }
      }
      this.setVpnInterface(value);
    },
    handleTooltip(value) {
      this.$emit("activeTooltip", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.chip-choice-wrapper > * {
  margin-right: $telia-spacing-12;
}
.tooltip-wrapper {
  display: flex;
}
.tooltip-icon {
  position: relative;
  top: 0.5rem;
  margin-left: $telia-spacing-12;
}
.tooltip-icon:hover {
  color: $telia-purple-500;
  cursor: pointer;
}
</style>
