import network from "./network.js";
import defaultChangeableValue from "../../defaultChangeableValue.js";
import { Actions } from "../../../constants";

const lan = ({
  action = Actions.ADD,
  key = "",
  siblingKey = "",
  vpn = "",
  id = "",
  isDefault = false,
  vlanId = { lanKey: key, ...defaultChangeableValue({ action: Actions.ADD }) },
  identifyingNetwork = network({ action: Actions.NONE, lanKey: key }),
  networks = [],
  dhcpRelays = [],
} = {}) => ({
  action,
  key,
  siblingKey,
  id,
  isDefault,
  vlanId,
  identifyingNetwork,
  networks,
  vpn,
  dhcpRelays,
});

export default lan;
