var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { name: "contact" } }, [
    _c(
      "div",
      { staticClass: "tooltip-wrapper" },
      [
        _c(
          "telia-heading",
          {
            staticClass: "margin-bottom",
            attrs: { tag: "h5", variant: "title-100" },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.t("DATANET_" + _vm.contactId.toUpperCase() + "_CONTACT")
                ) +
                "\n    "
            ),
          ]
        ),
        _c("telia-icon", {
          staticClass: "tooltip-icon",
          attrs: { name: "question", size: "sm" },
          on: {
            click: function ($event) {
              return _vm.handleTooltip(_vm.contactId)
            },
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "input-group" }, [
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("telia-text-input", {
            attrs: {
              id: _vm.contactId + "-contact-first-name",
              "t-id": _vm.contactId + "-contact-first-name",
              label: _vm.t("DATANET_FIRST_NAME"),
              value: _vm.firstName,
            },
            on: {
              input: function ($event) {
                _vm.firstName = $event.target.value
              },
            },
          }),
          _vm.$v.firstName.$error
            ? _c("ValidationError", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.t("DATANET_FIRST_NAME_REQUIRED")) +
                    "\n      "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("telia-text-input", {
            attrs: {
              id: _vm.contactId + "-contact-last-name",
              "t-id": _vm.contactId + "-contact-last-name",
              label: _vm.t("DATANET_LAST_NAME"),
              value: _vm.lastName,
            },
            on: {
              input: function ($event) {
                _vm.lastName = $event.target.value
              },
            },
          }),
          _vm.$v.lastName.$error
            ? _c("ValidationError", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.t("DATANET_LAST_NAME_REQUIRED")) +
                    "\n      "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "input-group" }, [
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("telia-text-input", {
            attrs: {
              id: _vm.contactId + "-contact-email-address",
              "t-id": _vm.contactId + "-contact-email-address",
              type: "email",
              label: _vm.t("DATANET_EMAIL"),
              "assistive-text": _vm.t("DATANET_EMAIL_PLACEHOLDER"),
              value: _vm.emailAddress,
            },
            on: {
              input: function ($event) {
                _vm.emailAddress = $event.target.value
              },
            },
          }),
          _vm.$v.emailAddress.$error
            ? _c("ValidationError", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$v.emailAddress.required
                        ? _vm.t("DATANET_INVALID_EMAIL_ADDRESS_FORMAT")
                        : _vm.t("DATANET_EMAIL_ADDRESS_REQUIRED")
                    ) +
                    "\n      "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("telia-text-input", {
            attrs: {
              id: _vm.contactId + "-contact-phone-number",
              "t-id": _vm.contactId + "-contact-phone-number",
              type: "tel",
              label: _vm.t("DATANET_PHONE_NUMBER"),
              "assistive-text": _vm.t("DATANET_PHONE_NUMBER_PLACEHOLDER"),
              value: _vm.phoneNumber,
            },
            on: {
              input: function ($event) {
                _vm.phoneNumber = $event.target.value
              },
            },
          }),
          _vm.$v.phoneNumber.$error
            ? _c("ValidationError", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$v.phoneNumber.required
                        ? _vm.t("DATANET_INVALID_PHONE_NUMBER_FORMAT")
                        : _vm.t("DATANET_PHONE_NUMBER_REQUIRED")
                    ) +
                    "\n      "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }