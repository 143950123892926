<template>
  <div>
    <div class="margin__bottom__12">
      <telia-heading tag="h3" variant="title-200">
        {{ t("DATANET_EXTENDED_AVAILABILITY") }}
      </telia-heading>
      <telia-p>
        {{ t("DATANET_EXTENDED_AVAILABILITY_DESCRIPTION") }}
      </telia-p>
    </div>
    <div v-if="!access.enable">
      <telia-button
        id="add-secondary-access"
        t-id="add-secondary-access"
        type="button"
        variant="secondary"
        size="sm"
        :left-icon="JSON.stringify({ name: 'plus', size: 'sm' })"
        :text="t('DATANET_ADD_EXTENDED_AVAILABILITY')"
        @click="showModal()"
      />
    </div>

    <div v-else>
      <Summary class="margin__bottom__24" :edit-label="t('DATANET_EDIT')" @edit="showModal()">
        <telia-p>
          <strong>{{ t("DATANET_SECONDARY_ACCESS") }}</strong>
          <br />
          <div v-if="secondaryAddress.streetName">
            {{
              `${secondaryAddress.streetName} ${secondaryAddress.streetNumber}${
                secondaryAddress.entrance || ""
              }`
            }}
            <br />
            {{ `${secondaryAddress.postalCode} ${secondaryAddress.city}` }}
            <br />
            <br />
          </div>
          <div v-else-if="secondaryAddress.x">
            {{ `X: ${secondaryAddress.x}` }}
            <br />
            {{ `Y: ${secondaryAddress.y}` }}
            <br />
            <br />
          </div>
          {{ backupSummaryText }}<br />
          {{ bearerSummaryText }}<br />
          <div v-if="isFttxBearer">
            {{ apartmentSummaryText }}<br />
            {{ installationRoomSummaryText }}<br />
          </div>
          <div v-if="isWirelessBearer">
            {{ teliaInstallationSummaryText }}<br />
            {{ antennaPackageSummaryText }}<br />
            {{ antennaCableSummaryText }}<br />
            {{ antennaInstallationSummaryText }}<br />
          </div>
          {{ bandwidthSummaryText }}<br />
          {{ slaSummaryText }}<br />
          {{ qosSummaryText }}<br />
          {{ statBasSummaryText }}<br />
        </telia-p>
      </Summary>

      <telia-button
        v-if="!shouldDisableExtendedAvailability"
        id="delete-secondary-access"
        type="button"
        variant="secondary"
        size="sm"
        :left-icon="JSON.stringify({ name: 'close', size: 'sm' })"
        :text="t('DATANET_REMOVE_EXTENDED_AVAILABILITY')"
        @click="disableAccess(Accesses.SECONDARY)"
      />
    </div>
    <Modal
      v-if="modalIsActive"
      modal-id="secondary-access-settings-modal"
      width="m"
      :is-open="modalIsActive"
      @closeModal="hideModal()"
    >
      <div slot="modal-body">
        <SecondaryAccessSettings @complete="hideModal()" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { translateMixin } from "../../locale";
import Summary from "../Summary";
import SecondaryAccessSettings from "./SecondaryAccessSettings";
import Modal from "../Modal.vue";
import { Accesses, Bearers } from "../../constants";

export default {
  name: "ExtendedAvailability",
  mixins: [translateMixin],
  components: {
    Summary,
    SecondaryAccessSettings,
    Modal,
  },

  data() {
    return {
      Accesses,
      modalIsActive: false,
    };
  },

  computed: {
    ...mapState("addresses", {
      installationRoom: (state) => state.installation.installationRoom,
      secondaryAddress: (state) => state.secondary,
    }),

    ...mapState("apartments", {
      apartment: (state) => state.apartment,
    }),

    ...mapState("products", {
      secondaryAccessBearer: (state) => state.secondaryBearer,
    }),

    ...mapGetters("access", ["getAccess", "shouldDisableExtendedAvailability"]),

    ...mapGetters("products", ["getBandwidthText"]),

    access() {
      return this.getAccess(Accesses.SECONDARY);
    },

    isWirelessBearer() {
      return this.secondaryAccessBearer === Bearers.WIRELESS;
    },

    isFttxBearer() {
      return this.secondaryAccessBearer === Bearers.FTTX;
    },

    backupSummaryText() {
      return this.t("DATANET_SUMMARY_BACKUP", {
        backup: this.t(`DATANET_${this.access.backup}`),
      });
    },

    bearerSummaryText() {
      return this.t("DATANET_SUMMARY_BEARER", {
        bearer: this.t(`DATANET_${this.secondaryAccessBearer}`),
      });
    },

    apartmentSummaryText() {
      return this.t("DATANET_SUMMARY_APARTMENT_NUMBER", {
        number: this.apartment,
      });
    },

    teliaInstallationSummaryText() {
      return this.t("DATANET_SUMMARY_HARDWARE_INSTALLATION", {
        response: this.access.options.wirelessInstallation
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    antennaPackageSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_PACKAGE", {
        package: this.t(`DATANET_${this.access.options.antenna.package}`),
      });
    },

    antennaCableSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_CABLE", {
        response: this.access.options.antenna.cable ? this.t("DATANET_YES") : this.t("DATANET_NO"),
      });
    },

    antennaInstallationSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_INSTALLATION", {
        response: this.access.options.antenna.installation
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    installationRoomSummaryText() {
      return this.t("DATANET_SUMMARY_INSTALLATION_INFO", {
        info: this.installationRoom,
      });
    },

    bandwidthSummaryText() {
      return this.t("DATANET_SUMMARY_BANDWIDTH", {
        bandwidth: this.getBandwidthText(this.secondaryAccessBearer, this.access.bandwidth.new),
      });
    },

    slaSummaryText() {
      return this.t("DATANET_SUMMARY_SLA", { sla: this.access.sla.new });
    },

    qosSummaryText() {
      return this.t("DATANET_SUMMARY_QOS", {
        qos: this.t(`DATANET_${this.access.qos.new}_DESCRIPTION`),
      });
    },

    statBasSummaryText() {
      return this.t("DATANET_SUMMARY_STAT_BAS", {
        response: this.access.options.trafficStatistics.new
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },
  },

  methods: {
    ...mapActions("access", ["disableAccess"]),

    showModal() {
      this.modalIsActive = true;
    },

    hideModal() {
      this.modalIsActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.input-wrapper {
  max-width: 42rem;
}

.margin {
  &__bottom {
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
}
</style>
