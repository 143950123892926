<template>
  <div :class="`summary-column summary-column--${variant}`">
    <slot>&nbsp;</slot>
  </div>
</template>

<script>
export default {
  name: "SummaryColumn",

  props: {
    variant: {
      type: String,
      default: "default",
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-column {
  display: flex;
  flex-direction: column;
}

.summary-column:last-child {
  margin: 0;
}

.summary-column--default {
  flex: 4 1 0;
  align-self: center;
  margin-left: 2rem;
}

.summary-column--align-top {
  flex: 4 1 0;
  align-self: flex-start;
  margin-left: 2rem;
}

.summary-column--small {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 0;
  margin: 0;
}
</style>
