var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("telia-select", {
            attrs: {
              "t-id": "invoice-account",
              label: _vm.t("DATANET_SELECT_INVOICE_ACCOUNT"),
              options: JSON.stringify(_vm.billingAccountOptions),
              value: _vm.billingAccountNumber,
            },
            on: {
              change: function ($event) {
                return _vm.handleInvoiceAccount($event.target.value)
              },
            },
          }),
        ],
        1
      ),
      _vm.$v.billingAccountNumber.$error
        ? _c("ValidationError", [
            _vm._v(
              "\n    " +
                _vm._s(_vm.t("DATANET_INVOICE_ACCOUNT_REQUIRED")) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "input-wrapper margin-top" },
        [
          _c(
            "telia-checkbox",
            {
              attrs: {
                "t-id": "handled-by-telia-finance",
                name: "handled-by-telia-finance",
                value: !_vm.isHandledByTeliaFinance,
                checked: _vm.isHandledByTeliaFinance,
              },
              on: {
                change: function ($event) {
                  return _vm.isHandledByTeliaFinanceHandler(
                    $event.target.checked
                  )
                },
              },
            },
            [
              _c("telia-p", [
                _vm._v(
                  _vm._s(_vm.t("DATANET_INVOICE_IS_HANDLED_BY_TELIA_FINANCE"))
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "input-wrapper margin-top" },
        [
          _vm.isHandledByTeliaFinance
            ? _c("telia-textarea", {
                attrs: {
                  "t-id": "telia-finance-info",
                  label: _vm.t("DATANET_TELIA_FINANCE_INFO"),
                  value: _vm.teliaFinanceInfo,
                  additional: _vm.t("DATANET_TELIA_FINANCE_INFO_PLACEHOLDER"),
                  maxlength: "350",
                },
                on: {
                  input: function ($event) {
                    _vm.teliaFinanceInfo = $event.target.value
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.$v.teliaFinanceInfo.$error
        ? _c("ValidationError", [
            _vm._v(
              "\n    " +
                _vm._s(_vm.t("DATANET_TELIA_FINANCE_INFO_ERROR")) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "margin-top info-wrapper" },
        [
          _c("telia-p", [
            _c("strong", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.t("DATANET_AGREEMENT_PERIOD")) +
                  "\n      "
              ),
            ]),
          ]),
          _c("telia-p", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.t("DATANET_AGREEMENT_PERIOD_INFO")) +
                "\n    "
            ),
          ]),
        ],
        1
      ),
      _vm.billingAccount
        ? _c(
            "Summary",
            {
              staticClass: "margin-top input-wrapper",
              attrs: { editable: false },
            },
            [
              _c("telia-p", [
                _c("strong", [
                  _vm._v(_vm._s(_vm.t("DATANET_INVOICE_ACCOUNT"))),
                ]),
              ]),
              _c("telia-p", [
                _vm._v(
                  "\n      " + _vm._s(_vm.billingAccountNumber) + "\n    "
                ),
              ]),
              _vm.billingAccount.billingAddress.street
                ? _c(
                    "div",
                    [
                      _c("telia-p", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.t("DATANET_INVOICE_ACCOUNT_ADDRESS"))
                          ),
                        ]),
                      ]),
                      _vm.billingAccount.billingAddress.careOf
                        ? _c("telia-p", [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  "c/o " +
                                    _vm.billingAccount.billingAddress.careOf
                                ) +
                                "\n      "
                            ),
                          ])
                        : _vm._e(),
                      _vm.billingAccount.billingAddress.street
                        ? _c("telia-p", [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.billingAccount.billingAddress.street
                                ) +
                                "\n      "
                            ),
                          ])
                        : _vm._e(),
                      _vm.billingAccount.billingAddress.zipCode
                        ? _c("telia-p", [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.billingAccount.billingAddress.zipCode +
                                    " " +
                                    _vm.billingAccount.billingAddress.city
                                ) +
                                "\n      "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.billingAccount.billingMethodText
                ? _c("telia-p", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.t("DATANET_INVOICE_ACCOUNT_METHOD"))),
                    ]),
                  ])
                : _vm._e(),
              _vm.billingAccount.billingMethodText
                ? _c("telia-p", [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.billingAccount.billingMethodText) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm.billingAccount.billingEmail
                ? _c("telia-p", [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.billingAccount.billingEmail) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }