var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "telia-heading",
        {
          staticClass: "margin__bottom__12",
          attrs: { tag: "h5", variant: "title-100" },
        },
        [_vm._v("\n    " + _vm._s(_vm.t("DATANET_PRIMARY_ACCESS")) + "\n  ")]
      ),
      _c(
        "div",
        { staticClass: "information-box-wrapper" },
        [
          _vm.activeTooltip
            ? _c(
                "InformationBox",
                {
                  on: {
                    close: function ($event) {
                      return _vm.setActiveTooltip(null)
                    },
                  },
                },
                [
                  _c("OnPremisesTooltip", {
                    attrs: { "should-show-tooltip-by-id": _vm.activeTooltip },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "input-with-tooltip" },
        [
          _c(
            "div",
            { staticClass: "input-wrapper" },
            [
              _c("telia-select", {
                attrs: {
                  id: "installation-contact",
                  label: _vm.t("DATANET_CONTACT_PERSON"),
                  value: _vm.contact,
                  options: JSON.stringify(_vm.contactOptions),
                },
                on: {
                  change: function ($event) {
                    return _vm.handleContactSelection($event.target.value)
                  },
                },
              }),
              _vm.$v.contact.emailAddress.$error
                ? _c("ValidationError", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.t("DATANET_CONTACT_PERSON_REQUIRED")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("telia-icon", {
            staticClass: "tooltip-icon-inputs",
            attrs: { name: "question", size: "sm" },
            on: {
              click: function ($event) {
                return _vm.setActiveTooltip("contact-person")
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm.isPrimaryAccessFiber || _vm.isPrimaryAccessCopper
            ? _c("OnPremisesInstallationSettings", {
                ref: "primary-access-installation-options",
                staticClass: "margin__top__24 margin__bottom__24",
                attrs: {
                  "access-key": "primary",
                  "should-show-tooltip-by-id": _vm.activeTooltip,
                },
                on: {
                  setActiveTooltip: function ($event) {
                    return _vm.setActiveTooltip($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.hasBackupAccess &&
      (_vm.isSecondaryAccessFiber || _vm.isSecondaryAccessCopper)
        ? _c(
            "div",
            [
              _c(
                "telia-heading",
                {
                  staticClass: "margin__bottom__12",
                  attrs: { tag: "h5", variant: "title-100" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.t("DATANET_SECONDARY_ACCESS")) +
                      "\n    "
                  ),
                ]
              ),
              _vm.hasBackupTypeDiversity || _vm.hasBackupTypeRedundancy
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "input-wrapper" },
                      [
                        _c("telia-select", {
                          attrs: {
                            id: "secondary-installation-contact",
                            label: _vm.t("DATANET_CONTACT_PERSON"),
                            value: _vm.secondaryContact,
                            options: JSON.stringify(
                              _vm.secondaryContactOptions
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleSecondaryContactSelection(
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm.$v.secondaryContact.emailAddress.$error
                          ? _c("ValidationError", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.t("DATANET_CONTACT_PERSON_REQUIRED")
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                [
                  _c("OnPremisesInstallationSettings", {
                    ref: "secondary-access-installation-options",
                    staticClass: "margin__top__24 margin__bottom__24",
                    attrs: { "access-key": "secondary" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }