<template>
  <div>
    <telia-heading tag="h3" variant="title-200">
      {{ t("DATANET_DELIVERY_INFORMATION") }}
    </telia-heading>
    <div class="margin__bottom__24">
      <telia-p
        v-html="
          t('DATANET_DELIVERY_INFORMATION_INFO', {
            date: estimatedInstallationDate,
          })
        "
      />
    </div>
    <div v-if="!access.enable">
      <div class="margin__bottom__12">
        <telia-heading tag="h4" variant="title-100">
          {{ t("DATANET_WOULD_YOU_LIKE_TO_ADD_TEMPORARY_ACCESS") }}
        </telia-heading>
      </div>
      <telia-button
        id="add-temporary-access"
        t-id="add-temporary-access"
        type="button"
        variant="secondary"
        size="sm"
        :left-icon="JSON.stringify({ name: 'plus', size: 'sm' })"
        :text="t('DATANET_ADD_TEMPORARY_ACCESS')"
        @click="showModal()"
      />
    </div>

    <div v-else>
      <Summary class="margin__bottom__24" :edit-label="t('DATANET_EDIT')" @edit="showModal()">
        <telia-p>
          <strong>{{ t("DATANET_TEMPORARY_ACCESS") }}</strong>
          <br />
          {{ fastTrackSummaryText }}<br />
          {{ bearerSummaryText }}<br />
          {{ bandwidthSummaryText }}<br />
          {{ slaSummaryText }}<br />
          {{ qosSummaryText }}<br />
          {{ teliaInstallationSummaryText }}<br />
          {{ antennaPackageSummaryText }}<br />
          {{ antennaCableSummaryText }}<br />
          {{ antennaInstallationSummaryText }}<br />
          {{ afterDeliveryOptionSummaryText }}<br />
        </telia-p>
      </Summary>

      <telia-button
        id="delete-temporary-access"
        type="button"
        variant="secondary"
        size="sm"
        :left-icon="JSON.stringify({ name: 'close', size: 'sm' })"
        :text="t('DATANET_DELETE_TEMPORARY_ACCESS')"
        @click="disableAccess(Accesses.TEMPORARY)"
      />
    </div>
    <Modal
      v-if="modalIsActive"
      modal-id="temporary-access-settings-modal"
      width="m"
      :is-open="modalIsActive"
      @closeModal="hideModal()"
    >
      <div slot="modal-body">
        <TemporaryAccessSettings @complete="hideModal()" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { translateMixin } from "../../locale";
import { mapState, mapGetters, mapActions } from "vuex";
import Summary from "../Summary";
import TemporaryAccessSettings from "./TemporaryAccessSettings";
import Modal from "../Modal.vue";
import { Accesses } from "../../constants";

export default {
  name: "QuickLaunch",
  mixins: [translateMixin],
  components: {
    Summary,
    TemporaryAccessSettings,
    Modal,
  },

  data() {
    return {
      Accesses,
      modalIsActive: false,
    };
  },

  computed: {
    ...mapState("products", {
      primaryAccessBearer: (state) => state.bearer.value,
    }),

    ...mapGetters("access", ["getAccess"]),

    ...mapGetters("products", { getOrderCaptureAccess: "getAccess" }),

    access() {
      return this.getAccess(Accesses.TEMPORARY);
    },

    estimatedInstallationDate() {
      return this.getOrderCaptureAccess(this.primaryAccessBearer).estimatedInstallationDate;
    },

    fastTrackSummaryText() {
      return this.t("DATANET_SUMMARY_FAST_TRACK", {
        response: this.access.fastTrack ? this.t("DATANET_YES") : this.t("DATANET_NO"),
      });
    },

    bearerSummaryText() {
      return this.t("DATANET_SUMMARY_BEARER", {
        bearer: this.t(`DATANET_${this.access.bearer}`),
      });
    },

    bandwidthSummaryText() {
      return this.t("DATANET_SUMMARY_BANDWIDTH", {
        bandwidth: this.t("DATANET_BEST_EFFORT"),
      });
    },

    slaSummaryText() {
      return this.t("DATANET_SUMMARY_SLA", { sla: this.access.sla.new });
    },

    qosSummaryText() {
      return this.t("DATANET_SUMMARY_QOS", {
        qos: this.t(`DATANET_${this.access.qos.new}_DESCRIPTION`),
      });
    },

    teliaInstallationSummaryText() {
      return this.t("DATANET_SUMMARY_HARDWARE_INSTALLATION", {
        response: this.access.options.wirelessInstallation
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    antennaPackageSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_PACKAGE", {
        package: this.t(`DATANET_${this.access.options.antenna.package}`),
      });
    },

    antennaCableSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_CABLE", {
        response: this.access.options.antenna.cable ? this.t("DATANET_YES") : this.t("DATANET_NO"),
      });
    },

    antennaInstallationSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_INSTALLATION", {
        response: this.access.options.antenna.installation
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    afterDeliveryOptionSummaryText() {
      return this.t("DATANET_SUMMARY_AFTER_DELIVERY_OPTION", {
        option: this.access.useAsSecondaryAccess
          ? this.t("DATANET_USE_TEMPORARY_ACCESS_AS_BACKUP")
          : this.t("DATANET_TERMINATE"),
      });
    },
  },

  methods: {
    ...mapActions("access", ["disableAccess"]),

    showModal() {
      this.modalIsActive = true;
    },

    hideModal() {
      this.modalIsActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.margin {
  &__bottom {
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
}
</style>
