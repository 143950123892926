<template>
  <div>
    <b2x-input-suggestions
      ref="addressSuggestions"
      :label="label"
      :suggestions="JSON.stringify(parsedAddressSuggestions)"
      :loading="loading"
      :disabled="disabled"
      :value="selectedAddress"
      :error="addressError"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { corpAddressLookup } from "@telia/b2b-rest-client";
import { translateMixin } from "../locale";
import "@telia/b2x-input-suggestions";

const SEARCH_DELAY_IN_MS = 500;
const MINIMUM_INPUT_LENGTH_FOR_SEARCH = 4;
const EMPTY_INPUT_LENGTH = 0;

export default {
  name: "AddressSearch",
  mixins: [translateMixin],
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      suggestions: [],
      address: null,
      selectedAddress: null,
      initiatedSearch: false,
      loading: false,
      debounce: null,
      addressError: "",
    };
  },

  mounted() {
    this.initializing = true;
    this.$refs.addressSuggestions.addEventListener("inputValue", (event) => {
      this.handleInput(event.detail);
    });
    this.$refs.addressSuggestions.addEventListener("suggestionSelected", (event) => {
      this.handleSuggestionSelect(event.detail);
    });
    if (this.value) {
      this.selectedAddress = this.value;
    }
  },

  computed: {
    ...mapState("user", {
      scopeId: (state) => state.scopeId,
    }),

    parsedAddressSuggestions() {
      return this.suggestions
        ? this.suggestions
            .map(function (address) {
              return address["fullAddress"];
            })
            .sort()
        : [];
    },
  },

  methods: {
    async handleInput(input) {
      this.selectedAddress = null;

      if (!this.initiatedSearch && input.length > 0) {
        this.initiatedSearch = true;
      }

      if (input.length < MINIMUM_INPUT_LENGTH_FOR_SEARCH) {
        if (input.length === EMPTY_INPUT_LENGTH) {
          this.suggestions = [];
          this.addressError = "";
        }
        clearTimeout(this.debounce);
        return;
      } else if (this.debounce) {
        clearTimeout(this.debounce);
      }

      this.loading = true;

      this.debounce = setTimeout(async () => {
        if (!this.suggestionsIncludesInput(input, this.suggestions)) {
          try {
            const response = await this.getAddressSuggestions(input);
            if (response.length > 0) {
              this.suggestions = response;
              this.suggestions.forEach((address) => {
                const parsedStreetName = address.streetName
                  .toLowerCase()
                  .replace(/(^|[\s-])\S/g, (c) => c.toUpperCase());
                address.fullAddress =
                  parsedStreetName +
                  " " +
                  address.streetNumber +
                  (address.entrance ? address.entrance : "") +
                  ", " +
                  address.city;
              });
            } else {
              this.addressError = this.t("ADDRESS_MASTER.INVALID_ADDRESS");
            }
          } catch (e) {
            this.addressError = this.t("ADDRESS_MASTER.FETCH_ERROR");
          }
          this.loading = false;
        }
      }, SEARCH_DELAY_IN_MS);
    },

    suggestionsIncludesInput(input, suggestions) {
      if (input && suggestions.length > 0) {
        return Object.keys(suggestions)
          .map((key) => key.toLowerCase().substring(0, input.length))
          .includes(input.toLowerCase());
      }
      return null;
    },

    async getAddressSuggestions(input) {
      try {
        const addressSuggestions = await corpAddressLookup.AddressLookupControllerService.searchAddress1(
          this.scopeId,
          input,
          true,
          25
        );

        return addressSuggestions.map((object) => {
          Object.assign(object, { ...object, id: object.fullAddress });
          return object;
        }, {});
      } catch (e) {
        throw new Error(e);
      }
    },

    handleSuggestionSelect(address) {
      this.address = this.suggestions.find((el) => el.fullAddress === address);
      this.selectedAddress = this.address.fullAddress;
      this.suggestions = [];
      this.addressError = "";
      this.initiatedSearch = false;
      this.$emit("addressSelected", this.address);
    },
  },
};
</script>
