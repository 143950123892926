import defaultChangeableValue from "../../defaultChangeableValue.js";
import { Actions } from "../../../constants";

const network = ({
  action = Actions.ADD,
  lanKey = "",
  input = { lanKey, ...defaultChangeableValue() },
  ipVersion = { lanKey, ...defaultChangeableValue() },
  ipAddress = { lanKey, ...defaultChangeableValue() },
  ipPrefix = { lanKey, ...defaultChangeableValue() },
  virtualIp = { lanKey, ...defaultChangeableValue() },
} = {}) => ({
  action,
  lanKey,
  input,
  ipVersion,
  ipAddress,
  ipPrefix,
  virtualIp,
});

export default network;
