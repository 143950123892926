import Vue from "vue";
import { Actions, Accesses, DefaultValues, MetadataKeys } from "../../constants";
import generateShortId from "../../helpers/generateShortId";
import defaultChangeableValue from "../defaultChangeableValue.js";
import updateExistingObjectProperties from "../../helpers/updateExistingObjectProperties";

const state = defaultState();

function defaultState() {
  return {
    routes: {},
  };
}

function defaultRouteObject(
  routeKey = DefaultValues.NONE,
  vpnKey = DefaultValues.NONE,
  siblingRouteKey = DefaultValues.NONE
) {
  return {
    action: Actions.ADD,
    key: routeKey,
    siblingRouteKey,
    vpn: vpnKey,
    network: {
      input: { routeKey, ...defaultChangeableValue() },
      ipVersion: { routeKey, ...defaultChangeableValue() },
      ipAddress: { routeKey, ...defaultChangeableValue() },
      ipPrefix: { routeKey, ...defaultChangeableValue() },
    },
    nextHop: {
      ipVersion: { routeKey, ...defaultChangeableValue() },
      ipAddress: { routeKey, ...defaultChangeableValue() },
      isMemberOfLanNetwork: false,
    },
  };
}

const getters = {
  shouldShowStaticRoutes: (state, getters, rootState, rootGetters) => (vpnKey) =>
    rootGetters["vpn/getVpn"](vpnKey).action !== Actions.DELETE,

  canAddStaticRoute: (state, getters, rootState, rootGetters) => (vpnKey) => {
    const { action: vpnAction, accessKey } = rootGetters["vpn/getVpn"](vpnKey);
    return (
      getters.shouldShowStaticRoutes(vpnKey) &&
      accessKey === Accesses.PRIMARY &&
      vpnAction !== Actions.DELETE
    );
  },
  isStaticRoutesEditable: (state, getters, rootState, rootGetters) => (vpnKey) =>
    getters.shouldShowStaticRoutes(vpnKey) &&
    rootGetters["vpn/isVpnsEditable"] &&
    rootGetters["metadata/isEditable"](MetadataKeys.STATIC_ROUTES),

  canDeleteStaticRoutes: (state, getters, rootState, rootGetters) => (vpnKey) =>
    getters.isStaticRoutesEditable(vpnKey) &&
    rootGetters["vpn/getVpn"](vpnKey).accessKey === Accesses.PRIMARY,

  getRoute: (state) => (routeKey = DefaultValues.NONE) => state.routes[routeKey] || null,

  getAction: (state) => (routeKey = DefaultValues.NONE) => state.routes[routeKey].action || "",

  getStaticRoutesByKeys: (state) => (staticRouteKeys = []) =>
    Object.values(state.routes).filter((route) => staticRouteKeys.includes(route.key)),

  getAllRoutes: (state) => Object.values(state.routes) || [],

  getNetwork: (state) => (routeKey = DefaultValues.NONE) => {
    const route = state.routes[routeKey];
    return route ? route.network : {};
  },

  getNextHop: (state) => (routeKey = DefaultValues.NONE) => {
    const route = state.routes[routeKey];
    return route ? route.nextHop : {};
  },

  getNextHopIsMemberOfLanNetwork: (state) => (routeKey = DefaultValues.NONE) => {
    const route = state.routes[routeKey];
    return route ? route.nextHop.isMemberOfLanNetwork : false;
  },
};

const actions = {
  addRoute({ commit, dispatch, rootGetters }, { vpnKey, staticRouteKey, staticRouteSiblingKey }) {
    const { siblingVpnKey } = rootGetters["vpn/getVpn"](vpnKey);

    const routeKey = staticRouteKey == null ? generateShortId() : staticRouteKey;
    commit("ADD_ROUTE", {
      routeKey,
      vpnKey,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
    dispatch(
      "vpn/addStaticRoute",
      {
        vpnKey,
        staticRouteKey: routeKey,
      },
      { root: true }
    );

    const siblingRouteKey =
      staticRouteSiblingKey == null ? generateShortId() : staticRouteSiblingKey;
    commit("ADD_ROUTE", {
      routeKey: siblingRouteKey,
      vpnKey: siblingVpnKey,
      siblingRouteKey: routeKey,
    });
    dispatch(
      "vpn/addStaticRoute",
      {
        vpnKey: siblingVpnKey,
        staticRouteKey: siblingRouteKey,
      },
      { root: true }
    );
    commit("SET_SIBLING_ROUTE_KEY", { routeKey, siblingRouteKey });

    return routeKey;
  },

  deleteRoute({ commit, dispatch }, routeKey = DefaultValues.NONE) {
    const { vpn: vpnKey, siblingRouteKey } = state.routes[routeKey];

    dispatch("vpn/removeStaticRoute", { vpnKey, staticRouteKey: routeKey }, { root: true });
    commit("DELETE_ROUTE", routeKey);

    const hasSiblingRoute = !!state.routes[siblingRouteKey];
    if (hasSiblingRoute) {
      dispatch("deleteRoute", siblingRouteKey);
    }

    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setActionNone({ dispatch, commit }, routeKey = DefaultValues.NONE) {
    if (routeKey === DefaultValues.NONE) {
      return;
    }

    commit("SET_ACTION_NONE", routeKey);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setActionAdd({ dispatch, commit }, routeKey = DefaultValues.NONE) {
    if (routeKey === DefaultValues.NONE) {
      return;
    }

    commit("SET_ACTION_ADD", routeKey);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setActionUpdate({ dispatch, commit }, routeKey = DefaultValues.NONE) {
    if (routeKey === DefaultValues.NONE) {
      return;
    }

    commit("SET_ACTION_UPDATE", routeKey);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setActionDelete({ dispatch, commit }, routeKey = DefaultValues.NONE) {
    if (routeKey === DefaultValues.NONE) {
      return;
    }

    commit("SET_ACTION_DELETE", routeKey);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkInput(
    { dispatch, commit },
    { routeKey = DefaultValues.NONE, action, current, new: _new }
  ) {
    if (routeKey === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const network = state.routes[routeKey].network;
    if (!network) return;

    const input = updateExistingObjectProperties(network.input, {
      action,
      current: current ? current.trim() : current,
      new: _new ? _new.trim() : _new,
    });

    if (!input) return;

    commit("SET_NETWORK_INPUT", { routeKey, input });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkIpVersion(
    { dispatch, commit },
    { routeKey = DefaultValues.NONE, action, current, new: _new }
  ) {
    if (routeKey === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const network = state.routes[routeKey].network;
    if (!network) return;

    const ipVersion = updateExistingObjectProperties(network.ipVersion, {
      action,
      current: current,
      new: _new,
    });

    if (!ipVersion) return;

    commit("SET_NETWORK_IP_VERSION", { routeKey, ipVersion });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkIpAddress(
    { dispatch, commit },
    { routeKey = DefaultValues.NONE, action, current, new: _new }
  ) {
    if (routeKey === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const network = state.routes[routeKey].network;
    if (!network) return;

    const ipAddress = updateExistingObjectProperties(network.ipAddress, {
      action,
      current: current,
      new: _new,
    });

    if (!ipAddress) return;

    commit("SET_NETWORK_IP_ADDRESS", { routeKey, ipAddress });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkIpPrefix(
    { dispatch, commit },
    { routeKey = DefaultValues.NONE, action, current, new: _new }
  ) {
    if (routeKey === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const network = state.routes[routeKey].network;
    if (!network) return;

    const ipPrefix = updateExistingObjectProperties(network.ipPrefix, {
      action,
      current: current,
      new: _new,
    });

    if (!ipPrefix) return;

    commit("SET_NETWORK_IP_PREFIX", { routeKey, ipPrefix });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNextHopIpVersion(
    { dispatch, commit },
    { routeKey = DefaultValues.NONE, action, current, new: _new }
  ) {
    if (routeKey === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const nextHop = state.routes[routeKey].nextHop;
    if (!nextHop) return;

    const ipVersion = updateExistingObjectProperties(nextHop.ipVersion, {
      action,
      current: current,
      new: _new,
    });

    if (!ipVersion) return;

    commit("SET_NEXT_HOP_IP_VERSION", { routeKey, ipVersion });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNextHopIpAddress(
    { dispatch, commit },
    { routeKey = DefaultValues.NONE, action, current, new: _new }
  ) {
    if (routeKey === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const nextHop = state.routes[routeKey].nextHop;
    if (!nextHop) return;

    const ipAddress = updateExistingObjectProperties(nextHop.ipAddress, {
      action,
      current: current,
      new: _new,
    });

    if (!ipAddress) return;

    commit("SET_NEXT_HOP_IP_ADDRESS", { routeKey, ipAddress });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNextHopIsMemberOfLanNetwork(
    { dispatch, commit },
    { routeKey = DefaultValues.NONE, isMemberOfLanNetwork = false }
  ) {
    if (routeKey === DefaultValues.NONE) return;

    commit("SET_NEXT_HOP_IS_MEMBER_OF_LAN_NETWORK", {
      routeKey,
      isMemberOfLanNetwork,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },
};

const mutations = {
  ADD_ROUTE(state, { routeKey, vpnKey, siblingRouteKey }) {
    const newRoute = Object.assign({}, defaultRouteObject(routeKey, vpnKey, siblingRouteKey));
    Vue.set(state.routes, routeKey, newRoute);
  },

  DELETE_ROUTE(state, routeKey) {
    Vue.delete(state.routes, routeKey);
  },

  SET_ACTION_NONE(state, routeKey) {
    state.routes[routeKey].action = Actions.NONE;
  },

  SET_ACTION_ADD(state, routeKey) {
    state.routes[routeKey].action = Actions.ADD;
  },

  SET_ACTION_UPDATE(state, routeKey) {
    state.routes[routeKey].action = Actions.UPDATE;
  },

  SET_ACTION_DELETE(state, routeKey) {
    state.routes[routeKey].action = Actions.DELETE;
  },

  SET_SIBLING_ROUTE_KEY(state, { routeKey, siblingRouteKey }) {
    state.routes[routeKey].siblingRouteKey = siblingRouteKey;
  },

  SET_NETWORK_INPUT(state, { routeKey, input }) {
    Vue.set(state.routes[routeKey].network, "input", input);
  },

  SET_NETWORK_IP_VERSION(state, { routeKey, ipVersion }) {
    Vue.set(state.routes[routeKey].network, "ipVersion", ipVersion);
  },

  SET_NETWORK_IP_ADDRESS(state, { routeKey, ipAddress }) {
    Vue.set(state.routes[routeKey].network, "ipAddress", ipAddress);
  },

  SET_NETWORK_IP_PREFIX(state, { routeKey, ipPrefix }) {
    Vue.set(state.routes[routeKey].network, "ipPrefix", ipPrefix);
  },

  SET_NEXT_HOP_IP_VERSION(state, { routeKey, ipVersion }) {
    Vue.set(state.routes[routeKey].nextHop, "ipVersion", ipVersion);
  },

  SET_NEXT_HOP_IP_ADDRESS(state, { routeKey, ipAddress }) {
    Vue.set(state.routes[routeKey].nextHop, "ipAddress", ipAddress);
  },

  SET_NEXT_HOP_IS_MEMBER_OF_LAN_NETWORK(state, { routeKey, isMemberOfLanNetwork }) {
    state.routes[routeKey].nextHop.isMemberOfLanNetwork = isMemberOfLanNetwork;
  },

  RESET_STATE(state) {
    Object.keys(state.routes).forEach((key) => delete state.routes[key]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
