import { corpValidationService } from "@telia/b2b-rest-client";

export async function corpValidation(validationBody, scopeId) {
  try {
    const response = await corpValidationService.ValidationControllerService.validate(
      scopeId,
      validationBody
    );
    return !!response;
  } catch (e) {
    return false;
  }
}

export function corpIpAddress(value, scopeId) {
  return value
    ? corpValidation(
        {
          type: "IPADDRESS",
          value: value,
        },
        scopeId
      )
    : false;
}

export function corpNetwork(value, scopeId) {
  return value
    ? corpValidation(
        {
          type: "NETWORK",
          value: value,
        },
        scopeId
      )
    : false;
}

export function isIpMemberOfNetwork(ip, network, scopeId) {
  try {
    return ip && network
      ? corpValidation(
          {
            type: "NETWORK",
            CIDR: network,
            MEMBER: ip,
          },
          scopeId
        )
      : false;
  } catch (e) {
    return false;
  }
}

export function positiveNumberOrZero(value) {
  return typeof value === "number" ? value >= 0 : parseInt(value) >= 0 || false;
}
