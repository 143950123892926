<template>
  <div class="pre-check-container">
    <div class="address-master-container">
      <AddressMaster
        t-id="address-input"
        :disable-submit="isFetchingProducts"
        @addressSubmit="handleAddressSubmit($event)"
        @coordinateSubmit="handleCoordinateSubmit($event)"
      />
    </div>

    <telia-notification
      v-if="alert.show"
      t-id="pre-check-alert"
      heading-tag="h4"
      variant="inline"
      :status="alert.status"
      html-aria-live="polite"
      html-role="alert"
    >
      <span slot="heading">
        <telia-visually-hidden>
          {{ t("A11Y_STATUS_WARNING") }}
        </telia-visually-hidden>
        {{ alert.title }}
      </span>
      <span slot="content">
        <telia-p>
          {{ alert.text }}
        </telia-p>
      </span>
    </telia-notification>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { translateMixin } from "../locale";
import { corpPrecheckService } from "@telia/b2b-rest-client";
import AddressMaster from "./AddressMaster";

const ADDRESS_OPTIONS = {
  ADDRESS: "address",
  COORDINATES: "coordinates",
};

export default {
  name: "PreCheck",

  mixins: [translateMixin],

  components: {
    AddressMaster,
  },

  data() {
    return {
      isFetchingProducts: false,
      selected: "",
      address: {
        streetName: null,
        streetNumber: null,
        entrance: null,
        postalCode: null,
        city: null,
      },
      coordinates: {
        x: null,
        y: null,
      },
      alert: {
        show: false,
        title: "",
        text: "",
        variant: "warning",
      },
    };
  },

  computed: {
    ...mapState("user", {
      scopeId: (state) => state.scopeId,
    }),
  },

  methods: {
    resetAddressAndCoordinates() {
      this.address.streetName = null;
      this.address.streetNumber = null;
      this.address.entrance = null;
      this.address.postalCode = null;
      this.address.city = null;
      this.coordinates.x = null;
      this.coordinates.y = null;
    },

    handleAddressSubmit(event) {
      this.selected = ADDRESS_OPTIONS.ADDRESS;
      this.hideAlert();
      this.resetAddressAndCoordinates();
      this.address.fullAddress = event.fullAddress;
      this.address.streetName = event.streetName;
      this.address.streetNumber = event.streetNumber;
      this.address.entrance = event.entrance;
      this.address.postalCode = event.postalCode;
      this.address.city = event.city;
      this.handleSubmit();
    },

    handleCoordinateSubmit(event) {
      this.selected = ADDRESS_OPTIONS.COORDINATES;
      this.hideAlert();
      this.resetAddressAndCoordinates();
      this.coordinates.x = event.x;
      this.coordinates.y = event.y;
      this.handleSubmit();
    },

    async handleSubmit() {
      if (!this.isFetchingProducts) {
        if (!this.alert.show) {
          this.isFetchingProducts = true;
          const products = await this.getProducts();
          this.isFetchingProducts = false;

          if (!products) {
            return;
          }

          const result = this.createResult(products);
          this.$emit("result", result);
        }
      }
    },

    async getProducts() {
      try {
        return await corpPrecheckService.MybdFixedNetControllerService.getFixedNet(
          this.scopeId,
          this.address.streetName,
          this.address.city,
          this.address.streetNumber,
          this.address.entrance,
          this.coordinates.x,
          this.coordinates.y
        );
      } catch (e) {
        this.showAlert(
          this.t("PRE_CHECK.GET_PRODUCTS_FETCH_ERROR_TITLE"),
          this.t("PRE_CHECK.GET_PRODUCTS_FETCH_ERROR"),
          "warning"
        );
      }
    },

    createResult(products) {
      return {
        products,
        coordinates:
          this.selected === ADDRESS_OPTIONS.COORDINATES
            ? {
                x: this.coordinates.x,
                y: this.coordinates.y,
              }
            : null,
        address: this.selected === ADDRESS_OPTIONS.ADDRESS ? Object.assign({}, this.address) : null,
      };
    },

    showAlert(title, text, status) {
      this.alert.title = title;
      this.alert.text = text;
      this.alert.status = status;
      this.alert.show = true;
    },

    hideAlert() {
      this.alert.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.pre-check-container {
  max-width: 68rem;
  margin-bottom: $telia-spacing-24;
}

.address-master-container {
  margin-bottom: $telia-spacing-24;
}
</style>
