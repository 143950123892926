var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "excluded-ip-range" },
      [
        _c(
          "telia-heading",
          {
            staticClass: "margin__bottom__12",
            attrs: { tag: "h3", variant: "title-100" },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.t("DATANET_VPN_CONFIGURE_EXCLUDED_IP_RANGE")) +
                "\n    "
            ),
          ]
        ),
        _c(
          "div",
          { attrs: { "t-id": "action-select" } },
          [
            _vm.isChangeOrder
              ? _c("ActionSelection", {
                  staticClass: "component-wrapper",
                  attrs: {
                    "selected-action": _vm.action,
                    "disabled-actions": _vm.disabledActions,
                  },
                  on: {
                    selected: function ($event) {
                      return _vm.setIpRangeAction($event)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "row margin__bottom__12" }, [
          _c(
            "div",
            { staticClass: "column column-small" },
            [
              _c(
                "telia-p",
                { staticClass: "column-title column-title-margin" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.t("DATANET_VPN_DHCP_SERVER_EXCLUDED_RANGE_START")
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "column-container" }, [
            _vm.isCurrentValuesRequired
              ? _c(
                  "div",
                  { staticClass: "column input-wrapper" },
                  [
                    _c("telia-text-input", {
                      attrs: {
                        "t-id": "current-start-interval",
                        id: "server-excluded-range-start-current",
                        label: _vm.t("DATANET_CURRENT_VALUE"),
                        "validation-state": _vm.$v.start.ipAddress.current
                          .$error
                          ? "Invalid"
                          : "Valid",
                        value: _vm.start.ipAddress.current,
                      },
                      on: {
                        input: function ($event) {
                          _vm.start.ipAddress.current = $event.target.value
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isNewValuesRequired
              ? _c(
                  "div",
                  { staticClass: "column input-wrapper" },
                  [
                    _c("telia-text-input", {
                      attrs: {
                        "t-id": "new-start-interval",
                        label: _vm.t("DATANET_NEW_VALUE"),
                        "validation-state": _vm.$v.start.ipAddress.new.$error
                          ? "Invalid"
                          : "Valid",
                        value: _vm.start.ipAddress.new,
                      },
                      on: {
                        input: function ($event) {
                          _vm.start.ipAddress.new = $event.target.value
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "row margin__bottom__4" }, [
          _c(
            "div",
            { staticClass: "column column-small" },
            [
              _c("telia-p", { staticClass: "column-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.t("DATANET_VPN_DHCP_SERVER_EXCLUDED_RANGE_END")
                    ) +
                    "\n        "
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "column-container" }, [
            _vm.isCurrentValuesRequired
              ? _c(
                  "div",
                  { staticClass: "column input-wrapper" },
                  [
                    _c("telia-text-input", {
                      attrs: {
                        "t-id": "current-end-interval",
                        id: "server-excluded-range-end-current",
                        "validation-state": _vm.$v.end.ipAddress.current.$error
                          ? "Invalid"
                          : "Valid",
                        value: _vm.end.ipAddress.current,
                      },
                      on: {
                        input: function ($event) {
                          _vm.end.ipAddress.current = $event.target.value
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isNewValuesRequired
              ? _c(
                  "div",
                  { staticClass: "column input-wrapper" },
                  [
                    _c("telia-text-input", {
                      attrs: {
                        "t-id": "new-end-interval",
                        "validation-state": _vm.$v.end.ipAddress.new.$error
                          ? "Invalid"
                          : "Valid",
                        value: _vm.end.ipAddress.new,
                      },
                      on: {
                        input: function ($event) {
                          _vm.end.ipAddress.new = $event.target.value
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "row margin__bottom__24" }, [
          _c(
            "div",
            { staticClass: "column column-small" },
            [_c("telia-p", [_vm._v(" ")])],
            1
          ),
          _c(
            "div",
            { staticClass: "column-container" },
            [
              _vm.$v.$error
                ? _c("ValidationError", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.t("DATANET_INVALID_IP_ADDRESS")) +
                        "\n        "
                    ),
                  ])
                : _c(
                    "telia-p",
                    {
                      staticClass: "additional-text",
                      attrs: { variant: "additional-100" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.t("DATANET_IP_ADDRESS_PLACEHOLDER")) +
                          "\n        "
                      ),
                    ]
                  ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "button-wrapper" },
      [
        _c(
          "telia-button",
          {
            attrs: {
              "t-id": "save-excluded-ip-interval-config",
              type: "button",
              variant: "expressive",
              disabled: _vm.disableSaveButton,
            },
            on: {
              click: function ($event) {
                return _vm.saveAndClose()
              },
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.t("DATANET_SAVE_CHANGES")) + "\n    "
            ),
          ]
        ),
        _c(
          "telia-button",
          {
            attrs: {
              id: "cancel-excluded-ip-address-configuration",
              type: "button",
              variant: "secondary",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("complete")
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.t("DATANET_ABORT")) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }