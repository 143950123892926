var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-container" },
    [
      _c(
        "telia-heading",
        { staticClass: "heading", attrs: { tag: "h3", variant: "title-100" } },
        [_vm._v("\n    " + _vm._s(_vm.t("DATANET_SLA")) + "\n  ")]
      ),
      _c(
        "div",
        { staticClass: "info-text" },
        [_c("telia-p", [_vm._v(_vm._s(_vm.t("DATANET_SLA_DESCRIPTION")))])],
        1
      ),
      _c(
        "table",
        { staticClass: "table" },
        [
          _c("tr", { staticClass: "table-row table-header-row" }, [
            _c(
              "th",
              { staticClass: "table-row-item" },
              [_c("telia-p", [_vm._v(_vm._s(_vm.t("DATANET_SLA_NAME")))])],
              1
            ),
            _c(
              "th",
              { staticClass: "table-row-item" },
              [
                _c("telia-p", [
                  _vm._v(_vm._s(_vm.t("DATANET_SLA_REPAIR_TIME"))),
                ]),
              ],
              1
            ),
            _c(
              "th",
              { staticClass: "table-row-item" },
              [
                _c("telia-p", [
                  _vm._v(_vm._s(_vm.t("DATANET_SLA_AVAILABILITY"))),
                ]),
              ],
              1
            ),
          ]),
          _vm._l(_vm.serviceLevelAgreements, function (sla, index) {
            return _c("tr", { key: index, staticClass: "table-row" }, [
              _c(
                "td",
                { staticClass: "table-row-item" },
                [_c("telia-p", [_vm._v(_vm._s(sla.name))])],
                1
              ),
              _c(
                "td",
                { staticClass: "table-row-item" },
                [_c("telia-p", [_vm._v(_vm._s(sla.serviceHours))])],
                1
              ),
              _c(
                "td",
                { staticClass: "table-row-item" },
                [_c("telia-p", [_vm._v(_vm._s(sla.availability))])],
                1
              ),
            ])
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }