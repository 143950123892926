<template>
  <div class="info-container">
    <telia-heading tag="h3" variant="title-100" class="heading">
      {{ t("DATANET_SLA") }}
    </telia-heading>
    <div class="info-text">
      <telia-p>{{ t("DATANET_SLA_DESCRIPTION") }}</telia-p>
    </div>

    <table class="table">
      <tr class="table-row table-header-row">
        <th class="table-row-item">
          <telia-p>{{ t("DATANET_SLA_NAME") }}</telia-p>
        </th>
        <th class="table-row-item">
          <telia-p>{{ t("DATANET_SLA_REPAIR_TIME") }}</telia-p>
        </th>
        <th class="table-row-item">
          <telia-p>{{ t("DATANET_SLA_AVAILABILITY") }}</telia-p>
        </th>
      </tr>
      <tr v-for="(sla, index) in serviceLevelAgreements" :key="index" class="table-row">
        <td class="table-row-item">
          <telia-p>{{ sla.name }}</telia-p>
        </td>
        <td class="table-row-item">
          <telia-p>{{ sla.serviceHours }}</telia-p>
        </td>
        <td class="table-row-item">
          <telia-p>{{ sla.availability }}</telia-p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { translateMixin } from "../../../locale";

export default {
  name: "SLATooltip",
  mixins: [translateMixin],
  computed: {
    serviceLevelAgreements() {
      return [
        {
          name: "D15M",
          serviceHours: "15 min",
          availability: "99,98%",
        },
        {
          name: "C4H",
          serviceHours: "4 h",
          availability: "99,80%",
        },
        {
          name: "C8H",
          serviceHours: "8 h",
          availability: "99,60%",
        },
        {
          name: "B4H",
          serviceHours: "4 h",
          availability: "99,60%",
        },
        {
          name: "B8H",
          serviceHours: "8 h",
          availability: "99,40%",
        },
        {
          name: "A4H",
          serviceHours: "4 h",
          availability: "99,50%",
        },
        {
          name: "A8H",
          serviceHours: "8 h",
          availability: "99,20%",
        },
        {
          name: "A12H",
          serviceHours: "12 h",
          availability: "98,80%",
        },
        {
          name: "A24H",
          serviceHours: "24 h",
          availability: "96,20%",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";
.heading {
  padding: 0 0 $telia-spacing-12 0;
}

.info-text {
  white-space: normal;
}

.info-container {
  background-color: $telia-gray-50;
}

.table {
  display: flex;
  flex-direction: column;
  padding: $telia-spacing-24 0 0 0;
}

.table-row {
  display: flex;
  justify-content: flex-start;
  padding: 0 0 $telia-spacing-12 0;
}

.table-header-row {
  padding: 0 0 $telia-spacing-24 0;
}

.table-row-item {
  display: flex;
  flex-basis: 33%;
  font-weight: normal;
}
</style>
