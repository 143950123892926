<template>
  <div class="margin__bottom__32">
    <div class="margin__bottom__16">
      <telia-heading
        v-if="!isChangeOrder"
        tag="h3"
        variant="title-200"
        v-text="t(`DATANET_${accessKey.toUpperCase()}_ACCESS`)"
      />
    </div>
    <MultiVpn
      v-if="accessKey === Accesses.PRIMARY"
      class="margin__bottom__24"
      @click="setDefaultSelected()"
    />
    <BandwidthDistribution
      v-if="shouldShowBandwidthDistribution"
      class="margin__bottom__24"
      :access-key="accessKey"
      :active-tooltip="activeTooltip"
      @activeTooltip="setActiveTooltip($event)"
    />
    <VpnInterface
      v-if="shouldShowVpnInterface(accessKey)"
      class="margin__bottom__24"
      :access-key="accessKey"
      :active-tooltip="activeTooltip"
      @activeTooltip="setActiveTooltip($event)"
    />
    <RtTot
      v-if="shouldShowRtTot(accessKey)"
      class="margin__bottom__24"
      :access-key="accessKey"
      :active-tooltip="activeTooltip"
      @activeTooltip="setActiveTooltip($event)"
    />
    <section v-if="shouldValidateVpnSettings && hasMultipleVpns && false" class="row margin-bottom">
      <telia-heading tag="h5" variant="title-500">
        {{ t("DATANET_VPN_SELECT_LAN_INTERFACES") }}
      </telia-heading>
      <telia-select
        :id="`lan-interfaces`"
        class="input-container margin-bottom"
        label=""
        :placeholder="t('DATANET_VPN_LAN_INTERFACES_PLACEHOLDER')"
        :value="lanInterfaces.value"
        :options="getLanInterfaceOptions()"
        @change="lanInterfaces = { value: $event.target.value }"
      />
    </section>

    <section>
      <div class="container">
        <!-- first level -->
        <div class="column">
          <template v-if="selectedVpn">
            <ol class="list">
              <ListItem
                v-for="(vpn, index) in vpns"
                :t-id="`${vpn.accessKey}-vpn-${index}`"
                :key="`vpn-${index}`"
                :text="`VPN ${index + 1}`"
                :invalid="vpnContainsAnyError(index)"
                :active="selectedVpnIndex === index"
                :deletable="canDeleteVpn(index, vpn.key)"
                :checkable="isBackupSettings && !hasBackupTypeRedundancy && !hasBackupTypeDiversity"
                :checked="isIncludedInBackup(vpn.key)"
                @delete="handleDeleteVpn(index)"
                @include="toggleBackupVpnInclusion(vpn.key)"
                @click="selectVpn(index)"
              />
            </ol>
            <template v-if="canAddVpn(accessKey)">
              <span class="separator" />
              <ul class="list">
                <ListItem
                  :t-id="`add-${accessKey}-vpn`"
                  :text="isChangeOrder ? t('DATANET_CONFIGURE_VPN') : t('DATANET_ADD_VPN')"
                  @click="addVpn({ addDefaultLan: !isChangeOrder })"
                  addable
                />
              </ul>
            </template>
          </template>
        </div>

        <!-- second level -->
        <div class="column">
          <template v-if="selectedVpn">
            <ol class="list">
              <ListItem
                :text="t('DATANET_VPN_BASE_SETTINGS')"
                :invalid="getVpnValidationObject(selectedVpnIndex).$anyError"
                :active="selectedSetting === settings.BASE"
                @click="selectBaseSettings()"
              />
              <template v-if="shouldNotDeleteVpn">
                <ListItem
                  v-for="(lan, index) in selectedVpnLans"
                  :t-id="`lan-${index}`"
                  :key="`lan-${index}`"
                  :text="`LAN ${index + 1}`"
                  :invalid="getLanValidationObject(lan.key).$anyError"
                  :active="selectedLanIndex === index"
                  :deletable="canDeleteLan(index)"
                  @delete="handleDeleteLan(index)"
                  @click="selectLan(index)"
                />
                <template v-if="shouldShowDhcpServers(selectedVpn.key)">
                  <ListItem
                    v-for="(dhcpServer, index) in selectedVpnDhcpServers"
                    :key="`dhcp-server-${index}`"
                    :t-id="`dhcp-server-${index}`"
                    :text="`DHCP server ${index + 1}`"
                    :invalid="getDhcpServerValidationObject(dhcpServer.key).$anyError"
                    :active="selectedDhcpServerIndex === index"
                    :deletable="canDeleteDhcpServer(selectedVpn.key)"
                    @delete="handleDeleteDhcpServer(index)"
                    @click="selectDhcpServer(index)"
                  />
                </template>
                <template v-if="shouldShowStaticRoutes(selectedVpn.key)">
                  <ListItem
                    v-for="(staticRoute, index) in selectedVpnStaticRoutes"
                    :key="`static-route-${index}`"
                    :t-id="`static-route-${index}`"
                    :text="`Static route ${index + 1}`"
                    :invalid="getStaticRouteValidationObject(staticRoute.key).$anyError"
                    :active="selectedStaticRouteIndex === index"
                    :deletable="canDeleteStaticRoutes(selectedVpn.key)"
                    @delete="handleDeleteStaticRoute(index)"
                    @click="selectStaticRoute(index)"
                  />
                </template>
              </template>
            </ol>
            <template v-if="!isBackupSettings">
              <span class="separator" />
              <ul v-if="isPrimaryAccessVpn && shouldNotDeleteVpn" class="list">
                <ListItem
                  v-if="canAddLan(selectedVpn.key)"
                  t-id="add-new-lan"
                  :text="
                    isChangeOrder
                      ? t('DATANET_VPN_CONFIGURE_LAN_AND_DHCP_RELAY')
                      : t('DATANET_VPN_ADD_LAN_AND_DHCP_RELAY')
                  "
                  @click="addLan({ vpnKey: selectedVpn.key })"
                  addable
                />
                <ListItem
                  v-if="shouldShowDhcpServers(selectedVpn.key)"
                  :addable="canAddDhcpServer(selectedVpn.key)"
                  t-id="add-dhcp-server"
                  :text="
                    isChangeOrder ? t('DATANET_VPN_CONFIGURE_SERVER') : t('DATANET_VPN_ADD_SERVER')
                  "
                  @click="addServer({ vpnKey: selectedVpn.key })"
                />
                <ListItem
                  v-if="shouldShowStaticRoutes(selectedVpn.key)"
                  :addable="canAddStaticRoute(selectedVpn.key)"
                  t-id="add-static-route"
                  :text="
                    isChangeOrder
                      ? t('DATANET_VPN_CONFIGURE_STATIC_ROUTE')
                      : t('DATANET_VPN_ADD_STATIC_ROUTE')
                  "
                  @click="addRoute({ vpnKey: selectedVpn.key })"
                />
              </ul>
            </template>
          </template>
        </div>

        <!-- third level -->
        <div class="column settings-container">
          <BaseSettings
            v-if="selectedSetting === settings.BASE"
            :vpn="selectedVpn"
            :v="getVpnValidationObject(selectedVpnIndex)"
          />
          <Lan
            v-else-if="selectedSetting === settings.LAN"
            :lan="selectedLan"
            :v="getLanValidationObject(selectedLan.key)"
            :active-tooltip="activeTooltip"
            @activeTooltip="setActiveTooltip($event)"
          />
          <DhcpServer
            v-else-if="selectedSetting === settings.DHCP_SERVER"
            :dhcp-server="selectedDhcpServer"
            :v="getDhcpServerValidationObject(selectedDhcpServer.key)"
          />
          <StaticRoute
            v-else-if="selectedSetting === settings.STATIC_ROUTE"
            :vpn-key="selectedVpn.key"
            :static-route-key="selectedStaticRoute.key"
            :v="getStaticRouteValidationObject(selectedStaticRoute.key)"
          />
        </div>
      </div>
    </section>

    <template v-if="$v.vpns.$error">
      <ValidationError
        v-if="!$v.vpns.hasLessVpnsThanMax"
        v-text="
          t('DATANET_NR_OF_VPNS_EXCEED_MAX_LIMIT', {
            max: maxNrOfVpns,
          })
        "
      />
      <ValidationError
        v-if="!$v.vpns.hasVpnsIncludedInBackup"
        v-text="t('DATANET_NO_INCLUDED_BACKUP_VPNS')"
      />
      <ValidationError
        v-if="!$v.vpns.hasAtLeastOneDhcpRelay"
        v-text="t('DATANET_VPN_CONFIGURE_AT_LEAST_ONE_DHCP_RELAY')"
      />
      <ValidationError
        v-if="!$v.vpns.hasAtLeastOneDhcpServer"
        v-text="t('DATANET_VPN_CONFIGURE_AT_LEAST_ONE_DHCP_SERVER')"
      />
    </template>
  </div>
</template>

<script>
import BandwidthDistribution from "./BandwidthDistribution.vue";
import BaseSettings from "./BaseSettings";
import DhcpServer from "./DhcpServer";
import Lan from "./Lan";
import ListItem from "./ListItem";
import MultiVpn from "./MultiVpn.vue";
import RtTot from "./RtTot.vue";
import StaticRoute from "./StaticRoute";
import ValidationError from "../ValidationError.vue";
import VpnInterface from "./VpnInterface.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { corpIpAddress, corpNetwork } from "../../helpers/corpValidation";
import { Accesses, Actions, MetadataKeys } from "../../constants";
import { translateMixin } from "../../locale";

const settings = {
  NONE: "",
  VPN: "vpn",
  BASE: "base",
  LAN: "lans",
  DHCP_SERVER: "dhcpServer",
  STATIC_ROUTE: "staticRoute",
};

const DEFAULT_VPN_INDEX = 0;
const UNSELECTED = -1;
const MAXIMUM_NUMBER_OF_LAN_INTERFACES = 8;

const VALID_VLAN_ID_REGEX = new RegExp(
  /^(?:[1-9]|[1-9][0-9]|[1-9][0-9]{2}|[1-3][0-9]{3}|40[0-8][0-9]|409[0-4])$/
);

export default {
  name: "VpnSettings",

  mixins: [translateMixin, validationMixin],

  components: {
    BandwidthDistribution,
    BaseSettings,
    DhcpServer,
    Lan,
    ListItem,
    MultiVpn,
    RtTot,
    StaticRoute,
    ValidationError,
    VpnInterface,
  },

  props: {
    accessKey: {
      type: String,
      required: true,
    },
    vpns: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedVpnIndex: DEFAULT_VPN_INDEX,
      selectedLanIndex: UNSELECTED,
      selectedDhcpServerIndex: UNSELECTED,
      selectedStaticRouteIndex: UNSELECTED,
      selectedSetting: settings.NONE,
      settings,
      Accesses,
      activeTooltip: null,
    };
  },

  computed: {
    ...mapState({
      scopeId: (state) => state.user.scopeId,
    }),

    ...mapGetters(["isChangeOrder", "isFunctionBusinessOrder"]),

    ...mapGetters("access", [
      "hasBackupAccess",
      "hasBackupTypeBackup",
      "hasBackupTypeRedundancy",
      "hasBackupTypeDiversity",
    ]),

    ...mapGetters("dhcpServer", {
      shouldShowDhcpServers: "shouldShowDhcpServers",
      canDeleteDhcpServer: "canDeleteDhcpServer",
      canAddDhcpServer: "canAddDhcpServer",
      hasAnyDhcpServers: "hasAnyDhcpServers",
      getServersByKeys: "getServersByKeys",
      getDhcpServerAction: "getAction",
    }),

    ...mapGetters("lans", ["canAddLan", "getLan", "getLansByKeys", "hasAnyDhcpRelays"]),

    ...mapGetters("staticRoutes", [
      "getStaticRoutesByKeys",
      "shouldShowStaticRoutes",
      "canAddStaticRoute",
      "canDeleteStaticRoutes",
    ]),

    ...mapGetters("staticRoutes", {
      getStaticRouteAction: "getAction",
    }),

    ...mapGetters("vpn", [
      "hasMultipleVpns",
      "hasIncludedBackupVpns",
      "hasLessOrEqualNrOfVpnsThanMax",
      "hasVpns",
      "shouldShowVpns",
      "shouldShowBandwidthDistribution",
      "shouldShowVpnInterface",
      "shouldShowRt2",
      "shouldShowRtBandwidth",
      "shouldShowRtTot",
      "shouldValidateVpnSettings",
      "canAddVpn",
      "canDeleteVpn",
      "isIncludedInBackup",
      "isPhysicalInterface",
      "getVpn",
      "maxNrOfVpns",
    ]),

    ...mapGetters("metadata", ["isMandatory"]),

    selectedVpn() {
      if (this.selectedVpnIndex === UNSELECTED) return null;
      return this.vpns[this.selectedVpnIndex];
    },

    isPrimaryAccessVpn() {
      return this.selectedVpn ? this.selectedVpn.accessKey === Accesses.PRIMARY : false;
    },

    shouldNotDeleteVpn() {
      return this.selectedVpn ? this.selectedVpn.action !== Actions.DELETE : false;
    },

    lans() {
      let lanKeys = [];

      this.vpns.forEach((vpn) => {
        lanKeys.push(...vpn.lans);
      });

      return lanKeys.length ? this.getLansByKeys(lanKeys) : [];
    },

    dhcpServers() {
      let dhcpServerKeys = [];

      this.vpns.forEach((vpn) => {
        dhcpServerKeys.push(...vpn.dhcpServers);
      });

      return dhcpServerKeys.length ? this.getServersByKeys(dhcpServerKeys) : [];
    },

    staticRoutes() {
      let staticRouteKeys = [];

      this.vpns.forEach((vpn) => {
        staticRouteKeys.push(...vpn.staticRoutes);
      });

      return staticRouteKeys.length ? this.getStaticRoutesByKeys(staticRouteKeys) : [];
    },

    selectedVpnLans() {
      return this.selectedVpn ? this.getLansByKeys(this.selectedVpn.lans) : [];
    },

    selectedVpnDhcpServers() {
      return this.selectedVpn ? this.getServersByKeys(this.selectedVpn.dhcpServers) : [];
    },

    selectedVpnStaticRoutes() {
      return this.selectedVpn ? this.getStaticRoutesByKeys(this.selectedVpn.staticRoutes) : [];
    },

    selectedLan() {
      if (this.selectedLanIndex === UNSELECTED) return null;
      return this.selectedVpnLans[this.selectedLanIndex];
    },

    selectedDhcpServer() {
      if (this.selectedDhcpServerIndex === UNSELECTED) return null;
      return this.selectedVpnDhcpServers[this.selectedDhcpServerIndex];
    },

    selectedStaticRoute() {
      if (this.selectedStaticRouteIndex === UNSELECTED) return null;
      return this.selectedVpnStaticRoutes[this.selectedStaticRouteIndex];
    },

    isBackupSettings() {
      return this.accessKey === Accesses.SECONDARY;
    },

    isStaticBandwidthDistribution() {
      return this.$store.getters["vpn/isStaticBandwidthDistribution"](this.accessKey);
    },

    lanInterfaces: {
      get() {
        return this.$store.getters["vpn/getLanInterfaces"];
      },
      set(lanInterfaces) {
        if (typeof lanInterfaces.value === "string") {
          lanInterfaces.value = parseInt(lanInterfaces.value) || 1;
        }

        this.$store.dispatch("vpn/setLanInterfaces", lanInterfaces);
      },
    },

    ips() {
      return this.$store.state.ips;
    },
  },

  validations: {
    vpns: {
      hasLessVpnsThanMax: function () {
        if (!this.shouldValidateVpnSettings) return true;
        return this.hasLessOrEqualNrOfVpnsThanMax;
      },
      hasVpnsIncludedInBackup: function () {
        if (!this.shouldValidateVpnSettings) return true;
        if (!this.hasBackupAccess) return true;
        if (!this.isBackupSettings) return true;
        return this.hasIncludedBackupVpns;
      },
      hasAtLeastOneDhcpRelay: function () {
        if (this.isMandatory(MetadataKeys.DHCP_RELAYS)) {
          return this.hasAnyDhcpRelays;
        }
        return true;
      },
      hasAtLeastOneDhcpServer: function () {
        if (this.isMandatory(MetadataKeys.DHCP_SERVERS)) {
          return this.hasAnyDhcpServers;
        }
        return true;
      },
      $each: {
        $trackBy: "key",
        id: {
          required: requiredIf(function () {
            return this.shouldValidateVpnSettings;
          }),
        },
        bandwidth: {
          new: {
            required: requiredIf(function () {
              if (!this.shouldValidateVpnSettings) return false;
              return this.isStaticBandwidthDistribution;
            }),
          },
        },
        qos: {
          new: {
            required: requiredIf(function () {
              if (this.isChangeOrder) return false;
              if (!this.shouldValidateVpnSettings) return false;
              return this.isStaticBandwidthDistribution;
            }),
          },
        },
        rt1: {
          new: {
            numeric: requiredIf(function (rt1) {
              if (this.isChangeOrder) return false;
              if (!this.shouldValidateVpnSettings) return false;
              if (!this.shouldShowRtBandwidth(rt1.vpnKey)) return false;
              return this.isStaticBandwidthDistribution;
            }),
          },
        },
        rt2: {
          new: {
            numeric: requiredIf(function (rt2) {
              if (this.isChangeOrder) return false;
              if (!this.shouldValidateVpnSettings) return false;
              if (!this.shouldShowRt2(rt2.vpnKey)) return false;
              return this.isStaticBandwidthDistribution;
            }),
          },
        },
        lans: {
          hasAtLeastOne: (lans, vpn) => vpn.action !== Actions.ADD || lans.length > 0,
        },
      },
    },
    lans: {
      $each: {
        $trackBy: "key",
        vlanId: {
          // TODO: Check if vlanId is unique in vpn
          current: {
            required: requiredIf(function (vlanId) {
              if (this.isPhysicalInterface) return false;
              return this.lans.find((lan) => lan.key === vlanId.lanKey).action !== Actions.ADD;
            }),
            isValid: function (value) {
              if (!value) return true;
              return VALID_VLAN_ID_REGEX.test(value);
            },
          },
          new: {
            required: requiredIf(function (vlanId) {
              if (this.isPhysicalInterface) return false;
              const lan = this.lans.find((lan) => lan.key === vlanId.lanKey);
              if (lan.action === Actions.ADD) return true;
              return lan.action !== Actions.DELETE && vlanId.action === Actions.UPDATE;
            }),
            isValid: function (value) {
              if (!value) return true;
              return VALID_VLAN_ID_REGEX.test(value);
            },
          },
        },
        identifyingNetwork: {
          input: {
            new: {
              required: requiredIf(function (input) {
                const lan = this.lans.find((lan) => lan.key === input.lanKey);
                return this.isPhysicalInterface && lan.action !== Actions.ADD;
              }),
            },
          },
          ipAddress: {
            new: {
              required: requiredIf(function (ipAddress) {
                const lan = this.lans.find((lan) => lan.key === ipAddress.lanKey);
                return this.isPhysicalInterface && lan.action !== Actions.ADD;
              }),
            },
          },
        },
        networks: {
          hasAtLeastOne: function (networks, lan) {
            if (lan.action !== Actions.ADD) return true;
            return networks.length > 0;
          },
          $each: {
            input: {
              current: {
                required: requiredIf(function (input) {
                  return input.action !== Actions.ADD;
                }),
              },
              new: {
                required: requiredIf(function (input) {
                  return input.action !== Actions.DELETE;
                }),
              },
            },
            virtualIp: {
              current: {
                required: requiredIf(function (virtualIp) {
                  if (!this.hasBackupAccess || !this.hasBackupTypeBackup) return false;
                  return virtualIp.action !== Actions.ADD;
                }),
              },
              new: {
                required: requiredIf(function (virtualIp) {
                  if (!this.hasBackupAccess || !this.hasBackupTypeBackup) return false;
                  return virtualIp.action !== Actions.DELETE;
                }),
              },
            },
          },
        },
        dhcpRelays: {
          $each: {
            validCurrentIp: function (dhcpRelayKey) {
              const ip = this.ips[dhcpRelayKey];
              const { vpn: vpnKey } = this.getLan(ip.ownerKey);
              const { isIncludedInBackup } = this.getVpn(vpnKey);
              if (!isIncludedInBackup) return true;
              if (ip.action !== Actions.ADD && !ip.ipAddress.current) return false;
              return true;
            },
            validNewIp: function (dhcpRelayKey) {
              const ip = this.ips[dhcpRelayKey];
              const { vpn: vpnKey } = this.getLan(ip.ownerKey);
              const { isIncludedInBackup } = this.getVpn(vpnKey);
              if (!isIncludedInBackup) return true;
              if (ip.action !== Actions.DELETE && !ip.ipAddress.new) return false;
              return true;
            },
          },
        },
      },
    },
    dhcpServers: {
      required: requiredIf(function () {
        return this.isMandatory(MetadataKeys.DHCP_SERVERS);
      }),
      $each: {
        $trackBy: "key",
        network: {
          input: {
            current: {
              required: requiredIf(function (input) {
                return this.getDhcpServerAction(input.serverKey) !== Actions.ADD;
              }),
              validNetwork: async function (value) {
                if (!value) {
                  return true;
                }

                const isValid = await corpNetwork(value, this.scopeId);
                return isValid;
              },
            },
            new: {
              required: requiredIf(function (input) {
                return this.getDhcpServerAction(input.serverKey) !== Actions.DELETE;
              }),
              validNetwork: async function (value) {
                if (!value) {
                  return true;
                }

                const isValid = await corpNetwork(value, this.scopeId);
                return isValid;
              },
            },
          },
        },
        excludedIpRanges: {
          values: {
            $each: {
              start: {
                ipAddress: {
                  current: {
                    required: requiredIf(function (ipAddress) {
                      return (
                        this.getDhcpServerAction(ipAddress.serverKey) !== Actions.ADD &&
                        ipAddress.action !== Actions.ADD
                      );
                    }),
                    validIpAddress: async function (value) {
                      if (!value) {
                        return true;
                      }

                      const isValid = await corpIpAddress(value, this.scopeId);
                      return isValid;
                    },
                  },
                  new: {
                    required: requiredIf(function (ipAddress) {
                      return (
                        this.getDhcpServerAction(ipAddress.serverKey) !== Actions.DELETE &&
                        ipAddress.action !== Actions.DELETE
                      );
                    }),
                    validIpAddress: async function (value) {
                      if (!value) {
                        return true;
                      }

                      const isValid = await corpIpAddress(value, this.scopeId);
                      return isValid;
                    },
                  },
                },
              },
              end: {
                ipAddress: {
                  current: {
                    required: requiredIf(function (ipAddress) {
                      return (
                        this.getDhcpServerAction(ipAddress.serverKey) !== Actions.ADD &&
                        ipAddress.action !== Actions.ADD
                      );
                    }),
                    validIpAddress: async function (value) {
                      if (!value) {
                        return true;
                      }

                      const isValid = await corpIpAddress(value, this.scopeId);
                      return isValid;
                    },
                  },
                  new: {
                    required: requiredIf(function (ipAddress) {
                      return (
                        this.getDhcpServerAction(ipAddress.serverKey) !== Actions.DELETE &&
                        ipAddress.action !== Actions.DELETE
                      );
                    }),
                    validIpAddress: async function (value) {
                      if (!value) {
                        return true;
                      }

                      const isValid = await corpIpAddress(value, this.scopeId);
                      return isValid;
                    },
                  },
                },
              },
            },
          },
        },
        dnsIps: {
          values: {
            $each: {
              ipAddress: {
                current: {
                  required: requiredIf(function (ipAddress) {
                    return (
                      this.getDhcpServerAction(ipAddress.serverKey) !== Actions.ADD &&
                      ipAddress.action !== Actions.ADD
                    );
                  }),
                  validIpAddress: async function (value) {
                    if (!value) {
                      return true;
                    }

                    const isValid = await corpIpAddress(value, this.scopeId);
                    return isValid;
                  },
                },
                new: {
                  required: requiredIf(function (ipAddress) {
                    return (
                      this.getDhcpServerAction(ipAddress.serverKey) !== Actions.DELETE &&
                      ipAddress.action !== Actions.DELETE
                    );
                  }),
                  validIpAddress: async function (value) {
                    if (!value) {
                      return true;
                    }

                    const isValid = await corpIpAddress(value, this.scopeId);
                    return isValid;
                  },
                },
              },
            },
          },
        },
      },
    },
    staticRoutes: {
      $each: {
        $trackBy: "key",
        network: {
          input: {
            current: {
              required: requiredIf(function (input) {
                const routeAction = this.getStaticRouteAction(input.routeKey);
                return (
                  this.isChangeOrder &&
                  this.shouldValidateVpnSettings &&
                  (routeAction === Actions.UPDATE || routeAction === Actions.DELETE)
                );
              }),
              validNetwork: async function (value, input) {
                const routeAction = this.getStaticRouteAction(input.routeKey);
                const isRequired =
                  this.isChangeOrder &&
                  this.shouldValidateVpnSettings &&
                  (routeAction === Actions.UPDATE || routeAction === Actions.DELETE);

                if (!isRequired && !input.current) {
                  return true;
                }

                const isValid = await corpNetwork(input.current, this.scopeId);
                return isValid;
              },
            },
            new: {
              required: requiredIf(function (input) {
                const routeAction = this.getStaticRouteAction(input.routeKey);
                return (
                  this.shouldValidateVpnSettings &&
                  (routeAction === Actions.ADD || routeAction === Actions.UPDATE)
                );
              }),
              validNetwork: async function (value, input) {
                const routeAction = this.getStaticRouteAction(input.routeKey);
                const isRequired =
                  this.shouldValidateVpnSettings &&
                  (routeAction === Actions.ADD || routeAction === Actions.UPDATE);

                if (!isRequired && !input.new) {
                  return true;
                }

                const isValid = await corpNetwork(input.new, this.scopeId);
                return isValid;
              },
            },
          },
        },
        nextHop: {
          isMemberOfLanNetwork: {
            isMember: function (isMemberOfLanNetwork, nextHop) {
              if (!this.shouldValidateVpnSettings) return true;
              if (this.isChangeOrder) return true;
              if (!nextHop.ipAddress.new) return true;
              return isMemberOfLanNetwork === true;
            },
          },
          ipAddress: {
            current: {
              required: requiredIf(function (ipAddress) {
                const routeAction = this.getStaticRouteAction(ipAddress.routeKey);
                return (
                  this.isChangeOrder &&
                  this.shouldValidateVpnSettings &&
                  (routeAction === Actions.UPDATE || routeAction === Actions.DELETE)
                );
              }),
              validIpAddress: async function (value, ipAddress) {
                const routeAction = this.getStaticRouteAction(ipAddress.routeKey);
                const isRequired =
                  this.isChangeOrder &&
                  this.shouldValidateVpnSettings &&
                  (routeAction.action === Actions.UPDATE || routeAction.action === Actions.DELETE);

                if (!isRequired && !ipAddress.current) {
                  return true;
                }

                const isValid = await corpIpAddress(ipAddress.current, this.scopeId);
                return isValid;
              },
            },
            new: {
              required: requiredIf(function (ipAddress) {
                const routeAction = this.getStaticRouteAction(ipAddress.routeKey);
                return (
                  this.shouldValidateVpnSettings &&
                  (routeAction === Actions.ADD || routeAction === Actions.UPDATE)
                );
              }),
              validIpAddress: async function (value, ipAddress) {
                const routeAction = this.getStaticRouteAction(ipAddress.routeKey);
                const isRequired =
                  this.shouldValidateVpnSettings &&
                  (routeAction.action === Actions.ADD || routeAction.action === Actions.UPDATE);

                if (!isRequired && !ipAddress.new) {
                  return true;
                }

                const isValid = await corpIpAddress(ipAddress.new, this.scopeId);
                return isValid;
              },
            },
          },
        },
      },
    },
  },

  async created() {
    this.setDefaultSelected();
  },

  methods: {
    ...mapActions("access", {
      addVpnToAccess: "addVpn",
      removeVpnFromAccess: "removeVpn",
    }),

    ...mapActions("vpn", ["addVpn", "deleteVpn"]),

    ...mapActions("staticRoutes", ["addRoute", "deleteRoute"]),

    ...mapActions("lans", ["addLan", "deleteLan"]),

    ...mapActions("dhcpServer", ["addServer", "deleteServer"]),

    canDeleteLan(index) {
      if (this.isChangeOrder) return this.isPrimaryAccessVpn;
      else return index > 0 && this.isPrimaryAccessVpn;
    },

    async $validate(v) {
      v = v || this.$v;
      v.$touch();
      await this.$nextTick();
      let unwatch = null;
      return new Promise((resolve) => {
        unwatch = this.$watch(
          () => v.$pending,
          (isPending) => {
            if (!isPending) {
              resolve(!v.$invalid);
              if (unwatch) {
                unwatch();
              }
            }
          },
          {
            immediate: true,
          }
        );
      });
    },

    async validate() {
      if (this.accessKey === Accesses.PRIMARY) {
        await this.$validate(this.$v);
        return !this.$v.$invalid;
      } else {
        this.$v.vpns.$touch();

        const includedVpns = Object.values(this.$v.vpns.$each.$iter);
        includedVpns.forEach((vpn) => {
          if (vpn.$model.isIncludedInBackup) {
            vpn.$touch();
          } else {
            vpn.$reset();
          }
        });

        const includedLans = Object.values(this.$v.lans.$each.$iter);
        includedLans.forEach((lan) => {
          const vpn = this.getVpn(lan.$model.vpn);
          if (vpn.isIncludedInBackup) {
            lan.$touch();
          } else {
            lan.$reset();
          }
        });

        const includedDhcpServers = Object.values(this.$v.dhcpServers.$each.$iter);
        includedDhcpServers.forEach((dhcpServer) => {
          const vpn = this.getVpn(dhcpServer.$model.vpn);
          if (vpn.isIncludedInBackup) {
            dhcpServer.$touch();
          } else {
            dhcpServer.$reset();
          }
        });

        const includedStaticRoutes = Object.values(this.$v.staticRoutes.$each.$iter);
        includedStaticRoutes.forEach((staticRoute) => {
          const vpn = this.getVpn(staticRoute.$model.vpn);
          if (vpn.isIncludedInBackup) {
            staticRoute.$touch();
          } else {
            staticRoute.$reset();
          }
        });

        // await flushPromises();

        if (!this.$v.vpns.hasLessVpnsThanMax) {
          return false;
        }

        if (!this.$v.vpns.hasVpnsIncludedInBackup) {
          return false;
        }

        if (!this.$v.vpns.hasAtLeastOneDhcpRelay) {
          return false;
        }

        if (!this.$v.vpns.hasAtLeastOneDhcpServer) {
          return false;
        }

        for (const vpn of includedVpns) {
          if (vpn.$error) {
            return false;
          }
        }

        for (const lan of includedLans) {
          if (lan.$error) {
            return false;
          }
        }

        for (const dhcpServer of includedDhcpServers) {
          if (dhcpServer.$error) {
            return false;
          }
        }

        for (const staticRoute of includedStaticRoutes) {
          if (staticRoute.$error) {
            return false;
          }
        }

        return true;
      }
    },

    setDefaultSelected() {
      this.selectedVpnIndex = DEFAULT_VPN_INDEX;
      this.selectNone();
    },

    vpnContainsAnyError(index) {
      if (this.$v.vpns.$each.$iter[index].$anyError) {
        return true;
      }

      const vpnLans = this.$v.vpns.$each.$iter[index].$model.lans;
      for (let i = 0; i < vpnLans.length; i++) {
        const lanValidationObject = this.getLanValidationObject(vpnLans[i]);
        if (lanValidationObject.$anyError) {
          return true;
        }
      }

      const vpnDhcpServers = this.$v.vpns.$each.$iter[index].$model.dhcpServers;
      for (let i = 0; i < vpnDhcpServers.length; i++) {
        const dhcpServerValidationObject = this.getDhcpServerValidationObject(vpnDhcpServers[i]);
        if (dhcpServerValidationObject.$anyError) {
          return true;
        }
      }

      const vpnStaticRoutes = this.$v.vpns.$each.$iter[index].$model.staticRoutes;
      for (let i = 0; i < vpnStaticRoutes.length; i++) {
        const staticRouteValidationObject = this.getStaticRouteValidationObject(vpnStaticRoutes[i]);
        if (staticRouteValidationObject.$anyError) {
          return true;
        }
      }

      return false;
    },

    getVpnValidationObject(index) {
      return this.$v.vpns.$each.$iter[index];
    },

    getLanValidationObject(key) {
      const index = Object.values(this.$v.lans.$each.$iter).findIndex((v) => {
        return v.$model.key === key;
      });

      return this.$v.lans.$each.$iter[index];
    },

    getDhcpServerValidationObject(key) {
      const index = Object.values(this.$v.dhcpServers.$each.$iter).findIndex((v) => {
        return v.$model.key === key;
      });

      return this.$v.dhcpServers.$each.$iter[index];
    },

    getStaticRouteValidationObject(key) {
      const index = Object.values(this.$v.staticRoutes.$each.$iter).findIndex((v) => {
        return v.$model.key === key;
      });

      return this.$v.staticRoutes.$each.$iter[index];
    },

    selectNone() {
      this.selectedLanIndex = UNSELECTED;
      this.selectedDhcpServerIndex = UNSELECTED;
      this.selectedStaticRouteIndex = UNSELECTED;
      this.selectedSetting = settings.BASE;
    },

    selectVpn(index) {
      this.selectedVpnIndex = index;
      this.selectBaseSettings();
    },

    selectBaseSettings() {
      this.selectedLanIndex = UNSELECTED;
      this.selectedDhcpServerIndex = UNSELECTED;
      this.selectedStaticRouteIndex = UNSELECTED;
      this.selectedSetting = settings.BASE;
    },

    selectLan(index) {
      this.selectedLanIndex = index;
      this.selectedDhcpServerIndex = UNSELECTED;
      this.selectedStaticRouteIndex = UNSELECTED;
      this.selectedSetting = settings.LAN;
    },

    selectDhcpServer(index) {
      this.selectedLanIndex = UNSELECTED;
      this.selectedDhcpServerIndex = index;
      this.selectedStaticRouteIndex = UNSELECTED;
      this.selectedSetting = settings.DHCP_SERVER;
    },

    selectStaticRoute(index) {
      this.selectedLanIndex = UNSELECTED;
      this.selectedDhcpServerIndex = UNSELECTED;
      this.selectedStaticRouteIndex = index;
      this.selectedSetting = settings.STATIC_ROUTE;
    },

    handleDeleteVpn(index) {
      if (confirm(this.t("DATANET_VPN_DELETE_VPN"))) {
        this.deleteVpn(this.vpns[index].key);

        if (this.selectedVpnIndex === index) {
          this.selectVpn(DEFAULT_VPN_INDEX);
        }

        // TODO: Move to store
        if (!this.isFunctionBusinessOrder) {
          // RT Tot is never editable for FB orders. Skip updating RT tot.
          this.$store.dispatch("vpn/setRtTot", {
            accessKey: this.accessKey,
            new: this.$store.getters["vpn/getTotalDistributedRtBandwidth"](this.accessKey),
          });
        }
      }
    },

    handleDeleteLan(index) {
      if (confirm(this.t("DATANET_VPN_DELETE_LAN"))) {
        this.deleteLan(this.lans[index].key);
      }
    },

    handleDeleteDhcpServer(index) {
      if (confirm(this.t("DATANET_VPN_DELETE_SERVER"))) {
        this.deleteServer(this.dhcpServers[index].key);
      }
    },

    handleDeleteStaticRoute(index) {
      if (confirm(this.t("DATANET_VPN_DELETE_STATIC_ROUTE"))) {
        this.deleteRoute(this.staticRoutes[index].key);
      }
    },

    getLanInterfaceOptions() {
      let lanInterfaceOptions = [];

      for (let i = 1; i <= MAXIMUM_NUMBER_OF_LAN_INTERFACES; i++) {
        lanInterfaceOptions.push({
          id: i,
          value: i,
          label: i,
          text: i,
        });
      }

      return JSON.stringify(lanInterfaceOptions);
    },

    toggleBackupVpnInclusion(vpnKey) {
      // TODO: Move to store. Add/remove from access in set included action
      this.isIncludedInBackup(vpnKey)
        ? this.removeVpnFromAccess({
            accessKey: Accesses.SECONDARY,
            vpnKey,
          })
        : this.addVpnToAccess({
            accessKey: Accesses.SECONDARY,
            vpnKey,
          });

      this.$store.dispatch("vpn/setIsIncludedInBackup", {
        vpnKey,
        isIncludedInBackup: !this.isIncludedInBackup(vpnKey),
      });

      const vpnValidationObjects = Object.values(this.$v.vpns.$each.$iter);
      const toggledVpnValidationObject = vpnValidationObjects.find(
        (vpn) => vpn.$model.key === vpnKey
      );
      toggledVpnValidationObject.$reset();

      const allLanValidationObjects = Object.values(this.$v.lans.$each.$iter);
      allLanValidationObjects.forEach((lan) => {
        if (toggledVpnValidationObject.$model.lans.includes(lan.$model.key)) {
          lan.$reset();
        }
      });

      const allStaticRouteValidationObjects = Object.values(this.$v.staticRoutes.$each.$iter);
      allStaticRouteValidationObjects.forEach((staticRoute) => {
        if (toggledVpnValidationObject.$model.staticRoutes.includes(staticRoute.$model.key)) {
          staticRoute.$reset();
        }
      });

      const allDhcpServerValidationObjects = Object.values(this.$v.dhcpServers.$each.$iter);
      allDhcpServerValidationObjects.forEach((dhcpServer) => {
        if (toggledVpnValidationObject.$model.dhcpServers.includes(dhcpServer.$model.key)) {
          dhcpServer.$reset();
        }
      });
    },
    setActiveTooltip(value) {
      if (value === this.activeTooltip) {
        this.activeTooltip = null;
        return;
      }
      this.activeTooltip = value;
    },
  },

  watch: {
    selectedLan: function (newValue, oldValue) {
      if (newValue === undefined) {
        this.selectNone();
      }
      if (oldValue && oldValue?.id !== newValue?.id) {
        this.setActiveTooltip(null);
      }
    },
    selectedDhcpServer: function (newValue) {
      if (newValue === undefined) {
        this.selectNone();
      }
      this.setActiveTooltip(null);
    },
    selectedStaticRoute: function (newValue) {
      if (newValue === undefined) {
        this.selectNone();
      }
      this.setActiveTooltip(null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables";

.container {
  display: flex;
  border: 0.1rem solid $telia-gray-500;
  border-radius: 0.2rem;
}

.input-container {
  display: inline-block;
  width: 100%;
  max-width: 38rem;
}

.settings-container {
  padding: $telia-spacing-12;
  flex: 1;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  border-right: 1px solid $telia-gray-500;
}

.column:last-child {
  border-right: 0;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-item {
  padding: $telia-spacing-12 $telia-spacing-24;
  min-width: 17rem;
  cursor: pointer;
}

.list-item:hover {
  background-color: $telia-gray-50;
  cursor: pointer;
}

.list-item:last-child {
  margin-bottom: 0;
}

.list-item-active {
  color: $telia-black;
  background-color: $telia-gray-500 !important;
  font-weight: bold;
}

.list-item-error {
  color: $telia-red-200;
}

.list-item-larger {
  min-width: 30rem;
}

.add {
  display: flex;
  cursor: pointer;
}

.add:hover {
  background: $telia-gray-50;
}

.add-icon {
  display: flex;
  padding: 0.4rem 0;
  fill: $telia-black;
}

.add-icon svg {
  width: 1.6rem;
  height: 1.6rem;
}

.add-icon-error {
  fill: $telia-red-200;
}

.add-text {
  flex: 1;
}

.error {
  display: flex;
  color: $telia-red-500;
}

.error-icon {
  display: flex;
  padding-right: $telia-spacing-8;
  fill: $telia-red-500;
}

.error-icon svg {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  margin: auto;
}

.separator {
  margin: $telia-spacing-12 $telia-spacing-24;
  border-bottom: 0.1rem solid $telia-gray-500;
}

.padding-right {
  padding-right: $telia-spacing-12;
}

.padding-left {
  padding-left: $telia-spacing-12;
}

.no-margin {
  margin: 0;
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
  color: $telia-black;
}
.margin {
  &__bottom {
    &__16 {
      margin-bottom: $telia-spacing-16;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
    &__32 {
      margin-bottom: $telia-spacing-32;
    }
  }
}
</style>
