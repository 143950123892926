import { MetadataKeys } from "../../constants";
import { t } from "../../locale";
const state = defaultState();

function defaultState() {
  return {
    order: defaultContact("order"),
    technical: defaultContact("technical"),
    facility: defaultContact("facility"),
    installation: defaultContact("installation"),
    delivery: defaultContact("delivery"),
    wirelessTechnical: defaultContact("wirelessTechnical"),
    wirelessSms: defaultContact("wirelessSms"),
    secondaryFacility: defaultContact("secondaryFacility"),
    secondaryInstallation: defaultContact("secondaryInstallation"),
  };
}

function defaultContact(id = "") {
  return {
    id,
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
  };
}

const getters = {
  hasOrderContact: (state) => !!state.order.emailAddress,

  getContact: (state) => (id = "") => state[id] || null,

  getAllContacts: (state) => Object.values(state),

  getContactOptions: (state) => (contactIds = []) => {
    const contacts = Object.values(state)
      .slice(0)
      .filter((contact) => contactIds.includes(contact.id))
      .map((contact) => ({
        value: contact.id,
        label: `${contact.firstName} ${contact.lastName} (${t(
          "DATANET_" + contact.id.toUpperCase() + "_CONTACT"
        ).toLowerCase()})`,
      }));

    contacts.splice(0, 0, {
      value: "",
      label: t("DATANET_SELECT_CONTACT_PERSON"),
    });

    return contacts;
  },

  includeContactConfigurationStep: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.CONTACT_PERSONS),
};

const actions = {
  setContact({ dispatch }, { id = "", contact = {} }) {
    dispatch("setFirstName", { id, firstName: contact.firstName || "" });
    dispatch("setLastName", { id, lastName: contact.lastName || "" });
    dispatch("setEmailAddress", {
      id,
      emailAddress: contact.emailAddress || "",
    });
    dispatch("setPhoneNumber", {
      id,
      phoneNumber: contact.phoneNumber || "",
    });
  },

  setFirstName({ dispatch, commit }, { id = "", firstName = "" }) {
    commit("SET_FIRST_NAME", { id, firstName });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setLastName({ dispatch, commit }, { id = "", lastName = "" }) {
    commit("SET_LAST_NAME", { id, lastName });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setEmailAddress({ dispatch, commit }, { id = "", emailAddress = "" }) {
    commit("SET_EMAIL_ADDRESS", { id, emailAddress });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setPhoneNumber({ dispatch, commit }, { id = "", phoneNumber = "" }) {
    commit("SET_PHONE_NUMBER", { id, phoneNumber });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },
};

const mutations = {
  SET_FIRST_NAME(state, { id = "", firstName = "" }) {
    state[id].firstName = firstName;
  },

  SET_LAST_NAME(state, { id = "", lastName = "" }) {
    state[id].lastName = lastName;
  },

  SET_EMAIL_ADDRESS(state, { id = "", emailAddress = "" }) {
    state[id].emailAddress = emailAddress;
  },

  SET_PHONE_NUMBER(state, { id = "", phoneNumber = "" }) {
    state[id].phoneNumber = phoneNumber;
  },

  RESET_STATE(state) {
    Object.assign(state.technical, defaultContact("technical"));
    Object.assign(state.facility, defaultContact("facility"));
    Object.assign(state.installation, defaultContact("installation"));
    Object.assign(state.delivery, defaultContact("delivery"));
    Object.assign(state.wirelessTechnical, defaultContact("wirelessTechnical"));
    Object.assign(state.wirelessSms, defaultContact("wirelessSms"));
    Object.assign(state.secondaryFacility, defaultContact("secondaryFacility"));
    Object.assign(state.secondaryInstallation, defaultContact("secondaryInstallation"));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
