import Vue from "vue";
import Vuex from "vuex";
import { corpDatanetService, corpPrecheckService } from "@telia/b2b-rest-client";
import { Products, BusinessAreas, OrderTypes, Bearers } from "../constants";
import * as modules from "./modules";
import modelStoreDataToOrderData from "../helpers/modelStoreDataToOrderData.js";
import populateStoreWithOrderData from "../helpers/populateStoreWithOrderData.js";

Vue.use(Vuex);

function defaultState() {
  return {
    product: Products.DATANET,
    businessArea: "",
    orderType: "",
    accessId: "",
    orderCaptureId: "",
    orderAlias: "",
    version: 0,
    createdBy: "",
    currentStep: 1,
    isUnsavedDraft: false,
    isShared: false,
    returnUrl: "",
    showAdvancedSettings: true,
  };
}

export const store = new Vuex.Store({
  state: defaultState(),

  modules,

  getters: {
    isUnsavedOrder: (state) => !state.orderCaptureId,
    isNewOrder: (state) => state.orderType === OrderTypes.NEW,
    isChangeOrder: (state) => state.orderType === OrderTypes.CHANGE,
    isProductBusinessOrder: (state) => state.businessArea === BusinessAreas.PRODUCT,
    isFunctionBusinessOrder: (state) => state.businessArea === BusinessAreas.FUNCTION,
    getShowAdvancedSettings: (state) => state.showAdvancedSettings,
    getOrderAlias: (state) => state.orderAlias,
    getVersion: (state) => state.version,
  },

  actions: {
    setState: ({ commit }, state = {}) => {
      commit("SET_STATE", state);
    },

    setSharedOrder: ({ commit }) => {
      commit("SET_UNSAVED_DRAFT", true);
      commit("SET_SHARED_ORDER", true);
    },

    setUnsharedOrder: ({ commit }) => {
      commit("SET_UNSAVED_DRAFT", true);
      commit("SET_SHARED_ORDER", false);
    },

    setUnsavedDraft: ({ state, commit }) => {
      if (state.isUnsavedDraft) {
        return;
      }

      commit("SET_UNSAVED_DRAFT", true);
    },

    setSavedDraft: ({ state, commit }) => {
      if (!state.isUnsavedDraft) {
        return;
      }

      commit("SET_UNSAVED_DRAFT", false);
    },

    setAsNewOrder: ({ commit }) => {
      commit("SET_ORDER_TYPE", OrderTypes.NEW);
    },

    setAsChangeOrder: ({ commit }) => {
      commit("SET_ORDER_TYPE", OrderTypes.CHANGE);
    },

    setAsProductBusinessArea: ({ commit }) => {
      commit("SET_BUSINESS_AREA", BusinessAreas.PRODUCT);
    },

    setAsFunctionBusinessArea: ({ commit }) => {
      commit("SET_BUSINESS_AREA", BusinessAreas.FUNCTION);
    },

    setOrderCaptureId: ({ state, commit }, orderCaptureId = "") => {
      if (state.orderCaptureId === orderCaptureId) {
        return;
      }

      commit("SET_ORDER_CAPTURE_ID", orderCaptureId);
    },

    setCreatedBy: ({ commit }, userId = "") => {
      commit("SET_CREATED_BY", userId);
    },

    setStep: ({ commit }, step = 0) => {
      commit("SET_STEP", step);
    },

    incrementStep: ({ commit }) => {
      commit("INCREMENT_STEP");
    },

    showExtraAccessOptions: ({ commit }) => {
      commit("SET_SHOW_ADVANCED_SETTINGS", true);
    },

    hideExtraAccessOptions: ({ commit }) => {
      commit("SET_SHOW_ADVANCED_SETTINGS", false);
    },

    setOrderAlias: ({ commit }, alias = "") => {
      commit("SET_ORDER_ALIAS", alias);
    },

    setupStoreWithOrderData: async ({ state, getters, rootState, rootGetters, dispatch }) => {
      const scopeId = rootState.user.scopeId;
      const userId = rootState.user.userId;
      const urlOrderTypeParams = {
        PRODUCT: "oc",
        FUNCTION: "fb",
      };

      await dispatch("user/getOrganisations", { root: true });

      // Remove potential query string and get last path name
      const pathNames = window.location.pathname.split("?")[0].split("/");
      const lastPathName = pathNames[pathNames.length - 1];

      const params = new URLSearchParams(window.location.search.substring(1));
      const tscid = params.get("tscid");
      const org = getters["user/getOrganisationByTscid"](tscid);

      await Promise.all([dispatch("user/getExistingVpns", scopeId, { root: true })]);

      try {
        if (org) {
          dispatch("user/setOrganisation", org.orgNr, { root: true });
          const streetName = params.get("streetName");
          const streetNumber = params.get("streetNumber");
          const postalCode = params.get("postalCode");
          const city = params.get("city");
          const entrance = params.get("entrance");
          const xCoordinate = params.get("x");
          const yCoordinate = params.get("y");

          const address = {
            city,
            streetName,
            streetNumber,
            postalCode,
            entrance,
            x: xCoordinate,
            y: yCoordinate,
          };
          dispatch("addresses/addAddress", {
            addressId: "installation",
            newAddress: address,
          });
          let products;
          if (xCoordinate && yCoordinate) {
            products = await fetchProductsFromPreCheckByCoordinates(
              {
                x: xCoordinate,
                y: yCoordinate,
              },
              scopeId
            );
          } else if (streetName && streetNumber && city) {
            products = await fetchProductsFromPreCheckByAddress(
              {
                city,
                entrance,
                streetName,
                streetNumber,
              },
              scopeId
            );
          }
          if (products) {
            dispatch("products/setProducts", products);
            dispatch("setStep", 3);
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.debug(e);
      }

      // Check if last path name is an order type
      if (Object.values(urlOrderTypeParams).includes(lastPathName)) {
        // It is a new order
        switch (lastPathName) {
          case urlOrderTypeParams.PRODUCT:
            dispatch("setAsProductBusinessArea");
            break;
          case urlOrderTypeParams.FUNCTION:
            dispatch("setAsFunctionBusinessArea");
            break;
          default:
            if (process.env.NODE_ENV === "development") {
              dispatch("setAsProductBusinessArea");
            } else {
              throw new Error("DATANET_UNKNOWN_ORDER_TYPE");
            }
        }
        dispatch("contactPersons/setContact", {
          id: "order",
          contact: {
            firstName: state.user.firstName,
            lastName: state.user.lastName,
            emailAddress: state.user.email,
            phoneNumber: state.user.phone,
          },
        });
        dispatch("setAsNewOrder");
        dispatch("setCreatedBy", userId);
        // Leave function since there is no order data to fetch
        return;
      }

      // The last path name is an id, so fetch order data
      const id = lastPathName;
      const orderType = pathNames[pathNames.length - 2];
      switch (orderType) {
        case urlOrderTypeParams.PRODUCT:
          dispatch("setAsProductBusinessArea");
          break;
        case urlOrderTypeParams.FUNCTION:
          dispatch("setAsFunctionBusinessArea");
          break;
        default:
          if (process.env.NODE_ENV === "development") {
            dispatch("setAsProductBusinessArea");
          } else {
            throw new Error("DATANET_UNKNOWN_ORDER_TYPE");
          }
      }

      let orderData;
      try {
        orderData = await getDataNetOrderData(state.businessArea, scopeId, id);
      } catch (e) {
        throw new Error(e);
      }

      dispatch("setOrderCaptureId", orderData.orderCaptureId);
      dispatch("metadata/setMetadata", orderData.metadata.sectionMetadata);

      switch (orderData.orderType) {
        case OrderTypes.NEW:
          dispatch("setAsNewOrder");
          break;
        case OrderTypes.CHANGE:
          dispatch("setAsChangeOrder");
          break;
        default:
          throw new Error("DATANET_UNKNOWN_ORDER_TYPE");
      }

      // Set logged-in user as order contact if this is the first version of the order
      if (orderData.version === 0) {
        if (getters.isProductBusinessOrder) {
          dispatch("contactPersons/setContact", {
            id: "order",
            contact: {
              firstName: state.user.firstName,
              lastName: state.user.lastName,
              emailAddress: state.user.email,
              phoneNumber: state.user.phone,
            },
          });
        }
      }

      let preCheckData;
      try {
        preCheckData = await validateDataNetOrderDataAndGetPreCheckData(
          state.businessArea,
          state.orderType,
          orderData,
          scopeId
        );
      } catch (e) {
        throw new Error("DATANET_COULD_NOT_VALIDATE_ORDER_DATA");
      }

      const isInvalidOrder = !preCheckData.isValid;
      if (getters.isProductBusinessOrder && getters.isNewOrder && isInvalidOrder) {
        throw new Error("DATANET_INVALID_ORDER");
      }

      let products;
      switch (state.businessArea) {
        case BusinessAreas.PRODUCT:
          await dispatch("products/setProducts", preCheckData.fixedNetResponse);
          products = getters["products/getProducts"];
          if (products.orderCaptureAccesses[Bearers.FTTX]) {
            dispatch(
              "apartments/setApartments",
              products.orderCaptureAccesses[Bearers.FTTX].fttxApartments
            );
          }
          break;
        case BusinessAreas.FUNCTION:
          await dispatch("products/setProducts", {
            precheckAccess: {},
            ...preCheckData,
          });

          try {
            const products = getters["products/getProducts"];
            const selectedBearer = orderData.data.access.primary.accessType.bearer;
            dispatch(
              "apartments/setApartments",
              products.orderCaptureAccesses[selectedBearer].fttxApartments
            );
          } catch (e) {
            // eslint-disable-next-line no-console
            console.debug(e);
          }

          break;
        default:
          throw new Error("DATANET_UNKNOWN_ORDER_TYPE");
      }

      if (orderData.orderType === OrderTypes.NEW && state.businessArea === BusinessAreas.PRODUCT) {
        try {
          const orgNr = orderData.data.organisation.orgNumber;
          const selectedTscid = rootGetters["user/getOrganisation"](orgNr).tscid;
          await dispatch(
            "invoice/getBillingAccounts",
            { scopeId, tscid: selectedTscid },
            { root: true }
          );
          const accountNumber = orderData.data.invoice.invoiceAccount;
          await dispatch(
            "invoice/getBillingAccountAddress",
            { scopeId, tscid: selectedTscid, accountNumber },
            { root: true }
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.debug(e);
        }
      }

      try {
        await populateStoreWithOrderData(store, orderData);
      } catch (e) {
        throw new Error("DATANET_COULD_NOT_POPULATE_FORM_WITH_ORDER_DATA");
      }
    },

    submitOrder: async function ({ state, rootState }, { businessArea = "", orderId = "" }) {
      const scopeId = rootState.user.scopeId;

      let orderBody;
      try {
        orderBody = modelStoreDataToOrderData(state);
      } catch (e) {
        throw Error(e);
      }

      try {
        const orderType = state.orderType;

        switch (businessArea) {
          case BusinessAreas.PRODUCT:
            if (orderType === OrderTypes.NEW) {
              await submitProductNewOrder(scopeId, orderId, orderBody);
            } else if (orderType === OrderTypes.CHANGE) {
              await submitProductChangeOrder(scopeId, orderId, orderBody);
            }
            break;
          case BusinessAreas.FUNCTION:
            await submitFunctionOrder(scopeId, orderId, orderBody);
            break;
        }
      } catch (e) {
        throw new Error("DATANET_COULD_NOT_SUBMIT_ORDER");
      }
    },

    resetAllStates: ({ commit }) => {
      const modulesArray = Object.keys(store._modules.root._children);

      commit("RESET_STATE");

      modulesArray.forEach((moduleKey) => {
        commit(`${moduleKey}/RESET_STATE`);
      });
    },

    resetModuleState: ({ commit }, moduleKey = "") => {
      if (!moduleKey) {
        return;
      }

      commit(`${moduleKey}/RESET_STATE`);
    },
  },

  mutations: {
    SET_STATE(state, newState = {}) {
      Object.assign(state, newState);
    },

    SET_SHARED_ORDER(state, boolean = true) {
      state.isShared = boolean;
    },

    SET_UNSAVED_DRAFT(state, boolean = true) {
      state.isUnsavedDraft = boolean;
    },

    SET_BUSINESS_AREA(state, area = "") {
      state.businessArea = area;
    },

    SET_ORDER_TYPE(state, type = "") {
      state.orderType = type;
    },

    SET_CREATED_BY(state, userId = "") {
      state.createdBy = userId;
    },

    SET_STEP(state, step = 0) {
      state.currentStep = step;
    },

    INCREMENT_STEP(state) {
      state.currentStep++;
    },

    SET_SHOW_ADVANCED_SETTINGS(state, boolean) {
      state.showAdvancedSettings = boolean;
    },

    SET_ORDER_CAPTURE_ID(state, id = "") {
      state.orderCaptureId = id;
    },

    SET_ORDER_ALIAS(state, alias = "") {
      state.orderAlias = alias;
    },

    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },
  },
});

async function getDataNetOrderData(businessArea = "", scopeId = "", id = "") {
  let orderData;
  try {
    const isOrderCaptureId = id && id.startsWith("DataNet");

    switch (businessArea) {
      case BusinessAreas.PRODUCT:
        orderData = await corpDatanetService.ExternalDatanetControllerService.getOrderCapture(
          scopeId,
          isOrderCaptureId ? "ORDER_CAPTURE_ID" : "C2BUID",
          id
        );
        break;
      case BusinessAreas.FUNCTION:
        orderData = await corpDatanetService.ExternalDatanetControllerService.getOrderCapture(
          scopeId,
          "XTAS_ID",
          id
        );
        break;
      default:
        throw new Error("DATANET_UNKNOWN_ORDER_TYPE");
    }
  } catch (e) {
    const errorData = e.body;

    const accessErrors = [
      "SPRINGFIELD_ORG_NOT_RELATED_TO_CUSTOMER",
      "SPRINGFIELD_TSCID_NOT_RELATED_TO_CUSTOMER",
      "SPRINGFIELD_DNI_NOT_RELATED_TO_CUSTOMER",
      "SPRINGFIELD_VPN_NOT_RELATED_TO_CUSTOMER",
      "SPRINGFIELD_NO_ACCESS_TO_ORDER_CAPTURE",
      "SPRINGFIELD_NO_ORDER_CAPTURE_FOUND",
    ];

    if (errorData?.errorKey === "SPRINGFIELD_NOT_IMPLEMENTED") {
      throw new Error("DATANET_NOT_YET_IMPLEMENTED");
    } else if (accessErrors.indexOf(errorData?.errorKey) > -1) {
      throw new Error(errorData?.errorKey);
    } else {
      throw new Error("DATANET_COULD_NOT_VALIDATE_ORDER_DATA");
    }
  }

  return orderData;
}

async function validateDataNetOrderDataAndGetPreCheckData(
  businessArea,
  orderType,
  orderData,
  scopeId
) {
  const { bearer, bandwidth } = orderData.data.access.primary.accessType;
  const { serviceLevelAgreement } = orderData.data.access.primary;
  const {
    streetName,
    streetNumber,
    entrance,
    city,
    apartmentNumber,
    xCoordinate: x,
    yCoordinate: y,
  } = orderData.data.addresses.installation;

  const isChangeOrder = orderType === OrderTypes.CHANGE;
  const bandwidthBody = bandwidth
    ? {
        upRateKbit: isChangeOrder ? bandwidth.upRateKbit.current : bandwidth.upRateKbit.new,
        downRateKbit: isChangeOrder ? bandwidth.downRateKbit.current : bandwidth.downRateKbit.new,
      }
    : null;

  const preCheckBody = {
    orderType: isChangeOrder ? "CHANGE" : "NEW",
    bearer: bearer || null,
    bandwidth: bandwidthBody,
    sla: serviceLevelAgreement
      ? isChangeOrder
        ? serviceLevelAgreement.current
        : serviceLevelAgreement.new
      : null,
    fttxApartmentNumber: apartmentNumber || null,
  };
  let preCheckData;
  try {
    switch (businessArea) {
      case BusinessAreas.PRODUCT:
        streetName
          ? (preCheckData = await corpPrecheckService.MybdFixedNetControllerService.validateFixedNet(
              scopeId,
              preCheckBody,
              streetName,
              city,
              streetNumber,
              entrance,
              null,
              null
            ))
          : (preCheckData = await corpPrecheckService.MybdFixedNetControllerService.validateFixedNet(
              scopeId,
              preCheckBody,
              null,
              null,
              null,
              null,
              y,
              x
            ));
        break;
      case BusinessAreas.FUNCTION:
        // eslint-disable-next-line no-case-declarations
        let filterPreCheckBandwidths = [];
        // eslint-disable-next-line no-case-declarations
        let filterPreCheckSla = [];
        // eslint-disable-next-line no-case-declarations
        let filterPreCheckQos = [];
        // eslint-disable-next-line no-case-declarations
        let filterPreCheckRtTotal = [];
        // eslint-disable-next-line no-case-declarations
        let filterPreCheckExtendedAvailability = [];

        // eslint-disable-next-line no-case-declarations
        const currentBandwidth = {
          upRateKbit: bandwidth?.upRateKbit?.current,
          downRateKbit: bandwidth?.downRateKbit?.current,
        };
        // eslint-disable-next-line no-case-declarations
        const newBandwidth = {
          upRateKbit: bandwidth?.upRateKbit?.new,
          downRateKbit: bandwidth?.downRateKbit?.new,
        };

        if (Object.values(currentBandwidth).every((val) => typeof val === "number")) {
          filterPreCheckBandwidths.push(currentBandwidth);
        }
        if (Object.values(newBandwidth).every((val) => typeof val === "number")) {
          filterPreCheckBandwidths.push(newBandwidth);
        }

        if (serviceLevelAgreement?.current) {
          filterPreCheckSla.push(serviceLevelAgreement.current);
        }
        if (serviceLevelAgreement?.new) {
          filterPreCheckSla.push(serviceLevelAgreement.new);
        }

        // eslint-disable-next-line no-case-declarations
        const { type, rtTotBandwidth } = orderData.data.access.primary.qualityOfService;

        if (type.current) {
          filterPreCheckQos.push(type.current);
        }
        if (type.new) {
          filterPreCheckQos.push(type.new);
        }

        if (rtTotBandwidth.current) {
          filterPreCheckRtTotal.push(rtTotBandwidth.current);
        }
        if (rtTotBandwidth.new) {
          filterPreCheckRtTotal.push(rtTotBandwidth.new);
        }

        // eslint-disable-next-line no-case-declarations
        const extendedAvailability = orderData.data.access.extendedAvailability;

        if (extendedAvailability?.type) {
          filterPreCheckExtendedAvailability.push(extendedAvailability.type);
        }

        // eslint-disable-next-line no-case-declarations
        let secondaryAccess = null;

        if (orderData.data.access.secondary) {
          // Secondary access
          // eslint-disable-next-line no-case-declarations
          let secondaryFilterPreCheckBandwidths = [];
          // eslint-disable-next-line no-case-declarations
          let secondaryFilterPreCheckSla = [];
          // eslint-disable-next-line no-case-declarations
          let secondaryFilterPreCheckQos = [];
          // eslint-disable-next-line no-case-declarations
          let secondaryFilterPreCheckRtTotal = [];

          // eslint-disable-next-line no-case-declarations
          const {
            bearer: secondaryBearer,
            bandwidth: secondaryBandwidth,
          } = orderData.data.access.secondary?.accessType; // eslint-disable-line no-unsafe-optional-chaining

          // eslint-disable-next-line no-case-declarations
          const currentSecondaryBandwidth = {
            upRateKbit: secondaryBandwidth?.upRateKbit?.current,
            downRateKbit: secondaryBandwidth?.downRateKbit?.current,
          };
          // eslint-disable-next-line no-case-declarations
          const newSecondaryBandwidth = {
            upRateKbit: secondaryBandwidth?.upRateKbit?.new,
            downRateKbit: secondaryBandwidth?.downRateKbit?.new,
          };

          if (Object.values(currentSecondaryBandwidth).every((val) => typeof val === "number")) {
            secondaryFilterPreCheckBandwidths.push(currentSecondaryBandwidth);
          }
          if (Object.values(newSecondaryBandwidth).every((val) => typeof val === "number")) {
            secondaryFilterPreCheckBandwidths.push(newSecondaryBandwidth);
          }

          // eslint-disable-next-line no-case-declarations
          const { serviceLevelAgreement: secondarySla } = orderData.data.access.secondary;

          if (secondarySla?.current) {
            secondaryFilterPreCheckSla.push(secondarySla.current);
          }
          if (secondarySla?.new) {
            secondaryFilterPreCheckSla.push(secondarySla.new);
          }

          // eslint-disable-next-line no-case-declarations
          const {
            type: secondaryQos,
            rtTotBandwidth: secondaryRtTotBandwidth,
          } = orderData.data.access.secondary.qualityOfService;

          if (secondaryQos?.current) {
            secondaryFilterPreCheckQos.push(type.current);
          }
          if (secondaryQos?.new) {
            secondaryFilterPreCheckQos.push(type.new);
          }

          if (secondaryRtTotBandwidth.current) {
            secondaryFilterPreCheckRtTotal.push(rtTotBandwidth.current);
          }
          if (secondaryRtTotBandwidth.new) {
            secondaryFilterPreCheckRtTotal.push(rtTotBandwidth.new);
          }

          // eslint-disable-next-line no-case-declarations
          const { secondaryApartmentNumber } = orderData.data.addresses.secondary;

          secondaryAccess = {
            bearer: secondaryBearer || null,
            bandwidths: secondaryFilterPreCheckBandwidths.length
              ? secondaryFilterPreCheckBandwidths
              : null,
            sla: secondaryFilterPreCheckSla.length ? secondaryFilterPreCheckSla : null,
            qos: secondaryFilterPreCheckQos.length ? secondaryFilterPreCheckQos : null,
            rtTotal: secondaryFilterPreCheckRtTotal.length ? secondaryFilterPreCheckRtTotal : null,
            fttxApartmentNumber: secondaryApartmentNumber || null,
          };
        }

        // eslint-disable-next-line no-case-declarations
        const filterPreCheckBody = {
          primary: {
            bearer: bearer || null,
            bandwidths: filterPreCheckBandwidths.length ? filterPreCheckBandwidths : null,
            sla: filterPreCheckSla.length ? filterPreCheckSla : null,
            qos: filterPreCheckQos.length ? filterPreCheckQos : null,
            rtTotal: filterPreCheckRtTotal.length ? filterPreCheckRtTotal : null,
            extendedAvailabilities: filterPreCheckExtendedAvailability.length
              ? filterPreCheckExtendedAvailability
              : null,
            fttxApartmentNumber: apartmentNumber || null,
          },
          secondary:
            secondaryAccess && objectContainsValues(secondaryAccess) ? secondaryAccess : null,
        };
        preCheckData = await corpPrecheckService.MybdFixedNetControllerService.filterFixedNet(
          scopeId,
          filterPreCheckBody
        );
        break;
      default:
        throw new Error("DATANET_UNKNOWN_ORDER_TYPE");
    }
  } catch (e) {
    throw new Error("DATANET_INVALID_ORDER");
  }

  return preCheckData;
}

function objectContainsValues(object) {
  return (
    Object.values(object).filter((value) => {
      if (
        value == null ||
        Number.isNaN(value) ||
        value < 0 ||
        value === "" ||
        (Array.isArray(value) && !value.length)
      ) {
        return false;
      }
      return true;
    }).length > 0
  );
}

async function submitProductNewOrder(scopeId = "", orderId = "", orderBody = {}) {
  if (orderId) {
    await corpDatanetService.ExternalDatanetControllerService.updateNewOrderCaptureFinalize(
      scopeId,
      orderId,
      orderBody
    );
  } else {
    await corpDatanetService.ExternalDatanetControllerService.addOrderCaptureFinalize(
      scopeId,
      orderBody
    );
  }
}

async function submitProductChangeOrder(scopeId = "", orderId = "", orderBody = {}) {
  if (orderId) {
    await corpDatanetService.ExternalDatanetControllerService.updateChangeOrderCaptureFinalize(
      scopeId,
      orderId,
      orderBody
    );
  } else {
    await corpDatanetService.ExternalDatanetControllerService.addOrderCaptureFinalize(
      scopeId,
      orderBody
    );
  }
}

async function submitFunctionOrder(scopeId = "", orderId = "", orderBody = {}) {
  await corpDatanetService.ExternalDatanetControllerService.updateChangeOrderCaptureFinalize(
    scopeId,
    orderId,
    orderBody
  );
}

async function fetchProductsFromPreCheckByAddress(queryValues, scopeId) {
  const {
    city = null,
    entrance = null,
    x = null,
    y = null,
    streetName = null,
    streetNumber = null,
  } = queryValues;
  try {
    return await corpPrecheckService.MybdFixedNetControllerService.getFixedNet(
      scopeId,
      streetName,
      city,
      streetNumber,
      entrance,
      x,
      y
    );
  } catch (e) {
    const parsedErrorMessage = JSON.parse(e.message);

    let error;
    if (parsedErrorMessage.errorKey) {
      error = {
        success: true,
        message: "",
      };
      throw new Error(JSON.stringify(error));
    }
    error = {
      success: false,
      message: "preCheck.getProductsFetchError",
    };
    throw new Error(JSON.stringify(error));
  }
}

async function fetchProductsFromPreCheckByCoordinates(queryValues, scopeId) {
  const {
    city = null,
    entrance = null,
    x = null,
    y = null,
    streetName = null,
    streetNumber = null,
  } = queryValues;
  try {
    return await corpPrecheckService.MybdFixedNetControllerService.getFixedNet(
      scopeId,
      streetName,
      city,
      streetNumber,
      entrance,
      x,
      y
    );
  } catch (e) {
    const parsedErrorMessage = JSON.parse(e.message);

    let error;
    if (parsedErrorMessage.errorKey) {
      error = {
        success: true,
        message: "",
      };
      throw new Error(JSON.stringify(error));
    }
    error = {
      success: false,
      message: "preCheck.getProductsFetchError",
    };
    throw new Error(JSON.stringify(error));
  }
}
