var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "address-master", attrs: { "data-dont-collect": "" } },
    [
      _c(
        "telia-tab",
        { attrs: { variant: "light-wide" } },
        [
          _c(
            "telia-tab-content",
            {
              attrs: {
                "tab-id": "1",
                name: _vm.t("ADDRESS_MASTER.ADDRESS_SEARCH"),
              },
            },
            [
              _c(
                "div",
                { staticClass: "address-search" },
                [
                  _c(
                    "div",
                    { staticClass: "address-input" },
                    [
                      _c("b2x-skeleton-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.initializing,
                            expression: "initializing",
                          },
                        ],
                      }),
                      _c("AddressSearch", {
                        attrs: { label: _vm.t("ADDRESS_MASTER.ADDRESS") },
                        on: {
                          addressSelected: function ($event) {
                            _vm.address = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("telia-p", { staticClass: "additional-info" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.t("ADDRESS_MASTER.ADDRESS_SEARCH_DESCRIPTION")
                        ) +
                        "\n        "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "service-info" },
                    [
                      _c("telia-p", [
                        _vm._v(" " + _vm._s(_vm.t("PRE_CHECK.SERVICE_INFO"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "button-holder" },
                    [
                      _c(
                        "telia-button",
                        {
                          attrs: {
                            "t-id": "address-button",
                            variant: "primary",
                            size: "sm",
                            disabled: _vm.disableSubmit,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleAddressSubmit()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.disableSubmit
                                  ? _vm.t("ADDRESS_MASTER.CHECKING_ADDRESS")
                                  : _vm.t("ADDRESS_MASTER.CONTINUE")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "telia-tab-content",
            {
              attrs: {
                "tab-id": "2",
                name: _vm.t("ADDRESS_MASTER.COORDINATE_SEARCH"),
              },
            },
            [
              _c(
                "div",
                { ref: "coordinateSearch", staticClass: "coordinate-search" },
                [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "address-input" },
                        [
                          _c("telia-text-input", {
                            ref: "xCoordinate",
                            attrs: {
                              label: _vm.t("ADDRESS_MASTER.COORDINATES.X"),
                              value: _vm.coordinates.x,
                              minLength: "7",
                              maxLength: "7",
                              "minlength-error-message": _vm.t(
                                "ADDRESS_MASTER.MIN_LENGTH"
                              ),
                              additional: _vm.t("ADDRESS_MASTER.SEVEN_DIGITS"),
                              required: "",
                              "required-error-message": _vm.t(
                                "ADDRESS_MASTER.REQUIRED_MESSAGE"
                              ),
                            },
                            on: {
                              input: function ($event) {
                                _vm.coordinates.x = $event.target.value
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "address-input" },
                        [
                          _c("telia-text-input", {
                            ref: "yCoordinate",
                            attrs: {
                              label: _vm.t("ADDRESS_MASTER.COORDINATES.Y"),
                              value: _vm.coordinates.y,
                              minLength: "7",
                              maxLength: "7",
                              "minlength-error-message": _vm.t(
                                "ADDRESS_MASTER.MIN_LENGTH"
                              ),
                              additional: _vm.t("ADDRESS_MASTER.SEVEN_DIGITS"),
                              required: "",
                              "required-error-message": _vm.t(
                                "ADDRESS_MASTER.REQUIRED_MESSAGE"
                              ),
                            },
                            on: {
                              input: function ($event) {
                                _vm.coordinates.y = $event.target.value
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "telia-p",
                        { staticClass: "additional-info" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.t("ADDRESS_MASTER.COORDINATES_DESCRIPTION")
                              ) +
                              "\n            "
                          ),
                          _c(
                            "b2x-tooltip",
                            {
                              attrs: {
                                content: _vm.t("ADDRESS_MASTER.TOOLTIP"),
                              },
                            },
                            [_vm._v(" (RT90) ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "service-info" },
                        [
                          _c("telia-p", [
                            _vm._v(
                              " " + _vm._s(_vm.t("PRE_CHECK.SERVICE_INFO"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "button-holder" },
                        [
                          _c(
                            "telia-button",
                            {
                              attrs: {
                                "t-id": "coordinate-button",
                                type: "submit",
                                variant: "primary",
                                size: "sm",
                                disabled: _vm.disableSubmit,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCoordinateSubmit()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.disableSubmit
                                      ? _vm.t("ADDRESS_MASTER.CHECKING_ADDRESS")
                                      : _vm.t("ADDRESS_MASTER.CONTINUE")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }