var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.choices, function (option) {
        return _c(
          "div",
          { key: option.value, staticClass: "tooltip-wrapper margin__top__12" },
          [
            _c(
              "div",
              { staticClass: "radio-wrapper" },
              [
                _c(
                  "telia-radio-button",
                  {
                    attrs: {
                      id: _vm.accessKey + "-access-installation-options",
                      name: _vm.accessKey + "-access-installation-options",
                      checked: _vm.choice === option.value,
                    },
                    on: {
                      change: function ($event) {
                        _vm.choice = option.value
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(option.label) + "\n      ")]
                ),
              ],
              1
            ),
            _vm.accessKey === "primary"
              ? _c("telia-icon", {
                  staticClass: "tooltip-icon",
                  attrs: { name: "question", size: "sm" },
                  on: {
                    click: function ($event) {
                      return _vm.emitTooltipValue(option.value)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm.$v.choice.$error
        ? _c("ValidationError", [
            _vm._v(
              "\n    " +
                _vm._s(_vm.t("DATANET_MUST_SELECT_INSTALLATION_CHOICE")) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "margin__top__32" },
        [
          _c(
            "telia-checkbox",
            {
              attrs: {
                id: _vm.accessKey + "-access-installation-consent",
                value: !_vm.consent,
                checked: _vm.consent,
              },
              on: {
                change: function ($event) {
                  _vm.consent = $event.target.checked
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.t("DATANET_ON_PREMISES_INSTALLATION_CONSENT_INFO")
                  ) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm.$v.consent.$error
        ? _c("ValidationError", [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.t("DATANET_ON_PREMISES_INSTALLATION_CONSENT_REQUIRED")
                ) +
                "\n  "
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }