<template>
  <div :class="`summary-row summary-row--${variant}`">
    <slot />
  </div>
</template>

<script>
export default {
  name: "SummaryRow",

  props: {
    variant: {
      type: String,
      default: "default",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";

.summary-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: 4rem;
  padding: 0.5rem 0;
  border-bottom: 2px solid $telia-gray-50;
}

.summary-row--default {
  background-color: $telia-white;
}

.summary-row--header {
  background-color: $telia-gray-50;
  font-weight: bold;
}
</style>
