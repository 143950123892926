var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "save-bar-container" },
    [
      _c(
        "telia-grid",
        [
          _c(
            "telia-row",
            [
              _c(
                "telia-col",
                { attrs: { width: "4" } },
                [
                  _c(
                    "telia-heading",
                    { attrs: { tag: "h3", variant: "title-200" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.t("DATANET_SAVE_ORDER")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c("telia-p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.t("DATANET_SAVE_ORDER_DESC")) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
              _c("telia-col", { attrs: { width: "8" } }, [
                _c("div", { staticClass: "action-container" }, [
                  _vm.hasOrderSharingEnabled || _vm.isSharedOrder
                    ? _c(
                        "div",
                        { staticClass: "share-order-toggle-container" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "shared-order",
                              on: {
                                mouseover: function ($event) {
                                  _vm.showTooltip = true
                                },
                                mouseleave: function ($event) {
                                  _vm.showTooltip = false
                                },
                              },
                            },
                            [
                              _c(
                                "Tooltip",
                                { attrs: { show: _vm.showTooltip } },
                                [
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.isOrderCreatedByCurrentUser
                                              ? _vm.t(
                                                  "DATANET_SHARE_ORDER_TOOLTIP"
                                                )
                                              : _vm.t(
                                                  "DATANET_NOT_ALLOWED_TO_TOGGLE"
                                                )
                                          ) +
                                          "\n                  "
                                      ),
                                    ]),
                                  ],
                                ],
                                2
                              ),
                              _c("telia-toggle", {
                                attrs: {
                                  id: "toggle-share-order",
                                  label: _vm.t("DATANET_SHARE_ORDER"),
                                  "label-position": "before",
                                  disabled: !_vm.isOrderCreatedByCurrentUser
                                    ? "true"
                                    : "false",
                                  selected: _vm.isSharedOrder,
                                },
                                on: {
                                  vocaChange: function ($event) {
                                    return _vm.setShared($event.detail.value)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "save-button-container" },
                    [
                      _c(
                        "telia-button",
                        {
                          attrs: {
                            id: "save-order",
                            type: "button",
                            size: "md",
                            disabled: _vm.isSaving || _vm.isTeliaAdmin,
                            variant: "expressive",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("save")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.isSaving
                                  ? _vm.t("DATANET_SAVING_ORDER")
                                  : _vm.t("DATANET_SAVE_ORDER")
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _c("telia-p", [
                        _c("small", [_c("em", [_vm._v(_vm._s(_vm.saveText))])]),
                      ]),
                    ],
                    1
                  ),
                  _vm.isCreateTicketEnabled
                    ? _c(
                        "div",
                        { staticClass: "help-button-container" },
                        [
                          _c("telia-button", {
                            attrs: {
                              id: "save-order",
                              type: "button",
                              size: "sm",
                              variant: "secondary",
                              "left-icon": JSON.stringify({
                                name: "customer-dialogue",
                                size: "md",
                              }),
                              text: _vm.t("HELP.BUTTON"),
                            },
                            on: { click: _vm.showHelpModal },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            "modal-id": "help-modal",
            width: "m",
            "is-open": _vm.isHelpModalActive,
          },
          on: {
            closeModal: function ($event) {
              return _vm.hideHelpModal()
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "modal-body" }, slot: "modal-body" },
            [
              _c(
                "div",
                { staticClass: "modal-heading-container" },
                [
                  _c(
                    "telia-heading",
                    {
                      staticClass: "modal-heading",
                      attrs: { tag: "h3", variant: "title-200" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.t("HELP.MODAL_TITLE")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c("telia-p", { attrs: { "disable-hyphenation": "" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.t("HELP.MODAL_ADDITIONAL")) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
              _c("telia-textarea", {
                staticClass: "help-text-area",
                attrs: {
                  label: _vm.t("HELP.HELP_INPUT_TITLE"),
                  additional: _vm.t("HELP.HELP_INPUT_ADDITIONAL"),
                  minlength: "1",
                  maxlength: "4000",
                  disabled: _vm.submitHelpSuccess,
                  value: _vm.helpTextInput,
                },
                on: {
                  input: function ($event) {
                    _vm.helpTextInput = $event.target.value
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "help-text-notification-container" },
                [
                  _c(
                    "telia-notification",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.submitHelpSuccess,
                          expression: "submitHelpSuccess",
                        },
                      ],
                      attrs: {
                        "heading-tag": "h4",
                        variant: "inline",
                        status: "success",
                        "html-aria-live": "polite",
                        "html-role": "alert",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "heading" }, slot: "heading" },
                        [
                          _c("telia-visually-hidden", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.t("A11Y_STATUS_INFORMATION")) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.t("HELP.SUBMIT_SUCCESS_TITLE")) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c(
                            "telia-p",
                            { attrs: { "disable-hyphenation": "" } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.t("HELP.SUBMIT_SUCCESS_ADDITIONAL")
                                  ) +
                                  "\n              "
                              ),
                              _vm.tnowId
                                ? _c(
                                    "telia-link",
                                    {
                                      attrs: {
                                        variant: "text",
                                        href:
                                          "/foretag/mybusiness/" +
                                          _vm.scopeId +
                                          "/support/arenden/detaljer/" +
                                          _vm.tnowId,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.tnowId) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "telia-notification",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.submitHelpError,
                          expression: "submitHelpError",
                        },
                      ],
                      attrs: {
                        "heading-tag": "h4",
                        variant: "inline",
                        status: "warning",
                        "html-aria-live": "assertive",
                        "html-role": "alert",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "heading" }, slot: "heading" },
                        [
                          _c("telia-visually-hidden", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.t("A11Y_STATUS_WARNING")) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.t("HELP.SUBMIT_ERROR_TITLE")) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c(
                            "telia-p",
                            { attrs: { "disable-hyphenation": "" } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.t("HELP.SUBMIT_ERROR_ADDITIONAL")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "button-footer" }, slot: "button-footer" },
            [
              _c(
                "div",
                { staticClass: "modal-footer-container" },
                [
                  _c(
                    "telia-button",
                    {
                      staticClass: "submit-help-button",
                      attrs: {
                        type: "button",
                        size: "sm",
                        variant: "expressive",
                        disabled:
                          _vm.isTeliaAdmin ||
                          !_vm.helpTextInput ||
                          _vm.isSubmittingHelp ||
                          _vm.submitHelpSuccess,
                      },
                      on: { click: _vm.submitHelp },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.t("HELP.SEND")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "telia-button",
                    {
                      attrs: {
                        type: "button",
                        size: "sm",
                        variant: "secondary",
                        disabled: _vm.isSubmittingHelp,
                      },
                      on: { click: _vm.hideHelpModal },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.t("HELP.CLOSE")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }