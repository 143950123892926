var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isChangeOrder &&
      (!_vm.action || _vm.action === "DELETE") &&
      _vm.currentSelectedBandwidthText
        ? _c(
            "div",
            { staticClass: "margin__bottom__24" },
            [
              _c(
                "Summary",
                { attrs: { editable: false } },
                [
                  _c("telia-p", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.t(
                            "DATANET_INTERNET_ON_SITE_CURRENT_CONFIGURATION"
                          )
                        )
                      ),
                    ]),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.t("DATANET_INTERNET_ON_SITE_CAPACITY") +
                              ": " +
                              _vm.currentSelectedBandwidthText
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.action === "ADD" || _vm.action === "DELETE"
        ? _c(
            "Summary",
            {
              staticClass: "margin__bottom__24",
              attrs: { "edit-label": _vm.t("DATANET_EDIT") },
              on: {
                edit: function ($event) {
                  return _vm.showInternetOnSiteConfigModal()
                },
              },
            },
            [
              _c("telia-p", [
                _c("strong", [
                  _vm._v(_vm._s(_vm.t("DATANET_INTERNET_ON_SITE"))),
                ]),
                _c("br"),
                _vm.action === "ADD"
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.t("DATANET_INTERNET_ON_SITE_IP_ADDRESSES") +
                              ": " +
                              (_vm.ripeNumber
                                ? _vm.t("DATANET_INTERNET_ON_SITE_NEW_IP")
                                : _vm.t("DATANET_INTERNET_ON_SITE_EXISTING_IP"))
                          ) +
                          "\n        "
                      ),
                      _c("br"),
                    ])
                  : _vm._e(),
                _vm.action === "ADD"
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.t("DATANET_INTERNET_ON_SITE_INTERFACE") +
                              ": " +
                              _vm.lanInterface
                          ) +
                          "\n        "
                      ),
                      _c("br"),
                    ])
                  : _vm._e(),
                _vm.action === "ADD"
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.t("DATANET_INTERNET_ON_SITE_CAPACITY") +
                              ": " +
                              (_vm.useDynamicCapacity
                                ? _vm.t(
                                    "DATANET_INTERNET_ON_SITE_DYNAMIC_CAPACITY"
                                  )
                                : _vm.t(
                                    "DATANET_INTERNET_ON_SITE_STATIC_BANDWIDTH"
                                  ))
                          ) +
                          "\n        "
                      ),
                      _c("br"),
                    ])
                  : _vm._e(),
                _vm.action === "ADD" && !_vm.useDynamicCapacity
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.t(
                              "DATANET_INTERNET_ON_SITE_SELECTED_BANDWIDTH"
                            ) +
                              ": " +
                              _vm.getStaticBandwidthText()
                          ) +
                          "\n        "
                      ),
                      _c("br"),
                    ])
                  : _vm._e(),
                _vm.action === "ADD"
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.t(
                              "DATANET_INTERNET_ON_SITE_SELECT_ETHERNET_INTERFACES"
                            ) +
                              ": " +
                              (_vm.extraEthernetInterfaces > 0
                                ? _vm.t(
                                    "DATANET_INTERNET_ON_SITE_EXTRA_ETHERNET_INTERFACES",
                                    {
                                      number: _vm.extraEthernetInterfaces,
                                    }
                                  )
                                : _vm.t(
                                    "DATANET_INTERNET_ON_SITE_NO_EXTRA_ETHERNET_INTERFACES"
                                  ))
                          ) +
                          "\n        "
                      ),
                      _c("br"),
                    ])
                  : _vm._e(),
                _vm.action === "DELETE"
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.t("DATANET_INTERNET_ON_SITE_REMOVE")) +
                          "\n        "
                      ),
                      _c("br"),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.action === "UPDATE"
        ? _c(
            "SummaryList",
            { staticClass: "change-summary" },
            [
              _c(
                "SummaryRow",
                { attrs: { variant: "header" } },
                [
                  _c(
                    "SummaryColumn",
                    [_c("telia-p", [_vm._v(_vm._s(_vm.t("DATANET_CHANGE")))])],
                    1
                  ),
                  _c("SummaryColumn", [_vm._v("   ")]),
                  _c(
                    "SummaryColumn",
                    [
                      _c("telia-p", [
                        _vm._v(_vm._s(_vm.t("DATANET_CURRENT_VALUE"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "SummaryColumn",
                    [
                      _c("telia-p", [
                        _vm._v(_vm._s(_vm.t("DATANET_NEW_VALUE"))),
                      ]),
                    ],
                    1
                  ),
                  _c("SummaryColumn", { attrs: { variant: "small" } }, [
                    _vm._v("   "),
                  ]),
                ],
                1
              ),
              _vm.isCapacityChanged
                ? _c(
                    "SummaryRow",
                    [
                      _c(
                        "SummaryColumn",
                        { attrs: { variant: "align-top" } },
                        [
                          _c("telia-p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.t("DATANET_ACTION_" + _vm.action))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("SummaryColumn", { attrs: { variant: "align-top" } }, [
                        _c(
                          "div",
                          { staticClass: "field-title" },
                          [
                            _c("telia-p", [
                              _vm._v(
                                _vm._s(
                                  _vm.t(
                                    "DATANET_INTERNET_ON_SITE_SELECT_BANDWIDTH"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c("SummaryColumn", { attrs: { variant: "align-top" } }, [
                        _c(
                          "div",
                          [
                            _c("telia-p", [
                              _vm._v(
                                _vm._s(_vm.currentSelectedBandwidthText || "-")
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c("SummaryColumn", [
                        _c(
                          "div",
                          [
                            _c("telia-p", [
                              _vm._v(
                                _vm._s(_vm.newSelectedBandwidthText || "-")
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "SummaryColumn",
                        {
                          staticClass: "align-end",
                          attrs: { variant: "small" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "summary-link",
                              attrs: {
                                id: "edit-internet-on-site-bandwidth-change",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showInternetOnSiteConfigModal()
                                },
                              },
                            },
                            [
                              _c(
                                "i",
                                { staticClass: "link-icon" },
                                [
                                  _c("telia-icon", {
                                    attrs: { name: "edit", size: "sm" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isEthernetInterfacesChanged
                ? _c(
                    "SummaryRow",
                    [
                      _c(
                        "SummaryColumn",
                        { attrs: { variant: "align-top" } },
                        [
                          _c("telia-p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.t("DATANET_ACTION_" + _vm.action))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("SummaryColumn", { attrs: { variant: "align-top" } }, [
                        _c(
                          "div",
                          { staticClass: "field-title" },
                          [
                            _c("telia-p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.t(
                                      "DATANET_INTERNET_ON_SITE_SELECT_ETHERNET_INTERFACES"
                                    )
                                  )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c("SummaryColumn", { attrs: { variant: "align-top" } }, [
                        _c(
                          "div",
                          [
                            _c("telia-p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    "" +
                                      (_vm.currentExtraEthernetInterfaces > 0
                                        ? _vm.t(
                                            "DATANET_INTERNET_ON_SITE_EXTRA_ETHERNET_INTERFACES",
                                            {
                                              number:
                                                _vm.currentExtraEthernetInterfaces,
                                            }
                                          )
                                        : "-")
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c("SummaryColumn", [
                        _c(
                          "div",
                          [
                            _c("telia-p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    "" +
                                      (_vm.extraEthernetInterfaces > 0
                                        ? _vm.t(
                                            "DATANET_INTERNET_ON_SITE_EXTRA_ETHERNET_INTERFACES",
                                            {
                                              number:
                                                _vm.extraEthernetInterfaces,
                                            }
                                          )
                                        : _vm.t(
                                            "DATANET_INTERNET_ON_SITE_NO_EXTRA_ETHERNET_INTERFACES"
                                          ))
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "SummaryColumn",
                        {
                          staticClass: "align-end",
                          attrs: { variant: "small" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "summary-link",
                              attrs: {
                                id: "edit-internet-on-site-interface-change",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showInternetOnSiteConfigModal()
                                },
                              },
                            },
                            [
                              _c(
                                "i",
                                { staticClass: "link-icon" },
                                [
                                  _c("telia-icon", {
                                    attrs: { name: "edit", size: "sm" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.modalIsActive
        ? _c(
            "Modal",
            {
              attrs: {
                "modal-id": "internet-on-site-config-modal",
                width: "m",
                "is-open": _vm.modalIsActive,
              },
              on: {
                closeModal: function ($event) {
                  return _vm.hideInternetOnSiteConfigModal()
                },
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "modal-body" }, slot: "modal-body" },
                [
                  _c("InternetOnSiteConfig", {
                    on: {
                      complete: function ($event) {
                        return _vm.hideInternetOnSiteConfigModal()
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "margin__top__12" },
        [
          _vm.action
            ? _c("telia-button", {
                attrs: {
                  id: "remove-internet-on-site-config",
                  "t-id": "remove-internet-on-site-config",
                  type: "button",
                  variant: "secondary",
                  size: "sm",
                  "left-icon": JSON.stringify({ name: "close", size: "sm" }),
                  text: _vm.isChangeOrder
                    ? _vm.t("DATANET_INTERNET_ON_SITE_REMOVE_CHANGE")
                    : _vm.t("DATANET_INTERNET_ON_SITE_REMOVE"),
                  disabled: !_vm.isPrimaryAccessInternetOnSiteEditable,
                },
                on: {
                  click: function ($event) {
                    return _vm.resetInternetOnSite()
                  },
                },
              })
            : _c("telia-button", {
                attrs: {
                  id: "add-internet-on-site-config",
                  "t-id": "add-internet-on-site-config",
                  type: "button",
                  variant: "secondary",
                  size: "sm",
                  "left-icon": JSON.stringify({ name: "plus", size: "sm" }),
                  text: _vm.isChangeOrder
                    ? _vm.t("DATANET_INTERNET_ON_SITE_ADD_CHANGE")
                    : _vm.t("DATANET_INTERNET_ON_SITE_ADD"),
                  disabled: !_vm.isPrimaryAccessInternetOnSiteEditable,
                },
                on: {
                  click: function ($event) {
                    return _vm.showInternetOnSiteConfigModal()
                  },
                },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }