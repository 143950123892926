<template>
  <SummaryList>
    <SummaryRow variant="header">
      <SummaryColumn class="summary-column--small">
        <telia-p>{{ t("DATANET_VPN") }}</telia-p>
      </SummaryColumn>
      <SummaryColumn class="summary-column--large">
        <SummaryRow variant="header" class="summary-row--nested">
          <SummaryColumn>
            <telia-p>{{ t("DATANET_SETTING") }}</telia-p>
          </SummaryColumn>
          <SummaryColumn> &nbsp; </SummaryColumn>
          <SummaryColumn>
            <telia-p>{{ t("DATANET_CURRENT_VALUE") }}</telia-p>
          </SummaryColumn>
          <SummaryColumn>
            <telia-p>{{ t("DATANET_NEW_VALUE") }}</telia-p>
          </SummaryColumn>
        </SummaryRow>
      </SummaryColumn>
    </SummaryRow>
    <SummaryRow v-for="(vpn, vpnIndex) in primaryAccessVpns" :key="vpn.key">
      <SummaryColumn variant="align-top" class="summary-column--small">
        <telia-p>
          <strong>{{ `VPN ${vpnIndex + 1}` }}</strong>
        </telia-p>
      </SummaryColumn>
      <SummaryColumn variant="align-top" class="summary-column--large">
        <SummaryRow class="summary-row--nested">
          <SummaryColumn
            variant="align-top"
            :class="vpn.action === Actions.DELETE ? 'field-value--deleted' : ''"
          >
            <telia-p>
              {{
                vpn.action === Actions.DELETE
                  ? `${t("DATANET_ACTION_DELETE")} ${t("DATANET_VPN")}`
                  : t("DATANET_VPN_BASE_SETTINGS")
              }}
            </telia-p>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div class="field-name">
              <telia-p>{{ t("DATANET_VPN_ID") }}</telia-p>
            </div>
            <div
              v-if="
                isMultiVpn &&
                vpn.action !== Actions.DELETE &&
                (vpnBandwidths[vpnIndex].current || vpnBandwidths[vpnIndex].new)
              "
              class="field-name"
            >
              <telia-p> {{ t("DATANET_VPN_BANDWIDTH") }}</telia-p>
            </div>
            <div
              v-if="
                isMultiVpn &&
                vpn.action !== Actions.DELETE &&
                isStaticBandwidthDistribution(vpn.accessKey)
              "
              class="field-name"
            >
              <telia-p>{{ t("DATANET_QOS") }}</telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div>
              <telia-p>{{ vpn.id || "-" }}</telia-p>
            </div>
            <div
              v-if="
                isMultiVpn &&
                vpn.action !== Actions.DELETE &&
                (vpnBandwidths[vpnIndex].current || vpnBandwidths[vpnIndex].new)
              "
            >
              <telia-p>{{ vpnBandwidths[vpnIndex].current || "-" }}</telia-p>
            </div>
            <div
              v-if="
                isMultiVpn &&
                vpn.action !== Actions.DELETE &&
                isStaticBandwidthDistribution(vpn.accessKey)
              "
            >
              <telia-p>{{
                vpn.qos.current ? t(`DATANET_${vpn.qos.current}_DESCRIPTION`) : "-"
              }}</telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div>&nbsp;</div>
            <div
              v-if="
                isMultiVpn &&
                vpn.action !== Actions.DELETE &&
                (vpnBandwidths[vpnIndex].current || vpnBandwidths[vpnIndex].new)
              "
            >
              <telia-p>{{ vpnBandwidths[vpnIndex].new || "-" }}</telia-p>
            </div>
            <div
              v-if="
                isMultiVpn &&
                vpn.action !== Actions.DELETE &&
                isStaticBandwidthDistribution(vpn.accessKey)
              "
            >
              <telia-p>{{ vpn.qos.new ? t(`DATANET_${vpn.qos.new}_DESCRIPTION`) : "-" }}</telia-p>
            </div>
          </SummaryColumn>
        </SummaryRow>
        <SummaryRow
          v-for="lan in getLansByKeys(vpn.lans)"
          :key="lan.key"
          class="summary-row--nested"
        >
          <SummaryColumn variant="align-top" class="field-value--changed">
            <telia-p> {{ `${t("DATANET_ACTION_" + lan.action)} ${t("DATANET_VPN_LAN")}` }}</telia-p>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div v-if="isLogicalInterface" class="field-name">
              <telia-p> {{ t("DATANET_VPN_LAN_VLAN_ID") }}</telia-p>
            </div>
            <div v-if="isPhysicalInterface" class="field-name">
              <telia-p> {{ t("DATANET_VPN_LAN_IDENTIFYING_NETWORK") }}</telia-p>
            </div>
            <div
              v-for="(network, networkIndex) in lan.networks"
              :key="`lan-${lan.key}-network-${networkIndex}-title`"
              class="field-name"
            >
              <telia-p> {{ t("DATANET_VPN_LAN_NETWORK") }}</telia-p>
            </div>
            <div
              v-for="(relay, relayIndex) in lan.dhcpRelays"
              :key="`lan-${lan.key}-dhcp-relay-${relayIndex}-title`"
              class="field-name"
            >
              <telia-p> {{ t("DATANET_VPN_DHCP_RELAY") }}</telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div v-if="isLogicalInterface">
              <telia-p> {{ lan.vlanId.current || "-" }}</telia-p>
            </div>
            <div v-if="isPhysicalInterface" class="field-name">
              <telia-p> {{ lan.identifyingNetwork.input.new || "-" }}</telia-p>
            </div>
            <div
              v-for="(network, networkIndex) in lan.networks"
              :key="`lan-${lan.key}-network-${networkIndex}-current`"
            >
              <telia-p> {{ network.input.current || "-" }}</telia-p>
            </div>
            <div
              v-for="(relay, relayIndex) in lan.dhcpRelays"
              :key="`lan-${lan.key}-dhcp-relay-${relayIndex}-current`"
            >
              <telia-p> {{ ips[relay].ipAddress.current || "-" }}</telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div v-if="isLogicalInterface" :class="lan.vlanId.new ? 'field-value--changed' : ''">
              <telia-p> {{ lan.vlanId.new || "-" }}</telia-p>
            </div>
            <div
              v-for="(network, networkIndex) in lan.networks"
              :key="`lan-${lan.key}-network-${networkIndex}-new`"
              :class="network.input.new ? 'field-value--changed' : ''"
            >
              <telia-p> {{ network.input.new || "-" }}</telia-p>
            </div>
            <div
              v-for="(relay, relayIndex) in lan.dhcpRelays"
              :key="`lan-${lan.key}-dhcp-relay-${relayIndex}-new`"
              :class="ips[relay].ipAddress.new ? 'field-value--changed' : ''"
            >
              <telia-p> {{ ips[relay].ipAddress.new || "-" }}</telia-p>
            </div>
          </SummaryColumn>
        </SummaryRow>
        <SummaryRow
          v-for="server in getServersByKeys(vpn.dhcpServers)"
          :key="server.key"
          class="summary-row--nested"
        >
          <SummaryColumn variant="align-top" class="field-value--changed">
            <telia-p>
              {{ `${t("DATANET_ACTION_" + server.action)} ${t("DATANET_VPN_DHCP_SERVER")}` }}
            </telia-p>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div class="field-name">
              <telia-p> {{ t("DATANET_VPN_DHCP_SERVER_NETWORK") }}</telia-p>
            </div>
            <div
              v-for="(ipRange, ipRangeIndex) in server.excludedIpRanges.values"
              :key="`dhcp-server-${server.key}-ip-range-${ipRangeIndex}-title`"
              class="field-name"
            >
              <telia-p>
                {{ `${t("DATANET_VPN_DHCP_SERVER_EXCLUDED_IP_RANGE")} ${ipRangeIndex + 1}` }}
              </telia-p>
            </div>
            <div
              v-for="(dnsIp, dnsIpIndex) in server.dnsIps.values"
              :key="`dhcp-server-${server.key}-dns-ip-${dnsIpIndex}-title`"
              class="field-name"
            >
              <telia-p>
                {{ `${t("DATANET_VPN_DHCP_DNS_IP")} ${dnsIpIndex + 1}` }}
              </telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div>
              <telia-p>{{ server.network.input.current || "-" }}</telia-p>
            </div>
            <div
              v-for="(ipRange, ipRangeIndex) in server.excludedIpRanges.values"
              :key="`dhcp-server-${server.key}-ip-range-${ipRangeIndex}-current`"
            >
              <telia-p>
                {{ `${ipRange.start.ipAddress.current} - ${ipRange.end.ipAddress.current}` }}
              </telia-p>
            </div>
            <div
              v-for="(dnsIp, dnsIpIndex) in server.dnsIps.values"
              :key="`dhcp-server-${server.key}-dns-ip-${dnsIpIndex}-current`"
            >
              <telia-p>{{ dnsIp.ipAddress.current || "-" }}</telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div :class="server.network.input.new ? 'field-value--changed' : ''">
              <telia-p>{{ server.network.input.new || "-" }}</telia-p>
            </div>
            <div
              v-for="(ipRange, ipRangeIndex) in server.excludedIpRanges.values"
              :key="`dhcp-server-${server.key}-ip-range-${ipRangeIndex}-new`"
              :class="ipRange.start.ipAddress.new ? 'field-value--changed' : ''"
            >
              <telia-p>{{
                `${ipRange.start.ipAddress.new} - ${ipRange.end.ipAddress.new}`
              }}</telia-p>
            </div>
            <div
              v-for="(dnsIp, dnsIpIndex) in server.dnsIps.values"
              :key="`dhcp-server-${server.key}-dns-ip-${dnsIpIndex}-new`"
              :class="dnsIp.ipAddress.new ? 'field-value--changed' : ''"
            >
              <telia-p>{{ dnsIp.ipAddress.new || "-" }}</telia-p>
            </div>
          </SummaryColumn>
        </SummaryRow>
        <SummaryRow
          v-for="route in getStaticRoutesByKeys(vpn.staticRoutes)"
          :key="route.key"
          class="'summary-row--nested"
        >
          <SummaryColumn variant="align-top" class="field-value--changed">
            <telia-p>
              {{ `${t("DATANET_ACTION_" + route.action)} ${t("DATANET_VPN_STATIC_ROUTE")}` }}
            </telia-p>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div class="field-name">
              <telia-p>{{ t("DATANET_VPN_STATIC_ROUTE_NETWORK") }}</telia-p>
            </div>
            <div class="field-name">
              <telia-p>{{ t("DATANET_VPN_STATIC_ROUTE_NEXT_HOP") }}</telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div>
              <telia-p>{{ route.network.input.current || "-" }}</telia-p>
            </div>
            <div>
              <telia-p>{{ route.nextHop.ipAddress.current || "-" }}</telia-p>
            </div>
          </SummaryColumn>
          <SummaryColumn variant="align-top">
            <div :class="route.network.input.new ? 'field-value--changed' : ''">
              <telia-p>{{ route.network.input.new || "-" }}</telia-p>
            </div>
            <div :class="route.nextHop.ipAddress.new ? 'field-value--changed' : ''">
              <telia-p>{{ route.nextHop.ipAddress.new || "-" }}</telia-p>
            </div>
          </SummaryColumn>
        </SummaryRow>
      </SummaryColumn>
    </SummaryRow>
  </SummaryList>
</template>

<script>
import SummaryColumn from "../summary/SummaryColumn";
import SummaryList from "../summary/SummaryList";
import SummaryRow from "../summary/SummaryRow";
import { mapState, mapGetters } from "vuex";
import createBandwithLabel from "../../helpers/createBandwidthLabel";
import { Accesses, Actions } from "../../constants";
import { translateMixin } from "../../locale";
export default {
  name: "VpnChangeSummary",
  mixins: [translateMixin],
  components: {
    SummaryColumn,
    SummaryList,
    SummaryRow,
  },

  data() {
    return {
      Actions,
    };
  },

  computed: {
    ...mapGetters(["isChangeOrder"]),

    ...mapGetters("products", ["isWirelessAccess", "isWirelessBackupAccess"]),

    ...mapGetters("vpn", [
      "isCurrentDynamicBandwidthDistribution",
      "isDynamicBandwidthDistribution",
      "isStaticBandwidthDistribution",
      "isLogicalInterface",
      "isPhysicalInterface",
      "getAllVpns",
      "getVpn",
    ]),

    ...mapGetters("lans", ["getLansByKeys"]),

    ...mapGetters("dhcpServer", ["getServersByKeys"]),

    ...mapGetters("staticRoutes", ["getStaticRoutesByKeys"]),

    ...mapState("vpn", {
      isMultiVpn: (state) => state.multiVpn,
    }),

    primaryAccessVpns() {
      return this.getAllVpns.filter((vpn) => vpn.accessKey === Accesses.PRIMARY);
    },

    vpnBandwidths() {
      return this.primaryAccessVpns.map((vpn) => {
        return {
          current: this.getVpnBandwidthText(vpn, true),
          new: this.getVpnBandwidthText(vpn),
        };
      });
    },

    ips() {
      return this.$store.state.ips;
    },
  },

  methods: {
    getVpnBandwidthText(vpn, useCurrent = false) {
      const { bandwidth } = vpn;

      if (useCurrent && bandwidth.current) {
        if (this.isDynamicBandwidthDistribution(Accesses.PRIMARY)) {
          return this.t("DATANET_VPN_BANDWIDTH_DYNAMIC");
        } else if (bandwidth.current) {
          return this.isWirelessAccess
            ? this.t("DATANET_BEST_EFFORT")
            : createBandwithLabel(bandwidth.current, bandwidth.current);
        }
      } else if (!useCurrent) {
        if (this.isDynamicBandwidthDistribution(Accesses.PRIMARY)) {
          return this.t("DATANET_VPN_BANDWIDTH_DYNAMIC");
        } else if (bandwidth.new) {
          const currentBandwidthText = bandwidth.current
            ? this.isWirelessAccess
              ? this.t("DATANET_BEST_EFFORT")
              : createBandwithLabel(bandwidth.current, bandwidth.current)
            : "";
          const newBandwidthText = this.isWirelessAccess
            ? this.t("DATANET_BEST_EFFORT")
            : createBandwithLabel(bandwidth.new, bandwidth.new);

          return currentBandwidthText !== newBandwidthText ? newBandwidthText : "";
        }
      }

      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
.summary-row--nested:first-child {
  padding-top: 0;
}

.summary-row--nested:last-child {
  border-bottom: none;
}

.summary-column--large {
  flex: 5;
}

.summary-column--small {
  flex: 1;
}

.field-name {
  color: $telia-gray-500;
}

.field-value--changed {
  color: $telia-blue-500;
}

.field-value--deleted {
  color: $telia-red-500;
}
</style>
