<template>
  <div>
    <div v-for="option in choices" :key="option.value" class="tooltip-wrapper margin__top__12">
      <div class="radio-wrapper">
        <telia-radio-button
          :id="`${accessKey}-access-installation-options`"
          :name="`${accessKey}-access-installation-options`"
          :checked="choice === option.value"
          @change="choice = option.value"
        >
          {{ option.label }}
        </telia-radio-button>
      </div>
      <telia-icon
        v-if="accessKey === 'primary'"
        class="tooltip-icon"
        name="question"
        size="sm"
        @click="emitTooltipValue(option.value)"
      />
    </div>
    <ValidationError v-if="$v.choice.$error">
      {{ t("DATANET_MUST_SELECT_INSTALLATION_CHOICE") }}
    </ValidationError>
    <div class="margin__top__32">
      <telia-checkbox
        :id="`${accessKey}-access-installation-consent`"
        :value="!consent"
        :checked="consent"
        @change="consent = $event.target.checked"
      >
        {{ t("DATANET_ON_PREMISES_INSTALLATION_CONSENT_INFO") }}
      </telia-checkbox>
    </div>
    <ValidationError v-if="$v.consent.$error">
      {{ t("DATANET_ON_PREMISES_INSTALLATION_CONSENT_REQUIRED") }}
    </ValidationError>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { translateMixin } from "../../locale";
import ValidationError from "./../ValidationError.vue";
export default {
  name: "OnPremisesInstallationSettings",
  mixins: [translateMixin, validationMixin],
  components: {
    ValidationError,
  },
  props: {
    accessKey: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      shouldValidateOnPremisesInstallation:
        "onPremisesInstallation/shouldValidateOnPremisesInstallation",
      choices: "onPremisesInstallation/getInstallationOptions",
    }),

    choice: {
      get() {
        return this.$store.state.onPremisesInstallation[this.accessKey].selectedChoiceId;
      },
      set(value) {
        this.$store.dispatch("onPremisesInstallation/setChoice", {
          accessKey: this.accessKey,
          id: value,
        });
      },
    },
    consent: {
      get() {
        return this.$store.state.onPremisesInstallation[this.accessKey].consentGiven;
      },
      set(value) {
        this.$store.dispatch("onPremisesInstallation/setConsentGiven", {
          accessKey: this.accessKey,
          boolean: value,
        });
      },
    },
  },

  validations: {
    choice: {
      required: requiredIf(function () {
        return this.shouldValidateOnPremisesInstallation;
      }),
    },
    consent: {
      hasGivenConsent: (value) => value === true,
    },
  },

  methods: {
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    emitTooltipValue(value) {
      this.$emit("setActiveTooltip", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";

.tooltip-wrapper {
  display: flex;
}
.tooltip-icon {
  position: relative;
  top: 0.5rem;
  margin-left: $telia-spacing-12;
}
.tooltip-icon:hover {
  color: $telia-purple-500;
  cursor: pointer;
}
.radio-wrapper {
  max-width: 95rem;
}
.margin {
  &__bottom {
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
  }
  &__top {
    &__12 {
      margin-top: $telia-spacing-12;
    }
    &__32 {
      margin-top: $telia-spacing-32;
    }
  }
}
</style>
