import { getUtcFromIsoDate } from "./formatDate.js";
import { Accesses, Actions, Bearers, VpnInterfaces } from "../constants";
import { t } from "../locale";

export default function modelStoreDataToOrderData(data = {}) {
  const access = data.products.orderCaptureAccesses[data.products.bearer.value];
  const organisation = data.user.organisations[data.user.organisation];

  return {
    alias: data.orderAlias || null,
    businessArea: data.businessArea || null,
    orderType: data.orderType,
    version: data.version <= 0 ? 0 : data.version,
    orderCaptureId: data.orderCaptureId || null,
    accessId: data.accessId || null,
    metadata: {
      createdBy: data.createdBy,
      isShared: data.isShared,
      returnUrl: data.returnUrl,
      currentSectionIndex: data.currentStep <= 0 ? 1 : data.currentStep - 1,
    },
    data: {
      requestedInstallationDate: getUtcFromIsoDate(access.estimatedInstallationDate) || null,
      estimatedInstallationDate: getUtcFromIsoDate(access.estimatedInstallationDate) || null,
      contactPersons: modelContacts(data.contactPersons),
      addresses: modelAddresses(data.addresses, data.apartments),
      invoice: modelObject({
        invoiceAccount: modelValue(data.invoice.billingAccountNumber),
        handledByTeliaFinance: modelValue(data.invoice.isHandledByTeliaFinance),
        teliaFinanceInfo: modelValue(data.invoice.teliaFinanceInfo),
      }),
      organisation: modelObject({
        orgNumber: modelValue(organisation.orgNr),
        name: modelValue(organisation.name),
      }),
      access: access
        ? modelAccesses(
            data.access,
            data.products,
            data.onPremisesInstallation,
            data.vpn,
            Object.values(data.lans),
            Object.values(data.staticRoutes.routes),
            data.dhcpServer,
            data.ips,
            data.apartments
          )
        : null,
    },
  };
}

function modelContacts(contacts) {
  const copyOfContacts = Object.assign({}, contacts);
  for (const id in copyOfContacts) {
    const contact = copyOfContacts[id];

    const newContact = modelObject({
      firstName: modelValue(contact.firstName),
      lastName: modelValue(contact.lastName),
      emailAddress: modelValue(contact.emailAddress),
      telephoneNumber: modelValue(contact.phoneNumber),
    });

    if (!newContact) {
      delete copyOfContacts[id];
      continue;
    }

    copyOfContacts[id] = newContact;
  }
  return copyOfContacts;
}

function modelAddresses(addresses, apartments) {
  const copyOfAddresses = Object.assign({}, addresses);
  for (const id in copyOfAddresses) {
    const address = copyOfAddresses[id];

    const newAddress = modelObject({
      streetName: modelValue(address.streetName),
      streetNumber: modelValue(address.streetNumber),
      entrance: modelValue(address.entrance),
      postalCode: modelValue(address.postalCode),
      city: modelValue(address.city),
      country: modelValue(address.country),
      ...((id === "installation" || id === "secondary") && {
        apartmentNumber: modelValue(apartments.apartment),
        installationRoom: modelValue(address.installationRoom),
        xCoordinate: modelValue(address.x),
        yCoordinate: modelValue(address.y),
      }),
    });

    if (!newAddress) {
      delete copyOfAddresses[id];
      continue;
    }

    copyOfAddresses[id] = newAddress;
  }
  return copyOfAddresses;
}

function modelAccesses(
  access = {},
  products = {},
  onPremisesInstallation = {},
  vpns = {},
  lans = [],
  staticRoutes = [],
  dhcpServers = {},
  ips,
  apartments
) {
  const { temporary, secondary } = access;
  const extendedAvailabilityEnabled = secondary.enable || false;

  const object = {
    primary: modelAccess(
      Accesses.PRIMARY,
      temporary,
      secondary,
      access.primary,
      products,
      onPremisesInstallation,
      vpns,
      lans,
      staticRoutes,
      dhcpServers,
      ips,
      apartments
    ),
    secondary: extendedAvailabilityEnabled
      ? modelAccess(
          Accesses.SECONDARY,
          temporary,
          secondary,
          access.secondary,
          products,
          onPremisesInstallation,
          vpns,
          lans,
          staticRoutes,
          dhcpServers,
          ips,
          apartments
        )
      : null,
    quickLaunch: modelQuickLaunch(temporary),
    extendedAvailability: modelExtendedAvailability({
      enable: secondary.enable,
      continueFromQuickLaunch: temporary.useAsSecondaryAccess,
      type: secondary.backup,
    }),
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelAccess(
  accessKey = "",
  quickLaunch = {},
  extendedAvailability = {},
  access = {},
  products = {},
  onPremisesInstallation = {},
  vpns = {},
  lans = [],
  staticRoutes = [],
  dhcpServers = {},
  ips,
  apartments
) {
  const selectedAccessType =
    accessKey === Accesses.PRIMARY ? products.bearer.value : products.secondaryBearer;

  const object = {
    accessType: modelAccessType(accessKey, products, access, apartments),
    serviceLevelAgreement: modelChangeableObject(access.sla),
    qualityOfService: modelAccessQos({
      type: access.qos,
      rtTotBandwidth: vpns[accessKey].rtTot,
    }),
    onPremisesInstallation: modelLocalInstallation(accessKey, onPremisesInstallation),
    options: modelOptions(selectedAccessType, quickLaunch, extendedAvailability, access.options),
    vpn: modelVpns(accessKey, vpns, lans, staticRoutes, dhcpServers, ips),
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelAccessType(accessKey, products = {}, access = {}, apartments) {
  const bearer = accessKey === Accesses.PRIMARY ? products.bearer.value : products.secondaryBearer;

  const newBandwidth =
    products.orderCaptureAccesses[bearer].bandwidths[access.bandwidth.new] ||
    products.bandwidths[access.bandwidth.new];
  const currentBandwidth =
    products.orderCaptureAccesses[bearer].bandwidths[access.bandwidth.current] ||
    products.bandwidths[access.bandwidth.current];

  let pointId;
  if (apartments.apartment) {
    pointId = apartments.apartments[apartments.apartment].pointId;
  }

  const object = {
    bearer: bearer || null,
    ...(pointId && {
      pointId,
    }),
    bandwidth:
      newBandwidth || currentBandwidth ? modelBandwidth(newBandwidth, currentBandwidth) : null,
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelBandwidth(newBandwidth = {}, currentBandwidth = {}) {
  const object = {
    upRateKbit: {
      new: Object.keys(newBandwidth).length ? Number(newBandwidth.upRateKbit) : null,
      current: Object.keys(currentBandwidth).length ? Number(currentBandwidth.upRateKbit) : null,
    },
    downRateKbit: {
      new: Object.keys(newBandwidth).length ? Number(newBandwidth.downRateKbit) : null,
      current: Object.keys(currentBandwidth).length ? Number(currentBandwidth.downRateKbit) : null,
    },
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelAccessQos({ type, rtTotBandwidth }) {
  return modelObject({
    type: modelChangeableObject(type),
    rtTotBandwidth: modelChangeableObject(rtTotBandwidth),
  });
}

function modelLocalInstallation(accessKey, onPremisesInstallation = {}) {
  const choice = onPremisesInstallation.choices[onPremisesInstallation[accessKey].selectedChoiceId];
  const choiceText = choice ? choice.text : null;
  const connectionSettings = modelConnectionSettings(
    onPremisesInstallation[accessKey].connectionSettings
  );
  const consentGiven = onPremisesInstallation[accessKey].consentGiven;

  if (!choiceText && !connectionSettings && !consentGiven) {
    return null;
  }

  const object = {
    choice: t(choiceText),
    connectionSettings,
    consentGiven,
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelConnectionSettings(connectionSettings = {}) {
  const object = {
    pair: connectionSettings.pair || null,
    punchDownBlock: connectionSettings.punchDownBlock || null,
    stand: connectionSettings.stand || null,
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelOptions(
  selectedAccessType,
  quickLaunch,
  extendedAvailability,
  { trafficStatistics = {}, wirelessInstallation, antenna = {}, snmp = {}, internetOnSite = {} }
) {
  const shouldMapWirelessOptions =
    selectedAccessType === Bearers.WIRELESS || quickLaunch.enable || extendedAvailability.enable;
  const shouldMapAntennaOptions =
    shouldMapWirelessOptions && antenna.package && antenna.package !== "ANTENNA_PACKAGE_NONE";

  const object = {
    highEndCpe: null,
    trafficStatistics,
    netFlow: null,
    wirelessInstallation,
    antenna: shouldMapAntennaOptions ? modelAntenna(antenna) : null,
    snmp: modelSnmp(snmp),
    internetOnSite: modelInternetOnSite(internetOnSite),
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelQuickLaunch(access) {
  return access.enable
    ? {
        fastTrack: access.fastTrack,
        serviceLevelAgreement: modelValue(access.sla.new),
        qualityOfService: modelValue(access.qos.new),
        wirelessInstallation: access.options.wirelessInstallation,
        antenna:
          access.options.antenna.package !== "ANTENNA_PACKAGE_NONE"
            ? modelAntenna(access.options.antenna)
            : null,
      }
    : null;
}

function modelExtendedAvailability({
  enable = false,
  continueFromQuickLaunch = false,
  type = null,
}) {
  return enable
    ? {
        continueFromQuickLaunch,
        type,
      }
    : null;
}

function modelAntenna({ package: antennaPackage = "", installation = false, cable = false }) {
  const object = {
    antennaPackage: antennaPackage || null,
    installation,
    cable,
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelSnmp({ action = "", communities = [] }) {
  const object = {
    action: action || null,
    communities: communities.map((community) => modelSnmpCommunity(community)),
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelSnmpCommunity({ action, communityName, networks }) {
  const object = {
    action: action || null,
    community: modelChangeableObject(communityName),
    networks: networks.map((network) => modelNetworkObject(network)),
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelInternetOnSite({ action = "", ripeNumber = "", lanInterface = "", lanConfig = {} }) {
  if (!lanInterface) {
    return null;
  }

  const object = {
    action: action || null,
    ripeNumber: action !== Actions.UPDATE && action !== Actions.DELETE ? ripeNumber || null : null,
    lanInterface:
      action !== Actions.UPDATE && action !== Actions.DELETE ? lanInterface || null : null,
    lanConfig: action !== Actions.DELETE ? modelInternetOnSiteLanConfig(lanConfig) : null,
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelInternetOnSiteLanConfig({ capacity = {}, ethernetInterface = {} }) {
  const hasChangedInterfaceValue = ethernetInterface?.current !== ethernetInterface?.new;

  // Always set current to null until we can read actual value from sub-system
  if (ethernetInterface?.current !== null) {
    ethernetInterface.current = null;
  }

  if (ethernetInterface?.action === Actions.UPDATE && !hasChangedInterfaceValue) {
    ethernetInterface.action = null;
    ethernetInterface.current = null;
    ethernetInterface.new = null;
  }

  const object = {
    capacity: modelInternetOnSiteCapacity(capacity),
    ethernetInterface: modelChangeableObject(ethernetInterface),
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelInternetOnSiteCapacity({ dynamic = {}, bandwidth = {} }) {
  const hasChangedDynamicValue = dynamic?.current !== dynamic?.new;
  const hasChangedBandwidthValue = bandwidth?.current !== bandwidth?.new;

  if (dynamic?.action === Actions.UPDATE) {
    if (hasChangedDynamicValue) {
      if (dynamic.new) {
        if (!bandwidth) {
          bandwidth = {
            action: null,
            current: null,
            new: null,
          };
        }

        bandwidth.action = Actions.DELETE;
        bandwidth.current = null;
        bandwidth.new = null;
      }
    } else {
      dynamic.action = null;
      dynamic.current = null;
      dynamic.new = null;
    }
  }

  if (bandwidth?.action === Actions.UPDATE) {
    if (hasChangedBandwidthValue) {
      if (bandwidth.new) {
        if (!dynamic) {
          dynamic = {
            action: null,
            current: null,
            new: null,
          };
        }

        dynamic.action = Actions.DELETE;
        dynamic.current = null;
        dynamic.new = null;
      }
    } else {
      bandwidth.action = null;
      bandwidth.current = null;
      bandwidth.new = null;
    }
  }

  const object = {
    dynamic: modelChangeableObject(dynamic),
    bandwidth: modelChangeableObject(bandwidth),
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelVpns(accessKey = "", vpns = {}, lans = [], staticRoutes = [], dhcpServers = {}, ips) {
  const vpnInterface = vpns.vpnInterface || null;
  const modelledVpns = Object.values(vpns.vpns)
    .filter((vpn) => {
      if (accessKey === Accesses.PRIMARY) {
        return vpn.accessKey === Accesses.PRIMARY;
      } else if (accessKey === Accesses.SECONDARY) {
        return vpn.accessKey === Accesses.SECONDARY && vpn.isIncludedInBackup;
      }

      return false;
    })
    .map((vpn) => modelVpn(vpn, lans, staticRoutes, dhcpServers, ips, vpnInterface));

  const object = {
    bandwidthDistribution: vpns.multiVpn ? vpns[accessKey].bandwidthDistribution || null : null,
    vpnInterface,
    vpns: modelledVpns,
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelVpn(vpn = {}, lans = [], staticRoutes = [], dhcpServers = {}, ips, vpnInterface) {
  const vpnLans = lans.filter((lan) => vpn.lans.includes(lan.key));

  const object = {
    action: vpn.action || null,
    key: vpn.key,
    siblingKey: vpn.siblingVpnKey,
    isPrimary: vpn.isPrimary || false,
    isExisting: vpn.isExisting || false,
    isIncludedInBackup:
      vpn.accessKey === Accesses.SECONDARY ? vpn.isIncludedInBackup || false : null,
    alias: vpn.alias || null,
    id: vpn.id || null,
    bandwidth: modelVpnBandwidth({
      upRateKbit: vpn.bandwidth,
      downRateKbit: vpn.bandwidth,
    }),
    lans: modelVpnLans(vpnLans, vpnInterface),
    options: modelVpnOptions(vpn, vpnLans, staticRoutes, dhcpServers, ips),
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelVpnBandwidth({ upRateKbit, downRateKbit }) {
  return modelObject({
    upRateKbit: modelChangeableObject(upRateKbit),
    downRateKbit: modelChangeableObject(downRateKbit),
  });
}

function modelVpnLans(lans = [], vpnInterface) {
  const object = lans.map((lan) => {
    const networks = lan.networks
      .map((network) => {
        const shouldModelVirtualIp =
          network.virtualIp && (network.virtualIp.current || network.virtualIp.new);

        return modelNetworkObject({
          action: network.action,
          ipVersion: network.ipVersion,
          ipAddress: network.ipAddress,
          ipPrefix: network.ipPrefix,
          ...(shouldModelVirtualIp && {
            virtualIp: network.virtualIp,
          }),
        });
      })
      .filter(Boolean);

    const vlanId = {
      action: lan.vlanId.action && lan.vlanId.action !== Actions.NONE ? lan.vlanId.action : null,
      current: lan.vlanId.current || null,
      new: lan.vlanId.new || null,
    };

    let identifyingNetwork;

    if (lan.identifyingNetwork.ipAddress.current || lan.identifyingNetwork.ipAddress.new) {
      identifyingNetwork = modelNetworkObject({
        action: lan.identifyingNetwork.action,
        ipVersion: lan.identifyingNetwork.ipVersion,
        ipAddress: lan.identifyingNetwork.ipAddress,
        ipPrefix: lan.identifyingNetwork.ipPrefix,
      });
    }

    return {
      id: lan.id === -1 ? null : lan.id,
      key: lan.key === -1 ? null : lan.key,
      siblingKey: lan.siblingKey === -1 ? null : lan.siblingKey,
      action: lan.action || null,
      vlanId:
        vpnInterface === VpnInterfaces.LOGICAL && objectContainsValuesToModel(vlanId)
          ? vlanId
          : null,
      identifyingNetwork:
        identifyingNetwork && objectContainsValuesToModel(identifyingNetwork)
          ? identifyingNetwork
          : null,
      networks: networks.length ? networks : null,
    };
  });

  return objectContainsValuesToModel(object) ? object : null;
}

function modelVpnOptions(vpn = {}, lans = [], staticRoutes = [], dhcpServers = {}, ips) {
  const vpnStaticRoutes = staticRoutes.filter((route) => vpn.staticRoutes.includes(route.key));
  const vpnDhcpServers = Object.values(dhcpServers.servers).filter((server) =>
    vpn.dhcpServers.includes(server.key)
  );

  const dhcpRelays = [];
  lans.forEach((lan) =>
    lan.dhcpRelays.forEach((dhcpRelayKey) => dhcpRelays.push(ips[dhcpRelayKey]))
  );

  const object = {
    staticRoutes: {
      values: modelVpnStaticRoutes(vpnStaticRoutes),
    },
    dhcpServers: {
      action: dhcpServers.action && dhcpServers.action !== Actions.NONE ? dhcpServers.action : null,
      values: modelVpnDhcpServers(vpnDhcpServers),
    },
    dhcpRelays: {
      action:
        vpn.dhcpRelaysAction && vpn.dhcpRelaysAction !== Actions.NONE ? vpn.dhcpRelaysAction : null,
      values: modelDhcpRelays(dhcpRelays),
    },
    qualityOfService: modelVpnQos({
      type: vpn.qos,
      rt1Bandwidth: vpn.rt1,
      rt2Bandwidth: vpn.rt2,
    }),
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelVpnQos({ type, rt1Bandwidth, rt2Bandwidth }) {
  return modelObject({
    type: modelChangeableObject(type),
    rt1Bandwidth: modelChangeableObject(rt1Bandwidth),
    rt2Bandwidth: modelChangeableObject(rt2Bandwidth),
  });
}

function modelVpnStaticRoutes(routes = []) {
  return routes.map((route) => ({
    action: route.action || null,
    key: route.key,
    siblingKey: route.siblingRouteKey,
    network: modelNetworkObject(route.network),
    nextHop: modelIpObject(route.nextHop),
  }));
}

const modelDhcpRelays = (dhcpRelays) =>
  dhcpRelays.map((dhcpRelay) => {
    return {
      key: dhcpRelay.key,
      siblingKey: dhcpRelay.siblingKey,
      action: dhcpRelay.action,
      lanKey: dhcpRelay.ownerKey,
      ipVersion: modelChangeableObject(dhcpRelay.ipVersion),
      ipAddress: modelChangeableObject(dhcpRelay.ipAddress),
    };
  });

function modelVpnDhcpServers(servers = []) {
  return servers.map((server) => ({
    action: server.action !== Actions.NONE ? server.action : null,
    key: server.key,
    siblingKey: server.siblingDhcpServerKey,
    network: modelNetworkObject(server.network),
    excludedIpRanges: {
      values: server.excludedIpRanges.values.map((ipRange) => {
        return {
          action: ipRange.action || null,
          start: modelIpObject(ipRange.start),
          end: modelIpObject(ipRange.end),
        };
      }),
    },
    dnsIps: {
      action: server.dnsIps.action !== Actions.NONE ? server.dnsIps.action : null,
      values: server.dnsIps.values.map((ip) => modelIpObject(ip)),
    },
  }));
}

function modelNetworkObject({
  action = "",
  ipVersion = null,
  ipAddress = null,
  ipPrefix = null,
  virtualIp = null,
}) {
  const object = {
    action: action !== Actions.NONE ? modelValue(action) : null,
    ipVersion: modelChangeableObject(ipVersion),
    ipAddress: modelChangeableObject(ipAddress),
    prefix: modelChangeableObject(ipPrefix),
    ...(virtualIp && {
      vIp: modelChangeableObject(virtualIp),
    }),
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelIpObject({ ipVersion = null, ipAddress = null }) {
  const object = {
    ipVersion: modelChangeableObject(ipVersion),
    ipAddress: modelChangeableObject(ipAddress),
  };

  return objectContainsValuesToModel(object) ? object : null;
}

function modelChangeableObject({ action, current, new: _new }) {
  return modelObject({
    action: modelValue(action),
    current: modelValue(current),
    new: modelValue(_new),
  });
}

function modelObject(object) {
  const values = Object.values(object);
  for (const value of values) {
    if (value !== null) return object;
  }
  return null;
}

function modelValue(value) {
  if (value === null) return null;
  if (value === undefined) return null;
  if (typeof value === "number" && isNaN(value)) return null;
  if (value === -1) return null;
  if (value === "") return null;
  if (value === []) return null;
  return value;
}

function objectContainsValuesToModel(object) {
  return (
    Object.values(object).filter((value) => {
      if (
        value == null ||
        Number.isNaN(value) ||
        value < 0 ||
        value === "" ||
        (Array.isArray(value) && !value.length)
      ) {
        return false;
      }
      return true;
    }).length > 0
  );
}
