<template>
  <div>
    <telia-heading tag="h3" variant="title-100" class="margin__bottom__12" v-text="titleText" />

    <ActionSelection
      v-if="shouldShowActionSelection"
      class="margin__bottom__24"
      :selected-action="action"
      :disabled-actions="disabledActions"
      @selected="handleActionSelection($event)"
    />

    <telia-p v-if="isChangeOrder">
      <strong>{{ t("DATANET_VPN_DHCP_RELAY") }}</strong>
    </telia-p>

    <div class="margin__bottom__4 inputs__container">
      <telia-text-input
        v-if="shouldShowCurrentIpAddressInput"
        t-id="current-dhcp-relay"
        class="inputs__container__input"
        :label="t('DATANET_CURRENT_VALUE')"
        :validation-state="$v.ipAddress.current.$error ? 'Invalid' : 'Valid'"
        :value="ipAddress.current"
        :disabled="isValidating"
        @input="ipAddress.current = $event.target.value"
      />
      <telia-text-input
        v-if="shouldShowNewIpAddressInput"
        t-id="new-dhcp-relay"
        class="inputs__container__input"
        :label="newIpAddressInputLabel"
        :validation-state="$v.ipAddress.new.$error ? 'Invalid' : 'Valid'"
        :value="ipAddress.new"
        :disabled="isValidating"
        @input="ipAddress.new = $event.target.value"
      />
    </div>
    <telia-p
      variant="additional-100"
      :class="$v.$anyError ? 'additional-text-warning' : 'additional-text'"
      v-text="t('DATANET_IP_ADDRESS_PLACEHOLDER')"
    />
  </div>
</template>

<script>
import { corpValidationService } from "@telia/b2b-rest-client";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { mapState, mapGetters } from "vuex";
import ActionSelection from "../ActionSelection";
import Actions from "../../constants/Actions.js";
import { translateMixin } from "../../locale";
export default {
  name: "DhcpRelaySettings",
  mixins: [translateMixin, validationMixin],
  components: {
    ActionSelection,
  },

  props: {
    dhcpRelayKey: {
      type: String,
      default: "",
    },
    lanAction: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      action: Actions.ADD,
      ipAddress: {
        current: "",
        new: "",
      },
      ipVersion: {
        current: "",
        new: "",
      },
      timeoutIds: {
        currentIp: null,
        newIp: null,
      },
      promises: {
        currentIp: false,
        newIp: false,
      },
      isValidating: false,
    };
  },

  computed: {
    ...mapState({
      scopeId: (state) => state.user.scopeId,
    }),

    ...mapGetters(["isChangeOrder"]),

    ...mapGetters("ips", ["getIp"]),

    ...mapGetters("lans", ["isDhcpRelayActionEditable", "getLan"]),

    ...mapGetters("vpn", ["isPrimaryAccessVpn"]),

    shouldShowActionSelection() {
      if (!this.isChangeOrder) return false;
      if (!this.dhcpRelayKey) return true;
      return this.isPrimaryAccessVpn(this.getLan(this.getIp(this.dhcpRelayKey).owner).vpn);
    },

    shouldShowCurrentIpAddressInput() {
      return this.action !== Actions.ADD;
    },

    shouldShowNewIpAddressInput() {
      return this.action !== Actions.DELETE;
    },

    titleText() {
      return this.isChangeOrder
        ? this.t("DATANET_VPN_ADD_DHCP_RELAY_CHANGE")
        : this.t("DATANET_VPN_ADD_RELAY");
    },

    newIpAddressInputLabel() {
      return this.isChangeOrder ? this.t("DATANET_NEW_VALUE") : this.t("DATANET_VPN_DHCP_RELAY_IP");
    },

    disabledActions() {
      if (this.lanAction === Actions.ADD) {
        return [Actions.UPDATE, Actions.DELETE];
      }

      if (!this.isDhcpRelayActionEditable) {
        return [Actions.UPDATE, Actions.UPDATE, Actions.DELETE].filter(
          (action) => action !== this.action
        );
      }

      return [];
    },
  },

  validations: {
    ipAddress: {
      current: {
        required: requiredIf(function () {
          return this.action !== Actions.ADD;
        }),
      },
      new: {
        required: requiredIf(function () {
          return this.action !== Actions.DELETE;
        }),
      },
    },
    ipVersion: {
      current: {
        required: requiredIf(function (value) {
          return this.ipAddress.current ? !!value : false;
        }),
      },
      new: {
        required: requiredIf(function (value) {
          return this.ipAddress.new ? !!value : false;
        }),
      },
    },
  },

  methods: {
    handleActionSelection(action) {
      this.ipAddress.current = "";
      this.ipAddress.new = "";
      this.ipVersion.current = "";
      this.ipVersion.new = "";
      this.action = action;
    },

    validateCurrentIpAddressInput() {
      this.$v.ipAddress.current.$reset();
      this.$v.ipVersion.current.$reset();

      this.promises.currentIp = new Promise((resolve, reject) => {
        if (this.timeoutIds.currentIp) {
          clearTimeout(this.timeoutIds.currentIp);
        }
        this.timeoutIds.currentIp = setTimeout(async () => {
          try {
            const response = await corpValidationService.ValidationControllerService.validate(
              this.scopeId,
              {
                type: "IPADDRESS",
                value: this.ipAddress.current,
              }
            );

            if (!response) throw new Error("invalid");
            const data = await response;
            this.ipVersion.current = data.version;
            resolve();
          } catch (e) {
            reject(e);
          }
        }, 400);
      }).catch(() => {
        this.ipVersion.current = "";
      });
    },

    validateNewIpInput() {
      this.$v.ipAddress.new.$reset();
      this.$v.ipVersion.new.$reset();

      this.promises.newIp = new Promise((resolve, reject) => {
        if (this.timeoutIds.newIp) {
          clearTimeout(this.timeoutIds.newIp);
        }
        this.timeoutIds.newIp = setTimeout(async () => {
          try {
            const response = await corpValidationService.ValidationControllerService.validate(
              this.scopeId,
              {
                type: "IPADDRESS",
                value: this.ipAddress.new,
              }
            );

            if (!response) throw new Error("invalid");
            const data = await response;
            this.ipVersion.new = data.version;
            resolve();
          } catch (e) {
            reject(e);
          }
        }, 400);
      }).catch(() => {
        this.ipVersion.new = "";
      });
    },

    async getDhcpRelayData() {
      await this.validate();
      this.$v.$invalid
        ? this.$emit("dhcp-relay-data", null)
        : this.$emit("dhcp-relay-data", {
            action: this.action,
            ipAddress: this.ipAddress,
            ipVersion: this.ipVersion,
          });
    },

    async validate() {
      this.$v.$reset();
      this.isValidating = true;
      this.$emit("is-validating", this.isValidating);
      if (this.shouldShowNewIpAddressInput) {
        this.validateNewIpInput();
      }
      if (this.shouldShowCurrentIpAddressInput) {
        this.validateCurrentIpAddressInput();
      }
      await Promise.allSettled([this.promises.currentIp, this.promises.newIp]);
      this.$v.$touch();
      this.isValidating = false;
      this.$emit("is-validating", this.isValidating);
    },
  },

  created() {
    if (!this.dhcpRelayKey) return;

    const { action, ipAddress, ipVersion } = this.getIp(this.dhcpRelayKey);
    this.action = action;
    this.ipAddress.current = ipAddress.current;
    this.ipAddress.new = ipAddress.new;
    this.ipVersion.current = ipVersion.current;
    this.ipVersion.new = ipVersion.new;
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";
.inputs__container {
  display: flex;

  &__input {
    width: 100%;
    max-width: 32rem;

    &:first-child {
      margin-right: $telia-spacing-12;
    }
  }
}
.additional-text {
  color: $telia-gray-500;
}
.additional-text-warning {
  color: $telia-red-500;
}
.margin {
  &__bottom {
    &__4 {
      margin-bottom: $telia-spacing-4;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
}
</style>
