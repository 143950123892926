export default function updateExistingObjectProperties(
  objectToUpdate,
  objectWithUpdatedProperties
) {
  if (typeof objectToUpdate !== "object" || typeof objectWithUpdatedProperties !== "object") {
    return undefined;
  }

  const copyOfObjectToUpdate = { ...objectToUpdate };
  for (const key in objectWithUpdatedProperties) {
    if (
      objectWithUpdatedProperties[key] !== undefined &&
      Object.prototype.hasOwnProperty.call(copyOfObjectToUpdate, key)
    ) {
      copyOfObjectToUpdate[key] = objectWithUpdatedProperties[key];
    }
  }

  return copyOfObjectToUpdate;
}
