<template>
  <div>
    <VpnSettings
      v-if="getVpnsByAccess(Accesses.PRIMARY).length"
      :ref="`${Accesses.PRIMARY}-vpn`"
      :access-key="Accesses.PRIMARY"
      :vpns="getVpnsByAccess(Accesses.PRIMARY)"
    />
    <VpnSettings
      v-if="hasBackupAccess"
      :ref="`${Accesses.SECONDARY}-vpn`"
      :access-key="Accesses.SECONDARY"
      :vpns="getVpnsByAccess(Accesses.SECONDARY)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VpnSettings from "./vpn-settings/VpnSettings";
import { Accesses } from "../constants";

export default {
  name: "AddOns",

  components: {
    VpnSettings,
  },

  data() {
    return {
      Accesses,
    };
  },

  computed: {
    ...mapGetters(["isChangeOrder"]),

    ...mapGetters("access", ["hasBackupAccess"]),

    ...mapGetters("vpn", ["getVpnsByAccess", "hasVpns"]),
  },

  async created() {
    if (!this.isChangeOrder && !this.hasVpns) {
      await this.addVpn({ addDefaultLan: !this.isChangeOrder });
    }
  },

  methods: {
    ...mapActions("vpn", ["addVpn"]),

    async completeStep() {
      this.$emit("is-validating-vpn-settings", true);
      let hasValidPrimaryAccessVpnSettings = false;
      let hasValidSecondaryAccessVpnSettings = false;

      hasValidPrimaryAccessVpnSettings = await this.$refs[`${Accesses.PRIMARY}-vpn`].validate();

      if (this.hasBackupAccess) {
        hasValidSecondaryAccessVpnSettings = await this.$refs[
          `${Accesses.SECONDARY}-vpn`
        ].validate();
      }

      if (
        this.hasBackupAccess &&
        hasValidPrimaryAccessVpnSettings &&
        hasValidSecondaryAccessVpnSettings
      ) {
        this.$emit("step-complete");
      }

      if (!this.hasBackupAccess && hasValidPrimaryAccessVpnSettings) {
        this.$emit("step-complete");
      }
      this.$emit("is-validating-vpn-settings", false);
    },
  },
};
</script>
