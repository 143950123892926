<template>
  <div>
    <InformationBox v-if="activeTooltip === 'rt-tot'" @close="handleTooltip(null)">
      <RtTotTooltip />
    </InformationBox>
    <telia-heading tag="h4" variant="title-100">
      {{ t("DATANET_RT_TOT") }}
    </telia-heading>
    <div class="tooltip-wrapper">
      <div class="input-wrapper">
        <telia-select
          id="vpn-rt-tot"
          :options="JSON.stringify(rtTotOptions)"
          :disabled="!isEditable"
          @change="rtTot = $event.target.value"
        />
      </div>
      <telia-icon
        class="tooltip-icon-inputs"
        name="question"
        size="sm"
        @click="handleTooltip('rt-tot')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { translateMixin } from "../../locale";
import InformationBox from "../InformationBox.vue";
import RtTotTooltip from "../tooltip-information/vpn-settings/RtTotTooltip.vue";

export default {
  name: "RtTot",
  mixins: [translateMixin],
  props: {
    accessKey: {
      type: String,
      required: true,
    },
    activeTooltip: {
      type: String,
    },
  },
  components: {
    InformationBox,
    RtTotTooltip,
  },
  computed: {
    ...mapGetters("access", ["getAccess"]),
    ...mapGetters("products", ["getRtTotOptions"]),
    ...mapGetters("vpn", ["getVpnSettings", "isRtTotEditable", "isSecondaryRtTotEditable"]),
    isEditable() {
      if (this.accessKey === "secondary") {
        return this.isSecondaryRtTotEditable;
      } else {
        return this.isRtTotEditable;
      }
    },
    rtTot: {
      set(value) {
        this.setRtTot({ accessKey: this.accessKey, new: value });
      },
      get() {
        return this.getVpnSettings(this.accessKey).rtTot.new;
      },
    },
    rtTotOptions() {
      const bandwidth = this.getAccess(this.accessKey).bandwidth.new;
      return this.getRtTotOptions(this.accessKey, bandwidth).map((option) => {
        return {
          value: option.value,
          label: option.label,
          selected: option.value === this.rtTot,
        };
      });
    },
  },
  methods: {
    ...mapActions("vpn", ["setRtTot"]),
    handleTooltip(value) {
      this.$emit("activeTooltip", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";

.input-wrapper {
  max-width: 42rem;
  margin-bottom: $telia-spacing-24;
  flex: 1;
}
.tooltip-wrapper {
  display: flex;
}
.tooltip-icon-inputs {
  position: relative;
  top: 1.8rem;
  margin-left: $telia-spacing-12;
}
.tooltip-icon-inputs:hover {
  color: $telia-purple-500;
  cursor: pointer;
}
</style>
