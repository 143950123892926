var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("telia-heading", { attrs: { tag: "h4", variant: "title-100" } }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.t("DATANET_EXTENDED_AVAILABILITY_TYPE_TITLE")) +
            "\n  "
        ),
      ]),
      _c(
        "div",
        { staticClass: "margin__bottom__24" },
        [
          _c("telia-fieldset", [
            _c(
              "div",
              { staticClass: "chip-choice-wrapper" },
              _vm._l(_vm.backupOptions, function (backupOption) {
                return _c(
                  "telia-chip-choice",
                  {
                    key: backupOption.value,
                    ref: "backup",
                    refInFor: true,
                    attrs: {
                      id: "secondary-access-backup-options",
                      name: "secondary-access-backup-options",
                      caption: "fieldset for secondary-access-backup-options",
                      checked: backupOption.value === _vm.backup,
                      disabled: backupOption.disabled,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleBackupSelection(backupOption.value)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(backupOption.label) + "\n        "
                    ),
                  ]
                )
              }),
              1
            ),
          ]),
          _vm.$v.backup.$error
            ? _c("ValidationError", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.t("DATANET_EXTENDED_AVAILABILITY_TYPE_REQUIRED")
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.shouldShowSecondaryAddress
        ? _c(
            "div",
            { staticClass: "margin__bottom__24" },
            [
              _c("AddressSearch", {
                staticClass: "input-wrapper",
                attrs: {
                  label: _vm.t(
                    "DATANET_EXTENDED_AVAILABILITY_SECONDARY_ADDRESS"
                  ),
                  disabled: !_vm.isSecondaryAddressEditable,
                  value: _vm.secondaryFullAddress,
                },
                on: {
                  addressSelected: function ($event) {
                    return _vm.updateSecondaryAddress($event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("telia-heading", { attrs: { tag: "h4", variant: "title-100" } }, [
        _vm._v("\n    " + _vm._s(_vm.t("DATANET_ACCESS_TYPE")) + "\n  "),
      ]),
      _c(
        "div",
        { staticClass: "margin__bottom__24" },
        [
          _c("telia-fieldset", [
            _c(
              "div",
              { staticClass: "chip-choice-wrapper" },
              _vm._l(_vm.bearerOptions, function (bearerOption) {
                return _c(
                  "telia-chip-choice",
                  {
                    key: bearerOption.value,
                    ref: "bearer",
                    refInFor: true,
                    attrs: {
                      inline: "",
                      id: "secondary-access-bearer-options",
                      name: "secondary-access-bearer-options",
                      caption: "fieldset for secondary-access-bearer-options",
                      checked: bearerOption.value === _vm.bearer,
                      disabled: bearerOption.disabled,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleBearerSelection(bearerOption.value)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(bearerOption.label) + "\n        "
                    ),
                  ]
                )
              }),
              1
            ),
          ]),
          _vm.$v.bearer.$error
            ? _c("ValidationError", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.t("DATANET_EXTENDED_AVAILABILITY_BEARER_REQUIRED")
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.isFttxBearer
        ? _c("div", [
            _c(
              "div",
              { staticClass: "input-wrapper" },
              [
                _c("telia-select", {
                  ref: "apartment",
                  attrs: {
                    id: "apartment",
                    label: _vm.t("DATANET_APARTMENT_NUMBER"),
                    options: JSON.stringify(_vm.apartmentOptions),
                    "validation-message": _vm.t(
                      "DATANET_APARTMENT_NUMBER_REQUIRED"
                    ),
                  },
                  on: {
                    change: function ($event) {
                      _vm.apartment = $event.target.value
                    },
                  },
                }),
                _vm.$v.apartment.$error
                  ? _c("ValidationError", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.t("DATANET_APARTMENT_NUMBER_REQUIRED")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "input-wrapper" },
              [
                _c("telia-text-input", {
                  attrs: {
                    id: "installation-room",
                    label: _vm.t("DATANET_INSTALLATION_ROOM"),
                    value: _vm.installationRoom,
                  },
                  on: {
                    input: function ($event) {
                      _vm.installationRoom = $event.target.value
                    },
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "telia-heading",
        {
          staticClass: "margin__bottom__12",
          attrs: { tag: "h4", variant: "title-100" },
        },
        [_vm._v("\n    " + _vm._s(_vm.t("DATANET_ACCESS_SETTINGS")) + "\n  ")]
      ),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("telia-select", {
            ref: "bandwidth",
            attrs: {
              id: "secondary-access-bandwidth",
              label: _vm.t("DATANET_SPEED"),
              disabled: _vm.disableBandwidth,
              options: JSON.stringify(_vm.bandwidthOptions),
            },
            on: {
              change: function ($event) {
                return _vm.handleBandwidthSelection($event.target.value)
              },
            },
          }),
          _vm.$v.bandwidth.$error
            ? _c("ValidationError", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      !_vm.$v.bandwidth.required
                        ? _vm.t(
                            "DATANET_EXTENDED_AVAILABILITY_BANDWIDTH_REQUIRED"
                          )
                        : _vm.t(
                            "DATANET_EXTENDED_AVAILABILITY_BANDWIDTH_EXCEEDS_PRIMARY"
                          )
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("telia-select", {
            ref: "sla",
            attrs: {
              id: "secondary-access-sla",
              label: _vm.t("DATANET_SLA"),
              disabled: _vm.disableSla,
              options: JSON.stringify(_vm.slaOptions),
            },
            on: {
              change: function ($event) {
                _vm.sla = $event.target.value
              },
            },
          }),
          _vm.$v.sla.$error
            ? _c("ValidationError", [
                _vm._v(
                  "\n      " + _vm._s(_vm.t("DATANET_SLA_REQUIRED")) + "\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("telia-select", {
            ref: "qos",
            attrs: {
              id: "secondary-access-qos",
              label: _vm.t("DATANET_QOS"),
              disabled: _vm.disableQos,
              options: JSON.stringify(_vm.qosOptions),
            },
            on: {
              change: function ($event) {
                _vm.qos = $event.target.value
              },
            },
          }),
          _vm.$v.qos.$error
            ? _c("ValidationError", [
                _vm._v(
                  "\n      " + _vm._s(_vm.t("DATANET_QOS_REQUIRED")) + "\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.shouldShowSecondaryAccessTrafficStatistics
        ? _c("StatBas", {
            staticClass: "component-wrapper",
            attrs: {
              id: "secondary-access-stat-bas",
              "access-key": _vm.accessKey,
              "initial-value": _vm.statBas,
            },
            on: {
              "request-stat-bas": function ($event) {
                _vm.statBas = $event
              },
            },
          })
        : _vm._e(),
      _vm.isWirelessBearer
        ? _c(
            "div",
            [
              _c("WirelessInstallation", {
                staticClass: "component-wrapper",
                attrs: {
                  id: "request-installation-by-telia-for-secondary-access",
                  "initial-value": _vm.wirelessInstallation,
                  disabled: _vm.useTemporaryAccessAsBackup,
                },
                on: {
                  "request-installation-by-telia": function ($event) {
                    _vm.wirelessInstallation = $event.target.value
                  },
                },
              }),
              _c("AntennaPackage", {
                staticClass: "component-wrapper",
                attrs: {
                  "access-key": _vm.accessKey,
                  disable: _vm.useTemporaryAccessAsBackup,
                },
                on: {
                  "antenna-package-data": function ($event) {
                    return _vm.handleAntennaPackageData($event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "button-wrapper" },
        [
          _c(
            "telia-button",
            {
              attrs: {
                id: "save-secondary-access-configuration",
                "t-id": "save-secondary-access-configuration",
                variant: "expressive",
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.saveAndClose()
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.t("DATANET_SAVE_CHANGES")) + "\n    "
              ),
            ]
          ),
          _c(
            "telia-button",
            {
              attrs: {
                id: "cancel-secondary-access-configuration",
                type: "button",
                variant: "secondary",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("complete")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.t("DATANET_ABORT")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }