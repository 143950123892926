var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "configure-dns-ip" },
      [
        _c(
          "telia-heading",
          {
            staticClass: "margin__bottom__12",
            attrs: { tag: "h4", variant: "title-100" },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.t("DATANET_VPN_CONFIGURE_DNS_IP")) +
                "\n    "
            ),
          ]
        ),
        _vm.isChangeOrder
          ? _c(
              "div",
              { attrs: { "t-id": "action-select" } },
              [
                _c("ActionSelection", {
                  attrs: {
                    "selected-action": _vm.action,
                    "disabled-actions": _vm.disabledActions,
                  },
                  on: {
                    selected: function ($event) {
                      _vm.action = $event
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "row margin__bottom__4" }, [
          _c("div", { staticClass: "column-container" }, [
            _vm.isCurrentValuesRequired
              ? _c(
                  "div",
                  { staticClass: "column input-wrapper" },
                  [
                    _c("telia-text-input", {
                      attrs: {
                        "t-id": "current-dns-ip",
                        label: _vm.t("DATANET_CURRENT_VALUE"),
                        value: _vm.ipAddress.current,
                      },
                      on: {
                        input: function ($event) {
                          _vm.ipAddress.current = $event.target.value
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isNewValuesRequired
              ? _c(
                  "div",
                  { staticClass: "column input-wrapper" },
                  [
                    _c("telia-text-input", {
                      attrs: {
                        "t-id": "new-dns-ip",
                        label: _vm.t("DATANET_NEW_VALUE"),
                        value: _vm.ipAddress.new,
                      },
                      on: {
                        input: function ($event) {
                          _vm.ipAddress.new = $event.target.value
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "column-container" },
            [
              _vm.$v.$error
                ? _c("ValidationError", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.t("DATANET_INVALID_IP_ADDRESS")) +
                        "\n        "
                    ),
                  ])
                : _c(
                    "telia-p",
                    {
                      staticClass: "additional-text",
                      attrs: { variant: "additional-100" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.t("DATANET_IP_ADDRESS_PLACEHOLDER")) +
                          "\n        "
                      ),
                    ]
                  ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "button-wrapper" },
      [
        _c(
          "telia-button",
          {
            attrs: {
              "t-id": "save-dns-ip-config",
              type: "button",
              variant: "expressive",
              size: "sm",
              disabled: _vm.isValidating,
            },
            on: {
              click: function ($event) {
                return _vm.saveAndClose()
              },
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.t("DATANET_SAVE_CHANGES")) + "\n    "
            ),
          ]
        ),
        _c(
          "telia-button",
          {
            attrs: {
              id: "cancel-dns-ip-address-configuration",
              type: "button",
              variant: "secondary",
              size: "sm",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("complete")
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.t("DATANET_ABORT")) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }