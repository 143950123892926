var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "action-selection", attrs: { "t-id": "action-selection" } },
    _vm._l(_vm.actionOptions, function (action) {
      return _c(
        "telia-chip-choice",
        {
          key: action.value,
          attrs: {
            value: action.value,
            checked: action.value === _vm.selectedAction,
            disabled: action.disabled,
          },
          on: {
            change: function ($event) {
              return _vm.handleSelection($event.target.value)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(action.label) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }