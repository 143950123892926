var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("telia-heading", {
        staticClass: "margin__bottom__12",
        attrs: { tag: "h3", variant: "title-100" },
        domProps: { textContent: _vm._s(_vm.titleText) },
      }),
      _vm.shouldShowActionSelection
        ? _c("ActionSelection", {
            staticClass: "margin__bottom__24",
            attrs: {
              "selected-action": _vm.action,
              "disabled-actions": _vm.disabledActions,
            },
            on: {
              selected: function ($event) {
                return _vm.handleActionSelection($event)
              },
            },
          })
        : _vm._e(),
      _vm.isChangeOrder
        ? _c("telia-p", [
            _c("strong", [_vm._v(_vm._s(_vm.t("DATANET_VPN_DHCP_RELAY")))]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "margin__bottom__4 inputs__container" },
        [
          _vm.shouldShowCurrentIpAddressInput
            ? _c("telia-text-input", {
                staticClass: "inputs__container__input",
                attrs: {
                  "t-id": "current-dhcp-relay",
                  label: _vm.t("DATANET_CURRENT_VALUE"),
                  "validation-state": _vm.$v.ipAddress.current.$error
                    ? "Invalid"
                    : "Valid",
                  value: _vm.ipAddress.current,
                  disabled: _vm.isValidating,
                },
                on: {
                  input: function ($event) {
                    _vm.ipAddress.current = $event.target.value
                  },
                },
              })
            : _vm._e(),
          _vm.shouldShowNewIpAddressInput
            ? _c("telia-text-input", {
                staticClass: "inputs__container__input",
                attrs: {
                  "t-id": "new-dhcp-relay",
                  label: _vm.newIpAddressInputLabel,
                  "validation-state": _vm.$v.ipAddress.new.$error
                    ? "Invalid"
                    : "Valid",
                  value: _vm.ipAddress.new,
                  disabled: _vm.isValidating,
                },
                on: {
                  input: function ($event) {
                    _vm.ipAddress.new = $event.target.value
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("telia-p", {
        class: _vm.$v.$anyError ? "additional-text-warning" : "additional-text",
        attrs: { variant: "additional-100" },
        domProps: {
          textContent: _vm._s(_vm.t("DATANET_IP_ADDRESS_PLACEHOLDER")),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }