var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SummaryList",
    [
      _c(
        "SummaryRow",
        { attrs: { variant: "header" } },
        [
          _c(
            "SummaryColumn",
            { staticClass: "summary-column--small" },
            [_c("telia-p", [_vm._v(" " + _vm._s(_vm.t("DATANET_ACCESS")))])],
            1
          ),
          _c(
            "SummaryColumn",
            { staticClass: "summary-column--large" },
            [
              _c(
                "SummaryRow",
                {
                  staticClass: "summary-row--nested",
                  attrs: { variant: "header" },
                },
                [
                  _c(
                    "SummaryColumn",
                    [
                      _c("telia-p", [
                        _vm._v(" " + _vm._s(_vm.t("DATANET_SETTING"))),
                      ]),
                    ],
                    1
                  ),
                  _c("SummaryColumn", [_c("telia-p", [_vm._v(" ")])], 1),
                  _c(
                    "SummaryColumn",
                    [
                      _c("telia-p", [
                        _vm._v(" " + _vm._s(_vm.t("DATANET_CURRENT_VALUE"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "SummaryColumn",
                    [
                      _c("telia-p", [
                        _vm._v(" " + _vm._s(_vm.t("DATANET_NEW_VALUE"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "SummaryRow",
        [
          _c(
            "SummaryColumn",
            {
              staticClass: "summary-column--small",
              attrs: { variant: "align-top" },
            },
            [
              _c("telia-p", [
                _c("strong", [_vm._v(_vm._s(_vm.t("DATANET_PRIMARY_ACCESS")))]),
              ]),
            ],
            1
          ),
          _c(
            "SummaryColumn",
            {
              staticClass: "summary-column--large",
              attrs: { variant: "align-top" },
            },
            [
              _c(
                "SummaryRow",
                { staticClass: "summary-row--nested" },
                [
                  _c(
                    "SummaryColumn",
                    { attrs: { variant: "align-top" } },
                    [
                      _c("telia-p", [
                        _vm._v(_vm._s(_vm.t("DATANET_ACCESS_BASE_SETTINGS"))),
                      ]),
                    ],
                    1
                  ),
                  _c("SummaryColumn", { attrs: { variant: "align-top" } }, [
                    _c(
                      "div",
                      { staticClass: "field-name" },
                      [
                        _c("telia-p", [
                          _vm._v(_vm._s(_vm.t("DATANET_ACCESS_TYPE"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "field-name" },
                      [_c("telia-p", [_vm._v(_vm._s(_vm.t("DATANET_SPEED")))])],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "field-name" },
                      [
                        _c("telia-p", [
                          _vm._v(_vm._s(_vm.t("DATANET_SLA_SHORT"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "field-name" },
                      [_c("telia-p", [_vm._v(_vm._s(_vm.t("DATANET_QOS")))])],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "field-name" },
                      [
                        _c("telia-p", [
                          _vm._v(_vm._s(_vm.t("DATANET_STAT_BAS"))),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("SummaryColumn", { attrs: { variant: "align-top" } }, [
                    _c(
                      "div",
                      [
                        _c("telia-p", [
                          _vm._v(
                            _vm._s(
                              this.primaryAccessBearer
                                ? _vm.t("DATANET_" + this.primaryAccessBearer)
                                : "-"
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("telia-p", [
                          _vm._v(
                            _vm._s(_vm.getPrimaryAccessBandwidthText(true))
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("telia-p", [
                          _vm._v(_vm._s(_vm.primaryAccess.sla.current || "-")),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("telia-p", [
                          _vm._v(
                            _vm._s(
                              _vm.primaryAccess.qos.current
                                ? _vm.t(
                                    "DATANET_" +
                                      _vm.primaryAccess.qos.current +
                                      "_DESCRIPTION"
                                  )
                                : "-"
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("telia-p", [
                          _vm._v(
                            _vm._s(
                              _vm.primaryAccess.options.trafficStatistics
                                .current
                                ? this.t("DATANET_YES")
                                : this.t("DATANET_NO")
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("SummaryColumn", { attrs: { variant: "align-top" } }, [
                    _c("div", [_c("telia-p", [_vm._v(" ")])], 1),
                    _c(
                      "div",
                      {
                        class:
                          _vm.getPrimaryAccessBandwidthText() !==
                          _vm.getPrimaryAccessBandwidthText(true)
                            ? "field-value--changed"
                            : "",
                      },
                      [
                        _c("telia-p", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.getPrimaryAccessBandwidthText() !==
                                  _vm.getPrimaryAccessBandwidthText(true)
                                  ? _vm.getPrimaryAccessBandwidthText()
                                  : "-"
                              ) +
                              "\n            "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.primaryAccess.sla.new !==
                          _vm.primaryAccess.sla.current
                            ? "field-value--changed"
                            : "",
                      },
                      [
                        _c("telia-p", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.primaryAccess.sla.new !==
                                  _vm.primaryAccess.sla.current
                                  ? _vm.primaryAccess.sla.new
                                  : "-"
                              ) +
                              "\n            "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.primaryAccess.qos.new !==
                          _vm.primaryAccess.qos.current
                            ? "field-value--changed"
                            : "",
                      },
                      [
                        _c("telia-p", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.primaryAccess.qos.new !==
                                  _vm.primaryAccess.qos.current
                                  ? _vm.t(
                                      "DATANET_" +
                                        _vm.primaryAccess.qos.new +
                                        "_DESCRIPTION"
                                    )
                                  : "-"
                              ) +
                              "\n            "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.primaryAccess.options.trafficStatistics
                            .current !==
                          _vm.primaryAccess.options.trafficStatistics.new
                            ? "field-value--changed"
                            : "",
                      },
                      [
                        _c("telia-p", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.primaryAccess.options.trafficStatistics
                                  .current !==
                                  _vm.primaryAccess.options.trafficStatistics
                                    .new
                                  ? _vm.primaryAccess.options.trafficStatistics
                                      .new
                                    ? this.t("DATANET_YES")
                                    : this.t("DATANET_NO")
                                  : "-"
                              ) +
                              "\n            "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._l(
                _vm.getSnmpCommunities,
                function (community, communityIndex) {
                  return _c(
                    "SummaryRow",
                    {
                      key: "community-" + communityIndex,
                      staticClass: "summary-row--nested",
                    },
                    [
                      _c(
                        "SummaryColumn",
                        {
                          staticClass: "field-value--changed",
                          attrs: { variant: "align-top" },
                        },
                        [
                          _c("telia-p", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.t("DATANET_ACTION_" + community.action) +
                                    " " +
                                    _vm.t("DATANET_SNMP_COMMUNITY")
                                ) +
                                "\n          "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "SummaryColumn",
                        { attrs: { variant: "align-top" } },
                        [
                          _c(
                            "div",
                            { staticClass: "field-name" },
                            [
                              _c("telia-p", [
                                _vm._v(
                                  _vm._s(_vm.t("DATANET_SNMP_COMMUNITY_NAME"))
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._l(
                            community.networks,
                            function (network, networkIndex) {
                              return _c(
                                "div",
                                {
                                  key:
                                    "community-" +
                                    communityIndex +
                                    "-network-" +
                                    networkIndex +
                                    "-title",
                                  staticClass: "field-name",
                                },
                                [
                                  _c("telia-p", [
                                    _vm._v(
                                      _vm._s(_vm.t("DATANET_SNMP_NETWORK"))
                                    ),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "SummaryColumn",
                        { attrs: { variant: "align-top" } },
                        [
                          _c(
                            "div",
                            [
                              _c("telia-p", [
                                _vm._v(
                                  _vm._s(community.communityName.current || "-")
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._l(
                            community.networks,
                            function (network, networkIndex) {
                              return _c(
                                "div",
                                {
                                  key:
                                    "community-" +
                                    communityIndex +
                                    "-network-" +
                                    networkIndex +
                                    "-current",
                                },
                                [
                                  _c("telia-p", [
                                    _vm._v(
                                      _vm._s(network.input.current || "-")
                                    ),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "SummaryColumn",
                        { attrs: { variant: "align-top" } },
                        [
                          _c(
                            "div",
                            {
                              class: community.communityName.new
                                ? "field-value--changed"
                                : "",
                            },
                            [
                              _c("telia-p", [
                                _vm._v(
                                  _vm._s(community.communityName.new || "-")
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._l(
                            community.networks,
                            function (network, networkIndex) {
                              return _c(
                                "div",
                                {
                                  key:
                                    "community-" +
                                    communityIndex +
                                    "-network-" +
                                    networkIndex +
                                    "-new",
                                  class: network.input.new
                                    ? "field-value--changed"
                                    : "",
                                },
                                [
                                  _c("telia-p", [
                                    _vm._v(_vm._s(network.input.new || "-")),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }
              ),
              _vm.internetOnSiteAction === "UPDATE" ||
              _vm.internetOnSiteAction === "DELETE"
                ? _c(
                    "SummaryRow",
                    { staticClass: "summary-row--nested" },
                    [
                      _c(
                        "SummaryColumn",
                        {
                          staticClass: "field-value--changed",
                          attrs: { variant: "align-top" },
                        },
                        [
                          _c("telia-p", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.t(
                                    "DATANET_ACTION_" + _vm.internetOnSiteAction
                                  ) +
                                    " " +
                                    _vm.t("DATANET_INTERNET_ON_SITE")
                                ) +
                                "\n          "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("SummaryColumn", { attrs: { variant: "align-top" } }, [
                        _vm.internetOnSiteAction === "UPDATE" &&
                        _vm.isInternetOnSiteCapacityChanged
                          ? _c(
                              "div",
                              { staticClass: "field-name" },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.t(
                                        "DATANET_INTERNET_ON_SITE_SELECT_BANDWIDTH"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.internetOnSiteAction === "UPDATE" &&
                        _vm.isInternetOnSiteEthernetInterfacesChanged
                          ? _c(
                              "div",
                              { staticClass: "field-name" },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.t(
                                        "DATANET_INTERNET_ON_SITE_SELECT_ETHERNET_INTERFACES"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("SummaryColumn", { attrs: { variant: "align-top" } }, [
                        _vm.internetOnSiteAction === "UPDATE" &&
                        _vm.isInternetOnSiteCapacityChanged
                          ? _c(
                              "div",
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currentSelectedBandwidthText || "-"
                                    )
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.internetOnSiteAction === "UPDATE" &&
                        _vm.isInternetOnSiteEthernetInterfacesChanged
                          ? _c(
                              "div",
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        "" +
                                          (_vm.currentExtraEthernetInterfaces >
                                          0
                                            ? _vm.t(
                                                "DATANET_INTERNET_ON_SITE_EXTRA_ETHERNET_INTERFACES",
                                                {
                                                  number:
                                                    _vm.currentExtraEthernetInterfaces,
                                                }
                                              )
                                            : "-")
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("SummaryColumn", { attrs: { variant: "align-top" } }, [
                        _vm.internetOnSiteAction === "UPDATE" &&
                        _vm.isInternetOnSiteCapacityChanged
                          ? _c(
                              "div",
                              { staticClass: "field-value--changed" },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(_vm.newSelectedBandwidthText || "-")
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.internetOnSiteAction === "UPDATE" &&
                        _vm.isInternetOnSiteEthernetInterfacesChanged
                          ? _c(
                              "div",
                              { staticClass: "field-value--changed" },
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        "" +
                                          (_vm.extraEthernetInterfaces > 0
                                            ? _vm.t(
                                                "DATANET_INTERNET_ON_SITE_EXTRA_ETHERNET_INTERFACES",
                                                {
                                                  number:
                                                    _vm.extraEthernetInterfaces,
                                                }
                                              )
                                            : _vm.t(
                                                "DATANET_INTERNET_ON_SITE_NO_EXTRA_ETHERNET_INTERFACES"
                                              ))
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }