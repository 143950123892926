<template>
  <div>
    <telia-heading tag="h3" variant="title-100" v-text="titleText" class="margin__bottom__12" />
    <ActionSelection
      v-if="shouldShowActionSelection"
      class="margin__bottom__24"
      :selected-action="network.action"
      :disabled-actions="disabledActions"
      @selected="handleActionSelection($event)"
    />

    <telia-p v-if="isChangeOrder">
      <strong>{{ t("DATANET_VPN_LAN_NETWORK") }}</strong>
    </telia-p>

    <div class="margin__bottom__24">
      <div class="margin__bottom__4 inputs-wrapper">
        <telia-text-input
          v-if="shouldShowCurrentNetworkInput"
          t-id="current-lan-network"
          class="input-container"
          :label="t('DATANET_CURRENT_VALUE')"
          :disabled="isValidating"
          :value="network.input.current"
          @input="network.input.current = $event.target.value"
        />
        <telia-text-input
          v-if="shouldShowNewNetworkInput"
          t-id="new-lan-network"
          class="input-container"
          :label="newNetworkLabel"
          :disabled="isValidating"
          :value="network.input.new"
          @input="network.input.new = $event.target.value"
        />
      </div>
      <telia-p
        variant="additional-100"
        :class="isAnyNetworkInvalid ? 'additional-text-warning' : 'additional-text'"
        v-text="t('DATANET_VPN_LAN_NETWORK_PLACEHOLDER')"
      />
    </div>

    <template v-if="shouldShowVirtualIpInputs">
      <telia-p v-if="isChangeOrder">
        <strong>{{ t("DATANET_VPN_LAN_VIRTUAL_IP") }}</strong>
      </telia-p>

      <div class="margin__bottom__4 inputs-wrapper">
        <telia-text-input
          v-if="shouldShowCurrentNetworkInput"
          class="input-container"
          :label="t('DATANET_CURRENT_VALUE')"
          :disabled="shouldDisableVirtualIpInputs"
          :value="network.virtualIp.current"
          @input="network.virtualIp.current = $event.target.value"
        />
        <telia-text-input
          v-if="shouldShowNewNetworkInput"
          class="input-container"
          t-id="new-virtual-ip"
          :label="newVirtualIpLabel"
          :disabled="shouldDisableVirtualIpInputs"
          :value="network.virtualIp.new"
          @input="network.virtualIp.new = $event.target.value"
        />
      </div>
      <telia-p
        :class="isAnyVirtualIpInvalid ? 'additional-text-warning' : 'additional-text'"
        variant="additional-100"
        v-text="t('DATANET_VPN_LAN_VIRTUAL_IP_PLACEHOLDER')"
      />
    </template>
  </div>
</template>

<script>
import { corpValidationService } from "@telia/b2b-rest-client";
import { mapState, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import ActionSelection from "../ActionSelection";
import Actions from "../../constants/Actions.js";
import { translateMixin } from "../../locale";

const changeableValue = () => ({
  action: Actions.ADD,
  current: "",
  new: "",
});

const defaultNetwork = () => ({
  action: Actions.ADD,
  input: changeableValue(),
  ipAddress: changeableValue(),
  ipVersion: changeableValue(),
  ipPrefix: changeableValue(),
  virtualIp: changeableValue(),
});

export default {
  name: "LanNetworkSettings",
  mixins: [translateMixin, validationMixin],
  components: {
    ActionSelection,
  },

  props: {
    initialNetwork: {
      type: Object,
      default: () => defaultNetwork(),
    },
    isPrimaryLan: {
      type: Boolean,
      required: true,
    },
    lanAction: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      Actions,
      network: { ...this.initialNetwork },
      timeoutIds: {
        currentNetwork: null,
        currentVirtualIp: null,
        newNetwork: null,
        newVirtualIp: null,
      },
      promises: {
        currentNetwork: null,
        currentVirtualIp: null,
        newNetwork: null,
        newVirtualIp: null,
      },
      isValidating: false,
      isValidCurrentVirtualIp: false,
      isValidNewVirtualIp: false,
    };
  },

  validations: {
    network: {
      input: {
        current: {
          required: requiredIf(function () {
            return this.network.action !== Actions.ADD;
          }),
        },
        new: {
          required: requiredIf(function () {
            return this.network.action !== Actions.DELETE;
          }),
        },
      },
      ipAddress: {
        current: {
          required: requiredIf(function (value) {
            if (!value) return false;
            return this.network.action !== Actions.ADD;
          }),
        },
        new: {
          required: requiredIf(function (value) {
            if (!value) return false;
            return this.network.action !== Actions.DELETE;
          }),
        },
      },
      virtualIp: {
        current: {
          required: requiredIf(function () {
            if (!this.shouldShowVirtualIpInputs) return false;
            return this.network.action !== Actions.ADD;
          }),
          valid: function (value) {
            if (!value) return true;
            if (!this.shouldShowVirtualIpInputs) return true;
            if (this.shouldDisableVirtualIpInputs) return true;
            return this.isValidCurrentVirtualIp;
          },
        },
        new: {
          required: requiredIf(function () {
            if (!this.shouldShowVirtualIpInputs) return false;
            return this.network.action !== Actions.DELETE;
          }),
          valid: function (value) {
            if (!value) return true;
            if (!this.shouldShowVirtualIpInputs) return true;
            if (this.shouldDisableVirtualIpInputs) return true;
            return this.isValidNewVirtualIp;
          },
        },
      },
    },
  },

  computed: {
    ...mapState({
      scopeId: (state) => state.user.scopeId,
    }),

    ...mapGetters(["isChangeOrder"]),

    ...mapGetters("lans", ["shouldShowVirtualIp"]),

    shouldShowActionSelection() {
      return this.isChangeOrder && this.isPrimaryLan;
    },

    shouldShowCurrentNetworkInput() {
      return this.network.action !== Actions.ADD;
    },

    shouldShowNewNetworkInput() {
      return this.network.action !== Actions.DELETE;
    },

    shouldShowVirtualIpInputs() {
      return this.shouldShowVirtualIp && this.network.action !== Actions.DELETE;
    },

    shouldDisableVirtualIpInputs() {
      return this.isValidating || !this.isPrimaryLan;
    },

    isCurrentNetworkInvalid() {
      return this.$v.network.input.current.$error || this.$v.network.ipAddress.current.$error;
    },

    isNewNetworkInvalid() {
      return this.$v.network.input.new.$error || this.$v.network.ipAddress.new.$error;
    },

    isAnyNetworkInvalid() {
      return this.isCurrentNetworkInvalid || this.isNewNetworkInvalid;
    },

    isAnyVirtualIpInvalid() {
      return this.$v.network.virtualIp.current.$error || this.$v.network.virtualIp.new.$error;
    },

    titleText() {
      if (this.isChangeOrder) return this.t("DATANET_VPN_LAN_ADD_NETWORK_CHANGE");
      else return this.t(`DATANET_VPN_LAN_${this.network.action}_NETWORK`);
    },

    newNetworkLabel() {
      return this.isChangeOrder ? this.t("DATANET_NEW_VALUE") : this.t("DATANET_VPN_LAN_NETWORK");
    },

    newVirtualIpLabel() {
      return this.isChangeOrder
        ? this.t("DATANET_NEW_VALUE")
        : this.t("DATANET_VPN_LAN_VIRTUAL_IP");
    },

    disabledActions() {
      return this.lanAction === Actions.ADD ? [Actions.UPDATE, Actions.DELETE] : [];
    },
  },

  created() {
    if (this.shouldShowVirtualIp) {
      this.network.virtualIp.action = this.network.action;
    }
  },

  methods: {
    handleActionSelection(action) {
      this.$set(this, "network", defaultNetwork());
      this.$v.$reset();
      this.network.action = action;
      if (this.shouldShowVirtualIp) {
        this.network.virtualIp.action = action;
      } else {
        this.network.virtualIp.action = Actions.NONE;
      }
    },

    validateCurrentNetworkInput() {
      this.$v.network.input.current.$reset();

      this.promises.currentNetwork = new Promise((resolve, reject) => {
        if (this.timeoutIds.currentNetwork) {
          clearTimeout(this.timeoutIds.currentNetwork);
        }

        this.timeoutIds.currentNetwork = setTimeout(async () => {
          try {
            const response = await corpValidationService.ValidationControllerService.validate(
              this.scopeId,
              {
                type: "NETWORK",
                value: this.network.input.current,
              }
            );
            if (!response) throw new Error("");

            const data = await response;
            this.network.ipVersion.current = data.ipVersion;
            this.network.ipAddress.current = data.network;
            this.network.ipPrefix.current = data.prefix;
            resolve();
          } catch (e) {
            reject(e);
          }
        }, 400);
      }).catch(() => {
        this.network.ipVersion.current = "";
        this.network.ipAddress.current = "";
        this.network.ipPrefix.current = "";
      });
    },

    validateNewNetworkInput() {
      this.$v.network.input.new.$reset();
      this.$v.network.ipAddress.new.$reset();

      this.promises.newNetwork = new Promise((resolve, reject) => {
        if (this.timeoutIds.newNetwork) {
          clearTimeout(this.timeoutIds.newNetwork);
        }

        this.timeoutIds.newNetwork = setTimeout(async () => {
          try {
            const response = await corpValidationService.ValidationControllerService.validate(
              this.scopeId,
              {
                type: "NETWORK",
                value: this.network.input.new,
              }
            );
            if (!response) throw new Error("invalid");

            const data = await response;
            this.network.ipVersion.new = data.ipVersion;
            this.network.ipAddress.new = data.network;
            this.network.ipPrefix.new = data.prefix;
            resolve();
          } catch (e) {
            reject(e);
          }
        }, 400);
      }).catch(() => {
        this.network.ipVersion.new = "";
        this.network.ipAddress.new = "";
        this.network.ipPrefix.new = "";
      });
    },

    validateCurrentNetworkVirtualIpInput() {
      this.$v.network.virtualIp.current.$reset();

      this.promises.currentVirtualIp = new Promise((resolve, reject) => {
        if (this.timeoutIds.currentVirtualIp) {
          clearTimeout(this.timeoutIds.currentVirtualIp);
        }

        this.timeoutIds.currentVirtualIp = setTimeout(async () => {
          try {
            const response = await corpValidationService.ValidationControllerService.validate(
              this.scopeId,
              {
                type: "IPADDRESS",
                value: this.network.virtualIp.new,
              }
            );

            if (!response) throw new Error("invalid");

            this.isValidCurrentVirtualIp = true;
            resolve();
          } catch (e) {
            reject(e);
          }
        }, 400);
      }).catch(() => {
        this.isValidCurrentVirtualIp = false;
      });
    },

    validateNewNetworkVirtualIpInput() {
      this.$v.network.virtualIp.new.$reset();

      this.promises.newVirtualIp = new Promise((resolve, reject) => {
        if (this.timeoutIds.newVirtualIp) {
          clearTimeout(this.timeoutIds.newVirtualIp);
        }

        this.timeoutIds.newVirtualIp = setTimeout(async () => {
          try {
            const response = await corpValidationService.ValidationControllerService.validate(
              this.scopeId,
              {
                type: "IPADDRESS",
                value: this.network.virtualIp.new,
              }
            );

            if (!response) throw new Error("invalid");

            this.isValidNewVirtualIp = true;
            resolve();
          } catch (e) {
            reject(e);
          }
        }, 400);
      }).catch(() => {
        this.isValidNewVirtualIp = false;
      });
    },

    async getLanNetworkData() {
      await this.validate();
      this.$v.$invalid
        ? this.$emit("lan-network-data", null)
        : this.$emit("lan-network-data", { ...this.network });
    },

    async validate() {
      this.$v.$reset();
      this.isValidating = true;
      this.$emit("is-validating", this.isValidating);

      if (this.shouldShowNewNetworkInput) {
        this.validateNewNetworkInput();
        if (this.shouldShowVirtualIpInputs) {
          this.validateNewNetworkVirtualIpInput();
        }
      }

      if (this.shouldShowCurrentNetworkInput) {
        this.validateCurrentNetworkInput();
        if (this.shouldShowVirtualIpInputs) {
          this.validateCurrentNetworkVirtualIpInput();
        }
      }

      await Promise.allSettled([
        this.promises.currentNetwork,
        this.promises.currentVirtualIp,
        this.promises.newNetwork,
        this.promises.newVirtualIp,
      ]);

      this.$v.$touch();
      this.isValidating = false;
      this.$emit("is-validating", this.isValidating);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";

.inputs-wrapper {
  display: flex;
}

.input-container {
  width: 100%;
  max-width: 32rem;
  margin-right: $telia-spacing-12;
}

.input-container:last-child {
  margin-right: 0;
}
.margin {
  &__bottom {
    &__4 {
      margin-bottom: $telia-spacing-4;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
}
.additional-text {
  color: $telia-gray-500;
}
.additional-text-warning {
  color: $telia-red-500;
}
</style>
