var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "telia-heading",
            {
              staticClass: "margin__bottom__12",
              attrs: { tag: "h3", variant: "title-100" },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.isChangeOrder
                      ? _vm.t("DATANET_SNMP_CHANGE_COMMUNITY")
                      : _vm.t("DATANET_SNMP_ADD_COMMUNITY")
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm.isChangeOrder
            ? _c("ActionSelection", {
                staticClass: "component-wrapper",
                attrs: {
                  "selected-action": _vm.action,
                  "disabled-actions": _vm.disabledActions,
                },
                on: {
                  selected: function ($event) {
                    return _vm.setCommunityAction($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("telia-p", [
        _c("strong", [_vm._v(_vm._s(_vm.t("DATANET_SNMP_COMMUNITY_NAME")))]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "column-container" },
          [
            _vm.isCurrentValuesRequired
              ? _c("telia-text-input", {
                  staticClass: "column input-wrapper",
                  attrs: {
                    "t-id": "current-snmp-community-name",
                    label: _vm.isChangeOrder
                      ? _vm.t("DATANET_CURRENT_VALUE")
                      : "",
                    disabled:
                      _vm.shouldDisablePrimaryAccessSnmp || _vm.isValidating,
                    value: _vm.communityName.current,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.setCommunityName({
                        current: $event.target.value,
                      })
                    },
                  },
                })
              : _vm._e(),
            _vm.isNewCommunityNameRequired
              ? _c("telia-text-input", {
                  staticClass: "column input-wrapper",
                  attrs: {
                    "t-id": "new-snmp-community-name",
                    label: _vm.isChangeOrder ? _vm.t("DATANET_NEW_VALUE") : "",
                    disabled:
                      _vm.shouldDisablePrimaryAccessSnmp || _vm.isValidating,
                    value: _vm.communityName.new,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.setCommunityName({ new: $event.target.value })
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row margin-bottom__12" }, [
        _c(
          "div",
          { staticClass: "column-container" },
          [
            _vm.$v.communityName.$error
              ? _c("ValidationError", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.t("DATANET_SNMP_COMMUNITY_NAME_REQUIRED")) +
                      "\n      "
                  ),
                ])
              : _c("telia-p", { staticClass: "placeholder-text" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.t("DATANET_SNMP_COMMUNITY_NAME_PLACEHOLDER")) +
                      "\n      "
                  ),
                ]),
          ],
          1
        ),
      ]),
      _vm.isActionUpdate
        ? _c(
            "telia-checkbox",
            {
              staticClass: "margin-bottom__24",
              attrs: {
                "t-id": "change-community-name",
                value: !_vm.changeCommunityName,
                checked: _vm.changeCommunityName,
              },
              on: {
                input: function ($event) {
                  _vm.changeCommunityName = !_vm.changeCommunityName
                },
              },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.t("DATANET_SNMP_CHANGE_COMMUNITY_NAME")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      !_vm.isActionDelete
        ? _c(
            "div",
            [
              !_vm.shouldDisablePrimaryAccessSnmp || _vm.networks.length > 0
                ? _c("telia-p", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.t("DATANET_SNMP_NETWORKS"))),
                    ]),
                  ])
                : _vm._e(),
              _vm.$v.networks.$dirty && !_vm.$v.networks.minOneRequired
                ? _c("ValidationError", { staticClass: "margin-bottom-12" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.t("DATANET_SNMP_MIN_1_NETWORK_REQUIRED")) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._l(_vm.networks, function (network, networkIndex) {
                return _c(
                  "div",
                  { key: networkIndex, staticClass: "margin-bottom-8" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "column-container" },
                        [
                          _vm.isCurrentValuesRequired
                            ? _c("telia-text-input", {
                                staticClass: "column input-wrapper",
                                attrs: {
                                  "t-id":
                                    "network-" + networkIndex + "-current",
                                  label: _vm.isChangeOrder
                                    ? _vm.t("DATANET_CURRENT_VALUE")
                                    : "",
                                  disabled:
                                    _vm.shouldDisablePrimaryAccessSnmp ||
                                    _vm.isValidating,
                                  value: network.input.current,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.setCurrentNetwork(
                                      networkIndex,
                                      $event.target.value
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isNewValuesRequired
                            ? _c("telia-text-input", {
                                staticClass: "column input-wrapper",
                                attrs: {
                                  "t-id": "network-" + networkIndex + "-new",
                                  label: _vm.isChangeOrder
                                    ? _vm.t("DATANET_NEW_VALUE")
                                    : "",
                                  disabled:
                                    _vm.shouldDisablePrimaryAccessSnmp ||
                                    _vm.isValidating,
                                  value: network.input.new,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.setNewNetwork(
                                      networkIndex,
                                      $event.target.value
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: _vm.isChangeOrder
                                ? "summary-link summary-link--top-margin"
                                : "summary-link",
                              attrs: { id: "remove-network-" + networkIndex },
                              on: {
                                click: function ($event) {
                                  return _vm.removeNetwork(networkIndex)
                                },
                              },
                            },
                            [
                              _c(
                                "i",
                                { staticClass: "'link-icon" },
                                [
                                  _c("telia-icon", {
                                    attrs: { name: "close", size: "sm" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              _vm.networks.length > 0
                ? _c("div", { staticClass: "row margin-bottom__12" }, [
                    _c(
                      "div",
                      { staticClass: "column-container" },
                      [
                        _vm.$v.networks.$error
                          ? _c("ValidationError", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.t("DATANET_SNMP_NETWORK_REQUIRED")
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _c("telia-p", { staticClass: "placeholder-text" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.t("DATANET_SNMP_NETWORK_PLACEHOLDER")
                                  ) +
                                  "\n        "
                              ),
                            ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "margin-bottom__32" }, [
                !_vm.shouldDisablePrimaryAccessSnmp
                  ? _c(
                      "div",
                      {
                        staticClass: "link-wrapper margin-bottom__24",
                        attrs: { "t-id": "add-snmp-community-network" },
                        on: {
                          click: function ($event) {
                            return _vm.addNetwork()
                          },
                        },
                      },
                      [
                        _c(
                          "i",
                          { staticClass: "link-icon" },
                          [
                            _c("telia-icon", {
                              attrs: { name: "plus", size: "sm" },
                            }),
                          ],
                          1
                        ),
                        _c("telia-p", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.isActionUpdate
                                  ? _vm.t("DATANET_SNMP_ADD_CHANGE_NETWORK")
                                  : _vm.t("DATANET_SNMP_ADD_NETWORK")
                              ) +
                              "\n        "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "button-wrapper" },
        [
          _c(
            "telia-button",
            {
              attrs: {
                "t-id": "save-community-configuration",
                disabled: _vm.isValidating,
                variant: "expressive",
              },
              on: {
                click: function ($event) {
                  return _vm.saveAndClose()
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.t("DATANET_SAVE_CHANGES")) + "\n    "
              ),
            ]
          ),
          _c(
            "telia-button",
            {
              attrs: { type: "button", variant: "secondary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("complete")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.t("DATANET_ABORT")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }