var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "margin__top__24 order-confirmation" }, [
    _c(
      "div",
      { staticClass: "order-summary-container" },
      [
        _c(
          "telia-heading",
          {
            staticClass: "margin__top__8 margin__bottom__8",
            attrs: { tag: "h2", variant: "title-400" },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.t("DATANET_ORDER_SUBMITTED_TITLE")) +
                "\n    "
            ),
          ]
        ),
        _c("telia-p", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.t("DATANET_ORDER_SUBMITTED_INFO_1")) +
              "\n      " +
              _vm._s(" " + _vm.orderEmail + ".") +
              "\n      " +
              _vm._s(_vm.t("DATANET_ORDER_SUBMITTED_INFO_2") + " ") +
              "\n      "
          ),
          _c(
            "a",
            {
              staticClass: "link",
              attrs: {
                href: "/foretag/mybusiness/" + _vm.scopeId + "/support/arenden",
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.t("DATANET_CONTACT_SUPPORT") + ".") +
                  "\n      "
              ),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "order-summary" },
          [
            _c("telia-p", [
              _c("strong", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.t("DATANET_ORDER_SUMMARY")) +
                    "\n        "
                ),
              ]),
              _c("div", { staticClass: "order-summary-info" }, [
                _c("span", { staticClass: "order-summary-column" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.t("DATANET_ORDER_ALIAS") + ":") +
                      "\n          "
                  ),
                ]),
                _c("strong", { staticClass: "order-summary-column" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getOrderAlias || "-") +
                      "\n          "
                  ),
                ]),
              ]),
              _vm.isProductBusinessOrder && _vm.isNewOrder
                ? _c("div", { staticClass: "order-summary-info" }, [
                    _c("span", { staticClass: "order-summary-column" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.t("DATANET_PREL_DELIVERY_DATE") + ":") +
                          "\n          "
                      ),
                    ]),
                    _c("strong", { staticClass: "order-summary-column" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.getSelectedInstallationDate || "-") +
                          "\n          "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.isProductBusinessOrder && _vm.isChangeOrder
                ? _c("div", { staticClass: "order-summary-info" }, [
                    _c("span", { staticClass: "order-summary-column" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.t("DATANET_ORDER_DATE") + ":") +
                          "\n          "
                      ),
                    ]),
                    _c("strong", { staticClass: "order-summary-column" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.orderDate || "-") +
                          "\n          "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "margin__top__24" },
          [
            _vm.isProductBusinessOrder && _vm.isNewOrder
              ? _c(
                  "telia-button",
                  {
                    attrs: { variant: "expressive" },
                    on: {
                      click: function ($event) {
                        return _vm.goToUrl("/foretag/mybusiness/" + _vm.scopeId)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.t("DATANET_GO_TO_START")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.isProductBusinessOrder && _vm.isChangeOrder
              ? _c(
                  "telia-button",
                  {
                    attrs: { variant: "expressive" },
                    on: {
                      click: function ($event) {
                        return _vm.goToUrl(
                          "/foretag/mybusiness/" +
                            _vm.scopeId +
                            "/hantera/produkter-tjanster/datanet-vpn"
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.t("DATANET_ADMINISTER_DATACOM")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "margin__top__24 margin__bottom__12" }, [
          _c(
            "div",
            [
              _c("telia-p", [
                _c("strong", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.t("DATANET_OTHER_OPTIONS")) +
                      "\n          "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm.isProductBusinessOrder && _vm.isNewOrder
            ? _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "link",
                    attrs: {
                      href:
                        "/foretag/mybusiness/" +
                        _vm.scopeId +
                        "/bestall/datanet/oc",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.t("DATANET_CREATE_NEW_ORDER")) +
                        "\n        "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.isProductBusinessOrder && _vm.isNewOrder
            ? _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "link",
                    attrs: {
                      href:
                        "/foretag/mybusiness/" +
                        _vm.scopeId +
                        "/hantera/produkter-tjanster/datanet-vpn",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.t("DATANET_ADMINISTER_DATACOM")) +
                        "\n        "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.isProductBusinessOrder && _vm.isChangeOrder
            ? _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "link",
                    attrs: {
                      href:
                        "/foretag/mybusiness/" +
                        _vm.scopeId +
                        "/hantera/produkter-tjanster/datanet-vpn/" +
                        _vm.accessId,
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.t("DATANET_RETURN_TO_ACCESS", {
                            id: _vm.accessId,
                          })
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }