const KB_IN_GB = 1000000;
const KB_IN_MB = 1000;

export default function (down = 0, up = 0) {
  if (down < 0) {
    down = 0;
  }

  if (up < 0) {
    up = 0;
  }

  if (up >= KB_IN_GB && down >= KB_IN_GB) {
    return up === down
      ? round(down / KB_IN_GB, 1) + " Gbit/s"
      : round(down / KB_IN_GB, 1) + "/" + round(up / KB_IN_GB, 1) + " Gbit/s";
  } else {
    return up === down
      ? round(down / KB_IN_MB, 1) + " Mbit/s"
      : round(down / KB_IN_MB, 1) + "/" + round(up / KB_IN_MB, 1) + " Mbit/s";
  }
}

function round(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
