import { render, staticRenderFns } from "./DeliveryTooltip.vue?vue&type=template&id=5867ff80"
import script from "./DeliveryTooltip.vue?vue&type=script&lang=js"
export * from "./DeliveryTooltip.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../common/temp/node_modules/.pnpm/vue-loader@15.11.1_prettier@2.2.1_vue-template-compiler@2.6.14_webpack@5.94.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports