import generateShortId from "../../../helpers/generateShortId";
import updateExistingObjectProperties from "../../../helpers/updateExistingObjectProperties";
import { Actions, DefaultValues } from "../../../constants";

const actions = {
  addLan({ state, commit, dispatch, rootGetters }, { vpnKey, lanKey, siblingKey }) {
    lanKey = lanKey == null ? generateShortId() : lanKey;
    const lanId = Object.keys(state).length;
    commit("ADD_LAN", { lanKey, vpnKey, lanId });
    dispatch("setUnsavedDraft", undefined, { root: true });
    dispatch("vpn/addLan", { vpnKey, lanKey }, { root: true });

    const { siblingVpnKey } = rootGetters["vpn/getVpn"](vpnKey);
    const siblingLanId = Object.keys(state).length;
    const siblingLanKey = siblingKey == null ? generateShortId() : siblingKey;

    commit("ADD_LAN", {
      lanKey: siblingLanKey,
      vpnKey: siblingVpnKey,
      siblingLanKey: lanKey,
      lanId: siblingLanId,
    });
    dispatch(
      "vpn/addLan",
      {
        vpnKey: siblingVpnKey,
        lanKey: siblingLanKey,
      },
      { root: true }
    );
    commit("SET_SIBLING_LAN_KEY", {
      lanKey,
      siblingLanKey,
    });

    return lanKey;
  },

  deleteLan({ state, commit, dispatch }, lanKey = DefaultValues.NONE) {
    if (lanKey === DefaultValues.NONE) {
      return;
    }

    const { vpn, siblingKey, dhcpRelays } = state[lanKey];

    for (const dhcpRelayKey in dhcpRelays) {
      dispatch("ips/deleteIp", dhcpRelayKey, { root: true });
    }

    dispatch("vpn/removeLan", { vpnKey: vpn, lanKey }, { root: true });
    commit("DELETE_LAN", lanKey);

    const hasSiblingLan = !!state[siblingKey];
    if (hasSiblingLan) {
      dispatch("deleteLan", siblingKey);
    }

    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setAction({ getters, dispatch, commit }, { lanKey, action }) {
    if (!Object.values(Actions).includes(action)) return;

    const lan = getters.getLan(lanKey);
    if (!lan) return;
    const siblingLan = getters.getLan(lan.siblingKey);
    if (!siblingLan) return;

    commit("RESET_LAN", lan.key);
    commit("RESET_LAN", siblingLan.key);

    commit("SET_ACTION", { lanKey, action });
    commit("SET_ACTION", { lanKey: siblingLan.key, action });

    switch (action) {
      case Actions.ADD:
        commit("SET_VLAN_ID_ACTION", { lanKey, action: Actions.ADD });
        commit("SET_VLAN_ID_ACTION", { lanKey: siblingLan.key, action: Actions.ADD });
        break;
      default:
        commit("SET_VLAN_ID_ACTION", { lanKey, action: Actions.NONE });
        commit("SET_VLAN_ID_ACTION", { lanKey: siblingLan.key, action: Actions.NONE });
        break;
    }

    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setSiblingLanKey(
    { dispatch, commit },
    { lanKey = DefaultValues.NONE, siblingLanKey = DefaultValues.NONE }
  ) {
    commit("SET_SIBLING_LAN_KEY", { lanKey, siblingLanKey });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setVlanId({ getters, dispatch, commit }, { lanKey, action, current, new: _new }) {
    if (lanKey == null) return;
    if (action == null && current == null && _new == null) return;

    const lan = getters.getLan(lanKey);
    if (!lan || lan.vlanId.new === _new) return;

    const vlanId = updateExistingObjectProperties(lan.vlanId, {
      lanKey,
      action,
      current,
      new: _new,
    });
    if (!vlanId) return;

    commit("SET_VLAN_ID", { lanKey, vlanId });
    const siblingVlanId = { ...vlanId };
    siblingVlanId.lanKey = lan.siblingKey;
    commit("SET_VLAN_ID", { lanKey: lan.siblingKey, vlanId: siblingVlanId });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setVlanIdAction({ getters, dispatch, commit }, { lanKey, action }) {
    if (!Object.values(Actions).includes(action)) return;

    const lan = getters.getLan(lanKey);
    if (!lan) return;

    if (action === Actions.NONE) {
      dispatch("setVlanId", {
        lanKey,
        action: Actions.NONE,
        new: "",
      });
    }

    commit("SET_VLAN_ID_ACTION", { lanKey, action });
    commit("SET_VLAN_ID_ACTION", { lanKey: lan.siblingKey, action });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  resetAllVlanIds({ state, dispatch }) {
    Object.values(state).forEach((lan) =>
      dispatch("setVlanId", { lanKey: lan.key, current: "", new: "" })
    );
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  addNetwork({ state, dispatch, commit }, lanKey) {
    commit("ADD_NETWORK", lanKey);

    const { siblingKey } = state[lanKey];
    commit("ADD_NETWORK", siblingKey);

    dispatch("setUnsavedDraft", undefined, { root: true });
    return state[lanKey].networks.length - 1;
  },

  removeNetwork({ state, commit, dispatch }, { lanKey = -1, index = -1 }) {
    if (lanKey === DefaultValues.NONE || index === DefaultValues.NONE) {
      return;
    }

    const lan = state[lanKey];
    if (!lan || !lan.networks[index]) {
      return;
    }

    commit("REMOVE_NETWORK", { lanKey, index });

    const { siblingKey } = lan;
    commit("REMOVE_NETWORK", { lanKey: siblingKey, index });

    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkAction({ commit, dispatch }, { lanKey, index, action }) {
    commit("SET_NETWORK_ACTION", { lanKey, index, action });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkInput(
    { state, dispatch, commit },
    { lanKey = DefaultValues.NONE, index, action, current, new: _new }
  ) {
    if (lanKey === DefaultValues.NONE || index === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const lan = state[lanKey];
    if (!lan) return;

    const network = lan.networks[index];
    if (!network) return;

    const input = updateExistingObjectProperties(network.input, {
      action,
      lanKey,
      current: current ? current.trim() : current,
      new: _new ? _new.trim() : _new,
    });
    if (!input) return;

    commit("SET_NETWORK_INPUT_ACTION", {
      lanKey: lan.siblingKey,
      index,
      action,
    });

    commit("SET_NETWORK_INPUT", { lanKey, index, input });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkIpVersion(
    { state, dispatch, commit },
    { lanKey = -1, index, action, current, new: _new }
  ) {
    if (lanKey === DefaultValues.NONE || index === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const lan = state[lanKey];
    if (!lan) return;

    const network = lan.networks[index];
    if (!network) return;

    const ipVersion = updateExistingObjectProperties(network.ipVersion, {
      action,
      lanKey,
      current: current,
      new: _new,
    });
    if (!ipVersion) return;

    commit("SET_NETWORK_IP_VERSION", { lanKey, index, ipVersion });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkIpAddress(
    { state, dispatch, commit },
    { lanKey = -1, index, action, current, new: _new }
  ) {
    if (lanKey === DefaultValues.NONE || index === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const lan = state[lanKey];
    if (!lan) return;

    const network = lan.networks[index];
    if (!network) return;

    const ipAddress = updateExistingObjectProperties(network.ipAddress, {
      action,
      lanKey,
      current: current,
      new: _new,
    });
    if (!ipAddress) return;

    commit("SET_NETWORK_IP_ADDRESS", { lanKey, index, ipAddress });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkIpPrefix(
    { state, dispatch, commit },
    { lanKey = -1, index, action, current, new: _new }
  ) {
    if (lanKey === DefaultValues.NONE || index === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const lan = state[lanKey];
    if (!lan) return;

    const network = lan.networks[index];
    if (!network) return;

    const ipPrefix = updateExistingObjectProperties(network.ipPrefix, {
      action,
      lanKey,
      current: current,
      new: _new,
    });
    if (!ipPrefix) return;

    commit("SET_NETWORK_IP_PREFIX", { lanKey, index, ipPrefix });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkVirtualIp(
    { state, dispatch, commit },
    { lanKey = -1, index, action, current, new: _new }
  ) {
    if (lanKey === DefaultValues.NONE || index === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const lan = state[lanKey];
    if (!lan) return;

    const network = lan.networks[index];
    if (!network) return;

    const virtualIp = updateExistingObjectProperties(network.virtualIp, {
      action,
      lanKey,
      current: current ? current.trim() : current,
      new: _new ? _new.trim() : _new,
    });
    if (!virtualIp) return;

    commit("SET_NETWORK_VIRTUAL_IP", { lanKey, index, virtualIp });

    const { siblingKey } = state[lanKey];
    commit("SET_NETWORK_VIRTUAL_IP", {
      lanKey: siblingKey,
      index,
      virtualIp,
    });

    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setIdentifyingNetworkInput({ state, commit, dispatch }, { lanKey, new: _new }) {
    if (lanKey == null || _new == null) return;
    const lan = state[lanKey];
    if (!lan) return;
    const identifyingNetworkInput = updateExistingObjectProperties(lan.identifyingNetwork.input, {
      new: _new.trim(),
    });
    if (!identifyingNetworkInput) return;

    commit("SET_IDENTIFYING_NETWORK_INPUT", {
      lanKey,
      identifyingNetworkInput,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setIdentifyingNetworkIpAddress({ state, commit, dispatch }, { lanKey, new: _new }) {
    if (lanKey == null || _new == null) return;
    const lan = state[lanKey];
    if (!lan) return;
    const identifyingNetworkIpAddress = updateExistingObjectProperties(
      lan.identifyingNetwork.ipAddress,
      {
        new: _new.trim(),
      }
    );
    if (!identifyingNetworkIpAddress) return;

    commit("SET_IDENTIFYING_NETWORK_IP_ADDRESS", {
      lanKey,
      identifyingNetworkIpAddress,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setIdentifyingNetworkIpVersion({ state, commit, dispatch }, { lanKey, new: _new }) {
    if (lanKey == null || _new == null) return;
    const lan = state[lanKey];
    if (!lan) return;
    const identifyingNetworkIpVersion = updateExistingObjectProperties(
      lan.identifyingNetwork.ipVersion,
      {
        new: _new.trim(),
      }
    );
    if (!identifyingNetworkIpVersion) return;

    commit("SET_IDENTIFYING_NETWORK_IP_VERSION", {
      lanKey,
      identifyingNetworkIpVersion,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setIdentifyingNetworkIpPrefix({ state, commit, dispatch }, { lanKey, new: _new }) {
    if (lanKey == null || _new == null) return;
    const lan = state[lanKey];
    if (!lan) return;
    const identifyingNetworkIpPrefix = updateExistingObjectProperties(
      lan.identifyingNetwork.input,
      {
        new: _new.trim(),
      }
    );
    if (!identifyingNetworkIpPrefix) return;

    commit("SET_IDENTIFYING_NETWORK_IP_PREFIX", {
      lanKey,
      identifyingNetworkIpPrefix,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  addDhcpRelay({ dispatch, commit }, { lanKey, dhcpRelayKey }) {
    commit("ADD_DHCP_RELAY", { lanKey, dhcpRelayKey });
    dispatch("setUnsavedDraft", undefined, { root: true });
    return dhcpRelayKey;
  },

  removeDhcpRelay({ dispatch, commit }, { lanKey, dhcpRelayKey }) {
    commit("REMOVE_DHCP_RELAY", { lanKey, dhcpRelayKey });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },
};

export default actions;
