var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("telia-heading", { attrs: { tag: "h4", variant: "title-100" } }, [
        _vm._v("\n    " + _vm._s(_vm.t("DATANET_ANTENNA_OPTIONS")) + "\n  "),
      ]),
      _c(
        "div",
        { staticClass: "margin__bottom__24" },
        [
          _c("telia-fieldset", [
            _c(
              "div",
              { staticClass: "chip-choice-wrapper" },
              [
                _c(
                  "telia-chip-choice",
                  {
                    attrs: {
                      "t-id": "antenna-package-1",
                      checked:
                        _vm.antennaPackage ===
                        _vm.AntennaPackages.ANTENNA_PACKAGE_1,
                      disabled:
                        _vm.antennaPackage !==
                          _vm.AntennaPackages.ANTENNA_PACKAGE_1 &&
                        _vm.disableInput,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleAntennaPackageSelection(
                          _vm.AntennaPackages.ANTENNA_PACKAGE_1
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "chip-choice-body" },
                      [
                        _c("b", [
                          _vm._v(_vm._s(_vm.t("DATANET_ANTENNA_PACKAGE_1"))),
                        ]),
                        _c("telia-p", [
                          _vm._v(
                            _vm._s(_vm.t("DATANET_ANTENNA_PACKAGE_1_DETAILED"))
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "telia-chip-choice",
                  {
                    attrs: {
                      id: "antenna-package-2",
                      "t-id": "antenna-package-2",
                      checked:
                        _vm.antennaPackage ===
                        _vm.AntennaPackages.ANTENNA_PACKAGE_2,
                      disabled:
                        _vm.antennaPackage !==
                          _vm.AntennaPackages.ANTENNA_PACKAGE_2 &&
                        _vm.disableInput,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleAntennaPackageSelection(
                          _vm.AntennaPackages.ANTENNA_PACKAGE_2
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "chip-choice-body" },
                      [
                        _c("b", [
                          _vm._v(_vm._s(_vm.t("DATANET_ANTENNA_PACKAGE_2"))),
                        ]),
                        _c("telia-p", [
                          _vm._v(
                            _vm._s(_vm.t("DATANET_ANTENNA_PACKAGE_2_DETAILED"))
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "telia-chip-choice",
                  {
                    attrs: {
                      id: "antenna-package-none",
                      checked:
                        _vm.antennaPackage ===
                        _vm.AntennaPackages.ANTENNA_PACKAGE_NONE,
                      disabled: _vm.disableInput,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleAntennaPackageSelection(
                          _vm.AntennaPackages.ANTENNA_PACKAGE_NONE
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "chip-choice-body" },
                      [
                        _c("b", [
                          _vm._v(_vm._s(_vm.t("DATANET_ANTENNA_PACKAGE_NONE"))),
                        ]),
                        _c("telia-p", [
                          _vm._v(
                            _vm._s(
                              _vm.t("DATANET_ANTENNA_PACKAGE_NONE_DETAILED")
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.antennaPackage !== _vm.AntennaPackages.ANTENNA_PACKAGE_NONE
        ? _c(
            "div",
            { staticClass: "checkbox-wrapper" },
            [
              _c(
                "telia-checkbox",
                {
                  attrs: {
                    "t-id": "antenna-cable",
                    disabled: _vm.disableInput,
                    value: !_vm.antennaCable,
                    checked: _vm.antennaCable,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handleCableSelection($event.target.checked)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.t("DATANET_ANTENNA_CABLE_INFO")) +
                      "\n    "
                  ),
                ]
              ),
              _c(
                "telia-checkbox",
                {
                  attrs: {
                    "t-id": "antenna-installation",
                    disabled:
                      _vm.antennaPackage === "ANTENNA_PACKAGE_2" ||
                      _vm.disableInput,
                    value: !_vm.antennaInstallation,
                    checked: _vm.antennaInstallation,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handleInstallationSelection(
                        $event.target.checked
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.t("DATANET_ANTENNA_INSTALLATION_INFO")) +
                      "\n    "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }