var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.communities && _vm.communities.length > 0
        ? _c(
            "SummaryList",
            { class: _vm.isChangeOrder ? "summary" : "summary--small" },
            [
              _c(
                "SummaryRow",
                { attrs: { variant: "header" } },
                [
                  _vm.isChangeOrder
                    ? _c(
                        "SummaryColumn",
                        [
                          _c("telia-p", [
                            _vm._v(_vm._s(_vm.t("DATANET_CHANGE"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isChangeOrder
                    ? _c("SummaryColumn", [_vm._v("   ")])
                    : _vm._e(),
                  _vm.isChangeOrder
                    ? _c(
                        "SummaryColumn",
                        [
                          _c("telia-p", [
                            _vm._v(_vm._s(_vm.t("DATANET_CURRENT_VALUE"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "SummaryColumn",
                    [
                      _c("telia-p", [
                        _vm._v(
                          _vm._s(
                            _vm.isChangeOrder
                              ? _vm.t("DATANET_NEW_VALUE")
                              : _vm.t("DATANET_SNMP_COMMUNITY")
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("SummaryColumn", { attrs: { variant: "small" } }, [
                    _vm._v("   "),
                  ]),
                ],
                1
              ),
              _vm._l(_vm.communities, function (community, communityIndex) {
                return _c(
                  "SummaryRow",
                  { key: "snmp-community-" + communityIndex },
                  [
                    _vm.isChangeOrder
                      ? _c(
                          "SummaryColumn",
                          { attrs: { variant: "align-top" } },
                          [
                            _c("telia-p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.t("DATANET_ACTION_" + community.action)
                                  )
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isChangeOrder
                      ? _c(
                          "SummaryColumn",
                          { attrs: { variant: "align-top" } },
                          [
                            community.communityName.current ||
                            community.communityName.new
                              ? _c(
                                  "div",
                                  { staticClass: "field-title" },
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.t("DATANET_SNMP_COMMUNITY_NAME")
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            community.networks.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "field-title" },
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.t("DATANET_SNMP_NETWORK"))
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm.isChangeOrder
                      ? _c(
                          "SummaryColumn",
                          { attrs: { variant: "align-top" } },
                          [
                            _c(
                              "div",
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(
                                      community.communityName.current || "-"
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._l(
                              community.networks,
                              function (network, networkIndex) {
                                return _c(
                                  "div",
                                  {
                                    key:
                                      "snmp-community-" +
                                      communityIndex +
                                      "-network-" +
                                      networkIndex +
                                      "-current",
                                  },
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(network.input.current || "-")
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "SummaryColumn",
                      [
                        _c(
                          "div",
                          [
                            _c("telia-p", [
                              _vm._v(
                                _vm._s(community.communityName.new || "-") + " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._l(
                          community.networks,
                          function (network, networkIndex) {
                            return _c(
                              "div",
                              {
                                key:
                                  "snmp-community-" +
                                  communityIndex +
                                  "-network-" +
                                  networkIndex +
                                  "-new",
                              },
                              [
                                _c("telia-p", [
                                  _vm._v(_vm._s(network.input.new || "-")),
                                ]),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _c(
                      "SummaryColumn",
                      { staticClass: "align-end", attrs: { variant: "small" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "summary-link",
                            attrs: {
                              id: "remove-snmp-community-" + communityIndex,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeCommunity(communityIndex)
                              },
                            },
                          },
                          [
                            _c(
                              "i",
                              { staticClass: "link-icon" },
                              [
                                _c("telia-icon", {
                                  attrs: { name: "close", size: "sm" },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.modalIsActive
        ? _c(
            "Modal",
            {
              attrs: {
                "modal-id": "snmp-community-modal",
                width: "m",
                "is-open": _vm.modalIsActive,
              },
              on: {
                closeModal: function ($event) {
                  return _vm.hideCommunityModal()
                },
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "modal-body" }, slot: "modal-body" },
                [
                  _c("SnmpCommunity", {
                    on: {
                      complete: function ($event) {
                        return _vm.hideCommunityModal()
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "margin__top__12" },
        [
          !_vm.shouldDisablePrimaryAccessSnmp
            ? _c("telia-button", {
                attrs: {
                  id: "add-snmp-community",
                  "t-id": "add-snmp-community",
                  type: "button",
                  variant: "secondary",
                  size: "sm",
                  "left-icon": JSON.stringify({ name: "plus", size: "sm" }),
                  text: _vm.isChangeOrder
                    ? _vm.t("DATANET_SNMP_ADD_CHANGE_COMMUNITY")
                    : _vm.t("DATANET_SNMP_ADD_COMMUNITY"),
                },
                on: {
                  click: function ($event) {
                    return _vm.showCommunityModal()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }