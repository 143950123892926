<template>
  <div>
    <SummaryList
      v-if="communities && communities.length > 0"
      :class="isChangeOrder ? 'summary' : 'summary--small'"
    >
      <SummaryRow variant="header">
        <SummaryColumn v-if="isChangeOrder">
          <telia-p>{{ t("DATANET_CHANGE") }}</telia-p>
        </SummaryColumn>
        <SummaryColumn v-if="isChangeOrder"> &nbsp; </SummaryColumn>
        <SummaryColumn v-if="isChangeOrder">
          <telia-p>{{ t("DATANET_CURRENT_VALUE") }}</telia-p>
        </SummaryColumn>
        <SummaryColumn>
          <telia-p>{{
            isChangeOrder ? t("DATANET_NEW_VALUE") : t("DATANET_SNMP_COMMUNITY")
          }}</telia-p>
        </SummaryColumn>
        <SummaryColumn variant="small"> &nbsp; </SummaryColumn>
      </SummaryRow>
      <SummaryRow
        v-for="(community, communityIndex) in communities"
        :key="`snmp-community-${communityIndex}`"
      >
        <SummaryColumn v-if="isChangeOrder" variant="align-top">
          <telia-p> {{ t(`DATANET_ACTION_${community.action}`) }}</telia-p>
        </SummaryColumn>
        <SummaryColumn v-if="isChangeOrder" variant="align-top">
          <div
            v-if="community.communityName.current || community.communityName.new"
            class="field-title"
          >
            <telia-p>{{ t("DATANET_SNMP_COMMUNITY_NAME") }}</telia-p>
          </div>
          <div v-if="community.networks.length > 0" class="field-title">
            <telia-p> {{ t("DATANET_SNMP_NETWORK") }}</telia-p>
          </div>
        </SummaryColumn>
        <SummaryColumn v-if="isChangeOrder" variant="align-top">
          <div>
            <telia-p>{{ community.communityName.current || "-" }}</telia-p>
          </div>
          <div
            v-for="(network, networkIndex) in community.networks"
            :key="`snmp-community-${communityIndex}-network-${networkIndex}-current`"
          >
            <telia-p> {{ network.input.current || "-" }}</telia-p>
          </div>
        </SummaryColumn>
        <SummaryColumn>
          <div>
            <telia-p>{{ community.communityName.new || "-" }} </telia-p>
          </div>
          <div
            v-for="(network, networkIndex) in community.networks"
            :key="`snmp-community-${communityIndex}-network-${networkIndex}-new`"
          >
            <telia-p>{{ network.input.new || "-" }}</telia-p>
          </div>
        </SummaryColumn>
        <SummaryColumn variant="small" class="align-end">
          <div
            :id="`remove-snmp-community-${communityIndex}`"
            class="summary-link"
            @click="removeCommunity(communityIndex)"
          >
            <i class="link-icon"><telia-icon name="close" size="sm" /></i>
          </div>
        </SummaryColumn>
      </SummaryRow>
    </SummaryList>

    <Modal
      v-if="modalIsActive"
      modal-id="snmp-community-modal"
      width="m"
      :is-open="modalIsActive"
      @closeModal="hideCommunityModal()"
    >
      <div slot="modal-body">
        <SnmpCommunity @complete="hideCommunityModal()" />
      </div>
    </Modal>

    <div class="margin__top__12">
      <telia-button
        v-if="!shouldDisablePrimaryAccessSnmp"
        id="add-snmp-community"
        t-id="add-snmp-community"
        type="button"
        variant="secondary"
        size="sm"
        :left-icon="JSON.stringify({ name: 'plus', size: 'sm' })"
        :text="
          isChangeOrder ? t('DATANET_SNMP_ADD_CHANGE_COMMUNITY') : t('DATANET_SNMP_ADD_COMMUNITY')
        "
        @click="showCommunityModal()"
      />
    </div>
  </div>
</template>

<script>
import SnmpCommunity from "./SnmpCommunity";
import SummaryColumn from "../summary/SummaryColumn";
import SummaryList from "../summary/SummaryList";
import SummaryRow from "../summary/SummaryRow";
import Modal from "../Modal.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { Accesses } from "../../constants";
import { translateMixin } from "../../locale";

export default {
  name: "Snmp",
  mixins: [translateMixin],
  components: {
    SnmpCommunity,
    SummaryColumn,
    SummaryList,
    SummaryRow,
    Modal,
  },
  data() {
    return {
      modalIsActive: false,
    };
  },

  computed: {
    ...mapState("access", {
      communities: (state) => state[Accesses.PRIMARY].options.snmp.communities,
    }),

    ...mapGetters(["isChangeOrder"]),

    ...mapGetters("access", [
      "shouldValidatePrimaryAccessSnmp",
      "shouldDisablePrimaryAccessSnmp",
      "shouldShowPrimaryAccessSnmp",
    ]),
  },

  methods: {
    ...mapActions("access", ["removeSnmpCommunity"]),

    showCommunityModal() {
      this.modalIsActive = true;
    },

    hideCommunityModal() {
      this.modalIsActive = false;
    },

    addCommunity() {
      this.addSnmpCommunity(Accesses.PRIMARY);
    },

    removeCommunity(communityIndex) {
      this.removeSnmpCommunity({
        accessKey: Accesses.PRIMARY,
        communityIndex,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";
.field-title {
  color: $telia-gray-500;
}

.link {
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
  transition: all 150ms ease;
}

.summary {
  max-width: 80rem;
}

.summary--small {
  max-width: 42rem;
}

.summary-link {
  float: right;
  margin-top: $telia-spacing-4;
  cursor: pointer;
  transition: all 150ms ease;
}

.summary-link:hover {
  color: $telia-purple-500;
}

.summary-link:active {
  color: $telia-purple-500;
}

.input-wrapper {
  max-width: 42rem;
}

.align-end {
  align-items: flex-end;
}

.margin {
  &__top {
    &__12 {
      margin-top: $telia-spacing-12;
    }
  }
}
</style>
