<template>
  <div>
    <div>
      <telia-heading tag="h3" variant="title-100" class="margin__bottom__12">
        {{ isChangeOrder ? t("DATANET_SNMP_CHANGE_COMMUNITY") : t("DATANET_SNMP_ADD_COMMUNITY") }}
      </telia-heading>

      <ActionSelection
        v-if="isChangeOrder"
        class="component-wrapper"
        :selected-action="action"
        :disabled-actions="disabledActions"
        @selected="setCommunityAction($event)"
      />
    </div>

    <telia-p>
      <strong>{{ t("DATANET_SNMP_COMMUNITY_NAME") }}</strong>
    </telia-p>
    <div class="row">
      <div class="column-container">
        <telia-text-input
          v-if="isCurrentValuesRequired"
          t-id="current-snmp-community-name"
          class="column input-wrapper"
          :label="isChangeOrder ? t('DATANET_CURRENT_VALUE') : ''"
          :disabled="shouldDisablePrimaryAccessSnmp || isValidating"
          :value="communityName.current"
          @input="setCommunityName({ current: $event.target.value })"
        />
        <telia-text-input
          v-if="isNewCommunityNameRequired"
          t-id="new-snmp-community-name"
          class="column input-wrapper"
          :label="isChangeOrder ? t('DATANET_NEW_VALUE') : ''"
          :disabled="shouldDisablePrimaryAccessSnmp || isValidating"
          :value="communityName.new"
          @change="setCommunityName({ new: $event.target.value })"
        />
      </div>
    </div>

    <div class="row margin-bottom__12">
      <div class="column-container">
        <ValidationError v-if="$v.communityName.$error">
          {{ t("DATANET_SNMP_COMMUNITY_NAME_REQUIRED") }}
        </ValidationError>
        <telia-p v-else class="placeholder-text">
          {{ t("DATANET_SNMP_COMMUNITY_NAME_PLACEHOLDER") }}
        </telia-p>
      </div>
    </div>

    <telia-checkbox
      v-if="isActionUpdate"
      t-id="change-community-name"
      class="margin-bottom__24"
      :value="!changeCommunityName"
      :checked="changeCommunityName"
      @input="changeCommunityName = !changeCommunityName"
    >
      {{ t("DATANET_SNMP_CHANGE_COMMUNITY_NAME") }}
    </telia-checkbox>

    <div v-if="!isActionDelete">
      <telia-p v-if="!shouldDisablePrimaryAccessSnmp || networks.length > 0">
        <strong>{{ t("DATANET_SNMP_NETWORKS") }}</strong>
      </telia-p>
      <ValidationError
        v-if="$v.networks.$dirty && !$v.networks.minOneRequired"
        class="margin-bottom-12"
      >
        {{ t("DATANET_SNMP_MIN_1_NETWORK_REQUIRED") }}
      </ValidationError>

      <div v-for="(network, networkIndex) in networks" :key="networkIndex" class="margin-bottom-8">
        <div class="row">
          <div class="column-container">
            <telia-text-input
              v-if="isCurrentValuesRequired"
              :t-id="`network-${networkIndex}-current`"
              class="column input-wrapper"
              :label="isChangeOrder ? t('DATANET_CURRENT_VALUE') : ''"
              :disabled="shouldDisablePrimaryAccessSnmp || isValidating"
              :value="network.input.current"
              @change="setCurrentNetwork(networkIndex, $event.target.value)"
            />
            <telia-text-input
              v-if="isNewValuesRequired"
              :t-id="`network-${networkIndex}-new`"
              class="column input-wrapper"
              :label="isChangeOrder ? t('DATANET_NEW_VALUE') : ''"
              :disabled="shouldDisablePrimaryAccessSnmp || isValidating"
              :value="network.input.new"
              @change="setNewNetwork(networkIndex, $event.target.value)"
            />
            <div
              :id="`remove-network-${networkIndex}`"
              :class="isChangeOrder ? 'summary-link summary-link--top-margin' : 'summary-link'"
              @click="removeNetwork(networkIndex)"
            >
              <i class="'link-icon">
                <telia-icon name="close" size="sm" />
              </i>
            </div>
          </div>
        </div>
      </div>

      <div v-if="networks.length > 0" class="row margin-bottom__12">
        <div class="column-container">
          <ValidationError v-if="$v.networks.$error">
            {{ t("DATANET_SNMP_NETWORK_REQUIRED") }}
          </ValidationError>
          <telia-p v-else class="placeholder-text">
            {{ t("DATANET_SNMP_NETWORK_PLACEHOLDER") }}
          </telia-p>
        </div>
      </div>

      <div class="margin-bottom__32">
        <div
          v-if="!shouldDisablePrimaryAccessSnmp"
          t-id="add-snmp-community-network"
          class="link-wrapper margin-bottom__24"
          @click="addNetwork()"
        >
          <i class="link-icon">
            <telia-icon name="plus" size="sm" />
          </i>

          <telia-p>
            {{
              isActionUpdate ? t("DATANET_SNMP_ADD_CHANGE_NETWORK") : t("DATANET_SNMP_ADD_NETWORK")
            }}
          </telia-p>
        </div>
      </div>
    </div>

    <div class="button-wrapper">
      <telia-button
        t-id="save-community-configuration"
        :disabled="isValidating"
        variant="expressive"
        @click="saveAndClose()"
      >
        {{ t("DATANET_SAVE_CHANGES") }}
      </telia-button>

      <telia-button type="button" variant="secondary" @click="$emit('complete')">
        {{ t("DATANET_ABORT") }}
      </telia-button>
    </div>
  </div>
</template>

<script>
import ActionSelection from "../ActionSelection";
import ValidationError from "../ValidationError";
import { corpValidationService } from "@telia/b2b-rest-client";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { mapState, mapActions, mapGetters } from "vuex";
import defaultChangeableValue from "../../store/defaultChangeableValue";
import defaultNetwork from "../../store/defaultNetwork";
import { Accesses, Actions } from "../../constants";
import { translateMixin } from "../../locale";

export default {
  name: "SnmpCommunity",
  mixins: [translateMixin, validationMixin],
  components: {
    ActionSelection,
    ValidationError,
  },

  data() {
    return {
      action: Actions.ADD,
      communityName: defaultChangeableValue(),
      networks: [],
      changeCommunityName: false,
      currentNetworkTimeoutId: null,
      newNetworkTimeoutId: null,
      isValidating: false,
    };
  },

  computed: {
    ...mapState({
      scopeId: (state) => state.user.scopeId,
    }),

    ...mapGetters(["isChangeOrder"]),

    ...mapGetters("access", [
      "isSnmpActionEditable",
      "shouldValidatePrimaryAccessSnmp",
      "shouldDisablePrimaryAccessSnmp",
      "shouldShowPrimaryAccessSnmp",
    ]),

    isActionAdd() {
      return this.action === Actions.ADD;
    },

    isActionUpdate() {
      return this.action === Actions.UPDATE;
    },

    isActionDelete() {
      return this.action === Actions.DELETE;
    },

    isCurrentValuesRequired() {
      return this.isActionUpdate || this.isActionDelete;
    },

    isNewValuesRequired() {
      return this.isActionAdd || this.isActionUpdate;
    },

    isNewCommunityNameRequired() {
      return this.isActionAdd || (this.isActionUpdate && this.changeCommunityName);
    },

    disabledActions() {
      if (!this.isSnmpActionEditable) {
        return [Actions.UPDATE, Actions.UPDATE, Actions.DELETE].filter(
          (action) => action !== this.action
        );
      }

      return [];
    },
  },

  mounted() {
    if (!this.isChangeOrder) {
      this.networks.push(defaultNetwork());
    }
  },

  watch: {
    action() {
      if (this.isChangeOrder) {
        this.communityName = defaultChangeableValue();
        this.networks = [];
        this.changeCommunityName = false;
      }
    },

    changeCommunityName() {
      this.communityName.new = "";
    },
  },

  methods: {
    ...mapActions("access", [
      "addSnmpCommunity",
      "setSnmpCommunityName",
      "setSnmpCommunityActionAdd",
      "setSnmpCommunityActionUpdate",
      "setSnmpCommunityActionDelete",
      "addSnmpNetwork",
      "setSnmpNetwork",
      "setSnmpNetworkInput",
      "setSnmpNetworkActionAdd",
      "setSnmpNetworkActionUpdate",
      "setSnmpNetworkActionDelete",
    ]),
    blur() {
      this.$v.communityName.$touch();
      if (this.$v.communityName.new.$error) return true;
      return false;
    },
    focus() {
      this.$v.communityName.$reset();
      if (this.$v.communityName.new.$error) return true;
      return false;
    },
    async saveAndClose() {
      this.isValidating = true;
      const valid = await this.validate();

      if (valid) {
        const communityIndex = await this.addSnmpCommunity(Accesses.PRIMARY);

        switch (this.action) {
          case Actions.ADD:
            this.setSnmpCommunityActionAdd({
              accessKey: Accesses.PRIMARY,
              communityIndex,
            });
            break;
          case Actions.UPDATE:
            this.setSnmpCommunityActionUpdate({
              accessKey: Accesses.PRIMARY,
              communityIndex,
            });
            break;
          case Actions.DELETE:
            this.setSnmpCommunityActionDelete({
              accessKey: Accesses.PRIMARY,
              communityIndex,
            });
            break;
        }

        this.setSnmpCommunityName({
          accessKey: Accesses.PRIMARY,
          communityIndex,
          communityName: this.communityName,
        });

        for (let i = 0; i < this.networks.length; i++) {
          const networkIndex = await this.addSnmpNetwork({
            accessKey: Accesses.PRIMARY,
            communityIndex,
          });

          this.setSnmpNetwork({
            accessKey: Accesses.PRIMARY,
            communityIndex,
            networkIndex,
            network: this.networks[networkIndex],
          });

          switch (this.action) {
            case Actions.ADD:
              this.setSnmpNetworkActionAdd({
                accessKey: Accesses.PRIMARY,
                communityIndex,
                networkIndex,
              });
              break;
            case Actions.UPDATE:
              this.setSnmpNetworkActionUpdate({
                accessKey: Accesses.PRIMARY,
                communityIndex,
                networkIndex,
              });
              break;
            case Actions.DELETE:
              this.setSnmpNetworkActionDelete({
                accessKey: Accesses.PRIMARY,
                communityIndex,
                networkIndex,
              });
              break;
          }
        }

        this.isValidating = false;
        this.$emit("complete");
      } else {
        this.isValidating = false;
      }
    },

    setCommunityAction(value) {
      this.$v.$reset();
      this.action = value;
    },

    setCommunityName(value) {
      this.$v.$reset();
      Object.assign(this.communityName, value);
    },

    addNetwork() {
      this.$v.$reset();
      this.networks.push(defaultNetwork());
    },

    removeNetwork(index) {
      this.networks.splice(index, 1);
    },

    async setCurrentNetwork(index, value) {
      this.networks[index].input.current = value;

      if (this.currentNetworkTimeoutId) {
        clearTimeout(this.currentNetworkTimeoutId);
      }

      this.currentNetworkTimeoutId = setTimeout(
        async function () {
          const networkInfo = await this.getNetworkInformation(value);

          this.networks[index].ipVersion.current = networkInfo.valid ? networkInfo.ipVersion : "";
          this.networks[index].ipAddress.current = networkInfo.valid ? networkInfo.network : "";
          this.networks[index].ipPrefix.current = networkInfo.valid ? networkInfo.prefix : "";
        }.bind(this),
        400
      );
    },

    async setNewNetwork(index, value) {
      this.networks[index].input.new = value;

      if (this.newNetworkTimeoutId) {
        clearTimeout(this.newNetworkTimeoutId);
      }

      this.newNetworkTimeoutId = setTimeout(
        async function () {
          const networkInfo = await this.getNetworkInformation(value);

          this.networks[index].ipVersion.new = networkInfo.valid ? networkInfo.ipVersion : "";
          this.networks[index].ipAddress.new = networkInfo.valid ? networkInfo.network : "";
          this.networks[index].ipPrefix.new = networkInfo.valid ? networkInfo.prefix : "";
        }.bind(this),
        400
      );
    },

    async getNetworkInformation(value) {
      let networkInfo = {};
      try {
        const response = await corpValidationService.ValidationControllerService.validate(
          this.scopeId,
          {
            type: "NETWORK",
            value,
          }
        );

        if (response) {
          const responseData = await response;

          networkInfo = {
            valid: true,
            ipVersion: responseData.ipVersion,
            network: responseData.network,
            prefix: responseData.prefix,
          };
        }
      } catch (e) {
        networkInfo = {
          valid: false,
        };
      }

      return networkInfo;
    },

    async validate() {
      this.$v.$touch();
      /* TODO: Should we do something similar?
      if (process.env.MYBD_ENV === "test") {
        return new Promise((resolve) => resolve(true));
      }
      */
      let unwatch = null;
      await new Promise((resolve) => {
        unwatch = this.$watch(
          () => !this.$v.$pending,
          (isNotPending) => {
            if (isNotPending) {
              if (unwatch) {
                unwatch();
              }
              resolve(!this.$v.$invalid);
            }
          },
          { immediate: true }
        );
      });

      return !this.$v.$invalid;
    },
  },
  validations: {
    communityName: {
      current: {
        required: requiredIf(function () {
          return this.shouldValidatePrimaryAccessSnmp && this.isCurrentValuesRequired;
        }),
      },
      new: {
        required: requiredIf(function () {
          return this.shouldValidatePrimaryAccessSnmp && this.isNewCommunityNameRequired;
        }),
      },
    },
    networks: {
      minOneRequired: function (networks) {
        if (!this.shouldValidatePrimaryAccessSnmp || this.isActionDelete) {
          return true;
        }

        if (this.isActionUpdate && this.communityName.current && this.communityName.new) {
          return true;
        }

        return networks.length;
      },
      $each: {
        input: {
          current: {
            required: requiredIf(function () {
              return this.shouldValidatePrimaryAccessSnmp && this.isCurrentValuesRequired;
            }),
            isValidNetwork: async function (value) {
              if (
                !this.shouldValidatePrimaryAccessSnmp ||
                (!this.isCurrentValuesRequired && !value)
              ) {
                return true;
              }

              try {
                const response = await corpValidationService.ValidationControllerService.validate(
                  this.scopeId,
                  {
                    type: "NETWORK",
                    value,
                  }
                );
                return !!response;
              } catch (e) {
                return false;
              }
            },
          },
          new: {
            required: requiredIf(function () {
              return this.shouldValidatePrimaryAccessSnmp && this.isNewValuesRequired;
            }),
            isValidNetwork: async function (value) {
              if (!this.shouldValidatePrimaryAccessSnmp || (!this.isNewValuesRequired && !value)) {
                return true;
              }

              try {
                const response = await corpValidationService.ValidationControllerService.validate(
                  this.scopeId,
                  {
                    type: "NETWORK",
                    value,
                  }
                );
                return !!response;
              } catch (e) {
                return false;
              }
            },
          },
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
}

.column-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  flex: 5 1 0;
}

.column {
  flex: 1 1 0;
  margin-right: 0.5rem;
}

.column:last-child {
  margin: 0;
}

.column-small {
  flex: 1 1 0;
  align-self: center;
  margin: 0;
}

.summary-link {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  cursor: pointer;
  transition: all 150ms ease;
}

.summary-link:hover {
  color: $telia-purple-500;
}

.summary-link:active {
  color: $telia-purple-500;
}

.summary-link--top-margin {
  margin-top: 2.5rem;
}

.link-wrapper {
  display: flex;
  cursor: pointer;
  align-items: center;
  transition: all 150ms ease;
}

.link-wrapper:hover {
  color: $telia-purple-500;
}

.link-wrapper:active {
  color: $telia-purple-500;
}

.link-icon {
  width: 2rem;
  height: 2.4rem;
  margin-right: 0.6rem;
  padding: 0.4rem 0.4rem 0.4rem 0;
}

.input-wrapper {
  max-width: 34rem;
}
.component-wrapper {
  margin-top: $telia-spacing-12;
  margin-bottom: $telia-spacing-24;
}
.margin-bottom {
  &__8 {
    margin-bottom: $telia-spacing-8;
  }
  &__12 {
    margin-bottom: $telia-spacing-12;
  }
  &__24 {
    margin-bottom: $telia-spacing-24;
  }
  &__32 {
    margin-bottom: $telia-spacing-32;
  }
}
.placeholder-text {
  margin-top: $telia-spacing-4;
  color: $telia-gray-500;
  font-size: 0.9rem;
}
.button-wrapper {
  padding-top: $telia-spacing-12;
}
.button-wrapper > * {
  margin-right: $telia-spacing-8;
}
</style>
