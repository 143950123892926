var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "margin__bottom__32" },
    [
      _c(
        "div",
        { staticClass: "margin__bottom__16" },
        [
          !_vm.isChangeOrder
            ? _c("telia-heading", {
                attrs: { tag: "h3", variant: "title-200" },
                domProps: {
                  textContent: _vm._s(
                    _vm.t("DATANET_" + _vm.accessKey.toUpperCase() + "_ACCESS")
                  ),
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.accessKey === _vm.Accesses.PRIMARY
        ? _c("MultiVpn", {
            staticClass: "margin__bottom__24",
            on: {
              click: function ($event) {
                return _vm.setDefaultSelected()
              },
            },
          })
        : _vm._e(),
      _vm.shouldShowBandwidthDistribution
        ? _c("BandwidthDistribution", {
            staticClass: "margin__bottom__24",
            attrs: {
              "access-key": _vm.accessKey,
              "active-tooltip": _vm.activeTooltip,
            },
            on: {
              activeTooltip: function ($event) {
                return _vm.setActiveTooltip($event)
              },
            },
          })
        : _vm._e(),
      _vm.shouldShowVpnInterface(_vm.accessKey)
        ? _c("VpnInterface", {
            staticClass: "margin__bottom__24",
            attrs: {
              "access-key": _vm.accessKey,
              "active-tooltip": _vm.activeTooltip,
            },
            on: {
              activeTooltip: function ($event) {
                return _vm.setActiveTooltip($event)
              },
            },
          })
        : _vm._e(),
      _vm.shouldShowRtTot(_vm.accessKey)
        ? _c("RtTot", {
            staticClass: "margin__bottom__24",
            attrs: {
              "access-key": _vm.accessKey,
              "active-tooltip": _vm.activeTooltip,
            },
            on: {
              activeTooltip: function ($event) {
                return _vm.setActiveTooltip($event)
              },
            },
          })
        : _vm._e(),
      _vm.shouldValidateVpnSettings && _vm.hasMultipleVpns && false
        ? _c(
            "section",
            { staticClass: "row margin-bottom" },
            [
              _c(
                "telia-heading",
                { attrs: { tag: "h5", variant: "title-500" } },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.t("DATANET_VPN_SELECT_LAN_INTERFACES")) +
                      "\n    "
                  ),
                ]
              ),
              _c("telia-select", {
                staticClass: "input-container margin-bottom",
                attrs: {
                  id: "lan-interfaces",
                  label: "",
                  placeholder: _vm.t("DATANET_VPN_LAN_INTERFACES_PLACEHOLDER"),
                  value: _vm.lanInterfaces.value,
                  options: _vm.getLanInterfaceOptions(),
                },
                on: {
                  change: function ($event) {
                    _vm.lanInterfaces = { value: $event.target.value }
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("section", [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _vm.selectedVpn
                ? [
                    _c(
                      "ol",
                      { staticClass: "list" },
                      _vm._l(_vm.vpns, function (vpn, index) {
                        return _c("ListItem", {
                          key: "vpn-" + index,
                          attrs: {
                            "t-id": vpn.accessKey + "-vpn-" + index,
                            text: "VPN " + (index + 1),
                            invalid: _vm.vpnContainsAnyError(index),
                            active: _vm.selectedVpnIndex === index,
                            deletable: _vm.canDeleteVpn(index, vpn.key),
                            checkable:
                              _vm.isBackupSettings &&
                              !_vm.hasBackupTypeRedundancy &&
                              !_vm.hasBackupTypeDiversity,
                            checked: _vm.isIncludedInBackup(vpn.key),
                          },
                          on: {
                            delete: function ($event) {
                              return _vm.handleDeleteVpn(index)
                            },
                            include: function ($event) {
                              return _vm.toggleBackupVpnInclusion(vpn.key)
                            },
                            click: function ($event) {
                              return _vm.selectVpn(index)
                            },
                          },
                        })
                      }),
                      1
                    ),
                    _vm.canAddVpn(_vm.accessKey)
                      ? [
                          _c("span", { staticClass: "separator" }),
                          _c(
                            "ul",
                            { staticClass: "list" },
                            [
                              _c("ListItem", {
                                attrs: {
                                  "t-id": "add-" + _vm.accessKey + "-vpn",
                                  text: _vm.isChangeOrder
                                    ? _vm.t("DATANET_CONFIGURE_VPN")
                                    : _vm.t("DATANET_ADD_VPN"),
                                  addable: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addVpn({
                                      addDefaultLan: !_vm.isChangeOrder,
                                    })
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "column" },
            [
              _vm.selectedVpn
                ? [
                    _c(
                      "ol",
                      { staticClass: "list" },
                      [
                        _c("ListItem", {
                          attrs: {
                            text: _vm.t("DATANET_VPN_BASE_SETTINGS"),
                            invalid: _vm.getVpnValidationObject(
                              _vm.selectedVpnIndex
                            ).$anyError,
                            active: _vm.selectedSetting === _vm.settings.BASE,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectBaseSettings()
                            },
                          },
                        }),
                        _vm.shouldNotDeleteVpn
                          ? [
                              _vm._l(
                                _vm.selectedVpnLans,
                                function (lan, index) {
                                  return _c("ListItem", {
                                    key: "lan-" + index,
                                    attrs: {
                                      "t-id": "lan-" + index,
                                      text: "LAN " + (index + 1),
                                      invalid: _vm.getLanValidationObject(
                                        lan.key
                                      ).$anyError,
                                      active: _vm.selectedLanIndex === index,
                                      deletable: _vm.canDeleteLan(index),
                                    },
                                    on: {
                                      delete: function ($event) {
                                        return _vm.handleDeleteLan(index)
                                      },
                                      click: function ($event) {
                                        return _vm.selectLan(index)
                                      },
                                    },
                                  })
                                }
                              ),
                              _vm.shouldShowDhcpServers(_vm.selectedVpn.key)
                                ? _vm._l(
                                    _vm.selectedVpnDhcpServers,
                                    function (dhcpServer, index) {
                                      return _c("ListItem", {
                                        key: "dhcp-server-" + index,
                                        attrs: {
                                          "t-id": "dhcp-server-" + index,
                                          text: "DHCP server " + (index + 1),
                                          invalid: _vm.getDhcpServerValidationObject(
                                            dhcpServer.key
                                          ).$anyError,
                                          active:
                                            _vm.selectedDhcpServerIndex ===
                                            index,
                                          deletable: _vm.canDeleteDhcpServer(
                                            _vm.selectedVpn.key
                                          ),
                                        },
                                        on: {
                                          delete: function ($event) {
                                            return _vm.handleDeleteDhcpServer(
                                              index
                                            )
                                          },
                                          click: function ($event) {
                                            return _vm.selectDhcpServer(index)
                                          },
                                        },
                                      })
                                    }
                                  )
                                : _vm._e(),
                              _vm.shouldShowStaticRoutes(_vm.selectedVpn.key)
                                ? _vm._l(
                                    _vm.selectedVpnStaticRoutes,
                                    function (staticRoute, index) {
                                      return _c("ListItem", {
                                        key: "static-route-" + index,
                                        attrs: {
                                          "t-id": "static-route-" + index,
                                          text: "Static route " + (index + 1),
                                          invalid: _vm.getStaticRouteValidationObject(
                                            staticRoute.key
                                          ).$anyError,
                                          active:
                                            _vm.selectedStaticRouteIndex ===
                                            index,
                                          deletable: _vm.canDeleteStaticRoutes(
                                            _vm.selectedVpn.key
                                          ),
                                        },
                                        on: {
                                          delete: function ($event) {
                                            return _vm.handleDeleteStaticRoute(
                                              index
                                            )
                                          },
                                          click: function ($event) {
                                            return _vm.selectStaticRoute(index)
                                          },
                                        },
                                      })
                                    }
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    !_vm.isBackupSettings
                      ? [
                          _c("span", { staticClass: "separator" }),
                          _vm.isPrimaryAccessVpn && _vm.shouldNotDeleteVpn
                            ? _c(
                                "ul",
                                { staticClass: "list" },
                                [
                                  _vm.canAddLan(_vm.selectedVpn.key)
                                    ? _c("ListItem", {
                                        attrs: {
                                          "t-id": "add-new-lan",
                                          text: _vm.isChangeOrder
                                            ? _vm.t(
                                                "DATANET_VPN_CONFIGURE_LAN_AND_DHCP_RELAY"
                                              )
                                            : _vm.t(
                                                "DATANET_VPN_ADD_LAN_AND_DHCP_RELAY"
                                              ),
                                          addable: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addLan({
                                              vpnKey: _vm.selectedVpn.key,
                                            })
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.shouldShowDhcpServers(_vm.selectedVpn.key)
                                    ? _c("ListItem", {
                                        attrs: {
                                          addable: _vm.canAddDhcpServer(
                                            _vm.selectedVpn.key
                                          ),
                                          "t-id": "add-dhcp-server",
                                          text: _vm.isChangeOrder
                                            ? _vm.t(
                                                "DATANET_VPN_CONFIGURE_SERVER"
                                              )
                                            : _vm.t("DATANET_VPN_ADD_SERVER"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addServer({
                                              vpnKey: _vm.selectedVpn.key,
                                            })
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.shouldShowStaticRoutes(
                                    _vm.selectedVpn.key
                                  )
                                    ? _c("ListItem", {
                                        attrs: {
                                          addable: _vm.canAddStaticRoute(
                                            _vm.selectedVpn.key
                                          ),
                                          "t-id": "add-static-route",
                                          text: _vm.isChangeOrder
                                            ? _vm.t(
                                                "DATANET_VPN_CONFIGURE_STATIC_ROUTE"
                                              )
                                            : _vm.t(
                                                "DATANET_VPN_ADD_STATIC_ROUTE"
                                              ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addRoute({
                                              vpnKey: _vm.selectedVpn.key,
                                            })
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "column settings-container" },
            [
              _vm.selectedSetting === _vm.settings.BASE
                ? _c("BaseSettings", {
                    attrs: {
                      vpn: _vm.selectedVpn,
                      v: _vm.getVpnValidationObject(_vm.selectedVpnIndex),
                    },
                  })
                : _vm.selectedSetting === _vm.settings.LAN
                ? _c("Lan", {
                    attrs: {
                      lan: _vm.selectedLan,
                      v: _vm.getLanValidationObject(_vm.selectedLan.key),
                      "active-tooltip": _vm.activeTooltip,
                    },
                    on: {
                      activeTooltip: function ($event) {
                        return _vm.setActiveTooltip($event)
                      },
                    },
                  })
                : _vm.selectedSetting === _vm.settings.DHCP_SERVER
                ? _c("DhcpServer", {
                    attrs: {
                      "dhcp-server": _vm.selectedDhcpServer,
                      v: _vm.getDhcpServerValidationObject(
                        _vm.selectedDhcpServer.key
                      ),
                    },
                  })
                : _vm.selectedSetting === _vm.settings.STATIC_ROUTE
                ? _c("StaticRoute", {
                    attrs: {
                      "vpn-key": _vm.selectedVpn.key,
                      "static-route-key": _vm.selectedStaticRoute.key,
                      v: _vm.getStaticRouteValidationObject(
                        _vm.selectedStaticRoute.key
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm.$v.vpns.$error
        ? [
            !_vm.$v.vpns.hasLessVpnsThanMax
              ? _c("ValidationError", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.t("DATANET_NR_OF_VPNS_EXCEED_MAX_LIMIT", {
                        max: _vm.maxNrOfVpns,
                      })
                    ),
                  },
                })
              : _vm._e(),
            !_vm.$v.vpns.hasVpnsIncludedInBackup
              ? _c("ValidationError", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.t("DATANET_NO_INCLUDED_BACKUP_VPNS")
                    ),
                  },
                })
              : _vm._e(),
            !_vm.$v.vpns.hasAtLeastOneDhcpRelay
              ? _c("ValidationError", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.t("DATANET_VPN_CONFIGURE_AT_LEAST_ONE_DHCP_RELAY")
                    ),
                  },
                })
              : _vm._e(),
            !_vm.$v.vpns.hasAtLeastOneDhcpServer
              ? _c("ValidationError", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.t("DATANET_VPN_CONFIGURE_AT_LEAST_ONE_DHCP_SERVER")
                    ),
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }