const QualityOfServices = Object.freeze({
  PREMIUM: "PREMIUM",
  PREMIUM_OLD: "PREMIUM_OLD",
  REALTIEM: "REALTIME",
  REALTIME_OLD: "REALTIME_OLD",
  VOIP_ONLY: "VOIP_ONLY",
  VOIP_ONLY_OLD: "VOIP_ONLY_OLD",
  BASIC: "BASIC",
  NONE: "NONE",
});

export default QualityOfServices;
