var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vpn-interface-information" },
    [
      _c("telia-heading", { attrs: { tag: "h5", variant: "title-100" } }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.t("DATANET_GUIDANCE_VPN_INTERFACE_TITLE")) +
            "\n  "
        ),
      ]),
      _c("telia-p", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.t("DATANET_GUIDANCE_VPN_INTERFACE_INFO_1")) +
            "\n  "
        ),
      ]),
      _c("telia-p", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.t("DATANET_GUIDANCE_VPN_INTERFACE_INFO_2")) +
            "\n  "
        ),
      ]),
      _c("telia-p", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.t("DATANET_GUIDANCE_VPN_INTERFACE_INFO_3")) +
            "\n  "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }