var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "qos-information" },
    [
      _c(
        "telia-heading",
        {
          staticClass: "margin__bottom__12",
          attrs: { tag: "h5", variant: "title-100" },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_TITLE")) +
              "\n  "
          ),
        ]
      ),
      _c("telia-p", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_1")) +
            "\n  "
        ),
      ]),
      _c("telia-p", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_2")) +
            "\n  "
        ),
      ]),
      _c("div", { staticClass: "margin__bottom__12 margin__left__48" }, [
        _c(
          "ul",
          [
            _c("telia-p", [
              _c("li", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_3")
                    ) +
                    "\n        "
                ),
              ]),
              _c("li", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_4")
                    ) +
                    "\n        "
                ),
              ]),
              _c("li", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_5")
                    ) +
                    "\n        "
                ),
              ]),
              _c("li", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_6")
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c("telia-p", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_7")) +
            "\n  "
        ),
      ]),
      _c("div", { staticClass: "margin__bottom__12 margin__left__48" }, [
        _c(
          "ul",
          [
            _c("telia-p", [
              _c("li", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_8")
                    ) +
                    "\n        "
                ),
              ]),
              _c("li", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_9")
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c("telia-p", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.t("DATANET_GUIDANCE_QUALITY_OF_SERVICE_INFO_10")) +
            "\n  "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }