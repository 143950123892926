<template>
  <div>
    <div v-if="showContactInfoById === 'technical'" class="technical-contact-information">
      <telia-heading tag="h5" variant="title-100">
        {{ t("DATANET_GUIDANCE_TECHNICAL_CONTACT_TITLE") }}
      </telia-heading>
      <telia-p class="margin-bottom__12">
        {{ t("DATANET_GUIDANCE_TECHNICAL_CONTACT_INFO") }}
      </telia-p>
    </div>
    <div v-if="showContactInfoById === 'facility'" class="local-contact-information">
      <telia-heading tag="h5" variant="title-100">
        {{ t("DATANET_GUIDANCE_LOCAL_CONTACT_TITLE") }}
      </telia-heading>
      <telia-p class="margin-bottom__12">
        {{ t("DATANET_GUIDANCE_LOCAL_CONTACT_INFO") }}
      </telia-p>
    </div>
    <div v-if="showContactInfoById === 'delivery'">
      <telia-heading tag="h5" variant="title-100">
        {{ t("DATANET_GUIDANCE_DELIVERY_CONTACT_TITLE") }}
      </telia-heading>
      <telia-p class="margin-bottom__12">
        {{ t("DATANET_GUIDANCE_DELIVERY_CONTACT_INFO") }}
      </telia-p>
    </div>
    <div v-if="showContactInfoById === 'wirelessTechnical'">
      <telia-heading tag="h5" variant="title-100">
        {{ t("DATANET_GUIDANCE_WIRELESS_TECHNICAL_CONTACT_TITLE") }}
      </telia-heading>
      <telia-p class="margin-bottom__12">
        {{ t("DATANET_GUIDANCE_WIRELESS_TECHNICAL_CONTACT_INFO") }}
      </telia-p>
    </div>
    <div v-if="showContactInfoById === 'wirelessSms'">
      <telia-heading tag="h5" variant="title-100">
        {{ t("DATANET_GUIDANCE_WIRELESS_SMS_CONTACT_TITLE") }}
      </telia-heading>
      <telia-p class="margin-bottom__12">
        {{ t("DATANET_GUIDANCE_WIRELESS_SMS_CONTACT_INFO") }}
      </telia-p>
    </div>
    <div v-if="showContactInfoById === 'secondaryFacility'">
      <telia-heading tag="h5" variant="title-100">
        {{ t("DATANET_GUIDANCE_SECONDARY_FACILITY_CONTACT_TITLE") }}
      </telia-heading>
      <telia-p class="margin-bottom__12">
        {{ t("DATANET_GUIDANCE_SECONDARY_FACILITY_CONTACT_INFO") }}
      </telia-p>
    </div>
  </div>
</template>

<script>
import { translateMixin } from "../../../locale";

export default {
  name: "ContactTooltip",
  mixins: [translateMixin],
  props: {
    showContactInfoById: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.margin {
  &__bottom {
    &__4 {
      margin-bottom: $telia-spacing-4;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
  &__top {
    &__12 {
      margin-top: $telia-spacing-12;
    }
  }
  &__left {
    &__48 {
      margin-left: $telia-spacing-48;
    }
  }
}
</style>
