<template>
  <div>
    <div class="heading-wrapper">
      <telia-heading tag="h5" variant="title-100">
        <strong>{{ `DataNet ${t(`DATANET_${product.id}`)}` }}</strong>
      </telia-heading>
    </div>
    <div class="container">
      <div class="items">
        <div>
          <img class="image" :src="productImage" />
        </div>
        <div class="double-size">
          <telia-heading tag="h5" variant="title-100" class="heading-wrapper">
            {{ t(`DATANET_${product.id}`) }}
          </telia-heading>
          <telia-p>
            {{ t(`DATANET_${product.id}_DESCRIPTION`) }}
          </telia-p>
          <telia-p v-if="product.estimatedInstallationDate">
            {{ t("DATANET_PREL_DELIVERY_DATE") + ": " + product.estimatedInstallationDate }}
          </telia-p>
        </div>
        <div>
          <telia-heading tag="h5" variant="title-100" class="heading-wrapper">
            {{ t("DATANET_SPEEDS") }}
          </telia-heading>
          <ul class="list">
            <telia-p>
              <li
                v-for="(bandwidth, index) in bandwidthsToDisplay"
                :key="`${product.id}-${bandwidth}${index}`"
                class="list-item"
                v-text="createBandwidthLabel(bandwidth.downRateKbit, bandwidth.upRateKbit)"
                :title="createBandwidthLabel(bandwidth.downRateKbit, bandwidth.upRateKbit)"
              />
            </telia-p>
          </ul>
          <telia-p v-if="nrOfBandwidthsNotDisplayed > 0" class="additional-info">
            <em>
              + {{ nrOfBandwidthsNotDisplayed }}
              {{
                nrOfBandwidthsNotDisplayed === 1
                  ? t("DATANET_NUMBER_OF_SPEED")
                  : t("DATANET_NUMBER_OF_SPEEDS")
              }}
            </em>
          </telia-p>
        </div>
        <div class="double-size">
          <telia-heading tag="h5" variant="title-100" class="heading-wrapper">
            {{ t("DATANET_SLA") }}
          </telia-heading>
          <ul class="list">
            <telia-p>
              <li
                v-for="(sla, index) in serviceLevelAgreementsToDisplay"
                :key="`${product.id}-${sla}${index}`"
                class="list-item'"
                v-text="t(`DATANET_${sla}_DESCRIPTION`)"
                :title="t(`DATANET_${sla}_DESCRIPTION`)"
              />
            </telia-p>
          </ul>
          <telia-p v-if="nrOfServiceLevelAgreementsNotDisplayed > 0" class="additional-info">
            <em>
              +
              {{ nrOfServiceLevelAgreementsNotDisplayed }}
              {{
                nrOfServiceLevelAgreementsNotDisplayed === 1
                  ? t("DATANET_NUMBER_OF_SLA")
                  : t("DATANET_NUMBER_OF_SLAS")
              }}
            </em>
          </telia-p>
        </div>
      </div>
      <div class="footer">
        <div class="notification-wrapper">
          <telia-notification
            v-if="product.options.quickLaunch"
            t-id="organisation-alert"
            heading-tag="h4"
            variant="inline"
            status="information"
            html-aria-live="polite"
            html-role="alert"
          >
            <span slot="heading">
              <telia-visually-hidden>
                {{ t("A11Y_STATUS_INFORMATION") }}
              </telia-visually-hidden>
              {{ t("INFORMATION") }}
            </span>
            <span slot="content">
              <telia-p>
                {{ t("DATANET_QUICK_LAUNCH_IS_AVAILABLE") }}
              </telia-p>
            </span>
          </telia-notification>
          <telia-notification
            v-if="product.id === Bearers.WIRELESS"
            t-id="organisation-alert"
            heading-tag="h4"
            variant="inline"
            status="information"
            html-aria-live="polite"
            html-role="alert"
          >
            <span slot="heading">
              <telia-visually-hidden>
                {{ t("A11Y_STATUS_INFORMATION") }}
              </telia-visually-hidden>
              {{ t("INFORMATION") }}
            </span>
            <span slot="content">
              <telia-p>
                {{ t("DATANET_WIRELESS_INFO") }}
              </telia-p>
            </span>
          </telia-notification>
        </div>
        <div v-if="selectable" class="button-wrapper">
          <telia-button
            :t-id="`select-${product.id.toLowerCase()}-access`"
            type="button"
            variant="expressive"
            @click="$emit('product-selected', product.id)"
            v-text="t('DATANET_SELECT')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import createBandwidthLabel from "../helpers/createBandwidthLabel";
import { Bearers } from "../constants";
import { translateMixin } from "../locale";
import productImage from "../../assets/images/product_box.png";

const NR_OF_BANDWIDTHS_TO_DISPLAY = 4;
const NR_OF_SERVICE_LEVEL_AGREEMENTS_TO_DISPLAY = 4;

export default {
  name: "Product",
  mixins: [translateMixin],
  props: {
    product: Object,
    selectable: Boolean,
  },

  data() {
    return {
      productImage: productImage,
      Bearers: Bearers,
    };
  },

  computed: {
    ...mapGetters({
      bandwidths: "products/getBandwidthsForPreCheckAccess",
    }),

    ...mapState("user", {
      customerGroupId: (state) => state.customerGroupId,
    }),

    bandwidthsToDisplay() {
      let bandwidths = this.bandwidths(this.product.id);
      if (bandwidths && bandwidths.length > 0) {
        return bandwidths
          .sort((a, b) => {
            const bw1 = a.downRateKbit + a.upRateKbit;
            const bw2 = b.downRateKbit + b.upRateKbit;

            return bw2 - bw1;
          })
          .slice(0, NR_OF_BANDWIDTHS_TO_DISPLAY);
      }

      return [];
    },

    nrOfBandwidthsNotDisplayed() {
      if (this.product.bandwidths) {
        if (this.product.bandwidths.length > NR_OF_BANDWIDTHS_TO_DISPLAY - 1) {
          return this.product.bandwidths.length + 1 - NR_OF_BANDWIDTHS_TO_DISPLAY;
        }
      }

      return 0;
    },

    serviceLevelAgreementsToDisplay() {
      if (this.product.slas) {
        return this.product.slas.slice(0, NR_OF_SERVICE_LEVEL_AGREEMENTS_TO_DISPLAY);
      }

      return [];
    },

    nrOfServiceLevelAgreementsNotDisplayed() {
      if (this.product.slas) {
        if (this.product.slas.length > NR_OF_SERVICE_LEVEL_AGREEMENTS_TO_DISPLAY - 1) {
          return this.product.slas.length + 1 - NR_OF_SERVICE_LEVEL_AGREEMENTS_TO_DISPLAY;
        }
      }

      return 0;
    },
  },

  methods: {
    createBandwidthLabel(down, up) {
      if (this.product.id === Bearers.WIRELESS) {
        return this.t("DATANET_BEST_EFFORT");
      } else {
        return createBandwidthLabel(down, up);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";

.container {
  border: 1px solid $telia-black;
  border-radius: 0.2rem;
  padding: $telia-spacing-24;
}

.items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  grid-gap: $telia-spacing-24;
}

.image {
  float: left;
  width: auto;
  height: 10rem;
  margin: 3.6rem $telia-spacing-24 $telia-spacing-24 0;
}

.double-size {
  grid-column: auto / span 2;
}

.list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: inside;
}

.list-item {
  text-overflow: ellipsis;
}

.heading-wrapper {
  margin-bottom: $telia-spacing-8;
}

.additional-info {
  color: $telia-gray-600;
}

.button-wrapper {
  display: flex;
  flex: 1;
  padding-bottom: 0;
  justify-content: flex-end;
  border: none;
  align-self: flex-end;
}

.notification-wrapper {
  max-width: 80rem;
}

.footer {
  display: flex;
  margin-top: $telia-spacing-32;
}
</style>
