var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.getVpnsByAccess(_vm.Accesses.PRIMARY).length
        ? _c("VpnSettings", {
            ref: _vm.Accesses.PRIMARY + "-vpn",
            attrs: {
              "access-key": _vm.Accesses.PRIMARY,
              vpns: _vm.getVpnsByAccess(_vm.Accesses.PRIMARY),
            },
          })
        : _vm._e(),
      _vm.hasBackupAccess
        ? _c("VpnSettings", {
            ref: _vm.Accesses.SECONDARY + "-vpn",
            attrs: {
              "access-key": _vm.Accesses.SECONDARY,
              vpns: _vm.getVpnsByAccess(_vm.Accesses.SECONDARY),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }