var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.shouldShowTooltipById === "contact-person"
      ? _c(
          "div",
          [
            _c(
              "telia-heading",
              { attrs: { tag: "h5", variant: "title-100" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.t(
                        "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CONTACT_TITLE"
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
            _c("telia-p", { staticClass: "margin__bottom__12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.t(
                      "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CONTACT_INFO"
                    )
                  ) +
                  "\n    "
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.shouldShowTooltipById === "YES_FIXED_FEE"
      ? _c(
          "div",
          [
            _c(
              "telia-heading",
              { attrs: { tag: "h5", variant: "title-100" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.t(
                        "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_YES_TITLE"
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
            _c("telia-p", { staticClass: "margin__bottom__12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.t(
                      "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_YES_INFO_1"
                    )
                  ) +
                  "\n    "
              ),
            ]),
            _c("telia-p", { staticClass: "margin__bottom__12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.t(
                      "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_YES_INFO_2"
                    )
                  ) +
                  "\n      "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.t(
                      "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_INFO_LINK_2"
                    ),
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.t(
                          "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_INFO_LINK_2"
                        )
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.shouldShowTooltipById === "NO_INTERCONNECTION_OF_CONNECTION_POINTS"
      ? _c(
          "div",
          [
            _c(
              "telia-heading",
              {
                staticClass: "right-padding",
                attrs: { tag: "h5", variant: "title-100" },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.t(
                        "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_NO_INTERCONNECTION_TITLE"
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
            _c("telia-p", { staticClass: "margin__bottom__12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.t(
                      "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_NO_INTERCONNECTION_INFO_1"
                    )
                  ) +
                  "\n    "
              ),
            ]),
            _c("telia-p", { staticClass: "margin__bottom__12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.t(
                      "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_NO_INTERCONNECTION_INFO_2"
                    )
                  ) +
                  "\n      "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.t(
                      "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_INFO_LINK_2"
                    ),
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.t(
                          "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_INFO_LINK_2"
                        )
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.shouldShowTooltipById === "NO_EQUIPMENT_AT_CONNECTION_POINTS"
      ? _c(
          "div",
          [
            _c(
              "telia-heading",
              { attrs: { tag: "h5", variant: "title-100" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.t(
                        "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_NO_EQUIPMENT_TITLE"
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
            _c("telia-p", { staticClass: "margin__bottom__12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.t(
                      "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_NO_EQUIPMENT_INFO_1"
                    )
                  ) +
                  "\n    "
              ),
            ]),
            _c("telia-p", { staticClass: "margin__bottom__12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.t(
                      "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_NO_EQUIPMENT_INFO_2"
                    )
                  ) +
                  "\n      "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.t(
                      "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_INFO_LINK_2"
                    ),
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        " " +
                          _vm.t(
                            "DATANET_GUIDANCE_ON_PREMISES_INSTALLATION_CHOICE_INFO_LINK_2"
                          )
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }