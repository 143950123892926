<template>
  <div class="summary">
    <slot></slot>
    <div class="summary__edit">
      <telia-button
        v-if="editable"
        type="button"
        variant="secondary"
        size="sm"
        @click="$emit('edit')"
      >
        {{ editLabel }}
      </telia-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Summary",

  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    editLabel: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";
.summary {
  position: relative;
  max-width: 42rem;
  padding: $telia-spacing-12;
  background-color: $telia-gray-50;
}

.summary__edit {
  position: absolute;
  top: $telia-spacing-12;
  right: $telia-spacing-20;
}
</style>
