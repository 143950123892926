var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "static-route-tooltip" }, [
    _vm.showTooltipById === "route-network"
      ? _c(
          "div",
          [
            _c(
              "telia-heading",
              { attrs: { tag: "h5", variant: "title-100" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.t("DATANET_GUIDANCE_STATIC_ROUTE_NETWORK_TITLE")
                    ) +
                    "\n    "
                ),
              ]
            ),
            _c("telia-p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.t("DATANET_GUIDANCE_STATIC_ROUTE_NETWORK_INFO")) +
                  "\n    "
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.showTooltipById === "next-hop"
      ? _c(
          "div",
          [
            _c(
              "telia-heading",
              { attrs: { tag: "h5", variant: "title-100" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.t("DATANET_GUIDANCE_STATIC_ROUTE_NEXT_HOP_TITLE")
                    ) +
                    "\n    "
                ),
              ]
            ),
            _c("telia-p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.t("DATANET_GUIDANCE_STATIC_ROUTE_NEXT_HOP_INFO")) +
                  "\n    "
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }