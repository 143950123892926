import Vue from "vue";
import { Actions, Accesses, DefaultValues, MetadataKeys } from "../../constants";
import generateShortId from "../../helpers/generateShortId";
import updateExistingObjectProperties from "../../helpers/updateExistingObjectProperties";
import defaultChangeableValue from "../defaultChangeableValue.js";

const state = defaultState();

function defaultState() {
  return {
    action: Actions.NONE,
    servers: {},
  };
}

function defaultServerObject({ key, vpn, siblingDhcpServerKey }) {
  return {
    action: Actions.ADD,
    key,
    vpn,
    siblingDhcpServerKey,
    network: {
      action: Actions.NONE,
      input: { serverKey: key, ...defaultChangeableValue() },
      ipVersion: { serverKey: key, ...defaultChangeableValue() },
      ipAddress: { serverKey: key, ...defaultChangeableValue() },
      ipPrefix: { serverKey: key, ...defaultChangeableValue() },
    },
    excludedIpRanges: {
      action: Actions.NONE,
      values: [],
    },
    dnsIps: {
      action: Actions.NONE,
      values: [],
    },
  };
}

function defaultExcludedRangeObject({ serverKey = DefaultValues.NONE, action = Actions.NONE }) {
  return {
    action,
    start: defaultIpObject(serverKey),
    end: defaultIpObject(serverKey),
  };
}

function defaultIpObject(serverKey = DefaultValues.NONE) {
  return {
    ipVersion: { serverKey, ...defaultChangeableValue() },
    ipAddress: { serverKey, ...defaultChangeableValue() },
  };
}

const getters = {
  hasAnyDhcpServers: (state) => Object.values(state.servers).length,

  hasLessServersThanMax: (state, getters, rootState, rootGetters) => {
    const max = rootGetters["metadata/getMax"](MetadataKeys.STATIC_ROUTES);
    if (max == null) return true;
    const nrOfDhcpServers = Object.values(state.servers).filter(
      (server) => server.action === Actions.DELETE
    ).length;
    return nrOfDhcpServers < max;
  },

  shouldShowDhcpServers: (state, getters, rootState, rootGetters) => (vpnKey) =>
    rootGetters["metadata/isVisible"](MetadataKeys.DHCP_SERVERS) &&
    rootGetters["vpn/getVpn"](vpnKey).action !== Actions.DELETE,

  shouldShowCurrentValues: (state, getters) => (dhcpServerKey) =>
    getters.getServer(dhcpServerKey).action !== Actions.ADD,

  shouldShowNewValues: (state, getters) => (dhcpServerKey) =>
    getters.getServer(dhcpServerKey).action !== Actions.DELETE,

  shouldShowCurrentNetwork: (state, getters) => (dhcpServerKey) =>
    getters.getServer(dhcpServerKey).action !== Actions.ADD,

  shouldShowNewNetwork: (state, getters) => (dhcpServerKey) => {
    const dhcpServer = getters.getServer(dhcpServerKey);
    if (!dhcpServer) return false;
    return dhcpServer.action === Actions.ADD || dhcpServer.network.action === Actions.UPDATE;
  },

  shouldShowNetworkAction: (state, getters) => (dhcpServerKey) =>
    getters.getServer(dhcpServerKey).action === Actions.UPDATE,

  isDhcpServersEditable: (state, getters, rootState, rootGetters) => (vpnKey) =>
    getters.shouldShowDhcpServers(vpnKey) &&
    rootGetters["vpn/isVpnsEditable"] &&
    rootGetters["metadata/isEditable"](MetadataKeys.DHCP_SERVERS),

  isDhcpServerActionEditable: (state, getters, rootState, rootGetters) => () =>
    rootGetters["metadata/isActionEditable"](MetadataKeys.DHCP_SERVERS),

  canDeleteDhcpServer: (state, getters, rootState, rootGetters) => (vpnKey) =>
    getters.isDhcpServersEditable(vpnKey) &&
    rootGetters["vpn/getVpn"](vpnKey).accessKey === Accesses.PRIMARY,

  canAddDhcpServer: (state, getters, rootState, rootGetters) => (vpnKey) => {
    const { action: vpnAction, accessKey } = rootGetters["vpn/getVpn"](vpnKey);
    return (
      getters.hasLessServersThanMax &&
      getters.isDhcpServersEditable(vpnKey) &&
      accessKey === Accesses.PRIMARY &&
      vpnAction !== Actions.DELETE
    );
  },

  getServer: (state) => (serverKey = DefaultValues.NONE) => state.servers[serverKey] || null,

  getAction: (state) => (serverKey = DefaultValues.NONE) => state.servers[serverKey].action || "",

  getAllServers: (state) => Object.values(state.servers) || [],

  getServersByKeys: (state) => (serverKeys = []) =>
    Object.values(state.servers).filter((server) => serverKeys.includes(server.key)),

  getNetworkInput: (state) => (serverKey = DefaultValues.NONE) => {
    const server = state.servers[serverKey];
    return server ? server.network.input : null;
  },

  getNetworkIpVersion: (state) => (serverKey = DefaultValues.NONE) => {
    const server = state.servers[serverKey];
    return server ? server.network.ipVersion : null;
  },

  getNetworkIpAddress: (state) => (serverKey = DefaultValues.NONE) => {
    const server = state.servers[serverKey];
    return server ? server.network.ipAddress : null;
  },

  getNetworkIpPrefix: (state) => (serverKey = DefaultValues.NONE) => {
    const server = state.servers[serverKey];
    return server ? server.network.ipPrefix : null;
  },

  getExcludedIpRanges: (state) => (serverKey = DefaultValues.NONE) => {
    const server = state.servers[serverKey];
    if (!server) {
      return [];
    }

    return server.excludedIpRanges.values || [];
  },

  getExcludedIpRangeStartIpVersion: (state) => (
    serverKey = DefaultValues.NONE,
    index = DefaultValues.NONE
  ) => {
    const server = state.servers[serverKey];
    if (!server) {
      return null;
    }

    const range = server.excludedIpRanges.values[index];
    if (!range || !range.start) {
      return null;
    }

    return range.start.ipVersion || null;
  },

  getExcludedIpRangeStartIpAddress: (state) => (
    serverKey = DefaultValues.NONE,
    index = DefaultValues.NONE
  ) => {
    const server = state.servers[serverKey];
    if (!server) {
      return null;
    }

    const range = server.excludedIpRanges.values[index];
    if (!range || !range.start) {
      return null;
    }

    return range.start.ipAddress || null;
  },

  getExcludedIpRangeEndIpVersion: (state) => (
    serverKey = DefaultValues.NONE,
    index = DefaultValues.NONE
  ) => {
    const server = state.servers[serverKey];
    if (!server) {
      return null;
    }

    const range = server.excludedIpRanges.values[index];
    if (!range || !range.end) {
      return null;
    }

    return range.end.ipVersion || null;
  },

  getExcludedIpRangeEndIpAddress: (state) => (
    serverKey = DefaultValues.NONE,
    index = DefaultValues.NONE
  ) => {
    const server = state.servers[serverKey];
    if (!server) {
      return null;
    }

    const range = server.excludedIpRanges.values[index];
    if (!range || !range.end) {
      return null;
    }

    return range.end.ipAddress || null;
  },

  getDnsIps: (state) => (serverKey = DefaultValues.NONE) => {
    const server = state.servers[serverKey];
    if (!server) {
      return [];
    }

    return server.dnsIps.values || [];
  },

  getDnsIpVersion: (state) => (serverKey = DefaultValues.NONE, index = DefaultValues.NONE) => {
    const server = state.servers[serverKey];
    if (!server) {
      return null;
    }

    const ip = server.dnsIps.values[index];
    if (!ip) {
      return null;
    }

    return ip.ipVersion || null;
  },

  getDnsIpAddress: (state) => (serverKey = DefaultValues.NONE, index = DefaultValues.NONE) => {
    const server = state.servers[serverKey];
    if (!server) {
      return null;
    }

    const ip = server.dnsIps.values[index];
    if (!ip) {
      return null;
    }

    return ip.ipAddress || null;
  },
};

const actions = {
  async addServer({ commit, dispatch, rootGetters }, { vpnKey, serverKey, serverSiblingKey }) {
    const dhcpServerKey = serverKey == null ? generateShortId() : serverKey;
    commit("ADD_SERVER", { serverKey: dhcpServerKey, vpnKey });
    dispatch("setUnsavedDraft", undefined, { root: true });
    dispatch("vpn/addDhcpServer", { vpnKey, dhcpServerKey: dhcpServerKey }, { root: true });

    const siblingDhcpServerKey = serverSiblingKey == null ? generateShortId() : serverSiblingKey;

    const { siblingVpnKey } = rootGetters["vpn/getVpn"](vpnKey);
    commit("ADD_SERVER", {
      serverKey: siblingDhcpServerKey,
      siblingDhcpServerKey: dhcpServerKey,
      vpnKey: siblingVpnKey,
    });
    dispatch(
      "vpn/addDhcpServer",
      { vpnKey: siblingVpnKey, dhcpServerKey: siblingDhcpServerKey },
      { root: true }
    );
    commit("SET_SIBLING_KEY", {
      serverKey: dhcpServerKey,
      siblingKey: siblingDhcpServerKey,
    });

    return dhcpServerKey;
  },

  deleteServer({ state, commit, dispatch }, serverKey = DefaultValues.NONE) {
    const { vpn: vpnKey, key: dhcpServerKey, siblingDhcpServerKey } = state.servers[serverKey];

    dispatch("vpn/removeDhcpServer", { vpnKey, dhcpServerKey }, { root: true });
    commit("DELETE_SERVER", serverKey);

    const hasSiblingDhcpServer = !!state.servers[siblingDhcpServerKey];
    if (hasSiblingDhcpServer) {
      dispatch("deleteServer", siblingDhcpServerKey);
    }

    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setServerAction({ getters, dispatch, commit }, { dhcpServerKey, action }) {
    if (!Object.values(Actions).includes(action)) return;

    const dhcpServer = getters.getServer(dhcpServerKey);
    if (!dhcpServer) return;
    const siblingDhcpServer = getters.getServer(dhcpServer.siblingDhcpServerKey);
    if (!siblingDhcpServer) return;

    commit("RESET_DHCP_SERVER", dhcpServer.key);
    commit("RESET_DHCP_SERVER", dhcpServer.siblingDhcpServerKey);
    commit("SET_SERVER_ACTION", { dhcpServerKey, action });
    commit("SET_SERVER_ACTION", { dhcpServerKey: dhcpServer.siblingDhcpServerKey, action });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkAction({ getters, dispatch, commit }, { dhcpServerKey, action }) {
    if (!Object.values(Actions).includes(action)) return;

    const dhcpServer = getters.getServer(dhcpServerKey);
    if (!dhcpServer) return;

    if (action === Actions.NONE) {
      dispatch("setNetworkInput", {
        serverKey: dhcpServer.key,
        new: "",
      });
      dispatch("setNetworkIpVersion", {
        serverKey: dhcpServer.key,
        new: "",
      });
      dispatch("setNetworkIpAddress", {
        serverKey: dhcpServer.key,
        new: "",
      });
      dispatch("setNetworkIpPrefix", {
        serverKey: dhcpServer.key,
        new: "",
      });
    }

    commit("SET_NETWORK_ACTION", { dhcpServerKey, action });
    commit("SET_NETWORK_ACTION", { dhcpServerKey: dhcpServer.siblingDhcpServerKey, action });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkInput({ dispatch, commit }, { serverKey, action, current, new: _new }) {
    if (serverKey == null) return;
    if (action == null && current == null && _new == null) return;

    const network = state.servers[serverKey].network;
    if (!network) return;

    const input = updateExistingObjectProperties(network.input, {
      action,
      current: current ? current.trim() : current,
      new: _new ? _new.trim() : _new,
    });

    if (!input) return;

    commit("SET_NETWORK_INPUT", { serverKey, input });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkIpVersion({ dispatch, commit }, { serverKey, action, current, new: _new }) {
    if (serverKey == null) return;
    if (action == null && current == null && _new == null) return;

    const network = state.servers[serverKey].network;
    if (!network) return;

    const ipVersion = updateExistingObjectProperties(network.ipVersion, {
      action,
      current: current,
      new: _new,
    });

    if (!ipVersion) return;

    commit("SET_NETWORK_IP_VERSION", { serverKey, ipVersion });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkIpAddress({ dispatch, commit }, { serverKey, action, current, new: _new }) {
    if (serverKey == null) return;
    if (action == null && current == null && _new == null) return;

    const network = state.servers[serverKey].network;
    if (!network) return;

    const ipAddress = updateExistingObjectProperties(network.ipAddress, {
      action,
      current: current,
      new: _new,
    });

    if (!ipAddress) return;

    commit("SET_NETWORK_IP_ADDRESS", { serverKey, ipAddress });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setNetworkIpPrefix({ dispatch, commit }, { serverKey, action, current, new: _new }) {
    if (serverKey == null) return;
    if (action == null && current == null && _new == null) return;

    const network = state.servers[serverKey].network;
    if (!network) return;

    const ipPrefix = updateExistingObjectProperties(network.ipPrefix, {
      action,
      current: current,
      new: _new,
    });

    if (!ipPrefix) return;

    commit("SET_NETWORK_IP_PREFIX", { serverKey, ipPrefix });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  addExcludedIpRange(
    { state, dispatch, commit },
    { serverKey = DefaultValues.NONE, action = Actions.NONE }
  ) {
    if (serverKey === DefaultValues.NONE) {
      return;
    }

    if (!state.servers[serverKey]) {
      return;
    }

    commit("ADD_EXCLUDED_IP_RANGE", { serverKey, action });
    const addedIndex = state.servers[serverKey].excludedIpRanges.values.length - 1;

    const { siblingDhcpServerKey } = state.servers[serverKey];
    commit("ADD_EXCLUDED_IP_RANGE", { serverKey: siblingDhcpServerKey, action });

    dispatch("setUnsavedDraft", undefined, { root: true });

    return addedIndex;
  },

  removeExcludedIpRange(
    { commit, dispatch },
    { serverKey = DefaultValues.NONE, index = DefaultValues.NONE }
  ) {
    if (serverKey === DefaultValues.NONE || index === DefaultValues.NONE) {
      return;
    }

    const server = state.servers[serverKey];
    if (!server || !server.excludedIpRanges.values[index]) {
      return;
    }

    commit("REMOVE_EXCLUDED_IP_RANGE", { serverKey, index });

    const { siblingDhcpServerKey } = state.servers[serverKey];
    commit("REMOVE_EXCLUDED_IP_RANGE", {
      serverKey: siblingDhcpServerKey,
      index,
    });

    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setExcludedRangeStartIpVersion(
    { dispatch, commit },
    { serverKey = DefaultValues.NONE, index = DefaultValues.NONE, action, current, new: _new }
  ) {
    if (serverKey === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const server = state.servers[serverKey];
    if (!server) return;

    const excludedIpRange = server.excludedIpRanges.values[index];
    if (!excludedIpRange) return;

    const ipVersion = updateExistingObjectProperties(excludedIpRange.start.ipVersion, {
      action,
      current: current,
      new: _new,
    });

    if (!ipVersion) return;

    commit("SET_EXCLUDED_IP_RANGE_START_IP_VERSION", {
      serverKey,
      index,
      ipVersion,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setExcludedRangeStartIpAddress(
    { dispatch, commit },
    { serverKey = DefaultValues.NONE, index = DefaultValues.NONE, action, current, new: _new }
  ) {
    if (serverKey === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const server = state.servers[serverKey];
    if (!server) return;

    const excludedIpRange = server.excludedIpRanges.values[index];
    if (!excludedIpRange) return;

    const ipAddress = updateExistingObjectProperties(excludedIpRange.start.ipAddress, {
      action,
      current: current ? current.trim() : current,
      new: _new ? _new.trim() : _new,
    });

    if (!ipAddress) return;

    commit("SET_EXCLUDED_IP_RANGE_START_IP_ADDRESS", {
      serverKey,
      index,
      ipAddress,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setExcludedRangeEndIpVersion(
    { dispatch, commit },
    { serverKey = DefaultValues.NONE, index = DefaultValues.NONE, action, current, new: _new }
  ) {
    if (serverKey === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const server = state.servers[serverKey];
    if (!server) return;

    const excludedIpRange = server.excludedIpRanges.values[index];
    if (!excludedIpRange) return;

    const ipVersion = updateExistingObjectProperties(excludedIpRange.end.ipVersion, {
      action,
      current: current,
      new: _new,
    });

    if (!ipVersion) return;

    commit("SET_EXCLUDED_IP_RANGE_END_IP_VERSION", {
      serverKey,
      index,
      ipVersion,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setExcludedRangeEndIpAddress(
    { dispatch, commit },
    { serverKey = DefaultValues.NONE, index = DefaultValues.NONE, action, current, new: _new }
  ) {
    if (serverKey === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const server = state.servers[serverKey];
    if (!server) return;

    const excludedIpRange = server.excludedIpRanges.values[index];
    if (!excludedIpRange) return;

    const ipAddress = updateExistingObjectProperties(excludedIpRange.end.ipAddress, {
      action,
      current: current ? current.trim() : current,
      new: _new ? _new.trim() : _new,
    });

    if (!ipAddress) return;

    commit("SET_EXCLUDED_IP_RANGE_END_IP_ADDRESS", {
      serverKey,
      index,
      ipAddress,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  addDnsIp({ state, dispatch, commit }, serverKey = DefaultValues.NONE) {
    if (serverKey === DefaultValues.NONE) {
      return;
    }

    if (!state.servers[serverKey]) {
      return;
    }

    commit("ADD_DNS_IP", serverKey);
    const addedIndex = state.servers[serverKey].dnsIps.values.length - 1;

    const { siblingDhcpServerKey } = state.servers[serverKey];
    commit("ADD_DNS_IP", siblingDhcpServerKey);

    dispatch("setUnsavedDraft", undefined, { root: true });

    return addedIndex;
  },

  removeDnsIp(
    { commit, dispatch },
    { serverKey = DefaultValues.NONE, index = DefaultValues.NONE }
  ) {
    if (serverKey === DefaultValues.NONE || index === DefaultValues.NONE) {
      return;
    }

    const server = state.servers[serverKey];
    if (!server || !server.dnsIps.values[index]) {
      return;
    }

    commit("REMOVE_DNS_IP", { serverKey, index });

    const { siblingDhcpServerKey } = state.servers[serverKey];
    commit("REMOVE_DNS_IP", { serverKey: siblingDhcpServerKey, index });

    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setDnsIpVersion(
    { dispatch, commit },
    { serverKey = DefaultValues.NONE, index = DefaultValues.NONE, action, current, new: _new }
  ) {
    if (serverKey === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const server = state.servers[serverKey];
    if (!server) return;

    const dnsIp = server.dnsIps.values[index];
    if (!dnsIp) return;

    const ipVersion = updateExistingObjectProperties(dnsIp.ipVersion, {
      action,
      current: current,
      new: _new,
    });

    if (!ipVersion) return;

    commit("SET_DNS_IP_VERSION", { serverKey, index, ipVersion });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setDnsIpAddress(
    { dispatch, commit },
    { serverKey = DefaultValues.NONE, index = DefaultValues.NONE, action, current, new: _new }
  ) {
    if (serverKey === DefaultValues.NONE) return;
    if (action == null && current == null && _new == null) return;

    const server = state.servers[serverKey];
    if (!server) return;

    const dnsIp = server.dnsIps.values[index];
    if (!dnsIp) return;

    const ipAddress = updateExistingObjectProperties(dnsIp.ipAddress, {
      action,
      current: current ? current.trim() : current,
      new: _new ? _new.trim() : _new,
    });

    if (!ipAddress) return;

    commit("SET_DNS_IP_ADDRESS", { serverKey, index, ipAddress });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },
};

const mutations = {
  SET_ACTION(state, { action }) {
    state.action = action;
  },

  ADD_SERVER(state, { serverKey, vpnKey, siblingDhcpServerKey }) {
    const server = Object.assign(
      {},
      defaultServerObject({ key: serverKey, vpn: vpnKey, siblingDhcpServerKey })
    );
    Vue.set(state.servers, serverKey, server);
  },

  DELETE_SERVER(state, serverKey) {
    Vue.delete(state.servers, serverKey);
  },

  SET_SERVER_ACTION(state, { dhcpServerKey, action }) {
    state.servers[dhcpServerKey].action = action;
  },

  SET_SIBLING_KEY(state, { serverKey, siblingKey }) {
    state.servers[serverKey].siblingDhcpServerKey = siblingKey;
  },

  SET_NETWORK_ACTION(state, { dhcpServerKey, action }) {
    state.servers[dhcpServerKey].network.action = action;
  },

  SET_NETWORK_INPUT(state, { serverKey, input }) {
    Vue.set(state.servers[serverKey].network, "input", input);
  },

  SET_NETWORK_IP_VERSION(state, { serverKey, ipVersion }) {
    Vue.set(state.servers[serverKey].network, "ipVersion", ipVersion);
  },

  SET_NETWORK_IP_ADDRESS(state, { serverKey, ipAddress }) {
    Vue.set(state.servers[serverKey].network, "ipAddress", ipAddress);
  },

  SET_NETWORK_IP_PREFIX(state, { serverKey, ipPrefix }) {
    Vue.set(state.servers[serverKey].network, "ipPrefix", ipPrefix);
  },

  ADD_EXCLUDED_IP_RANGE(state, { serverKey, action }) {
    state.servers[serverKey].excludedIpRanges.values.push(
      defaultExcludedRangeObject({ serverKey, action })
    );
  },

  REMOVE_EXCLUDED_IP_RANGE(state, { serverKey, index }) {
    const server = state.servers[serverKey];
    server.excludedIpRanges.values.splice(index, 1);
  },

  SET_EXCLUDED_IP_RANGE_START_IP_VERSION(state, { serverKey, index, ipVersion }) {
    Vue.set(state.servers[serverKey].excludedIpRanges.values[index].start, "ipVersion", ipVersion);
  },

  SET_EXCLUDED_IP_RANGE_START_IP_ADDRESS(state, { serverKey, index, ipAddress }) {
    Vue.set(state.servers[serverKey].excludedIpRanges.values[index].start, "ipAddress", ipAddress);
  },

  SET_EXCLUDED_IP_RANGE_END_IP_VERSION(state, { serverKey, index, ipVersion }) {
    Vue.set(state.servers[serverKey].excludedIpRanges.values[index].end, "ipVersion", ipVersion);
  },

  SET_EXCLUDED_IP_RANGE_END_IP_ADDRESS(state, { serverKey, index, ipAddress }) {
    Vue.set(state.servers[serverKey].excludedIpRanges.values[index].end, "ipAddress", ipAddress);
  },

  ADD_DNS_IP(state, serverKey) {
    state.servers[serverKey].dnsIps.values.push(defaultIpObject(serverKey));
  },

  REMOVE_DNS_IP(state, { serverKey, index }) {
    const server = state.servers[serverKey];
    server.dnsIps.values.splice(index, 1);
  },

  SET_DNS_IP_VERSION(state, { serverKey, index, ipVersion }) {
    Vue.set(state.servers[serverKey].dnsIps.values[index], "ipVersion", ipVersion);
  },

  SET_DNS_IP_ADDRESS(state, { serverKey, index, ipAddress }) {
    Vue.set(state.servers[serverKey].dnsIps.values[index], "ipAddress", ipAddress);
  },

  RESET_DHCP_SERVER(state, dhcpServerKey) {
    const { key, vpn, siblingDhcpServerKey } = state.servers[dhcpServerKey];
    Vue.set(state.servers, key, defaultServerObject({ key, vpn, siblingDhcpServerKey }));
  },

  RESET_NETWORK() {},

  RESET_STATE(state) {
    Object.keys(state.servers).forEach((key) => delete state.servers[key]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
