<template>
  <div>
    <InformationBox v-if="activeTooltip" @close="activeTooltip = false">
      <DeliveryTooltip />
    </InformationBox>
    <div class="tooltip-wrapper">
      <AddressSearch
        class="input-wrapper"
        :label="t('DATANET_SEARCH_DELIVERY_ADDRESS')"
        :value="deliveryFullAddress"
        @addressSelected="updateDeliveryAddress($event)"
      />
      <telia-icon
        class="tooltip-icon-inputs"
        name="question"
        size="sm"
        @click="activeTooltip = !activeTooltip"
      />
    </div>
  </div>
</template>

<script>
import AddressSearch from "./AddressSearch.vue";
import InformationBox from "./InformationBox";
import DeliveryTooltip from "./tooltip-information/delivery-information/DeliveryTooltip.vue";
import { translateMixin } from "../locale";

export default {
  name: "DeliveryInformation",
  mixins: [translateMixin],
  inject: ["deliveryAddressValidation"],
  components: {
    AddressSearch,
    InformationBox,
    DeliveryTooltip,
  },

  props: {
    scopeId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      activeTooltip: false,
    };
  },

  computed: {
    deliveryFullAddress() {
      return this.$store.getters["addresses/getAddress"]("delivery").fullAddress || "";
    },
  },

  created() {
    this.setupDeliveryAddress();
  },

  methods: {
    setupDeliveryAddress() {
      if (this.deliveryFullAddress) {
        return;
      }

      const installationAddress = this.$store.getters["addresses/getAddress"]("installation");

      if (
        installationAddress &&
        installationAddress.fullAddress &&
        installationAddress.streetName
      ) {
        this.$store.dispatch("addresses/addAddress", {
          addressId: "delivery",
          newAddress: installationAddress,
        });
      }
    },

    updateDeliveryAddress(address) {
      if (address) {
        this.$store.dispatch("addresses/addAddress", {
          addressId: "delivery",
          newAddress: address,
        });
      } else {
        this.$store.dispatch("addresses/resetAddress", "delivery");
      }
    },

    completeStep() {
      this.deliveryAddressValidation.$touch();
      if (!this.deliveryAddressValidation.$invalid) {
        this.$emit("step-complete");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";

.input-wrapper {
  max-width: 42rem;
}

.tooltip-wrapper {
  display: flex;
}

.tooltip-icon-inputs {
  position: relative;
  top: 3.8rem;
  margin-left: $telia-spacing-12;
}

.tooltip-icon-inputs:hover {
  color: $telia-purple-500;
  cursor: pointer;
}
</style>
