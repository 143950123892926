import { Actions } from "../constants";
import defaultChangeableValue from "./defaultChangeableValue";

export default function () {
  return {
    action: Actions.ADD,
    input: defaultChangeableValue(),
    ipVersion: defaultChangeableValue(),
    ipAddress: defaultChangeableValue(),
    ipPrefix: defaultChangeableValue(),
  };
}
