import Vue from "vue";
import { t } from "../../locale";
import { corpInstalledbaseVpn, corpScopeInformation } from "@telia/b2b-rest-client";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { getLoggedInUserInfo, getOrganizations } from "@telia/b2b-logged-in-service";
import { MetadataKeys } from "../../constants";

const defaultState = () => {
  return {
    isTeliaAdmin: false,
    userId: "",
    scopeId: "",
    customerGroupId: "",
    firstName: "",
    lastName: "",
    fullName: "",
    email: "",
    phone: "",
    organisation: "",
    organisations: {},
    existingVpns: {},
  };
};

const state = defaultState();

const getters = {
  shouldShowOrganisation: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.ORG),

  shouldDisableOrganisation: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.ORG),

  shouldValidateOrganisation: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isEditable"](MetadataKeys.ORG),

  getOrganisation: (state) => (orgNumber = "") => state.organisations[orgNumber],

  getOrganisationByTscid: (state) => (tscid = "") =>
    Object.values(state.organisations).find((organisation) => organisation.tscid === tscid),

  getOrganisationOptions: (state) => {
    const organisationOptions = Object.values(state.organisations).map((organisation) => ({
      value: organisation.orgNr,
      label: organisation.name,
      selected: organisation.orgNr === state.organisation,
    }));

    organisationOptions.splice(0, 0, {
      label: t("DATANET_ORGANISATION_PLACEHOLDER"),
      value: "",
      selected: !state.organisation,
      disabled: true,
    });

    return organisationOptions;
  },

  getOrganisationList: (state) => Object.values(state.organisations),

  getAvailableVpnIdsForSelectedOrganisation: (state) => {
    const organisation = state.organisations[state.organisation];
    if (!organisation) return [];

    const organisationVpns = organisation.existingVpns;
    let vpns = [];
    for (const vpn of organisationVpns) {
      vpns.push(state.existingVpns[vpn]);
    }
    return vpns;
  },

  getVpnIdOptions: (state, getters, rootState, rootGetters) => (vpnId) => {
    const placeholderOption = {
      label: t("DATANET_VPN_ID_PLACEHOLDER"),
      value: "",
      disabled: true,
    };
    const vpns = getters.getAvailableVpnIdsForSelectedOrganisation;
    if (!vpns) return [placeholderOption];

    const selectedVpnIds = rootGetters["vpn/getSelectedVpnIds"];
    let vpnIdOptions = vpns.map((vpn) => {
      const disabled = vpnId.split(" - ")[0] !== vpn.id && selectedVpnIds.indexOf(vpn.id) !== -1;
      const value = `${vpn.id}${vpn.alias ? " - " + vpn.alias : ""}`;

      return {
        label: value,
        value,
        disabled,
      };
    });

    vpnIdOptions.splice(0, 0, {
      label: t("DATANET_VPN_ID_PLACEHOLDER"),
      value: "",
      disabled: true,
    });

    return vpnIdOptions;
  },
};

const actions = {
  async getOrganisations({ commit }) {
    try {
      const organisations = await getOrganizations();

      const organisationMap = organisations.reduce((organisationMap, organisation) => {
        const strippedOrgNr = organisation.organizationNumber.replace("-", "");
        organisationMap[strippedOrgNr] = {
          id: strippedOrgNr,
          tscid: organisation.tscid,
          orgNr: strippedOrgNr,
          name: organisation.name,
          existingVpns: [],
        };
        return organisationMap;
      }, {});

      commit("SET_ORGANISATIONS", organisationMap);
    } catch (e) {
      throw new Error("DATANET_COULD_NOT_GET_USER_ORGANISATIONS");
    }
  },

  async getExistingVpns({ commit }, scopeId) {
    let allVpns;
    try {
      allVpns = await corpInstalledbaseVpn.VpnControllerService.getVpnsUsingGet(scopeId);
    } catch (e) {
      throw new Error("DATANET_COULD_NOT_GET_EXISTING_VPNS");
    }

    const existingVpns = {};
    const organisationVpns = {};
    for (const vpn of allVpns) {
      const { vpnId: id, vpnAlias: alias, organisation } = vpn;
      const { number } = organisation;
      const orgNr = number.replace("-", "");
      if (!organisationVpns[orgNr]) {
        organisationVpns[orgNr] = [];
      }
      organisationVpns[orgNr].push(id);
      existingVpns[id] = { id, alias };
    }

    commit("SET_EXISTING_VPNS", existingVpns);

    for (const key in organisationVpns) {
      commit("SET_ORGANISATION_VPNS", {
        organisation: key,
        vpns: organisationVpns[key],
      });
    }
  },

  async getCurrentUser({ commit }) {
    let userData = {
      isTeliaAdmin: false,
      userId: "",
      scopeId: "",
      customerGroupId: "",
      firstName: "",
      lastName: "",
      fullName: "",
      email: "",
      phone: "",
    };

    try {
      const scopeId = await getScopeIdOrThrow();
      const scopeDetails = await corpScopeInformation.ScopeControllerService.getScopeDetails(
        scopeId
      );
      userData.isTeliaAdmin = scopeDetails.user.domain === "TCAD";
      userData.userId = scopeDetails.user.id;
      userData.scopeId = scopeDetails.scope.id;
      userData.customerGroupId = scopeDetails.scope.groupId;

      // TODO: How do we handle user details when imitating?
      if (!userData.isTeliaAdmin) {
        const userDetails = await getLoggedInUserInfo();
        userData.firstName = userDetails.firstName;
        userData.lastName = userDetails.lastName;
        userData.fullName = userDetails.firstName + " " + userDetails.lastName;
        userData.email = userDetails.mail;
        userData.phone = userDetails.phoneNumber;
      }
    } catch (e) {
      throw new Error("DATANET_COULD_NOT_GET_USER_INFO");
    }

    commit("SET_STATE", userData);
  },

  setOrganisation({ commit }, organisation = "") {
    commit("SET_ORGANISATION", organisation);
  },
};

const mutations = {
  SET_STATE(state, newState = defaultState()) {
    Object.assign(state, newState);
  },

  SET_ORGANISATION(state, organisation = "") {
    state.organisation = organisation;
  },

  SET_ORGANISATIONS(state, organisations = {}) {
    Vue.set(state, "organisations", organisations);
  },

  SET_ORGANISATION_VPNS(state, { organisation = "", vpns = [] }) {
    state.organisations[organisation].existingVpns = vpns;
  },

  SET_EXISTING_VPNS(state, vpns = {}) {
    state.existingVpns = vpns;
  },

  RESET_STATE(state) {
    Object.assign(state, defaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
