import Vue from "vue";
import ip from "./ip";

const mutations = {
  ADD_IP(state, { key, ownerKey, action }) {
    Vue.set(state, key, ip({ key, ownerKey, action }));
  },

  DELETE_IP(state, key) {
    Vue.delete(state, key);
  },

  SET_SIBLING(state, { key, siblingKey }) {
    state[key].siblingKey = siblingKey;
  },

  SET_IP(state, { key, ipAddress, ipVersion }) {
    Vue.set(state[key], "ipAddress", ipAddress);
    Vue.set(state[key], "ipVersion", ipVersion);
  },

  RESET_STATE(state) {
    Object.assign(state, {});
  },
};

export default mutations;
