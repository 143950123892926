<template>
  <div class="wrapper" data-test-id="b2b-order-datanet">
    <telia-grid>
      <telia-row v-if="isLoading">
        <telia-col width="12">
          <telia-heading
            tag="h1"
            variant="title-500"
            class="margin__bottom__48"
            v-text="getTitle()"
          />

          <div class="spinner-wrapper">
            <b2x-spinner color="core-purple-light" size="large" />
          </div>
        </telia-col>
      </telia-row>
      <telia-row v-else-if="alert.show">
        <telia-col width="6">
          <telia-heading
            tag="h1"
            variant="title-500"
            class="margin__bottom__48"
            v-text="getTitle()"
          />
          <div class="margin__bottom__24">
            <telia-notification
              heading-tag="h4"
              variant="inline"
              status="warning"
              html-aria-live="polite"
              html-role="alert"
            >
              <span slot="heading">
                <telia-visually-hidden>
                  {{ t("A11Y_STATUS_WARNING") }}
                </telia-visually-hidden>
                {{ t("SOMETHING_WENT_WRONG") }}
              </span>
              <span slot="content">
                <telia-p>
                  {{ alert.text }}
                </telia-p>
              </span>
            </telia-notification>
          </div>
          <div class="margin__bottom__12">
            <telia-button type="button" variant="primary" @click="goToUrl(myBusinessUrl)">
              {{ t("DATANET_BACK_TO_MY_BUSINESS") }}
            </telia-button>
          </div>
        </telia-col>
      </telia-row>
      <telia-row
        v-else-if="
          (!isLoading && !hasSubmittedOrder) || (hasSubmittedOrder && isFunctionBusinessOrder)
        "
      >
        <telia-col width="12">
          <telia-heading
            tag="h1"
            variant="title-500"
            class="margin__bottom__48"
            v-text="getTitle()"
          />
          <Stepper
            v-if="!isLoading"
            :step-titles="stepTitles"
            :current-step="currentStep"
            :show-icons="isNewOrder"
          >
            <!-- Organisation selection -->
            <div
              :slot="`step-${getIndexOfAsStepInArray(
                stepTitles,
                t('DATANET_ORGANISATION_SELECT')
              )}`"
            >
              <div
                v-show="
                  currentStep ===
                  getIndexOfAsStepInArray(stepTitles, t('DATANET_ORGANISATION_SELECT'))
                "
              >
                <OrganisationSettings
                  ref="organisationSettings"
                  :scope-id="scopeId"
                  @step-complete="handleStepComplete()"
                />
              </div>
              <Summary
                v-show="
                  currentStep >
                  getIndexOfAsStepInArray(stepTitles, t('DATANET_ORGANISATION_SELECT'))
                "
                :edit-label="t('DATANET_EDIT')"
                @edit="resetSectionsFrom(1)"
              >
                <telia-p class="margin__bottom__12">
                  <strong>{{ t("DATANET_ORGANISATION") }}</strong>
                  <br />
                  {{ organisationSummaryText }}
                  <br />
                </telia-p>
              </Summary>
            </div>

            <!-- Installation address -->
            <div
              :slot="`step-${getIndexOfAsStepInArray(
                stepTitles,
                t('DATANET_INSTALLATION_ADDRESS')
              )}`"
            >
              <PreCheck
                v-if="
                  currentStep ===
                  getIndexOfAsStepInArray(stepTitles, t('DATANET_INSTALLATION_ADDRESS'))
                "
                t-id="pre-check"
                @result="handlePreCheckData($event)"
              />

              <Summary
                v-else-if="
                  currentStep >
                  getIndexOfAsStepInArray(stepTitles, t('DATANET_INSTALLATION_ADDRESS'))
                "
                :edit-label="t('DATANET_EDIT')"
                @edit="resetSectionsFrom(1)"
              >
                <telia-p class="margin__bottom__12">
                  <strong>{{ t("DATANET_INSTALLATION_ADDRESS") }}</strong>
                  <br />
                  <div v-if="installationAddress.streetName">
                    {{
                      `${installationAddress.streetName} ${installationAddress.streetNumber}${
                        installationAddress.entrance || ""
                      }`
                    }}
                    <br />
                    {{ `${installationAddress.postalCode} ${installationAddress.city}` }}
                    <br />
                  </div>
                  <div v-else-if="installationAddress.x">
                    {{ `X: ${installationAddress.x}` }}
                    <br />
                    {{ `Y: ${installationAddress.y}` }}
                    <br />
                  </div>
                </telia-p>
              </Summary>
            </div>
            <!-- Available accesses -->
            <div
              :slot="`step-${getIndexOfAsStepInArray(stepTitles, t('DATANET_AVAILABILITY_TITLE'))}`"
            >
              <ProductOverview
                v-if="
                  currentStep ===
                  getIndexOfAsStepInArray(stepTitles, t('DATANET_AVAILABILITY_TITLE'))
                "
                @step-complete="handleStepComplete()"
              />

              <Summary
                v-else-if="
                  currentStep > getIndexOfAsStepInArray(stepTitles, t('DATANET_AVAILABILITY_TITLE'))
                "
                id="primary-access"
                :edit-label="t('DATANET_EDIT')"
                @edit="resetSectionsFrom(2)"
              >
                <telia-p>
                  <strong>{{ primaryAccessBearerSummaryText }}</strong>
                  <br />
                  {{ primaryAccessDeliveryDateSummaryText }}
                </telia-p>
              </Summary>
            </div>
            <!-- Access settings -->
            <div
              :slot="`step-${getIndexOfAsStepInArray(stepTitles, t('DATANET_ACCESS_SETTINGS'))}`"
            >
              <div
                v-show="
                  currentStep === getIndexOfAsStepInArray(stepTitles, t('DATANET_ACCESS_SETTINGS'))
                "
              >
                <AccessSettings ref="accessSettings" @step-complete="handleStepComplete()" />
                <div class="margin__top__12">
                  <telia-button
                    t-id="next-step"
                    type="button"
                    :variant="isNewOrder ? 'primary' : 'expressive'"
                    size="sm"
                    @click="$refs.accessSettings.completeStep()"
                  >
                    {{ getStepButtonText() }}
                  </telia-button>
                </div>
              </div>
              <Summary
                v-show="
                  currentStep > getIndexOfAsStepInArray(stepTitles, t('DATANET_ACCESS_SETTINGS')) &&
                  isNewOrder
                "
                :edit-label="t('DATANET_EDIT')"
                @edit="goToStep(getIndexOfAsStepInArray(stepTitles, t('DATANET_ACCESS_SETTINGS')))"
              >
                <telia-p>
                  <strong>{{ t("DATANET_PRIMARY_ACCESS") }}</strong>
                  <br />
                  {{ primaryAccessBearerSummaryText }}
                  <br />
                  <div v-if="isFttxAccess">
                    {{ apartmentNumberSummaryText }}
                    <br />
                    {{ installationRoomSummaryText }}
                    <br />
                  </div>
                  <div v-if="isWirelessAccess">
                    {{ primaryAccessTeliaInstallationSummaryText }}
                    <br />
                    {{ primaryAccessAntennaPackageSummaryText }}
                    <br />
                    {{ primaryAccessAntennaCableSummaryText }}
                    <br />
                    {{ primaryAccessAntennaInstallationSummaryText }}
                    <br />
                  </div>
                  <div v-if="shouldShowPrimaryAccessBandwidth">
                    {{ primaryAccessBandwidthSummaryText }}
                    <br />
                  </div>
                  <div v-if="shouldShowPrimaryAccessSla">
                    {{ primaryAccessSlaSummaryText }}
                    <br />
                  </div>
                  <div v-if="shouldShowPrimaryAccessQos">
                    {{ primaryAccessQosSummaryText }}
                    <br />
                  </div>
                  <div v-if="shouldShowPrimaryAccessTrafficStatistics">
                    {{ primaryAccessStatBasSummaryText }}
                    <br />
                  </div>

                  <div v-if="temporaryAccess.enable">
                    <br />
                    <strong>{{ t("DATANET_TEMPORARY_ACCESS") }}</strong>
                    <br />
                    {{ fastTrackSummaryText }}
                    <br />
                    {{ temporaryAccessBearerSummaryText }}
                    <br />
                    {{ temporaryAccessBandwidthSummaryText }}
                    <br />
                    {{ temporaryAccessSlaSummaryText }}
                    <br />
                    {{ temporaryAccessQosSummaryText }}
                    <br />
                    {{ temporaryAccessTeliaInstallationSummaryText }}
                    <br />
                    {{ temporaryAccessAntennaPackageSummaryText }}
                    <br />
                    {{ temporaryAccessAntennaCableSummaryText }}
                    <br />
                    {{ temporaryAccessAntennaInstallationSummaryText }}
                    <br />
                    {{ temporaryAccessAfterDeliveryChoiceSummaryText }}
                    <br />
                  </div>

                  <div v-if="hasBackupAccess">
                    <br />
                    <strong>{{ t("DATANET_SECONDARY_ACCESS") }}</strong>
                    <br />
                    {{ secondaryAccessBackupSummaryText }}
                    <br />
                    {{ secondaryAccessBearerSummaryText }}
                    <br />
                    <div v-if="isWirelessBackupAccess">
                      {{ secondaryAccessTeliaInstallationSummaryText }}
                      <br />
                      {{ secondaryAccessAntennaPackageSummaryText }}
                      <br />
                      {{ secondaryAccessAntennaCableSummaryText }}
                      <br />
                      {{ secondaryAccessAntennaInstallationSummaryText }}
                      <br />
                    </div>
                    {{ secondaryAccessBandwidthSummaryText }}
                    <br />
                    {{ secondaryAccessSlaSummaryText }}
                    <br />
                    {{ secondaryAccessQosSummaryText }}
                    <br />
                    {{ secondaryAccessStatBasSummaryText }}
                    <br />
                  </div>
                </telia-p>
              </Summary>
              <div
                v-show="
                  currentStep !==
                    getIndexOfAsStepInArray(stepTitles, t('DATANET_ACCESS_SETTINGS')) &&
                  isChangeOrder
                "
              >
                <AccessChangeSummary v-if="isChangeOrder" />
                <div class="margin__top__12">
                  <telia-button
                    t-id="change"
                    type="button"
                    variant="expressive"
                    size="sm"
                    @click="
                      goToStep(getIndexOfAsStepInArray(stepTitles, t('DATANET_ACCESS_SETTINGS')))
                    "
                  >
                    {{ t("DATANET_EDIT") }}
                  </telia-button>
                </div>
                <ValidationError
                  v-if="!hasValidAccessSettings()"
                  class="margin__top__24"
                  v-text="t('DATANET_ACCESS_SETTINGS_ERROR')"
                />
              </div>
            </div>
            <!--- Vpn settings -->
            <div :slot="`step-${getIndexOfAsStepInArray(stepTitles, t('DATANET_VPN_SETTINGS'))}`">
              <div
                v-show="
                  currentStep === getIndexOfAsStepInArray(stepTitles, t('DATANET_VPN_SETTINGS'))
                "
              >
                <AddOns
                  ref="vpnSettings"
                  @step-complete="handleStepComplete()"
                  @is-validating-vpn-settings="isValidatingVpnSettings = $event"
                />
                <div class="margin__top__12">
                  <telia-button
                    t-id="next-step"
                    type="button"
                    :variant="isNewOrder ? 'primary' : 'expressive'"
                    size="sm"
                    :disabled="isValidatingVpnSettings"
                    @click="$refs.vpnSettings.completeStep()"
                  >
                    {{
                      isValidatingVpnSettings ? t("VALIDATING_BUTTON_STATE") : getStepButtonText()
                    }}
                  </telia-button>
                </div>
              </div>
              <Summary
                v-show="
                  currentStep > getIndexOfAsStepInArray(stepTitles, t('DATANET_VPN_SETTINGS')) &&
                  isNewOrder
                "
                :edit-label="t('DATANET_EDIT')"
                @edit="goToStep(getIndexOfAsStepInArray(stepTitles, t('DATANET_VPN_SETTINGS')))"
              >
                <div v-for="(vpn, vpnIndex) in primaryAccessVpns" :key="`vpn-${vpnIndex}`">
                  <telia-p :class="[vpnIndex > 0 ? 'margin__top__12' : '']">
                    <strong>{{ `VPN ${vpnIndex + 1}` }}</strong>
                  </telia-p>
                  <telia-p>
                    {{ `${t("DATANET_VPN_ID")}: ${vpn.id}` }}
                  </telia-p>
                  <telia-p>
                    {{ `${t("DATANET_VPN_ALIAS")}: ${vpn.alias}` }}
                  </telia-p>
                  <telia-p v-if="isMultiVpn">
                    {{ `${t("DATANET_VPN_BANDWIDTH")}: ${getVpnBandwidthText(vpn.key)}` }}
                  </telia-p>
                </div>
              </Summary>
              <div
                v-show="
                  currentStep !== getIndexOfAsStepInArray(stepTitles, t('DATANET_VPN_SETTINGS')) &&
                  isChangeOrder
                "
              >
                <VpnChangeSummary v-if="isChangeOrder" />
                <div class="margin__top__12">
                  <telia-button
                    t-id="change"
                    type="button"
                    variant="expressive"
                    size="sm"
                    @click="
                      goToStep(getIndexOfAsStepInArray(stepTitles, t('DATANET_VPN_SETTINGS')))
                    "
                  >
                    {{ t("DATANET_EDIT") }}
                  </telia-button>
                </div>
                <ValidationError
                  v-if="!hasValidVpnSettings()"
                  v-text="t('DATANET_VPN_SETTINGS_ERROR')"
                />
              </div>
            </div>
            <!-- Contact information -->
            <div
              :slot="`step-${getIndexOfAsStepInArray(
                stepTitles,
                t('DATANET_CONTACT_INFORMATION')
              )}`"
            >
              <div
                v-if="
                  currentStep ===
                    getIndexOfAsStepInArray(stepTitles, t('DATANET_CONTACT_INFORMATION')) ||
                  isChangeOrder
                "
              >
                <ContactInformation
                  ref="contactInformation"
                  @step-complete="handleStepComplete()"
                  @is-validating-contacts="isValidatingContacts = $event"
                />

                <telia-button
                  v-if="!isChangeOrder"
                  t-id="next-step"
                  type="button"
                  :variant="isNewOrder ? 'primary' : 'expressive'"
                  size="sm"
                  :disabled="isValidatingContacts"
                  @click="$refs.contactInformation.completeStep()"
                >
                  {{ isValidatingContacts ? t("VALIDATING_BUTTON_STATE") : getStepButtonText() }}
                </telia-button>
              </div>
              <Summary
                v-else-if="
                  currentStep >
                    getIndexOfAsStepInArray(stepTitles, t('DATANET_CONTACT_INFORMATION')) &&
                  isNewOrder
                "
                :edit-label="t('DATANET_EDIT')"
                @edit="
                  goToStep(getIndexOfAsStepInArray(stepTitles, t('DATANET_CONTACT_INFORMATION')))
                "
              >
                <div v-for="(contact, index) in contacts" :key="contact.id">
                  <telia-p :class="[index > 0 ? 'margin__top__12' : '']">
                    <strong>{{
                      `${t("DATANET_" + contact.id.toUpperCase() + "_CONTACT")}`
                    }}</strong>
                  </telia-p>
                  <telia-p>
                    {{ `${contact.firstName} ${contact.lastName}` }}
                  </telia-p>
                </div>
              </Summary>
            </div>

            <!-- Inhouse cabling installation settings -->
            <div
              :slot="`step-${getIndexOfAsStepInArray(
                stepTitles,
                t('DATANET_ON_PREMISES_INSTALLATION')
              )}`"
            >
              <div
                v-if="
                  currentStep ===
                  getIndexOfAsStepInArray(stepTitles, t('DATANET_ON_PREMISES_INSTALLATION'))
                "
              >
                <OnPremisesInstallation
                  ref="onPremisesInstallation"
                  @step-complete="handleStepComplete()"
                />
                <div class="margin__top__12">
                  <telia-button
                    t-id="next-step"
                    type="button"
                    :variant="isNewOrder ? 'primary' : 'tertiary'"
                    size="sm"
                    @click="$refs.onPremisesInstallation.completeStep()"
                  >
                    {{ getStepButtonText() }}
                  </telia-button>
                </div>
              </div>
              <Summary
                v-else-if="
                  currentStep >
                  getIndexOfAsStepInArray(stepTitles, t('DATANET_ON_PREMISES_INSTALLATION'))
                "
                :edit-label="t('DATANET_EDIT')"
                @edit="
                  goToStep(
                    getIndexOfAsStepInArray(stepTitles, t('DATANET_ON_PREMISES_INSTALLATION'))
                  )
                "
              >
                <telia-p>
                  <strong>
                    {{
                      `${t("DATANET_CONTACT_PERSON")} (${t(
                        "DATANET_PRIMARY_ACCESS"
                      ).toLowerCase()})`
                    }}
                  </strong>
                </telia-p>
                <telia-p>
                  {{ `${installationContact.firstName} ${installationContact.lastName}` }}
                </telia-p>
                <div v-if="primaryInstallationChoice">
                  <telia-p>
                    <strong>{{
                      `${t("DATANET_CHOICE")} (${t("DATANET_PRIMARY_ACCESS").toLowerCase()})`
                    }}</strong>
                  </telia-p>
                  <telia-p>
                    {{ t(primaryInstallationChoice.text) }}
                  </telia-p>
                </div>
                <div v-if="secondaryInstallationChoice" class="margin__top__12">
                  <div v-if="secondaryInstallationContact.emailAddress">
                    <telia-p>
                      <strong>
                        {{
                          `${t("DATANET_CONTACT_PERSON")} (${t(
                            "DATANET_SECONDARY_ACCESS"
                          ).toLowerCase()})`
                        }}
                      </strong>
                    </telia-p>
                    <telia-p>
                      {{
                        `${secondaryInstallationContact.firstName} ${secondaryInstallationContact.lastName}`
                      }}
                    </telia-p>
                  </div>
                  <telia-p>
                    <strong>{{
                      `${t("DATANET_CHOICE")} (${t("DATANET_SECONDARY_ACCESS").toLowerCase()})`
                    }}</strong>
                  </telia-p>
                  <telia-p>
                    {{ t(secondaryInstallationChoice.text) }}
                  </telia-p>
                </div>
              </Summary>
            </div>

            <!-- Invoice settings -->
            <div
              :slot="`step-${getIndexOfAsStepInArray(
                stepTitles,
                t('DATANET_INVOICE_INFORMATION')
              )}`"
            >
              <div
                v-if="
                  currentStep ===
                  getIndexOfAsStepInArray(stepTitles, t('DATANET_INVOICE_INFORMATION'))
                "
              >
                <Invoice
                  ref="invoiceInformation"
                  class="margin__bottom__24"
                  @step-complete="handleStepComplete()"
                />
                <div class="margin__top__12">
                  <telia-button
                    t-id="next-step"
                    type="button"
                    :variant="isNewOrder ? 'primary' : 'expressive'"
                    size="sm"
                    @click="$refs.invoiceInformation.completeStep()"
                  >
                    {{ getStepButtonText() }}
                  </telia-button>
                </div>
              </div>
              <Summary
                v-else-if="
                  currentStep >
                  getIndexOfAsStepInArray(stepTitles, t('DATANET_INVOICE_INFORMATION'))
                "
                :edit-label="t('DATANET_EDIT')"
                @edit="
                  goToStep(getIndexOfAsStepInArray(stepTitles, t('DATANET_INVOICE_INFORMATION')))
                "
              >
                <div v-if="billingAccount">
                  <telia-p>
                    <strong>{{ t("DATANET_INVOICE_ACCOUNT") }}</strong>
                  </telia-p>

                  <telia-p>
                    {{ billingAccount.billingAccountNumber }}
                  </telia-p>

                  <telia-p>
                    <strong>{{ t("DATANET_INVOICE_ACCOUNT_ADDRESS") }}</strong>
                  </telia-p>

                  <telia-p v-if="billingAccount.billingAddress.careOf">
                    {{ `c/o ${billingAccount.billingAddress.careOf}` }}
                  </telia-p>

                  <div v-if="billingAccount.billingAddress.street">
                    <telia-p>
                      {{ billingAccount.billingAddress.street }}
                    </telia-p>
                    <telia-p>
                      {{
                        `${billingAccount.billingAddress.zipCode} ${billingAccount.billingAddress.city}`
                      }}
                    </telia-p>
                  </div>

                  <telia-p>
                    <strong>{{ t("DATANET_INVOICE_ACCOUNT_METHOD") }}</strong>
                  </telia-p>
                  <telia-p>
                    {{ billingAccount.billingMethodText }}
                  </telia-p>
                  <telia-p v-if="billingAccount.billingEmail.email">
                    {{ billingAccount.billingEmail.email }}
                  </telia-p>
                </div>
                <div>
                  <telia-p class="paragraph margin-top-half">
                    <strong>{{ t("DATANET_INVOICE_IS_HANDLED_BY_TELIA_FINANCE") }}</strong>
                  </telia-p>
                  <telia-p>
                    {{ isHandledByTeliaFinance ? t("DATANET_YES") : t("DATANET_NO") }}
                  </telia-p>
                </div>
              </Summary>
            </div>
            <!-- Delivery information -->
            <div
              :slot="`step-${getIndexOfAsStepInArray(
                stepTitles,
                t('DATANET_DELIVERY_INFORMATION')
              )}`"
            >
              <div
                v-if="
                  currentStep ===
                  getIndexOfAsStepInArray(stepTitles, t('DATANET_DELIVERY_INFORMATION'))
                "
              >
                <DeliveryInformation
                  ref="deliveryInformation"
                  :scope-id="scopeId"
                  @step-complete="handleStepComplete()"
                />
                <div class="margin__top__24">
                  <telia-button
                    t-id="next-step"
                    type="button"
                    :variant="isNewOrder ? 'primary' : 'expressive'"
                    size="sm"
                    @click="$refs.deliveryInformation.completeStep()"
                  >
                    {{ getStepButtonText() }}
                  </telia-button>
                </div>
              </div>
              <Summary
                v-else-if="
                  currentStep >
                  getIndexOfAsStepInArray(stepTitles, t('DATANET_DELIVERY_INFORMATION'))
                "
                :edit-label="t('DATANET_EDIT')"
                @edit="
                  goToStep(getIndexOfAsStepInArray(stepTitles, t('DATANET_DELIVERY_INFORMATION')))
                "
              >
                <telia-p>
                  <strong>{{ t("DATANET_DELIVERY_ADDRESS") }}</strong>
                </telia-p>
                <div v-if="deliveryAddress.streetName">
                  <telia-p>
                    {{
                      `${deliveryAddress.streetName} ${deliveryAddress.streetNumber}${
                        deliveryAddress.entrance || ""
                      }`
                    }}
                  </telia-p>
                  <telia-p>
                    {{ `${deliveryAddress.postalCode} ${deliveryAddress.city}` }}
                  </telia-p>
                </div>
              </Summary>
            </div>
            <!-- Order confirmation -->
            <div
              :slot="`step-${getIndexOfAsStepInArray(stepTitles, t('DATANET_ORDER_CONFIRMATION'))}`"
            >
              <OrderConfirmation
                v-if="
                  currentStep ===
                  getIndexOfAsStepInArray(stepTitles, t('DATANET_ORDER_CONFIRMATION'))
                "
                @submitted="hasSubmittedOrder = $event"
                @submitting="isSubmittingOrder = $event"
              />
            </div>
          </Stepper>
          <!-- Change order actions -->
          <section v-if="isChangeOrder || (isNewOrder && isFunctionBusinessOrder)">
            <div v-if="isProductBusinessOrder">
              <telia-p>
                <strong>
                  {{ t("DATANET_ORDER_INFORMATION") }}
                </strong>
              </telia-p>
              <div class="input-wrapper">
                <telia-text-input
                  id="order-alias"
                  t-id="order-alias"
                  :label="t('DATANET_ORDER_ALIAS')"
                  :additional="t('DATANET_ORDER_ALIAS_PLACEHOLDER')"
                  :disabled="isSubmittingOrder"
                  :value="getOrderAlias"
                  @input="$store.dispatch('setOrderAlias', $event.target.value)"
                />
              </div>
            </div>
            <div class="separator margin__top__24 margin__bottom__24"><telia-divider /></div>

            <div class="button-wrapper margin__bottom__48">
              <telia-button
                type="button"
                t-id="submit-change-order"
                variant="expressive"
                size="sm"
                :disabled="isSubmittingOrder || isTeliaAdmin"
                @click="handleSubmit()"
              >
                {{
                  isProductBusinessOrder
                    ? t("DATANET_PLACE_ORDER")
                    : t("DATANET_SAVE_AND_BACK_TO_SDW")
                }}
              </telia-button>

              <telia-button
                type="button"
                variant="secondary"
                size="sm"
                :disabled="isSubmittingOrder || isTeliaAdmin"
                @click="handleCancelOrder"
              >
                {{ t("DATANET_ABORT") }}
              </telia-button>
            </div>

            <div v-if="isFunctionBusinessOrder && saveDraftError" class="margin__top__24">
              <telia-notification
                id="save-draft-alert"
                heading-tag="h4"
                variant="inline"
                status="warning"
                html-aria-live="polite"
                html-role="alert"
              >
                <span slot="heading">
                  <telia-visually-hidden>
                    {{ t("A11Y_STATUS_WARNING") }}
                  </telia-visually-hidden>
                  {{ t("SOMETHING_WENT_WRONG") }}
                </span>
                <span slot="content">
                  <telia-p>
                    {{ saveDraftError }}
                  </telia-p>
                </span>
              </telia-notification>
            </div>

            <div v-if="changeOrderAlert.show" class="margin__top__24">
              <telia-notification
                id="change-order-alert"
                heading-tag="h4"
                variant="inline"
                status="warning"
                html-aria-live="polite"
                html-role="alert"
              >
                <span slot="heading">
                  <telia-visually-hidden>
                    {{ t("A11Y_STATUS_WARNING") }}
                  </telia-visually-hidden>
                  {{ t("SOMETHING_WENT_WRONG") }}
                </span>
                <span slot="content">
                  <telia-p>
                    {{ changeOrderAlert.text }}
                  </telia-p>
                </span>
              </telia-notification>
            </div>
          </section>
        </telia-col>
      </telia-row>
      <telia-row v-else-if="hasSubmittedOrder && !isFunctionBusinessOrder">
        <telia-col width="12">
          <OrderSubmitted data-test-id="order-confirmation-info" />
        </telia-col>
      </telia-row>
    </telia-grid>
    <FloatingSaveBar
      v-if="
        !isLoading &&
        !alert.show &&
        !isFunctionBusinessOrder &&
        !hasSubmittedOrder &&
        primaryAccessBearer &&
        orgNr
      "
      :is-saving="isSavingOrder"
      :has-changes="isUnsavedDraft"
      :saved-at="savedAt"
      :error="saveDraftError"
      @save="handleSave"
    />
  </div>
</template>

<script>
import AccessChangeSummary from "./components/summary/AccessChangeSummary.vue";
import AccessSettings from "./components/access-settings/AccessSettings.vue";
import AddOns from "./components/AddOns.vue";
import ContactInformation from "./components/contact-information/ContactInformation.vue";
import DeliveryInformation from "./components/DeliveryInformation.vue";
import FloatingSaveBar from "./components/FloatingSaveBar.vue";
import Invoice from "./components/Invoice.vue";
import OnPremisesInstallation from "./components/on-premises-installation/OnPremisesInstallation.vue";
import OrderConfirmation from "./components/OrderConfirmation.vue";
import OrderSubmitted from "./components/OrderSubmitted.vue";
import OrganisationSettings from "./components/OrganisationSettings.vue";
import PreCheck from "./components/PreCheck.vue";
import ProductOverview from "./components/ProductOverview.vue";
import Stepper from "./components/Stepper.vue";
import Summary from "./components/Summary.vue";
import ValidationError from "./components/ValidationError.vue";
import VpnChangeSummary from "./components/summary/VpnChangeSummary.vue";
import "@telia/b2x-spinner";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import { corpDatanetService, corpOrderService } from "@telia/b2b-rest-client";
import { Accesses, Bearers, BusinessAreas } from "./constants";
import { store } from "./store/store.js";
import { translateSetup, translateMixin } from "./locale";
import createBandwidthLabel from "./helpers/createBandwidthLabel";
import modelStoreDataToOrderData from "./helpers/modelStoreDataToOrderData.js";

export default {
  name: "App",
  store,
  mixins: [translateMixin, validationMixin],
  components: {
    AccessChangeSummary,
    AccessSettings,
    AddOns,
    ContactInformation,
    DeliveryInformation,
    FloatingSaveBar,
    Invoice,
    OnPremisesInstallation,
    OrderConfirmation,
    OrderSubmitted,
    OrganisationSettings,
    PreCheck,
    ProductOverview,
    Stepper,
    Summary,
    ValidationError,
    VpnChangeSummary,
  },
  provide() {
    return {
      allValidations: this.$v,
      installationAddressValidation: this.$v.installationAddress,
      deliveryAddressValidation: this.$v.deliveryAddress,
    };
  },
  data() {
    return {
      isLoading: true,
      isSubmittingOrder: false,
      hasSubmittedOrder: false,
      isSavingOrder: false,
      saveDraftError: "",
      savedAt: "",
      xtasId: "",
      isValidatingContacts: false,
      isValidatingVpnSettings: false,
      alert: {
        show: false,
        type: "warning",
        text: "",
      },
      changeOrderAlert: {
        show: false,
        type: "warning",
        text: "",
      },
      myBusinessUrl: "https://www.telia.se/foretag/mybusiness/",
    };
  },

  computed: {
    ...mapState({
      currentStep: (state) => state.currentStep,
      orderCaptureId: (state) => state.orderCaptureId,
      accessId: (state) => state.accessId,
      orderReturnUrl: (state) => state.returnUrl,
      isUnsavedDraft: (state) => state.isUnsavedDraft,
    }),

    ...mapState("access", {
      primaryAccess: (state) => state[Accesses.PRIMARY],
      temporaryAccess: (state) => state[Accesses.TEMPORARY],
      secondaryAccess: (state) => state[Accesses.SECONDARY],
    }),

    ...mapState("addresses", {
      installationRoom: (state) => state.installation.installationRoom,
    }),

    ...mapState("apartments", {
      apartmentNr: (state) => state.apartment,
    }),

    ...mapState("invoice", {
      isHandledByTeliaFinance: (state) => state.isHandledByTeliaFinance,
      billingAccountNumber: (state) => state.billingAccountNumber,
    }),

    ...mapState("onPremisesInstallation", {
      selectedChoiceId: (state) => state.selectedChoiceId,
      primaryAccessInstallationChoice: (state) => state.primary.selectedChoiceId,
      secondaryAccessInstallationChoice: (state) => state.secondary.selectedChoiceId,
    }),

    ...mapState("products", {
      primaryAccessBearer: (state) => state.bearer.value,
      secondaryAccessBearer: (state) => state.secondaryBearer,
    }),

    ...mapState("user", {
      scopeId: (state) => state.scopeId,
      isTeliaAdmin: (state) => state.isTeliaAdmin,
      orgNr: (state) => state.organisation,
      organisations: (state) => state.organisations,
    }),

    ...mapState("vpn", {
      isMultiVpn: (state) => state.multiVpn,
    }),

    ...mapGetters([
      "isNewOrder",
      "isChangeOrder",
      "isProductBusinessOrder",
      "isFunctionBusinessOrder",
      "getOrderAlias",
    ]),

    ...mapGetters("access", [
      "shouldShowPrimaryAccessBandwidth",
      "shouldShowPrimaryAccessQos",
      "shouldShowPrimaryAccessSla",
      "shouldShowPrimaryAccessTrafficStatistics",
      "includeAccessConfigurationStep",
      "hasBackupAccess",
      "hasBackupTypeBackup",
    ]),

    ...mapGetters("addresses", [
      "getAddress",
      "includeInstallationSelectionStep",
      "includeDeliveryConfigurationStep",
    ]),

    ...mapGetters("contactPersons", [
      "includeContactConfigurationStep",
      "getContact",
      "getAllContacts",
    ]),

    ...mapGetters("dhcpServer", { dhcpServer: "getAllServers" }),

    ...mapGetters("invoice", ["includeInvoiceConfigurationStep", "getBillingAccount"]),

    ...mapGetters("lans", { lan: "getAllLans" }),

    ...mapGetters("onPremisesInstallation", [
      "includeInstallationConfigurationStep",
      "getInstallationChoice",
    ]),

    ...mapGetters("products", [
      "getBandwidthText",
      "getAccess",
      "isWirelessAccess",
      "isCopperAccess",
      "isFiberAccess",
      "isFttxAccess",
      "isWirelessBackupAccess",
      "includePrimaryBearerSelectionStep",
    ]),

    ...mapGetters("staticRoutes", { staticRoutes: "getAllRoutes" }),

    ...mapGetters("vpn", [
      "getVpn",
      "isStaticBandwidthDistribution",
      "isDynamicBandwidthDistribution",
      "shouldShowVpns",
      "shouldShowRtTot",
      "shouldValidateVpnSettings",
      "isLogicalInterface",
      "isPhysicalInterface",
    ]),

    ...mapGetters("vpn", {
      vpns: "getAllVpns",
    }),

    ...mapGetters("user", ["shouldShowOrganisation", "getOrganisation", "getOrganisationList"]),

    stepTitles() {
      let stepTitles = [];

      if (this.shouldShowOrganisation) {
        stepTitles.push(this.t("DATANET_ORGANISATION_SELECT"));
      }

      if (this.includeInstallationSelectionStep) {
        stepTitles.push(this.t("DATANET_INSTALLATION_ADDRESS"));
      }

      if (this.includePrimaryBearerSelectionStep) {
        stepTitles.push(this.t("DATANET_AVAILABILITY_TITLE"));
      }

      if (this.includeAccessConfigurationStep) {
        stepTitles.push(this.t("DATANET_ACCESS_SETTINGS"));
      }

      if (this.shouldShowVpns || this.shouldShowRtTot) {
        stepTitles.push(this.t("DATANET_VPN_SETTINGS"));
      }

      if (this.includeContactConfigurationStep) {
        stepTitles.push(this.t("DATANET_CONTACT_INFORMATION"));
      }

      if (this.includeInstallationConfigurationStep) {
        stepTitles.push(this.t("DATANET_ON_PREMISES_INSTALLATION"));
      }

      if (this.includeInvoiceConfigurationStep) {
        stepTitles.push(this.t("DATANET_INVOICE_INFORMATION"));
      }

      if (this.includeDeliveryConfigurationStep) {
        stepTitles.push(this.t("DATANET_DELIVERY_INFORMATION"));
      }

      if (this.isNewOrder && this.isProductBusinessOrder) {
        stepTitles.push(this.t("DATANET_ORDER_CONFIRMATION"));
      }

      return stepTitles;
    },

    installationAddress() {
      return this.getAddress("installation");
    },

    primaryAccessDeliveryDateSummaryText() {
      const access = this.getAccess(this.primaryAccessBearer);
      return `${this.t("DATANET_PREL_DELIVERY_DATE")} ${access.estimatedInstallationDate}`;
    },

    organisationSummaryText() {
      if (!this.orgNr) return "";

      const organisation = this.getOrganisation(this.orgNr);
      return `${organisation.orgNr} - ${organisation.name} `;
    },

    primaryAccessBearerSummaryText() {
      return this.t("DATANET_SUMMARY_BEARER", {
        bearer: this.t(`DATANET_${this.primaryAccessBearer}`),
      });
    },

    apartmentNumberSummaryText() {
      return this.t("DATANET_SUMMARY_APARTMENT_NUMBER", {
        number: this.apartmentNr,
      });
    },

    installationRoomSummaryText() {
      return this.t("DATANET_SUMMARY_INSTALLATION_INFO", {
        info: this.installationRoom,
      });
    },

    primaryAccessTeliaInstallationSummaryText() {
      return this.t("DATANET_SUMMARY_HARDWARE_INSTALLATION", {
        response: this.primaryAccess.options.wirelessInstallation
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    primaryAccessAntennaPackageSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_PACKAGE", {
        package: this.t(`DATANET_${this.primaryAccess.options.antenna.package}`),
      });
    },

    primaryAccessAntennaInstallationSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_CABLE", {
        response: this.primaryAccess.options.antenna.cable
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    primaryAccessAntennaCableSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_INSTALLATION", {
        response: this.primaryAccess.options.antenna.installation
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    primaryAccessBandwidthSummaryText() {
      return this.t("DATANET_SUMMARY_BANDWIDTH", {
        bandwidth: this.getBandwidthText(
          this.primaryAccessBearer,
          this.primaryAccess.bandwidth.new
        ),
      });
    },

    primaryAccessSlaSummaryText() {
      return this.t("DATANET_SUMMARY_SLA", {
        sla: this.primaryAccess.sla.new,
      });
    },

    primaryAccessQosSummaryText() {
      return this.t("DATANET_SUMMARY_QOS", {
        qos: this.t(`DATANET_${this.primaryAccess.qos.new}_DESCRIPTION`),
      });
    },

    primaryAccessStatBasSummaryText() {
      return this.t("DATANET_SUMMARY_STAT_BAS", {
        response: this.primaryAccess.options.trafficStatistics.new
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    fastTrackSummaryText() {
      return this.t("DATANET_SUMMARY_FAST_TRACK", {
        response: this.temporaryAccess.fastTrack ? this.t("DATANET_YES") : this.t("DATANET_NO"),
      });
    },

    temporaryAccessBearerSummaryText() {
      return this.t("DATANET_SUMMARY_BEARER", {
        bearer: this.t(`DATANET_${this.temporaryAccess.bearer}`),
      });
    },

    temporaryAccessBandwidthSummaryText() {
      return this.t("DATANET_SUMMARY_BANDWIDTH", {
        bandwidth: this.getBandwidthText(
          this.temporaryAccess.bearer,
          this.temporaryAccess.bandwidth.new
        ),
      });
    },

    temporaryAccessSlaSummaryText() {
      return this.t("DATANET_SUMMARY_SLA", {
        sla: this.temporaryAccess.sla.new,
      });
    },

    temporaryAccessQosSummaryText() {
      return this.t("DATANET_SUMMARY_QOS", {
        qos: this.t(`DATANET_${this.temporaryAccess.qos.new}_DESCRIPTION`),
      });
    },

    temporaryAccessTeliaInstallationSummaryText() {
      return this.t("DATANET_SUMMARY_HARDWARE_INSTALLATION", {
        response: this.temporaryAccess.options.wirelessInstallation
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    temporaryAccessAntennaPackageSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_PACKAGE", {
        package: this.t(`DATANET_${this.temporaryAccess.options.antenna.package}`),
      });
    },

    temporaryAccessAntennaCableSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_CABLE", {
        response: this.temporaryAccess.options.antenna.cable
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    temporaryAccessAntennaInstallationSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_INSTALLATION", {
        response: this.temporaryAccess.options.antenna.installation
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    temporaryAccessAfterDeliveryChoiceSummaryText() {
      return this.t("DATANET_SUMMARY_AFTER_DELIVERY_OPTION", {
        option: this.temporaryAccess.useAsSecondaryAccess
          ? this.t("DATANET_USE_TEMPORARY_ACCESS_AS_BACKUP")
          : this.t("DATANET_TERMINATE"),
      });
    },

    secondaryAccessBackupSummaryText() {
      return this.t("DATANET_SUMMARY_BACKUP", {
        backup: this.t(`DATANET_${this.secondaryAccess.backup}`),
      });
    },

    secondaryAccessBearerSummaryText() {
      return this.t("DATANET_SUMMARY_BEARER", {
        bearer: this.t(`DATANET_${this.secondaryAccessBearer}`),
      });
    },

    secondaryAccessTeliaInstallationSummaryText() {
      return this.t("DATANET_SUMMARY_HARDWARE_INSTALLATION", {
        response: this.secondaryAccess.options.wirelessInstallation
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    secondaryAccessAntennaPackageSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_PACKAGE", {
        package: this.t(`DATANET_${this.secondaryAccess.options.antenna.package}`),
      });
    },

    secondaryAccessAntennaCableSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_CABLE", {
        response: this.secondaryAccess.options.antenna.cable
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    secondaryAccessAntennaInstallationSummaryText() {
      return this.t("DATANET_SUMMARY_ANTENNA_INSTALLATION", {
        response: this.secondaryAccess.options.antenna.installation
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    secondaryAccessBandwidthSummaryText() {
      return this.t("DATANET_SUMMARY_BANDWIDTH", {
        bandwidth: this.getBandwidthText(
          this.secondaryAccessBearer,
          this.secondaryAccess.bandwidth.new
        ),
      });
    },

    secondaryAccessSlaSummaryText() {
      return this.t("DATANET_SUMMARY_SLA", {
        sla: this.secondaryAccess.sla.new,
      });
    },

    secondaryAccessQosSummaryText() {
      return this.t("DATANET_SUMMARY_QOS", {
        qos: this.t(`DATANET_${this.secondaryAccess.qos.new}_DESCRIPTION`),
      });
    },

    secondaryAccessStatBasSummaryText() {
      return this.t("DATANET_SUMMARY_STAT_BAS", {
        response: this.secondaryAccess.options.trafficStatistics.new
          ? this.t("DATANET_YES")
          : this.t("DATANET_NO"),
      });
    },

    deliveryAddress() {
      return this.getAddress("delivery");
    },

    contacts() {
      return this.getAllContacts.filter(
        (contact) =>
          contact.firstName &&
          contact.id !== "installation" &&
          contact.id !== "secondaryInstallation"
      );
    },

    installationContact() {
      return this.getContact("installation");
    },

    secondaryInstallationContact() {
      return this.getContact("secondaryInstallation");
    },

    primaryInstallationChoice() {
      return this.getInstallationChoice(this.primaryAccessInstallationChoice);
    },

    secondaryInstallationChoice() {
      return this.getInstallationChoice(this.secondaryAccessInstallationChoice);
    },

    billingAccount() {
      return this.getBillingAccount(this.billingAccountNumber);
    },

    primaryAccessVpns() {
      return this.vpns.filter((vpn) => vpn.accessKey === Accesses.PRIMARY);
    },
  },

  validations: {
    installationAddress: {
      fullAddress: {
        required: requiredIf(function () {
          const x = this.installationAddress.x;
          const y = this.installationAddress.y;

          return !x && !y;
        }),
      },
      x: {
        required: requiredIf(function () {
          const fullAddress = this.installationAddress.fullAddress;
          return !fullAddress;
        }),
      },
      y: {
        required: requiredIf(function () {
          const fullAddress = this.installationAddress.fullAddress;
          return !fullAddress;
        }),
      },
    },
    deliveryAddress: {
      fullAddress: {
        required: requiredIf(function () {
          return (
            this.isWirelessAccess ||
            this.temporaryAccess.enable ||
            (this.secondaryAccess.enable && this.isWirelessBackupAccess)
          );
        }),
      },
    },
  },

  async mounted() {
    this.isLoading = true;
    await translateSetup(["mybusiness"]);
    try {
      await this.getCurrentUser();
    } catch (e) {
      let errorMessage = e.message;
      if (errorMessage.includes("Error: ")) {
        errorMessage = errorMessage.replace("Error: ", "");
      }
      this.showError(this.t(errorMessage));
      this.isLoading = false;
    }

    const hasPermission = await this.checkDataNetOrderPermission();
    if (!hasPermission) {
      this.showError(this.t("DATANET_PERMISSION_MISSING"));
      this.isLoading = false;
      return;
    }

    try {
      await this.$store.dispatch("setupStoreWithOrderData");
    } catch (e) {
      let errorMessage = e.message;
      if (errorMessage.includes("Error: ")) {
        errorMessage = errorMessage.replace("Error: ", "");
      }
      this.showError(this.t(errorMessage));
      this.isLoading = false;
    }

    // TODO: Temp. fix for MYBD-11449. To be removed at a later date.
    if (this.isChangeOrder && this.isProductBusinessOrder) {
      let isBlockedOrganisation = false;
      const organisations = this.getOrganisationList;
      organisations.forEach((organisation) => {
        if (organisation.tscid === "7000953161") {
          isBlockedOrganisation = true;
        }
      });

      if (isBlockedOrganisation) {
        this.showError(this.t("DATANET_PERMISSION_MISSING"));
        this.isLoading = false;
        return;
      }
    }

    window.onbeforeunload = this.handleUnload;
    this.isLoading = false;
  },

  async destroyed() {
    await this.$store.dispatch("resetAllStates");
  },

  methods: {
    ...mapActions("user", ["getCurrentUser"]),

    async checkDataNetOrderPermission() {
      try {
        const permissionData = await corpOrderService.DataComOrderPermissionsControllerService.getDataComUserPermissions(
          this.scopeId
        );
        return permissionData.permissionList.indexOf("DATANET") !== -1;
      } catch (e) {
        return false;
      }
    },

    goToUrl(url) {
      window.location.href = url;
    },

    getTitle() {
      if (this.isChangeOrder) {
        return this.t("DATANET_CHANGE_OF_ACCESS", {
          id: this.accessId ? this.accessId : this.t("DATANET"),
        });
      } else if (this.isFunctionBusinessOrder && this.isNewOrder) {
        return this.t("DATANET_TECHNICAL_CONFIGURATION");
      } else {
        return this.t("DATANET");
      }
    },

    handleStepComplete() {
      if (this.isChangeOrder) {
        this.goToStep(99);
      } else if (this.isNewOrder) {
        this.nextStep();
      }
    },

    getStepButtonText() {
      if (this.isChangeOrder) {
        return this.t("DATANET_MINIMIZE");
      } else if (this.isNewOrder) {
        return this.t("DATANET_NEXT_STEP");
      }
    },

    hasValidAccessSettings() {
      if (!this.$refs.accessSettings) return true;
      return !this.$refs.accessSettings.$v.$anyError;
    },

    hasValidVpnSettings() {
      if (!this.$refs.vpnSettings) return true;
      if (!this.$refs.vpnSettings.$children[0]) return true;
      return !this.$refs.vpnSettings.$children[0].$v.$anyError;
    },

    showError(text = "") {
      this.alert.text = text;
      this.alert.show = true;
    },

    handlePreCheckData({ coordinates, address, products }) {
      if (coordinates) {
        const { x, y } = coordinates;

        this.$store.dispatch("addresses/addAddress", {
          addressId: "installation",
          newAddress: { x, y },
        });
      } else if (address) {
        this.$store.dispatch("addresses/addAddress", {
          addressId: "installation",
          newAddress: address,
        });
      }

      this.$store.dispatch("products/setProducts", products);

      if (products.orderCaptureAccess[Bearers.FTTX]) {
        this.$store.dispatch(
          "apartments/setApartments",
          products.orderCaptureAccess[Bearers.FTTX].fttxApartments
        );
      }

      this.nextStep();
    },

    getIndexOfAsStepInArray(stepTitles = [], stepTitle = "") {
      return stepTitles.indexOf(stepTitle) + 1;
    },

    isLastStep(stepTitles = [], stepTitle = "") {
      return this.getIndexOfAsStepInArray(stepTitles, stepTitle) === stepTitles.length;
    },

    nextStep() {
      if (this.isSubmittingOrder) {
        return;
      }

      if (this.isChangeOrder) {
        this.goToStep(99);
        return;
      }

      if (this.currentStep !== this.stepTitles.length + 1) {
        this.$store.dispatch("incrementStep");
      }
    },

    async resetSectionsFrom(step) {
      if (this.isSubmittingOrder) {
        return;
      }

      const editSection = confirm(this.t("DATANET_RESET_SECTIONS"));

      if (editSection) {
        let modulesToReset = [
          "access",
          "vpn",
          "lans",
          "contactPersons",
          "onPremisesInstallation",
          "invoice",
        ];

        this.$v.$reset();

        if (Object.keys(this.organisations).length !== 1) {
          this.$store.dispatch("user/setOrganisation", "");
        }

        if (step === 1) {
          modulesToReset = ["products", ...modulesToReset];
        } else if (step === 2) {
          this.$store.dispatch("products/setSelectedAccess", "");
        }

        for (let i = 0; i < modulesToReset.length; i++) {
          this.$store.dispatch("resetModuleState", modulesToReset[i]);
        }
        if (!this.isChangeOrder) {
          await this.$store.dispatch("vpn/addVpn", {
            addDefaultLan: !this.isChangeOrder,
          });
        }
        this.goToStep(step);
      }
    },

    goToStep(step) {
      if (this.isSubmittingOrder) {
        return;
      }

      this.$store.dispatch("setStep", step);
    },

    async handleSubmit() {
      this.changeOrderAlert.show = false;
      this.changeOrderAlert.text = "";

      if (this.isFunctionBusinessOrder) {
        await this.handleSave(true);
      } else if (this.isChangeOrder && this.isProductBusinessOrder) {
        this.isSubmittingOrder = true;
        this.$refs.accessSettings.$v.$touch();
        this.$refs.vpnSettings.$children[0].$v.$touch();
        const isContactInformationValid = await this.$refs.contactInformation.validate();

        if (!this.hasValidAccessSettings() || !this.hasValidVpnSettings()) {
          this.isSubmittingOrder = false;
          return;
        }

        if (!isContactInformationValid) {
          this.isSubmittingOrder = false;
          return;
        }

        try {
          await this.$store.dispatch("submitOrder", {
            businessArea: BusinessAreas.PRODUCT,
            orderId: this.orderCaptureId,
          });
          this.hasSubmittedOrder = true;
        } catch (e) {
          this.setChangeOrderError(this.t(e.message));
        }

        this.isSubmittingOrder = false;
      }
    },

    async handleSave(redirectAfterSave = false) {
      if (this.isSubmittingOrder) {
        return;
      }

      this.isSavingOrder = true;
      this.saveDraftError = "";

      let saveData;
      try {
        saveData = modelStoreDataToOrderData(this.$store.state);
      } catch (e) {
        this.saveDraftError = this.t("DATANET_COULD_NOT_FORMAT_STORE_DATA");
        this.isSavingOrder = false;
        return;
      }

      let orderData;
      if (this.orderCaptureId) {
        if (this.isNewOrder) {
          try {
            orderData = await corpDatanetService.ExternalDatanetControllerService.updateNewOrderCapturePreSave(
              this.scopeId,
              this.orderCaptureId,
              saveData
            );

            if (redirectAfterSave) {
              this.hasSubmittedOrder = true;
              this.goToUrl(this.orderReturnUrl);
              return;
            }
          } catch (e) {
            if (e?.body?.errorKey === "SPRINGFIELD_CONFLICT_WHEN_UPDATING_ORDER_CAPTURE") {
              this.saveDraftError = this.t("SPRINGFIELD_CONFLICT_WHEN_UPDATING_ORDER_CAPTURE");
            } else {
              this.saveDraftError = this.t("DATANET_COULD_NOT_UPDATE_ORDER");
            }

            this.isSavingOrder = false;
            return;
          }
        } else if (this.isChangeOrder) {
          try {
            orderData = await corpDatanetService.ExternalDatanetControllerService.updateChangeOrderCapturePreSave(
              this.scopeId,
              this.orderCaptureId,
              saveData
            );

            if (redirectAfterSave) {
              this.hasSubmittedOrder = true;
              this.goToUrl(this.orderReturnUrl);
              return;
            }
          } catch (e) {
            this.saveDraftError = this.t("DATANET_COULD_NOT_UPDATE_ORDER");
            this.isSavingOrder = false;
            return;
          }
        } else {
          this.saveDraftError = this.t("DATANET_UNKNOWN_ORDER_TYPE");
          this.isSavingOrder = false;
          return;
        }
      } else {
        try {
          orderData = await corpDatanetService.ExternalDatanetControllerService.addOrderCapturePreSave(
            this.scopeId,
            saveData
          );
        } catch (e) {
          this.saveDraftError = this.t("DATANET_COULD_NOT_SAVE_NEW_ORDER");
          this.isSavingOrder = false;
          return;
        }
      }

      const { businessArea = "", orderType = "", orderCaptureId = "", version = 0 } = orderData;
      this.$store.dispatch("setState", {
        businessArea,
        orderType,
        orderCaptureId,
        version,
      });

      this.savedAt = new Date().toLocaleString("sv-SV", {
        day: "numeric",
        month: "short",
        hour: "numeric",
        minute: "numeric",
      });

      this.$store.dispatch("setSavedDraft");
      this.isSavingOrder = false;
    },

    setChangeOrderError(text) {
      this.changeOrderAlert.show = true;
      this.changeOrderAlert.text = text;
    },

    getVpnBandwidthText(vpnIndex) {
      const { accessKey, bandwidth } = this.getVpn(vpnIndex);

      if (this.isDynamicBandwidthDistribution(accessKey)) {
        return this.t("DATANET_VPN_BANDWIDTH_DYNAMIC");
      } else if (bandwidth.new) {
        const isWirelessAccess =
          accessKey === Accesses.SECONDARY ? this.isWirelessBackupAccess : this.isWirelessAccess;
        return isWirelessAccess
          ? this.t("DATANET_BEST_EFFORT")
          : createBandwidthLabel(bandwidth.new, bandwidth.new);
      }

      return "";
    },

    handleUnload() {
      const isCurrentSectionOrganisation =
        this.currentStep ===
        this.getIndexOfAsStepInArray(this.stepTitles, this.t("DATANET_ORGANISATION_SELECT"));
      if (this.isUnsavedDraft && !this.hasSubmittedOrder && !isCurrentSectionOrganisation) {
        return this.t("DATANET_UNSAVED_BEFORE_UNLOAD");
      } else {
        return undefined;
      }
    },

    handleCancelOrder() {
      if (this.isFunctionBusinessOrder) {
        window.location.href = this.orderReturnUrl;
        return;
      } else {
        window.location.href = `/foretag/mybusiness/hantera/produkter-tjanster/datanet-vpn/${this.accessId}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.wrapper {
  position: relative;
  margin-top: $telia-spacing-48;
}
.spinner-wrapper {
  display: flex;
  justify-content: center;
}
.heading-wrapper {
  padding: $telia-spacing-48 0;
}
.input-wrapper {
  max-width: 42rem;
}
.button-wrapper > * {
  margin-right: $telia-spacing-8;
}
.separator {
  max-width: 42rem;
}
.margin {
  &__bottom {
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
    &__48 {
      margin-bottom: $telia-spacing-48;
    }
  }
  &__top {
    &__12 {
      margin-top: $telia-spacing-12;
    }
    &__24 {
      margin-top: $telia-spacing-24;
    }
  }
}
</style>
