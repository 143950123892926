import Vue from "vue";
import { t } from "../../locale";
import {
  Actions,
  Accesses,
  BandwidthDistributions,
  DefaultValues,
  VpnInterfaces,
  MetadataKeys,
  QualityOfServices,
} from "../../constants";
import generateShortId from "../../helpers/generateShortId";
import defaultChangeableValue from "../defaultChangeableValue.js";

const DEFAULT_MAX_NR_OF_VPNS = 4;
const DEFAULT_BANDWIDTH_DISTRIBUTION = BandwidthDistributions.DYNAMIC;
const DEFAULT_VPN_INTERFACE = VpnInterfaces.LOGICAL;

function defaultVpnSettingsObject() {
  return {
    bandwidthDistribution: {
      current: null,
      new: DEFAULT_BANDWIDTH_DISTRIBUTION,
    },
    rtTot: defaultChangeableValue(),
  };
}

function defaultVpnObject(vpnKey = null) {
  return {
    action: Actions.ADD,
    isPrimary: false,
    isExisting: false,
    isIncludedInBackup: false,
    accessKey: "",
    key: vpnKey,
    siblingVpnKey: null,
    id: "",
    alias: "",
    lanInterface: null,
    topology: "",
    hubAddress: "",
    bandwidth: defaultChangeableValue(),
    qos: defaultChangeableValue(),
    rt1: { vpnKey, ...defaultChangeableValue() },
    rt2: { vpnKey, ...defaultChangeableValue() },
    lans: [],
    dhcpRelaysAction: Actions.NONE,
    dhcpServers: [],
    staticRoutes: [],
  };
}

function defaultState() {
  return {
    multiVpn: false,
    vpnInterface: DEFAULT_VPN_INTERFACE,
    lanInterfaces: {
      value: DefaultValues.NONE,
    },
    primary: defaultVpnSettingsObject(),
    secondary: defaultVpnSettingsObject(),
    vpns: {},
  };
}

const state = Object.assign({}, defaultState());

const getters = {
  hasMultipleVpns: (state, getters) => {
    const vpns = getters.getPrimaryVpns;
    const nrOfPrimaryVpnsToAddOrUpdate = vpns.filter((vpn) => vpn.action !== Actions.DELETE).length;
    return nrOfPrimaryVpnsToAddOrUpdate > 1;
  },

  shouldDeleteVpn: (state, getters) => (vpnKey) => {
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return null;
    return vpn.action === Actions.DELETE;
  },

  isPrimaryAccessVpn: (state, getters) => (vpnKey) => {
    const vpn = getters.getVpn(vpnKey);
    return vpn ? vpn.accessKey === Accesses.PRIMARY : false;
  },

  isSecondaryAccessVpn: (state, getters) => (vpnKey) => {
    const vpn = getters.getVpn(vpnKey);
    return vpn ? vpn.accessKey === Accesses.SECONDARY : false;
  },

  isExistingVpn: (state, getters) => (vpnKey) => {
    const vpn = getters.getVpn(vpnKey);
    return vpn ? vpn.isExisting : false;
  },

  isVpnsEditable: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isEditable"](MetadataKeys.VPN),

  isVpnActionEditable: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isActionEditable"](MetadataKeys.VPN),

  isBandwidthDistributionEditable: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isEditable"](MetadataKeys.BANDWIDTH_DISTRIBUTION),

  isSecondaryBandwidthDistributionEditable: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isEditable"](MetadataKeys.SECONDARY_ACCESS_BANDWIDTH_DISTRIBUTION) &&
    !rootGetters["access/hasBackupTypeRedundancy"] &&
    !rootGetters["access/hasBackupTypeDiversity"],

  isVpnInterfaceEditable: (state, getters) =>
    getters.shouldShowVpnInterface && getters.isVpnsEditable,

  isRtTotEditable: (state, getters, rootState, rootGetters) =>
    getters.shouldShowRtTot(Accesses.PRIMARY) &&
    rootGetters["metadata/isEditable"](MetadataKeys.ACCESS_RT_TOT),

  isSecondaryRtTotEditable: (state, getters, rootState, rootGetters) =>
    getters.shouldShowRtTot(Accesses.SECONDARY) &&
    rootGetters["metadata/isEditable"](MetadataKeys.SECONDARY_ACCESS_RT_TOT),

  isVpnIdEditable: (state, getters) => (vpnKey) =>
    getters.isVpnsEditable && getters.isPrimaryAccessVpn(vpnKey) && !getters.isExistingVpn(vpnKey),

  isVpnBandwidthEditable: (state, getters) => getters.isVpnsEditable,

  isVpnQosEditable: (state, getters) => (vpnKey) =>
    getters.isVpnBandwidthEditable && getters.getVpn(vpnKey).bandwidth.new,

  isVpnRt1Editable: (state, getters) => (vpnKey) =>
    getters.isVpnQosEditable(vpnKey) && getters.getVpn(vpnKey).qos.new,

  isVpnRt2Editable: (state, getters) => (vpnKey) => getters.isVpnQosEditable(vpnKey),

  shouldShowVpns: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.VPN),

  shouldShowBandwidthDistribution: (state, getters) => getters.shouldShowVpns && state.multiVpn,

  shouldShowVpnInterface: (state, getters) => (accessKey) =>
    getters.shouldShowVpns && state.multiVpn && accessKey === Accesses.PRIMARY,

  shouldShowRtTot: (state, getters, rootState, rootGetters) => (accessKey) =>
    getters.isDynamicBandwidthDistribution(accessKey) &&
    rootGetters["metadata/isVisible"](MetadataKeys.ACCESS_RT_TOT) &&
    !rootGetters["products/isWirelessBearer"](accessKey) &&
    ![QualityOfServices.BASIC, QualityOfServices.NONE].includes(
      rootGetters["access/getQos"](accessKey).new
    ),

  shouldShowRt2: (state, getters, rootState, rootGetters) => (vpnKey) => {
    const vpn = getters.getVpn(vpnKey);
    const access = rootGetters["access/getAccess"](vpn.accessKey);
    const bearer = rootGetters["products/getBearer"](vpn.accessKey);

    return rootGetters["products/getShowRt2ForVpnBandwidthAndVpnQos"](
      bearer,
      access.bandwidth.new,
      access.qos.new,
      vpn.bandwidth.new,
      vpn.qos.new
    );
  },

  shouldShowVpnSettings: (state, getters, rootState, rootGetters) => (vpnKey) => {
    const vpn = getters.getVpn(vpnKey);
    return (
      !rootGetters["products/isWirelessBearer"](vpn.accessKey) &&
      getters.isStaticBandwidthDistribution(vpn.accessKey)
    );
  },

  shouldShowRtBandwidth: (state, getters, rootState, rootGetters) => (vpnKey) =>
    rootGetters["products/getVpnRt1Options"](vpnKey).length > 1,

  shouldShowDeleteExistingVpn: (state, getters, rootState, rootGetters) => (vpnKey) => {
    return (
      rootGetters.isChangeOrder &&
      getters.getVpn(vpnKey).isExisting &&
      !getters.getVpn(vpnKey).isPrimary
    );
  },

  shouldValidateVpnSettings: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isEditable"](MetadataKeys.VPN),

  shouldDisableVpnSettings: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.VPN),

  hasVpns: (state) => Object.keys(state.vpns).length > 0,

  getVpnSettings: (state) => (accessKey) => state[accessKey],

  getVpn: (state) => (vpnKey) => state.vpns[vpnKey] || null,

  getAction: (state) => (vpnKey = DefaultValues.NONE) => state.vpns[vpnKey].action || "",

  getAllVpns: (state) => Object.values(state.vpns),

  getPrimaryVpns: (state, getters) =>
    getters.getAllVpns.filter((vpn) => vpn.accessKey === Accesses.PRIMARY),

  getVpnsByAccess: ({ vpns }) => (accessKey) => {
    if (!accessKey || !vpns || !Object.values(Accesses).includes(accessKey)) return undefined;
    return Object.values(vpns)
      .filter((vpn) => vpn.accessKey === accessKey)
      .sort((firstVpn, secondVpn) =>
        firstVpn.isExisting === secondVpn.isExisting ? 0 : firstVpn.isExisting ? -1 : 1
      );
  },

  getVpnIdAndAlias: (state, getters) => (vpnKey) => {
    const vpn = getters.getVpn(vpnKey);
    return `${vpn.id}${vpn.alias ? " - " + vpn.alias : ""}`;
  },

  hasLessVpnsThanMax: (state, { nrOfVpns, maxNrOfVpns }) => {
    if (nrOfVpns == null || maxNrOfVpns == null) return undefined;
    return nrOfVpns < maxNrOfVpns;
  },

  hasMultipleExistingVpns: (state, getters) =>
    getters.getVpnsByAccess(Accesses.PRIMARY).filter((vpn) => vpn.isExisting).length > 1,

  nrOfVpns: (state, { getVpnsByAccess }) => {
    const allPrimaryVpns = getVpnsByAccess(Accesses.PRIMARY);
    if (!allPrimaryVpns) return undefined;

    return allPrimaryVpns.filter((vpn) => vpn.action !== Actions.DELETE).length;
  },

  nrOfIncludedSecondaryVpns: (state, { getVpnsByAccess }) => {
    const allSecondaryVpns = getVpnsByAccess(Accesses.SECONDARY);
    if (!allSecondaryVpns) return undefined;

    return allSecondaryVpns.filter((vpn) => vpn.action !== Actions.DELETE && vpn.isIncludedInBackup)
      .length;
  },

  maxNrOfVpns: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/getMax"](MetadataKeys.VPN) || DEFAULT_MAX_NR_OF_VPNS,

  getTotalDistributedBandwidth: (state) => (accessKey) => {
    const vpns = Object.values(state.vpns).filter((vpn) => vpn.accessKey === accessKey);
    const totalUsedBandwidth = vpns.reduce(
      (total, vpn) => total + Number(vpn.bandwidth.new || vpn.bandwidth.current),
      0
    );
    return totalUsedBandwidth;
  },

  hasLessOrEqualNrOfVpnsThanMax: (state, { nrOfVpns, maxNrOfVpns }) => {
    if (nrOfVpns == null || maxNrOfVpns == null) return undefined;
    return nrOfVpns <= maxNrOfVpns;
  },

  hasIncludedBackupVpns: (state, { nrOfIncludedSecondaryVpns }) => {
    if (nrOfIncludedSecondaryVpns == null) return undefined;
    return nrOfIncludedSecondaryVpns > 0;
  },

  canAddVpn: (state, getters, rootState, rootGetters) => (accessKey) => {
    const nrOfCurrentPrimaryVpns = getters.nrOfVpns;
    const nrOfAvailableVpnIds =
      rootGetters["user/getAvailableVpnIdsForSelectedOrganisation"].length;
    if (nrOfCurrentPrimaryVpns >= nrOfAvailableVpnIds) return false;
    if (accessKey === Accesses.SECONDARY) return false;
    if (!getters.hasLessVpnsThanMax) return false;
    if (!state.multiVpn) return false;
    if (!rootGetters["metadata/isEditable"](MetadataKeys.VPN)) return false;
    return true;
  },

  canDeleteVpn: (state, getters, rootState, rootGetters) => (index, vpnKey) => {
    const { isExisting, accessKey } = getters.getVpn(vpnKey);
    if (isExisting) return false;
    // Should be replaced with isPrimary
    if (index === 0) return false;
    if (accessKey === Accesses.SECONDARY) return false;
    if (!rootGetters["metadata/isEditable"](MetadataKeys.VPN)) return false;
    return true;
  },

  getMultiVpnOptions: (state, getters, rootState, rootGetters) => {
    return [
      {
        label: t("DATANET_YES"),
        value: true,
        disabled: getters.maxNrOfVpns <= 1,
      },
      {
        label: t("DATANET_NO"),
        value: false,
        disabled:
          getters.hasMultipleExistingVpns ||
          (rootGetters.isFunctionBusinessOrder && getters.maxNrOfVpns === 8 && state.multiVpn),
      },
    ];
  },

  getBandwidthDistributionOptions: (state, getters, rootState, rootGetters) => (accessKey) => {
    const currentBandwidthDistribution = getters.getVpnSettings(accessKey).bandwidthDistribution
      .new;

    const isWirelessAccess =
      accessKey === Accesses.PRIMARY
        ? rootGetters["products/isWirelessAccess"]
        : rootGetters["products/isWirelessBackupAccess"];

    const selectedBearer = rootGetters["products/getBearer"](accessKey);
    const selectedBandwidth = rootGetters["access/getAccess"](accessKey).bandwidth.new;
    const isInternetOnSiteActive =
      rootGetters["access/getAccess"](accessKey).options.internetOnSite?.action === Actions.ADD ||
      rootGetters["access/getAccess"](accessKey).options.internetOnSite?.action === Actions.UPDATE;
    const isDynamicBandwidthAllowed = rootGetters[
      "products/getDynamicAllowedForAccessAndBandwidth"
    ](selectedBearer, selectedBandwidth);
    const isBandwidthDistributionEditable =
      accessKey === Accesses.PRIMARY
        ? getters.isBandwidthDistributionEditable
        : getters.isSecondaryBandwidthDistributionEditable;

    const shouldDisableDynamicBandwidthDistribution =
      (!isBandwidthDistributionEditable || !isDynamicBandwidthAllowed) &&
      currentBandwidthDistribution !== BandwidthDistributions.DYNAMIC;
    const shouldDisableStaticBandwidthDistribution =
      (!isBandwidthDistributionEditable || isWirelessAccess || isInternetOnSiteActive) &&
      currentBandwidthDistribution !== BandwidthDistributions.STATIC;

    return [
      {
        value: BandwidthDistributions.DYNAMIC,
        disabled: shouldDisableDynamicBandwidthDistribution,
      },
      {
        value: BandwidthDistributions.STATIC,
        disabled: shouldDisableStaticBandwidthDistribution,
      },
    ];
  },

  getVpnInterfaceOptions: (state, getters) => {
    const currentVpnInterface = state.vpnInterface;
    return [
      {
        value: VpnInterfaces.PHYSICAL,
        label: t(`DATANET_VPN_INTERFACE_${VpnInterfaces.PHYSICAL}`),
        disabled: !getters.isVpnInterfaceEditable && currentVpnInterface !== VpnInterfaces.PHYSICAL,
      },
      {
        value: VpnInterfaces.LOGICAL,
        label: t(`DATANET_VPN_INTERFACE_${VpnInterfaces.LOGICAL}`),
        disabled: !getters.isVpnInterfaceEditable && currentVpnInterface !== VpnInterfaces.LOGICAL,
      },
    ];
  },

  getTotalDistributedRtBandwidth: (state) => (accessKey) => {
    return Object.values(state.vpns)
      .filter((vpn) => vpn.accessKey === accessKey)
      .reduce(
        (total, vpn) =>
          total +
          Math.max(Number(vpn.rt1.new || vpn.rt1.current), 0) +
          Math.max(Number(vpn.rt2.new || vpn.rt2.current), 0),
        0
      );
  },

  getLanInterfaces: (state) => state.lanInterfaces,

  isStaticBandwidthDistribution: (state) => (accessKey = Accesses.PRIMARY) =>
    state[accessKey].bandwidthDistribution.new === BandwidthDistributions.STATIC,

  isCurrentDynamicBandwidthDistribution: (state) => (accessKey = Accesses.PRIMARY) =>
    state[accessKey].bandwidthDistribution.current === BandwidthDistributions.DYNAMIC,

  isDynamicBandwidthDistribution: (state) => (accessKey = Accesses.PRIMARY) =>
    state[accessKey].bandwidthDistribution.new === BandwidthDistributions.DYNAMIC,

  isPhysicalInterface: (state) => state.vpnInterface === VpnInterfaces.PHYSICAL,

  isLogicalInterface: (state) => state.vpnInterface === VpnInterfaces.LOGICAL,

  getSelectedVpnIds: (state) => {
    return Object.values(state.vpns)
      .filter((vpn) => vpn.id)
      .map((vpn) => vpn.id);
  },

  isIncludedInBackup: (state) => (vpnKey = DefaultValues.NONE) => {
    const vpn = state.vpns[vpnKey];
    return vpn ? vpn.isIncludedInBackup : false;
  },

  getVpnId: (state) => (vpnKey = DefaultValues.NONE) => {
    const vpn = state.vpns[vpnKey];
    return vpn ? vpn.id : "";
  },

  getLanInterface: (state, getters) => (vpnKey) => {
    const vpn = getters.getVpn(vpnKey);
    return vpn ? vpn.lanInterface : DefaultValues.NONE;
  },

  getQos: (state, getters) => (vpnKey) => {
    const vpn = getters.getVpn(vpnKey);
    return vpn ? vpn.qos.new : "";
  },

  getRt1: (state, getters) => (vpnKey) => {
    const vpn = getters.getVpn(vpnKey);
    return vpn ? vpn.rt1.new : DefaultValues.NONE;
  },

  getRt2: (state, getters) => (vpnKey) => {
    const vpn = getters.getVpn(vpnKey);
    return vpn ? vpn.rt2.new : DefaultValues.NONE;
  },
};

const actions = {
  async addVpn(
    { commit, dispatch, state, rootGetters },
    { key, siblingKey, addDefaultLan = false } = {}
  ) {
    const vpnKey = key == null ? generateShortId() : key;
    commit("ADD_VPN", { vpnKey, accessKey: Accesses.PRIMARY });
    dispatch("setUnsavedDraft", undefined, { root: true });
    dispatch("access/addVpn", { accessKey: Accesses.PRIMARY, vpnKey }, { root: true });

    siblingKey = siblingKey == null ? generateShortId() : siblingKey;

    commit("ADD_VPN", {
      vpnKey: siblingKey,
      accessKey: Accesses.SECONDARY,
      siblingVpnKey: vpnKey,
    });
    commit("SET_SIBLING_VPN_KEY", { vpnKey, siblingVpnKey: siblingKey });

    if ((rootGetters.isNewOrder && rootGetters.isUnsavedOrder) || addDefaultLan) {
      // Add default LAN
      dispatch("lans/addLan", { vpnKey }, { root: true });
    }

    if (rootGetters.isNewOrder && Object.keys(state.vpns).length === 2) {
      commit("SET_IS_PRIMARY", { vpnKey, isPrimary: true });
      commit("SET_IS_PRIMARY", { vpnKey: siblingKey, isPrimary: true });
    }

    // VPNs added are automatically included in backup if redundancy or diversity
    if (
      rootGetters["access/hasBackupTypeRedundancy"] ||
      rootGetters["access/hasBackupTypeDiversity"]
    ) {
      commit("SET_IS_INCLUDED_IN_BACKUP", { vpnKey, isIncludedInBackup: true });
      commit("SET_IS_INCLUDED_IN_BACKUP", { vpnKey: siblingKey, isIncludedInBackup: true });
    }

    return vpnKey;
  },

  deleteVpn({ state, getters, commit, dispatch }, vpnKey) {
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return;

    const { accessKey, lans, dhcpServers, staticRoutes, siblingVpnKey } = vpn;

    if (dhcpServers.length > 0) {
      for (let i = dhcpServers.length - 1; i >= 0; i--) {
        dispatch("dhcpServer/deleteServer", dhcpServers[i], { root: true });
      }
    }

    if (staticRoutes.length > 0) {
      for (let i = staticRoutes.length - 1; i >= 0; i--) {
        dispatch("staticRoutes/deleteRoute", staticRoutes[i], { root: true });
      }
    }

    if (lans.length > 0) {
      for (let i = lans.length - 1; i >= 0; i--) {
        dispatch("lans/deleteLan", lans[i], { root: true });
      }
    }

    dispatch("access/removeVpn", { accessKey, vpnKey }, { root: true });

    commit("DELETE_VPN", vpnKey);

    const hasSiblingVpn = !!state.vpns[siblingVpnKey];
    if (hasSiblingVpn) {
      dispatch("deleteVpn", siblingVpnKey);
    }

    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  resetVpnAddOns({ state, dispatch, rootState }, vpnKey = DefaultValues.NONE) {
    if (vpnKey === DefaultValues.NONE) {
      return;
    }

    const vpn = state.vpns[vpnKey];
    if (!vpn) {
      return;
    }

    vpn.lans.forEach((lanKey) => {
      const lan = rootState.lan.lans[lanKey] || null;

      if (lan && !lan.isDefault) {
        dispatch("lans/deleteLan", lanKey, { root: true });
      }
    });
    vpn.dhcpServers.forEach((serverKey) =>
      dispatch("dhcpServer/deleteServer", serverKey, { root: true })
    );
    vpn.staticRoutes.forEach((routeKey) =>
      dispatch("staticRoutes/deleteRoute", routeKey, { root: true })
    );

    dispatch("setUnsavedDraft", true, { root: true });
  },

  addLan({ getters, dispatch, commit }, { vpnKey, lanKey }) {
    if (lanKey == null) return;
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return;

    if (!vpn.action || vpn.action === Actions.NONE) {
      commit("SET_ACTION", { vpnKey, action: Actions.UPDATE });
    }

    commit("ADD_LAN", { vpnKey, lanKey });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  removeLan({ dispatch, commit }, { vpnKey, lanKey }) {
    if (vpnKey === DefaultValues.NONE || lanKey === DefaultValues.NONE) {
      return;
    }

    const vpn = state.vpns[vpnKey];
    if (!vpn) {
      return;
    }

    const index = vpn.lans.indexOf(lanKey);
    if (index === DefaultValues.NONE) {
      return;
    }

    commit("REMOVE_LAN", { vpnKey, lanKey });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  addDhcpServer({ getters, dispatch, commit }, { vpnKey, dhcpServerKey }) {
    if (dhcpServerKey == null) return;
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return;

    if (!vpn.action || vpn.action === Actions.NONE) {
      commit("SET_ACTION", { vpnKey, action: Actions.UPDATE });
    }

    commit("ADD_DHCP_SERVER", { vpnKey, dhcpServerKey });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  removeDhcpServer({ commit, dispatch }, { vpnKey, dhcpServerKey }) {
    commit("REMOVE_DHCP_SERVER", { vpnKey, dhcpServerKey });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  addStaticRoute({ getters, dispatch, commit }, { vpnKey, staticRouteKey }) {
    if (staticRouteKey == null) return;
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return;

    if (!vpn.action || vpn.action === Actions.NONE) {
      commit("SET_ACTION", { vpnKey, action: Actions.UPDATE });
    }

    commit("ADD_STATIC_ROUTE", { vpnKey, staticRouteKey });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  removeStaticRoute({ commit, dispatch }, { vpnKey, staticRouteKey }) {
    if (vpnKey === DefaultValues.NONE || staticRouteKey === DefaultValues.NONE) {
      return;
    }

    const vpn = state.vpns[vpnKey];
    if (!vpn) {
      return;
    }

    const index = vpn.staticRoutes.indexOf(staticRouteKey);
    if (index === DefaultValues.NONE) {
      return;
    }

    commit("REMOVE_STATIC_ROUTE", { vpnKey, staticRouteKey });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setSiblingVpnKey({ dispatch, commit }, { vpnKey = -1, siblingVpnKey = -1 }) {
    commit("SET_SIBLING_VPN_KEY", { vpnKey, siblingVpnKey });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setMultiVpn({ state, getters, rootState, rootGetters, dispatch, commit }, boolean) {
    if (typeof boolean !== "boolean") return;

    if (boolean) {
      const primaryBearer = rootGetters["products/getBearer"](Accesses.PRIMARY);
      const primaryBandwidth = rootState.access[Accesses.PRIMARY].bandwidth;
      const isPrimaryDynamicBandwidthDistributionAllowed = rootGetters[
        "products/getDynamicAllowedForAccessAndBandwidth"
      ](primaryBearer, primaryBandwidth.new);

      if (getters.nrOfVpns > 1 || isPrimaryDynamicBandwidthDistributionAllowed) {
        dispatch("setBandwidthDistribution", {
          accessKey: Accesses.PRIMARY,
          new: BandwidthDistributions.DYNAMIC,
        });
      } else {
        dispatch("setBandwidthDistribution", {
          accessKey: Accesses.PRIMARY,
          new: BandwidthDistributions.STATIC,
        });
      }

      const hasSecondaryAccessEnabled = rootGetters["access/hasBackupAccess"];
      if (hasSecondaryAccessEnabled) {
        const secondaryBearer = rootGetters["products/getBearer"](Accesses.SECONDARY);
        const secondaryBandwidth = rootState.access[Accesses.SECONDARY].bandwidth;
        const isSecondaryDynamicBandwidthDistributionAllowed = rootGetters[
          "products/getDynamicAllowedForAccessAndBandwidth"
        ](secondaryBearer, secondaryBandwidth.new);

        if (getters.nrOfVpns > 1 || isSecondaryDynamicBandwidthDistributionAllowed) {
          dispatch("setBandwidthDistribution", {
            accessKey: Accesses.SECONDARY,
            new: BandwidthDistributions.DYNAMIC,
          });
        } else {
          dispatch("setBandwidthDistribution", {
            accessKey: Accesses.SECONDARY,
            new: BandwidthDistributions.STATIC,
          });
        }
      }

      commit("SET_MULTI_VPN", true);
      dispatch("setUnsavedDraft", undefined, { root: true });
      return;
    }

    const vpns = Object.values(state.vpns);
    const secondaryVpns = vpns.filter((vpn) => !vpn.isPrimary);
    for (let i = secondaryVpns.length - 1; i >= 0; i--) {
      const vpn = secondaryVpns[i];
      if (vpn.lans.length !== 0) {
        for (let j = vpn.lans.length - 1; j >= 0; j--) {
          const lan = vpn.lans[j];
          dispatch("lans/deleteLan", lan.key, { root: true });
        }
      }
      dispatch("deleteVpn", vpn.key);
    }

    dispatch("setBandwidthDistribution", {
      accessKey: Accesses.PRIMARY,
      new: DEFAULT_BANDWIDTH_DISTRIBUTION,
    });
    dispatch("setBandwidthDistribution", {
      accessKey: Accesses.SECONDARY,
      new: DEFAULT_BANDWIDTH_DISTRIBUTION,
    });
    dispatch("setVpnInterface", DEFAULT_VPN_INTERFACE);
    commit("SET_MULTI_VPN", false);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setBandwidthDistribution({ getters, rootGetters, dispatch, commit }, { accessKey, new: _new }) {
    const vpnSettings = getters.getVpnSettings(accessKey);
    if (!vpnSettings) return;
    if (vpnSettings.bandwidthDistribution.new === _new) return;
    if (!Object.values(BandwidthDistributions).includes(_new)) return;

    if (_new === BandwidthDistributions.DYNAMIC) {
      dispatch("resetVpnsForAccess", accessKey);
    } else if (_new === BandwidthDistributions.STATIC) {
      const vpns = getters.getVpnsByAccess(accessKey);

      if (!rootGetters.isFunctionBusinessOrder) {
        // RT Tot is never editable for FB orders. Skip resetting RT tot.
        dispatch("setRtTot", {
          accessKey,
          new: "",
        });
      }

      vpns.forEach((vpn) => {
        dispatch("setBandwidth", {
          vpnKey: vpn.key,
          new: vpn.bandwidth.current,
        });
        dispatch("setQos", {
          vpnKey: vpn.key,
          new: vpn.qos.current,
        });
        dispatch("setRt1", {
          vpnKey: vpn.key,
          new: vpn.rt1.current,
        });
        dispatch("setRt2", {
          vpnKey: vpn.key,
          new: vpn.rt2.current,
        });
      });
    }

    commit("SET_BANDWIDTH_DISTRIBUTION", { accessKey, bandwidthDistribution: { new: _new } });
    dispatch("setUnsavedDraft", undefined, { root: true });

    // If backup type is redundancy or diversity, also set secondary bandwidth distribution to mimic primary access
    if (
      accessKey === Accesses.PRIMARY &&
      (rootGetters["access/hasBackupTypeRedundancy"] ||
        rootGetters["access/hasBackupTypeDiversity"])
    ) {
      commit("SET_BANDWIDTH_DISTRIBUTION", {
        accessKey: Accesses.SECONDARY,
        bandwidthDistribution: { new: _new },
      });
    }
  },

  setVpnInterface({ state, dispatch, commit }, vpnInterface) {
    if (state.vpnInterface === vpnInterface) return;
    if (!Object.values(VpnInterfaces).includes(vpnInterface)) return;

    if (vpnInterface === VpnInterfaces.PHYSICAL) {
      // Don't remove first LAN if it's a NEW order
      const index = getters.isChangeOrder ? 0 : 1;
      Object.values(state.vpns).forEach((vpn) => {
        for (let i = vpn.lans.length - 1; i >= index; i--) {
          dispatch("lans/deleteLan", vpn.lans[i], { root: true });
        }
      });
      dispatch("lans/resetAllVlanIds", undefined, { root: true });
    }

    commit("SET_VPN_INTERFACE", vpnInterface);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setLanInterfaces({ dispatch, commit }, lanInterfaces = {}) {
    commit("SET_LAN_INTERFACES", lanInterfaces);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setActionNone({ getters, dispatch, commit }, vpnKey = DefaultValues.NONE) {
    if (vpnKey === DefaultValues.NONE) {
      return;
    }

    commit("SET_ACTION_NONE", vpnKey);

    const { siblingVpnKey } = getters.getVpn(vpnKey);

    commit("SET_ACTION_NONE", siblingVpnKey);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setActionAdd({ dispatch, commit }, vpnKey = DefaultValues.NONE) {
    if (vpnKey === DefaultValues.NONE) {
      return;
    }
    commit("SET_ACTION_ADD", vpnKey);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setActionDelete({ getters, dispatch, commit }, { vpnKey, resetSibling = true }) {
    if (vpnKey === DefaultValues.NONE) {
      return;
    }

    const { accessKey, lans, dhcpServers, staticRoutes, siblingVpnKey } = getters.getVpn(vpnKey);
    dispatch("resetVpnsForAccess", accessKey);

    if (dhcpServers.length > 0) {
      for (let i = dhcpServers.length - 1; i >= 0; i--) {
        dispatch("dhcpServer/deleteServer", dhcpServers[i], { root: true });
      }
    }

    if (staticRoutes.length > 0) {
      for (let i = staticRoutes.length - 1; i >= 0; i--) {
        dispatch("staticRoutes/deleteRoute", staticRoutes[i], { root: true });
      }
    }

    if (lans.length > 0) {
      for (let i = lans.length - 1; i >= 0; i--) {
        dispatch("lans/deleteLan", lans[i], { root: true });
      }
    }

    commit("SET_ACTION_DELETE", vpnKey);

    if (resetSibling) {
      dispatch("setActionDelete", { vpnKey: siblingVpnKey, resetSibling: false });
    }

    commit("SET_ACTION_DELETE", siblingVpnKey);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setIsPrimary({ getters, commit }, { vpnKey, isPrimary }) {
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return;
    if (vpn.isPrimary === isPrimary) return;

    commit("SET_IS_PRIMARY", { vpnKey, isPrimary });
    commit("SET_IS_PRIMARY", { vpnKey: vpn.siblingVpnKey, isPrimary });
  },

  setIsExisting({ getters, commit }, { vpnKey, isExisting }) {
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return;
    if (vpn.isExisting === isExisting) return;

    commit("SET_IS_EXISTING", { vpnKey, isExisting });
    commit("SET_IS_EXISTING", { vpnKey: vpn.siblingVpnKey, isExisting });
  },

  setIsIncludedInBackup({ getters, commit }, { vpnKey, isIncludedInBackup }) {
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return;
    if (vpn.isIncludedInBackup === isIncludedInBackup) return;

    commit("SET_IS_INCLUDED_IN_BACKUP", { vpnKey, isIncludedInBackup });
    commit("SET_IS_INCLUDED_IN_BACKUP", { vpnKey: vpn.siblingVpnKey, isIncludedInBackup });
  },

  setVpnId({ getters, dispatch, commit }, { vpnKey, vpnId }) {
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return;
    if (!vpn.id === vpnId) return;

    commit("SET_ID", { vpnKey, vpnId });
    commit("SET_ID", { vpnKey: vpn.siblingVpnKey, vpnId });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setAlias({ getters, dispatch, commit }, { vpnKey, alias }) {
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return;
    if (vpn.alias === alias) return;

    commit("SET_ALIAS", { vpnKey, alias });
    commit("SET_ALIAS", { vpnKey: vpn.siblingVpnKey, alias });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setLanInterface(
    { dispatch, commit },
    { vpnKey = DefaultValues.NONE, lanInterface = DefaultValues.NONE }
  ) {
    if (vpnKey === DefaultValues.NONE) {
      return;
    }

    commit("SET_LAN_INTERFACE", { vpnKey, lanInterface });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setBandwidth({ getters, dispatch, commit }, { vpnKey, new: _new }) {
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return;
    if (vpn.bandwidth.new === _new) return;

    if (!vpn.action || vpn.action === Actions.NONE) {
      commit("SET_ACTION", { vpnKey, action: Actions.UPDATE });
    }

    commit("SET_BANDWIDTH", { vpnKey, bandwidth: { new: _new } });
    dispatch("setUnsavedDraft", undefined, { root: true });
    dispatch("setQos", { vpnKey, new: "" });
    dispatch("setRt1", { vpnKey, new: "" });
    dispatch("setRt2", { vpnKey, new: "" });
  },

  setQos({ getters, dispatch, commit }, { vpnKey, new: _new }) {
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return;
    if (vpn.qos.new === _new) return;

    if (!vpn.action || vpn.action === Actions.NONE) {
      commit("SET_ACTION", { vpnKey, action: Actions.UPDATE });
    }

    commit("SET_QOS", { vpnKey, qos: { new: _new } });
    dispatch("setUnsavedDraft", undefined, { root: true });
    dispatch("setRt1", { vpnKey, new: "" });
    dispatch("setRt2", { vpnKey, new: "" });
  },

  setRt1({ getters, dispatch, commit, rootGetters }, { vpnKey, new: _new }) {
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return;
    if (vpn.rt1.new === _new) return;

    commit("SET_RT1", { vpnKey, rt1: { new: _new } });
    dispatch("setUnsavedDraft", undefined, { root: true });

    if (!rootGetters.isFunctionBusinessOrder) {
      // RT Tot is never editable for FB orders. Skip updating RT tot based on RT 1 selection.
      const totalDistributedRtBandwidth = getters.getTotalDistributedRtBandwidth(vpn.accessKey);
      dispatch("setRtTot", { accessKey: vpn.accessKey, new: totalDistributedRtBandwidth });
    }
  },

  setRt2({ getters, dispatch, commit, rootGetters }, { vpnKey, new: _new }) {
    const vpn = getters.getVpn(vpnKey);
    if (!vpn) return;
    if (vpn.rt2.new === _new) return;

    commit("SET_RT2", { vpnKey, rt2: { new: _new } });
    dispatch("setUnsavedDraft", undefined, { root: true });

    if (!rootGetters.isFunctionBusinessOrder) {
      // RT Tot is never editable for FB orders. Skip updating RT tot based on RT 2 selection.
      const totalDistributedRtBandwidth = getters.getTotalDistributedRtBandwidth(vpn.accessKey);
      dispatch("setRtTot", { accessKey: vpn.accessKey, new: totalDistributedRtBandwidth });
    }
  },

  setRtTot({ getters, dispatch, commit }, { accessKey, new: _new }) {
    const vpnSettings = getters.getVpnSettings(accessKey);
    if (!vpnSettings) return;
    if (vpnSettings.rtTot.new === _new) return;

    commit("SET_RT_TOT", { accessKey, rtTot: { new: _new } });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  resetAllVpns({ dispatch }) {
    dispatch("resetVpnsForAccess", Accesses.PRIMARY);
    dispatch("resetVpnsForAccess", Accesses.SECONDARY);
  },

  resetVpnsForAccess({ state, getters, dispatch, rootGetters }, accessKey) {
    const accessQos = rootGetters["access/getAccess"](accessKey).qos.new;
    const defaultQosList = [QualityOfServices.NONE, QualityOfServices.BASIC];

    if (!rootGetters.isFunctionBusinessOrder) {
      // RT Tot is never editable for FB orders. Skip resetting RT tot.
      const rtTot = getters.getVpnSettings(accessKey).rtTot;
      dispatch("setRtTot", {
        accessKey,
        new: defaultQosList.includes(accessQos) ? 0 : rtTot.current,
      });
    }

    Object.values(state.vpns)
      .filter((vpn) => vpn.accessKey === accessKey)
      .forEach((vpn) => {
        const { bandwidth, qos, rt1, rt2 } = vpn;
        dispatch("setBandwidth", {
          vpnKey: vpn.key,
          new: bandwidth.current,
        });
        dispatch("setQos", {
          vpnKey: vpn.key,
          new: qos.current,
        });
        dispatch("setRt1", {
          vpnKey: vpn.key,
          new: rt1.current,
        });
        dispatch("setRt2", {
          vpnKey: vpn.key,
          new: rt2.current,
        });
      });
  },

  /* NOT USED IN INCREMENT 1
    setTopology({ dispatch, commit }, { vpnId, value }) {
        commit('SET_TOPOLOGY', { vpnId, value });
        dispatch('setUnsavedDraft', undefined, { root: true });
    },

    setHubAddress({ dispatch, commit }, { vpnId, value }) {
        commit('SET_HUB_ADDRESS', { vpnId, value });
        dispatch('setUnsavedDraft', undefined, { root: true });
    },
    */
};

const mutations = {
  ADD_VPN(state, { vpnKey, accessKey, siblingVpnKey }) {
    const newVpn = Object.assign({}, defaultVpnObject(vpnKey), {
      key: vpnKey,
      accessKey,
      siblingVpnKey,
    });
    Vue.set(state.vpns, vpnKey, newVpn);
  },

  DELETE_VPN(state, vpnKey) {
    Vue.delete(state.vpns, vpnKey);
  },

  ADD_LAN(state, { vpnKey, lanKey }) {
    const vpn = state.vpns[vpnKey];

    if (vpn) {
      vpn.lans.push(lanKey);
    }
  },

  REMOVE_LAN(state, { vpnKey, lanKey }) {
    const vpn = state.vpns[vpnKey];
    const index = vpn.lans.indexOf(lanKey);
    vpn.lans.splice(index, 1);
  },

  ADD_DHCP_SERVER(state, { vpnKey, dhcpServerKey }) {
    const vpn = state.vpns[vpnKey];

    if (vpn) {
      vpn.dhcpServers.push(dhcpServerKey);
    }
  },

  REMOVE_DHCP_SERVER(state, { vpnKey, dhcpServerKey }) {
    const vpn = state.vpns[vpnKey];
    const index = vpn.dhcpServers.indexOf(dhcpServerKey);
    vpn.dhcpServers.splice(index, 1);
  },

  ADD_STATIC_ROUTE(state, { vpnKey, staticRouteKey }) {
    const vpn = state.vpns[vpnKey];

    if (vpn) {
      vpn.staticRoutes.push(staticRouteKey);
    }
  },

  REMOVE_STATIC_ROUTE(state, { vpnKey, staticRouteKey }) {
    const vpn = state.vpns[vpnKey];
    const index = vpn.staticRoutes.indexOf(staticRouteKey);
    vpn.staticRoutes.splice(index, 1);
  },

  SET_VPN_KEY(state, { vpnKey }) {
    state.vpns[vpnKey].key = vpnKey;
  },

  SET_SIBLING_VPN_KEY(state, { vpnKey, siblingVpnKey }) {
    state.vpns[vpnKey].siblingVpnKey = siblingVpnKey;
  },

  SET_MULTI_VPN(state, boolean) {
    state.multiVpn = boolean;
  },

  SET_BANDWIDTH_DISTRIBUTION(state, { accessKey, bandwidthDistribution }) {
    const currentBandwidthDistribution = state[accessKey].bandwidthDistribution;
    for (const key in bandwidthDistribution) {
      currentBandwidthDistribution[key] = bandwidthDistribution[key];
    }
  },

  SET_RT_TOT(state, { accessKey, rtTot }) {
    const currentRtTot = state[accessKey].rtTot;
    for (const key in rtTot) {
      currentRtTot[key] = rtTot[key];
    }
  },

  SET_VPN_INTERFACE(state, vpnInterface) {
    state.vpnInterface = vpnInterface;
  },

  SET_LAN_INTERFACES(state, payload) {
    state.lanInterfaces = payload;
  },

  SET_ACTION(state, { vpnKey, action }) {
    state.vpns[vpnKey].action = action;
  },

  SET_ACTION_NONE(state, vpnKey) {
    state.vpns[vpnKey].action = Actions.NONE;
  },

  SET_ACTION_ADD(state, vpnKey) {
    state.vpns[vpnKey].action = Actions.ADD;
  },

  SET_ACTION_DELETE(state, vpnKey) {
    state.vpns[vpnKey].action = Actions.DELETE;
  },

  SET_LAN_INTERFACE(state, { vpnKey, lanInterface }) {
    state.vpns[vpnKey].lanInterface = lanInterface;
  },

  SET_IS_PRIMARY(state, { vpnKey, isPrimary }) {
    state.vpns[vpnKey].isPrimary = isPrimary;
  },

  SET_IS_EXISTING(state, { vpnKey, isExisting }) {
    state.vpns[vpnKey].isExisting = isExisting;
  },

  SET_IS_INCLUDED_IN_BACKUP(state, { vpnKey, isIncludedInBackup }) {
    state.vpns[vpnKey].isIncludedInBackup = isIncludedInBackup;
  },

  SET_ID(state, { vpnKey, vpnId }) {
    state.vpns[vpnKey].id = vpnId;
  },

  SET_ALIAS(state, { vpnKey, alias }) {
    state.vpns[vpnKey].alias = alias;
  },

  SET_BANDWIDTH(state, { vpnKey, bandwidth }) {
    const currentBandwidth = state.vpns[vpnKey].bandwidth;
    for (const key in bandwidth) {
      currentBandwidth[key] = bandwidth[key];
    }
  },

  SET_QOS(state, { vpnKey, qos }) {
    const currentQos = state.vpns[vpnKey].qos;
    for (const key in qos) {
      currentQos[key] = qos[key];
    }
  },

  SET_RT1(state, { vpnKey, rt1 }) {
    const currentRt1 = state.vpns[vpnKey].rt1;
    for (const key in rt1) {
      currentRt1[key] = rt1[key];
    }
  },

  SET_RT2(state, { vpnKey, rt2 }) {
    const currentRt2 = state.vpns[vpnKey].rt2;
    for (const key in rt2) {
      currentRt2[key] = rt2[key];
    }
  },

  SET_DHCP_RELAYS_ACTION(state, { vpnKey, action }) {
    state.vpns[vpnKey].dhcpRelaysAction = action;
  },

  RESET_STATE(state) {
    Object.assign(state, defaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
