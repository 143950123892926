<template>
  <div>
    <Summary v-if="isProductBusinessOrder" class="margin-bottom__24" :editable="false">
      <telia-p>
        <strong>{{ t("DATANET_ORDER_CONTACT") }}</strong>
      </telia-p>
      <telia-p>
        {{ `${orderContact.firstName} ${orderContact.lastName}` }}
      </telia-p>
      <telia-p>
        {{ orderContact.emailAddress }}
      </telia-p>
      <telia-p>
        {{ orderContact.phoneNumber }}
      </telia-p>
    </Summary>

    <div v-for="contactId in contactIds" :key="contactId">
      <InformationBox v-if="activeTooltip === contactId" @close="setActiveTooltip(null)">
        <ContactTooltip :show-contact-info-by-id="contactId" />
      </InformationBox>
      <Contact
        ref="contacts"
        :contact-id="contactId"
        :active-tooltip="activeTooltip"
        @activeTooltip="setActiveTooltip($event)"
      />
    </div>
  </div>
</template>

<script>
import InformationBox from "../InformationBox";
import Contact from "./Contact.vue";
import Summary from "../Summary";
import { mapState, mapGetters } from "vuex";
import { translateMixin } from "../../locale";
import ContactTooltip from "../tooltip-information/contact-information/ContactTooltip.vue";

export default {
  name: "ContactInformation",
  mixins: [translateMixin],
  components: {
    Contact,
    InformationBox,
    Summary,
    ContactTooltip,
  },
  data() {
    return {
      activeTooltip: null,
    };
  },

  computed: {
    ...mapState({
      isQuickLaunchEnabled: (state) => state.access.temporary.enable,
      isExtendedAvailabilityEnabled: (state) => state.access.secondary.enable,
    }),

    ...mapGetters({
      isProductBusinessOrder: "isProductBusinessOrder",
      isFunctionBusinessOrder: "isFunctionBusinessOrder",
      isWirelessBackupAccess: "products/isWirelessBackupAccess",
      hasBackupTypeDiversity: "access/hasBackupTypeDiversity",
      hasBackupTypeRedundancy: "access/hasBackupTypeRedundancy",
    }),

    showWirelessContacts() {
      return (
        this.$store.getters["products/isWirelessAccess"] ||
        this.isQuickLaunchEnabled ||
        (this.isExtendedAvailabilityEnabled && this.isWirelessBackupAccess)
      );
    },

    showSecondaryAccessContacts() {
      return this.hasBackupTypeDiversity || this.hasBackupTypeRedundancy;
    },

    orderContact() {
      return this.$store.getters["contactPersons/getContact"]("order");
    },

    contactIds() {
      let contactIds = ["technical", "facility"];

      if (this.showWirelessContacts) {
        if (this.isFunctionBusinessOrder) {
          contactIds.push("wirelessTechnical", "wirelessSms");
        } else {
          contactIds.push("delivery", "wirelessTechnical", "wirelessSms");
        }
      }

      if (this.showSecondaryAccessContacts) {
        contactIds.push("secondaryFacility");
      }

      return contactIds;
    },
  },

  methods: {
    async completeStep() {
      this.$emit("is-validating-contacts", true);
      const areContactsValid = await this.validate();

      if (areContactsValid) {
        this.$emit("step-complete");
      }
      this.$emit("is-validating-contacts", false);
    },

    async validate() {
      let validContacts = [];
      for (let i = 0; i < this.$refs.contacts.length; i++) {
        const isValid = await this.$refs.contacts[i].validate();
        validContacts.push(isValid);
      }
      if (validContacts.filter((isValid) => !isValid).length === 0) {
        return true;
      } else {
        return false;
      }
    },
    setActiveTooltip(value) {
      if (value === this.activeTooltip) {
        this.activeTooltip = null;
        return;
      }
      this.activeTooltip = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.margin-bottom {
  &__12 {
    margin-bottom: $telia-spacing-12;
  }
  &__24 {
    margin-bottom: $telia-spacing-24;
  }
}
</style>
