var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.activeTooltip
        ? _c(
            "InformationBox",
            {
              on: {
                close: function ($event) {
                  _vm.activeTooltip = false
                },
              },
            },
            [_c("DeliveryTooltip")],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "tooltip-wrapper" },
        [
          _c("AddressSearch", {
            staticClass: "input-wrapper",
            attrs: {
              label: _vm.t("DATANET_SEARCH_DELIVERY_ADDRESS"),
              value: _vm.deliveryFullAddress,
            },
            on: {
              addressSelected: function ($event) {
                return _vm.updateDeliveryAddress($event)
              },
            },
          }),
          _c("telia-icon", {
            staticClass: "tooltip-icon-inputs",
            attrs: { name: "question", size: "sm" },
            on: {
              click: function ($event) {
                _vm.activeTooltip = !_vm.activeTooltip
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }