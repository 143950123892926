var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "information-box-container",
      attrs: { id: "information-box-container" },
    },
    [
      _c(
        "div",
        { staticClass: "information-box", attrs: { id: "test" } },
        [
          _c("telia-icon", {
            staticClass: "information-box__close",
            attrs: { name: "close" },
            on: {
              click: function ($event) {
                return _vm.emitClose()
              },
            },
          }),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }