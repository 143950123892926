<template>
  <div t-id="action-selection" class="action-selection">
    <telia-chip-choice
      v-for="action in actionOptions"
      :key="action.value"
      :value="action.value"
      :checked="action.value === selectedAction"
      :disabled="action.disabled"
      @change="handleSelection($event.target.value)"
    >
      {{ action.label }}
    </telia-chip-choice>
  </div>
</template>

<script>
import { Actions } from "../constants";
import { translateMixin } from "../locale";

export default {
  name: "ActionSelection",
  mixins: [translateMixin],
  props: {
    selectedAction: {
      type: String,
      default: Actions.NONE,
      required: true,
    },
    disabledActions: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    actionOptions() {
      return [
        {
          value: Actions.ADD,
          label: this.t("DATANET_ACTION_ADD"),
          disabled: this.disabledActions.includes(Actions.ADD),
        },
        {
          value: Actions.UPDATE,
          label: this.t("DATANET_ACTION_UPDATE"),
          disabled: this.disabledActions.includes(Actions.UPDATE),
        },
        {
          value: Actions.DELETE,
          label: this.t("DATANET_ACTION_DELETE"),
          disabled: this.disabledActions.includes(Actions.DELETE),
        },
      ];
    },
  },

  methods: {
    handleSelection(value) {
      this.$emit("selected", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
.action-selection {
  display: flex;
}
.action-selection > * {
  margin-right: $telia-spacing-12;
}
</style>
