<template>
  <div>
    <div v-if="isChangeOrder" class="margin__bottom__24">
      <telia-heading tag="h4" variant="title-100" class="margin__bottom__12">
        {{ t("DATANET_VPN_EDIT_STATIC_ROUTE_CHOICE") }}
      </telia-heading>

      <ActionSelection
        :selected-action="routeAction"
        :disabled-actions="disabledActions"
        @selected="setAction($event)"
      />
    </div>
    <div class="information-box-wrapper">
      <InformationBox v-if="activeTooltip" @close="setActiveTooltip(null)">
        <StaticRouteTooltip :show-tooltip-by-id="activeTooltip" />
      </InformationBox>
    </div>
    <div class="margin__bottom__24">
      <telia-p>
        <strong>{{ t("DATANET_VPN_STATIC_ROUTE_NETWORK_INPUT") }}</strong>
      </telia-p>

      <div class="row margin__bottom__4">
        <div v-if="isCurrentValuesRequired" class="column">
          <div class="tooltip-wrapper">
            <div class="input-wrapper">
              <telia-text-input
                id="route-network-current"
                t-id="current-static-route-network"
                :label="t('DATANET_CURRENT_VALUE')"
                :value="networkInput.current"
                @input="setAndValidateCurrentNetwork($event.target.value)"
              />
            </div>
            <telia-icon
              v-if="!isNewValuesRequired"
              class="tooltip-icon-inputs"
              name="question"
              size="sm"
              @click="setActiveTooltip('route-network')"
            />
          </div>
        </div>
        <div v-if="isNewValuesRequired" class="column">
          <div class="tooltip-wrapper">
            <div class="input-wrapper">
              <telia-text-input
                id="route-network-new"
                t-id="new-static-route-network"
                :label="t('DATANET_NEW_VALUE')"
                :value="networkInput.new"
                @input="setAndValidateNewNetwork($event.target.value)"
              />
            </div>
            <telia-icon
              class="tooltip-icon-inputs"
              name="question"
              size="sm"
              @click="setActiveTooltip('route-network')"
            />
          </div>
        </div>
      </div>

      <ValidationError v-if="v.network.input.current.$error || v.network.input.new.$error">
        {{ t("DATANET_INVALID_NETWORK_FORMAT") }}
      </ValidationError>
      <telia-p v-else variant="additional-100" class="additional-text">
        {{ t("DATANET_NETWORK_PLACEHOLDER") }}
      </telia-p>
    </div>

    <div>
      <telia-p>
        <strong>{{ t("DATANET_VPN_STATIC_ROUTE_NEXT_HOP") }}</strong>
      </telia-p>

      <div class="row margin__bottom__4">
        <div v-if="isCurrentValuesRequired" class="column">
          <div class="tooltip-wrapper">
            <div class="input-wrapper">
              <telia-text-input
                id="route-next-hop-current"
                t-id="route-current-next-hop"
                :label="t('DATANET_CURRENT_VALUE')"
                :value="nextHopIpAddress.current"
                @input="setAndValidateCurrentNextHop($event.target.value)"
              />
            </div>
            <telia-icon
              v-if="!isNewValuesRequired"
              class="tooltip-icon-inputs"
              name="question"
              size="sm"
              @click="setActiveTooltip('next-hop')"
            />
          </div>
        </div>
        <div v-if="isNewValuesRequired" class="column">
          <div class="tooltip-wrapper">
            <div class="input-wrapper">
              <telia-text-input
                id="route-next-hop-new"
                t-id="route-new-next-hop"
                :label="t('DATANET_NEW_VALUE')"
                :value="nextHopIpAddress.new"
                @input="setAndValidateNewNextHop($event.target.value)"
              />
            </div>
            <telia-icon
              class="tooltip-icon-inputs"
              name="question"
              size="sm"
              @click="setActiveTooltip('next-hop')"
            />
          </div>
        </div>
      </div>

      <ValidationError v-if="v.nextHop.ipAddress.current.$error || v.nextHop.ipAddress.new.$error">
        {{ t("DATANET_INVALID_IP_ADDRESS") }}
      </ValidationError>
      <ValidationError v-else-if="v.nextHop.isMemberOfLanNetwork.$error">
        {{ t("DATANET_VPN_STATIC_ROUTE_NEXT_HOP_NOT_IN_LAN_NETWORK") }}
      </ValidationError>
      <telia-p v-else variant="additional-100" class="additional-text">
        {{ t("DATANET_IP_ADDRESS_PLACEHOLDER") }}
      </telia-p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { corpValidationService } from "@telia/b2b-rest-client";
import { translateMixin } from "../../locale";
import { Actions } from "../../constants";
import ActionSelection from "../ActionSelection";
import ValidationError from "../ValidationError";
import InformationBox from "../InformationBox.vue";
import StaticRouteTooltip from "../tooltip-information/vpn-settings/StaticRouteTooltip.vue";

export default {
  name: "StaticRoute",

  mixins: [translateMixin],

  components: {
    ActionSelection,
    ValidationError,
    InformationBox,
    StaticRouteTooltip,
  },

  props: {
    vpnKey: {
      type: String,
      required: true,
    },
    staticRouteKey: {
      type: String,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentNetworkTimeoutId: null,
      newNetworkTimeoutId: null,
      currentNextHopTimeoutId: null,
      newNextHopTimeoutId: null,
      activeTooltip: null,
    };
  },

  computed: {
    ...mapState({
      scopeId: (state) => state.user.scopeId,
    }),
    ...mapGetters({
      isChangeOrder: "isChangeOrder",
    }),
    ...mapGetters("vpn", {
      getVpn: "getVpn",
    }),
    ...mapGetters("lans", {
      getLanNetworks: "getNetworks",
    }),
    ...mapGetters("staticRoutes", {
      getStaticRouteAction: "getAction",
      getNetwork: "getNetwork",
      getNextHop: "getNextHop",
    }),

    vpn() {
      return this.getVpn(this.vpnKey);
    },

    disabledActions() {
      if (this.vpn.action === Actions.ADD) {
        return [Actions.UPDATE, Actions.DELETE];
      }

      return [];
    },

    routeAction() {
      return this.getStaticRouteAction(this.staticRouteKey);
    },

    routeActionDelete() {
      return this.routeAction === Actions.DELETE;
    },

    isCurrentValuesRequired() {
      return this.routeAction === Actions.UPDATE || this.routeAction === Actions.DELETE;
    },

    isNewValuesRequired() {
      return this.routeAction === Actions.ADD || this.routeAction === Actions.UPDATE;
    },

    networkInput() {
      const { input } = this.getNetwork(this.staticRouteKey);
      return input;
    },

    nextHopIpAddress() {
      const { ipAddress } = this.getNextHop(this.staticRouteKey);
      return ipAddress;
    },
  },

  methods: {
    ...mapActions("staticRoutes", {
      setActionAdd: "setActionAdd",
      setActionUpdate: "setActionUpdate",
      setActionDelete: "setActionDelete",
      setActionNone: "setActionNone",
      setNetworkInput: "setNetworkInput",
      setNetworkIpAddress: "setNetworkIpAddress",
      setNetworkIpPrefix: "setNetworkIpPrefix",
      setNetworkIpVersion: "setNetworkIpVersion",
      setNextHopIpAddress: "setNextHopIpAddress",
      setNextHopIpVersion: "setNextHopIpVersion",
      setNextHopIsMemberOfLanNetwork: "setNextHopIsMemberOfLanNetwork",
    }),

    setAction(value) {
      this.v.$reset();

      switch (value) {
        case Actions.ADD:
          this.setActionAdd(this.staticRouteKey);
          break;
        case Actions.UPDATE:
          this.setActionUpdate(this.staticRouteKey);
          break;
        case Actions.DELETE:
          this.setActionDelete(this.staticRouteKey);
          break;
        default:
          this.setActionNone(this.staticRouteKey);
          break;
      }

      this.resetNetwork();
      this.resetNextHop();
    },

    setActiveTooltip(value) {
      if (value === this.activeTooltip) {
        this.activeTooltip = null;
        return;
      }
      this.activeTooltip = value;
    },

    resetNetwork() {
      this.setNetworkInput({
        routeKey: this.staticRouteKey,
        action: "",
        current: "",
        new: "",
      });
      this.setNetworkIpVersion({
        routeKey: this.staticRouteKey,
        action: "",
        current: "",
        new: "",
      });

      this.setNetworkIpAddress({
        routeKey: this.staticRouteKey,
        action: "",
        current: "",
        new: "",
      });

      this.setNetworkIpPrefix({
        routeKey: this.staticRouteKey,
        action: "",
        current: "",
        new: "",
      });

      this.v.network.$reset();
    },

    resetNextHop() {
      this.setNextHopIpVersion({
        routeKey: this.staticRouteKey,
        action: "",
        current: "",
        new: "",
      });

      this.setNextHopIpAddress({
        routeKey: this.staticRouteKey,
        action: "",
        current: "",
        new: "",
      });

      this.setNextHopIsMemberOfLanNetwork({
        routeKey: this.staticRouteKey,
        isMemberOfLanNetwork: false,
      });

      this.v.nextHop.$reset();
    },

    async setAndValidateCurrentNetwork(input) {
      this.setNetworkInput({
        routeKey: this.staticRouteKey,
        action: this.routeAction,
        current: input,
      });

      if (this.currentNetworkTimeoutId) {
        clearTimeout(this.currentNetworkTimeoutId);
      }

      this.currentNetworkTimeoutId = setTimeout(
        async function () {
          let networkInfo;
          try {
            const response = await corpValidationService.ValidationControllerService.validate(
              this.scopeId,
              {
                type: "NETWORK",
                value: input,
              }
            );
            const data = await response;
            networkInfo = { valid: true, ...data };
          } catch (e) {
            networkInfo = {
              valid: false,
            };
          }

          this.setNetworkIpVersion({
            routeKey: this.staticRouteKey,
            action: this.routeAction,
            current: networkInfo && networkInfo.valid ? networkInfo.ipVersion : "",
          });

          this.setNetworkIpAddress({
            routeKey: this.staticRouteKey,
            action: this.routeAction,
            current: networkInfo && networkInfo.valid ? networkInfo.network : "",
          });

          this.setNetworkIpPrefix({
            routeKey: this.staticRouteKey,
            action: this.routeAction,
            current: networkInfo && networkInfo.valid ? networkInfo.prefix : "",
          });
        }.bind(this),
        400
      );
    },

    async setAndValidateNewNetwork(input) {
      this.setNetworkInput({
        routeKey: this.staticRouteKey,
        action: this.routeAction,
        new: input,
      });

      if (this.newNetworkTimeoutId) {
        clearTimeout(this.newNetworkTimeoutId);
      }

      this.newNetworkTimeoutId = setTimeout(
        async function () {
          let networkInfo;
          try {
            const response = await corpValidationService.ValidationControllerService.validate(
              this.scopeId,
              {
                type: "NETWORK",
                value: input,
              }
            );
            networkInfo = { valid: true, ...response };
          } catch (e) {
            networkInfo = {
              valid: false,
            };
          }

          this.setNetworkIpVersion({
            routeKey: this.staticRouteKey,
            action: this.routeAction,
            new: networkInfo && networkInfo.valid ? networkInfo.ipVersion : "",
          });

          this.setNetworkIpAddress({
            routeKey: this.staticRouteKey,
            action: this.routeAction,
            new: networkInfo && networkInfo.valid ? networkInfo.network : "",
          });

          this.setNetworkIpPrefix({
            routeKey: this.staticRouteKey,
            action: this.routeAction,
            new: networkInfo && networkInfo.valid ? networkInfo.prefix : "",
          });
        }.bind(this),
        400
      );
    },

    async setAndValidateCurrentNextHop(input) {
      this.v.nextHop.ipAddress.current.$reset();
      this.v.nextHop.isMemberOfLanNetwork.$reset();

      this.setNextHopIpAddress({
        routeKey: this.staticRouteKey,
        action: this.routeAction,
        current: input,
      });

      if (this.currentNextHopTimeoutId) {
        clearTimeout(this.currentNextHopTimeoutId);
      }

      this.currentNextHopTimeoutId = setTimeout(
        async function () {
          let ipInfo;
          try {
            const response = await corpValidationService.ValidationControllerService.validate(
              this.scopeId,
              {
                type: "IPADDRESS",
                value: input,
              }
            );
            const data = await response;
            ipInfo = { valid: true, ...data };
          } catch (e) {
            ipInfo = {
              valid: false,
            };
          }

          if (ipInfo.valid && ipInfo.version === "4") {
            this.setNextHopIpVersion({
              routeKey: this.staticRouteKey,
              action: this.routeAction,
              current: ipInfo.valid ? ipInfo.version : "",
            });

            this.setNextHopIsMemberOfLanNetwork({
              routeKey: this.staticRouteKey,
              isMemberOfLanNetwork: true, // isMemberOfLanNetwork,
            });
          }
        }.bind(this),
        400
      );
    },

    async setAndValidateNewNextHop(input) {
      this.v.nextHop.ipAddress.new.$reset();
      this.v.nextHop.isMemberOfLanNetwork.$reset();

      this.setNextHopIpAddress({
        routeKey: this.staticRouteKey,
        action: this.routeAction,
        new: input,
      });

      if (this.newNextHopTimeoutId) {
        clearTimeout(this.newNextHopTimeoutId);
      }

      this.newNextHopTimeoutId = setTimeout(
        async function () {
          let ipInfo;
          try {
            const response = await corpValidationService.ValidationControllerService.validate(
              this.scopeId,
              {
                type: "IPADDRESS",
                value: input,
              }
            );
            ipInfo = { valid: true, ...response };
          } catch (e) {
            ipInfo = {
              valid: false,
            };
          }
          if (ipInfo.valid && ipInfo.version === "4") {
            this.setNextHopIpVersion({
              routeKey: this.staticRouteKey,
              action: this.routeAction,
              new: ipInfo.valid ? ipInfo.version : "",
            });

            this.setNextHopIsMemberOfLanNetwork({
              routeKey: this.staticRouteKey,
              isMemberOfLanNetwork: true, // isMemberOfLanNetwork,
            });
          }
        }.bind(this),
        400
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
}

.column {
  flex: 1 1 0;
  margin-right: 0.5rem;
}

.column:last-child {
  margin: 0;
}

.input-wrapper {
  max-width: 42rem;
  flex: 1;
}
.tooltip-wrapper {
  display: flex;
}
.tooltip-icon-inputs {
  position: relative;
  top: 3.8rem;
  margin-left: $telia-spacing-12;
}
.tooltip-icon-inputs:hover {
  color: $telia-purple-500;
  cursor: pointer;
}
.information-box-wrapper {
  position: relative;
  top: -12rem;
  z-index: 10;
}
.margin {
  &__bottom {
    &__4 {
      margin-bottom: $telia-spacing-4;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
}
.additional-text {
  color: $telia-gray-500;
}
.additional-text-warning {
  color: $telia-red-500;
}
</style>
