<template>
  <div>
    <telia-heading tag="h5" variant="title-100">
      {{
        isWirelessAccess
          ? t("DATANET_WOULD_YOU_LIKE_TO_ADD_WIRELESS_ACCESS_FAST_TRACK")
          : t("DATANET_WOULD_YOU_LIKE_TO_ADD_TEMPORARY_ACCESS_FAST_TRACK")
      }}
    </telia-heading>

    <telia-fieldset name="fast-track-options" caption="fieldset for fast-track-options">
      <div class="chip-choice-wrapper">
        <telia-chip-choice
          id="enable-fast-track"
          :checked="fastTrack"
          @change="handleSelection(true)"
        >
          {{ t("DATANET_YES") }}
        </telia-chip-choice>
        <telia-chip-choice
          id="disable-fast-track"
          :checked="!fastTrack"
          @change="handleSelection(false)"
        >
          {{ t("DATANET_NO") }}
        </telia-chip-choice>
      </div>
    </telia-fieldset>
  </div>
</template>

<script>
import { translateMixin } from "../../locale";
import { mapGetters } from "vuex";

export default {
  name: "FastTrack",
  mixins: [translateMixin],

  data() {
    return {
      fastTrack: this.$store.state.access.temporary.fastTrack,
    };
  },

  computed: {
    ...mapGetters({
      isWirelessAccess: "products/isWirelessAccess",
    }),
  },

  methods: {
    handleSelection(value) {
      this.fastTrack = value;
      this.$emit("enable-fast-track", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.chip-choice-wrapper > * {
  margin-right: $telia-spacing-12;
}
</style>
