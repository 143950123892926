import Vue from "vue";
import { t } from "../../locale";
const defaultState = () => ({
  apartment: "",
  apartments: null,
});

const state = defaultState();

const getters = {
  getApartmentOptions: (state) => {
    // TODO: Is placeholder option necessary when using new components? Remove otherwise
    let placeholder = [
      {
        value: "",
        label: t("DATANET_APARTMENT_NUMBER_PLACEHOLDER"),
      },
    ];

    if (!state.apartments) return placeholder;

    return placeholder.concat(
      Object.values(state.apartments).map((apartment) => ({
        value: apartment.number,
        label: apartment.number,
      }))
    );
  },
};

const actions = {
  setApartment: ({ state, dispatch, commit }, apartment) => {
    if (apartment === state.apartment) return;
    commit("SET_APARTMENT", apartment);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setApartments: ({ commit }, apartments) => {
    let modelledApartments = {};
    for (const apartment of apartments) {
      modelledApartments[apartment.number] = {
        key: apartment.number,
        number: apartment.number,
        pointId: apartment.pointId,
      };
    }
    commit("SET_APARTMENTS", modelledApartments);
  },
};

const mutations = {
  SET_APARTMENT(state, apartment) {
    state.apartment = apartment;
  },

  SET_APARTMENTS(state, apartments) {
    Vue.set(state, "apartments", apartments);
  },

  RESET_STATE() {
    Object.assign(state, defaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
