import { render, staticRenderFns } from "./ProductOverview.vue?vue&type=template&id=1c7af678&scoped=true"
import script from "./ProductOverview.vue?vue&type=script&lang=js"
export * from "./ProductOverview.vue?vue&type=script&lang=js"
import style0 from "./ProductOverview.vue?vue&type=style&index=0&id=1c7af678&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../common/temp/node_modules/.pnpm/vue-loader@15.11.1_prettier@2.2.1_vue-template-compiler@2.6.14_webpack@5.94.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c7af678",
  null
  
)

export default component.exports