<template>
  <li :class="listItemClasses" @click="$emit('click')">
    <telia-icon
      v-if="deletable"
      class="margin-right"
      name="close"
      size="sm"
      @click="$emit('delete')"
    />
    <telia-icon v-if="addable" class="margin-right" name="plus" size="sm" />
    <telia-checkbox
      v-if="checkable"
      :value="!checked"
      :checked="checked"
      @change="$emit('include', !checked)"
    />
    <telia-p class="list-item__text" v-text="text" />
    <telia-icon v-if="!addable" :name="invalid ? 'alert' : 'chevron-right'" size="sm" />
  </li>
</template>

<script>
export default {
  name: "ListItem",

  props: {
    text: {
      type: String,
      default: "",
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    addable: {
      type: Boolean,
      default: false,
    },
    checkable: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    listItemClasses() {
      const classes = ["list-item"];
      if (this.invalid) classes.push("invalid");
      if (this.active) classes.push("active");
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables";

.list-item {
  display: flex;
  align-items: center;
  padding: $telia-spacing-12 $telia-spacing-24;
  cursor: pointer;

  &:hover {
    background-color: $telia-gray-50;
  }

  &__text {
    flex: 1;
    margin-bottom: 0 !important;
  }
}

.invalid {
  color: $telia-red-600;
}

.active {
  background-color: $telia-gray-100;
  font-weight: bold;
}
.margin-right {
  margin-right: $telia-spacing-12;
}
</style>
