var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FastTrack", {
        staticClass: "margin__bottom__32",
        on: {
          "enable-fast-track": function ($event) {
            _vm.fastTrack = $event
          },
        },
      }),
      _c(
        "div",
        { staticClass: "margin__bottom__12" },
        [
          _c("telia-heading", { attrs: { tag: "h4", variant: "title-100" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.t("DATANET_TEMPORARY_ACCESS_SETTINGS")) +
                "\n    "
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "margin__bottom__12" },
        [
          _c("telia-p", [
            _c("strong", [_vm._v(_vm._s(_vm.t("DATANET_ACCESS_TYPE")))]),
            _c("br"),
            _vm._v(
              "\n      " + _vm._s(_vm.t("DATANET_" + _vm.bearer)) + "\n    "
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "margin__bottom__12" },
        [
          _c("telia-p", [
            _c("strong", [_vm._v(_vm._s(_vm.t("DATANET_SPEED")))]),
            _c("br"),
            _vm._v(
              "\n      " + _vm._s(_vm.t("DATANET_BEST_EFFORT")) + "\n    "
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "margin__bottom__24 input-wrapper" },
        [
          _c("telia-select", {
            ref: "sla",
            attrs: {
              id: "temporary-access-sla",
              "t-id": "temporary-access-sla",
              label: _vm.t("DATANET_SLA"),
              value: _vm.sla,
              options: JSON.stringify(_vm.slaOptions),
            },
            on: {
              change: function ($event) {
                _vm.sla = $event.target.value
              },
            },
          }),
          _vm.$v.sla.$error
            ? _c("ValidationError", [
                _vm._v(" " + _vm._s(_vm.t("DATANET_SLA_REQUIRED")) + " "),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "margin__bottom__32 input-wrapper" },
        [
          _c("telia-select", {
            ref: "qos",
            attrs: {
              id: "temporary-access-qos",
              "t-id": "temporary-access-qos",
              label: _vm.t("DATANET_QOS"),
              value: _vm.qos,
              options: JSON.stringify(_vm.qosOptions),
            },
            on: {
              change: function ($event) {
                _vm.qos = $event.target.value
              },
            },
          }),
          _vm.$v.qos.$error
            ? _c("ValidationError", [
                _vm._v(" " + _vm._s(_vm.t("DATANET_QOS_REQUIRED")) + " "),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("WirelessInstallation", {
        staticClass: "margin__bottom__32",
        attrs: {
          id: "request-installation-by-telia-for-temporary-access",
          "initial-value": _vm.wirelessInstallation,
        },
        on: {
          "request-installation-by-telia": function ($event) {
            _vm.wirelessInstallation = $event
          },
        },
      }),
      _c("AntennaPackage", {
        staticClass: "margin__bottom__32",
        attrs: { "access-key": "temporary" },
        on: {
          "antenna-package-data": function ($event) {
            return _vm.handleAntennaPackageData($event)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "margin__bottom__32" },
        [
          _c("telia-heading", { attrs: { tag: "h4", variant: "title-100" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.t("DATANET_AFTER_DELIVERY_INFORMATION")) +
                "\n    "
            ),
          ]),
          _c(
            "telia-fieldset",
            _vm._l(_vm.afterDeliveryOptions, function (option) {
              return _c("telia-radio-button", {
                key: option.value,
                attrs: {
                  id: "after-installation-options",
                  "t-id": "after-installation-options",
                  name: "after-installation-options",
                  label: option.label,
                  checked: _vm.afterDeliveryOption === option.value,
                },
                on: {
                  change: function ($event) {
                    _vm.afterDeliveryOption = option.value
                  },
                },
              })
            }),
            1
          ),
          _vm.$v.afterDeliveryOption.$error
            ? _c("ValidationError", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.t("DATANET_MUST_SELECT_AFTER_DELIVERY_CHOICE")) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button-wrapper" },
        [
          _c(
            "telia-button",
            {
              attrs: {
                id: "save-temporary-access-configuration",
                "t-id": "save-temporary-access-configuration",
                variant: "expressive",
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.saveAndClose()
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.t("DATANET_SAVE_CHANGES")) + "\n    "
              ),
            ]
          ),
          _c(
            "telia-button",
            {
              attrs: {
                id: "cancel-temporary-access-configuration",
                type: "button",
                variant: "secondary",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("complete")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.t("DATANET_ABORT")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }