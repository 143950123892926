var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("telia-heading", {
        attrs: { tag: "h4", variant: "title-100" },
        domProps: {
          textContent: _vm._s(_vm.t("DATANET_INSTALLATION_INFORMATION")),
        },
      }),
      _c("telia-p", {
        staticClass: "margin-bottom",
        domProps: {
          textContent: _vm._s(_vm.t("DATANET_INSTALLATION_INFORMATION_INFO")),
        },
      }),
      _c(
        "telia-checkbox",
        {
          attrs: {
            "t-id": "installation-by-telia",
            disabled: _vm.disableInput,
            value: !_vm.checked,
            checked: _vm.checked,
          },
          on: { change: _vm.handleInput },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.t("DATANET_WIRELESS_INSTALLATION_CHOICE")) +
              "\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }