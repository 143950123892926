import defaultChangeableValue from "../../defaultChangeableValue.js";
import { Actions } from "../../../constants";

const ip = ({
  action = Actions.ADD,
  key = "",
  ownerKey = "",
  siblingKey = "",
  ipVersion = { ownerKey, ...defaultChangeableValue({ action }) },
  ipAddress = { ownerKey, ...defaultChangeableValue({ action }) },
} = {}) => ({
  action,
  key,
  ownerKey,
  siblingKey,
  ipVersion,
  ipAddress,
});

export default ip;
