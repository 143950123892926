<template>
  <div class="lan-tooltip">
    <telia-heading tag="h5" variant="title-100">
      {{ t("DATANET_GUIDANCE_LAN_ADDRESS_TITLE") }}
    </telia-heading>
    <telia-p>
      {{ t("DATANET_GUIDANCE_LAN_ADDRESS_INFO") }}
    </telia-p>
  </div>
</template>
<script>
import { translateMixin } from "../../../locale";
export default {
  name: "lanTooltip",
  mixins: [translateMixin],
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.margin {
  &__bottom {
    &__4 {
      margin-bottom: $telia-spacing-4;
    }
    &__12 {
      margin-bottom: $telia-spacing-12;
    }
    &__24 {
      margin-bottom: $telia-spacing-24;
    }
  }
  &__top {
    &__12 {
      margin-top: $telia-spacing-12;
    }
  }
  &__left {
    &__48 {
      margin-left: $telia-spacing-48;
    }
  }
}
</style>
