var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { "data-test-id": "b2b-order-datanet" } },
    [
      _c(
        "telia-grid",
        [
          _vm.isLoading
            ? _c(
                "telia-row",
                [
                  _c(
                    "telia-col",
                    { attrs: { width: "12" } },
                    [
                      _c("telia-heading", {
                        staticClass: "margin__bottom__48",
                        attrs: { tag: "h1", variant: "title-500" },
                        domProps: { textContent: _vm._s(_vm.getTitle()) },
                      }),
                      _c(
                        "div",
                        { staticClass: "spinner-wrapper" },
                        [
                          _c("b2x-spinner", {
                            attrs: {
                              color: "core-purple-light",
                              size: "large",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.alert.show
            ? _c(
                "telia-row",
                [
                  _c(
                    "telia-col",
                    { attrs: { width: "6" } },
                    [
                      _c("telia-heading", {
                        staticClass: "margin__bottom__48",
                        attrs: { tag: "h1", variant: "title-500" },
                        domProps: { textContent: _vm._s(_vm.getTitle()) },
                      }),
                      _c(
                        "div",
                        { staticClass: "margin__bottom__24" },
                        [
                          _c(
                            "telia-notification",
                            {
                              attrs: {
                                "heading-tag": "h4",
                                variant: "inline",
                                status: "warning",
                                "html-aria-live": "polite",
                                "html-role": "alert",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "heading" }, slot: "heading" },
                                [
                                  _c("telia-visually-hidden", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.t("A11Y_STATUS_WARNING")) +
                                        "\n              "
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.t("SOMETHING_WENT_WRONG")) +
                                      "\n            "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _c("telia-p", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.alert.text) +
                                        "\n              "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "margin__bottom__12" },
                        [
                          _c(
                            "telia-button",
                            {
                              attrs: { type: "button", variant: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToUrl(_vm.myBusinessUrl)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.t("DATANET_BACK_TO_MY_BUSINESS")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : (!_vm.isLoading && !_vm.hasSubmittedOrder) ||
              (_vm.hasSubmittedOrder && _vm.isFunctionBusinessOrder)
            ? _c(
                "telia-row",
                [
                  _c(
                    "telia-col",
                    { attrs: { width: "12" } },
                    [
                      _c("telia-heading", {
                        staticClass: "margin__bottom__48",
                        attrs: { tag: "h1", variant: "title-500" },
                        domProps: { textContent: _vm._s(_vm.getTitle()) },
                      }),
                      !_vm.isLoading
                        ? _c(
                            "Stepper",
                            {
                              attrs: {
                                "step-titles": _vm.stepTitles,
                                "current-step": _vm.currentStep,
                                "show-icons": _vm.isNewOrder,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  attrs: {
                                    slot:
                                      "step-" +
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t("DATANET_ORGANISATION_SELECT")
                                      ),
                                  },
                                  slot:
                                    "step-" +
                                    _vm.getIndexOfAsStepInArray(
                                      _vm.stepTitles,
                                      _vm.t("DATANET_ORGANISATION_SELECT")
                                    ),
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.currentStep ===
                                            _vm.getIndexOfAsStepInArray(
                                              _vm.stepTitles,
                                              _vm.t(
                                                "DATANET_ORGANISATION_SELECT"
                                              )
                                            ),
                                          expression:
                                            "\n                currentStep ===\n                getIndexOfAsStepInArray(stepTitles, t('DATANET_ORGANISATION_SELECT'))\n              ",
                                        },
                                      ],
                                    },
                                    [
                                      _c("OrganisationSettings", {
                                        ref: "organisationSettings",
                                        attrs: { "scope-id": _vm.scopeId },
                                        on: {
                                          "step-complete": function ($event) {
                                            return _vm.handleStepComplete()
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Summary",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.currentStep >
                                            _vm.getIndexOfAsStepInArray(
                                              _vm.stepTitles,
                                              _vm.t(
                                                "DATANET_ORGANISATION_SELECT"
                                              )
                                            ),
                                          expression:
                                            "\n                currentStep >\n                getIndexOfAsStepInArray(stepTitles, t('DATANET_ORGANISATION_SELECT'))\n              ",
                                        },
                                      ],
                                      attrs: {
                                        "edit-label": _vm.t("DATANET_EDIT"),
                                      },
                                      on: {
                                        edit: function ($event) {
                                          return _vm.resetSectionsFrom(1)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "telia-p",
                                        { staticClass: "margin__bottom__12" },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.t("DATANET_ORGANISATION")
                                              )
                                            ),
                                          ]),
                                          _c("br"),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.organisationSummaryText
                                              ) +
                                              "\n                "
                                          ),
                                          _c("br"),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    slot:
                                      "step-" +
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t("DATANET_INSTALLATION_ADDRESS")
                                      ),
                                  },
                                  slot:
                                    "step-" +
                                    _vm.getIndexOfAsStepInArray(
                                      _vm.stepTitles,
                                      _vm.t("DATANET_INSTALLATION_ADDRESS")
                                    ),
                                },
                                [
                                  _vm.currentStep ===
                                  _vm.getIndexOfAsStepInArray(
                                    _vm.stepTitles,
                                    _vm.t("DATANET_INSTALLATION_ADDRESS")
                                  )
                                    ? _c("PreCheck", {
                                        attrs: { "t-id": "pre-check" },
                                        on: {
                                          result: function ($event) {
                                            return _vm.handlePreCheckData(
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    : _vm.currentStep >
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t("DATANET_INSTALLATION_ADDRESS")
                                      )
                                    ? _c(
                                        "Summary",
                                        {
                                          attrs: {
                                            "edit-label": _vm.t("DATANET_EDIT"),
                                          },
                                          on: {
                                            edit: function ($event) {
                                              return _vm.resetSectionsFrom(1)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "telia-p",
                                            {
                                              staticClass: "margin__bottom__12",
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.t(
                                                      "DATANET_INSTALLATION_ADDRESS"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("br"),
                                              _vm.installationAddress.streetName
                                                ? _c("div", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm
                                                            .installationAddress
                                                            .streetName +
                                                            " " +
                                                            _vm
                                                              .installationAddress
                                                              .streetNumber +
                                                            (_vm
                                                              .installationAddress
                                                              .entrance || "")
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm
                                                            .installationAddress
                                                            .postalCode +
                                                            " " +
                                                            _vm
                                                              .installationAddress
                                                              .city
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                    _c("br"),
                                                  ])
                                                : _vm.installationAddress.x
                                                ? _c("div", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          "X: " +
                                                            _vm
                                                              .installationAddress
                                                              .x
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          "Y: " +
                                                            _vm
                                                              .installationAddress
                                                              .y
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                    _c("br"),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    slot:
                                      "step-" +
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t("DATANET_AVAILABILITY_TITLE")
                                      ),
                                  },
                                  slot:
                                    "step-" +
                                    _vm.getIndexOfAsStepInArray(
                                      _vm.stepTitles,
                                      _vm.t("DATANET_AVAILABILITY_TITLE")
                                    ),
                                },
                                [
                                  _vm.currentStep ===
                                  _vm.getIndexOfAsStepInArray(
                                    _vm.stepTitles,
                                    _vm.t("DATANET_AVAILABILITY_TITLE")
                                  )
                                    ? _c("ProductOverview", {
                                        on: {
                                          "step-complete": function ($event) {
                                            return _vm.handleStepComplete()
                                          },
                                        },
                                      })
                                    : _vm.currentStep >
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t("DATANET_AVAILABILITY_TITLE")
                                      )
                                    ? _c(
                                        "Summary",
                                        {
                                          attrs: {
                                            id: "primary-access",
                                            "edit-label": _vm.t("DATANET_EDIT"),
                                          },
                                          on: {
                                            edit: function ($event) {
                                              return _vm.resetSectionsFrom(2)
                                            },
                                          },
                                        },
                                        [
                                          _c("telia-p", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.primaryAccessBearerSummaryText
                                                )
                                              ),
                                            ]),
                                            _c("br"),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.primaryAccessDeliveryDateSummaryText
                                                ) +
                                                "\n              "
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    slot:
                                      "step-" +
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t("DATANET_ACCESS_SETTINGS")
                                      ),
                                  },
                                  slot:
                                    "step-" +
                                    _vm.getIndexOfAsStepInArray(
                                      _vm.stepTitles,
                                      _vm.t("DATANET_ACCESS_SETTINGS")
                                    ),
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.currentStep ===
                                            _vm.getIndexOfAsStepInArray(
                                              _vm.stepTitles,
                                              _vm.t("DATANET_ACCESS_SETTINGS")
                                            ),
                                          expression:
                                            "\n                currentStep === getIndexOfAsStepInArray(stepTitles, t('DATANET_ACCESS_SETTINGS'))\n              ",
                                        },
                                      ],
                                    },
                                    [
                                      _c("AccessSettings", {
                                        ref: "accessSettings",
                                        on: {
                                          "step-complete": function ($event) {
                                            return _vm.handleStepComplete()
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "margin__top__12" },
                                        [
                                          _c(
                                            "telia-button",
                                            {
                                              attrs: {
                                                "t-id": "next-step",
                                                type: "button",
                                                variant: _vm.isNewOrder
                                                  ? "primary"
                                                  : "expressive",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.accessSettings.completeStep()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.getStepButtonText()
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Summary",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.currentStep >
                                              _vm.getIndexOfAsStepInArray(
                                                _vm.stepTitles,
                                                _vm.t("DATANET_ACCESS_SETTINGS")
                                              ) && _vm.isNewOrder,
                                          expression:
                                            "\n                currentStep > getIndexOfAsStepInArray(stepTitles, t('DATANET_ACCESS_SETTINGS')) &&\n                isNewOrder\n              ",
                                        },
                                      ],
                                      attrs: {
                                        "edit-label": _vm.t("DATANET_EDIT"),
                                      },
                                      on: {
                                        edit: function ($event) {
                                          _vm.goToStep(
                                            _vm.getIndexOfAsStepInArray(
                                              _vm.stepTitles,
                                              _vm.t("DATANET_ACCESS_SETTINGS")
                                            )
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("telia-p", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.t("DATANET_PRIMARY_ACCESS")
                                            )
                                          ),
                                        ]),
                                        _c("br"),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.primaryAccessBearerSummaryText
                                            ) +
                                            "\n                "
                                        ),
                                        _c("br"),
                                        _vm.isFttxAccess
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.apartmentNumberSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.installationRoomSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                        _vm.isWirelessAccess
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.primaryAccessTeliaInstallationSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.primaryAccessAntennaPackageSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.primaryAccessAntennaCableSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.primaryAccessAntennaInstallationSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                        _vm.shouldShowPrimaryAccessBandwidth
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.primaryAccessBandwidthSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                        _vm.shouldShowPrimaryAccessSla
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.primaryAccessSlaSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                        _vm.shouldShowPrimaryAccessQos
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.primaryAccessQosSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                        _vm.shouldShowPrimaryAccessTrafficStatistics
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.primaryAccessStatBasSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                        _vm.temporaryAccess.enable
                                          ? _c("div", [
                                              _c("br"),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.t(
                                                      "DATANET_TEMPORARY_ACCESS"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.fastTrackSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.temporaryAccessBearerSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.temporaryAccessBandwidthSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.temporaryAccessSlaSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.temporaryAccessQosSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.temporaryAccessTeliaInstallationSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.temporaryAccessAntennaPackageSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.temporaryAccessAntennaCableSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.temporaryAccessAntennaInstallationSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.temporaryAccessAfterDeliveryChoiceSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                        _vm.hasBackupAccess
                                          ? _c("div", [
                                              _c("br"),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.t(
                                                      "DATANET_SECONDARY_ACCESS"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.secondaryAccessBackupSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.secondaryAccessBearerSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm.isWirelessBackupAccess
                                                ? _c("div", [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.secondaryAccessTeliaInstallationSummaryText
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.secondaryAccessAntennaPackageSummaryText
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.secondaryAccessAntennaCableSummaryText
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.secondaryAccessAntennaInstallationSummaryText
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                    _c("br"),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.secondaryAccessBandwidthSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.secondaryAccessSlaSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.secondaryAccessQosSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.secondaryAccessStatBasSummaryText
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("br"),
                                            ])
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.currentStep !==
                                              _vm.getIndexOfAsStepInArray(
                                                _vm.stepTitles,
                                                _vm.t("DATANET_ACCESS_SETTINGS")
                                              ) && _vm.isChangeOrder,
                                          expression:
                                            "\n                currentStep !==\n                  getIndexOfAsStepInArray(stepTitles, t('DATANET_ACCESS_SETTINGS')) &&\n                isChangeOrder\n              ",
                                        },
                                      ],
                                    },
                                    [
                                      _vm.isChangeOrder
                                        ? _c("AccessChangeSummary")
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "margin__top__12" },
                                        [
                                          _c(
                                            "telia-button",
                                            {
                                              attrs: {
                                                "t-id": "change",
                                                type: "button",
                                                variant: "expressive",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.goToStep(
                                                    _vm.getIndexOfAsStepInArray(
                                                      _vm.stepTitles,
                                                      _vm.t(
                                                        "DATANET_ACCESS_SETTINGS"
                                                      )
                                                    )
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.t("DATANET_EDIT")
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      !_vm.hasValidAccessSettings()
                                        ? _c("ValidationError", {
                                            staticClass: "margin__top__24",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.t(
                                                  "DATANET_ACCESS_SETTINGS_ERROR"
                                                )
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    slot:
                                      "step-" +
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t("DATANET_VPN_SETTINGS")
                                      ),
                                  },
                                  slot:
                                    "step-" +
                                    _vm.getIndexOfAsStepInArray(
                                      _vm.stepTitles,
                                      _vm.t("DATANET_VPN_SETTINGS")
                                    ),
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.currentStep ===
                                            _vm.getIndexOfAsStepInArray(
                                              _vm.stepTitles,
                                              _vm.t("DATANET_VPN_SETTINGS")
                                            ),
                                          expression:
                                            "\n                currentStep === getIndexOfAsStepInArray(stepTitles, t('DATANET_VPN_SETTINGS'))\n              ",
                                        },
                                      ],
                                    },
                                    [
                                      _c("AddOns", {
                                        ref: "vpnSettings",
                                        on: {
                                          "step-complete": function ($event) {
                                            return _vm.handleStepComplete()
                                          },
                                          "is-validating-vpn-settings": function (
                                            $event
                                          ) {
                                            _vm.isValidatingVpnSettings = $event
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "margin__top__12" },
                                        [
                                          _c(
                                            "telia-button",
                                            {
                                              attrs: {
                                                "t-id": "next-step",
                                                type: "button",
                                                variant: _vm.isNewOrder
                                                  ? "primary"
                                                  : "expressive",
                                                size: "sm",
                                                disabled:
                                                  _vm.isValidatingVpnSettings,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.vpnSettings.completeStep()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.isValidatingVpnSettings
                                                      ? _vm.t(
                                                          "VALIDATING_BUTTON_STATE"
                                                        )
                                                      : _vm.getStepButtonText()
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Summary",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.currentStep >
                                              _vm.getIndexOfAsStepInArray(
                                                _vm.stepTitles,
                                                _vm.t("DATANET_VPN_SETTINGS")
                                              ) && _vm.isNewOrder,
                                          expression:
                                            "\n                currentStep > getIndexOfAsStepInArray(stepTitles, t('DATANET_VPN_SETTINGS')) &&\n                isNewOrder\n              ",
                                        },
                                      ],
                                      attrs: {
                                        "edit-label": _vm.t("DATANET_EDIT"),
                                      },
                                      on: {
                                        edit: function ($event) {
                                          _vm.goToStep(
                                            _vm.getIndexOfAsStepInArray(
                                              _vm.stepTitles,
                                              _vm.t("DATANET_VPN_SETTINGS")
                                            )
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.primaryAccessVpns,
                                      function (vpn, vpnIndex) {
                                        return _c(
                                          "div",
                                          { key: "vpn-" + vpnIndex },
                                          [
                                            _c(
                                              "telia-p",
                                              {
                                                class: [
                                                  vpnIndex > 0
                                                    ? "margin__top__12"
                                                    : "",
                                                ],
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      "VPN " + (vpnIndex + 1)
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c("telia-p", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.t("DATANET_VPN_ID") +
                                                      ": " +
                                                      vpn.id
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]),
                                            _c("telia-p", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.t("DATANET_VPN_ALIAS") +
                                                      ": " +
                                                      vpn.alias
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]),
                                            _vm.isMultiVpn
                                              ? _c("telia-p", [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.t(
                                                          "DATANET_VPN_BANDWIDTH"
                                                        ) +
                                                          ": " +
                                                          _vm.getVpnBandwidthText(
                                                            vpn.key
                                                          )
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.currentStep !==
                                              _vm.getIndexOfAsStepInArray(
                                                _vm.stepTitles,
                                                _vm.t("DATANET_VPN_SETTINGS")
                                              ) && _vm.isChangeOrder,
                                          expression:
                                            "\n                currentStep !== getIndexOfAsStepInArray(stepTitles, t('DATANET_VPN_SETTINGS')) &&\n                isChangeOrder\n              ",
                                        },
                                      ],
                                    },
                                    [
                                      _vm.isChangeOrder
                                        ? _c("VpnChangeSummary")
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "margin__top__12" },
                                        [
                                          _c(
                                            "telia-button",
                                            {
                                              attrs: {
                                                "t-id": "change",
                                                type: "button",
                                                variant: "expressive",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.goToStep(
                                                    _vm.getIndexOfAsStepInArray(
                                                      _vm.stepTitles,
                                                      _vm.t(
                                                        "DATANET_VPN_SETTINGS"
                                                      )
                                                    )
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.t("DATANET_EDIT")
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      !_vm.hasValidVpnSettings()
                                        ? _c("ValidationError", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.t(
                                                  "DATANET_VPN_SETTINGS_ERROR"
                                                )
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    slot:
                                      "step-" +
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t("DATANET_CONTACT_INFORMATION")
                                      ),
                                  },
                                  slot:
                                    "step-" +
                                    _vm.getIndexOfAsStepInArray(
                                      _vm.stepTitles,
                                      _vm.t("DATANET_CONTACT_INFORMATION")
                                    ),
                                },
                                [
                                  _vm.currentStep ===
                                    _vm.getIndexOfAsStepInArray(
                                      _vm.stepTitles,
                                      _vm.t("DATANET_CONTACT_INFORMATION")
                                    ) || _vm.isChangeOrder
                                    ? _c(
                                        "div",
                                        [
                                          _c("ContactInformation", {
                                            ref: "contactInformation",
                                            on: {
                                              "step-complete": function (
                                                $event
                                              ) {
                                                return _vm.handleStepComplete()
                                              },
                                              "is-validating-contacts": function (
                                                $event
                                              ) {
                                                _vm.isValidatingContacts = $event
                                              },
                                            },
                                          }),
                                          !_vm.isChangeOrder
                                            ? _c(
                                                "telia-button",
                                                {
                                                  attrs: {
                                                    "t-id": "next-step",
                                                    type: "button",
                                                    variant: _vm.isNewOrder
                                                      ? "primary"
                                                      : "expressive",
                                                    size: "sm",
                                                    disabled:
                                                      _vm.isValidatingContacts,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$refs.contactInformation.completeStep()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.isValidatingContacts
                                                          ? _vm.t(
                                                              "VALIDATING_BUTTON_STATE"
                                                            )
                                                          : _vm.getStepButtonText()
                                                      ) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm.currentStep >
                                        _vm.getIndexOfAsStepInArray(
                                          _vm.stepTitles,
                                          _vm.t("DATANET_CONTACT_INFORMATION")
                                        ) && _vm.isNewOrder
                                    ? _c(
                                        "Summary",
                                        {
                                          attrs: {
                                            "edit-label": _vm.t("DATANET_EDIT"),
                                          },
                                          on: {
                                            edit: function ($event) {
                                              _vm.goToStep(
                                                _vm.getIndexOfAsStepInArray(
                                                  _vm.stepTitles,
                                                  _vm.t(
                                                    "DATANET_CONTACT_INFORMATION"
                                                  )
                                                )
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.contacts,
                                          function (contact, index) {
                                            return _c(
                                              "div",
                                              { key: contact.id },
                                              [
                                                _c(
                                                  "telia-p",
                                                  {
                                                    class: [
                                                      index > 0
                                                        ? "margin__top__12"
                                                        : "",
                                                    ],
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          "" +
                                                            _vm.t(
                                                              "DATANET_" +
                                                                contact.id.toUpperCase() +
                                                                "_CONTACT"
                                                            )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c("telia-p", [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        contact.firstName +
                                                          " " +
                                                          contact.lastName
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    slot:
                                      "step-" +
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t(
                                          "DATANET_ON_PREMISES_INSTALLATION"
                                        )
                                      ),
                                  },
                                  slot:
                                    "step-" +
                                    _vm.getIndexOfAsStepInArray(
                                      _vm.stepTitles,
                                      _vm.t("DATANET_ON_PREMISES_INSTALLATION")
                                    ),
                                },
                                [
                                  _vm.currentStep ===
                                  _vm.getIndexOfAsStepInArray(
                                    _vm.stepTitles,
                                    _vm.t("DATANET_ON_PREMISES_INSTALLATION")
                                  )
                                    ? _c(
                                        "div",
                                        [
                                          _c("OnPremisesInstallation", {
                                            ref: "onPremisesInstallation",
                                            on: {
                                              "step-complete": function (
                                                $event
                                              ) {
                                                return _vm.handleStepComplete()
                                              },
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "margin__top__12" },
                                            [
                                              _c(
                                                "telia-button",
                                                {
                                                  attrs: {
                                                    "t-id": "next-step",
                                                    type: "button",
                                                    variant: _vm.isNewOrder
                                                      ? "primary"
                                                      : "tertiary",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$refs.onPremisesInstallation.completeStep()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.getStepButtonText()
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.currentStep >
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t(
                                          "DATANET_ON_PREMISES_INSTALLATION"
                                        )
                                      )
                                    ? _c(
                                        "Summary",
                                        {
                                          attrs: {
                                            "edit-label": _vm.t("DATANET_EDIT"),
                                          },
                                          on: {
                                            edit: function ($event) {
                                              _vm.goToStep(
                                                _vm.getIndexOfAsStepInArray(
                                                  _vm.stepTitles,
                                                  _vm.t(
                                                    "DATANET_ON_PREMISES_INSTALLATION"
                                                  )
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("telia-p", [
                                            _c("strong", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.t(
                                                      "DATANET_CONTACT_PERSON"
                                                    ) +
                                                      " (" +
                                                      _vm
                                                        .t(
                                                          "DATANET_PRIMARY_ACCESS"
                                                        )
                                                        .toLowerCase() +
                                                      ")"
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]),
                                          ]),
                                          _c("telia-p", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.installationContact
                                                    .firstName +
                                                    " " +
                                                    _vm.installationContact
                                                      .lastName
                                                ) +
                                                "\n              "
                                            ),
                                          ]),
                                          _vm.primaryInstallationChoice
                                            ? _c(
                                                "div",
                                                [
                                                  _c("telia-p", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.t(
                                                            "DATANET_CHOICE"
                                                          ) +
                                                            " (" +
                                                            _vm
                                                              .t(
                                                                "DATANET_PRIMARY_ACCESS"
                                                              )
                                                              .toLowerCase() +
                                                            ")"
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("telia-p", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.t(
                                                            _vm
                                                              .primaryInstallationChoice
                                                              .text
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.secondaryInstallationChoice
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "margin__top__12",
                                                },
                                                [
                                                  _vm
                                                    .secondaryInstallationContact
                                                    .emailAddress
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c("telia-p", [
                                                            _c("strong", [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    _vm.t(
                                                                      "DATANET_CONTACT_PERSON"
                                                                    ) +
                                                                      " (" +
                                                                      _vm
                                                                        .t(
                                                                          "DATANET_SECONDARY_ACCESS"
                                                                        )
                                                                        .toLowerCase() +
                                                                      ")"
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]),
                                                          ]),
                                                          _c("telia-p", [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm
                                                                    .secondaryInstallationContact
                                                                    .firstName +
                                                                    " " +
                                                                    _vm
                                                                      .secondaryInstallationContact
                                                                      .lastName
                                                                ) +
                                                                "\n                  "
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("telia-p", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.t(
                                                            "DATANET_CHOICE"
                                                          ) +
                                                            " (" +
                                                            _vm
                                                              .t(
                                                                "DATANET_SECONDARY_ACCESS"
                                                              )
                                                              .toLowerCase() +
                                                            ")"
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("telia-p", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.t(
                                                            _vm
                                                              .secondaryInstallationChoice
                                                              .text
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    slot:
                                      "step-" +
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t("DATANET_INVOICE_INFORMATION")
                                      ),
                                  },
                                  slot:
                                    "step-" +
                                    _vm.getIndexOfAsStepInArray(
                                      _vm.stepTitles,
                                      _vm.t("DATANET_INVOICE_INFORMATION")
                                    ),
                                },
                                [
                                  _vm.currentStep ===
                                  _vm.getIndexOfAsStepInArray(
                                    _vm.stepTitles,
                                    _vm.t("DATANET_INVOICE_INFORMATION")
                                  )
                                    ? _c(
                                        "div",
                                        [
                                          _c("Invoice", {
                                            ref: "invoiceInformation",
                                            staticClass: "margin__bottom__24",
                                            on: {
                                              "step-complete": function (
                                                $event
                                              ) {
                                                return _vm.handleStepComplete()
                                              },
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "margin__top__12" },
                                            [
                                              _c(
                                                "telia-button",
                                                {
                                                  attrs: {
                                                    "t-id": "next-step",
                                                    type: "button",
                                                    variant: _vm.isNewOrder
                                                      ? "primary"
                                                      : "expressive",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$refs.invoiceInformation.completeStep()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.getStepButtonText()
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.currentStep >
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t("DATANET_INVOICE_INFORMATION")
                                      )
                                    ? _c(
                                        "Summary",
                                        {
                                          attrs: {
                                            "edit-label": _vm.t("DATANET_EDIT"),
                                          },
                                          on: {
                                            edit: function ($event) {
                                              _vm.goToStep(
                                                _vm.getIndexOfAsStepInArray(
                                                  _vm.stepTitles,
                                                  _vm.t(
                                                    "DATANET_INVOICE_INFORMATION"
                                                  )
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm.billingAccount
                                            ? _c(
                                                "div",
                                                [
                                                  _c("telia-p", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.t(
                                                            "DATANET_INVOICE_ACCOUNT"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("telia-p", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.billingAccount
                                                            .billingAccountNumber
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]),
                                                  _c("telia-p", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.t(
                                                            "DATANET_INVOICE_ACCOUNT_ADDRESS"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _vm.billingAccount
                                                    .billingAddress.careOf
                                                    ? _c("telia-p", [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              "c/o " +
                                                                _vm
                                                                  .billingAccount
                                                                  .billingAddress
                                                                  .careOf
                                                            ) +
                                                            "\n                "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.billingAccount
                                                    .billingAddress.street
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c("telia-p", [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm
                                                                    .billingAccount
                                                                    .billingAddress
                                                                    .street
                                                                ) +
                                                                "\n                  "
                                                            ),
                                                          ]),
                                                          _c("telia-p", [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm
                                                                    .billingAccount
                                                                    .billingAddress
                                                                    .zipCode +
                                                                    " " +
                                                                    _vm
                                                                      .billingAccount
                                                                      .billingAddress
                                                                      .city
                                                                ) +
                                                                "\n                  "
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("telia-p", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.t(
                                                            "DATANET_INVOICE_ACCOUNT_METHOD"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("telia-p", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.billingAccount
                                                            .billingMethodText
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]),
                                                  _vm.billingAccount
                                                    .billingEmail.email
                                                    ? _c("telia-p", [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.billingAccount
                                                                .billingEmail
                                                                .email
                                                            ) +
                                                            "\n                "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "telia-p",
                                                {
                                                  staticClass:
                                                    "paragraph margin-top-half",
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.t(
                                                          "DATANET_INVOICE_IS_HANDLED_BY_TELIA_FINANCE"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c("telia-p", [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.isHandledByTeliaFinance
                                                        ? _vm.t("DATANET_YES")
                                                        : _vm.t("DATANET_NO")
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    slot:
                                      "step-" +
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t("DATANET_DELIVERY_INFORMATION")
                                      ),
                                  },
                                  slot:
                                    "step-" +
                                    _vm.getIndexOfAsStepInArray(
                                      _vm.stepTitles,
                                      _vm.t("DATANET_DELIVERY_INFORMATION")
                                    ),
                                },
                                [
                                  _vm.currentStep ===
                                  _vm.getIndexOfAsStepInArray(
                                    _vm.stepTitles,
                                    _vm.t("DATANET_DELIVERY_INFORMATION")
                                  )
                                    ? _c(
                                        "div",
                                        [
                                          _c("DeliveryInformation", {
                                            ref: "deliveryInformation",
                                            attrs: { "scope-id": _vm.scopeId },
                                            on: {
                                              "step-complete": function (
                                                $event
                                              ) {
                                                return _vm.handleStepComplete()
                                              },
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "margin__top__24" },
                                            [
                                              _c(
                                                "telia-button",
                                                {
                                                  attrs: {
                                                    "t-id": "next-step",
                                                    type: "button",
                                                    variant: _vm.isNewOrder
                                                      ? "primary"
                                                      : "expressive",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$refs.deliveryInformation.completeStep()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.getStepButtonText()
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.currentStep >
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t("DATANET_DELIVERY_INFORMATION")
                                      )
                                    ? _c(
                                        "Summary",
                                        {
                                          attrs: {
                                            "edit-label": _vm.t("DATANET_EDIT"),
                                          },
                                          on: {
                                            edit: function ($event) {
                                              _vm.goToStep(
                                                _vm.getIndexOfAsStepInArray(
                                                  _vm.stepTitles,
                                                  _vm.t(
                                                    "DATANET_DELIVERY_INFORMATION"
                                                  )
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("telia-p", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.t(
                                                    "DATANET_DELIVERY_ADDRESS"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _vm.deliveryAddress.streetName
                                            ? _c(
                                                "div",
                                                [
                                                  _c("telia-p", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.deliveryAddress
                                                            .streetName +
                                                            " " +
                                                            _vm.deliveryAddress
                                                              .streetNumber +
                                                            (_vm.deliveryAddress
                                                              .entrance || "")
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]),
                                                  _c("telia-p", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.deliveryAddress
                                                            .postalCode +
                                                            " " +
                                                            _vm.deliveryAddress
                                                              .city
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    slot:
                                      "step-" +
                                      _vm.getIndexOfAsStepInArray(
                                        _vm.stepTitles,
                                        _vm.t("DATANET_ORDER_CONFIRMATION")
                                      ),
                                  },
                                  slot:
                                    "step-" +
                                    _vm.getIndexOfAsStepInArray(
                                      _vm.stepTitles,
                                      _vm.t("DATANET_ORDER_CONFIRMATION")
                                    ),
                                },
                                [
                                  _vm.currentStep ===
                                  _vm.getIndexOfAsStepInArray(
                                    _vm.stepTitles,
                                    _vm.t("DATANET_ORDER_CONFIRMATION")
                                  )
                                    ? _c("OrderConfirmation", {
                                        on: {
                                          submitted: function ($event) {
                                            _vm.hasSubmittedOrder = $event
                                          },
                                          submitting: function ($event) {
                                            _vm.isSubmittingOrder = $event
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isChangeOrder ||
                      (_vm.isNewOrder && _vm.isFunctionBusinessOrder)
                        ? _c("section", [
                            _vm.isProductBusinessOrder
                              ? _c(
                                  "div",
                                  [
                                    _c("telia-p", [
                                      _c("strong", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.t("DATANET_ORDER_INFORMATION")
                                            ) +
                                            "\n              "
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "input-wrapper" },
                                      [
                                        _c("telia-text-input", {
                                          attrs: {
                                            id: "order-alias",
                                            "t-id": "order-alias",
                                            label: _vm.t("DATANET_ORDER_ALIAS"),
                                            additional: _vm.t(
                                              "DATANET_ORDER_ALIAS_PLACEHOLDER"
                                            ),
                                            disabled: _vm.isSubmittingOrder,
                                            value: _vm.getOrderAlias,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.$store.dispatch(
                                                "setOrderAlias",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "separator margin__top__24 margin__bottom__24",
                              },
                              [_c("telia-divider")],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "button-wrapper margin__bottom__48",
                              },
                              [
                                _c(
                                  "telia-button",
                                  {
                                    attrs: {
                                      type: "button",
                                      "t-id": "submit-change-order",
                                      variant: "expressive",
                                      size: "sm",
                                      disabled:
                                        _vm.isSubmittingOrder ||
                                        _vm.isTeliaAdmin,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSubmit()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.isProductBusinessOrder
                                            ? _vm.t("DATANET_PLACE_ORDER")
                                            : _vm.t(
                                                "DATANET_SAVE_AND_BACK_TO_SDW"
                                              )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _c(
                                  "telia-button",
                                  {
                                    attrs: {
                                      type: "button",
                                      variant: "secondary",
                                      size: "sm",
                                      disabled:
                                        _vm.isSubmittingOrder ||
                                        _vm.isTeliaAdmin,
                                    },
                                    on: { click: _vm.handleCancelOrder },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.t("DATANET_ABORT")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm.isFunctionBusinessOrder && _vm.saveDraftError
                              ? _c(
                                  "div",
                                  { staticClass: "margin__top__24" },
                                  [
                                    _c(
                                      "telia-notification",
                                      {
                                        attrs: {
                                          id: "save-draft-alert",
                                          "heading-tag": "h4",
                                          variant: "inline",
                                          status: "warning",
                                          "html-aria-live": "polite",
                                          "html-role": "alert",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "heading" },
                                            slot: "heading",
                                          },
                                          [
                                            _c("telia-visually-hidden", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.t("A11Y_STATUS_WARNING")
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.t("SOMETHING_WENT_WRONG")
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c("telia-p", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.saveDraftError) +
                                                  "\n                "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.changeOrderAlert.show
                              ? _c(
                                  "div",
                                  { staticClass: "margin__top__24" },
                                  [
                                    _c(
                                      "telia-notification",
                                      {
                                        attrs: {
                                          id: "change-order-alert",
                                          "heading-tag": "h4",
                                          variant: "inline",
                                          status: "warning",
                                          "html-aria-live": "polite",
                                          "html-role": "alert",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "heading" },
                                            slot: "heading",
                                          },
                                          [
                                            _c("telia-visually-hidden", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.t("A11Y_STATUS_WARNING")
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.t("SOMETHING_WENT_WRONG")
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c("telia-p", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.changeOrderAlert.text
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.hasSubmittedOrder && !_vm.isFunctionBusinessOrder
            ? _c(
                "telia-row",
                [
                  _c(
                    "telia-col",
                    { attrs: { width: "12" } },
                    [
                      _c("OrderSubmitted", {
                        attrs: { "data-test-id": "order-confirmation-info" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.isLoading &&
      !_vm.alert.show &&
      !_vm.isFunctionBusinessOrder &&
      !_vm.hasSubmittedOrder &&
      _vm.primaryAccessBearer &&
      _vm.orgNr
        ? _c("FloatingSaveBar", {
            attrs: {
              "is-saving": _vm.isSavingOrder,
              "has-changes": _vm.isUnsavedDraft,
              "saved-at": _vm.savedAt,
              error: _vm.saveDraftError,
            },
            on: { save: _vm.handleSave },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }