var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "margin__bottom__12" },
        [
          _c("telia-heading", { attrs: { tag: "h3", variant: "title-200" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.t("DATANET_EXTENDED_AVAILABILITY")) +
                "\n    "
            ),
          ]),
          _c("telia-p", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.t("DATANET_EXTENDED_AVAILABILITY_DESCRIPTION")) +
                "\n    "
            ),
          ]),
        ],
        1
      ),
      !_vm.access.enable
        ? _c(
            "div",
            [
              _c("telia-button", {
                attrs: {
                  id: "add-secondary-access",
                  "t-id": "add-secondary-access",
                  type: "button",
                  variant: "secondary",
                  size: "sm",
                  "left-icon": JSON.stringify({ name: "plus", size: "sm" }),
                  text: _vm.t("DATANET_ADD_EXTENDED_AVAILABILITY"),
                },
                on: {
                  click: function ($event) {
                    return _vm.showModal()
                  },
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "Summary",
                {
                  staticClass: "margin__bottom__24",
                  attrs: { "edit-label": _vm.t("DATANET_EDIT") },
                  on: {
                    edit: function ($event) {
                      return _vm.showModal()
                    },
                  },
                },
                [
                  _c("telia-p", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.t("DATANET_SECONDARY_ACCESS"))),
                    ]),
                    _c("br"),
                    _vm.secondaryAddress.streetName
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.secondaryAddress.streetName +
                                  " " +
                                  _vm.secondaryAddress.streetNumber +
                                  (_vm.secondaryAddress.entrance || "")
                              ) +
                              "\n          "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.secondaryAddress.postalCode +
                                  " " +
                                  _vm.secondaryAddress.city
                              ) +
                              "\n          "
                          ),
                          _c("br"),
                          _c("br"),
                        ])
                      : _vm.secondaryAddress.x
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s("X: " + _vm.secondaryAddress.x) +
                              "\n          "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s("Y: " + _vm.secondaryAddress.y) +
                              "\n          "
                          ),
                          _c("br"),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm._v("\n        " + _vm._s(_vm.backupSummaryText)),
                    _c("br"),
                    _vm._v("\n        " + _vm._s(_vm.bearerSummaryText)),
                    _c("br"),
                    _vm.isFttxBearer
                      ? _c("div", [
                          _vm._v(
                            "\n          " + _vm._s(_vm.apartmentSummaryText)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.installationRoomSummaryText)
                          ),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm.isWirelessBearer
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.teliaInstallationSummaryText)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.antennaPackageSummaryText)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " + _vm._s(_vm.antennaCableSummaryText)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.antennaInstallationSummaryText)
                          ),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm._v("\n        " + _vm._s(_vm.bandwidthSummaryText)),
                    _c("br"),
                    _vm._v("\n        " + _vm._s(_vm.slaSummaryText)),
                    _c("br"),
                    _vm._v("\n        " + _vm._s(_vm.qosSummaryText)),
                    _c("br"),
                    _vm._v("\n        " + _vm._s(_vm.statBasSummaryText)),
                    _c("br"),
                  ]),
                ],
                1
              ),
              !_vm.shouldDisableExtendedAvailability
                ? _c("telia-button", {
                    attrs: {
                      id: "delete-secondary-access",
                      type: "button",
                      variant: "secondary",
                      size: "sm",
                      "left-icon": JSON.stringify({
                        name: "close",
                        size: "sm",
                      }),
                      text: _vm.t("DATANET_REMOVE_EXTENDED_AVAILABILITY"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.disableAccess(_vm.Accesses.SECONDARY)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
      _vm.modalIsActive
        ? _c(
            "Modal",
            {
              attrs: {
                "modal-id": "secondary-access-settings-modal",
                width: "m",
                "is-open": _vm.modalIsActive,
              },
              on: {
                closeModal: function ($event) {
                  return _vm.hideModal()
                },
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "modal-body" }, slot: "modal-body" },
                [
                  _c("SecondaryAccessSettings", {
                    on: {
                      complete: function ($event) {
                        return _vm.hideModal()
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }