var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isChangeOrder
        ? _c(
            "div",
            { staticClass: "margin__bottom__24" },
            [
              _c("telia-heading", {
                staticClass: "margin__bottom__12",
                attrs: { tag: "h3", variant: "title-100" },
                domProps: {
                  textContent: _vm._s(
                    _vm.t("DATANET_VPN_EDIT_DHCP_SERVER_CHOICE")
                  ),
                },
              }),
              _c("ActionSelection", {
                attrs: {
                  "selected-action": _vm.dhcpServer.action,
                  "disabled-actions": _vm.disabledActions,
                },
                on: {
                  selected: function ($event) {
                    return _vm.handleActionSelection($event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "margin__bottom__24" },
        [
          _c("telia-p", [
            _c("strong", [
              _vm._v(_vm._s(_vm.t("DATANET_VPN_DHCP_SERVER_NETWORK"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "inputs-container margin__bottom__4" },
            [
              _vm.shouldShowCurrentNetwork(_vm.dhcpServer.key)
                ? _c("telia-text-input", {
                    staticClass: "input-container",
                    attrs: {
                      id: "server-network-current",
                      "t-id": "current-dhcp-server-network",
                      label: _vm.t("DATANET_CURRENT_VALUE"),
                      "validation-state": _vm.v.network.input.current.$error
                        ? "Invalid"
                        : "Valid",
                      value: _vm.dhcpServer.network.input.current,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.setAndValidateCurrentNetwork(
                          $event.target.value
                        )
                      },
                    },
                  })
                : _vm._e(),
              _vm.shouldShowNewNetwork(_vm.dhcpServer.key)
                ? _c("telia-text-input", {
                    staticClass: "input-container",
                    attrs: {
                      "t-id": "new-dhcp-server-network",
                      label: _vm.t("DATANET_NEW_VALUE"),
                      "validation-state": _vm.v.network.input.new.$error
                        ? "Invalid"
                        : "Valid",
                      value: _vm.dhcpServer.network.input.new,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.setAndValidateNewNetwork($event.target.value)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("telia-p", {
            class: _vm.v.network.$anyError
              ? "additional-text-warning"
              : "additional-text",
            attrs: { variant: "additional-100" },
            domProps: {
              textContent: _vm._s(_vm.t("DATANET_NETWORK_PLACEHOLDER")),
            },
          }),
          _vm.shouldShowNetworkAction(_vm.dhcpServer.key)
            ? _c(
                "telia-checkbox",
                {
                  attrs: {
                    "t-id": "change-dhcp-server-network",
                    value: _vm.networkCheckboxValue,
                    checked: _vm.isNetworkCheckboxChecked,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.handleNetworkActionSelection(
                        $event.target.value
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.t("DATANET_VPN_DHCP_SERVER_NETWORK_UPDATE")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.shouldNotDeleteDhcpServer
        ? [
            !_vm.isSiblingDhcpServer ||
            (_vm.isSiblingDhcpServer &&
              _vm.dhcpServer.excludedIpRanges.values.length > 0)
              ? _c("telia-p", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.t("DATANET_VPN_DHCP_SERVER_EXCLUDED_IP_RANGES")
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.dhcpServer.excludedIpRanges.values.length > 0
              ? _c(
                  "SummaryList",
                  [
                    _c(
                      "SummaryRow",
                      { attrs: { variant: "header" } },
                      [
                        _vm.shouldShowCurrentValues(_vm.dhcpServer.key)
                          ? _c(
                              "SummaryColumn",
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(_vm.t("DATANET_CURRENT_VALUE"))
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.shouldShowNewValues(_vm.dhcpServer.key)
                          ? _c(
                              "SummaryColumn",
                              [
                                _c("telia-p", [
                                  _vm._v(_vm._s(_vm.t("DATANET_NEW_VALUE"))),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "SummaryColumn",
                          { attrs: { variant: "small" } },
                          [_c("telia-p", [_vm._v(" ")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(
                      _vm.dhcpServer.excludedIpRanges.values,
                      function (range, index) {
                        return _c(
                          "SummaryRow",
                          { key: "range-" + index },
                          [
                            _vm.shouldShowCurrentValues(_vm.dhcpServer.key)
                              ? _c("SummaryColumn", [
                                  _c(
                                    "span",
                                    {
                                      class:
                                        !_vm.v.excludedIpRanges.values.$each
                                          .$iter[index].start.ipAddress.current
                                          .$pending &&
                                        !_vm.v.excludedIpRanges.values.$each
                                          .$iter[index].end.ipAddress.current
                                          .$pending &&
                                        (_vm.v.excludedIpRanges.values.$each
                                          .$iter[index].start.ipAddress.current
                                          .$invalid ||
                                          _vm.v.excludedIpRanges.values.$each
                                            .$iter[index].end.ipAddress.current
                                            .$invalid)
                                          ? "additional-text-warning"
                                          : "",
                                    },
                                    [
                                      _c("telia-p", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              range.start.ipAddress.current &&
                                                range.end.ipAddress.current
                                                ? range.start.ipAddress
                                                    .current +
                                                    " - " +
                                                    range.end.ipAddress.current
                                                : _vm.v.excludedIpRanges.values
                                                    .$each.$iter[index].start
                                                    .ipAddress.current
                                                    .$invalid ||
                                                  _vm.v.excludedIpRanges.values
                                                    .$each.$iter[index].end
                                                    .ipAddress.current.$invalid
                                                ? _vm.t("DATANET_MISSING_VALUE")
                                                : "-"
                                            ) +
                                            "\n            "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.shouldShowNewValues(_vm.dhcpServer.key)
                              ? _c("SummaryColumn", [
                                  _c(
                                    "span",
                                    {
                                      class:
                                        !_vm.v.excludedIpRanges.values.$each
                                          .$iter[index].start.ipAddress.new
                                          .$pending &&
                                        !_vm.v.excludedIpRanges.values.$each
                                          .$iter[index].end.ipAddress.new
                                          .$pending &&
                                        (_vm.v.excludedIpRanges.values.$each
                                          .$iter[index].start.ipAddress.new
                                          .$invalid ||
                                          _vm.v.excludedIpRanges.values.$each
                                            .$iter[index].end.ipAddress.new
                                            .$invalid)
                                          ? "additional-text-warning"
                                          : "",
                                    },
                                    [
                                      _c("telia-p", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              range.start.ipAddress.new &&
                                                range.end.ipAddress.new
                                                ? range.start.ipAddress.new +
                                                    " - " +
                                                    range.end.ipAddress.new
                                                : _vm.v.excludedIpRanges.values
                                                    .$each.$iter[index].start
                                                    .ipAddress.new.$invalid ||
                                                  _vm.v.excludedIpRanges.values
                                                    .$each.$iter[index].end
                                                    .ipAddress.new.$invalid
                                                ? _vm.t("DATANET_MISSING_VALUE")
                                                : "-"
                                            ) +
                                            "\n            "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _c(
                              "SummaryColumn",
                              {
                                staticClass: "align-end",
                                attrs: { variant: "small" },
                              },
                              [
                                !_vm.isSiblingDhcpServer
                                  ? _c("telia-button", {
                                      attrs: {
                                        size: "sm",
                                        variant: "text",
                                        "right-icon": JSON.stringify({
                                          name: "close",
                                          size: "sm",
                                        }),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.removeExcludedIpRange({
                                            serverKey: _vm.dhcpServer.key,
                                            index: index,
                                          })
                                        },
                                      },
                                    })
                                  : _c("telia-button", {
                                      attrs: {
                                        size: "sm",
                                        variant: "text",
                                        "right-icon": JSON.stringify({
                                          name: "edit",
                                          size: "sm",
                                        }),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.showExcludedIpRangeModal(
                                            index
                                          )
                                        },
                                      },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm.isExcludedIpRangeModalActive
              ? _c(
                  "Modal",
                  {
                    attrs: {
                      "modal-id": "excluded-ip-range-modal",
                      width: "s",
                      "is-open": _vm.isExcludedIpRangeModalActive,
                    },
                    on: {
                      closeModal: function ($event) {
                        return _vm.hideExcludedIpRangeModal()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "excluded-ip-range-wrapper",
                        attrs: { slot: "modal-body" },
                        slot: "modal-body",
                      },
                      [
                        _c("ExcludedIpRange", {
                          attrs: {
                            "dhcp-server-key": _vm.dhcpServer.key,
                            "range-index": _vm.selectedRangeIndex,
                          },
                          on: {
                            complete: function ($event) {
                              return _vm.hideExcludedIpRangeModal()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "margin__bottom__24" },
              [
                !_vm.isSiblingDhcpServer
                  ? _c("telia-button", {
                      attrs: {
                        "t-id": "add-new-excluded-ip-interval-config",
                        variant: "text",
                        "left-icon": JSON.stringify({
                          name: "add",
                          size: "sm",
                        }),
                        text: _vm.t("DATANET_VPN_CONFIGURE_EXCLUDED_IP_RANGE"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showExcludedIpRangeModal()
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            !_vm.isSiblingDhcpServer ||
            (_vm.isSiblingDhcpServer &&
              _vm.dhcpServer.excludedIpRanges.values.length > 0)
              ? _c("telia-p", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.t("DATANET_VPN_DHCP_DNS_IP"))),
                  ]),
                ])
              : _vm._e(),
            _vm.dhcpServer.dnsIps.values.length > 0
              ? _c(
                  "SummaryList",
                  [
                    _c(
                      "SummaryRow",
                      { attrs: { variant: "header" } },
                      [
                        _vm.shouldShowCurrentValues(_vm.dhcpServer.key)
                          ? _c(
                              "SummaryColumn",
                              [
                                _c("telia-p", [
                                  _vm._v(
                                    _vm._s(_vm.t("DATANET_CURRENT_VALUE"))
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.shouldShowNewValues(_vm.dhcpServer.key)
                          ? _c(
                              "SummaryColumn",
                              [
                                _c("telia-p", [
                                  _vm._v(_vm._s(_vm.t("DATANET_NEW_VALUE"))),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("SummaryColumn", { attrs: { variant: "small" } }, [
                          _vm._v("   "),
                        ]),
                      ],
                      1
                    ),
                    _vm._l(_vm.dhcpServer.dnsIps.values, function (ip, index) {
                      return _c(
                        "SummaryRow",
                        { key: "range-" + index },
                        [
                          _vm.shouldShowCurrentValues(_vm.dhcpServer.key)
                            ? _c("SummaryColumn", [
                                _c(
                                  "span",
                                  {
                                    class:
                                      !_vm.v.dnsIps.values.$each.$iter[index]
                                        .ipAddress.current.$pending &&
                                      _vm.v.dnsIps.values.$each.$iter[index]
                                        .ipAddress.current.$invalid
                                        ? "additional-text-warning"
                                        : "",
                                  },
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            ip.ipAddress.current
                                              ? "" + ip.ipAddress.current
                                              : _vm.v.dnsIps.values.$each.$iter[
                                                  index
                                                ].ipAddress.current.$invalid
                                              ? _vm.t("DATANET_MISSING_VALUE")
                                              : "-"
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.shouldShowNewValues(_vm.dhcpServer.key)
                            ? _c("SummaryColumn", [
                                _c(
                                  "span",
                                  {
                                    class:
                                      !_vm.v.dnsIps.values.$each.$iter[index]
                                        .ipAddress.new.$pending &&
                                      _vm.v.dnsIps.values.$each.$iter[index]
                                        .ipAddress.new.$invalid
                                        ? "additional-text-warning"
                                        : "",
                                  },
                                  [
                                    _c("telia-p", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            ip.ipAddress.new
                                              ? "" + ip.ipAddress.new
                                              : _vm.v.dnsIps.values.$each.$iter[
                                                  index
                                                ].ipAddress.new.$invalid
                                              ? _vm.t("DATANET_MISSING_VALUE")
                                              : "-"
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "SummaryColumn",
                            {
                              staticClass: "align-end",
                              attrs: { variant: "small" },
                            },
                            [
                              !_vm.isSiblingDhcpServer
                                ? _c("telia-button", {
                                    attrs: {
                                      size: "sm",
                                      variant: "text",
                                      "right-icon": JSON.stringify({
                                        name: "close",
                                        size: "sm",
                                      }),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.removeDnsIp({
                                          serverKey: _vm.dhcpServer.key,
                                          index: index,
                                        })
                                      },
                                    },
                                  })
                                : _c("telia-button", {
                                    attrs: {
                                      size: "sm",
                                      variant: "text",
                                      "right-icon": JSON.stringify({
                                        name: "edit",
                                        size: "sm",
                                      }),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.showDnsIpModal(index)
                                      },
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "Modal",
              {
                attrs: {
                  "modal-id": "dns-ip-modal",
                  width: "s",
                  "is-open": _vm.isDnsIpModalActive,
                },
                on: {
                  closeModal: function ($event) {
                    return _vm.hideDnsIpModal()
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "excluded-ip-range-wrapper",
                    attrs: { slot: "modal-body" },
                    slot: "modal-body",
                  },
                  [
                    _c("DnsIp", {
                      attrs: {
                        "dhcp-server-key": _vm.dhcpServer.key,
                        "dns-index": _vm.selectedDnsIndex,
                      },
                      on: {
                        complete: function ($event) {
                          return _vm.hideDnsIpModal()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "margin__bottom__24" },
              [
                !_vm.isSiblingDhcpServer
                  ? _c("telia-button", {
                      attrs: {
                        "t-id": "add-new-dns-ip-config",
                        variant: "text",
                        "left-icon": JSON.stringify({
                          name: "add",
                          size: "sm",
                        }),
                        text: _vm.t("DATANET_VPN_CONFIGURE_DNS_IP"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showDnsIpModal()
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }