const PLACEHOLDER_OPTION_INDEX = 0;

export default function (value, options) {
  if (typeof value !== "string" && typeof value !== "number") return PLACEHOLDER_OPTION_INDEX;
  if (!Array.isArray(options) || !options.length) return PLACEHOLDER_OPTION_INDEX;

  if (typeof value === "string") {
    value = value.toUpperCase();
  }

  const index = options.findIndex((option) => {
    const optionValue =
      typeof option.value === "string" ? option.value.toUpperCase() : option.value;
    return value === optionValue;
  });
  return index === -1 ? PLACEHOLDER_OPTION_INDEX : index;
}
