var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isProductBusinessOrder
        ? _c(
            "Summary",
            { staticClass: "margin-bottom__24", attrs: { editable: false } },
            [
              _c("telia-p", [
                _c("strong", [_vm._v(_vm._s(_vm.t("DATANET_ORDER_CONTACT")))]),
              ]),
              _c("telia-p", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.orderContact.firstName +
                        " " +
                        _vm.orderContact.lastName
                    ) +
                    "\n    "
                ),
              ]),
              _c("telia-p", [
                _vm._v(
                  "\n      " + _vm._s(_vm.orderContact.emailAddress) + "\n    "
                ),
              ]),
              _c("telia-p", [
                _vm._v(
                  "\n      " + _vm._s(_vm.orderContact.phoneNumber) + "\n    "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.contactIds, function (contactId) {
        return _c(
          "div",
          { key: contactId },
          [
            _vm.activeTooltip === contactId
              ? _c(
                  "InformationBox",
                  {
                    on: {
                      close: function ($event) {
                        return _vm.setActiveTooltip(null)
                      },
                    },
                  },
                  [
                    _c("ContactTooltip", {
                      attrs: { "show-contact-info-by-id": contactId },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("Contact", {
              ref: "contacts",
              refInFor: true,
              attrs: {
                "contact-id": contactId,
                "active-tooltip": _vm.activeTooltip,
              },
              on: {
                activeTooltip: function ($event) {
                  return _vm.setActiveTooltip($event)
                },
              },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }