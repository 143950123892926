import Vue from "vue";
import updateExistingObjectProperties from "../../helpers/updateExistingObjectProperties.js";
import {
  Actions,
  Accesses,
  Backups,
  BandwidthDistributions,
  Bearers,
  MetadataKeys,
  DefaultValues,
} from "../../constants";
import defaultChangeableValue from "../defaultChangeableValue.js";
import defaultNetwork from "../defaultNetwork.js";

const defaultState = () => ({
  primary: Object.assign(defaultAccess(), {
    vpns: [],
  }),
  temporary: defaultTemporaryAccess(),
  secondary: defaultSecondaryAccess(),
});

const defaultAccess = () => ({
  bandwidth: defaultChangeableValue(),
  sla: defaultChangeableValue(),
  qos: defaultChangeableValue(),
  options: {
    wirelessInstallation: null,
    trafficStatistics: {
      current: false,
      new: false,
    },
    antenna: {
      package: "ANTENNA_PACKAGE_NONE",
      cable: false,
      installation: false,
    },
    snmp: {
      action: "",
      communities: [],
    },
    internetOnSite: defaultInternetOnSite(),
  },
});

const defaultTemporaryAccess = () => ({
  ...defaultAccess(),
  enable: false,
  fastTrack: false,
  bearer: Bearers.WIRELESS,
  useAsSecondaryAccess: null,
  bandwidth: defaultChangeableValue({ new: "00" }),
});

const defaultSecondaryAccess = () => ({
  ...defaultAccess(),
  enable: false,
  backup: Backups.BACKUP,
  vpns: [],
});

const defaultCommunity = () => ({
  action: "",
  communityName: defaultChangeableValue(),
  networks: [],
});

const defaultInternetOnSite = () => ({
  action: null,
  ripeNumber: null,
  lanInterface: null,
  lanConfig: {
    capacity: {
      dynamic: {
        action: null,
        current: null,
        new: true,
      },
      bandwidth: {
        action: null,
        current: null,
        new: null,
      },
    },
    ethernetInterface: {
      action: null,
      current: 0,
      new: 0,
    },
  },
});

const state = defaultState();

const getters = {
  getAccess: (state) => (accessKey) => state[accessKey],

  getQos: (state) => (accessKey) => state[accessKey].qos,

  includeAccessConfigurationStep: (state, getters, rootState, rootGetters) =>
    getters.shouldShowPrimaryAccessBandwidth ||
    getters.shouldShowPrimaryAccessSla ||
    getters.shouldShowPrimaryAccessQos ||
    getters.shouldShowPrimaryAccessTrafficStatistics ||
    getters.shouldShowAccessOptions ||
    getters.shouldShowPrimaryAccessSnmp ||
    getters.shouldShowPrimaryAccessAntennaPackage ||
    rootGetters["products/shouldShowBearerSelection"] ||
    rootGetters["products/shouldShowExtendedAvailability"] ||
    rootGetters["products/shouldShowQuickLaunch"],

  shouldShowPrimaryAccessApartment: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.APARTMENT_NUMBER),

  shouldShowPrimaryAccessBandwidth: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.ACCESS_BANDWIDTH),

  shouldShowPrimaryAccessSla: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.ACCESS_SLA),

  shouldShowPrimaryAccessQos: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.ACCESS_QOS),

  shouldShowPrimaryAccessTrafficStatistics: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.TRAFFIC_STATISTICS),

  shouldShowSecondaryAccessTrafficStatistics: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.SECONDARY_TRAFFIC_STATISTICS),

  shouldShowAccessOptions: (state, getters) =>
    getters.shouldShowPrimaryAccessAntennaPackage || getters.shouldShowPrimaryAccessSnmp,

  shouldShowPrimaryAccessSnmp: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.SNMP),

  shouldShowPrimaryAccessInternetOnSite: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.INTERNET_ON_SITE) &&
    !rootGetters["products/isCopperAccess"] &&
    !rootGetters["vpn/isStaticBandwidthDistribution"](Accesses.PRIMARY) &&
    !rootGetters.isFunctionBusinessOrder,

  isPrimaryAccessInternetOnSiteEditable: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isEditable"](MetadataKeys.INTERNET_ON_SITE),

  shouldShowPrimaryAccessAntennaPackage: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.WIRELESS_ANTENNA) &&
    rootGetters["products/isWirelessAccess"],

  shouldShowPrimaryAccessWirelessInstallation: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isVisible"](MetadataKeys.WIRELESS_INSTALLATION) &&
    rootGetters["products/isWirelessAccess"],

  shouldValidatePrimaryAccessApartment: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isEditable"](MetadataKeys.APARTMENT_NUMBER) &&
    rootGetters["products/isFttxAccess"],

  shouldDisablePrimaryAccessApartment: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isVisible"](MetadataKeys.APARTMENT_NUMBER),

  shouldDisablePrimaryAccessBandwidth: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.ACCESS_BANDWIDTH),

  shouldDisablePrimaryAccessSla: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.ACCESS_SLA),

  shouldDisablePrimaryAccessQos: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.ACCESS_QOS) ||
    !state[Accesses.PRIMARY].bandwidth.new,

  shouldDisablePrimaryAccessTrafficStatistics: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.TRAFFIC_STATISTICS),

  shouldDisablePrimaryAccessSnmp: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isVisible"](MetadataKeys.SNMP),

  shouldDisablePrimaryAccessAntennaPackage: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isVisible"](MetadataKeys.WIRELESS_ANTENNA),

  shouldDisablePrimaryAccessWirelessInstallation: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.WIRELESS_INSTALLATION),

  shouldValidatePrimaryAccessAntennaPackage: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isEditable"](MetadataKeys.WIRELESS_ANTENNA) &&
    rootGetters["products/isWirelessAccess"],

  shouldValidatePrimaryAccessBandwidth: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isEditable"](MetadataKeys.ACCESS_BANDWIDTH),

  shouldValidatePrimaryAccessSla: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isEditable"](MetadataKeys.ACCESS_SLA) && !rootGetters.isChangeOrder,

  shouldValidatePrimaryAccessQos: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isEditable"](MetadataKeys.ACCESS_QOS),

  shouldValidatePrimaryAccessSnmp: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isEditable"](MetadataKeys.SNMP),

  isSnmpActionEditable: (state, getters, rootState, rootGetters) =>
    rootGetters["metadata/isActionEditable"](MetadataKeys.SNMP),

  shouldDisableExtendedAvailability: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.EXTENDED_AVAILABILITY),

  shouldDisableSecondaryAccessBandwidth: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.SECONDARY_ACCESS_BANDWIDTH),

  shouldDisableSecondaryAccessSla: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.SECONDARY_ACCESS_SLA),

  shouldDisableSecondaryAccessQos: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.SECONDARY_ACCESS_QOS),

  shouldDisableSecondaryAccessTrafficStatistics: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.SECONDARY_TRAFFIC_STATISTICS),

  shouldDisableSecondaryAccessWirelessInstallation: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.SECONDARY_ACCESS_WIRELESS_INSTALLATION),

  shouldDisableSecondaryAccessAntennaPackage: (state, getters, rootState, rootGetters) =>
    !rootGetters["metadata/isEditable"](MetadataKeys.SECONDARY_ACCESS_WIRELESS_ANTENNA),

  hasBackupAccess: (state) => state[Accesses.SECONDARY].enable,

  hasBackupTypeBackup: (state) => state[Accesses.SECONDARY].backup === Backups.BACKUP,

  hasBackupTypeBackupCombo: (state) => state[Accesses.SECONDARY].backup === Backups.BACKUP_COMBO,

  hasBackupTypeDiversity: (state) => state[Accesses.SECONDARY].backup === Backups.DIVERSITY,

  hasBackupTypeRedundancy: (state) => state[Accesses.SECONDARY].backup === Backups.REDUNDANCY,

  getSnmpCommunities: (state) => state[Accesses.PRIMARY].options.snmp.communities || [],
  getSnmpCommunity: (state) => (communityIndex) =>
    state[Accesses.PRIMARY].options.snmp.communities[communityIndex] || {},
  getSnmpCommunityName: (state) => (communityIndex) =>
    state[Accesses.PRIMARY].options.snmp.communities[communityIndex].communityName || {},
  getSnmpNetworks: (state) => (communityIndex) =>
    state[Accesses.PRIMARY].options.snmp.communities[communityIndex].networks || {},
  getSnmpNetwork: (state) => (communityIndex, networkIndex) =>
    state[Accesses.PRIMARY].options.snmp.communities[communityIndex].networks[networkIndex] || {},
};

const actions = {
  setBandwidth(
    { state, getters, dispatch, commit, rootGetters },
    { accessKey, new: _new, current }
  ) {
    const access = getters.getAccess(accessKey);
    if (!access || access.bandwidth.new === _new) return;

    const updatedBandwidth = updateExistingObjectProperties(access.bandwidth, {
      current,
      new: _new,
    });
    if (!updatedBandwidth) return;

    commit("SET_BANDWIDTH", { accessKey, bandwidth: updatedBandwidth });
    dispatch("setUnsavedDraft", undefined, { root: true });
    dispatch("setQos", { accessKey, new: "" });

    const isPrimaryAccess = accessKey === Accesses.PRIMARY;
    if (isPrimaryAccess) {
      dispatch("vpn/resetAllVpns", undefined, {
        root: true,
      });
    }

    if (isPrimaryAccess) {
      dispatch("vpn/resetAllVpns", undefined, {
        root: true,
      });
    }

    const isSecondaryAccess = accessKey === Accesses.SECONDARY;
    if (isSecondaryAccess) {
      dispatch("vpn/resetVpnsForAccess", Accesses.SECONDARY, {
        root: true,
      });
    }

    const isTemporaryAccess = accessKey === Accesses.TEMPORARY;
    const useTemporaryAccessAsSecondaryAccess = state[Accesses.TEMPORARY].useAsSecondaryAccess;
    if (isTemporaryAccess && useTemporaryAccessAsSecondaryAccess) {
      dispatch("setBandwidth", {
        accessKey: Accesses.SECONDARY,
        ...updatedBandwidth,
      });
    }

    const isSecondaryAccessEnabled = state[Accesses.SECONDARY].enable;
    if (isPrimaryAccess && isSecondaryAccessEnabled && !useTemporaryAccessAsSecondaryAccess) {
      dispatch("access/resetSecondaryAccess", undefined, { root: true });
    }

    const bearer = rootGetters["products/getBearer"](accessKey);
    const isDynamicBandwidthDistributionAllowed = rootGetters[
      "products/getDynamicAllowedForAccessAndBandwidth"
    ](bearer, _new);
    if (rootGetters["vpn/nrOfVpns"] > 1 && !isDynamicBandwidthDistributionAllowed) {
      dispatch(
        "vpn/setBandwidthDistribution",
        { accessKey, new: BandwidthDistributions.STATIC },
        { root: true }
      );
    }
  },

  setSla({ state, dispatch, commit }, { accessKey = "", current, new: _new }) {
    const access = state[accessKey];
    if (!access || access.sla.new === _new) return;

    const updatedSla = updateExistingObjectProperties(access.sla, {
      current,
      new: _new,
    });
    if (!updatedSla) return;

    commit("SET_SLA", { accessKey, sla: updatedSla });
    dispatch("setUnsavedDraft", undefined, { root: true });

    const isTemporaryAccess = accessKey === Accesses.TEMPORARY;
    const useTemporaryAccessAsSecondaryAccess = state[Accesses.TEMPORARY].useAsSecondaryAccess;
    if (isTemporaryAccess && useTemporaryAccessAsSecondaryAccess) {
      dispatch("setSla", {
        accessKey: Accesses.SECONDARY,
        ...updatedSla,
      });
    }
  },

  setQos({ state, dispatch, commit }, { accessKey = "", new: _new, current }) {
    const access = state[accessKey];
    if (!access || access.qos.new === _new) return;

    const updatedQos = updateExistingObjectProperties(access.qos, {
      current,
      new: _new,
    });
    if (!updatedQos) return;

    commit("SET_QOS", { accessKey, qos: updatedQos });
    dispatch("setUnsavedDraft", undefined, { root: true });

    const isPrimaryAccess = accessKey === Accesses.PRIMARY;
    const isSecondaryAccess = accessKey === Accesses.SECONDARY;
    const isTemporaryAccess = accessKey === Accesses.TEMPORARY;
    const isSecondaryAccessEnabled = state[Accesses.SECONDARY].enable;
    const useTemporaryAccessAsSecondaryAccess = state[Accesses.TEMPORARY].useAsSecondaryAccess;

    if (isPrimaryAccess) {
      dispatch("vpn/resetAllVpns", undefined, {
        root: true,
      });
    }

    if (isSecondaryAccess) {
      dispatch("vpn/resetVpnsForAccess", Accesses.SECONDARY, {
        root: true,
      });
    }

    if (isTemporaryAccess && useTemporaryAccessAsSecondaryAccess) {
      dispatch("setQos", {
        accessKey: Accesses.SECONDARY,
        ...updatedQos,
      });
    }

    if (isPrimaryAccess && isSecondaryAccessEnabled && !useTemporaryAccessAsSecondaryAccess) {
      dispatch("access/resetSecondaryAccess", undefined, { root: true });
    }
  },

  setBackup({ dispatch, commit, state }, backup = "") {
    if (state[Accesses.SECONDARY].backup === backup) return;

    commit("SET_BACKUP", backup);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  enableAccess({ dispatch, commit, state }, accessKey = "") {
    const access = state[accessKey];
    if (!access || access.enable) return;

    commit("SET_ENABLE", { accessKey, boolean: true });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  disableAccess({ dispatch, commit, state, rootGetters }, accessKey = "") {
    const access = state[accessKey];
    if (!access || !access.enable) return;

    commit("SET_ENABLE", { accessKey, boolean: false });
    dispatch("setUnsavedDraft", undefined, { root: true });

    rootGetters["lans/getAllLans"].forEach((lan) =>
      lan.networks.forEach((network, index) =>
        dispatch(
          "lans/setNetworkVirtualIp",
          {
            lanKey: lan.key,
            index,
            action: Actions.NONE,
            current: "",
            new: "",
          },
          { root: true }
        )
      )
    );

    if (accessKey === Accesses.TEMPORARY) {
      commit("SET_TEMP_ACCESS_AS_SECONDARY", null);
      dispatch("setUnsavedDraft", undefined, { root: true });
    }
  },

  enableWirelessInstallation({ commit, dispatch, state }, accessKey = "") {
    const access = state[accessKey];
    if (!access || access.options.wirelessInstallation) return;

    commit("SET_WIRELESS_INSTALLATION", { accessKey, boolean: true });
    dispatch("setUnsavedDraft", undefined, { root: true });

    const isTemporaryAccess = accessKey === Accesses.TEMPORARY;
    const useTemporaryAccessAsSecondaryAccess = state[Accesses.TEMPORARY].useAsSecondaryAccess;
    if (isTemporaryAccess && useTemporaryAccessAsSecondaryAccess) {
      dispatch("enableWirelessInstallation", Accesses.SECONDARY);
    }
  },

  disableWirelessInstallation({ commit, dispatch, state }, accessKey = "") {
    const access = state[accessKey];
    if (!access || !access.options.wirelessInstallation) return;

    commit("SET_WIRELESS_INSTALLATION", { accessKey, boolean: false });
    dispatch("setUnsavedDraft", undefined, { root: true });

    const isTemporaryAccess = accessKey === Accesses.TEMPORARY;
    const useTemporaryAccessAsSecondaryAccess = state[Accesses.TEMPORARY].useAsSecondaryAccess;
    if (isTemporaryAccess && useTemporaryAccessAsSecondaryAccess) {
      dispatch("disableWirelessInstallation", Accesses.SECONDARY);
    }
  },

  setAntennaPackage({ dispatch, commit, state }, { accessKey = "", name = "" }) {
    const access = state[accessKey];
    if (!access || access.options.antenna.package === name) return;

    commit("SET_ANTENNA_PACKAGE", { accessKey, name });
    dispatch("setUnsavedDraft", undefined, { root: true });

    const isTemporaryAccess = accessKey === Accesses.TEMPORARY;
    const useTemporaryAccessAsSecondaryAccess = state[Accesses.TEMPORARY].useAsSecondaryAccess;
    if (isTemporaryAccess && useTemporaryAccessAsSecondaryAccess) {
      dispatch("setAntennaPackage", {
        accessKey: Accesses.SECONDARY,
        name,
      });
    }
  },

  setAntennaCable({ dispatch, commit, state }, { accessKey = "", boolean = false }) {
    const access = state[accessKey];
    if (!access || access.options.antenna.cable === boolean) return;

    commit("SET_ANTENNA_CABLE", { accessKey, boolean });
    dispatch("setUnsavedDraft", undefined, { root: true });

    const isTemporaryAccess = accessKey === Accesses.TEMPORARY;
    const useTemporaryAccessAsSecondaryAccess = state[Accesses.TEMPORARY].useAsSecondaryAccess;
    if (isTemporaryAccess && useTemporaryAccessAsSecondaryAccess) {
      dispatch("setAntennaCable", {
        accessKey: Accesses.SECONDARY,
        boolean,
      });
    }
  },

  setAntennaInstallation({ dispatch, commit, state }, { accessKey = "", boolean = false }) {
    const access = state[accessKey];
    if (!access || access.options.antenna.installation === boolean) return;

    commit("SET_ANTENNA_INSTALLATION", { accessKey, boolean });
    dispatch("setUnsavedDraft", undefined, { root: true });

    const isTemporaryAccess = accessKey === Accesses.TEMPORARY;
    const useTemporaryAccessAsSecondaryAccess = state[Accesses.TEMPORARY].useAsSecondaryAccess;
    if (isTemporaryAccess && useTemporaryAccessAsSecondaryAccess) {
      dispatch("setAntennaInstallation", {
        accessKey: Accesses.SECONDARY,
        boolean,
      });
    }
  },

  enableFastTrack({ dispatch, commit, state }) {
    if (state[Accesses.TEMPORARY].fastTrack) return;

    commit("SET_FAST_TRACK", true);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  disableFastTrack({ dispatch, commit, state }) {
    if (!state[Accesses.TEMPORARY].fastTrack) return;

    commit("SET_FAST_TRACK", false);
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setTrafficStatistics({ getters, dispatch, commit }, { accessKey, new: _new, current }) {
    const access = getters.getAccess(accessKey);
    if (!access) return;

    const updatedTrafficStatistics = updateExistingObjectProperties(
      access.options.trafficStatistics,
      {
        current,
        new: _new,
      }
    );
    if (!updatedTrafficStatistics) return;

    commit("SET_TRAFFIC_STATISTICS", {
      accessKey,
      trafficStatistics: updatedTrafficStatistics,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  useTemporaryAccessAsSecondary({ commit, dispatch, state }) {
    // '=== true' since useAsSecondaryAccess can be null or true
    const tempAccess = state[Accesses.TEMPORARY];
    if (tempAccess.useAsSecondaryAccess === true) return;

    commit("SET_TEMP_ACCESS_AS_SECONDARY", true);
    dispatch("setUnsavedDraft", undefined, { root: true });

    dispatch("resetSecondaryAccess");
    dispatch("products/setSecondaryBearer", Bearers.WIRELESS, {
      root: true,
    });
    dispatch("setBackup", Backups.BACKUP);
    dispatch("setBandwidth", {
      accessKey: Accesses.SECONDARY,
      ...tempAccess.bandwidth,
    });
    dispatch("setSla", {
      accessKey: Accesses.SECONDARY,
      ...tempAccess.sla,
    });
    dispatch("setQos", {
      accessKey: Accesses.SECONDARY,
      ...tempAccess.qos,
    });
    tempAccess.options.wirelessInstallation
      ? dispatch("enableWirelessInstallation", Accesses.SECONDARY)
      : dispatch("disableWirelessInstallation", Accesses.SECONDARY);
    dispatch("setAntennaPackage", {
      accessKey: Accesses.SECONDARY,
      name: tempAccess.options.antenna.package,
    });
    dispatch("setAntennaCable", {
      accessKey: Accesses.SECONDARY,
      boolean: tempAccess.options.antenna.cable,
    });
    dispatch("setAntennaInstallation", {
      accessKey: Accesses.SECONDARY,
      boolean: tempAccess.options.antenna.installation,
    });
  },

  terminateTemporaryAccessOnDeliveryOfPrimary({ commit, dispatch, state }) {
    // '=== false' since useAsSecondaryAccess can be null or true
    const tempAccess = state[Accesses.TEMPORARY];
    if (!tempAccess || tempAccess.useAsSecondaryAccess === false) return;

    commit("SET_TEMP_ACCESS_AS_SECONDARY", false);
    dispatch("setUnsavedDraft", undefined, { root: true });
    dispatch("resetSecondaryAccess");
  },

  addVpn({ dispatch, commit }, { accessKey = "", vpnKey = "" }) {
    const access = state[accessKey];
    if (!access || access.vpns[vpnKey]) return;

    commit("ADD_VPN", { accessKey, vpnKey });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  removeVpn({ dispatch, commit }, { accessKey = "", vpnKey = "" }) {
    const access = state[accessKey];
    if (!access || access.vpns[vpnKey]) return;

    commit("REMOVE_VPN", { accessKey, vpnKey });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setSnmpActionAdd({ commit }, accessKey = "") {
    commit("SET_SNMP_ACTION_ADD", accessKey);
  },

  setSnmpActionUpdate({ commit }, accessKey = "") {
    commit("SET_SNMP_ACTION_UPDATE", accessKey);
  },

  setSnmpActionDelete({ commit }, accessKey = "") {
    commit("SET_SNMP_ACTION_DELETE", accessKey);
  },

  setSnmpActionNone({ commit }, accessKey = "") {
    commit("SET_SNMP_ACTION_NONE", accessKey);
  },

  addSnmpCommunity({ state, dispatch, commit }, accessKey = "") {
    const access = state[accessKey];
    if (!access) return;

    commit("ADD_SNMP_COMMUNITY", accessKey);
    dispatch("setUnsavedDraft", undefined, { root: true });
    const addedCommunityIndex = state[accessKey].options.snmp.communities.length - 1;
    return addedCommunityIndex;
  },

  setSnmpCommunityActionAdd({ commit }, { accessKey = "", communityIndex = DefaultValues.NONE }) {
    commit("SET_SNMP_COMMUNITY_ACTION_ADD", { accessKey, communityIndex });
  },

  setSnmpCommunityActionUpdate(
    { commit },
    { accessKey = "", communityIndex = DefaultValues.NONE }
  ) {
    commit("SET_SNMP_COMMUNITY_ACTION_UPDATE", { accessKey, communityIndex });
  },

  setSnmpCommunityActionDelete(
    { commit },
    { accessKey = "", communityIndex = DefaultValues.NONE }
  ) {
    commit("SET_SNMP_COMMUNITY_ACTION_DELETE", {
      accessKey,
      communityIndex,
    });
  },

  setSnmpCommunityActionNone({ commit }, { accessKey = "", communityIndex = DefaultValues.NONE }) {
    commit("SET_SNMP_COMMUNITY_ACTION_NONE", { accessKey, communityIndex });
  },

  removeSnmpCommunity(
    { dispatch, commit },
    { accessKey = "", communityIndex = DefaultValues.NONE }
  ) {
    commit("REMOVE_SNMP_COMMUNITY", { accessKey, communityIndex });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setSnmpCommunityName(
    { dispatch, commit },
    { accessKey = "", communityIndex = DefaultValues.NONE, communityName = {} }
  ) {
    commit("SET_SNMP_COMMUNITY_NAME", {
      accessKey,
      communityIndex,
      communityName,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setSnmpNetworkActionAdd(
    { commit },
    { accessKey = "", communityIndex = DefaultValues.NONE, networkIndex = DefaultValues.NONE }
  ) {
    commit("SET_SNMP_NETWORK_ACTION_ADD", {
      accessKey,
      communityIndex,
      networkIndex,
    });
  },

  setSnmpNetworkActionUpdate(
    { commit },
    { accessKey = "", communityIndex = DefaultValues.NONE, networkIndex = DefaultValues.NONE }
  ) {
    commit("SET_SNMP_NETWORK_ACTION_UPDATE", {
      accessKey,
      communityIndex,
      networkIndex,
    });
  },

  setSnmpNetworkActionDelete(
    { commit },
    { accessKey = "", communityIndex = DefaultValues.NONE, networkIndex = DefaultValues.NONE }
  ) {
    commit("SET_SNMP_NETWORK_ACTION_DELETE", {
      accessKey,
      communityIndex,
      networkIndex,
    });
  },

  setSnmpNetworkActionNone(
    { commit },
    { accessKey = "", communityIndex = DefaultValues.NONE, networkIndex = DefaultValues.NONE }
  ) {
    commit("SET_SNMP_NETWORK_ACTION_NONE", {
      accessKey,
      communityIndex,
      networkIndex,
    });
  },

  addSnmpNetwork(
    { state, dispatch, commit },
    { accessKey = "", communityIndex = DefaultValues.NONE }
  ) {
    commit("ADD_SNMP_NETWORK", { accessKey, communityIndex });
    dispatch("setUnsavedDraft", undefined, { root: true });
    const addedNetworkIndex =
      state[Accesses.PRIMARY].options.snmp.communities[communityIndex].networks.length - 1;
    return addedNetworkIndex;
  },

  removeSnmpNetwork(
    { dispatch, commit },
    { accessKey = "", communityIndex = DefaultValues.NONE, networkIndex = DefaultValues.NONE }
  ) {
    commit("REMOVE_SNMP_NETWORK", {
      accessKey,
      communityIndex,
      networkIndex,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setSnmpNetwork(
    { state, dispatch, commit },
    {
      accessKey = Accesses.PRIMARY,
      communityIndex = DefaultValues.NONE,
      networkIndex = DefaultValues.NONE,
      network = {},
    }
  ) {
    const currentNetwork =
      state[accessKey].options.snmp.communities[communityIndex].networks[networkIndex];

    if (!currentNetwork) {
      return;
    }

    commit("SET_SNMP_NETWORK", {
      accessKey,
      communityIndex,
      networkIndex,
      network: Object.assign(currentNetwork, network),
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setSnmpNetworkInput(
    { dispatch, commit },
    {
      accessKey = "",
      communityIndex = DefaultValues.NONE,
      networkIndex = DefaultValues.NONE,
      input = {},
    }
  ) {
    commit("SET_SNMP_NETWORK_INPUT", {
      accessKey,
      communityIndex,
      networkIndex,
      input,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setInternetOnSiteAction({ dispatch, commit }, { accessKey = "", action = "" }) {
    commit("SET_INTERNET_ON_SITE_ACTION", { accessKey, action });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setInternetOnSiteRipeNumber({ dispatch, commit }, { accessKey = "", ripeNumber = "" }) {
    commit("SET_INTERNET_ON_SITE_RIPE_NUMBER", { accessKey, ripeNumber });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setInternetOnSiteLanInterface({ dispatch, commit }, { accessKey = "", lanInterface = "" }) {
    commit("SET_INTERNET_ON_SITE_LAN_INTERFACE", { accessKey, lanInterface });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setInternetOnSiteUseDynamicCapacity(
    { dispatch, commit },
    { accessKey = "", useDynamicCapacity = {} }
  ) {
    commit("SET_INTERNET_ON_SITE_USE_DYNAMIC_CAPACITY", { accessKey, useDynamicCapacity });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setInternetOnSiteStaticBandwidth({ dispatch, commit }, { accessKey = "", staticBandwidth = {} }) {
    commit("SET_INTERNET_ON_SITE_STATIC_BANDWIDTH", { accessKey, staticBandwidth });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  setInternetOnSiteExtraEthernetInterfaces(
    { dispatch, commit },
    { accessKey = "", extraEthernetInterfaces = {} }
  ) {
    commit("SET_INTERNET_ON_SITE_EXTRA_ETHERNET_INTERFACES", {
      accessKey,
      extraEthernetInterfaces,
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  resetInternetOnSite({ dispatch, commit }) {
    commit("RESET_INTERNET_ON_SITE", {
      accessKey: Accesses.PRIMARY,
      internetOnSite: defaultInternetOnSite(),
    });
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  resetTemporaryAccess({ dispatch, commit }) {
    commit("RESET_TEMPORARY_ACCESS", defaultTemporaryAccess());
    dispatch("setUnsavedDraft", undefined, { root: true });
  },

  resetSecondaryAccess({ dispatch, commit }) {
    commit("RESET_SECONDARY_ACCESS", defaultSecondaryAccess());
    dispatch("setUnsavedDraft", undefined, { root: true });
  },
};

const mutations = {
  SET_BANDWIDTH(state, { accessKey = "", bandwidth = {} }) {
    Vue.set(state[accessKey], "bandwidth", bandwidth);
  },

  SET_SLA(state, { accessKey = "", sla = {} }) {
    Vue.set(state[accessKey], "sla", sla);
  },

  SET_QOS(state, { accessKey = "", qos = {} }) {
    Vue.set(state[accessKey], "qos", qos);
  },

  SET_BACKUP(state, backup = "") {
    state[Accesses.SECONDARY].backup = backup;
  },

  SET_ENABLE(state, { accessKey, boolean }) {
    state[accessKey].enable = boolean;
  },

  SET_FAST_TRACK(state, payload) {
    state[Accesses.TEMPORARY].fastTrack = payload;
  },

  SET_WIRELESS_INSTALLATION(state, { accessKey = "", boolean }) {
    state[accessKey].options.wirelessInstallation = boolean;
  },

  SET_ANTENNA_PACKAGE(state, { accessKey = "", name = "" }) {
    state[accessKey].options.antenna.package = name;
  },

  SET_ANTENNA_CABLE(state, { accessKey = "", boolean = false }) {
    state[accessKey].options.antenna.cable = boolean;
  },

  SET_ANTENNA_INSTALLATION(state, { accessKey = "", boolean = false }) {
    state[accessKey].options.antenna.installation = boolean;
  },

  SET_TEMP_ACCESS_AS_SECONDARY(state, boolean) {
    state[Accesses.TEMPORARY].useAsSecondaryAccess = boolean;
  },

  SET_SNMP_ACTION_ADD(state, accessKey = "") {
    state[accessKey].options.snmp.action = Actions.ADD;
  },

  SET_SNMP_ACTION_UPDATE(state, accessKey = "") {
    state[accessKey].options.snmp.action = Actions.UPDATE;
  },

  SET_SNMP_ACTION_DELETE(state, accessKey = "") {
    state[accessKey].options.snmp.action = Actions.DELETE;
  },

  SET_SNMP_ACTION_NONE(state, accessKey = "") {
    state[accessKey].options.snmp.action = Actions.NONE;
  },

  SET_SNMP_COMMUNITY_ACTION_ADD(state, { accessKey = "", communityIndex = DefaultValues.NONE }) {
    state[accessKey].options.snmp.communities[communityIndex].action = Actions.ADD;
  },

  SET_SNMP_COMMUNITY_ACTION_UPDATE(state, { accessKey = "", communityIndex = DefaultValues.NONE }) {
    state[accessKey].options.snmp.communities[communityIndex].action = Actions.UPDATE;
  },

  SET_SNMP_COMMUNITY_ACTION_DELETE(state, { accessKey = "", communityIndex = DefaultValues.NONE }) {
    state[accessKey].options.snmp.communities[communityIndex].action = Actions.DELETE;
  },

  SET_SNMP_COMMUNITY_ACTION_NONE(state, { accessKey = "", communityIndex = DefaultValues.NONE }) {
    state[accessKey].options.snmp.communities[communityIndex].action = Actions.NONE;
  },

  ADD_SNMP_COMMUNITY(state, accessKey = "") {
    state[accessKey].options.snmp.communities.push(defaultCommunity());
  },

  REMOVE_SNMP_COMMUNITY(state, { accessKey = "", communityIndex = DefaultValues.NONE }) {
    state[accessKey].options.snmp.communities.splice(communityIndex, 1);
  },

  SET_SNMP_COMMUNITY_NAME(
    state,
    { accessKey = "", communityIndex = DefaultValues.NONE, communityName = {} }
  ) {
    Vue.set(
      state[accessKey].options.snmp.communities[communityIndex],
      "communityName",
      communityName
    );
  },

  SET_SNMP_NETWORK_ACTION_ADD(
    state,
    { accessKey = "", communityIndex = DefaultValues.NONE, networkIndex = DefaultValues.NONE }
  ) {
    state[accessKey].options.snmp.communities[communityIndex].networks[networkIndex].action =
      Actions.ADD;
  },

  SET_SNMP_NETWORK_ACTION_UPDATE(
    state,
    { accessKey = "", communityIndex = DefaultValues.NONE, networkIndex = DefaultValues.NONE }
  ) {
    state[accessKey].options.snmp.communities[communityIndex].networks[networkIndex].action =
      Actions.UPDATE;
  },

  SET_SNMP_NETWORK_ACTION_DELETE(
    state,
    { accessKey = "", communityIndex = DefaultValues.NONE, networkIndex = DefaultValues.NONE }
  ) {
    state[accessKey].options.snmp.communities[communityIndex].networks[networkIndex].action =
      Actions.DELETE;
  },

  SET_SNMP_NETWORK_ACTION_NONE(
    state,
    { accessKey = "", communityIndex = DefaultValues.NONE, networkIndex = DefaultValues.NONE }
  ) {
    state[accessKey].options.snmp.communities[communityIndex].networks[networkIndex].action =
      Actions.NONE;
  },

  SET_SNMP_NETWORK(
    state,
    {
      accessKey = "",
      communityIndex = DefaultValues.NONE,
      networkIndex = DefaultValues.NONE,
      network = {},
    }
  ) {
    Object.assign(
      state[accessKey].options.snmp.communities[communityIndex].networks[networkIndex],
      network
    );
  },

  SET_SNMP_NETWORK_INPUT(
    state,
    {
      accessKey = "",
      communityIndex = DefaultValues.NONE,
      networkIndex = DefaultValues.NONE,
      input = {},
    }
  ) {
    Vue.set(
      state[accessKey].options.snmp.communities[communityIndex].networks[networkIndex],
      "input",
      input
    );
  },

  ADD_SNMP_NETWORK(state, { accessKey = "", communityIndex = DefaultValues.NONE }) {
    state[accessKey].options.snmp.communities[communityIndex].networks.push(defaultNetwork());
  },

  REMOVE_SNMP_NETWORK(
    state,
    { accessKey = "", communityIndex = DefaultValues.NONE, networkIndex = DefaultValues.NONE }
  ) {
    state[accessKey].options.snmp.communities[communityIndex].networks.splice(networkIndex, 1);
  },

  SET_INTERNET_ON_SITE(state, { accessKey, internetOnSite }) {
    Vue.set(state[accessKey].options, "internetOnSite", internetOnSite);
  },

  SET_INTERNET_ON_SITE_ACTION(state, { accessKey, action }) {
    state[accessKey].options.internetOnSite.action = action;
  },

  SET_INTERNET_ON_SITE_RIPE_NUMBER(state, { accessKey, ripeNumber }) {
    state[accessKey].options.internetOnSite.ripeNumber = ripeNumber;
  },

  SET_INTERNET_ON_SITE_LAN_INTERFACE(state, { accessKey, lanInterface }) {
    state[accessKey].options.internetOnSite.lanInterface = lanInterface;
  },

  SET_INTERNET_ON_SITE_USE_DYNAMIC_CAPACITY(state, { accessKey, useDynamicCapacity }) {
    Vue.set(
      state[accessKey].options.internetOnSite.lanConfig.capacity,
      "dynamic",
      useDynamicCapacity
    );
  },

  SET_INTERNET_ON_SITE_STATIC_BANDWIDTH(state, { accessKey, staticBandwidth }) {
    Vue.set(
      state[accessKey].options.internetOnSite.lanConfig.capacity,
      "bandwidth",
      staticBandwidth
    );
  },

  SET_INTERNET_ON_SITE_EXTRA_ETHERNET_INTERFACES(state, { accessKey, extraEthernetInterfaces }) {
    Vue.set(
      state[accessKey].options.internetOnSite.lanConfig,
      "ethernetInterface",
      extraEthernetInterfaces
    );
  },

  RESET_INTERNET_ON_SITE(state, { accessKey, internetOnSite }) {
    Vue.set(state[accessKey].options, "internetOnSite", internetOnSite);
  },

  SET_TRAFFIC_STATISTICS(state, { accessKey, trafficStatistics }) {
    Vue.set(state[accessKey].options, "trafficStatistics", trafficStatistics);
  },

  ADD_VPN(state, { accessKey = "", vpnKey = "" }) {
    state[accessKey].vpns.push(vpnKey);
  },

  REMOVE_VPN(state, { accessKey = "", vpnKey = "" }) {
    const vpns = state[accessKey].vpns;
    const index = vpns.indexOf(vpnKey);
    vpns.splice(index, 1);
  },

  RESET_TEMPORARY_ACCESS(state, access) {
    Vue.set(state, Accesses.TEMPORARY, access);
  },

  RESET_SECONDARY_ACCESS(state, access) {
    Vue.set(state, Accesses.SECONDARY, access);
  },

  RESET_STATE(state) {
    Object.assign(state, defaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
